import { createSelector } from 'reselect';
import getMobileLightVariant from 'util/getMobileLightVariant';
import theme from 'components/theme';
import { TILE_SIZES } from 'globalConst/const';
import { DEFAULT_ITEMS_PER_ROW, DisplayLayout } from 'components/Collection/Collection.const';
import { contentActions } from 'actions/actions-const';
import CONTENT_TYPES from 'globalConst/contentTypes-const';

const { breakpoints } = theme;

const initialState = {
  contentWidth: null,
  isLoading: false,
  isOnline: true,
  pages: [],
  randomStation: null,
  collections: null,
  fetchError: null,
  pageSlug: null,
  pageSubSlug: null,
  tileAmount: {
    small: null,
    smallWidth: null,
    medium: null,
    large: null,
    extraLarge: null,
  },
  progress: {},
};

export { initialState };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case contentActions.IS_ONLINE:
      return { ...state, isOnline: true };
    case contentActions.IS_OFFLINE:
      return { ...state, isOnline: false };
    case contentActions.FETCH_CONTENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case contentActions.FETCH_PAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pages: action.pages,
      };
    case contentActions.FETCH_CONTENT_SUCCESS:
      switch (action.payload.type) {
        case CONTENT_TYPES.RANDOM_STATION:
          return {
            ...state,
            randomStation: action.payload.data,
          };
        default:
          return {
            ...state,
            collections: { ...state.collections, ...action.payload.data },
          };
      }

    case contentActions.FETCH_COLLECTION_SUCCES:
      return {
        ...state,
        collections: { ...action.data, ...state.collections },
      };
    case contentActions.UPDATE_PAGE_CONTENT:
      const pages = state.pages.map((page) => {
        if (page.slug === action.pageSlug) {
          if (page.pageHeader) {
            page.pageHeader.title = action.title;
          }
          page.title = action.title;
          page.description = action.description;
        }
        return page;
      });

      return {
        ...state,
        pages: pages,
      };
    case contentActions.SET_CURRENT_PAGE_SLUG:
      return {
        ...state,
        pageSlug: action.pageSlug,
        pageSubSlug: action.pageSubSlug,
      };
    case contentActions.FETCH_CONTENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        fetchError: action.error,
      };
    case contentActions.SET_TILE_AMOUNT:
      return {
        ...state,
        tileAmount: action.tileAmount,
        contentWidth: action.contentWidth,
      };
    default:
      return state;
  }
}

/**
 * Given the current state, it returns the random stations
 *
 * @param {object} state
 * @returns {object}
 */
export const getRandomStation = (state) => (state.content.randomStation ? state.content.randomStation.station : null);

/**
 * Given the current state, it returns the random stations's CTA
 *
 * @param {object} state
 * @returns {object}
 */
export const getRandomStationCTA = (state) => (state.content.randomStation ? state.content.randomStation.cta : null);

/**
 * Given the current state, it returns whether or not content is loading
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIsLoading = (state) => state.content.isLoading || state.content.pages.length === 0;

/**
 * Given the current state, it returns whether or not content is online
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIsOnline = (state) => state.content.isOnline;

/**
 * Given the current state, it returns fetchError
 *
 * @param {object} state
 * @returns {object}
 */
export const getFetchError = (state) => state.content.fetchError;

/**
 * Given the current state and a list of page slugs, returns a list of page object with corresponding slugs.
 */
export const pagesFromSlugsSelector = (state, slugs) => state.content.pages.filter((page) => slugs.includes(page.slug));

export const getPagesFromSlugs = createSelector([pagesFromSlugsSelector], (pages) => pages);

/**
 * Grabs a single page from it's slug and returns an object instead of an array
 * @returns {object}
 */
export const getPageFromSlug = (state, slug) => state.content.pages.find((page) => page.slug === slug);

/**
 * Given the current state, it returns the current page slug
 *
 * @param {object} state
 * @returns {string}
 */
export const getCurrentPageSlug = (state) => state.content.pageSlug;

/**
 * Given the current state, it returns the current page sub slug used for indexable tabs
 *
 * @param {object} state
 * @returns {string}
 */
export const getCurrentPageSubSlug = (state) => state.content.pageSubSlug;

/**
 * Given the current state, it returns the content pages
 * @returns {array}
 */
const getPages = (state) => state.content.pages;

/**
 * Given the current state, it returns the current collection
 * @returns {array}
 */
export const getCollection = (slug) => (state) => state?.content?.collections && state.content.collections[slug];

/**
 * Grabs a single page based on current slug
 * @returns {object}
 */
export const getCurrentPage = createSelector(
  [getCurrentPageSlug, getPages],
  (slug, pages) =>
    pages.find((page) => getMobileLightVariant() && `${slug}-light` === page.slug) ||
    pages.find((page) => page.slug === slug)
);

/**
 * Given the current state, it returns the current width of the scrollcontainter
 *
 * @param {object} state
 * @returns {string}
 */
export const getContentWidth = (state) => state.content.contentWidth;

const getDefaultTileSizes = (contentTypeId) =>
  contentTypeId === 'genreCollection' ? TILE_SIZES.LARGE : TILE_SIZES.MEDIUM;

/**
 * Given the current state and tile size, it returns the amount tiles per row
 *
 * @param {object} state
 * @param {object} ownProps
 * @returns {number}
 */
export const getTilesPerRow = (state, ownProps) => {
  const { innerWidth } = window;
  const { contentTypeId, display, tileSizes } = ownProps || {};

  if (
    display === DisplayLayout.LIST ||
    display === DisplayLayout.LIST_NOW_PLAYING_FULL ||
    (display === DisplayLayout.LIST_NOW_PLAYING_HALF && window.innerWidth < breakpoints.values.sm)
  ) {
    return 1;
  }

  if (display === DisplayLayout.LIST_NOW_PLAYING_HALF) {
    return 2;
  }

  const tileAmount = state.content.tileAmount[tileSizes || getDefaultTileSizes(contentTypeId)];
  if (tileAmount) {
    return tileAmount;
  }

  // fallback calculation || Do we ever reach this point?
  if (!breakpoints) return DEFAULT_ITEMS_PER_ROW;
  if (innerWidth < breakpoints.values.ml) return DEFAULT_ITEMS_PER_ROW - 1;
  if (innerWidth < breakpoints.values.ll) return DEFAULT_ITEMS_PER_ROW;
  if (innerWidth < breakpoints.values.lg) return DEFAULT_ITEMS_PER_ROW + 1;

  return DEFAULT_ITEMS_PER_ROW + 2;
};

/**
 * Given the current state, it returns the content
 *
 * @param {object} state
 * @returns {object}
 */
const contentSelector = (state) => state.content;
const contentTypeSelector = (state, props) => props.contentType;
const currentSlugSelector = (state, props) =>
  getCurrentPageSlug(state) || props.match.params.slug || props.match.path.substring('/'.length);
const currentSubSlugSelector = (state, props) => getCurrentPageSubSlug(state) || props.match.params.subSlug;

const matchSubPage = (page, slug, subSlug) => {
  // handle TABS on same page
  if (page.slug === slug) {
    return true;
  }
  // handle page with parent
  if (page.parentSlug && page.parentSlug === slug) {
    return page.slug === subSlug;
  }
  return null;
};

export const getPage = createSelector(
  [contentSelector, contentTypeSelector, currentSlugSelector, currentSubSlugSelector],
  (content, contentType, currentSlug, currentSubSlug) => {
    const mobileLightVariant = getMobileLightVariant();
    switch (contentType) {
      case 'station':
        return {
          contentTypeId: 'stationPage',
          slug: currentSlug,
          tagSlug: currentSlug,
          subSlug: currentSubSlug,
        };
      case 'page':
        return currentSubSlug
          ? content.pages.find((page) => matchSubPage(page, currentSlug, currentSubSlug))
          : content.pages.find((page) => mobileLightVariant && `${currentSlug}-light` === page.slug) ||
              content.pages.find((page) => page.slug === currentSlug);
      case 'genre':
        return { contentTypeId: 'genrePage', slug: currentSlug };
      default:
        return null;
    }
  }
);
