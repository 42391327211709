/* Event data for the Modal Message can be different depending on the
modal's usage, so we're keeping this function very generic */
export function trackModalMessageCTA(eventData) {
  if (!eventData) return;
  window.Taq.push([
    'track',
    'link',
    {
      event_name: eventData.event_name,
      event_category: eventData.event_category,
      event_action: eventData.event_action,
      event_label: eventData.event_label,
    },
  ]);
}

export function trackCTAButton(eventAction, eventLabel, eventName) {
  window.Taq.push([
    'track',
    'link',
    {
      event_category: 'cta',
      event_action: eventAction,
      event_label: eventLabel,
      event_name: eventName,
    },
  ]);
}

export function trackInlineTextframCTA(url) {
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'content-interactions_inline-textframe-cta',
      event_category: 'content-interactions',
      event_action: 'textframe-cta',
      event_label: url,
    },
  ]);
}

export function generateCTAClickData(label, pathname = null, type = null) {
  return {
    event_name: 'cta_click',
    event_category: 'cta',
    event_action: 'click',
    event_label: label,
    page_id: pathname,
    link_id: type,
    content_id: label,
  };
}
