import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Logo from 'components/svg/Logo';
import Search from './Search';
import Switch from 'components/Switch';
import DefaultHeaderContext from './DefaultHeaderContext';
import { isTouch, isMobile } from 'util/device';

import styles from './DefaultHeader.styles';
import MenuButton from './MenuButton';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';

import { useFlags } from 'firebase/useFlags';

const useStyles = createUseStyles(styles, { name: 'DefaultHeader' });
const DefaultHeader = ({ showThemeSwitch }) => {
  const classes = useStyles();
  const [isFullWidthSearch, setFullWidthSearch] = useState(false);

  const { showWebsiteOfTheYearLogo } = useFlags();

  const toggleFullWidthSearch = () => {
    setFullWidthSearch(!isFullWidthSearch);
  };

  return (
    <div data-testid="default-header" className={classes.headerWrapper}>
      <div
        className={classNames(classes.header, {
          [classes.headerTouch]: isTouch,
        })}
      >
        <DefaultHeaderContext.Provider value={{ toggleFullWidthSearch, searchInFullWidth: isFullWidthSearch }}>
          {!isFullWidthSearch && (
            <>
              {isTouch && <MenuButton />}
              <Link className={classes.logo} to="/">
                <Logo />
              </Link>
            </>
          )}

          {isMobile && <div style={{ minWidth: '40px' }}> </div>}
          {!isMobile && (
            <>
              <Search />
              {!isFullWidthSearch && (
                <div className={classes.auth}>
                  {showThemeSwitch && (
                    <div className={classes.switch}>
                      <Switch data-testid="default-header-theme-switch" active={false} light />
                    </div>
                  )}
                  {showWebsiteOfTheYearLogo && (
                    <img
                      src={'/img/website-van-het-jaar-2022-logo.png'}
                      className={classes.websiteOfTheYear}
                      alt="Website van het Jaar 2022 logo"
                    />
                  )}
                </div>
              )}
            </>
          )}
        </DefaultHeaderContext.Provider>
      </div>
    </div>
  );
};

DefaultHeader.propTypes = {
  showThemeSwitch: PropTypes.bool,
};

DefaultHeader.defaultProps = {
  showThemeSwitch: false,
};

export default DefaultHeader;
