import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

export default class AirTime extends PureComponent {
  static defaultProps = {
    timeStart: null,
    timeEnd: null,
  };

  static propTypes = {
    timeStart: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    timeEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  render() {
    if (!this.props.timeStart || !this.props.timeEnd) return null;
    const timeStart = format(new Date(this.props.timeStart), 'HH:mm');
    const timeEnd = format(new Date(this.props.timeEnd), 'HH:mm');
    return `${timeStart} - ${timeEnd}`;
  }
}
