import React from 'react';

const Radio = () => (
  <svg width="141px" height="116px" viewBox="0 0 141 116">
    <defs>
      <polygon points="20.7815137 10 0.0691849315 10 0.0691849315 0.000151515152 20.7815137 0.000151515152 20.7815137 10" />
      <polygon points="20.7815137 10 0.0691849315 10 0.0691849315 0.000151515152 20.7815137 0.000151515152" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-516.000000, -327.000000)">
        <g transform="translate(436.000000, 292.000000)">
          <g transform="translate(80.000000, 35.000000)">
            <path
              d="M133.883957,104 L7.1160426,104 C3.20240732,104 0,100.798619 0,96.8862381 L0,32.1137619 C0,28.201381 3.20240732,25 7.1160426,25 L133.883957,25 C137.797882,25 141,28.201381 141,32.1137619 L141,96.8862381 C141,100.798619 137.797882,104 133.883957,104"
              fill="#F4F4F8"
            />
            <g transform="translate(6.000000, 106.000000)">
              <mask fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-4" />
              <path
                d="M20.7815137,2.03924242 L20.7815137,2.03954545 C20.7815137,6.41772727 17.3809521,10.0001515 13.2243904,10.0001515 L7.62630822,10.0001515 C3.46974658,10.0001515 0.0691849315,6.41772727 0.0691849315,2.03954545 L0.0691849315,2.03924242 C0.0691849315,0.912878788 0.935938356,0.000151515152 2.00492466,0.000151515152 L18.845774,0.000151515152 C19.9147603,0.000151515152 20.7815137,0.912878788 20.7815137,2.03924242"
                fill="#8D929E"
                mask="url(#mask-2)"
              />
            </g>
            <g id="Group-8" transform="translate(114.000000, 106.000000)">
              <mask fill="white">
                <use xlinkHref="#path-3" />
              </mask>
              <g id="Clip-7" />
              <path
                d="M20.7815137,2.03924242 L20.7815137,2.03954545 C20.7815137,6.41772727 17.3809521,10.0001515 13.2243904,10.0001515 L7.62630822,10.0001515 C3.46974658,10.0001515 0.0691849315,6.41772727 0.0691849315,2.03954545 L0.0691849315,2.03924242 C0.0691849315,0.912878788 0.935938356,0.000151515152 2.00492466,0.000151515152 L18.845774,0.000151515152 C19.9147603,0.000151515152 20.7815137,0.912878788 20.7815137,2.03924242"
                fill="#8D929E"
                mask="url(#mask-4)"
              />
            </g>
            <path
              d="M58,63.5 C58,75.9263057 47.9263057,86 35.5,86 C23.0736943,86 13,75.9263057 13,63.5 C13,51.0736943 23.0736943,41 35.5,41 C47.9263057,41 58,51.0736943 58,63.5"
              fill="#8D929E"
            />
            <path
              d="M12.2673337,29.5603409 C11.6303902,28.2882504 12.1712519,26.7367809 13.4687941,26.1124719 L67.2153134,0.261899116 C68.5131477,-0.362123658 70.0960149,0.167723202 70.7326663,1.43981366 C71.3696098,2.71190413 70.8287481,4.26337359 69.5312059,4.88768262 L15.7846866,30.7379692 C14.4871443,31.3622782 12.9042771,30.8321451 12.2673337,29.5603409"
              fill="#F4F4F8"
            />
            <path
              d="M112,50.5 C112,49.125 113.088563,48 114.41903,48 L119.58097,48 C120.911437,48 122,49.125 122,50.5 C122,51.875 120.911437,53 119.58097,53 L114.41903,53 C113.088563,53 112,51.875 112,50.5"
              fill="#8D929E"
            />
            <path
              d="M72,63.5 C72,62.125 73.1655992,61 74.5902204,61 L120.40978,61 C121.834401,61 123,62.125 123,63.5 C123,64.875 121.834401,66 120.40978,66 L74.5902204,66 C73.1655992,66 72,64.875 72,63.5"
              fill="#8D929E"
            />
            <path
              d="M73,50.5 C73,49.125 74.1738658,48 75.6085906,48 L106.391409,48 C107.826134,48 109,49.125 109,50.5 C109,51.875 107.826134,53 106.391409,53 L75.6085906,53 C74.1738658,53 73,51.875 73,50.5"
              fill="#8D929E"
            />
            <path
              d="M72,77.5 C72,76.125 73.1655992,75 74.5902204,75 L120.40978,75 C121.834401,75 123,76.125 123,77.5 C123,78.875 121.834401,80 120.40978,80 L74.5902204,80 C73.1655992,80 72,78.875 72,77.5"
              fill="#8D929E"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Radio;
