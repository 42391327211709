/* for implementation see: https://538groep.atlassian.net/wiki/spaces/juke/pages/1748402290/Button+animation */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectStyles } from 'injectStyles';
import VALID_VARIANTS from './ButtonAnimation.const';

class ButtonAnimation extends PureComponent {
  static defaultProps = {
    borderRadius: '100%',
    buttonPosition: null,
    withAnimation: false,
    isPlaying: false,
    buttonAnimation: null,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    borderRadius: PropTypes.string,
    buttonPosition: PropTypes.string,
    withAnimation: PropTypes.bool,
    isPlaying: PropTypes.bool,
    buttonAnimation: PropTypes.string,
  };

  render() {
    const { buttonPosition, classes, children, withAnimation, buttonAnimation } = this.props;

    if (!withAnimation || !VALID_VARIANTS.includes(buttonAnimation)) {
      return children;
    }

    const [animation, position] = buttonAnimation.split('_');
    if (position !== buttonPosition) {
      return children;
    }

    return (
      <div className={classes.wrapper}>
        <div
          className={classNames({
            [classes.pulse]: animation === 'pulse',
          })}
        />
        {children}
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'relative',
  },
  '@keyframes pulseAnimation': {
    '0%': {
      boxShadow: 'inset 0px 0px 12px 1px rgb(147,192,31)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '70%': {
      boxShadow: 'inset 0px 0px 12px 1px rgba(147,192,31, 0)',
      top: -30,
      left: -30,
      right: -30,
      bottom: -30,
    },
    '100%': {
      boxShadow: 'inset 0px 0px 12px 1px rgba(147,192,31, 0)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  pulse: {
    position: 'absolute',
    animation: '$pulseAnimation 1.5s infinite',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: ({ borderRadius }) => borderRadius,
  },
};

export default injectStyles(styles)(ButtonAnimation);
