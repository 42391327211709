import * as cssVariable from 'cssVariables';
import { ANIMATION_TIME } from 'components/Tiles/StationTile/StationTile.const';

export default {
  hoverOverlay: {
    display: 'none',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    borderRadius: cssVariable.borderRadius.default,
    animation: `$fadeIn ${ANIMATION_TIME} ease-in forwards`,
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      zIndex: 5,
      position: 'relative',
    },
  },
  hoverBackdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: cssVariable.linearGradient,
    opacity: 0.8,
    zIndex: 0,
    borderRadius: cssVariable.borderRadius.default,
  },
  pageLink: {
    position: 'absolute',
    left: 0,
    bottom: 5,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0 0 0 10px',
    fontSize: 13,
    color: cssVariable.white,

    '@supports (-webkit-line-clamp: 1)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
      [cssVariable.WEBKIT_LINE_CLAMP]: 1,
      [cssVariable.WEBKIT_BOX_ORIENT]: 'vertical',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconWrapper: {
    display: 'flex',
    justifyItems: 'flex-start',
    minWidth: 15,
  },
  chevron: {
    verticalAlign: 'middle',
    marginTop: -4,
    height: 26,
    width: 26,
  },
};
