import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import Collection from 'components/Collection';
import StationTile from 'components/Tiles/StationTile';
import withGraphqlData from 'components/hoc/withGraphqlData';
import { MIN_TILE_SIZES } from 'globalConst/const';

const tileComponent = withGraphqlData(StationTile);
function BannerTileCollection({ classes, tileItems }) {
  const containerRef = useRef(null);
  const [visibleItems, setVisibleItems] = useState(0);

  const calculateVisibleItems = useCallback(() => {
    const { width } = containerRef && containerRef.current.getBoundingClientRect();
    let items = 1;
    const minWidthTile = MIN_TILE_SIZES.small[0];

    while (width / (items + 1) > minWidthTile) {
      items += 1;
    }
    setVisibleItems(items);
  }, []);

  useEffect(() => {
    if (tileItems) {
      calculateVisibleItems();
      window.addEventListener('resize', calculateVisibleItems);
    }
    return () => {
      window.removeEventListener('resize', calculateVisibleItems);
    };
  }, [calculateVisibleItems, tileItems]);

  return (
    <div ref={containerRef} className={classes.tileContainer}>
      <Collection
        items={tileItems}
        display="tiles"
        tileComponent={tileComponent}
        rows={1}
        hideShowMore
        isInBanner
        itemsPerRow={tileItems.length > visibleItems ? visibleItems : tileItems.length}
      />
    </div>
  );
}

BannerTileCollection.defaultProps = {
  tileItems: null,
};

BannerTileCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  tileItems: PropTypes.array,
};

const styles = {
  tileContainer: {
    width: '60%',
    padding: '20px 30px',
    alignSelf: 'center',
  },
};

export default injectStyles(styles)(BannerTileCollection);
