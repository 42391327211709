import React, { PureComponent, Fragment } from 'react';
import injectStylesheet from 'react-jss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as cssVariables from 'cssVariables';
import CrossIcon from 'components/svg/Cross';
import Button from 'components/Button';
import { environments, apiSelector } from './ApiSwitch.const';

class ApiSwitch extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {
    showApiSwitch: false,
    selected: {
      [apiSelector.JUKE_API]: localStorage.getItem(apiSelector.JUKE_API),
      [apiSelector.UNIVERSAL_API]: localStorage.getItem(apiSelector.UNIVERSAL_API),
    },
  };

  onToggleApiSwitch = () => {
    this.setState((prevState) => ({
      showApiSwitch: !prevState.showApiSwitch,
    }));
  };

  handleChange = (name) => (event) => {
    const { value } = event.target;

    this.setState((prevState) => ({
      [name]: value,
      selected: {
        ...prevState.selected,
        [name]: value,
      },
    }));
    localStorage.setItem(name, event.target.value);
  };

  handleSave = () => {
    localStorage.removeItem('reduxPersist::appsync');
    window.location.reload();
  };

  render() {
    const { classes } = this.props;
    const { selected } = this.state;
    return (
      <Fragment>
        <div
          className={classNames(classes.apiSwitch, {
            [classes.apiSwitchHide]: this.state.showApiSwitch,
          })}
        >
          <div className={classes.apiSelector}>
            <div className={classes.envWrapper}>
              <span>JUKE API</span>
              {environments.JUKE_API_ENV.map((item) => (
                <div key={item}>
                  <input
                    type="radio"
                    value={item}
                    data-testid={`${apiSelector.JUKE_API}-${item}`}
                    name={apiSelector.JUKE_API}
                    onChange={this.handleChange(apiSelector.JUKE_API)}
                    checked={item === selected[apiSelector.JUKE_API]}
                  />
                  {item}
                </div>
              ))}
            </div>
            <div className={classes.envWrapper}>
              <span>UNIVERSAL API</span>
              {environments.UNIVERSAL_API_ENV.map((item) => (
                <div key={item}>
                  <input
                    data-testid={`${apiSelector.UNIVERSAL_API}-${item}`}
                    type="radio"
                    value={item}
                    name={apiSelector.UNIVERSAL_API}
                    onChange={this.handleChange(apiSelector.UNIVERSAL_API)}
                    checked={item === selected[apiSelector.UNIVERSAL_API]}
                  />
                  {item}
                </div>
              ))}
            </div>
            <div role="presentation" onClick={this.onToggleApiSwitch} className={classes.closeIcon}>
              <CrossIcon />
            </div>
          </div>
          <Button className={classes.saveBtn} onClick={this.handleSave} text="Save and Reload" />
        </div>
        <Button
          className={classNames(classes.btn, {
            [classes.btnHide]: this.state.showApiSwitch,
          })}
          onClick={this.onToggleApiSwitch}
          text="API SWITCH"
        />
      </Fragment>
    );
  }
}
const styles = (theme) => ({
  apiSwitch: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    maxWidth: 550,
    height: 400,
    color: cssVariables.white,
    backgroundColor: cssVariables.black,
    transition: 'translateX 0.3s ease',
    transform: 'translateX(-100%)',
    zIndex: theme.zIndex.apiSwitch + 1,
    [theme.breakpoints.down('sl')]: {
      height: 'auto',
      padding: '25px 5px',
    },
  },
  apiSwitchHide: {
    transform: 'translateX(0px)',
  },
  btn: {
    color: cssVariables.white,
    backgroundColor: cssVariables.black,
    borderRadius: [3, 3, 0, 0],
    width: 150,
    height: 25,
    position: 'fixed',
    zIndex: theme.zIndex.apiSwitch,
    left: 240,
    bottom: 0,
    border: 'none',
    opacity: 1,
    transition: 'all 0.3s ease',
    '&:hover': {
      boxShadow: `0 6px 30px ${cssVariables.transparentLightmode030}`,
    },
    [theme.breakpoints.down('sl')]: {
      left: 70,
    },
  },
  btnHide: {
    opacity: 0,
    visibility: 'hidden',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  apiSelector: {
    padding: '20px',
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sl')]: {
      fontSize: '18px',
    },
  },
  envWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& > span': {
      marginBottom: 10,
    },
  },
  saveBtn: {
    position: 'relative',
    display: 'block',
    margin: '0 auto',
    width: 200,
    height: 50,
    color: cssVariables.black,
    border: 'none',
    fontSize: 20,
    backgroundColor: cssVariables.grey100,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: cssVariables.grey300,
    },
  },
});

export default injectStylesheet(styles)(ApiSwitch);
