import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getHasMobileHeader } from 'store/reducers/navigation';
import { getModalOpen } from 'store/reducers/modal';
import { getIsInMobileApp } from 'store/reducers/urlParams';
import { handleConsentManagerDisplay } from 'actions/gigya-router-actions';
import { closeFold } from 'actions/podcastFold-actions';
import { setModalClosed } from 'actions/modal-actions';
import { userIsOnJuke, userLeftJuke } from 'actions/tracking-actions';
import { closeNavigation } from 'actions/navigation-actions';
import { getIsOpen } from 'store/reducers/navigation';
import Layout from './Layout';

const mapDispatchToProps = {
  handleConsentManagerDisplay,
  closeNavigation,
  closeFold,
  setModalClosed,
  userIsOnJuke,
  userLeftJuke,
};

const mapStateToProps = (state) => ({
  navigationIsOpen: getIsOpen(state),
  modalIsOpen: getModalOpen(state),
  hasMobileHeader: getHasMobileHeader(state),
  isInMobileApp: getIsInMobileApp(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
