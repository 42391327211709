import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import RadioError from 'components/svg/RadioError';
import { gutter } from 'cssVariables';
import logger from 'util/logger';
import { reportPageNotFound } from 'metrics';

const NotFoundPage = ({ classes }) => {
  const timestamp = Math.round(new Date().getTime() / 1000);
  reportPageNotFound(`page not found error`);

  useEffect(() => {
    try {
      // Add noindex tag to header
      document.querySelector('meta[name="robots"]').setAttribute('content', 'noindex, nofollow');
    } catch (error) {
      logger.error(`Couldn't add noindex meta tag: ${error.message}`);
    }
  }, []);

  return (
    <div className={classes.container}>
      <RadioError />
      <h1>Oeps! Sorry, we kunnen deze pagina niet vinden</h1>
      <p>
        We hebben ons best gedaan, maar het lijkt erop dat deze pagina niet (meer) bestaat of misschien verhuisd is. Je
        kunt natuurlijk altijd onze zoekfunctie gebruiken.
      </p>
      <a className={classes.suggestedLink} href={`/radiozenders?preventCache=${timestamp}`}>
        radiozenders
      </a>
      <a className={classes.suggestedLink} href={`/non-stop-muziek?preventCache=${timestamp}`}>
        non-stop muziek
      </a>
      <a className={classes.suggestedLink} href={`/podcasts?preventCache=${timestamp}`}>
        podcasts
      </a>
    </div>
  );
};

NotFoundPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '200px',
  },
  suggestedLink: {
    textTransform: 'uppercase',
    display: 'inline-block',
    marginTop: gutter,
    '&:nth-of-type(2)': {
      marginLeft: gutter * 3,
      marginRight: gutter * 3,
    },
  },
};

export default injectStyles(styles)(NotFoundPage);
