import { connect } from 'react-redux';
import { getCastingDevice, getIsCasting } from 'store/reducers/player';
import { showNotification } from 'actions/notifications-actions';
import GoogleCastLauncher from './GoogleCastLauncher';

const mapStateToProps = (state) => ({
  castingDevice: getCastingDevice(state),
  isCasting: getIsCasting(state),
});

function mapDispatchToProps(dispatch) {
  return {
    showNotification: (notification) => dispatch(showNotification(notification)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleCastLauncher);
