import React from 'react';
import PropTypes from 'prop-types';
import styles from './HighlightText.styles';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'util/index';

const useStyles = createUseStyles(styles, { name: 'HighlightText' });
const HighlightText = ({ text }) => {
  const classes = useStyles();
  const { search } = window.location;
  const searchParams = {};

  if (!text) return null;
  let highlightedText = text;

  if (search) {
    search
      .split('?')[1]
      .split('&')
      .forEach((value) => {
        const keyValue = value.split('=');
        searchParams[keyValue[0]] = keyValue[1];
      });
  }

  if (text && searchParams.search) {
    decodeURIComponent(searchParams.search)
      .split(' ')
      .forEach((textVal) => {
        if (isEmpty(textVal)) return;

        try {
          highlightedText = highlightedText.replace(new RegExp(`(${textVal})`, 'ig'), function replace(match) {
            return `<span>` + match + '</span>';
          });
        } catch {}
      });
  }

  return (
    <p className={classes.wrapper} data-testid="highlight-text" dangerouslySetInnerHTML={{ __html: highlightedText }} />
  );
};

HighlightText.propTypes = {
  text: PropTypes.string,
};

export default HighlightText;
