/**
 * list of routes that have the big search field design
 * when fully switched to dynamic pages, we can add a property "searchDesign" to page model and remove this check
 * check is used in PageHeader fo page padding and Input component
 */
export const supportedRoutes = [
  '/',
  '/radiozenders',
  '/brand',
  '/muziekgenres',
  '/radioshows',
  '/luisterboeken',
  '/terugluisteren',
  '/apparaten',
  '/sonos',
];

export const supportedChildRoutes = ['/podcasts/:slug'];
