export default function outboundClick(url) {
  window.Taq.push([
    'track',
    'link',
    {
      event_action: 'outbound_click',
      event_category: 'content_listing',
      event_label: url,
    },
  ]);
}
