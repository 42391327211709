import { Action } from 'redux';
import { trackingActions } from './actions-const';

export function userIsOnJuke(): Action<string> {
  return {
    type: trackingActions.USER_ON_JUKE,
  };
}

export function userLeftJuke(): Action<string> {
  return {
    type: trackingActions.USER_NOT_ON_JUKE,
  };
}
