import React from 'react';
import PropTypes from 'prop-types';

import svgIcon from 'components/hoc/svgIcon';

const svg = ({ hasStroke, filled }) => (
  <svg viewBox="0 0 40 40">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(1.000000, 1.000000)">
      {hasStroke ? <circle cx="19" cy="19" r="19" stroke="#FFFFFF" /> : ''}
      <polygon
        fill={filled ? 'currentColor' : 'none'}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
        points="24,19 15,25 15,13 24,19"
      />
    </g>
  </svg>
);

svg.propTypes = {
  hasStroke: PropTypes.bool,
  filled: PropTypes.bool,
};

svg.defaultProps = {
  hasStroke: false,
  filled: true,
};

export default svgIcon(svg);
