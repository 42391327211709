import React from 'react';
import Schema from 'components/Schema';
import PropTypes from 'prop-types';

const PodcastsSchema = ({ name, abstract, author, slug, image, keywords, feedUrl, inLanguage }) => {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'PodcastSeries',
    name,
    description: `Podcast van ${author}`,
    abstract,
    author: {
      '@type': 'Person',
      name: author,
    },
    inLanguage,
    url: `https://juke.nl/podcasts/${slug}`,
    image,
    keywords,
    webFeed: feedUrl,
  };
  return <Schema data={schema} />;
};

PodcastsSchema.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  abstract: PropTypes.string,
  author: PropTypes.string,
  numberOfEpisodes: PropTypes.number,
  slug: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
  feedUrl: PropTypes.string,
  inLanguage: PropTypes.string,
};

PodcastsSchema.defaultProps = {
  name: null,
  description: null,
  abstract: null,
  author: null,
  numberOfEpisodes: null,
  slug: null,
  image: null,
  keywords: null,
  feedUrl: null,
  inLanguage: null,
};
export default PodcastsSchema;
