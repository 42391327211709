import { connect } from 'react-redux';
import { saveRecentSearch } from 'actions/search-actions';
import { IHoverOverlay, DispatchToProps } from './HoverOverlay.interface';
import HoverOverlay from './HoverOverlay';
import { State } from 'redux';
import { Station } from 'api/models';

interface OwnProps extends IHoverOverlay {
  station: Station;
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  return {
    station: ownProps.station,
    customTracking: ownProps.customTracking,
  };
};

const mapDispatchToProps = {
  saveRecentSearch,
};

function mergeProps(stateProps: IHoverOverlay, { saveRecentSearch }: DispatchToProps, ownProps: OwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    saveRecentSearch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HoverOverlay);
