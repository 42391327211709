import * as cssVariable from 'cssVariables';

export default {
  containerWrapper: {
    position: 'relative',
    overflowY: 'scroll',
    height: 225,
    textAlign: 'left',
  },
  container: {
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    padding: [0, 12, 8],
  },
  noPlayoutTitle: {
    margin: 0,
    padding: `25px ${cssVariable.playerGutter}px`,
    fontWeight: cssVariable.fontWeights.light,
    fontSize: cssVariable.fontSizes.small,
    textAlign: 'center',
    color: cssVariable.white,
  },
  title: {
    margin: 0,
    padding: [14, 0, 0],
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.white,
    letterSpacing: -0.4,
  },
  trackListContainer: {
    height: '100%',
    overflow: 'visible',
    backfaceVisibility: 'hidden',
  },
  trackWrapper: {
    marginBottom: 7,
  },
};
