import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg viewBox="0 0 72 72">
    <path d="M66.7 38.9l-27.2-26c-1-1-2.7-1-3.8 0l-27.1 26c-.5.5-.5 1.3 0 1.8s1.3.5 1.8 0l6-5.7v24.5c0 1.5 1.2 2.7 2.6 2.7h37.4c1.5 0 2.6-1.2 2.6-2.7V35l6 5.7c.2.2.6.3.9.3.3 0 .7-.1.9-.4.4-.4.4-1.2-.1-1.7zM43.8 59.7H31.7V45c0-.1.1-.2.1-.2h11.8c.1 0 .1.1.1.2v14.7zm12.7-27.1v26.9c0 .1-.1.2-.1.2h-10V45c0-1.5-1.2-2.7-2.6-2.7H31.9c-1.5 0-2.6 1.2-2.6 2.7v14.7H19c-.1 0-.1-.1-.1-.2V32.6l18.7-17.9c.1-.1.2-.1.3 0l18.6 17.9zm-37.7-6.8v-9.6H23v5.5l2.5-2.4v-3.5c0-1.2-1-2.2-2.1-2.2h-4.9c-1.2 0-2.1 1-2.1 2.2v12c0 .1 0 .2.1.3l2.3-2.3z" />
  </svg>
);

export default svgIcon(svg);
