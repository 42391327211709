import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as cssVariable from 'cssVariables';
import { getImageWithResamplingQuery } from 'util/resampling';
import { isMobile, isTouch } from 'util/device';
import { isEmpty } from 'util/index';

import PlayIcon from 'components/svg/Play';
import SpeakerIcon from 'components/svg/Speaker';
import StopIcon from 'components/svg/Stop';

import PlayControl from 'components/PlayControl';
import { convertHMSToSeconds } from 'util/time';
import ClipProgress from './ClipProgress';
import Progressbar from './Progressbar';
import AirDate from './AirDate';
import Duration from './Duration';
import Description from './Description';
import LazyTileImage from 'components/LazyTileImage';
import HighlightText from './HighlightText';
import CollectionContext from 'components/Collection/CollectionContext';
import ContextMenu from 'components/ContextMenu';

import styles from './OnDemandTileList.styles';
import { createUseStyles } from 'react-jss';
import { useQuery } from 'components/hooks/useQuery';
import { SCROLL_CONTAINER_ID } from 'globalConst/const';
import * as analytics from 'analytics';
import { trackNavigateToPodcastEpisodePage } from 'analytics';

const fallbackUrl = '/album-cover-fallback-high-res.png';

const useStyles = createUseStyles(styles, { name: 'OnDemandTileList' });

const OnDemandTileList = ({
  isPlaying,
  isLoading,
  isActive,
  onPlayPauseToggle,
  onDemandClip,
  onDemandClipInPlayerTime,
  onDemandTitle,
  screenReaderTitle,
  station,
  clearSearch,
}) => {
  const {
    id,
    show,
    publishedUtc,
    publishDate,
    showNewLabel,
    podcastTitle,
    title,
    subTitle,
    description,
    disabled,
    image,
    imageUrl,
    track,
    duration,
    brandColor,
    index,
    progress,
    podcast,
    slug,
    pageName,
  } = onDemandClip;

  const classes = useStyles({ onDemandClip: { brandColor } });

  const isPodcast = !!podcast;
  const podcastSlug = podcast && podcast.slug;
  const { isPodcastPage, isInFold, isInSearch } = useContext(CollectionContext);
  const { title: mainPodcastTitle } = podcast || {};

  let query = useQuery();
  const [isHighlighted, setHighlighted] = useState(false);
  const [isClipDeleted, setClipDeleted] = useState(false);

  const mobilePodcastCollection = isPodcast && isMobile;
  const usableImage = image && image.url ? image.url : imageUrl;

  const { contentLength: clipDuration = convertHMSToSeconds(duration) } = progress || {};

  let scrollTimeout;

  const scrollContainer = isTouch ? window : document.getElementById(SCROLL_CONTAINER_ID);

  const handleHighlightScroll = () => {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function () {
      setHighlighted(true);
      onPlayPauseToggle(true);
      scrollContainer.removeEventListener('scroll', handleHighlightScroll);
    }, 200);
  };

  const handleClipDeletion = () => {
    setClipDeleted(true);
  };
  const clipId = query.get('id');

  const tileRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (id === clipId) {
      if (isEmpty(tileRef.current)) return;

      scrollContainer.addEventListener('scroll', handleHighlightScroll);
      tileRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [clipId, podcastSlug]);

  useEffect(() => {
    if (isHighlighted && !isActive) {
      setHighlighted(false);
    }
  }, [isActive]);

  const getShareDescription = () => {
    return `Luister de laatste aflevering van ${title}`;
  };

  const clipProgress = onDemandClipInPlayerTime;

  const getPodcastEpisodeTrackingData = (pageName, title, subTitle, publishDate, publishedUtc) => {
    if (pageName) {
      // Variation for all podcast clips
      const mediaDate = publishDate || publishedUtc ? title + ' - ' + publishDate || publishedUtc : title;

      return {
        mediaName: pageName,
        mediaProgramEpisode: mediaDate,
      };
    }

    if (subTitle && title) {
      // Variation for the radiozenders OnDemandShow in Clips in progress on Podcasts page
      const mediaProgramEpisodeData =
        title === subTitle ? publishDate || publishedUtc : subTitle + ' - ' + publishDate || publishedUtc;

      return {
        mediaName: title,
        mediaProgramEpisode: mediaProgramEpisodeData,
      };
    }

    return {
      mediaName: null,
      mediaProgramEpisode: null,
    };
  };

  const onClick = () => {
    if (disabled) {
      return;
    }

    if (isInSearch) {
      history.push(`/${['podcasts', podcast.slug].join('/')}?id=${id}`);
      clearSearch();
    } else {
      onPlayPauseToggle();
    }

    if (progress && progress.durationLength > 0) {
      let mediaProgramEpisode;
      let mediaName;

      if (!isPodcastPage) {
        // Variation for the radiozenders OnDemandShow on radiozenders page
        mediaName = title;
        mediaProgramEpisode = publishDate || publishedUtc;
      } else {
        const episodeTrackDataObj = getPodcastEpisodeTrackingData(pageName, title, subTitle, publishDate, publishedUtc);
        mediaName = episodeTrackDataObj.mediaName;
        mediaProgramEpisode = episodeTrackDataObj.mediaProgramEpisode;
      }

      analytics.trackPlayClipsInProgress(mediaName, mediaProgramEpisode);
    }
  };

  if (isClipDeleted) return null;

  return (
    <div
      id={`ondemand_tile_list-${id}`}
      ref={tileRef}
      data-testid="ondemand_tile_list"
      className={classNames(classes.listItem, {
        [classes.podcastEpisode]: isPodcastPage,
        [classes.highlight]: isHighlighted,
      })}
      role="presentation"
    >
      <div
        className={classNames(classes.rowContainer, {
          [classes.rowContainerEven]: index % 2 === 0 && !mobilePodcastCollection,
          [classes.withHoverEffect]: !isPodcast,
          [classes.hideTriangles]: isPlaying,
          [classes.mobileRowContainer]: mobilePodcastCollection,
          [classes.lightBackgound]: isInFold,
        })}
        onClick={onClick}
      >
        <div className={classes.clickContainer}>
          <LazyTileImage
            data-key="tile-image"
            className={classNames({
              [classes.albumCover]: !disabled,
              [classes.albumCoverWithColor]: brandColor,
            })}
            imageUrl={
              usableImage ? getImageWithResamplingQuery({ url: usableImage, isTransparent: true }) : fallbackUrl
            }
          >
            {!isPodcast && !disabled && (
              <div className={classes.triangles}>
                <div className={classes.triangleSmall} style={{ background: brandColor || cssVariable.black }} />
                <div className={classes.triangleBig} style={{ background: brandColor || cssVariable.black }} />
              </div>
            )}
            {!disabled ? (
              <div
                className={classNames(classes.albumCoverOverlay, { [classes.roundOverlay]: !isPlaying })}
                data-testid="onDemandTileList_play"
              >
                {isPlaying ? (
                  <Fragment>
                    <SpeakerIcon className={classes.speakerIcon} volumeLevel={100} />
                    <StopIcon filled className={classes.stopIcon} />
                  </Fragment>
                ) : (
                  <PlayIcon />
                )}
              </div>
            ) : (
              isPlaying && (
                <div className={classNames(classes.albumCoverOverlay)}>
                  <SpeakerIcon volumeLevel={100} />
                </div>
              )
            )}
          </LazyTileImage>
          <div className={classes.info}>
            {station && (
              <Fragment>
                <h3 className={classNames(classes.title, { [classes.titleActivePlayer]: isPlaying })}>
                  {onDemandTitle}
                </h3>
                <span className={classes.screenReaderTitle}>{screenReaderTitle}</span>
                <span className={classes.airDateHolder}>
                  <AirDate date={publishedUtc} />
                </span>
                {duration && (
                  <span className={classes.airDateHolder}>
                    <Duration duration={duration} />
                  </span>
                )}
              </Fragment>
            )}
            {/* @Note: Used for podcast and ondemand tile */}
            {!station && !show && !track && (
              <Fragment>
                {isPodcastPage && (publishDate || publishedUtc) && (
                  <div className={classes.podcastAirDate}>
                    <AirDate date={publishDate || publishedUtc} showDays showNewLabel={showNewLabel} />
                  </div>
                )}
                <h3 className={classNames(classes.title, { [classes.titleActivePlayer]: isPlaying })}>
                  {isInSearch ? <HighlightText text={podcastTitle} /> : podcastTitle || title}
                </h3>
                {(subTitle && isInSearch && <HighlightText text={subTitle} />) || subTitle}
                {isPodcastPage && duration && (
                  <div className={classes.podcastDuration}>
                    {subTitle && <span>&nbsp;-&nbsp;</span>}
                    <ClipProgress clipProgress={clipProgress} clipDuration={clipDuration} />
                  </div>
                )}
              </Fragment>
            )}

            {/* @Note: Not used (no show page currently) */}
            {show && show.hasPage && !disabled && !isMobile && !isInFold && (
              <NavLink
                className={classes.pageLink}
                to={`/radioshows/${show.slug}`}
                aria-label={`Ga naar ${show.name}`}
                title="Bekijk meer"
                onClick={(e) => e.stopPropagation()}
              >
                Bekijk meer
              </NavLink>
            )}
            {/* @Note: Used for ondemand content and old podcast design */}
            {!isPodcastPage && (publishDate || publishedUtc) && (
              <span className={classes.airDateHolder}>
                <AirDate date={publishDate || publishedUtc} />
                {duration && (
                  <Fragment>
                    <span>&nbsp;-&nbsp;</span>
                    <Duration duration={duration} />
                  </Fragment>
                )}
              </span>
            )}
          </div>
          <div className={classes.buttonGroup}>
            {/* @Note: Play button for ondemand  */}
            {!isPodcastPage && !disabled && isInFold && (
              <PlayControl
                playToggle={onPlayPauseToggle}
                isPlaying={isPlaying && !isLoading}
                isLoading={isPlaying && isLoading}
                isOnDemand
                listItem
                size={45}
              />
            )}
            <ContextMenu
              params={{ id }}
              right
              title={title}
              item={podcast}
              podcastEpisodeSlug={slug}
              description={getShareDescription()}
              color={cssVariable.grey500}
              onDemandClip={onDemandClip}
              setClipDeleted={handleClipDeletion}
              clipDuration={clipDuration}
              collectionTitle={title}
              isOnDemandTile={true}
            />
          </div>
        </div>
      </div>
      {isPodcastPage && (
        <NavLink
          onClick={() => trackNavigateToPodcastEpisodePage(title, mainPodcastTitle)}
          className={classes.description}
          to={`/podcasts/${podcastSlug}/${slug}`}
        >
          <Description text={description} />
        </NavLink>
      )}
      <Progressbar duration={clipDuration} progress={clipProgress} isPlaying={isPlaying} />
    </div>
  );
};

OnDemandTileList.propTypes = {
  isPlaying: PropTypes.bool,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  playerInitialized: PropTypes.bool,
  onDemandClip: PropTypes.object,
  onDemandTitle: PropTypes.string,
  screenReaderTitle: PropTypes.string,
  station: PropTypes.object,
  isSearchActive: PropTypes.bool,
  onPlayPauseToggle: PropTypes.func.isRequired,
  onDemandClipInPlayerTime: PropTypes.number,
  clearSearch: PropTypes.func.isRequired,
};

OnDemandTileList.defaultProps = {
  isPlaying: false,
  isLoading: false,
  isActive: false,
  playerInitialized: false,
  onDemandClip: null,
  onDemandTitle: null,
  screenReaderTitle: null,
  station: null,
  onDemandClipInPlayerTime: null,
};

export default OnDemandTileList;
