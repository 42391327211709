import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';

import getMobileLightVariant from 'util/getMobileLightVariant';
import { noop } from 'util/index';

import { BreadcrumbsSchema, DevicesSchema, PageTypeSchema, DeeplinkSchema } from 'components/Schema';

import PageContent from 'components/PageContent';
import PageHeader from 'components/PageHeader';
import Video from 'components/Video';
import Image from 'components/Image';

import ContentTypeComponent from '../../ContentTypeComponent';

class DynamicPage extends PureComponent {
  static defaultProps = {
    page: {
      title: null,
      content: null,
      headerVideo: {},
      articleImage: {},
      showSocial: false,
      isArticlePage: false,
      hidePlayer: false,
    },
    subSlug: null,
    parentSlug: null,
    subtitle: null,
    description: null,
    forceMiniPlayerState: noop,
    pageTitle: null,
    rating: null,
    deeplink: null,
  };

  static propTypes = {
    page: PropTypes.object,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    classes: PropTypes.object.isRequired,
    subSlug: PropTypes.string,
    parentSlug: PropTypes.string,
    forceMiniPlayerState: PropTypes.func,
    pageTitle: PropTypes.string,
    rating: PropTypes.object,
    deeplink: PropTypes.string,
  };

  state = {
    tabsTitle: null,
  };

  mobileLightVariant = getMobileLightVariant();

  componentDidMount() {
    const {
      page: { hidePlayer },
    } = this.props;
    if (hidePlayer) {
      this.props.forceMiniPlayerState(true);
    }

    this.getCurrentTabTitle();
  }

  componentDidUpdate() {
    this.getCurrentTabTitle();
  }

  componentWillUnmount() {
    const { hidePlayer } = this.props.page;
    if (hidePlayer) {
      this.props.forceMiniPlayerState(false);
    }
  }

  getCurrentTabTitle = () => {
    const { page } = this.props;

    const subSlug = window.location.pathname.split('/').pop();
    const matchingTabs = (page.content || []).find(
      ({ contentTypeId, tabs }) => contentTypeId === 'tabs' && tabs.some((tab) => tab.slug === subSlug)
    );
    const tabsTitle = matchingTabs && matchingTabs.tabs.find((tab) => tab.slug === subSlug).title;
    this.setState({ tabsTitle });
  };

  getMetadata = () => {
    const { page, subSlug } = this.props;
    const { metadata } = page || {};

    /**
     * Used for Tabs with Indexable Tabs
     * If we have a sub slug in the url we should use the the metadata of the matching tab if available
     */
    if (subSlug) {
      const matchingTabs = (page.content || []).find(
        ({ contentTypeId, tabs }) => contentTypeId === 'tabs' && tabs.some((tab) => tab.slug === subSlug)
      );
      const tabMetadata = matchingTabs && matchingTabs.tabs.find((tab) => tab.slug === subSlug).metadata;
      if (tabMetadata) {
        return tabMetadata;
      }
    }
    return metadata;
  };

  render() {
    const { page, pageTitle, parentSlug, subSlug, rating, classes } = this.props;
    const { content, slug, title, pageTypeSchema, deeplink } = page || {};
    const { tabsTitle } = this.state;

    const metadata = this.getMetadata();

    return (
      <div
        className={classNames({
          [classes.articlePageContent]: page.isArticlePage,
        })}
      >
        <PageTypeSchema type={pageTypeSchema} metadata={metadata} />
        <DeeplinkSchema deeplinks={[{ link: deeplink }]} />
        <PageHeader {...this.props} metadata={metadata} />
        <BreadcrumbsSchema level2={parentSlug ? pageTitle : title} level3={tabsTitle || (parentSlug && title)} />
        {page.headerVideo && page.headerVideo.url ? <Video {...page.headerVideo} /> : null}
        {page.articleImage && page.articleImage.url ? <Image media={page.articleImage} /> : null}
        <DevicesSchema rating={rating} slug={slug} subSlug={subSlug} />
        <PageContent isArticlePage={page.isArticlePage}>
          {page.description && page.isArticlePage && (
            <div
              className={classNames(classes.componentWrapperInArticlePage, classes.articlePageDescription)}
              dangerouslySetInnerHTML={{ __html: page.description }}
            />
          )}
          {content && content.map((component) => <ContentTypeComponent key={component.uuid} {...component} />)}
        </PageContent>
      </div>
    );
  }
}

const styles = (theme) => ({
  articlePageContent: {
    maxWidth: 1166,
    margin: '0 auto',
  },
  componentWrapperInArticlePage: {
    maxWidth: 680,
    display: 'block',
    width: '100%',
  },
  articlePageDescription: {
    marginBottom: 40,
    fontSize: 18,
  },
  [theme.breakpoints.down('lg')]: {
    articlePageContent: {
      maxWidth: '75%',
      padding: 0,
      minWidth: 680,
    },
    componentWrapperInArticlePage: {
      maxWidth: '100%',
    },
  },
  [theme.breakpoints.down('ml')]: {
    articlePageContent: {
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
});

export default injectStyles(styles)(DynamicPage);
