import { connect } from 'react-redux';
import { getPlaybackMode, getIsPlaying } from 'store/reducers/player';
import { loadStation, loadLiveVideo } from 'actions/player-actions';

import PlayerVideo from './PlayerVideo';

const mapStateToProps = (state) => ({
  playbackMode: getPlaybackMode(state),
  isPlaying: getIsPlaying(state),
});

const mapDispatchToProps = {
  onSetPlaybackToLiveVideo: loadLiveVideo,
  onSetPlaybackToLiveAudio: loadStation,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerVideo);
