import { format } from 'date-fns';

export function firstVisitData() {
  const FIRST_VISIT_LOCAL_STORAGE_KEY = 'JUKE_FIRST_VISIT';
  const FIRST_VISIT_URL_LOCAL_STORAGE_KEY = 'JUKE_FIRST_VISIT_URL';
  let firstVisit = {
    date: localStorage.getItem(FIRST_VISIT_LOCAL_STORAGE_KEY),
    url: localStorage.getItem(FIRST_VISIT_URL_LOCAL_STORAGE_KEY),
  };

  if (!firstVisit.date && !firstVisit.url) {
    firstVisit = {
      date: format(Date.now(), 'yyyyMMdd'),
      url:
        window.location.pathname === '/'
          ? `${window.location.host}/home`
          : `${window.location.host}${window.location.pathname}`,
    };
    localStorage.setItem(FIRST_VISIT_LOCAL_STORAGE_KEY, firstVisit.date);
    localStorage.setItem(FIRST_VISIT_URL_LOCAL_STORAGE_KEY, firstVisit.url);
  }
  return firstVisit;
}

export function trackFirstPageView() {
  window.Taq.push([
    'track',
    'view',
    {
      event_name: 'first_pageview',
      event_category: 'first',
      event_action: 'pageview',
      event_label: window.location.href,
    },
  ]);

  window.Taq.push(['setDataLayer', { call_noninteractionhit: true }, false]);
}
