import * as cssVariable from 'cssVariables';
import { breakpoints, mediaQuery } from '../theme/breakpoints';

const styles = (theme) => ({
  socialShare: {
    display: 'block',
    lineHeight: 0,
    position: 'relative',
  },
  shareButton: {
    '& svg': {
      height: '2.2em',
      width: '2.2em',
      color: ({ color = cssVariable.white }) => color,
    },
  },
  shareButtonWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareMenu: {
    background: cssVariable.white,
    borderRadius: 4,
    boxShadow: `0 5px 10px 0 ${cssVariable.transparentLightmode030}, 0 1px 3px 0 ${cssVariable.transparentLightmode010}`,
    left: '50%',
    minWidth: 250,
    padding: 0,
    position: 'absolute',
    transform: 'translateX(-50%)',
    zIndex: theme.zIndex.navigation,
  },
  shareMenuBrandPage: {
    transform: 'translateX(-5%)',
  },
  shareMenuHeader: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    fontSize: 16,
    justifyContent: 'flex-start',
    padding: '15px 20px',
  },
  shareMenuHeaderTitle: {
    color: cssVariable.black,
    display: 'block',
    lineHeight: 1.375,
  },
  shareMenuCloseButton: {
    position: 'absolute',
    top: 0,
    right: 5,
    bottom: 0,
    margin: 'auto',
    background: 'none',
    border: 'none',
    color: cssVariable.grey500,
    cursor: 'pointer',
    display: 'block',
    lineHeight: 0,
    transition: 'all 0.2s ease',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      color: cssVariable.black,
    },
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  listItem: {
    padding: 15,
    position: 'relative',
    '& button': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      textAlign: 'left',
      paddingLeft: '15px !important', //Arg, but needs to be done because react-share sets inline styling padding 0,
    },
  },
  shareMenuList: {
    borderTop: `1px solid ${cssVariable.grey100}`,
    listStyleType: 'none',
    margin: 0,
    padding: 5,
    textIndent: 0,
    '& > li': {
      borderRadius: 2,
      cursor: 'pointer',
      minHeight: 50,
      verticalAlign: 'middle',
      fontSize: cssVariable.fontSizes.xsmall,
      color: cssVariable.grey500,
      fontWeight: cssVariable.fontWeights.bold,
      transition: 'all 0.2s ease',
      '& svg': {
        width: 22,
        height: 22,
      },
    },
    '& > li:hover, & > li:active': {
      color: cssVariable.black,
      background: cssVariable.grey100,
    },
    '& .SocialMediaShareButton': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      padding: 15,
      '&:focus': {
        outline: 0,
      },
    },
  },
  shareMenuListItemLabel: {
    marginLeft: 15,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  shareMenuCustomListItemLabel: {
    paddingLeft: 15,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 15,
    '& > li > div': {
      display: 'initial',
      padding: 0,
    },
    '& $shareMenuListItemLabel': {
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  shareMenuOffset: {},
  [mediaQuery.maxWidth(breakpoints.lg)]: {
    shareMenu: {
      left: 0,
      transform: 'translateX(calc(-100% + 45px))',
    },
    shareMenuBrandPage: {
      transform: 'translateX(4%)',
    },
  },
  [mediaQuery.maxWidth(breakpoints.ml)]: {
    shareMenu: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    shareMenuBrandPage: {
      transform: 'translateX(-6%)',
    },
  },
  [mediaQuery.maxWidth(breakpoints.md)]: {
    shareMenu: {
      left: '10%',
      transform: 'translateX(-50%)',
    },
    shareMenuBrandPage: {
      transform: 'translateX(2%)',
    },
  },
  pullToRight: {
    left: 'auto',
    right: 0,
    transform: 'none',
  },
  shareMenuMobile: {
    position: 'fixed',
    bottom: 10,
    left: 15,
    right: 15,
    transform: 'translateX(0)',
  },
});

export default styles;
