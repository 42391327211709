import React from 'react';
import PropTypes from 'prop-types';

import { injectStyles } from 'injectStyles';
import { gutter } from 'cssVariables';

import Connection from 'components/svg/Connection';

const OfflinePage = ({ classes }) => (
  <div className={classes.container} data-testid="offline-page">
    <Connection />
    <h1>Er is geen internet verbinding beschikbaar</h1>
    <p>We blijven het voor je proberen, maar het kan aan je verbinding liggen.</p>
  </div>
);

OfflinePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  container: {
    textAlign: 'center',
    height: '90vh',
    padding: gutter,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default injectStyles(styles)(OfflinePage);
