/**
 * Convert a hexadecimal value to a rgb value included optional opacity
 * @param {string} hex - the hexadecimal value (no Shorthand value)
 * @param {number} opacity - the opacity 0-100
 * @returns {string} - The rgb(a) value
 */

export default function convertHextoRGB(hex, opacity) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  let result = `${r},${g},${b}`;
  if (opacity) result += `,${opacity / 100}`;
  return result;
}
