export const STATION_COLLECTION_FRAGMENT = `
  fragment StationCollectionFragment on StationCollection {
    id
    title
    description
    options
    config {
      ...ConfigFragment
    }
    type
    slug
    items {
      id
      type
      title
      shortTitle
      slug
      description
      media {
      ...MediaFragment
      }
      images {
      ...ImageFragment
      }
      tags {
        slug
        title
        type
      }
      config {
        ...ConfigFragment
      }
    }
  }
  `;
