import React from 'react';

const Whatsapp = () => (
  <svg viewBox="0 0 20 20" fill="currentColor">
    <circle cx="10" cy="10" r="8" />
    <polygon points="2 18 4 11 9 16 2 18" />
    <path
      fill="#FFFFFF"
      d="M8.12,6l.8,1.4a.63.63,0,0,1,0,.74l-.76.69A11.48,11.48,0,0,0,11,12l.73-.66a.55.55,0,0,1,.73,0l1.31.91c.23.15.18.53,0,.73s-.69.63-1.06.92C11.05,15.34,4.8,8.38,6.37,7c.36-.29.7-.62,1.06-1A.49.49,0,0,1,8.12,6Z"
    />
  </svg>
);

export default Whatsapp;
