import React from 'react';

const NewPodcast = () => (
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M14,22.9809614 L14,25 L16.5,25 C16.776,25 17,25.224 17,25.5 C17,25.776 16.776,26 16.5,26 L10.5,26 C10.224,26 10,25.776 10,25.5 C10,25.224 10.224,25 10.5,25 L13,25 L13,22.9809614 C9.64869771,22.7248025 7,19.9157843 7,16.5 C7,16.224 7.224,16 7.5,16 C7.776,16 8,16.224 8,16.5 C8,19.5264242 10.4579348,21.9909209 13.4832729,21.999975 C13.4888263,21.9997921 13.4944024,21.9997 13.5,21.9997 C13.5055976,21.9997 13.5111737,21.9997921 13.5167271,21.999975 C16.5420652,21.9909209 19,19.5264242 19,16.5 C19,16.224 19.224,16 19.5,16 C19.776,16 20,16.224 20,16.5 C20,19.9157843 17.3513023,22.7248025 14,22.9809614 Z M18,9.3833 L18,13.6163 L21.528,11.5003 L18,9.3833 Z M17.5,15.0003 C17.415,15.0003 17.33,14.9783 17.253,14.9353 C17.097,14.8463 17,14.6803 17,14.5003 L17,8.5003 C17,8.3203 17.097,8.1543 17.253,8.0653 C17.411,7.9763 17.603,7.9783 17.757,8.0713 L22.757,11.0713 C22.908,11.1613 23,11.3243 23,11.5003 C23,11.6753 22.908,11.8383 22.757,11.9283 L17.757,14.9283 C17.678,14.9763 17.589,15.0003 17.5,15.0003 Z M13.5,21 C11.019,21 9,18.981 9,16.5 L9,8.5 C9,6.019 11.019,4 13.5,4 C15.101,4 16.594,4.861 17.396,6.248 C17.535,6.487 17.453,6.793 17.214,6.931 C16.974,7.069 16.669,6.988 16.531,6.749 C15.907,5.67 14.746,5 13.5,5 C11.57,5 10,6.57 10,8.5 L10,16.5 C10,18.43 11.57,20 13.5,20 C15.43,20 17,18.43 17,16.5 C17,16.224 17.224,16 17.5,16 C17.776,16 18,16.224 18,16.5 C18,18.981 15.981,21 13.5,21 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default NewPodcast;
