import React from 'react';

function ThreeDots(props) {
  return (
    <svg {...props} fill="currentColor">
      <circle cx="20" cy="20" r="2" />
      <circle cx="11" cy="20" r="2" />
      <circle cx="29" cy="20" r="2" />
    </svg>
  );
}

export default ThreeDots;
