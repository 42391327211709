import logger from 'util/logger';

export function trackCollectionExpand(collectionTitle) {
  if (!collectionTitle) {
    logger.warn("Can't track titleless Collection");
  }
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'content-interactions_expand-all',
      event_category: 'content-interactions',
      event_action: 'expand-all',
      event_label: `toon meer ${collectionTitle}`,
    },
  ]);
}

export function trackCollectionCollapse(collectionTitle) {
  if (!collectionTitle) {
    logger.warn("Can't track titleless Collection");
  }
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'content-interactions_collapse',
      event_category: 'content-interactions',
      event_action: 'collapse',
      event_label: `toon minder ${collectionTitle}`,
    },
  ]);
}
