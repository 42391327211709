import React from 'react';
import PropTypes from 'prop-types';

const Schema = ({ data }) =>
  data && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />;

Schema.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Schema.defaultProps = {
  data: null,
};

export default Schema;
