import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';
import Markdown from 'marked-react';
import { getImageWithResamplingQuery } from 'util/resampling';
import Video from 'components/Video';
import * as cssVariables from '../../cssVariables';
import quoteIcon from '../Quote/quote-icon.svg';
import { mediaPositions } from './Composite.const';

class Composite extends PureComponent {
  static defaultProps = {
    title: '',
    image: null,
    video: null,
    text: '',
    mediaPosition: null,
    showTitle: true,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    showTitle: PropTypes.bool,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    video: PropTypes.shape({
      url: PropTypes.string,
      aspectRatio: PropTypes.string,
    }),
    text: PropTypes.string,
    mediaPosition: PropTypes.oneOf(Object.values(mediaPositions)),
  };

  state = {
    splitTitle: null,
  };

  componentDidMount() {
    this.checkForSplitTitle();
  }

  bindContent = (content) => {
    this.content = content;
  };

  /*
   * On small displays we want to display a short title next to
   * the image on top
   */
  checkForSplitTitle = () => {
    const titles = this.content && this.content.querySelectorAll('h1,h2,h3');
    if (titles && titles.length === 1 && titles[0].innerHTML.length <= 8) {
      this.setState({ splitTitle: titles[0].innerHTML });
    }
  };

  render() {
    const { classes, title, text, image, video, showTitle, mediaPosition } = this.props;
    const { splitTitle } = this.state;

    return (
      <div
        className={classNames(classes.container, {
          [classes.splitTitleAndImage]: splitTitle && image && !video,
        })}
      >
        {splitTitle && <div className={classNames(classes.compositeTitle, classes.splitTitle)}>{splitTitle}</div>}
        {(image || video) && (
          <div
            className={classNames(classes.media, {
              [classes.alignRight]: !!(text.length && mediaPosition === mediaPositions.RIGHT),
            })}
            data-testid="media-container"
          >
            {video ? (
              <Video {...video} />
            ) : (
              <img
                src={getImageWithResamplingQuery({
                  url: image.url,
                  customWidth: 700,
                })}
                alt={title}
              />
            )}
          </div>
        )}
        <div
          ref={this.bindContent}
          className={classNames(classes.textWrapper, {
            [classes.noMedia]: !image && !video,
          })}
        >
          {showTitle && <h2 className={classes.compositeTitle}>{title}</h2>}
          <div className={classNames(classes.text)}>
            <Markdown>{text}</Markdown>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    margin: `${cssVariables.gutter * 2}px 0`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  splitTitleAndImage: {},
  compositeTitle: {
    fontFamily: cssVariables.fontFamily,
    fontSize: cssVariables.fontSizes.xlarge,
    fontWeight: cssVariables.fontWeights.bold,
    lineHeight: 1,
    color: cssVariables.grey600,
    marginBottom: 25,
    textTransform: 'uppercase',
  },
  splitTitle: {
    display: 'none',
    fontSize: cssVariables.fontSizes.xxlarge,
    textTransform: 'uppercase',
    width: '50%',
    order: 1,
    textAlign: 'center',
  },
  textWrapper: {
    marginBlockStart: 0,
    width: '60%',
    fontFamily: cssVariables.fontFamily,
    fontSize: cssVariables.fontSizes.medium,
    fontWeight: cssVariables.fontWeights.regular,
    color: cssVariables.grey600,
    '& a': {
      fontWeight: cssVariables.fontWeights.bold,
    },
    '& p': {
      marginBlockStart: 0,
    },
    '& h1, & h2': {
      marginTop: 0,
      textTransform: 'uppercase',
    },
    '& h1': {
      fontSize: cssVariables.fontSizes.xxlarge,
    },
    '& blockquote': {
      alignItems: 'center',
      margin: '30px 0 0 0',
      fontSize: cssVariables.fontSizes.xlarge,
      fontWeight: cssVariables.fontWeights.bold,
      color: cssVariables.grey600,
      textTransform: 'uppercase',
      lineHeight: 1.1,
      textAlign: 'center',
      padding: 20,
      position: 'relative',
      maxWidth: 345,
      float: 'right',
      '&:before': {
        position: 'absolute',
        top: -25,
        left: 'calc(50% - 30px)',
        width: 40,
        height: 40,
        background: `url(${quoteIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '40px 40px',
        content: '""',
        display: 'block',
      },
    },
    '& ol': {
      listStyle: 'none',
      counterReset: 'custom-counter',
      margin: 0,
      padding: 0,
      paddingRight: cssVariables.gutter * 2,
      '& li': {
        position: 'relative',
        counterIncrement: 'custom-counter',
        fontSize: cssVariables.fontSizes.medium,
        lineHeight: '24px',
        paddingLeft: 48,
        '&::before': {
          position: 'absolute',
          top: '50%',
          marginTop: -24,
          left: 10,
          content: 'counter(custom-counter)',
          fontWeight: 'bold',
          fontSize: cssVariables.fontSizes.xxlarge,
          color: cssVariables.black,
          lineHeight: '48px',
        },
      },
      '& li:not(:last-child)': {
        marginBottom: '3rem',
      },
    },
  },
  text: {
    maxWidth: 680,
  },
  media: {
    width: 'calc(40% - 20px)',
    overflow: 'hidden',
    marginRight: 20,
    marginLeft: 0,
  },
  alignRight: {
    marginLeft: 20,
    marginRight: 0,
    order: 2,
  },
  [theme.breakpoints.down('sl')]: {
    textWrapper: {
      width: '50%',
    },
    image: {
      width: 'calc(50% - 20px)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    splitTitle: {
      display: 'block',
    },
    textWrapper: {
      width: '100%',
    },
    alignRight: {},
    media: {
      width: '100%',
      marginRight: 0,
      '&:not($alignRight)': {
        order: 3,
        margin: `${cssVariables.gutter}px 0`,
      },
    },
    splitTitleAndImage: {
      '& $media': {
        width: '50%',
        marginLeft: 0,
        order: 0,
        '&$alignRight': {
          order: 2,
        },
      },
      '& $textWrapper': {
        order: 3,
        '& h1, h2, h3': {
          display: 'none',
        },
      },
    },
  },
  noMedia: {
    width: '100%',
    '& blockquote': {
      display: 'inline-block',
      width: '100%',
    },
  },
});
export default injectStyles(styles)(Composite);
