import { connect } from 'react-redux';

import { getTilesPerRow } from 'store/reducers/content';
import { setUpcomingTracks } from 'actions/player-actions';

import Collection, { CollectionProps } from './Collection';
import { State } from 'redux';
import { withConditionDisplay } from '../hoc/withConditionDisplay';

const mapStateToProps = (state: State, ownProps: CollectionProps) => {
  return {
    itemsPerRow: ownProps.itemsPerRow || getTilesPerRow(state, ownProps),
  };
};

const mapDispatchToProps = {
  setUpcomingTracks,
};

export default withConditionDisplay(connect(mapStateToProps, mapDispatchToProps)(Collection));
