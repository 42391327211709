import isEmpty from './isEmpty';
import { Config, UniversalConfig } from 'api/interfaces';

// @Todo | add value validation

export default function parseConfig(config: UniversalConfig[]): Config {
  if (isEmpty(config)) return {};

  if (Array.isArray(config)) {
    return config.reduce((acc, { entries }) => {
      entries.forEach(({ key, value }) => {
        acc[key] = value;
      });

      return acc;
    }, {} as Config);
  }
  return config;
}
