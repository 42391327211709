import React from 'react';
import { useFetchData } from 'components/hooks';
import asContentPage from 'components/hoc/asContentPage';
import LoadingPage from 'components/pages/status/LoadingPage';
import NotFoundPage from 'components/pages/status/NotFoundPage';
import CONTENT_TYPES from 'globalConst/contentTypes-const';

import PageHeader from 'components/PageHeader';
interface PodcastEpisodePageProps {
  subSlug: string;
}

function PodcastEpisodePage({ subSlug }: PodcastEpisodePageProps): JSX.Element {
  const { data: PodcastEpisodeData, isLoading } = useFetchData({
    slug: subSlug,
    contentTypeId: CONTENT_TYPES.CLIP,
  });

  const { title, description, imageUrl } = PodcastEpisodeData || {};

  if (isLoading) {
    return <LoadingPage />;
  }
  if (!PodcastEpisodeData) {
    return <NotFoundPage />;
  }

  const metadata = {
    title,
    description,
    share: {
      title,
      description,
      url: window.location.href,
      image: {
        url: imageUrl,
      },
    },
  };

  return (
    !isLoading && (
      <PageHeader episode={PodcastEpisodeData} {...PodcastEpisodeData} isPodcastEpisodePage metadata={metadata} />
    )
  );
}

export default asContentPage(CONTENT_TYPES.CLIP, PodcastEpisodePage);
