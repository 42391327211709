import React, { Fragment, useState } from 'react';
import withStyles, { WithStylesProps } from 'react-jss';

import { isMobile, getUrlWithConsent, onConsentChange } from 'util/index';
import { fallbackHeight } from './IFrame.const';

interface IFrameProps extends WithStylesProps<typeof styles> {
  source: string;
  name?: string;
  defaultHeight?: number;
  mobileHeight?: number;
  defaultMaxWidth?: number;
}

const IFrame: React.FunctionComponent<IFrameProps> = ({
  classes,
  source,
  name = null,
  defaultHeight = null,
  mobileHeight = null,
  defaultMaxWidth = null,
}) => {
  const [url, setUrl] = useState(getUrlWithConsent(source));

  onConsentChange(() => {
    setUrl(getUrlWithConsent(source));
  });

  return (
    <Fragment>
      {name && <h2>{name}</h2>}
      {url && (
        <iframe
          data-testid="iframe"
          className={classes.iframeComponent}
          style={{
            height: isMobile ? mobileHeight || fallbackHeight : defaultHeight || fallbackHeight,
            maxWidth: defaultMaxWidth,
          }}
          sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
          title={name}
          src={url}
        />
      )}
    </Fragment>
  );
};

const styles = {
  iframeComponent: {
    width: '100%',
    display: 'block',
    margin: '10px auto',
    border: 'none',
  },
};

export default withStyles(styles)(IFrame);
