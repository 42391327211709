import React from 'react';

import { isEmpty } from 'util/index';
import { useSelector } from 'react-redux';
import { getCollection } from '../../store/reducers/content';

interface WithConditionDisplayHOCProps {
  showIfEmpty: Array<string>;
  hideIfEmpty: Array<string>;
}

export function withConditionDisplay<P>(InnerComponent: React.FC<P>) {
  return (componentProps: P): JSX.Element => {
    const { showIfEmpty, hideIfEmpty, ...props } = componentProps as unknown as WithConditionDisplayHOCProps;

    const isVisible = useSelector((state) => {
      let retVal = true;

      // Check if provided collections have some items
      // if one collection has items than hide this collection
      if (!isEmpty(showIfEmpty)) {
        showIfEmpty.some((slug) => {
          const collection = getCollection(slug)(state);
          if (collection && !isEmpty(collection.items)) {
            retVal = false;
            return true;
          }
        });
      }

      // Check if provided collections have some items
      // if one collection does not have items than hide this collection
      if (!isEmpty(hideIfEmpty)) {
        hideIfEmpty.some((slug) => {
          const collection = getCollection(slug)(state);
          if (collection && isEmpty(collection.items)) {
            retVal = false;
            return true;
          }
        });
      }

      return retVal;
    });

    if (isVisible) {
      return <InnerComponent {...(props as unknown as P)} />;
    }

    return null;
  };
}

export default withConditionDisplay;
