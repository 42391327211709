import Show from './Show';

/**
 * @class
 * A representation of Schedules
 *
 * @param {object} station - station model
 */
export default class Schedules {
  constructor(schedules) {
    if (!schedules) {
      return null;
    }

    this.schedules = schedules.map(
      (live) =>
        new Show({
          ...live.show,
          broadcastStartDate: live.broadcastStartDate,
          broadcastEndDate: live.broadcastEndDate,
        })
    );

    // Make this class read-only
    Object.freeze(this);
  }
}
