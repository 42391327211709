import { isMobile } from './device';

/**
 * the light variant contains a more minimalistic design
 * compared with the regular design
 *
 * @returns {boolean} - whether or not juke light variant is used
 */
export default function getMobileLightVariant() {
  return isMobile;
}
