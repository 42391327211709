import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import toKebabCase from 'util/toKebabCase';
import { navigationEvents } from 'analytics';
import NavigationItem from './NavigationItem';
import { navItem } from '../navigationStyles';
import NavigationLink from '../../NavigationLink';
import { isHuawei } from 'util/device';
import { createUseStyles } from 'react-jss';
import styles from './SingleNavigationItem.styles';

const useStyles = createUseStyles(styles, { name: 'SingleNavigationItem' });
const SingleNavigationItem = ({
  id,
  icon,
  title,
  hasSmallDesktopMenu,
  pageUrl,
  pageLink,
  isNew,
  navIsExpanded,
  isCollapsible,
  onItemClick,
}) => {
  const classes = useStyles();
  if (isHuawei && pageLink && pageLink.slug === 'juke-app') {
    return null;
  }

  return (
    <NavigationLink
      className={classNames(classes.navItem, {
        [classes.staticNav]: !isCollapsible,
        [classes.collabsibleNavItem]: isCollapsible,
        [classes.smallNavItem]: hasSmallDesktopMenu,
      })}
      source={navigationEvents.mainNav}
      data-testid={`primary-nav-${toKebabCase(id)}`}
      pageLink={pageLink}
      pageUrl={pageUrl}
      title={navItem.title}
      onClick={onItemClick}
    >
      <NavigationItem
        icon={icon}
        title={title}
        isNew={isNew}
        hasSmallDesktopMenu={hasSmallDesktopMenu}
        navIsExpanded={navIsExpanded}
        isCollapsible={isCollapsible}
      />
    </NavigationLink>
  );
};

SingleNavigationItem.defaultProps = {
  hasSmallDesktopMenu: false,
  isNew: false,
  pageUrl: '',
  pageLink: null,
  navIsExpanded: false,
  isCollapsible: false,
};

SingleNavigationItem.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  // Note: we need the location prop.
  // When the location prop changes, this re-renders and become active/inactive
  // eslint-disable-next-line react/no-unused-prop-types
  location: PropTypes.object.isRequired,
  hasSmallDesktopMenu: PropTypes.bool,
  isNew: PropTypes.bool,
  pageUrl: PropTypes.string,
  pageLink: PropTypes.object,
  navIsExpanded: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  onItemClick: PropTypes.func,
};

export default SingleNavigationItem;
