import { connect } from 'react-redux';
import { getCurrentStation } from 'store/reducers/player';

import PlayerRecommendations from './PlayerRecommendations';

const mapStateToProps = (state) => ({
  currentStation: getCurrentStation(state),
});

export default connect(mapStateToProps)(PlayerRecommendations);
