import {
  fetchStationCollection,
  fetchStation,
  fetchPlayouts,
  fetchUnderlineTrack,
  fetchStationPage,
  fetchRandomStation,
} from './station.service';
import { fetchShowCollection, fetchShow, fetchSchedules } from './show.service';
import { fetchClipCollection } from './clip.service';
import { fetchCampaign } from './campaign.service';
import {
  fetchPodcastCollection,
  fetchPodcast,
  fetchPodcastBasic,
  fetchRelatedPodcast,
  fetchClip,
} from './podcast.service';
import { fetchTagCollection, fetchTagPage, fetchTag } from './tag.service';
import { fetchMixed } from './overviewWithReferences.service';
import { fetchOverviewWithReferences } from './overviewWithReferences.service';
import { fetchSearchAlgolia } from './search.service';
import { createMetric } from './metrics.service';
import { subscribeToScheduleEvents, subscribeToPlayoutEvents } from './subscription.service';
import { fetchPromotionCollection } from './promotion.service';
import { fetchCollectionConfig } from './collectionConfig.service';
import logger from 'util/logger';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFetchFunction = (contentTypeId: string): any => {
  switch (contentTypeId) {
    case 'podcastCollection':
      return fetchPodcastCollection;
    case 'featuredPodcast':
      return fetchPodcastBasic;
    case 'podcast':
      return fetchPodcast;
    case 'onDemandCollection':
    case 'showCollection':
      return fetchShowCollection;
    case 'show':
      return fetchShow;
    case 'playouts':
      return fetchPlayouts;
    case 'stationCollection':
      return fetchStationCollection;
    case 'station':
      return fetchStation;
    case 'schedules':
      return fetchSchedules;
    case 'stationPage':
      return fetchStationPage;
    case 'genre':
    case 'mood':
    case 'brand':
      return fetchTag;
    case 'genrePage':
      return fetchTagPage;
    case 'tagCollection':
      return fetchTagCollection;
    case 'overview':
    case 'overviewCollection':
      return fetchOverviewWithReferences;
    case 'mixedCollection':
      return fetchMixed;
    case 'promotionCollection':
      return fetchPromotionCollection;
    case 'campaign':
      return fetchCampaign;
    case `clipCollection`:
      return fetchClipCollection;
    case 'clip':
      return fetchClip;
    case 'search':
      return fetchSearchAlgolia;

    default:
      logger.error(`no fetch function for contentTypeId ${contentTypeId}`);
      return null;
  }
};

const graphql = {
  fetchShowCollection,
  fetchShow,
  fetchStationCollection,
  fetchStation,
  fetchPlayouts,
  fetchUnderlineTrack,
  fetchStationPage,
  fetchPromotionCollection,
  fetchRandomStation,
  fetchPodcastCollection,
  fetchPodcast,
  fetchPodcastBasic,
  fetchRelatedPodcast,
  fetchTagCollection,
  fetchTagPage,
  fetchTag,
  getFetchFunction,
  fetchSearchAlgolia,
  fetchMixed,
  subscribeToScheduleEvents,
  subscribeToPlayoutEvents,
  fetchCampaign,
  fetchCollectionConfig,
  createMetric,
  fetchClipCollection,
};

export default graphql;
