import * as cssVariable from 'cssVariables';
import ButtonTypes from 'components/Button/Button.const';
import tileStyles from 'tile-components-styles';

const styles = {
  hover: {},
  hasIcon: {
    maxWidth: 50,
    maxHeight: 50,
    '&$hover:hover svg': {
      color: cssVariable.green500,
    },
  },
  hasIconWithText: {},
  button: {
    padding: [0, 30, 0, 30],
    minWidth: 140,
    border: 'none',
    borderRadius: 4,
    textAlign: 'center',
    fontWeight: cssVariable.fontWeights.bold,
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 40,
    lineHeight: 1.2,
    '&:hover': {
      cursor: 'pointer',
    },
    '&$hasIcon': {
      minWidth: 'auto',
      padding: [0, 0, 0, 0],
    },
    '&$hasIconWithText': {
      padding: [0, 30, 0, 0],
    },
  },

  isActive: {},
  disabled: {},
  [ButtonTypes.PRIMARY]: {
    background: cssVariable.green500,
    color: cssVariable.white,
    '&$hover:hover': {
      background: cssVariable.green400,
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.1), 0 5px 10px 0 rgba(0,0,0,0.1)',
    },
    '&$hover:active': {
      background: cssVariable.green500,
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)',
    },
    '&$isActive': {
      background: cssVariable.grey500,
    },
    '&$disabled': {
      background: cssVariable.grey300,
      pointerEvents: 'none',
    },
  },
  [ButtonTypes.SECONDARY]: {
    background: 'transparent',
    color: cssVariable.green500,
    border: `solid 1px ${cssVariable.green500}`,
    '&$hover:hover': {
      background: cssVariable.white,
      boxShadow: '0 5px 20px 0 rgba(147,192,31,0.2)',
    },
    '&:active': {
      background: cssVariable.green100,
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)',
    },
    '&$isActive': {
      border: 'none',
      background: cssVariable.green100,
    },
    '&$disabled': {
      color: cssVariable.grey300,
      border: `solid 1px ${cssVariable.grey300}`,
      pointerEvents: 'none',
    },
    '&:focus': {
      border: 'solid 1px currentColor',
    },
  },
  [ButtonTypes.TERTIARY]: {
    background: 'transparent',
    color: cssVariable.white,
    border: `solid 1px ${cssVariable.white}`,
    '&$hover:hover': {
      color: cssVariable.grey600,
      background: cssVariable.white,
      border: `solid 1px transparent`,
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.1), 0 5px 10px 0 rgba(0,0,0,0.1)',
    },
    '&:active': {
      background: cssVariable.white,
      color: cssVariable.grey600,
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)',
    },
    '&$isActive': {
      border: `solid 1px transparent`,
      color: cssVariable.grey600,
      background: cssVariable.white,
    },
    '&$disabled': {
      color: cssVariable.white,
      border: `solid 1px ${cssVariable.grey300}`,
      background: cssVariable.grey300,
      pointerEvents: 'none',
    },
    '&:focus': {
      border: `solid 1px ${cssVariable.white}`,
    },
  },

  [ButtonTypes.ICON]: {
    ...tileStyles.addToFavoritesButtonDefault,
    padding: '0 !important',
    color: cssVariable.grey500,
  },

  // The button types underneath are deprecated
  mobileButton: {
    height: 40,
    minWidth: 160,
    fontSize: cssVariable.fontSizes.xxsmall2,
    '& > svg': {
      width: '100%',
      height: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 15px',
    '& svg': {
      width: '1.725em',
      height: '1.725em',
    },
  },
};

export default styles;
