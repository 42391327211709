import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';
import styles from './PodcastDetailPageHeader.styles';
import { trackModalMessageCTA } from 'analytics';

import { getResamplingQuery, getURLChainingParameter } from 'util/resampling';
import getMobileLightVariant from 'util/getMobileLightVariant';
import { BUTTON_SIZE, LABEL } from 'globalConst/const';
import Button from 'components/Button';
import ButtonTypes from 'components/Button/Button.const';

import SocialShare from 'components/SocialShare';
import * as cssVariable from 'cssVariables';

import Title from '../Title';
import PlayIcon from 'components/svg/Play';
import PauseIcon from 'components/svg/Pause';

class PodcastDetailPageHeader extends PureComponent {
  static defaultProps = {
    contentWidth: null,
    mainPodcastImage: null,
    title: null,
    author: null,
    description: null,
    id: null,
    slug: null,
  };

  static propTypes = {
    contentWidth: PropTypes.number,
    classes: PropTypes.object.isRequired,
    mainPodcastImage: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    author: PropTypes.string,
    id: PropTypes.string,
    slug: PropTypes.string,
    isPlaying: PropTypes.bool,
    onStop: PropTypes.func.isRequired,
    onPlay: PropTypes.func.isRequired,
    podcastLatestEpisode: PropTypes.object,
    currentClip: PropTypes.object,
  };

  state = {
    showFullDescription: false,
    descriptionIsOverflowing: true,
  };

  componentDidMount() {
    this.isDescriptionOverflowing();
  }

  componentDidUpdate(prevProps) {
    const { contentWidth } = this.props;
    const { showFullDescription } = this.state;

    if (showFullDescription && prevProps.contentWidth !== contentWidth) {
      this.setState({
        descriptionIsOverflowing: true,
        showFullDescription: false,
      });
    } else if (
      this.descriptionDomElement?.scrollHeight !== this.descriptionDomElement?.offsetHeight ||
      prevProps.contentWidth !== contentWidth
    ) {
      this.isDescriptionOverflowing();
    }
  }

  mobileLightVariant = getMobileLightVariant();

  handlePlayLatestEpisode = () => {
    const { podcastLatestEpisode, onStop, onPlay } = this.props;
    if (this.isPodcastLatestEpisode()) {
      onStop();
      this.onTrackData('stop_nieuwste_aflevering');
    } else {
      onPlay(podcastLatestEpisode);
      this.onTrackData('speel_nieuwste_aflevering');
    }
  };

  onTrackData = (eventLabel) => {
    trackModalMessageCTA({
      event_name: eventLabel,
      event_category: 'content',
      event_action: 'click',
      event_label: eventLabel,
    });
  };

  renderDescElement = () => {
    const { showFullDescription } = this.state;
    const { author, description, classes, title, podcastLatestEpisode } = this.props;
    return (
      <Fragment>
        {title && this.mobileLightVariant && (
          <div data-testid="podcast-detail-page-header-title" className={classes.podcastHeaderTitle}>
            {title}
          </div>
        )}
        {author && (
          <h2
            data-testid="podcast-detail-page-header-subtitle"
            className={classNames(classes.podcastHeaderSubtitle, {
              [classes.podcastHeaderSubtitleNoMargin]: !podcastLatestEpisode,
              [classes.podcastHeaderSubtitleMobile]: this.mobileLightVariant,
            })}
          >
            {author}
          </h2>
        )}
        {description && (
          <div
            data-testid="podcast-detail-page-header-description"
            className={classNames(classes.podcastHeaderDescription, {
              [classes.overflowEllipsis]: !showFullDescription,
            })}
            ref={(el) => {
              this.descriptionDomElement = el;
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </Fragment>
    );
  };

  getShareDescription = () => {
    const { title } = this.props;
    return `Luister de laatste aflevering van ${title}`;
  };

  toggleDescriptionCollapse = () => {
    this.setState((prevState) => ({
      showFullDescription: !prevState.showFullDescription,
    }));
  };

  isDescriptionOverflowing = () => {
    const element = this.descriptionDomElement;

    /**
     * It's possible to have a podcast episode without a description
     * In that case we don't have a descriptionDomElement and that content couldn't be overflow
     * This is support not showing the Lees Meer button if the description doesn't exist
     * And also we will prevent the application from crashing by adding additional checks for scrollHeight and offsetHeight
     */
    if (element && element.scrollHeight && element.offsetHeight) {
      this.setState({
        descriptionIsOverflowing: element.offsetHeight < element.scrollHeight,
      });
    } else {
      this.setState({ descriptionIsOverflowing: false });
    }
  };

  renderButtonText = () => {
    return this.isPodcastLatestEpisode() ? 'Pauzeer' : 'Nieuwste aflevering';
  };

  isPodcastLatestEpisode = () => {
    const { isPlaying, podcastLatestEpisode, currentClip } = this.props;
    const latestEpisode = podcastLatestEpisode || null;
    const currentOnDemandClip = currentClip || null;
    return isPlaying && currentOnDemandClip?.id === latestEpisode?.id;
  };

  renderDesktopHeader = (hasImages) => {
    const { classes, title, podcastLatestEpisode } = this.props;
    const { descriptionIsOverflowing, showFullDescription } = this.state;

    return (
      <div className={classes.headerContent}>
        <Title title={title} />
        <div className={classes.wrapper}>
          <div className={classNames(classes.flex)}>
            <div
              data-testid="podcast-detail-page-header-background-image"
              className={classes.image}
              style={{ backgroundImage: hasImages }}
            />
            <div className={classes.podcastHeaderInfo}>
              {!!podcastLatestEpisode && (
                <div className={classNames(classes.flex, classes.actionsHolder)}>
                  <Button
                    className={classes.playPauseButton}
                    data-testid="podcast-detail-page-header-play-pause-button"
                    iconClassName={classes.buttonIcon}
                    type={ButtonTypes.PRIMARY}
                    icon={this.isPodcastLatestEpisode() ? <PauseIcon /> : <PlayIcon filled={false} />}
                    onClick={this.handlePlayLatestEpisode}
                    text={this.renderButtonText()}
                  />
                  <div className={classes.shareholder}>
                    <SocialShare
                      subject={title}
                      description={this.getShareDescription()}
                      color={cssVariable.grey500}
                      size={BUTTON_SIZE.medium}
                    />
                  </div>
                </div>
              )}
              <div
                className={classNames(classes.descriptionHolder, {
                  [classes.fullDescription]: showFullDescription,
                })}
              >
                {this.renderDescElement()}
                {descriptionIsOverflowing && (
                  <button
                    data-testid="podcast-detail-page-header-show-more"
                    type="button"
                    className={classes.toggleBtn}
                    onClick={this.toggleDescriptionCollapse}
                  >
                    {showFullDescription ? LABEL.HIDE : LABEL.SHOW_MORE}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMobileHeader = (hasImages) => {
    const { classes, title, podcastLatestEpisode } = this.props;
    const { showFullDescription, descriptionIsOverflowing } = this.state;

    return (
      <Fragment>
        <div className={classes.containerMobile}>
          <div
            data-testid="podcast-detail-page-header-mobile-background-image"
            className={classes.backgroundImage}
            style={{ backgroundImage: hasImages }}
          />
          <div
            data-testid="podcast-detail-page-header-mobile-tile-image"
            className={classes.tileImage}
            style={{ backgroundImage: hasImages }}
          />
        </div>
        {!!podcastLatestEpisode && (
          <div className={classes.playLatestEpisodeMobile}>
            <Button
              className={classes.playLatestEpisodeButton}
              iconClassName={classes.buttonIcon}
              type={ButtonTypes.PRIMARY}
              icon={this.isPodcastLatestEpisode() ? <PauseIcon /> : <PlayIcon filled={false} />}
              onClick={this.handlePlayLatestEpisode}
              text={this.renderButtonText()}
            />
            <div className={classes.socialShareDetailPage}>
              <SocialShare subject={title} description={this.getShareDescription()} color={cssVariable.grey500} />
            </div>
          </div>
        )}
        <div
          className={classNames(classes.descriptionContainer, {
            [classes.fullDescription]: showFullDescription,
          })}
        >
          {this.renderDescElement()}
          {descriptionIsOverflowing && (
            <button
              data-testid="podcast-detail-page-header-mobile-show-more"
              type="button"
              className={classes.toggleCollapseBtn}
              onClick={this.toggleDescriptionCollapse}
            >
              {showFullDescription ? LABEL.HIDE : LABEL.SHOW_MORE}
            </button>
          )}
        </div>
      </Fragment>
    );
  };

  render() {
    const { mainPodcastImage } = this.props;

    const hasImages =
      mainPodcastImage &&
      mainPodcastImage.url &&
      `url(${mainPodcastImage.url}${getURLChainingParameter(mainPodcastImage.url)}${getResamplingQuery()})`;

    return (
      <header data-testid="podcast_detail_page_header">
        {this.mobileLightVariant ? this.renderMobileHeader(hasImages) : this.renderDesktopHeader(hasImages)}
      </header>
    );
  }
}

export default injectStyles(styles)(PodcastDetailPageHeader);
