import { connect } from 'react-redux';
import { withAnalytics } from 'analytics';
import { stop, loadStation } from 'actions/player-actions';
import { getIsCurrentStationPlaying, getIsLoading } from 'store/reducers/player';
import PlayStationButton from './PlayStationButton';
import { Station } from 'api/models';

interface stateProps {
  isPlayingCurrentStation: boolean;
  station: Station;
  isLoading: boolean;
}

interface dispatchProps {
  onStop: () => void;
  onLoadStation: (station: Station) => void;
}

interface ownProps {
  station: Station;
}

const mapStateToProps = (state: stateProps, ownProps: ownProps) => {
  return {
    isPlayingCurrentStation: getIsCurrentStationPlaying(state, ownProps.station),
    isLoading: getIsLoading(state),
    station: ownProps.station,
  };
};

const loadStationWithAnalytics = withAnalytics(loadStation, {
  player_name: 'header',
});

const stopStationWithAnalytics = withAnalytics(stop, {
  player_name: 'header',
});

const mapDispatchToProps = {
  onLoadStation: loadStationWithAnalytics,
  loadStation: loadStationWithAnalytics,
  onStop: stopStationWithAnalytics,
};

function mergeProps(stateProps: stateProps, { onStop, onLoadStation }: dispatchProps) {
  const { isPlayingCurrentStation, station, isLoading } = stateProps;
  return {
    isPlayingCurrentStation,
    isLoading,
    onPlayToggle: isPlayingCurrentStation ? onStop : () => onLoadStation(station),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PlayStationButton);
