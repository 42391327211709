import React from 'react';

const Mail = () => (
  <svg viewBox="0 0 20 20" fill="currentColor">
    <rect x="2" y="4" width="16" height="12" rx="2" />
    <polyline points="19.5 5.5 10 11 0.5 5.5" stroke="#FFFFFF" strokeWidth="1.5" />
  </svg>
);

export default Mail;
