import { popoutActions } from '../../actions/actions-const';
import { playbackModes } from './player';

const initialState = {
  currentStation: null,
  currentOnDemandClip: null,
  playbackMode: playbackModes.LIVE_AUDIO,
  playbackTime: 0,
  playbackDuration: 0,
  isPopoutPlayerActive: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case popoutActions.POPOUT_SET_STATION:
      return {
        ...state,
        station: action.station,
        clip: null,
        playbackTime: null,
        playbackMode: action.playbackMode || playbackModes.LIVE_AUDIO,
      };
    case popoutActions.POPOUT_SET_CLIP:
      return {
        ...state,
        clip: action.clip,
        station: null,
        playbackTime: action.playbackTime,
        playbackMode: playbackModes.ON_DEMAND_CLIP,
      };
    case popoutActions.POPOUT_SET_PLAYBACK_TIME:
      return {
        ...state,
        playbackTime: action.playbackTime,
      };
    case popoutActions.POPOUT_SET_PLAYBACK_DURATION:
      return {
        ...state,
        playbackDuration: action.playbackDuration,
      };
    case popoutActions.TOGGLE_POPOUT_PLAYER:
      return {
        ...state,
        isPopoutPlayerActive: action.isPopoutPlayerActive,
      };
    default:
      return state;
  }
}

/**
 * Given the current state, it returns the station played in the popout
 *
 * @param {object} state
 * @returns {object}
 */
export const getStationInPopout = (state) => state.popout.station;

/**
 * Given the current state, it returns the clip played in the popout
 *
 * @param {object} state
 * @returns {object}
 */
export const getClipInPopout = (state) => state.popout.clip;

/**
 * Given the current state, it returns if the popout player is active
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIsPopoutPlayerActive = (state) => state.popout.isPopoutPlayerActive;
