import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg viewBox="0 0 23 19">
    <g stroke="none" strokeWidth="1" fill="interit" fillRule="evenodd">
      <g transform="translate(-14.000000, -16.000000)" fill="interit">
        <g transform="translate(14.000000, 16.000000)">
          <path d="M9.55924788,17.7093977 L13.4407521,17.7093977 L13.4407521,1.03134305 L9.55924788,1.03134305 L9.55924788,17.7093977 Z M13.5448251,18.7407407 L9.45517495,18.7407407 C8.93855689,18.7407407 8.51851852,18.3244907 8.51851852,17.812532 L8.51851852,0.928208741 C8.51851852,0.41666259 8.93855689,0 9.45517495,0 L13.5448251,0 C14.0610268,0 14.4814815,0.41666259 14.4814815,0.928208741 L14.4814815,17.812532 C14.4814815,18.3244907 14.0610268,18.7407407 13.5448251,18.7407407 Z" />
          <path d="M1.04072936,17.70809 L4.9222336,17.70809 L4.9222336,5.29191004 L1.04072936,5.29191004 L1.04072936,17.70809 Z M5.02630654,18.7407407 L0.936656427,18.7407407 C0.420038371,18.7407407 0,18.3239629 0,17.811355 L0,5.18864496 C0,4.67603711 0.420038371,4.25925926 0.936656427,4.25925926 L5.02630654,4.25925926 C5.5425083,4.25925926 5.96296296,4.67603711 5.96296296,5.18864496 L5.96296296,17.811355 C5.96296296,18.3239629 5.5425083,18.7407407 5.02630654,18.7407407 Z" />
          <path d="M18.0778391,17.6916966 L21.9596143,17.6916966 L21.9596143,7.01200715 L18.0778391,7.01200715 L18.0778391,17.6916966 Z M22.0632782,18.7407407 L17.9737589,18.7407407 C17.4571047,18.7407407 17.037037,18.3173465 17.037037,17.796601 L17.037037,6.90710273 C17.037037,6.38677681 17.4571047,5.96296296 17.9737589,5.96296296 L22.0632782,5.96296296 C22.5799323,5.96296296 23,6.38677681 23,6.90710273 L23,17.796601 C23,18.3173465 22.5799323,18.7407407 22.0632782,18.7407407 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default svgIcon(svg);
