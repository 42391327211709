import React from 'react';
import PropTypes from 'prop-types';

import svgIcon from 'components/hoc/svgIcon';

const Svg = ({ fill, ...rest }) => (
  <svg viewBox="0 0 40 40" {...rest}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M23.3035216,26.8095163 L23.3035216,24.1689022 C26.3188101,22.8560545 28.304,19.8872285 28.304,16.6034517 L28.304,13.2881798 C28.304,8.71807745 24.565309,5 19.9698693,5 C15.3744295,5 11.6357385,8.71807745 11.6357385,13.2881798 L11.6357385,16.6034517 C11.6357385,19.8872285 13.6209284,22.8560545 16.6362169,24.1689022 L16.6362169,26.8658759 L10.4289563,29.9523941 C8.35111157,30.9848962 7.43645599,33.6352667 7,35.3079495 C10.820571,38.4748166 15.1615801,40.0385924 20.0230275,39.9992768 C24.8844748,39.9599613 29.2101323,38.3260962 33,35.0976818 C32.5557277,33.4648587 31.6330369,30.9499703 29.6241263,29.9523941 L23.3035216,26.8095163 Z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

Svg.defaultProps = {
  fill: '#d5d8dd',
};
Svg.propTypes = {
  fill: PropTypes.string,
};

export default svgIcon(Svg);
