import React, { Component } from 'react';
import UnknownError from 'components/pages/status/UnknownError';
import { injectStyles } from 'injectStyles';

import * as cssVariable from 'cssVariables';
import PropTypes from 'prop-types';
import logger from 'util/logger';
import { reportOverallError } from 'metrics';

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    reportOverallError(`overall error - ${info}`);
    logger.error(error, info); // eslint-disable-line
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <UnknownError />
        </Container>
      );
    }
    return this.props.children;
  }
}

const styles = {
  containerClass: {
    height: 'auto',
    ...cssVariable.fullPageMinHeight,
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
};

const ContainerStyle = ({ classes, children }) => <div className={classes.containerClass}>{children}</div>;

ContainerStyle.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Container = injectStyles(styles, 'ErrorBoundary')(ContainerStyle);
