import * as cssVariable from 'cssVariables';

export default {
  sliderHolder: {
    zIndex: 3,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: cssVariable.grey600,
    transform: 'rotate(-90deg) translateX(0px) translateY(-15px)',
    transformOrigin: '0 0',
    padding: '12.5px 8px 12.5px 15px',
    lineHeight: 1,
    borderRadius: 4,
    filter: `drop-shadow(0 0 4px ${cssVariable.transparentLightmode010})`,
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      top: '50%',
      left: 0,
      width: 10,
      height: 10,
      marginTop: -5,
      marginLeft: -4,
      transform: 'rotate(45deg)',
      borderRadius: 'inherit',
      background: 'inherit',
    },
  },
  volumeSlider: {
    transform: 'translate3d(0, 0, 0)',
    verticalAlign: 'middle',
    margin: 0,
    padding: 0,
    WebkitAppearance: 'none' /* Hides the slider so that custom slider can be made */,
    background: 'transparent',
    outline: 'none',
    WebkitTransition: '.2s',
    height: 12,
    width: 140,
    paddingBottom: 1,
    paddingRight: 3,
    '&::-webkit-slider-thumb': {
      WebkitAppearance: 'none',
      appearance: 'none',
      width: 20,
      height: 20,
      borderRadius: 20,
      background: cssVariable.white,
      cursor: 'pointer',
      boxShadow: `-2px 0 4px 0 ${cssVariable.transparentLightmode030}`,
    },
  },
  ieStyling: {
    padding: 0,
    width: 140,
    marginRight: 10,
    '&::-ms-track': {
      width: 300,
      height: 8,
      background: 'transparent',
      borderColor: 'transparent',
      borderWidth: '6px 0',
      color: 'transparent',
    },
    '&::-ms-fill-lower': {
      backgroundColor: cssVariable.green500,
      borderRadius: 10,
    },
    '&::-ms-fill-upper': {
      background: cssVariable.grey200,
      borderRadius: 10,
    },
    '&::-ms-thumb': {
      border: 'none',
      height: 16,
      width: 16,
      borderRadius: '50%',
      background: cssVariable.white,
      boxShadow: `-2px 0 4px 0 ${cssVariable.transparentLightmode030}`,
    },
    '&:focus::-ms-fill-lower': {
      backgroundColor: cssVariable.green600,
    },
    '&:focus::-ms-fill-upper': {
      background: cssVariable.grey300,
    },
  },
  progressWrapper: {
    position: 'absolute',
    zIndex: -1,
    bottom: 15,
    left: 15,
    height: 8,
    width: 'calc(100% - 40px)',
    background: cssVariable.grey200,
    borderRadius: 2,
  },
  progress: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    height: 8,
    backgroundColor: cssVariable.green500,
    borderRadius: 2,
  },
};
