import { connect } from 'react-redux';
import { getCurrentOnDemandClip, getCurrentStation } from 'store/reducers/player';
import { isEmpty } from 'util/index';

import PlayerNowPlaying from './PlayerNowPlaying';

const mapStateToProps = (state) => {
  const station = getCurrentStation(state);
  const clip = getCurrentOnDemandClip(state);

  let slug = null;

  let imageUrl = null;
  let logo = null;
  let brandColor = null;
  let title = null;
  let subtitle = null;

  let additionalFetchContentTypeId = null;
  let contentTypeId = null;

  let pageUrl = null;

  if (!isEmpty(station)) {
    contentTypeId = 'station';
    title = station.title;
    subtitle = station.playouts;
    slug = station.slug;
    imageUrl = (!isEmpty(station.image) && station.image.url) || null;
    logo = (!isEmpty(station.logo) && station.logo.url) || null;
    brandColor = station.brandSlug !== 'regionaal' && station.brandColor;
    additionalFetchContentTypeId = 'schedules';
  }

  if (!isEmpty(clip)) {
    slug = clip.id;
    imageUrl = clip.imageUrl;
    title = clip?.pageName || clip?.mainPodcastTitle;
    subtitle = clip.name;
    pageUrl = clip?.reference?.slug || clip?.pageUrl;
    contentTypeId = clip.contentTypeId;
  }

  return {
    slug,
    imageUrl,
    logo,
    brandColor,
    title,
    subtitle,
    additionalFetchContentTypeId,
    contentTypeId,
    pageUrl,
  };
};

export default connect(mapStateToProps)(PlayerNowPlaying);
