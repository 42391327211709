import * as cssVariable from 'cssVariables';

export default (theme) => ({
  button: {
    flex: 'none',
    position: 'relative',
    padding: 0,
    background: 'transparent',
    borderRadius: '50%',
    transform: 'scale(1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 200ms ease',
    border: 'none',
    transitionProperty: 'background-color',
    '&:hover, &:focus': {
      backgroundColor: cssVariable.transparentDarkmode030,
    },
    color: cssVariable.white,
    cursor: 'pointer',
  },
  primary: {
    border: 'none',
    ...cssVariable.backgroundGradient,
    transitionProperty: 'transform, box-shadow',
    backfaceVisibility: 'hidden',
    '&:after': {
      ...cssVariable.fillAspectRatio,
      content: '""',
      borderRadius: 'inherit',
      zIndex: -1,
      ...cssVariable.backgroundGradient,
      filter: 'blur(9px)',
      opacity: 0,
      transition: 'opacity 200ms ease',
    },
    '&:hover': {
      outline: 'none',
      transform: 'scale(1.04)',
      boxShadow: `0 0 20px ${cssVariable.transparentGreen500}`,
      ':after': {
        opacity: 0.7,
      },
    },
  },
  buttonWhite: {
    backgroundColor: cssVariable.white,
    '& > svg': {
      color: cssVariable.green500,
    },
    '&:hover': {
      outline: 'none',
      transform: 'none',
      boxShadow: 'none',
      ':after': {
        opacity: 1,
      },
    },
    '&:hover, &:focus': {
      backgroundColor: cssVariable.white,
    },
  },
  buttonTransparent: {
    padding: 0,
    color: cssVariable.white,
    backgroundColor: cssVariable.transparentLightmode040,
    borderRadius: '50%',
    border: 'none',
    '&:hover, &:focus': {
      backgroundColor: cssVariable.white,
      '& > svg': {
        color: cssVariable.green500,
        '& polygon': {
          fill: cssVariable.green500,
        },
      },
    },
  },

  [theme.breakpoints.down('sl')]: {
    primary: {
      '&:hover': {
        transform: 'none',
      },
    },
  },
});
