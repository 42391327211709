import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import * as analytics from 'analytics';

const linkWithAnalytics = (pathname, analyticsData) => ({
  pathname,
  state: {
    analytics: analyticsData,
  },
});

const getPath = (pageLink) => {
  if (pageLink.slug === 'home') {
    return '/';
  }
  if (pageLink.parentSlug) {
    return `/${pageLink.parentSlug}/${pageLink.slug}`;
  }
  return `/${pageLink.slug}`;
};

const NavigationLink = ({ children = {}, pageUrl, pageLink = null, source = '', title, target = null, ...rest }) => {
  const generateAnalyticsData =
    source === 'CallToActionBlock' ? analytics.generateCTAClickData : analytics.generateMenuSelectData;
  // handle juke pages setuped in contentful
  if (pageLink) {
    const pathname = getPath(pageLink);
    return (
      <NavLink
        to={linkWithAnalytics(pathname, generateAnalyticsData(title, pathname, source))}
        exact={pageLink.slug === 'home'}
        {...rest}
      >
        {children}
      </NavLink>
    );
  }
  // handle external links
  if (pageUrl.match('^(https|http)')) {
    return (
      <a
        href={pageUrl}
        onClick={() => analytics.trackEvent(generateAnalyticsData(title, pageUrl, source))}
        target={target || '_blank'}
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </a>
    );
  }
  // handle hash
  const hasHash = pageUrl.indexOf('#') !== -1;
  if (hasHash) {
    return (
      <a
        href={pageUrl}
        onClick={() => analytics.trackEvent(generateAnalyticsData(title, pageUrl, source))}
        target="_self"
        rel="nofollow"
        {...rest}
      >
        {children}
      </a>
    );
  }
  // handle juke pages NOT setuped in contentful
  const url = pageUrl.indexOf('/') === 0 ? pageUrl.toLowerCase() : `/${pageUrl.toLowerCase()}`;
  return (
    <NavLink to={linkWithAnalytics(url, generateAnalyticsData(title, url, source))} exact={pageUrl === '/'} {...rest}>
      {children}
    </NavLink>
  );
};

NavigationLink.propTypes = {
  pageUrl: PropTypes.string,
  pageLink: PropTypes.object,
  source: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
};

NavigationLink.defaultProps = {
  pageUrl: '',
  pageLink: null,
  source: '',
  title: null,
  target: null,
};

export default NavigationLink;
