import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import SingleNavigationItem from '../NavigationItem';
import { isEmpty } from 'util/index';
import { createUseStyles } from 'react-jss';

import styles from './NavigationItems.styles';

const useStyles = createUseStyles(styles, { name: 'NavigationItems' });
const NavigationItems = ({
  navigationItems,
  location,
  hasSmallDesktopMenu,
  isCollapsible,
  navIsExpanded,
  isDesktopApp,
  onItemClick,
}) => {
  const classes = useStyles();

  const getFilteredNavigationItems = (navigationItems) => {
    let filteredNavigationItems = navigationItems;

    if (isDesktopApp) {
      filteredNavigationItems = navigationItems.filter((item) => {
        return item?.pageLink?.slug !== 'apparaten';
      });
    }

    return filteredNavigationItems.map((navItem, index) => {
      return (
        <li key={`main-nav-${navItem.id}-${index}`}>
          <SingleNavigationItem
            hasSmallDesktopMenu={hasSmallDesktopMenu}
            navIsExpanded={navIsExpanded}
            location={location}
            isCollapsible={isCollapsible}
            onItemClick={onItemClick}
            {...navItem}
          />
        </li>
      );
    });
  };
  return <ul className={classes.list}>{!isEmpty(navigationItems) && getFilteredNavigationItems(navigationItems)}</ul>;
};

NavigationItems.defaultProps = {
  hasSmallDesktopMenu: false,
  navIsExpanded: false,
  isDesktopApp: false,
};

NavigationItems.propTypes = {
  navigationItems: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  hasSmallDesktopMenu: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  navIsExpanded: PropTypes.bool,
  isDesktopApp: PropTypes.bool,
  onItemClick: PropTypes.func,
};

// We wrap the NavigationItems in withRouter so that the location property becomes available
// When the location prop changes, the navigation items are re-rendered and become active/
export default withRouter(NavigationItems);
