import { trackMenuClick, navigationEvents } from 'analytics';
import { navigationActions } from './actions-const';
import { setBodyScroll } from '../util/noscroll';
import { Action } from 'redux';
import { AppDispatch, RootState } from 'store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setNavigation(navigationItems: any[]): Action<string> & { payload: any[] } {
  return {
    type: navigationActions.SET_NAVIGATION,
    payload: navigationItems,
  };
}

export function openNavigation(): Action<string> {
  setBodyScroll(false);
  return {
    type: navigationActions.OPEN_NAVIGATION,
  };
}

export function closeNavigation(): Action<string> {
  setBodyScroll(true);
  return {
    type: navigationActions.CLOSE_NAVIGATION,
  };
}

export function toggleNavigation(source: string) {
  return (dispatch: AppDispatch, getState: () => RootState): void => {
    const {
      navigation: { isOpen },
    } = getState();
    if (isOpen) {
      trackMenuClick(navigationEvents.closeMenu, source);
      dispatch(closeNavigation());
    } else {
      trackMenuClick(navigationEvents.openMenu, source);
      dispatch(openNavigation());
    }
  };
}

export function setScrollHideNavigation(): Action<string> {
  return {
    type: navigationActions.SET_SCROLL_HIDE_NAVIGATION,
  };
}

export function setScrolShowNavigation(): Action<string> {
  return {
    type: navigationActions.SET_SCROLL_SHOW_NAVIGATION,
  };
}

export function setHasMobileHeader(hasMobileHeader: boolean) {
  return (dispatch: AppDispatch): void => {
    dispatch({
      type: navigationActions.SET_HAS_MOBILE_HEADER,
      hasMobileHeader,
    });
  };
}

//@typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setMobileLightNavigation(navigationItems: any[]): Action<string> & { payload: any[] } {
  return {
    type: navigationActions.SET_MOBILE_LIGHT_NAVIGATION,
    payload: navigationItems,
  };
}
