import * as cssVariable from 'cssVariables';

export default {
  container: {
    padding: [7, 12],
    backgroundColor: cssVariable.grey600,
    borderRadius: 4,
    textAlign: 'left',
  },
  title: {
    margin: 0,
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.white,
    letterSpacing: -0.4,
  },
  lightPlayout: {
    padding: 6,
    textAlign: 'center',
    fontWeight: cssVariable.fontWeights.light,
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.grey600,
    textTransform: 'capitalize',
    '& p': {
      margin: 0,
    },
  },
  lightPlayoutTitle: {
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.mediumLarge,
  },
};
