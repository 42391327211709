export const breakpoints = {
  xs: 480, // extraSmall
  sm: 768, // small
  md: 900, // smallMedium
  sl: 1025, // medium
  ml: 1280, // large
  ll: 1440, // extraLarge
  lg: 1680, // extraExtraLarge
  k: 1920, // 1080p
  kk: 2048, // 2k
  kkk: 2880, // 3k
  kkkk: 3840, // 4k
};
export default {
  values: breakpoints,
};

export const mediaQuery = {
  maxWidth: (width) => `@media (max-width: ${width}px)`,
  minWidth: (width) => `@media (min-width: ${width}px)`,
  betweenWidth: (minWidth, maxWidth) => `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`,
};
