import { trackingActions } from '../../actions/actions-const';

const initialState = {
  userOnJuke: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case trackingActions.USER_ON_JUKE:
      return {
        ...state,
        userOnJuke: true,
      };

    case trackingActions.USER_NOT_ON_JUKE:
      return {
        ...state,
        userOnJuke: false,
      };

    default:
      return state;
  }
}

/**
 * Given the current state, it returns nowPlayingStationsTimer
 *
 * @param {object} state
 * @returns {object}
 */
export const isUserOnJuke = (state) => state.trackers.userOnJuke;
