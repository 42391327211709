import deepMerge from 'deepmerge';

export function createPalette(options = {}) {
  return deepMerge(
    {
      default: {
        white: '#ffffff',
        black: '#000000',
      },
    },
    options,
    {
      clone: false,
    }
  );
}
