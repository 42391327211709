export { default } from './NotificationContainer';
export const showNotification = {
  type: 'SHOW_NOTIFICATION',
  notification: null,
};

export const ClearNotification = {
  type: 'CLEAR_NOTIFICATION',
  notificationId: null,
};

export const NotificationTypes = {
  NOTIFICATION: 'NOTIFICATION',
  STICKY: 'STICKY',
  ERROR: 'ERROR',
};

export const NotificationTexts = {
  NO_INTERNET: 'Er is op dit moment geen internet verbinding beschikbaar',

  STATION_ADD_TO_COLLECTION: 'Zender is toegevoegd aan Mijn JUKE.',
  STATION_ADD_TO_COLLECTION_ERROR: 'Kon zender niet toevoegen aan Mijn JUKE.',
  STATION_REMOVE_FROM_COLLECTION: 'Zender is verwijderd uit Mijn JUKE.',
  STATION_REMOVE_FROM_COLLECTION_ERROR: 'Kon zender niet verwijderen uit Mijn JUKE.',

  TRACK_ADD_TO_COLLECTION: 'Nummer is toegevoegd aan Mijn JUKE.',
  TRACK_ADD_TO_COLLECTION_ERROR: 'Kon nummer niet toevoegen aan Mijn JUKE.',
  TRACK_REMOVE_FROM_COLLECTION: 'Nummer is verwijderd uit Mijn JUKE.',
  TRACK_REMOVE_FROM_COLLECTION_ERROR: 'Kon nummer niet verwijderen uit Mijn JUKE.',

  LOGOUT_SUCCESS: 'Je bent succesvol uitgelogd.',

  PLAYBACK_LIVE_VIDEO_FROM_URL: 'Live video gestart',
  PLAYBACK_CLIP_ERROR: 'Het fragment kan niet afgespeeld worden',
  PLAYBACK_STATION_ERROR: 'kan niet afgespeeld worden',
  PLAYBACK_LIVE_VIDEO_ERROR: 'live video kan niet afgespeeld worden',
  PLAYBACK_CASTING_ERROR: 'Deze media kan op dit moment niet gecast worden',
  PLAYBACK_CASTING: 'Je bent nu aan het casten via: ',

  PODCAST_PAGE_RSS_LOAD_ERROR: 'De podcasts konden niet geladen worden.',

  PODCAST_ADD_TO_COLLECTION: 'Podcast is toegevoegd aan Mijn JUKE.',
  PODCAST_ADD_TO_COLLECTION_ERROR: 'Kon podcast niet toevoegen aan Mijn JUKE.',
  PODCAST_REMOVE_FROM_COLLECTION: 'Podcast is verwijderd uit Mijn JUKE.',
  PODCAST_REMOVE_FROM_COLLECTION_ERROR: 'Kon podcast niet verwijderen uit Mijn JUKE.',
  PODCAST_CLIP_ADD_TO_COLLECTION: 'Podcast aflevering is toegevoegd aan Mijn JUKE.',
  PODCAST_CLIP_ADD_TO_COLLECTION_ERROR: 'Kon podcast aflevering niet toevoegen aan Mijn JUKE.',
  PODCAST_CLIP_REMOVE_FROM_COLLECTION: 'Podcast aflevering is verwijderd uit Mijn JUKE.',
  PODCAST_CLIP_REMOVE_FROM_COLLECTION_ERROR: 'Kon podcast aflevering niet verwijderen uit Mijn JUKE.',

  CLIP_ADD_TO_COLLECTION: 'Aflevering is toegevoegd aan Mijn JUKE.',
  CLIP_ADD_TO_COLLECTION_ERROR: 'Kon aflevering niet toevoegen aan Mijn JUKE.',
  CLIP_REMOVE_FROM_COLLECTION: 'Aflevering is verwijderd uit Mijn JUKE.',
  CLIP_REMOVE_FROM_COLLECTION_ERROR: 'Kon aflevering niet verwijderen uit Mijn JUKE.',

  COPY_TO_CLIPBOARD: 'URL is gekopieerd naar klembord',
};
