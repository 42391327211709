export default () => ({
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
    width: '100%',
    height: 25,
  },
});
