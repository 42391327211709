// initialise the preparation of analytics on components/App load
import {
  firstVisitData,
  trackFirstPageView,
  setPersistentData,
  setCustomData,
  taqPersistentData,
  taqPersistentCustomData,
  beforeUnload,
  historyListen,
} from 'analytics';
import { lastActivityExpireTime } from 'globalConst/const';

if (process.env.NODE_ENV === 'development') {
  if (process.env.REACT_APP_TAQ_DEBUG === 'true') {
    document.cookie = `__TA_DEBUG__`;
  } else {
    // Remove cookie if exists
    document.cookie = '__TA_DEBUG__= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

window.Taq = window.Taq || [];
window.Taq.push(['load', 'NoBo']);

firstVisitData();

const lastActivityExpireTimeValue = localStorage.getItem(lastActivityExpireTime);
const currentDateTime = new Date();
if (
  !lastActivityExpireTimeValue ||
  (lastActivityExpireTimeValue && new Date(lastActivityExpireTimeValue) < currentDateTime)
) {
  trackFirstPageView();

  const expireTime = new Date();
  expireTime.setMinutes(expireTime.getMinutes() + 30);
  localStorage.setItem(lastActivityExpireTime, expireTime);
}
historyListen();
beforeUnload();
// Set persistent data
setPersistentData(taqPersistentData);
setCustomData(taqPersistentCustomData, true);
