import { getDateFromTime } from 'util/time';
import ShowEpisode, { UniversalShowEpisode } from './ShowEpisode';
import {
  Image,
  UniversalCollection,
  UniversalCollectionItem,
  UniversalConfig,
  UniversalImage,
  UniversalMedia,
  UniversalRef,
} from '../interfaces';
import CollectionItem from './CollectionItem';
import { isEmpty } from 'util/index';
import Station, { UniversalStation } from './Station';
import logger from 'util/logger';
import { CONFIG_KEY } from 'globalConst/const';

export interface UniversalShow extends UniversalCollectionItem {
  shortTitle: string;
  images: Array<UniversalImage>;
  refs: Array<UniversalRef>;
  config: Array<UniversalConfig>;

  startTime: string;
  endTime: string;
  broadcastStartDate: Date;
  broadcastEndDate: Date;
  episodes: Array<UniversalShowEpisode>;

  station: UniversalStation;

  media: Array<UniversalMedia>;
  references: UniversalCollection[];
}

/**
 * @class
 * A representation of a Show
 *
 * @param {object} show - show model
 */
export default class Show extends CollectionItem {
  name: string;
  subtitle: string;
  image: Image;
  logoSecondary: Image;
  logo: Image;
  headerImage: Image;
  stationSlug: string;

  brandColor: string;
  brandName: string;
  brandSlug: string;

  linkedPage: string;
  hasPage: boolean;

  startTime: string;
  endTime: string;
  broadcastStartDate: Date;
  broadcastEndDate: Date;

  episodes: Array<ShowEpisode>;

  tile: {
    title: string;
    altText: string;
    image: Image;
  };
  station: Station;

  constructor(item: UniversalCollectionItem) {
    super(item);

    const show = <UniversalShow>item;

    this.name = show.title;
    this.subtitle = show.shortTitle;

    if (show.images && Array.isArray(show.images)) {
      show.images.forEach(({ imageType, uri, title }) => {
        switch (imageType) {
          case 'image':
            this.image = {
              url: uri,
              altText: title || this.title,
            };
            break;
          case 'logo':
            this.logoSecondary = {
              url: uri,
              altText: title || this.title,
            };
            break;
          case 'logoWhite':
            this.logo = {
              url: uri,
              altText: title || this.title,
            };
            break;
          default:
            logger.warn(`Show missing image: ${imageType}`);
            break;
        }
      });
    }
    if (!this.headerImage && this.image) {
      this.headerImage = this.image;
    }

    if (show.refs && Array.isArray(show.refs)) {
      show.refs.forEach(({ targetType, uri }) => {
        switch (targetType) {
          case 'station':
            this.stationSlug = uri;
            break;
          default:
            break;
        }
      });
    }

    // In case of non-deprecated fields, refs will be replaced with references
    // This is support for references
    if (show.references && Array.isArray(show.references)) {
      show.references.forEach(({ type, slug }) => {
        switch (type) {
          case 'station':
            this.stationSlug = slug;
            break;
          default:
            break;
        }
      });
    }

    if (show.config && Array.isArray(show.config)) {
      show.config.forEach(({ entries }) =>
        entries.forEach(({ key, value }) => {
          // No brand Color for juke JUKE Originals
          if (key === CONFIG_KEY.BRAND_NAME && value === 'JUKE Originals') {
            this.brandColor = 'rgba(0,0,0,0)';
          }
          switch (key) {
            case CONFIG_KEY.BRAND_NAME:
              this.brandName = value;
              break;
            case CONFIG_KEY.BRAND_SLUG:
              this.brandSlug = value;
              this.linkedPage =
                value === this.stationSlug || !this.stationSlug
                  ? `/radiozenders/${value}`
                  : `/radiozenders/${value}/${this.stationSlug}`;
              break;
            case CONFIG_KEY.BACKGROUND_COLOR:
              this.brandColor = value;
              break;
            case CONFIG_KEY.MAIN_STATION_SLUG:
              break;
            default:
              logger.warn(`Show missing config: ${key}`);
              break;
          }
        })
      );
      this.hasPage = !isEmpty(this.brandSlug) && !isEmpty(this.stationSlug);
    }

    this.startTime = show.startTime;
    this.endTime = show.endTime;
    this.broadcastStartDate = show.broadcastStartDate;
    this.broadcastEndDate = show.broadcastEndDate;

    if (!show.broadcastStartDate) {
      this.broadcastStartDate = getDateFromTime(show.startTime);
    }

    if (!show.broadcastEndDate && show.startTime && show.endTime) {
      const [startHours] = show.startTime.split(':');
      const [endHours] = show.endTime.split(':');
      // Validate whether or not end time is after midnight
      const nextDay = +endHours < +startHours;
      this.broadcastEndDate = getDateFromTime(show.endTime, nextDay);
    }

    this.episodes =
      show.episodes &&
      show.episodes.map(
        (episode) =>
          new ShowEpisode({
            ...episode,
            brandColor: this.brandColor,
            image: this.image,
            logo: this.logoSecondary,
            logoWhite: this.logo,
          })
      );

    this.tile = {
      title: show.title,
      altText: show.title,
      image: this.image,
    };

    const showStation = new Station(show.station);

    this.station = showStation || {
      slug: this.stationSlug,
      title: this.brandName,
      brandName: this.brandName,
      brandColor: this.brandColor,
      linkedPage: this.linkedPage,
      hasPage: this.hasPage,
      image: this.image,
      logoSecondary: this.logoSecondary,
      logo: this.logo,
      type: 'generic',
    };

    // This is probably not needed anymore. show.service is loaded ref stations
    if (!show.station && show.media && Array.isArray(show.media)) {
      show.media.forEach(({ source, uri }) => {
        switch (source) {
          case 'mount_point':
            this.station.tritonMount = uri;
            this.station.type = 'triton';
            break;
          case 'mount_point_hd':
            this.station.tritonMountHD = uri;
            break;
          case 'audio_mp3':
            this.station.audioUrl = uri;
            break;
          case 'audio_aac':
            this.station.audioUrlAac = uri;
            break;
          case 'audio_hls':
            this.station.audioUrlHls = uri;
            break;
          case 'video_hls':
            this.station.videoUrl = uri;
            break;
          default:
            logger.warn(`Show missing source: ${source}`);
            break;
        }
      });
    }

    // Make this class read-only
    Object.freeze(this);
  }
}
