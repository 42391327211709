import React from 'react';
import { FOOTER_ID } from 'globalConst/const';
import styles from './Footer.styles';
import { createUseStyles } from 'react-jss';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { FOOTER_NAVIGATION } from 'globalConst/const';
import ContentTypeComponent from 'components/ContentTypeComponent';

const useStyles = createUseStyles(styles, { name: 'Footer' });
const Footer = () => {
  const classes = useStyles();

  return (
    <footer id={FOOTER_ID}>
      <nav className={classes.footerContainer}>
        <ContentTypeComponent contentTypeId={CONTENT_TYPES.OVERVIEW_COLLECTION} slug={FOOTER_NAVIGATION} />
      </nav>
    </footer>
  );
};

export default Footer;
