import React from 'react';

import PropTypes from 'prop-types';
import Playouts from './Playouts';
import PlayerButton from '../PlayerButton';
import RecentlyPlayedIcon from 'components/svg/RecentlyPlayed';
import styles from './PlayerRecentlyPlayed.styles';
import { injectStyles } from 'injectStyles';

import { isEmpty } from 'util/index';

const PlayerRecentlyPlayed = ({ classes, currentStation }) => {
  if (isEmpty(currentStation)) return null;

  const renderPopout = (closeBtn) => {
    return (
      <div data-testid="player-bottom-playouts" className={classes.recentlyPlayed}>
        {closeBtn}
        <Playouts slug={currentStation.slug} contentTypeId="playouts" />
      </div>
    );
  };

  const renderIcon = () => {
    return <RecentlyPlayedIcon />;
  };

  return (
    <PlayerButton
      data-testid="player-bottom-recently-played-btn"
      title="Afspeellijst"
      renderIcon={renderIcon}
      renderPopout={renderPopout}
      hasPopout
      hideOnSmall
    />
  );
};

PlayerRecentlyPlayed.defaultProps = {
  currentStation: null,
};

PlayerRecentlyPlayed.propTypes = {
  classes: PropTypes.object.isRequired,
  currentStation: PropTypes.object,
};

export default injectStyles(styles)(PlayerRecentlyPlayed);
