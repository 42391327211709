import { getStore } from 'store';

export default function trackVolume(volumeLevel) {
  const { isPlaying, isCasting } = getStore().getState().player;

  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'juke-player_volume',
      event_category: 'juke-player',
      event_action: 'volume',
      player_state: isPlaying ? 'stop' : 'play',
      event_label: 'volume',
      event_value: volumeLevel || null,
      player_casting: isCasting ? 'chromecast' : false,
    },
  ]);
}
