import HttpError from 'lib/HttpError';
import { apiSelector } from 'components/ApiSwitch/ApiSwitch.const';
import getEnvironmentVariable from '../util/getEnviromentVariable';
import logger from 'util/logger';
import { reportFailedAPIRequest } from 'metrics';
import { HTTP_STATUS_CODE } from 'globalConst/const';

const staticsBaseUrl = getEnvironmentVariable('STATICS_BASE_URL', apiSelector.JUKE_API);
const FIVE_MINUTES = 300 * 1000;

function throwOnError(response) {
  if (!response.ok) {
    throw new HttpError(response.status, response.statusText);
  }
}

async function getJSON(path) {
  try {
    const response = await fetch(`${staticsBaseUrl}${path}`);
    if (response.status === HTTP_STATUS_CODE.NOT_FOUND) {
      reportFailedAPIRequest('api request error - content', response?.variables, response?.url);
    }
    throwOnError(response);
    return response.json();
  } catch (error) {
    logger.error('Something went wrong while downloading and indexing content.', error);
  }
  return [];
}

function getTimestamp(ms) {
  const now = Date.now();
  const floor = now - (now % ms);
  const ceil = floor + ms;
  return ceil;
}

export const fetchPages = () => getJSON(`/content/pages.json?preventCache=${getTimestamp(FIVE_MINUTES)}`);
