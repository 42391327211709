import { ellipsis, fillAspectRatio, fontSizes, fontWeights, onAirBadgeStyles } from 'cssVariables';
import { StyleTheme } from 'components/theme/StyleTheme';

import { Styles } from 'jss';

export default (theme: StyleTheme): Styles => ({
  placeholder: {
    width: '100%',
    height: 0,
    paddingBottom: '100%',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      borderRadius: '100%',
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.backgroundSecondary,
    },
  },
  link: {
    textDecoration: 'none',
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  hoverAnimation: {
    ...fillAspectRatio,
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  tile: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
  },
  horizontalTile: {
    minHeight: 110,
    minWidth: 110,
    paddingTop: 0,
    marginRight: 20,
  },
  imageWrapper: {
    borderRadius: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 10,
    left: 10,
    right: 10,
    bottom: 10,
  },
  onAirBadgeWrapper: {
    position: 'absolute',
    zIndex: 2,
    textAlign: 'center',
    left: 0,
    bottom: 20,
    width: '100%',
    height: 28,
  },
  onAirBadgeWrapperSmall: {
    bottom: 4,
  },
  onAirBadge: {
    ...onAirBadgeStyles,
  },
  onAirBadgeSmall: {
    padding: '4px 4px',
    fontSize: fontSizes.xxxsmall,
  },
  image: {
    borderRadius: '100%',
    position: 'relative',
    zIndex: 0,
    height: '100%',
    width: '100%',
  },
  info: {
    textAlign: 'center',
  },
  infoHorizontal: {
    flexGrow: 1,
    flexDirection: 'row',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    margin: 0,
    fontWeight: fontWeights.bold,
    fontSize: fontSizes.small,
    color: theme.palette.text.primary,
    lineHeight: '22px',
    marginBottom: 4,
    ...ellipsis,
    whiteSpace: 'pre-wrap',
  },
  logoWrapper: {
    height: 38,
    padding: [5, 0],
    '& $title': {
      fontSize: fontSizes.xsmall,
      lineHeight: '38px',
    },
  },
  logo: {
    height: 28,
    margin: 'auto',
  },
  airTime: {
    margin: 0,
    fontWeight: fontWeights.regular,
    fontSize: fontSizes.small,
    color: theme.palette.text.secondary,
  },
});
