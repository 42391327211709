import { playbackModes } from 'store/reducers/player';
import { popoutActions } from './actions-const';
import { loadOnDemandClip, loadStation, trackOnDemandPlaybackTime, loadLiveVideo } from './player-actions';
import { Station } from 'api/models';
import { Action } from 'redux';
import { UniversalClip } from 'api/interfaces';
import { AppDispatch, RootState } from 'store';

export function setStationInPopout(
  station: Station,
  playbackMode: string
): Action<string> & { station: Station; playbackMode: string } {
  return {
    type: popoutActions.POPOUT_SET_STATION,
    station,
    playbackMode,
  };
}

export function setClipInPopout(
  clip: UniversalClip,
  playbackTime: number
): Action<string> & { clip: UniversalClip; playbackTime: number } {
  return {
    type: popoutActions.POPOUT_SET_CLIP,
    clip,
    playbackTime,
  };
}

export function setPlaybackTimeInPopout(playbackTime: number): Action<string> & { playbackTime: number } {
  return {
    type: popoutActions.POPOUT_SET_PLAYBACK_TIME,
    playbackTime,
  };
}

export function setPlaybackDurationInPopout(playbackDuration: number): Action<string> & { playbackDuration: number } {
  return {
    type: popoutActions.POPOUT_SET_PLAYBACK_DURATION,
    playbackDuration,
  };
}

export function setIsPopoutPlayerActive(
  popoutPlayerIsActive: boolean
): Action<string> & { isPopoutPlayerActive: boolean } {
  return {
    type: popoutActions.TOGGLE_POPOUT_PLAYER,
    isPopoutPlayerActive: popoutPlayerIsActive,
  };
}

export function playMediaInPopout() {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      popout: { playbackMode, station, clip, playbackTime },
    } = getState();
    if (station) {
      const load = playbackMode === playbackModes.LIVE_VIDEO ? loadLiveVideo : loadStation;
      dispatch(load(station));
    }
    if (clip) {
      if (playbackTime) {
        dispatch(trackOnDemandPlaybackTime(playbackTime));
      }
      dispatch(loadOnDemandClip(clip));
    }
  };
}
