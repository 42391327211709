import { DisplayLayout } from 'components/Collection/Collection.const';
import { Config, UniversalCollection, UniversalConfig } from 'api/interfaces';
import { contains, isEmpty, parseConfig } from 'util/index';
/**
 * @class
 * A representation of collection config
 * It contains all required data fetched from config
 *
 * @param {object} collection - collection model
 * @param {object} sectionConfig - universal api config
 * @param {object} ownProps - collection props
 */
export default class CollectionConfig {
  display: string = DisplayLayout.CAROUSEL;
  rows: number = undefined;
  variationKey: string;
  showNumbers?: boolean;

  config: Config;

  constructor(collection: UniversalCollection, config: UniversalConfig[]) {
    if (isEmpty(collection)) {
      return undefined;
    }

    // collection.config will be provided if its experiments
    this.config = {
      ...parseConfig(config),
      ...parseConfig(collection.config),
    };

    this.variationKey = this.config.VariationKey;

    const presentationType = this.config.PresentationType;

    if (presentationType && contains(Object.values(DisplayLayout), presentationType, true)) {
      this.display = presentationType;
    }

    if (contains([DisplayLayout.LIST, DisplayLayout.GRID], presentationType, true)) {
      this.display = DisplayLayout.TILES;
    }
    this.showNumbers = this.config?.ListNumber === 'true' ? true : false;

    const rows = this.config.Rows;
    if (!isNaN(+rows)) {
      this.rows = Math.max(+rows, 1);
    }
  }
}
