import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="nonzero">
      <g transform="translate(-18.000000, -328.000000)">
        <g transform="translate(10.701569, 319.701569)">
          <g
            transform="translate(16.500000, 16.500000) rotate(-40.000000) translate(-16.500000, -16.500000) translate(5.000000, 5.000000)"
            stroke="currentColor"
          >
            <path
              d="M4,11.5 C4,15.6421356 7.35786438,19 11.5,19 C15.6421356,19 19,15.6421356 19,11.5 C19,7.35786438 15.6421356,4 11.5,4"
              strokeWidth="0.75"
              strokeLinecap="round"
              transform="translate(11.500000, 11.500000) rotate(-40.000000) translate(-11.500000, -11.500000) "
            />
            <path
              d="M6.75297624,6.8363229 L4.84797624,8.0473229 C4.78397624,8.0873229 4.69897624,8.0693229 4.65797624,8.0053229 C4.64497624,7.9833229 4.63697624,7.9573229 4.63697624,7.9313229 L4.63697624,5.5093229 C4.63697624,5.4333229 4.69797624,5.3723229 4.77397624,5.3723229 C4.79997624,5.3723229 4.82597624,5.3793229 4.84797624,5.3933229 L6.75297624,6.6043229 C6.81697624,6.6453229 6.83597624,6.7303229 6.79497624,6.7943229 C6.78397624,6.8113229 6.76997624,6.8253229 6.75297624,6.8363229"
              id="Fill-7"
              strokeWidth="0.15"
              fill="currentColor"
              transform="translate(5.726803, 6.720428) rotate(134.000000) translate(-5.726803, -6.720428) "
            />
          </g>
          <path
            d="M14.4174499,13.9642122 C14.3773969,13.9441857 14.3332313,13.9337596 14.2884508,13.9337596 C14.1291438,13.9337596 14,14.0629034 14,14.2222104 L14,19.0577796 C14,19.1025602 14.0104261,19.1467258 14.0304526,19.1867787 C14.1016968,19.3292672 14.2749614,19.3870221 14.4174499,19.3157779 L19.2530191,16.8979932 C19.3088423,16.8700817 19.3541067,16.8248173 19.3820183,16.7689941 C19.4532625,16.6265056 19.3955076,16.453241 19.2530191,16.3819968 L14.4174499,13.9642122 Z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default svgIcon(svg);
