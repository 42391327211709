import * as cssVariable from 'cssVariables';

export default (theme) => ({
  fold: {
    overflow: 'hidden',
    transition: 'max-height 0.3s ease-out',
    maxHeight: 0,
    margin: `0 -${cssVariable.gutter}px`,
  },
  unfolded: {
    maxHeight: 800,
  },
  container: {
    position: 'relative',
    borderTop: `1px solid ${cssVariable.grey300}`,
    padding: 30,
    marginTop: 20,
    backgroundColor: cssVariable.grey100,
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 5,
    top: 5,
  },
  pageLink: {
    position: 'absolute',
    top: 25,
    right: 30,
    textDecoration: 'none',
    color: cssVariable.green500,
    fontWeight: cssVariable.fontWeights.bold,
    padding: '10px 15px',
    border: `1px solid ${cssVariable.white}`,
    borderRadius: 4,
    backgroundColor: cssVariable.white,
    '&:hover': {
      color: cssVariable.black,
    },
  },
  [theme.breakpoints.down('xs')]: {
    pageLink: {
      position: 'relative',
      padding: '10px 15px',
      width: '100%',
      fontSize: cssVariable.fontSizes.xsmall,
      color: cssVariable.black,
      top: 14,
      right: 0,
      display: 'inline-block',
    },
    pageLinkWrapper: {
      textAlign: 'center',
    },
  },
});
