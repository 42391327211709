import { connect } from 'react-redux';
import { showNotification } from 'actions/notifications-actions';
import { NotificationTypes, NotificationTexts } from 'components/Notifications/Notification';

import { getHasMobileHeader } from 'store/reducers/navigation';

import SocialShare from './SocialShare';
import { State } from 'redux';

const mapStateToProps = (state: State) => {
  return {
    hasMobileHeader: getHasMobileHeader(state),
  };
};

const mapDispatchToProps = {
  showNotification: () =>
    showNotification({
      type: NotificationTypes.NOTIFICATION,
      message: NotificationTexts.COPY_TO_CLIPBOARD,
    }),
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialShare);
