import { AppDispatch, RootState } from 'store';
import { healthyPlayActions } from './actions-const';
import { logger } from 'util/index';

export function trackHealthyPlayStation(playSessionId: string) {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      player: { currentStation },
    } = getState();

    if (!currentStation) {
      logger.warn('CurrentStation should exists while reporting healthy station play.');
      return;
    }

    dispatch({
      type: healthyPlayActions.HEALTHY_PLAY_STATION,
      playSessionId,
      station: currentStation,
    });
  };
}

export function trackHealthyPlayOnDemand(playSessionId: string) {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      player: { currentOnDemandClip },
    } = getState();

    if (!currentOnDemandClip) {
      logger.warn('currentOnDemandClip should exists while reporting healthy ondemand play.');
      return;
    }

    dispatch({
      type: healthyPlayActions.HEALTHY_PLAY_ON_DEMAND_CLIP,
      playSessionId,
      onDemandClip: currentOnDemandClip,
    });
  };
}
