export const BUTTON_IN_HEADER_ID = 'buttonInHeader';
export const SCROLL_CONTAINER_ID = 'scrollContainer';
export const FOOTER_ID = 'footer';
export const CONTENT_CONTAINER = 'contentContainer';
export const SEARCH_CONTAINER_ID = 'searchContainer';
export const MODAL_CONTAINER_ID = 'modal-portal-root';
export const PLAYOUT_PORTAL_ID = 'playout-portal-id';
export const SHARE_MENU_ID = 'share-portal-root';
export const TABS_HEADER_PORTAL_ID = 'tabs-header-portal-id';
export const PLAYER_BOTTOM_VIDEO_BTN_ID = 'video-btn-id';

export const PRESENTATION_TYPES = ['swimlane', 'grid', 'list'];

export const TALPA_RADIO_EMBED_DOMAINS = ['radio10.nl/app', '538.nl/app', 'radioveronica.nl/app', 'skyradio.nl/app'];

// slugs
export const FOOTER_NAVIGATION = 'juke-web-footer-navigation';

export const PAGE_TYPE = {
  BRAND: '/radiozenders/:slug',
  STATION: '/radiozenders/:slug/:subSlug',
  STATION_OVERVIEW: '/radiozenders',
  NON_STATION_BRAND: '/brand/:slug',
  NON_STATION_BRAND_OVERVIEW: '/brand',
  CAMPAIGN_PAGE: '/radiozenders/:slug/acties/:campaignSlug',
  GENRE: '/muziekgenres/:slug',
  MOODS: '/moods-en-momenten/:slug',
  PODCAST_GENRE: '/podcasts/genres/:slug',
  PODCAST: '/podcasts/:slug',
  PODCAST_EPISODE_PAGE: '/podcasts/:slug/:subSlug',
};

export const URL_PARAM = {
  SEARCH: '?search=',
};

export const URL_PARAM_KEY = {
  SEARCH: 'search',
};

export const STATION_PAGE_SETTINGS = {
  pageType: 'station',
  showAddToMyJukeButton: true,
};

export const CONFIG_KEY = {
  BRAND_NAME: 'BrandName',
  BRAND_SLUG: 'BrandSlug',
  BACKGROUND_COLOR: 'BackgroundColor',
  MAIN_STATION_SLUG: 'MainStationSlug',
};

export const TAB_TYPES = {
  ANCHOR: 'tabs-anchor',
  INDEXABLE: 'tabs-indexable',
};

export const GENRE = 'genre';
export const MOOD = 'mood';
export const BRAND = 'brand';
export const CLIP = 'clip';
export const PODCAST_GENRE = 'podcast category';
export const MOODS_EN_MOMENTEN = 'moods-en-momenten';
export const MUZIEKGENRES = 'muziekgenres';
export const RADIOZENDERS = 'radiozenders';

export const FAVORITE_ALL_PODCASTS_SLUG = 'juke-favorite-podcasts';
export const FAVORITE_ALL_STATIONS_SLUG = 'juke-favorite-stations';
export const FAVORITE_ALL_CLIPS_SLUG = 'juke-favorite-clips';
export const ALL_STATIONS_SLUG = 'all-stations-juke-generated';
// export const ALL_CLIP_COLLECTION_SLUG = 'editorial-clip-collection';
// ACCEPTANCE ENVIRONMENT ONLY:
export const ALL_CLIP_COLLECTION_SLUG = 'all-clips-juke-generated';
export const CLIPS_IN_PROGRESS_SLUG = 'clips-in-progress';
export const ALL_GENRES_SLUG = 'all-genres-juke-generated';
export const ALL_MOODS_SLUG = 'all-moods-juke-generated';
export const ALL_PODCASTS_SLUG = 'all-podcasts-juke-generated';
export const SHOWS_ON_AIR_SLUG = 'shows-on-air-generated';
export const ALL_SHOWS_SLUG = 'all-shows-juke-generated';

export const MOBILE_HEADER_HEIGHT = 175;
export const COLORED_HEADER_HEIGHT = 230;

export const TILE_SIZES = {
  SMALL: 'small',
  SMALL_WIDTH: 'smallWidth',
  MEDIUM: 'medium',
  LARGE: 'large',
  MEDIUM_LARGE: 'mediumLarge',
  EXTRA_LARGE: 'extraLarge',
};

export const MIN_TILE_SIZES = {
  small: [130, 90],
  smallWidth: [290, 290],
  medium: [180, 105],
  large: [220, 130],
  mediumLarge: [650, 300],
  extraLarge: [900, 900],
};

export const BUTTON_SIZE = {
  medium: 34,
};

export const TITLE = {
  POPULAR_SEARCH: 'Populaire zoekopdrachten',
  RECENT_SEARCH: 'Recente zoekopdrachten',
};

export const LABEL = {
  DELETE_ALL: 'Wis alles',
  HIDE: 'Lees minder',
  SHOW_MORE: 'Lees meer',
  TODAY: 'Vandaag',
  YESTERDAY: 'Gisteren',
  NEW: 'NIEUW',
  ADD_TO_FAVORITES: 'Toevoegen',
};

export const STATION_PAGE_TITLE = 'Radiozenders';
export const BRAND_PAGE_TITLE = 'Brands';
export const GENRES_PAGE_TITLE = 'Muziekgenres';
export const MOODS_PAGE_TITLE = 'Moods & Momenten';
export const PODCASTS_PAGE_TITLE = 'Podcasts';
export const FEEDBACK_PAGE_TITLE = 'Feedback';
export const CONTACT_PAGE_TITLE = 'Contact';
export const VRAGEN_PAGE_TITLE = 'Veelgestelde vragen';
export const ERROR_PAGE_TITLE = 'Fout opgetreden';
export const PODCAST_TEASER_TITLE = 'Nieuw beschikbaar';
export const EPISODE_DETAIL_PAGE_TITLE = 'Episode detail page';

export const NO_PLAYOUTS_MESSAGE = 'Voor deze zender is helaas geen playlist beschikbaar';
export const PLAYOUTS_MESSAGE = 'Hiervoor afgespeeld';
export const NOW_PLAYING = 'Nu aan het afspelen';
export const RECOMMENDATIONS = 'Aanbevolen';

export const EVENT_TYPE = {
  START: 'start',
  STOP: 'stop',
  HEARTBEAT: 'heartbeat',
};

export const TIME = {
  HEARTBEAT: 60000,
};

export const COLLECTION_OPTIONS = {
  HIGHLIGHT: 'highlight',
  NO_PLAYOUTS: 'no-playouts',
  HIDE_TITLE: 'hide-title',
  SHOW_EMPTY_STATE: 'show-empty-state',
  FAVOURITE: 'favourite',
  CONTINUE_LISTENING: 'continue-listening',
  SHOW_SECTION_TITLE: 'show-section-title',
  SHOW_IF_EMPTY: 'show-if-empty',
  HIDE_IF_EMPTY: 'hide-if-empty',
  RECENTLY_PLAYED: 'recently-played',
};

export const HTTP_STATUS_CODE = {
  OK: 200,
  NOT_FOUND: 404,
};

export const POPOUT_WINDOW_NAME = 'playerPopoutWindow';
export const isPopoutPlayer = window.name === POPOUT_WINDOW_NAME;

export const lastActivityExpireTime = 'lastActivityExpireTime';
