import React from 'react';
import svgIcon from 'components/hoc/svgIcon';
import PropTypes from 'prop-types';

const svg = ({ size }) => {
  const viewBox = size ? '0 0 28 21' : '0 0 40 40';
  const style = size ? { transform: 'translate(-6px, -9px)' } : {};

  return (
    <svg viewBox={viewBox} style={{ height: size, width: size }}>
      <path
        d="M15 13a1 1 0 110 2h-3a1 1 0 00-1 1v14a1 1 0 001 1h16a1 1 0 001-1V16a1 1 0 00-1-1h-3a1 1 0 110-2h3c1.654 0 3 1.346 3 3v14c0 1.654-1.346 3-3 3H12c-1.654 0-3-1.346-3-3V16c0-1.654 1.346-3 3-3zm4.618-7.923a1.001 1.001 0 011.09.217l3.999 3.999a.999.999 0 11-1.414 1.414L21 8.414V20a1 1 0 11-2 0V8.414l-2.293 2.293a.999.999 0 11-1.414-1.414l3.999-3.999a.99.99 0 01.326-.217z"
        fillRule="nonzero"
        style={style}
      />
    </svg>
  );
};

svg.propTypes = {
  size: PropTypes.number,
};

svg.defaultProps = {
  size: null,
};

export default svgIcon(svg);
