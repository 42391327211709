import React from 'react';

// Store data that can come from top level page but its used on single tile
const CollectionContext = React.createContext({
  showNowPlayingInfo: true,
  isPodcastPage: false,
  isInSearch: false,
  recentSearch: false,

  withFold: false,
  isInFold: false,
  isContinueListening: false,
});

export default CollectionContext;
