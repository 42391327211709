import * as cssVariable from 'cssVariables';

// SubNavigation and ParentNavigation share these styles
export const navItem = {
  border: 'none',
  background: 'none',
  width: '100%',
  textAlign: 'inherit',
  position: 'relative',
  textDecoration: 'none',
  color: cssVariable.grey500,
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderBottom: `solid 1px ${cssVariable.grey200}`,
  height: cssVariable.defaultMenuHeight,
  paddingLeft: 4,
  paddingRight: 8,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 4,
  },
};

export const navItemMobile = {
  height: cssVariable.mobileMenuHeight,
  paddingLeft: 16,
  paddingRight: 16,
  '&:before': {
    width: 8,
  },
};
