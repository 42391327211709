import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withGraphQL from 'components/hoc/withGraphqlData';
import OnDemandShowCollection from './OnDemandShowCollection';

function OnDemandSection({ items, children, isComponentLoading, ...otherProps }) {
  if (isComponentLoading || !Array.isArray(items) || !items.length) return null;

  return (
    <Fragment>
      {children}
      {items &&
        items.map(
          (show) =>
            show.episodes &&
            show.episodes.length > 0 && <OnDemandShowCollection key={show.slug} show={show} {...otherProps} />
        )}
    </Fragment>
  );
}

OnDemandSection.propTypes = {
  items: PropTypes.array,
  isComponentLoading: PropTypes.bool,
};

OnDemandSection.defaultProps = {
  items: null,
  isComponentLoading: false,
};

export default withGraphQL(OnDemandSection);
