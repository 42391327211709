import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import injectStylesheet from 'react-jss';
import ReactPlayer from 'react-player/lazy';
import * as analytics from 'analytics';
import { transparentLightmode005 } from 'cssVariables';
import { checkVendors, onConsentChange } from 'util/index';
import Overlay from './Overlay';
import Backdrop from './Backdrop';
import { aspectRatios, STREAMING_APIS } from './Video.const';

// simple embed video player for youtube, vimeo etc
function Video({
  aspectRatio,
  classes,
  image,
  isPlaying,
  onInlineMediaErrored,
  onInlineMediaPlay,
  onInlineMediaStop,
  title,
  url,
}) {
  useEffect(
    () => () => {
      onInlineMediaStop();
    },
    []
  );

  /**
   * Event handler for when the cover image is clicked and playback is started
   */
  const clickHandlerPlay = () => {
    onInlineMediaPlay(url);
    analytics.trackInlineMediaPlayback(url);
  };

  /**
   * Event handler for when the backdrop is clicked
   */
  const clickHandlerStop = () => {
    onInlineMediaStop();
  };
  // Show a backdrop if it's just audio
  const backdropExtensions = ['.mp3', '.aac', '.hls'];
  const showBackdrop = backdropExtensions.some((item) => url.endsWith(item));
  const getConsent = () => (url ? showBackdrop || checkVendors(STREAMING_APIS, url) : null);

  const [hasConsent, setHasConsent] = useState(getConsent());

  onConsentChange(() => {
    setHasConsent(getConsent());
  });

  if (!url) return null;

  if (!hasConsent) {
    const { name } = STREAMING_APIS.find((vendor) => url.includes(vendor.url)) || {};
    return (
      <div
        className={classNames(classes.wrapper, classes.noConsent, {
          [classes[aspectRatio]]: aspectRatio && classes[aspectRatio],
        })}
      >
        <p>
          Om deze video van {name || 'deze video'} te bekijken moet je toestemming geven. Ga naar je{' '}
          <a href="#show-consent-manager">cookie-instellingen.</a>
        </p>
      </div>
    );
  }

  return (
    <div
      className={classNames(classes.wrapper, {
        [classes[aspectRatio]]: aspectRatio && classes[aspectRatio],
      })}
    >
      <Overlay
        isPlaying={isPlaying}
        image={image}
        clickHandlerPlay={clickHandlerPlay}
        title={title}
        hideTitle={aspectRatio === aspectRatios.PORTRAIT}
      />
      <div
        className={classes.videoWrapper}
        onClick={showBackdrop ? clickHandlerStop : null}
        onKeyPress={showBackdrop ? clickHandlerStop : null}
        role="button"
        tabIndex="0"
      >
        <ReactPlayer url={url} width="100%" height="100%" onError={onInlineMediaErrored} playing={isPlaying} controls />
      </div>
      {showBackdrop && (
        <Backdrop isPlaying={isPlaying} image={image} clickHandlerPause={clickHandlerStop} title={title} />
      )}
    </div>
  );
}

Video.defaultProps = {
  aspectRatio: null,
  image: null,
  title: '',
  isPlaying: false,
};

Video.propTypes = {
  aspectRatio: PropTypes.oneOf(Object.values(aspectRatios)),
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  onInlineMediaPlay: PropTypes.func.isRequired,
  onInlineMediaStop: PropTypes.func.isRequired,
  onInlineMediaErrored: PropTypes.func.isRequired,
  image: PropTypes.object,
  title: PropTypes.string,
  isPlaying: PropTypes.bool,
};

const styles = (theme) => ({
  wrapper: {
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: '40px',
    position: 'relative',
    paddingTop: '56.25%',
  },
  noConsent: {
    backgroundColor: transparentLightmode005,
    '& p': {
      margin: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
  },
  [aspectRatios.SQUARE]: {
    // 1:1
    paddingTop: '100%',
  },
  [aspectRatios.FOUR_THREE]: {
    // 4:3
    paddingTop: '75%',
  },
  [aspectRatios.PORTRAIT]: {
    // 2:3
    paddingTop: '150%',
  },
  [aspectRatios.LANDSCAPE]: {
    // '16:9'
    paddingTop: '56.25%',
  },
  videoWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
});

export default injectStylesheet(styles)(Video);
