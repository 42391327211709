import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import { FEEDBACK_PAGE_TITLE } from 'globalConst/const';

import { fontSizes } from 'cssVariables';

import { BreadcrumbsSchema } from 'components/Schema';
import ContentPageLayout from 'components/ContentPageLayout';
import PageHeader from 'components/PageHeader';
import PageContent from 'components/PageContent';
import LogoGradientLine from 'components/svg/LogoGradientLine';

class FeedbackCompletePage extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    forceMiniPlayer: PropTypes.func.isRequired,
    browser: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.browser.down.sl) {
      this.props.forceMiniPlayer(true);
    }
  }

  componentWillUnmount() {
    if (this.props.browser.down.sl) {
      this.props.forceMiniPlayer(false);
    }
  }

  render() {
    const { classes, browser } = this.props;
    return (
      <ContentPageLayout>
        <PageHeader title={FEEDBACK_PAGE_TITLE} />
        <BreadcrumbsSchema level2={FEEDBACK_PAGE_TITLE} />
        <PageContent>
          <div className={classes.contentAlignWrapper}>
            <div className={classes.logoWrapper}>
              <LogoGradientLine strokeWidth={browser.down.sl ? 2 : 1.5} />
            </div>
            <div className={classes.contentWrapper}>
              <h1 className={classes.title}>Bedankt voor je feedback!</h1>
              <p className={classes.paragraph}>
                Enorm bedankt voor je medewerking! Mocht je nog vragen of opmerkingen hebben? Je kunt ons bereiken via{' '}
                <a href="mailto:support@juke.nl">support@juke.nl</a>
              </p>
            </div>
          </div>
        </PageContent>
      </ContentPageLayout>
    );
  }
}

const styles = (theme) => ({
  contentWrapper: {
    width: '100%',
    maxWidth: '550px',
    textAlign: 'center',
  },
  logoWrapper: {
    '& > svg': {
      width: 'auto',
      height: 95,
      marginBottom: 24,
      fill: 'transparent',
      overflow: 'visible',
    },
  },
  contentAlignWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '100px 0 0 0',
  },
  [theme.breakpoints.down('sl')]: {
    logoWrapper: {
      '& > svg': {
        height: 60,
      },
    },
    contentAlignWrapper: {
      padding: '100px 0',
    },
  },
  title: {
    textAlign: 'center',
    lineHeight: 1,
    fontSize: fontSizes.xlarge,
    marginBottom: 28,
  },
  paragraph: {
    fontSize: fontSizes.small,
    lineHeight: 1.5,
  },
  svg: {
    strokeWidth: 1.5,
  },
});

export default injectStyles(styles)(FeedbackCompletePage);
