import { connect } from 'react-redux';

import { setFullPlayerState } from 'actions/player-actions';
import { getCurrentStation, getError, hasMediaInPlayer } from 'store/reducers/player';
import withGraphQL from 'components/hoc/withGraphqlData';

import PlayerBottom from './PlayerBottom';
import { getIsOnline } from 'store/reducers/content';
import { getBrowser } from 'store/reducers/browser';

const mapStateToProps = (state) => {
  const { slug } = getCurrentStation(state) || {};

  return {
    slug,
    contentTypeId: slug ? 'station' : null,
    isOnline: getIsOnline(state),
    playerError: getError(state),
    hasContent: hasMediaInPlayer(state),
    browser: getBrowser(state),
  };
};

const mapDispatchToProps = {
  onSetFullPlayerState: setFullPlayerState,
};

export default connect(mapStateToProps, mapDispatchToProps)(withGraphQL(PlayerBottom));
