import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import graphql from 'api/graphql';
import { isUserOnJuke as getIsUserOnJuke } from 'store/reducers/trackers';
import logger from 'util/logger';

export default function useFetchNowPlaying({ contentTypeId, slug, shouldFetchTrack } = {}) {
  const isUserOnJuke = useSelector((state) => getIsUserOnJuke(state));
  const [playouts, setPlayouts] = useState(null);

  useEffect(() => {
    let nowPlayingIntervalUpdate;
    let unmounted;
    async function fetchNowPlaying() {
      try {
        const response = await graphql.fetchUnderlineTrack({ slug });
        if (!unmounted) {
          setPlayouts(response);
        }
      } catch (error) {
        clearInterval(nowPlayingIntervalUpdate);
        logger.error(`Error ${error} while fetchNowPlaying with graphQL for ${slug} | ${contentTypeId}`);
      }
    }

    const shouldFetchNowPlaying = contentTypeId === 'stationCollection';
    if (slug && shouldFetchNowPlaying) {
      if (isUserOnJuke && shouldFetchTrack) {
        fetchNowPlaying();
        nowPlayingIntervalUpdate = setInterval(fetchNowPlaying, 60000);
      } else {
        clearInterval(nowPlayingIntervalUpdate);
      }
    }

    return () => {
      clearInterval(nowPlayingIntervalUpdate);
      unmounted = true;
    };
  }, [slug, isUserOnJuke, contentTypeId, shouldFetchTrack]);

  return playouts;
}
