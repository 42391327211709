import { connect } from 'react-redux';
import { getCurrentStation } from 'store/reducers/player';

import IframeWall from './IframeWall';

const mapStateToProps = (state) => ({
  currentStation: getCurrentStation(state),
});

export default connect(mapStateToProps)(IframeWall);
