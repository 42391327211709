import * as cssVariables from 'cssVariables';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: cssVariables.grey100,
    height: 163,
    padding: 10,
  },
  title: {
    fontSize: cssVariables.fontSizes.medium,
    fontWeight: cssVariables.fontWeights.bold,
  },
  icon: {
    height: 55,
    width: 55,
    '& path': {
      fill: cssVariables.grey500,
    },
  },
};
