import { IConfig } from '@4tn/core-audio-player-v2';
import { getEUConsentString, tritonConsentTag } from './consent';
import { getVolumeLevel } from 'store/reducers/player';
import { getStore } from 'store';

const getCapConfig = async (prefersHD: boolean): Promise<IConfig> => {
  const gdprConsent = getEUConsentString();
  const store = getStore();
  const state = store.getState();
  const volume = getVolumeLevel(state) || 1;
  const userToken = '';

  /* 
  Feature flag is added but it's only forwarded to CAP if the value is true 
  because we don't have any ads on JUKE for now and sending false as a value
  for excludePreroll causes the player to break.
  */
  // const excludePrerollTemp = await getFlagValue(FEATURE_FLAG.EXCLUDE_PREROLL);

  return {
    distTag: 'jukefm',
    gdpr: gdprConsent ? 1 : 0,
    gdprConsent,
    hdStream: prefersHD,
    excludePreroll: true,
    userToken,
    newTealiumSetup: false,
    volume,
    ttag: tritonConsentTag(),
  };
};

export default getCapConfig;
