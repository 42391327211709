import { v4 as uuid } from 'uuid';

import { NotificationTypes } from 'components/Notifications/Notification';
import { notificationActions } from './actions-const';
import { Action } from 'redux';
import { AppDispatch } from 'store';

interface INotification {
  id?: string;
  type?: string;
  message?: string;
}

export function showNotification(notification: INotification): Action<string> & { notification: INotification } {
  return {
    type: notificationActions.SHOW_NOTIFICATION,
    notification: {
      id: notification.id || uuid(),
      type: notification.type || NotificationTypes.NOTIFICATION,
      message: notification.message,
    },
  };
}

export function clearNotification(notificationId: string) {
  return (dispatch: AppDispatch): void => {
    dispatch({ type: notificationActions.CLEAR_NOTIFICATION, notificationId });
  };
}
