import React from 'react';
import PropTypes from 'prop-types';
import StationPage from 'components/pages/content/StationPage';
import GenrePage from 'components/pages/content/GenrePage';
import PodcastPage from 'components/pages/content/PodcastPage';
import PodcastEpisodePage from 'components/pages/content/PodcastEpisodePage';
import DynamicPage from 'components/pages/DynamicPage';
import { PAGE_TYPE } from 'globalConst/const';

const LinkContentPage = ({ page, match }) => {
  const isDynamicPage = !!page.content.length;

  /**
   * We have pages that are not dynamic pages, but contain data which is applied on our hardcoded pages, example:
   * * Radiozenders with slug `radiozenders` is page, but should be used as model for StationOverviewPage instead.
   * We can assume that dynamic page is the one that has `content` populated.
   */
  if (isDynamicPage) {
    return <DynamicPage />;
  }

  if (!match) {
    return null;
  }

  if (match.path === PAGE_TYPE.BRAND || match.path === PAGE_TYPE.STATION) {
    return <StationPage />;
  }

  if (
    match.path === PAGE_TYPE.GENRE ||
    match.path === PAGE_TYPE.MOODS ||
    match.path === PAGE_TYPE.PODCAST_GENRE ||
    match.path === PAGE_TYPE.NON_STATION_BRAND
  ) {
    return <GenrePage />;
  }

  if (match.path === PAGE_TYPE.PODCAST) {
    return <PodcastPage />;
  }

  if (match.path === PAGE_TYPE.PODCAST_EPISODE_PAGE) {
    return <PodcastEpisodePage />;
  }

  return null;
};

LinkContentPage.defaultProps = {
  page: {
    slug: null,
    content: [],
  },
  match: null,
};

LinkContentPage.propTypes = {
  page: PropTypes.shape({
    slug: PropTypes.string,
    content: PropTypes.array,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default LinkContentPage;
