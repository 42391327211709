import { Styles } from 'react-jss';
import * as cssVariable from 'cssVariables';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  modalBackground: {
    background: 'rgba(0, 0, 0, 0.5) none repeat 0% 0%',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    'z-index': 10301,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBox: {
    background: 'white',
    'border-radius': 8,
    position: 'fixed',
    'max-width': 620,
    'z-index': 10302,
    display: 'flex',
    flexDirection: 'column',
    'overflow-y': 'hidden',
    margin: [0, 16],
  },
  dialogTitle: {
    color: 'white',
    fontWeight: 600,
  },
  showCloseIcon: {
    color: cssVariable.white,
  },
  hideCloseIcon: {
    display: 'none',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    'background-image': 'linear-gradient(to right, #37b376, #46C31D, #facf18)',
    height: 60,
    padding: 15,
    fontSize: 20,
  },
  dialogContentContainer: {
    position: 'relative',
    'overflow-y': 'auto',
    padding: [35, 75],
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      padding: [25, 25],
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 292,
  },
  dialogContentTitle: {
    fontWeight: 600,
    fontSize: '28px',
    textAlign: 'center',
  },
  dialogButtonSection: {},
  dialogLoginButton: {
    marginRight: 10,
  },
  dialogContentText: {
    margin: [25, 0],
    lineHeight: '28px',
    textAlign: 'center',
    fontSize: 19,
  },
});
