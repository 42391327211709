import { queryRequest } from './client';
import { getPromotionCollection } from './queries';
import PromotionCollection from '../models/PromotionCollection';
import graphql from 'api/graphql';
import { isEmpty } from 'util/index';
import logger from 'util/logger';

/**
 * Fetch a list of promotion collections from the universal api
 *
 * @param {object} param
 * @param {string} param.slug
 * @param {object} param.sectionConfig
 * @param {object} param.collectionProps
 *
 * @returns {Object} PromotionCollection
 */

export const fetchPromotionCollection = async ({ slug, config, options }) => {
  const { data } =
    (await queryRequest({
      query: getPromotionCollection,
      variables: { slug },
    })) || {};

  if (!data.collection || isEmpty(data.collection.items)) {
    return null;
  }

  if (data.collection && !isEmpty(data.collection.items)) {
    data.collection.items = await Promise.all(
      data.collection.items.map(async (item) => {
        const { ref } = item || {};

        switch (ref && ref.targetType) {
          case 'station':
            try {
              const station = await graphql.fetchStation({ slug: ref.uri });
              item.pageUrl =
                station.brandSlug !== ref.uri
                  ? `/radiozenders/${station.brandSlug}/${ref.uri}`
                  : `/radiozenders/${ref.uri}`;
              item.station = station;
            } catch {
              logger.error(`Could not create refUri of ${ref.uri}`);
            }
            return item;
          case 'campaign':
            try {
              const campaign = await graphql.fetchCampaign({ slug: ref.uri });
              item.pageUrl = `/radiozenders/${campaign.brandSlug}/acties/${ref.uri}`;
            } catch {
              logger.error(`Could not create refUri of ${ref.uri}`);
            }
            return item;
          case 'podcast':
            try {
              item.podcast = await graphql.fetchPodcast({ slug: ref.uri });
            } catch {
              logger.error(`Could not create refUri of ${ref.uri}`);
            }
            item.pageUrl = `/podcasts/${ref.uri}`;
            return item;
          case 'mood':
            item.pageUrl = `/moods-en-momenten/${ref.uri}`;
            return item;
          case 'genre':
            item.pageUrl = `/muziekgenres/${ref.uri}`;
            return item;
          default:
            return item;
        }
      })
    );
  }
  return data.collection && new PromotionCollection(data.collection, config, options);
};
