export default {
  '@keyframes lazyTileImageAnimation': {
    '0%': {
      opacity: 0,
    },
    '30%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  loading: {
    visibility: 'hidden',
    height: 0,
  },
  loaded: {
    animation: '$lazyTileImageAnimation 0.5s',
  },
};
