import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import JWPlayer from './JWPlayer';
import { getCurrentStation, playbackModes } from 'store/reducers/player';
import { createUseStyles } from 'react-jss';

import styles from './PlayerVideo.styles';
import { useSelector } from 'react-redux';
import PlayerVideoButton from './PlayerVideoButton';
import { JW_PLAYER_LOAD_TIMEOUT } from 'players/Players.const';

const useStyles = createUseStyles(styles, { name: 'PlayerVideo' });

const PlayerVideo = ({ playbackMode, isPlaying, onSetPlaybackToLiveAudio, onSetPlaybackToLiveVideo }) => {
  const classes = useStyles();

  let videoCloseAction = null;
  const [visible, setVisible] = useState(true);
  const isLiveVideo = playbackMode === playbackModes.LIVE_VIDEO;
  const isLiveAudio = playbackMode === playbackModes.LIVE_AUDIO;
  // use useSelector hook to extract state because prop passed from connect() has some delay
  // which cause unwanted behavior when switching from stream to invalid stream
  const currentStation = useSelector((state) => getCurrentStation(state));

  useEffect(() => {
    // If HD Audio is press check if it was playing video and hide it
    if (isLiveAudio && visible && videoCloseAction) {
      setVisible(false);
      videoCloseAction();
    }
  }, [playbackMode]);

  const onPress = (isOpening) => {
    setVisible(isOpening);
    setTimeout(() => {
      if (isOpening) {
        if (!isLiveVideo || !isPlaying) {
          onSetPlaybackToLiveVideo(currentStation);
        }
        return;
      }

      onSetPlaybackToLiveAudio(currentStation, true, true);
    }, JW_PLAYER_LOAD_TIMEOUT);
  };

  /**
   * Renders JSX for a video player (JWPlayer)
   * @returns {object} JSX
   */
  const renderPopout = (closeBtn, closeAction) => {
    videoCloseAction = closeAction;
    return (
      <div className={classes.videoWrapper}>
        {closeBtn}
        <JWPlayer isShown={visible} />
      </div>
    );
  };

  if (!currentStation?.videoUrl) {
    return null;
  }

  return <PlayerVideoButton onPress={onPress} renderPopout={renderPopout} />;
};

PlayerVideo.defaultProps = {
  isPlaying: false,
};

PlayerVideo.propTypes = {
  playbackMode: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool,
  onSetPlaybackToLiveAudio: PropTypes.func.isRequired,
  onSetPlaybackToLiveVideo: PropTypes.func.isRequired,
};

export default PlayerVideo;
