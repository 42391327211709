import { convertSecondsToHMS } from 'util/time';
import { Image, UniversalMedia } from '../interfaces';
import logger from 'util/logger';
import { Duration } from 'date-fns';

export interface UniversalShowEpisode {
  id: string;
  title: string;
  publishDate: string;
  durationSeconds: string;
  media: Array<UniversalMedia>;
}

export interface ExtendedUniversalShowEpisode extends UniversalShowEpisode {
  brandColor: string;
  image: Image;
  logo: Image;
  logoWhite: Image;
}

/**
 * @class
 * A representation of show episode.
 *
 * @param {object} episode
 */
export default class ShowEpisode {
  id: string;
  title: string;
  publishedUtc: string;
  duration: Duration;
  type: 'show';

  tritonMount: string;
  tritonMountHD: string;
  audioUrl: string;
  audioUrlAac: string;
  audioUrlHls: string;

  image: Image;
  logo: Image;
  logoWhite: Image;

  brandColor: string;

  media: Array<UniversalMedia>;

  constructor(episode: ExtendedUniversalShowEpisode) {
    if (!episode) {
      return null;
    }

    this.id = episode.id;
    this.title = episode.title;
    this.publishedUtc = episode.publishDate;
    this.duration = convertSecondsToHMS(episode.durationSeconds);

    this.media = [];

    if (episode.media && Array.isArray(episode.media)) {
      episode.media.forEach(({ source, uri }) => {
        this.media.push({ uri, source });
        switch (source) {
          case 'mount_point':
            this.tritonMount = uri;
            break;
          case 'mount_point_hd':
            this.tritonMountHD = uri;
            break;
          case 'audio_mp3':
            this.audioUrl = uri;
            break;
          case 'audio_aac':
            this.audioUrlAac = uri;
            break;
          case 'audio_hls':
            this.audioUrlHls = uri;
            break;
          default:
            logger.warn(`Show Episode missing source: ${source}`);
            break;
        }
      });
    }

    this.image = episode.image;
    this.logo = episode.logo;
    this.logoWhite = episode.logoWhite;

    this.brandColor = episode.brandColor;

    // Make this class read-only
    Object.freeze(this);
  }
}
