import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRatingAndroidApp, getRatingIOSApp } from 'store/reducers/rating';

import { SCHEMA_OFFERS, SCHEMA_ACTION_TYPE } from 'globalConst/schema-const';

import Schema from './Schema';

const PLATFORMS = {
  ANDROID: 'Android',
  IOS: 'IOS',
};

const getTarget = ({ type, platform, link, rating }) => ({
  '@type': 'EntryPoint',
  urlTemplate: `juke://${link}`,
  actionApplication: {
    '@type': 'SoftwareApplication',
    '@id': `https://juke.nl${window.location.pathname}?${platform}&${type}`,
    applicationCategory: 'EntertainmentApplication',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: rating.score,
      ratingCount: rating.reviews,
    },
    name: 'JUKE',
    operatingSystem: platform,
    offers: SCHEMA_OFFERS,
  },
});

const DeepLinkSchema = ({ deeplinks, ratings }) => {
  const hasRating = ratings && (ratings[PLATFORMS.IOS] || ratings[PLATFORMS.ANDROID]);

  if (!deeplinks || !hasRating) return null;

  const actions = deeplinks.map(({ type = SCHEMA_ACTION_TYPE.VIEW_ACTION, link }) => ({
    '@type': 'action',
    potentialAction: {
      '@type': type,
      target: Object.values(PLATFORMS)
        .filter((plaform) => ratings[plaform])
        .map((platform) => getTarget({ type, platform, link, rating: ratings[platform] })),
    },
  }));

  const schema = {
    '@context': 'http://schema.org',
    '@graph': actions,
  };

  return <Schema data={schema} />;
};

DeepLinkSchema.propTypes = {
  deeplinks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  ratings: PropTypes.shape({
    [PLATFORMS.ANDROID]: PropTypes.shape({
      score: PropTypes.string,
      reviews: PropTypes.string,
    }),
    [PLATFORMS.IOS]: PropTypes.shape({
      score: PropTypes.string,
      reviews: PropTypes.string,
    }),
  }),
};

DeepLinkSchema.defaultProps = {
  deeplinks: {
    type: null,
    link: null,
  },
  ratings: null,
};

const mapStateToProps = (state) => ({
  ratings: {
    [PLATFORMS.ANDROID]: getRatingAndroidApp(state),
    [PLATFORMS.IOS]: getRatingIOSApp(state),
  },
});

export default connect(mapStateToProps)(DeepLinkSchema);
