import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import styles from './Navigation.styles';

import MobileCloseIcon from 'components/svg/MobileClose';

import NavigationItems from './NavigationItems';
import { isDesktopApp, isTouch } from 'util/device';

const SVG_ICON_SIZE_SMALL = 20;
const svgIconPropsSmall = {
  width: SVG_ICON_SIZE_SMALL,
  height: SVG_ICON_SIZE_SMALL,
};

const useStyles = createUseStyles(styles, { name: 'Navigation' });
const Navigation = ({ isOpen, browser, modalIsOpen, navigationItems, onToggleNavigation, isCollapsible }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigationRef = useRef(null);

  const hasMobileHeader = isTouch;
  const hasSmallDesktopMenu = (!hasMobileHeader && browser.down.sl && !isCollapsible) || (isCollapsible && !isExpanded);

  const updateCollapsibleState = (state) => () => {
    setIsExpanded(state);
  };

  useEffect(() => {
    if (navigationRef.current && isCollapsible) {
      navigationRef.current.addEventListener('mouseenter', updateCollapsibleState(true));
      navigationRef.current.addEventListener('mouseleave', updateCollapsibleState(false));
    }
    return () => {
      if (navigationRef.current && isCollapsible) {
        navigationRef.current.removeEventListener('mouseenter', updateCollapsibleState);
        navigationRef.current.removeEventListener('mouseleave', updateCollapsibleState);
      }
    };
  }, [isCollapsible]);

  const navClassName = classNames(classes.navigation, {
    [classes.hideNavigation]: modalIsOpen,
    [classes.navigationOpen]: isOpen,
    [classes.navigationMobile]: hasMobileHeader,
  });

  return (
    <Fragment>
      <div
        ref={navigationRef}
        className={classNames(classes.navigationWrapper, {
          [classes.desktopNavigation]: !hasMobileHeader,
          [classes.smallDesktopMenu]: hasSmallDesktopMenu,
          [classes.collapsibleNavigation]: isCollapsible,
        })}
      >
        <nav className={navClassName}>
          {hasMobileHeader && (
            <button
              data-testid="navigation-close-icon"
              type="button"
              className={classes.iconContainer}
              onClick={onToggleNavigation}
            >
              <MobileCloseIcon style={svgIconPropsSmall} />
            </button>
          )}
          <NavigationItems
            navigationItems={navigationItems}
            hasSmallDesktopMenu={hasSmallDesktopMenu}
            hasMobileHeader={hasMobileHeader}
            navIsExpanded={isCollapsible && isExpanded}
            isCollapsible={isCollapsible}
            isDesktopApp={isDesktopApp()}
            onItemClick={isTouch ? onToggleNavigation : undefined}
          />
        </nav>
      </div>
      {isTouch && (
        <div
          data-testid="navigation-backdrop"
          className={classNames(classes.backdrop, {
            [classes.backdropOpen]: isOpen,
          })}
          onClick={onToggleNavigation}
          role="presentation"
        />
      )}
    </Fragment>
  );
};

Navigation.defaultProps = {
  isOpen: false,
  navigationItems: [],
  isCollapsible: true,
};

Navigation.propTypes = {
  isOpen: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  browser: PropTypes.object.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  navigationItems: PropTypes.array,
  onToggleNavigation: PropTypes.func.isRequired,
};

export default Navigation;
