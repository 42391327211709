import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg viewBox="0 0 72 72">
    <path d="M32 39.5c-2 0-3.7-1.7-3.7-3.7s1.7-3.7 3.7-3.7 3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7zm0-4.9c-.7 0-1.2.5-1.2 1.2S31.3 37 32 37s1.2-.5 1.2-1.2-.6-1.2-1.2-1.2z" />
    <path d="M32 46c-5.7 0-10.3-4.6-10.3-10.3S26.3 25.5 32 25.5s10.3 4.6 10.3 10.3S37.6 46 32 46zm0-18c-4.3 0-7.8 3.5-7.8 7.8s3.5 7.8 7.8 7.8 7.8-3.5 7.8-7.8S36.3 28 32 28z" />
    <path d="M58.1 40.2c-3.5 0-6.4-2.9-6.4-6.4 0-3.5 2.9-6.4 6.4-6.4 3.5 0 6.4 2.9 6.4 6.4 0 3.6-2.9 6.4-6.4 6.4zm0-10.3c-2.2 0-3.9 1.8-3.9 3.9 0 2.2 1.8 3.9 3.9 3.9 2.2 0 3.9-1.8 3.9-3.9 0-2.1-1.8-3.9-3.9-3.9z" />
    <path d="M63.4 34.6c-.7 0-1.3-.6-1.3-1.3V10.2c0-.5.4-1 .9-1.2.5-.2 1.1 0 1.4.5l5.5 7.7c.4.6.3 1.3-.3 1.7-.6.4-1.3.3-1.7-.3l-3.2-4.5v19.3c-.1.6-.7 1.2-1.3 1.2zM31 62.3c-14.6 0-26.5-11.9-26.5-26.5S16.4 9.3 31 9.3c9.3 0 18.1 5 22.8 13 .4.6.2 1.4-.4 1.7-.6.4-1.4.2-1.7-.4-4.4-7.3-12.3-11.8-20.7-11.8-13.2 0-24 10.8-24 24s10.8 24 24 24c9.7 0 18.4-5.8 22.1-14.8.3-.6 1-.9 1.6-.7.6.3.9 1 .7 1.6-4.1 10-13.7 16.4-24.4 16.4z" />
  </svg>
);

export default svgIcon(svg);
