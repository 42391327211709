import { Styles } from 'jss';
import * as cssVariable from 'cssVariables';
import tileStyles from 'tile-components-styles';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  title: {
    ...tileStyles.titleDefault,
    textTransform: 'none',
    marginTop: 12,
    minHeight: 25,
    lineHeight: 1.2,
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.small,
    '& span': {
      fontSize: cssVariable.fontSizes.small,
      fontWeight: cssVariable.fontWeights.light,
      marginBottom: '5px',
    },
  },
  titleLink: {
    color: cssVariable.grey600,
    textDecoration: 'none',
    '&:hover': {
      color: cssVariable.green500,
    },
  },
  author: {
    marginTop: 5,
    fontSize: cssVariable.fontSizes.small,
  },
  backdropDefaultFix: {
    maxWidth: '99.7%',
    borderRadius: cssVariable.borderRadius.default,
  },
  ...tileStyles,
  podcastTile: {
    position: 'relative',
    transition: 'transform 300ms ease',
    display: 'block',
    backgroundColor: theme.palette.backgroundSecondary,
    paddingTop: '100%',
    borderRadius: cssVariable.borderRadius.default,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  hoverOverlayDefault: {
    ...tileStyles.hoverOverlayDefault,
    borderRadius: cssVariable.borderRadius.default,
  },
  noHover: {
    '&:hover': {
      opacity: 0,
    },
    '&:focus': {
      opacity: 0,
    },
  },
  notVisible: {
    display: 'none',
  },
  hoverBackdropDefault: {
    ...tileStyles.hoverBackdropDefault,
    borderRadius: cssVariable.borderRadius.default,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: [0, 0, 10, 0],
    '& span': {
      padding: [0, 3, 0, 0],
    },
  },
  hoverAdditionForContextMenu: {
    opacity: 1,
  },
  link: {
    position: 'absolute',
    left: 10,
    bottom: 5,
    fontSize: 13,
    fontWeight: cssVariable.fontWeights.light,
    color: cssVariable.white,
    textDecoration: 'none',
  },
  chevron: {
    verticalAlign: 'middle',
    marginTop: -4,
    height: 26,
    width: 26,
  },
  button: {
    zIndex: 1,
    width: 35,
    height: 35,
    margin: [0, 0, 0, 14.5],
    color: cssVariable.white,
    backgroundColor: cssVariable.transparentLightmode040,
    borderRadius: '50%',
    border: 'none',
    '& > span': {
      padding: 0,
      '& > svg': {
        fill: 'none',
        stroke: '#FFF',
        width: '100%',
        height: '100%',
      },
    },
    '&:hover': {
      width: 44,
      height: 44,
      margin: [0, 0, -5, 10],
      backgroundColor: cssVariable.white,
      color: cssVariable.green500,
      '& > span': {
        '& > svg': {
          color: cssVariable.black,
          '& polygon': {
            fill: cssVariable.green500,
          },
        },
      },
    },
  },
  threeDots: {
    transform: 'rotate(90deg)',
    bottom: 0,
    right: 1,
    position: 'absolute',
    cursor: 'pointer',
    width: 50,
    height: 40,
    fill: cssVariable.white,
  },
  [theme.breakpoints.down('sm')]: {
    chevron: {
      display: 'none',
    },
  },
});
