import { Station, Tag, Podcast } from './index';
import Collection from './Collection';
import { TILE_SIZES } from 'globalConst/const';
/**
 * @class
 * A representation of a Collection with mixed ContentTypes
 *
 * @param {object} collection - collection model
 */
export default class MixedCollection extends Collection {
  constructor(data) {
    super(data);
    if (!data) {
      return null;
    }
    this.tileSizes = TILE_SIZES.SMALL;
    this.items =
      data.items &&
      Object.values(data.items).map((item) => {
        switch (item.type) {
          case 'station':
            return new Station(item);
          case 'genre':
          case 'mood':
            return new Tag(item);
          case 'podcast':
            return new Podcast(item);
          default:
            return item;
        }
      });

    // Make this class read-only
    Object.freeze(this);
  }
}
