export const TILE_COMPONENT_TYPES = {
  STATION: 'StationTile',
  TAG: 'TagTile',
  SHOW: 'ShowTile',
  PROMOTION: 'Promotion',
  ON_DEMAND: 'OnDemandTile',
  PODCAST: 'PodcastTile',
  RECENTLY_PLAYED_STATION: 'RecentlyPlayedStationsItem',
  CARD: 'Card',
  MIXED_CONTENT: 'MixedContentItem',
  MIXED_CONTENT_WITH_HOC: 'MixedContentItemWithHoc',
  LIST: 'ListTile',
};
