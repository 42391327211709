import { connect } from 'react-redux';
import { getHasMobileHeader } from 'store/reducers/navigation';
import { getModalOpen } from 'store/reducers/modal';

import { getForceMiniPlayer } from 'store/reducers/player';
import { setCurrentPageSlug } from 'actions/content-actions';
import { getCurrentPageSubSlug, getCurrentPageSlug } from 'store/reducers/content';
import Tabs from './Tabs';

const mapStateToProps = (state) => ({
  hasMobileHeader: getHasMobileHeader(state),
  forceMiniPlayer: getForceMiniPlayer(state),
  pageSlug: getCurrentPageSlug(state),
  subSlug: getCurrentPageSubSlug(state),
  isModalOpen: getModalOpen(state),
});

const mapDispatchToProps = {
  setCurrentPageSlug,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
