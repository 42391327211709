import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import tileStyles from 'tile-components-styles';
import { createUseStyles } from 'react-jss';
import PlayControl from 'components/PlayControl';
import CrossIcon from 'components/svg/Cross';
import { getImageWithResamplingQuery } from 'util/resampling';
import styles from './MixedContentItem.styles';
import CONTENT_TYPE from './MixedContentItem.const';
import CollectionContext from 'components/Collection/CollectionContext';

import Marquee from 'components/Marquee';
import { isTouch } from 'util/device';

const useStyles = createUseStyles(styles, { name: 'MixedContentItem' });

const MixedContentItem = ({
  author,
  brandColor,
  contentTypeId,
  error,
  image,
  isComponentLoading,
  isPlaying,
  linkedPage,
  onPlayToggle,
  removeItemParent,
  saveRecentSearch,
  slug,
  title,
}) => {
  const { recentSearch } = useContext(CollectionContext);
  const classes = useStyles();

  const getNavLink = () => {
    if (contentTypeId === CONTENT_TYPE.GENRE) {
      return `/muziekgenres/${slug}`;
    }
    if (contentTypeId === CONTENT_TYPE.MOOD) {
      return `/moods-en-momenten/${slug}`;
    }
    if (contentTypeId === CONTENT_TYPE.SHOW) {
      return '#';
    }
    if (contentTypeId === CONTENT_TYPE.STATION) {
      return !linkedPage ? '#' : linkedPage;
    }
    if (contentTypeId === CONTENT_TYPE.PODCAST) {
      return `/podcasts/${slug}`;
    }
    return '#';
  };

  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    removeItemParent(slug);
  };

  const handleSave = () => {
    saveRecentSearch(contentTypeId, slug);
  };

  const marqueeProps = {
    text: author,
  };

  const showAuthor = (author) => {
    if (contentTypeId === CONTENT_TYPE.PODCAST) {
      return <Marquee {...marqueeProps} />;
    } else {
      return (
        <span className={classes.contentAuthor} data-testid={'mixed-content-no-marquee'}>
          {author}
        </span>
      );
    }
  };

  if (error) {
    return null;
  }
  if (isComponentLoading) {
    return (
      <div
        data-testid={'mixed-content-item-loading'}
        className={classNames(classes.listItem, classes.listItemPlaceholder)}
      />
    );
  }

  return (
    <div className={classes.wrapper} data-testid={'mixed-content-item'}>
      <div className={isTouch ? classes.listItemWrapperTouch : classes.listItemWrapper}>
        <Link
          className={classes.listItem}
          to={getNavLink()}
          onClick={handleSave}
          data-testid={'mixed-content-item-link'}
        >
          <div className={classes.imageContainer} style={{ backgroundColor: !image ? brandColor : 'transparent' }}>
            <div
              className={classes.image}
              style={{ backgroundImage: `url(${getImageWithResamplingQuery({ url: image?.url })})` }}
            >
              {contentTypeId !== CONTENT_TYPE.PODCAST && (
                <Fragment>
                  <h4 className={classes.title}>{title}</h4>
                </Fragment>
              )}
            </div>

            {recentSearch && (
              <button
                className={classes.remove}
                onClick={handleRemove}
                type="button"
                aria-label={`Verwijder ${title} van recente zoekopdrachten`}
                data-testid={'mixed-content-item-cross-button'}
              >
                <CrossIcon />
              </button>
            )}
          </div>
        </Link>
        {contentTypeId === CONTENT_TYPE.STATION && (
          <div
            className={classNames(isTouch ? classes.playControlTouch : classes.playControl, tileStyles.actionsDefault)}
            data-testid={'mixed-content-item-station'}
          >
            <PlayControl
              slug={slug}
              contentTypeId={contentTypeId}
              playToggle={onPlayToggle}
              isPlaying={isPlaying}
              size={45}
              isTransparent
            />
          </div>
        )}
      </div>
      <div className={classes.contentContainer}>
        <h4 className={classes.contentTitle}>{title}</h4>
        {author && showAuthor(author)}
        <span className={classes.contentTypeId}>{contentTypeId}</span>
      </div>
    </div>
  );
};

MixedContentItem.propTypes = {
  author: PropTypes.string,
  brandColor: PropTypes.string,
  contentTypeId: PropTypes.string,
  error: PropTypes.bool,
  image: PropTypes.object,
  isComponentLoading: PropTypes.bool,
  isPlaying: PropTypes.bool,
  linkedPage: PropTypes.string,
  onPlayToggle: PropTypes.func,
  recentSearch: PropTypes.bool,
  removeItemParent: PropTypes.func,
  saveRecentSearch: PropTypes.func,
  slug: PropTypes.string,
  title: PropTypes.string,
};

MixedContentItem.defaultProps = {
  author: null,
  brandColor: null,
  contentTypeId: null,
  error: false,
  image: null,
  isComponentLoading: false,
  isPlaying: false,
  linkedPage: null,
  onPlayToggle: null,
  recentSearch: false,
  removeItemParent: null,
  saveRecentSearch: null,
  slug: null,
  title: null,
};

export default MixedContentItem;
