import * as cssVariable from 'cssVariables';
import { Styles } from 'jss';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  isPlayingWrapper: {},
  '@keyframes fadeIn': {
    from: {
      opacity: '0',
    },
    to: {
      opacity: '1',
    },
  },
  tile: {
    position: 'relative',
    backgroundColor: theme.palette.backgroundSecondary,
    borderRadius: cssVariable.borderRadius.default,
    paddingTop: '100%',
    transition: `transform 100ms ease-in`,
    backgroundSize: '100%',
  },
  wrapper: {
    '& > div[data-key="tile-image"]:hover div[data-testid="hover-overlay"]': {
      display: 'flex',
      '& div[data-testid="is-playing-overlay"]': {
        display: 'none',
      },
    },
  },
  nonTouchTile: {
    '&:hover': {
      transform: 'scale(1.05)',
      cursor: 'pointer',
    },
  },
  isPlayingWrapperClass: {
    width: 'auto',
    height: 'auto',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    '& > button': {
      '&:hover': {
        color: cssVariable.green500,
      },
    },
  },
  content: {
    position: 'absolute',
    bottom: 5,
    width: '100%',
    height: '37%',
  },
  logo: {
    width: '80%',
  },
  jukeLogo: {
    position: 'initial',
    top: 'auto',
    bottom: 'auto',
    left: 0,
    width: '40%',
    transform: 'translate(0, 0)',
  },
  title: {
    margin: 0,
    color: cssVariable.white,
    fontSize: '21px',
    lineHeight: 1,
    marginTop: 5,
    padding: [0, 5],
    textAlign: 'center',
    textTransform: 'uppercase',
    overflow: 'hidden',
    [`@supports (-webkit-line-clamp: 2) `]: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
  },
  touch: {
    fontSize: 16,
  },

  [`@media (max-width: ${theme.breakpoints.values.sl}px)`]: {
    title: {
      fontSize: 16,
    },
  },
});
