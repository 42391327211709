import React from 'react';
import PropTypes from 'prop-types';

import svgIcon from 'components/hoc/svgIcon';

const Close = ({ inherit }) => (
  <svg {...(inherit && { width: 18, height: 18 })} viewBox="0 0 18 18">
    <path d="M7.987 9.048l-7.01 7.01c-.292.292-.292.767 0 1.06.293.293.768.293 1.06 0l7.01-7.01 7.01 7.01c.293.293.768.293 1.06 0 .294-.293.294-.768 0-1.06l-7.01-7.01 7.01-7.01c.294-.293.294-.768 0-1.06-.292-.293-.767-.293-1.06 0l-7.01 7.01-7.01-7.01c-.29-.293-.767-.293-1.06 0-.29.292-.29.767 0 1.06l7.01 7.01z" />
  </svg>
);

Close.propTypes = {
  inherit: PropTypes.bool,
};

Close.defaultProps = {
  inherit: false,
};

export default svgIcon(Close);
