import { queryRequest } from './client';
import { getClip, getPodcast, getPodcastBasic, getPodcasts } from './queries';
import Podcast from '../models/Podcast';
import { PodcastCollection, PodcastEpisode } from 'api/models';
import { isEmpty } from 'util/index';

/**
 * Fetch a podcast collections from the universal api
 *
 * @param {object} param
 * @param {string} param.slug
 * @param {object} param.config
 * @param {object} param.options
 *
 * @returns {Object} PodcastCollection
 */
/*
 * TODO - Double check in clean up ticket, this should probably be updated
 * getPodcasts query fetch deprecated fields
 */
export const fetchPodcastCollection = async ({ slug, config, options }) => {
  try {
    const { data } =
      (await queryRequest({
        query: getPodcasts,
        variables: { slug },
      })) || {};
    return new PodcastCollection(data.collection, config, options);
  } catch (error) {
    return null;
  }
};

/**
 * Fetch a single podcast from the universal api
 * @returns {Podcast} Podcast
 */
export const fetchPodcast = async ({ slug, limit = 100 }) => {
  try {
    const { data } = await queryRequest({ query: getPodcast, variables: { slug, limit } });
    return new Podcast(data.podcast);
  } catch {
    return null;
  }
};

export const fetchClip = async ({ slug }) => {
  try {
    const { data } = await queryRequest({ query: getClip, variables: { slug } });
    const { clip } = data || {};
    const podcast = clip.reference && clip.reference.type === 'podcast' ? new Podcast(clip.reference) : {};
    return new PodcastEpisode(clip, podcast);
  } catch {
    return null;
  }
};
/**
 * Fetch a single podcast with basic items from the universal api
 * @param {string} slug
 * @returns {Object} Podcast
 */
export const fetchPodcastBasic = async ({ slug }) => {
  const { data } =
    (await queryRequest({
      query: getPodcastBasic,
      variables: { slug },
    })) || {};
  return new Podcast(data.podcast);
};

/**
 * Fetch a related podcasts from the universal api
 * @param {Array} relatedPodcasts
 * @returns {Promise<Array>} Array of Podcasts
 */
export const fetchRelatedPodcast = (relatedPodcasts) => {
  if (isEmpty(relatedPodcasts)) return null;

  return Promise.all(
    relatedPodcasts.map(async (podcast) => {
      const { slug } = podcast;
      const { data } =
        (await queryRequest({
          query: getPodcastBasic,
          variables: { slug },
        })) || {};

      return new Podcast(data.podcast);
    })
  );
};
