export const PODCAST_FRAGMENT = `
  fragment PodcastFragment on Podcast {
    title
    description
    slug
    feedUrl
    id
    subTitle
    type
    tags {
      slug
      title
      type
    }
    images {
      ...ImageFragment
    }
    refs {
      id
      type
      targetType
      uri
    }
    labels
    episodes(limit: 1) {
      ...PodcastEpisodeFragment
    }
    author
    language
    publisher
  }
  `;
