import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import * as cssVariable from 'cssVariables';
import * as analytics from 'analytics';
import Button from 'components/Button';
import ButtonTypes from 'components/Button/Button.const';
import { NavLink } from 'react-router-dom';

class TextFrame extends PureComponent {
  static defaultProps = {
    url: '',
    buttonText: '',
    handleClick: undefined,
    isNavLink: false,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    showTitle: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    url: PropTypes.string,
    handleClick: PropTypes.func,
    isNavLink: PropTypes.bool,
  };

  renderButton = () => {
    const { handleClick, buttonText, url } = this.props;

    const onClick = () => (handleClick ? handleClick() : analytics.trackInlineTextframCTA(url));

    return <Button type={ButtonTypes.PRIMARY} href={url} onClick={onClick} text={buttonText} />;
  };

  render() {
    const { classes, title, showTitle, text, buttonText, url, isNavLink } = this.props;

    return (
      <div className={classes.wrapper}>
        {showTitle && <h2 className={classes.title}>{title}</h2>}
        {text && <span className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />}
        {buttonText && url && (
          <div className={classes.buttonWrapper}>
            {isNavLink ? <NavLink to={url}>{this.renderButton()}</NavLink> : this.renderButton()}
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '36px 40px',
    backgroundColor: cssVariable.grey100,
    marginBottom: '40px',
  },
  title: {
    margin: 0,
    fontSize: cssVariable.fontSizes.xlarge,
  },
  text: {
    fontSize: cssVariable.fontSizes.medium,
    lineHeight: 1.3333,
    marginBottom: 0,
  },
  buttonWrapper: {
    marginTop: '36px',
  },
};

export default injectStyles(styles)(TextFrame);
