import { connect } from 'react-redux';
import { getStationHistory } from 'store/reducers/stationHistory';
import RecentlyPlayedStationsCollection from './RecentlyPlayedStationsCollection';
import { State } from 'redux';

const mapStateToProps = (state: State) => ({
  history: getStationHistory(state),
});

export default connect(mapStateToProps)(RecentlyPlayedStationsCollection);
