import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { getImageWithResamplingQuery } from 'util/index';
import Headphone from 'components/svg/Headphone';

import styles from './NowPlayingImage.styles';

const useStyles = createUseStyles(styles, { name: 'NowPlayingImage' });

const NowPlayingImage = ({ imageUrl, backgroundColor, logo: logoUrl, title, subtitle, isClip }) => {
  const classes = useStyles({ backgroundColor, imageUrl });

  const logo = getImageWithResamplingQuery({
    url: logoUrl,
    isTransparent: true,
    customWidth: 50,
  });

  const headphone = <Headphone />;
  return (
    <div className={classNames(classes.background, { [classes.backgroundHover]: isClip })}>
      <div className={classes.infoArea}>
        {!logo && !imageUrl && headphone}
        {title && <span>{title}</span>}
        {subtitle && <span>{subtitle}</span>}
      </div>
    </div>
  );
};

NowPlayingImage.propTypes = {
  backgroundColor: PropTypes.string,
  imageUrl: PropTypes.string,
  logo: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isClip: PropTypes.bool,
};

NowPlayingImage.defaultProps = {
  backgroundColor: null,
  imageUrl: null,
  logo: null,
  title: null,
  subtitle: null,
  isClip: null,
};

export default NowPlayingImage;
