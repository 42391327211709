/* eslint-disable no-console */
const logger = (type: string, message: Array<string>) => {
  if (process.env.NODE_ENV === 'development') {
    switch (type) {
      case 'info':
        console.info(message);
        break;
      case 'debug':
        console.debug(message);
        break;
      case 'warning':
        console.warn(message);
        break;
      case 'error':
        console.error(message);
        break;
      default:
        console.log(message);
    }
  } else {
    // TODO: Report to API
  }
};

export default {
  log: (...messages: Array<string>): void => logger('log', messages),
  info: (...messages: Array<string>): void => logger('info', messages),
  debug: (...messages: Array<string>): void => logger('debug', messages),
  warn: (...messages: Array<string>): void => logger('warning', messages),
  error: (...messages: Array<string>): void => logger('error', messages),
};
