import React from 'react';
import PropTypes from 'prop-types';

import svgIcon from 'components/hoc/svgIcon';

const svg = ({ filled }) => (
  <svg viewBox="0 0 40 40">
    <g transform="translate(1.000000, 1.000000)">
      <rect
        x="13"
        y="13"
        width="12"
        fill={filled ? 'currentColor' : 'none'}
        height="12"
        stroke="currentColor"
        strokeWidth="1.5px"
        rx="1"
      />
    </g>
  </svg>
);

svg.propTypes = {
  filled: PropTypes.bool,
};

svg.defaultProps = {
  filled: false,
};

export default svgIcon(svg);
