import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import generatePlaceholders from 'util/generatePlaceholders';
import { BreadcrumbsSchema, PageTypeSchema } from 'components/Schema';
import PageHeader from 'components/PageHeader';
import PageContent from 'components/PageContent';
import NotFoundPage from 'components/pages/status/NotFoundPage';
import DeeplinkSchema from 'components/Schema/DeeplinkSchema';

import { GENRES_PAGE_TITLE, MOODS_PAGE_TITLE, TILE_SIZES } from 'globalConst/const';
import { SCHEMA_PAGE_TYPE } from 'globalConst/schema-const';
import HeaderBanner from 'components/HeaderBanner';
import ContentTypeComponent from 'components/ContentTypeComponent';
import { TILE_COMPONENT_TYPES } from 'components/TileComponent/TileComponent.const';
import { fetchShareImageFromSonos } from 'api/graphql/station.service';
import { logger } from 'util/index';

import { withRouter } from 'react-router-dom';

const DEFAULT_PAGE_SETTINGS = {
  pageType: 'genrePage',
};

const SPECIFIC_BRAND_TYPE = {
  RADIOZENDERS: 'radiozenders',
  BRAND: 'brand',
  MUSIC_GENRE: 'muziekgenres',
  GENRE: 'genres',
  MOODS: 'moods-en-momenten',
};

const DEFAULT_COLLECTION_PROPS = {
  tileSizes: TILE_SIZES.SMALL,
  rows: 1,
  showNowPlayingInfo: true,
};

class GenrePage extends PureComponent {
  static propTypes = {
    error: PropTypes.bool,
    genre: PropTypes.shape({
      brandColor: PropTypes.string,
      headerImage: PropTypes.object,
      logo: PropTypes.object,
      logoSecondary: PropTypes.object,
      title: PropTypes.string,
      contentTypeId: PropTypes.string,
      subtitle: PropTypes.string,
      slug: PropTypes.string.isRequired,
      pageLink: PropTypes.string.isRequired,
    }),
    isComponentLoading: PropTypes.bool,
    overview: PropTypes.shape({
      brandColor: PropTypes.string,
      headerImage: PropTypes.object,
      logo: PropTypes.object,
      logoSecondary: PropTypes.object,
      title: PropTypes.string,
      sections: PropTypes.array,
      subtitle: PropTypes.string,
    }),
    location: PropTypes.object,
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.object,
  };

  static defaultProps = {
    error: false,
    genre: null,
    isComponentLoading: false,
    overview: null,
  };

  state = {
    shareImageUrl: '',
  };

  componentDidMount() {
    const { slug } = this.props;
    fetchShareImageFromSonos({
      slug,
    })
      .then((imageUri) => {
        this.setState({
          imageUri,
        });
      })
      .catch((error) => {
        logger.error(`something went wrong while fetching station share image`, error);
      });
  }

  renderPlaceholder = () => {
    const placeholder = {
      uuid: 'placeholder-collection',
      contentTypeId: 'stationCollection',
      display: 'tiles',
      ...DEFAULT_COLLECTION_PROPS,
      showMore: {
        variant: 'hideShowMore',
      },
      collection: generatePlaceholders(20, {
        contentTypeId: 'station',
        tileComponent: TILE_COMPONENT_TYPES.STATION,
      }),
    };

    return (
      <Fragment>
        <PageHeader page={DEFAULT_PAGE_SETTINGS} isComponentLoading />
        <PageContent>
          <ContentTypeComponent {...placeholder} />
        </PageContent>
      </Fragment>
    );
  };

  render() {
    const { error, genre, isComponentLoading, overview, location } = this.props;
    const { metadata, pageLink } = genre || {};
    const isBadRequest = location.pathname !== pageLink;
    const { share } = metadata || {};
    const isBrandPage = this.props.location?.pathname?.split('/')[1] === SPECIFIC_BRAND_TYPE.BRAND;
    const showShare =
      // this.props.location?.pathname?.split('/')[1] !== SPECIFIC_BRAND_TYPE.MUSIC_GENRE &&
      ![SPECIFIC_BRAND_TYPE.MUSIC_GENRE, SPECIFIC_BRAND_TYPE.MOODS].includes(
        this.props.location?.pathname?.split('/')[1]
      ) && this.props.location?.pathname?.split('/')[2] !== SPECIFIC_BRAND_TYPE.GENRE;
    const metadataWithShareImage = {
      ...metadata,
      share: {
        ...share,
        image: {
          url: this.state.imageUri,
        },
      },
    };

    if (isComponentLoading) {
      return this.renderPlaceholder();
    }

    const { sections, ...otherOverviewProps } = overview || {};

    if (error || !sections || !sections.length || isBadRequest) {
      return <NotFoundPage />;
    }
    return (
      <Fragment>
        <PageTypeSchema type={SCHEMA_PAGE_TYPE.COLLECTION_PAGE} metadata={genre} />
        <DeeplinkSchema deeplinks={[{ link: `${genre.contentTypeId}/${genre.slug}` }]} />
        <PageHeader {...genre} {...otherOverviewProps} page={DEFAULT_PAGE_SETTINGS} />
        <HeaderBanner
          imageUrl={overview?.headerImage?.url}
          logoUrl={genre?.logoSecondary?.url || genre?.logo?.url}
          color={genre.brandColor}
          title={genre.title}
          description={genre.subtitle ?? overview.subtitle}
          isBrandPage={isBrandPage}
          showShare={showShare}
          metadata={metadataWithShareImage}
        />
        <BreadcrumbsSchema
          level2={genre.contentTypeId === 'mood' ? MOODS_PAGE_TITLE : GENRES_PAGE_TITLE}
          level3={genre.title}
        />
        <PageContent>
          {sections &&
            sections.map((section) => (
              <ContentTypeComponent key={section.uuid} {...DEFAULT_COLLECTION_PROPS} {...section} />
            ))}
        </PageContent>
      </Fragment>
    );
  }
}

export default withRouter(GenrePage);
