/**
 * @returns {string} ex. 2020-02-25T20:30:15.567Z
 */
export const currentTimeISO = new Date().toISOString();

/**
 * @returns {number} ex. 2020
 */
export const currentYear = new Date().getFullYear();

export default function convertUtcToUnix(utcTime) {
  return Date.parse(utcTime) / 1000;
}

/**
 * Converts seconds to object that contains `hours`, `minutes` and `seconds` fields.
 * @param {number|string} seconds
 * @returns {{ hours:number, minutes:number, seconds:number }
 */
export function convertSecondsToHMS(seconds) {
  const totalSeconds = parseInt(seconds, 10);
  if (Number.isNaN(totalSeconds)) {
    return null;
  }
  const h = Math.floor(totalSeconds / 3600);
  const secondsWithoutHours = totalSeconds % 3600;
  const m = Math.floor(secondsWithoutHours / 60);
  const s = secondsWithoutHours % 60;
  return {
    hours: h,
    minutes: m,
    seconds: s,
  };
}

export function convertHMSToSeconds(duration) {
  const { hours = 0, minutes = 0, seconds = 0 } = duration || {};
  return seconds + minutes * 60 + hours * 3600;
}

/**
 * Converts time of the format hh:mm to Date
 * @param {string} time - format hh:mm
 * @param {boolean} [nextDay=false] - whether or not to add one day
 * @returns {Date}
 */
export function getDateFromTime(time, nextDay = false) {
  if (!time || !time.match(/\d{2}:\d{2}/)) {
    return null;
  }
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);

  if (nextDay) {
    date.setDate(date.getDate() + 1);
  }

  return date;
}

export function toMinutes(milliseconds) {
  if (!milliseconds) {
    return null;
  }

  return milliseconds / 1000 / 60;
}
