import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import * as cssVariable from 'cssVariables';

import Notification from './Notification';

class NotificationList extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
  };

  render() {
    const { classes, notifications } = this.props;

    if (!notifications.length) {
      return null;
    }

    return (
      <section className={classes.container}>
        {notifications.map((notification) => (
          <Notification {...notification} key={notification.id} />
        ))}
      </section>
    );
  }
}

const styles = (theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    maxWidth: 600,
    width: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: cssVariable.gutter,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: theme.zIndex.notifications,
  },
  [theme.breakpoints.down('md')]: {
    container: {
      top: 54,
    },
  },
});

export default injectStyles(styles)(NotificationList);
