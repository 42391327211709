import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchIcon from 'components/svg/Search';
import CloseIcon from 'components/svg/Close';
import DefaultHeaderContext from '../DefaultHeaderContext';
import useBrowser from 'components/hooks/useBrowser';
import { breakpoints } from 'components/theme/breakpoints';
import { isTouch } from 'util/device';
import SearchConst from './Search.const';
import { createUseStyles } from 'react-jss';
import styles from './Search.styles';
import { useHistory } from 'react-router-dom';
import getSearchParams from 'util/getSearchParams';
import { useStore } from 'react-redux';
import { searchActions } from 'actions/actions-const';
import { URL_PARAM } from 'globalConst/const';

let searchTimer = null;

const useStyles = createUseStyles(styles, { name: 'HeaderSearch' });
const Search = ({ isActive, onSetActive, onDetermineSearchState, onSearchAlgolia }) => {
  const classes = useStyles();
  const [isInFocus, setFocus] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef({
    value: null,
  });

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  const store = useStore();

  useEffect(() => {
    store.dispatch({ type: searchActions.SET_SEARCH_QUERY, query: '' });
  }, []);

  useEffect(() => {
    history.listen(() => {
      onDetermineSearchState();
    });
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      const { search } = window.location;
      let searchParams = {};
      if (search) {
        searchParams = getSearchParams(search);
      }
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([, ...keys]) => keys);
          if (searchParams?.search !== undefined) {
            setSearchQuery(searchParams?.search);
            inputRef.current.value = searchParams?.search;
          }
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          if (searchParams?.search !== undefined) {
            setSearchQuery(searchParams?.search);
            inputRef.current.value = searchParams?.search;
          }
        }
      }
    });
  }, [locationKeys]);

  const browser = useBrowser();
  const isSmall = browser.down(breakpoints.sm) || isTouch;

  let placeholder = SearchConst.SEARCH_SHORT;

  if (browser.up(breakpoints.md)) {
    placeholder = SearchConst.SEARCH_MOBILE;
  }

  if (browser.up(breakpoints.ml)) {
    placeholder = SearchConst.SEARCH_LONG;
  }

  // When only search icon is visible call toggle function to display full width search
  const { searchInFullWidth, toggleFullWidthSearch = () => {} } = useContext(DefaultHeaderContext);

  const onFocus = () => {
    setFocus(true);
    onSetActive(true);
  };
  const onBlur = () => setFocus(false);

  const onChange = (e) => {
    const query = e.target.value;
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(() => {
      if (!query && searchQuery) {
        onSearchAlgolia('');
      }
      if (query !== searchQuery) {
        onSearchAlgolia(query);
      }
      setSearchQuery(query);
    }, 500);
  };

  const clearSearch = (throughCloseBtn) => {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    setSearchQuery('');
    inputRef.current.value = '';
    if (throughCloseBtn) {
      history.push({
        pathname: history.location.pathname,
        search: '',
      });
    }
    onBlur();
    onSetActive(false);
    if (searchInFullWidth) {
      setTimeout(() => {
        toggleFullWidthSearch();
      }, 0);
    }
  };

  useEffect(() => {
    if (!isActive) {
      clearSearch();
    }
  }, [isActive]);

  useEffect(() => {
    const { search } = window.location;
    if (search && search.includes(URL_PARAM.SEARCH)) {
      let searchParams = getSearchParams(search);
      inputRef.current.value = searchParams.search;
      onSearchAlgolia(searchParams.search);
    }
  }, [inputRef]);

  // Handle screen resize
  useEffect(() => {
    if (!isSmall && searchInFullWidth) {
      toggleFullWidthSearch();
      inputRef.current.value = searchQuery;
    }
    if (searchQuery && isSmall && !searchInFullWidth) {
      toggleFullWidthSearch();
      // Wait for input to be visible again and set current value
      const setInput = () => {
        if (!inputRef.current) {
          setTimeout(() => {
            setInput();
          }, 100);
          return;
        }
        inputRef.current.value = searchQuery;
      };
      setInput();
    }
  }, [isSmall]);

  const onSearchIconClick = () => {
    toggleFullWidthSearch();
    onSetActive(true);
  };

  if (isSmall && !searchInFullWidth) {
    return (
      <div className={classNames(classes.search, classes.searchRight)}>
        <div
          data-testid="search-search-icon-btn"
          onClick={onSearchIconClick}
          className={classNames(classes.searchIcon, classes.searchIconBtn)}
        >
          <SearchIcon />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(classes.search, {
        [classes.paddingLeft]: !searchInFullWidth,
      })}
    >
      <div
        className={classNames(classes.form, {
          [classes.formFocus]: isInFocus,
        })}
      >
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <input
          data-testid="search-input-text"
          ref={inputRef}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          className={classes.input}
        />
        {(searchQuery || searchInFullWidth || isActive) && (
          <div data-testid="search-close-icon-btn" onClick={clearSearch.bind(true)}>
            <CloseIcon className={classes.closeIcon} />
          </div>
        )}
      </div>
    </div>
  );
};

Search.propTypes = {
  onSetActive: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  onDetermineSearchState: PropTypes.func.isRequired,
  onSearchAlgolia: PropTypes.func.isRequired,
};

export default Search;
