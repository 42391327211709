import React, { useState } from 'react';
import { getImageWithResamplingQuery } from 'util/resampling';
import Promotion from 'api/models/Promotion';

import styles from './PromotionImage.styles';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'util/index';
import useBrowser from 'components/hooks/useBrowser';
import { breakpoints } from 'components/theme/breakpoints';

export interface PromotionImageProps extends Promotion {
  onClick?: () => void;
}

const useStyles = createUseStyles(styles, { name: 'PromotionImage' });

const PromotionImage = ({
  title,
  imageSmall,
  imageMedium,
  imageLarge,
  image: imageOriginale,
  onClick,
  ...rest
}: PromotionImageProps): JSX.Element => {
  const classes = useStyles();
  const browser = useBrowser();
  const [containerWidth, setContainerWidth] = useState(0);

  let customWidth = 1290;
  let image = imageOriginale;

  if (browser.down(breakpoints.ml)) {
    customWidth = 900;
    if (imageMedium) {
      image = imageMedium;
    }
  }

  if (browser.down(breakpoints.sm)) {
    customWidth = browser.down(breakpoints.xs) ? 480 : 768;
    if (imageSmall) {
      image = imageSmall;
    }
  }

  if (browser.up(breakpoints.ml)) {
    if (imageLarge) {
      image = imageLarge;
    }
  }

  if (isEmpty(image)) {
    return null;
  }

  const { height, width, url } = image;
  let customHeight;
  if (customWidth < containerWidth) {
    customHeight = customWidth / (width / height);
  } else {
    customHeight = containerWidth / (width / height);
  }

  const refLoad = (e: HTMLDivElement) => {
    if (e) {
      const { width: elementWidth } = e.getBoundingClientRect();
      const intWidth = Math.round(elementWidth);
      if (intWidth && intWidth !== containerWidth) {
        setContainerWidth(intWidth);
      }
    }
  };

  return (
    <div className={classes.image} ref={refLoad}>
      <img
        data-key="tile-image"
        style={{ height: customHeight }}
        onClick={onClick}
        data-testid={rest['data-testid']}
        className={classNames(classes.image, {
          [classes.pointer]: !!onClick,
        })}
        alt={title}
        src={getImageWithResamplingQuery({
          url,
          customWidth,
        })}
      />
    </div>
  );
};

export default PromotionImage;
