import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectStyles } from 'injectStyles';
import IMAGE_POSITIONS from './Image.const';

function Image({ classes, name, media, size, offset, align }) {
  if (!media || !media.url) return null;
  return (
    <img
      className={classNames(classes.image, {
        [classes.size]: size,
        [classes.offset]: offset,
        [classes[align]]: align,
      })}
      alt={name}
      src={media.url}
    />
  );
}

Image.propTypes = {
  classes: PropTypes.object.isRequired,
  media: PropTypes.object,
  name: PropTypes.string,
  size: PropTypes.number,
  align: PropTypes.oneOf(Object.values(IMAGE_POSITIONS)),
  offset: PropTypes.number,
};

Image.defaultProps = {
  media: null,
  name: '',
  size: null,
  align: null,
  offset: null,
};

const styles = (theme) => ({
  image: {
    position: 'relative',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    margin: '20px auto',
  },
  size: ({ size }) => ({
    maxWidth: size,
  }),
  offset: ({ offset }) => ({
    top: offset,
  }),
  [theme.breakpoints.down('ml')]: {
    size: ({ size }) => ({
      maxWidth: size / 1.5,
    }),
  },
  // Aligns
  left: {
    marginLeft: 0,
  },
  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  right: {
    marginRight: 0,
  },
});
export default injectStyles(styles)(Image);
