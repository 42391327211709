export default function generateMenuSelectData(menuItem, pathname, type) {
  return {
    event_name: 'menu_select',
    event_category: 'menu',
    event_action: 'select',
    event_label: menuItem || null,
    page_id: (pathname && pathname.split('/').pop().replace(/#.*$/, '')) || 'home',
    link_id: type || null,
  };
}

export function trackMenuClick(menuItem, type) {
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'menu_select',
      event_category: 'menu',
      event_action: 'select',
      event_label: menuItem || null,
      link_id: type || null,
    },
  ]);
}
