import gql from 'graphql-tag';
import { MEDIA_FRAGMENT, IMAGE_FRAGMENT } from './fragments';

export const getSchedules = gql`
  query GetSchedules($slug: String!, $broadcastStartDate: AWSDateTime!, $broadcastEndDate: AWSDateTime!) {
    station(slug: $slug) {
      id
      schedules(broadcastStartDate: $broadcastStartDate, broadcastEndDate: $broadcastEndDate) {
        show {
          id
          title
          slug
          media {
            ...MediaFragment
          }
          images {
            ...ImageFragment
          }
          startTime
          endTime
          refs {
            targetType
            uri
          }
          config {
            type
            entries {
              key
              value
              type
            }
          }
        }
        broadcastStartDate
        broadcastEndDate
      }
    }
  }
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

/*
 * TODO - Double check in clean up ticket, this should probably be updated
 * getShows query fetch deprecated fields
 */
export const getShows = gql`
  query GetShows($slug: String!) {
    collection(slug: $slug) {
      ... on ShowCollection {
        id
        title
        description
        items {
          id
          type
          title
          shortTitle
          slug
          description
          media {
            ...MediaFragment
          }
          images {
            ...ImageFragment
          }
          config {
            type
            entries {
              key
              value
              type
            }
          }
          startTime
          endTime
          refs {
            targetType
            uri
          }
          episodes(limit: 100) {
            id
            title
            description
            media {
              ...MediaFragment
            }
            durationSeconds
            broadcastDate
            publishDate
          }
        }
      }
    }
  }
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

export const getShow = gql`
  query GetShow($slug: String!) {
    show(slug: $slug) {
      id
      type
      title
      shortTitle
      slug
      description
      media {
        ...MediaFragment
      }
      images {
        ...ImageFragment
      }
      config {
        type
        entries {
          key
          value
          type
        }
      }
      startTime
      endTime
      episodes(limit: 100) {
        id
        type
        title
        description
        media {
          ...MediaFragment
        }
        images {
          ...ImageFragment
        }
        durationSeconds
        broadcastDate
        publishDate
      }
    }
  }
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

export const getShowProgress = gql`
  query GetShow($slug: String!) {
    item: show(slug: $slug) {
      episodes(limit: 100) {
        id
        progress {
          completed
          durationLength
          contentLength
        }
      }
    }
  }
`;

export const scheduleSubscription = gql`
  subscription onStationEvent($profile: String!, $slug: String!) {
    scheduleEvent(slug: $slug, profile: $profile) {
      slug
      schedule {
        show {
          id
          slug
          startTime
          endTime
        }
      }
    }
  }
`;
