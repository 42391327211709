import * as analytics from 'analytics';
import { playbackModes } from 'store/reducers/player';
import { getAirDate } from 'lib/onDemand';
import { PLAYER_STATE, CAST_STATE, ERROR_CODES, CAST_SENDER_SRC, CAST_CONTEXT_EVENT_TYPE } from './Players.const';

class ChromeSender {
  constructor() {
    // Make sure the SDK is loaded only once
    if (typeof cast === 'undefined') {
      this.loadChromeSenderSDK();
    }
  }

  /* Cast player variables */
  /** @type {cast.framework.RemotePlayer} */
  remotePlayer = null;

  /** @type {cast.framework.RemotePlayerController} */
  remotePlayerController = null;

  /** @type {cast.framework.CastSession} */
  castSession = null;

  /** @type {?number} A timer for tracking progress of media */
  timer = null;

  /* local variables */
  /** @type {?string} Playback Mode of current casted media */
  playbackMode = null;

  /** @type {?string} Session Id of current casted media */
  playSessionId = null;

  /** @type {?object} Current casted station */
  station = null;

  /** @type {?object} Current casted ondemand media */
  onDemandClip = null;

  /** @type {?number} Current time of casted media */
  currentTime = 0;

  /** @type {?number} Duration of currently casted ondemand media */
  duration = 0;

  /**
   * Adds a <script> tag to the document HEAD
   */
  loadChromeSenderSDK = () => {
    const script = document.createElement('SCRIPT');

    script.src = CAST_SENDER_SRC;
    script.async = true;

    document.getElementsByTagName('HEAD')[0].appendChild(script);
  };

  /**
   * Initializes Cast Player on __onGCastApiAvailable
   */
  initializeCastPlayer = () => {
    const options = {};
    if (typeof chrome.cast === 'undefined') return;
    // Set the receiver application ID to your own (created in the Google Cast Developer Console), or optionally
    // use the chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
    options.receiverApplicationId = process.env.REACT_APP_CHROME_SENDER_ID
      ? process.env.REACT_APP_CHROME_SENDER_ID
      : chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;

    // Auto join policy can be one of the following three:
    //  ORIGIN_SCOPED - Auto connect from same appId and page origin
    //  TAB_AND_ORIGIN_SCOPED - Auto connect from same appId, page origin, and tab
    //  PAGE_SCOPED - No auto connect
    options.autoJoinPolicy = chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;

    const context = cast.framework.CastContext.getInstance();

    cast.framework.CastContext.getInstance().setOptions(options);

    this.remotePlayer = new cast.framework.RemotePlayer();
    this.remotePlayerController = new cast.framework.RemotePlayerController(this.remotePlayer);

    context.addEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, this.onCastStateChanged);

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      this.onConnectionChanged
    );

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
      this.onPlayerStateChange
    );

    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED, () =>
      this.setMutedCallback(this.remotePlayer.isMuted)
    );

    this.remotePlayerController.addEventListener(cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED, () =>
      this.setVolumeLevelCallback(this.remotePlayer.volumeLevel)
    );

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
      this.onCurrentTimeChange
    );

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.DURATION_CHANGED,
      this.onDurationChange
    );

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
      ({ value: isMediaLoaded }) => isMediaLoaded === false && this.endedPlayCallback()
    );
  };

  /**
   * Checks whether casting is available and casting devices is connected or not
   * @returns {boolean} */
  isCasting = () => typeof cast !== 'undefined' && cast.framework && this.remotePlayer && this.remotePlayer.isConnected;

  /**
   * Checks whether or not current media is live audio
   * @returns {boolean}
   */
  isLiveAudio = () => this.playSessionId && this.playbackMode === playbackModes.LIVE_AUDIO;

  /**
   * Checks whether or not current media is live video
   * @returns {boolean}
   */
  isLiveVideo = () => this.playSessionId && this.playbackMode === playbackModes.LIVE_VIDEO;

  /**
   * Checks whether or not current media is ondemand
   * @returns {boolean}
   */
  isOnDemand = () => this.playSessionId && this.playbackMode === playbackModes.ON_DEMAND_CLIP;

  /**
   * Checks whether or not media is played first
   * @returns {boolean}
   */
  isFirstPlay = () => {
    if (this.playSessionId === this.playingSessionId) return false;
    this.playingSessionId = this.playSessionId;
    return true;
  };

  onCastStateChanged = ({ type, castState }) => {
    if (type === CAST_CONTEXT_EVENT_TYPE.CAST_STATE_CHANGED) {
      if (castState === CAST_STATE.NO_DEVICES_AVAILABLE) {
        this.castingUnavailableCallback();
      } else {
        this.castingAvailableCallback();
      }
    }
  };

  /**
   * Handler if isConnected has changed
   */
  onConnectionChanged = () => {
    if (this.isCasting()) {
      this.castSession = cast.framework.CastContext.getInstance().getCurrentSession();
      const castDevice = this.castSession.getCastDevice().friendlyName;
      this.castingStartCallback(castDevice);
      return;
    }

    this.castingStopCallback();
  };

  /**
   * Handler if playerState has changed
   */
  onPlayerStateChange = ({ value: playerState }) => {
    switch (playerState) {
      case PLAYER_STATE.PLAYING:
        this.playerPlayingCallback();
        if (this.isFirstPlay()) {
          if (this.isLiveAudio()) analytics.trackPlayLiveAudio(this.station, this.currentTime);
          if (this.isLiveVideo()) analytics.trackPlayLiveVideo(this.station, null, true);
          if (this.isOnDemand())
            analytics.trackPlayOnDemandClip(this.onDemandClip, this.currentTime, this.duration, true);
        } else {
          if (this.isLiveAudio()) analytics.trackResumeLiveAudio(this.station, this.currentTime, true);
          if (this.isLiveVideo()) analytics.trackResumeLiveVideo(this.station, null, true);
          if (this.isOnDemand())
            analytics.trackResumeOnDemand(this.onDemandClip, this.currentTime, this.duration, true);
        }
        break;
      case PLAYER_STATE.PAUSED:
      case PLAYER_STATE.STOPPED:
        if (this.playSessionId) {
          this.endedPlayCallback();
          analytics.stopHeartBeat();
          if (this.isLiveAudio()) analytics.trackStopLiveAudio(this.station, this.currentTime, true);
          if (this.isLiveVideo()) analytics.trackStopLiveVideo(this.station, null, true);
          if (this.isOnDemand()) analytics.trackStopOnDemand(this.onDemandClip, this.currentTime, true);
        }
        break;
      default:
        break;
    }
  };

  /**
   * Handler if currentTime has changed
   */
  onCurrentTimeChange = ({ value: time }) => {
    if (this.isOnDemand()) this.timeUpdateCallback(time);

    this.currentTime = time;
  };

  /**
   * Handler if duration has changed
   */
  onDurationChange = ({ value: duration }) => {
    if (duration) this.durationChangedCallback(duration);

    this.duration = duration;
  };

  /**
   * Handler if loadmedia fails
   */
  onError = (errorCode) => {
    this.errorCallback(this.getErrorMessage(errorCode));
    throw new Error(this.getErrorMessage(errorCode));
  };

  /**
   * Plays the audio playback on cast device
   * @param {object} MediaInformation
   */
  play = async ({
    playSessionId,
    currentOnDemandClip,
    onDemandPlaybackTime,
    onDemandDuration,
    currentStation,
    playbackMode,
  }) => {
    if (!this.isCasting()) {
      this.castingStopCallback();
      return;
    }

    if (playSessionId !== null && playSessionId === this.playSessionId && this.remotePlayer.isPaused) {
      analytics.trackResumeOnDemand(this.onDemandClip, onDemandPlaybackTime, onDemandDuration, true);
      this.remotePlayerController.playOrPause();
      return;
    }

    let contentId = null;
    let contentType = null;

    const metadata = {};
    const customData = {};
    switch (playbackMode) {
      case playbackModes.LIVE_AUDIO:
        this.station = currentStation;
        this.onDemandClip = null;
        this.currentTime = 0;
        analytics.trackStartLiveAudio(this.station, this.currentTime);
        contentId = currentStation && currentStation.audioUrl;
        contentType = 'audio/mp3';
        metadata.title = currentStation.name ? currentStation.name : currentStation.subtitle;
        metadata.images = [currentStation.logo.url];

        customData.isOnDemand = false;
        // Quickfix until slugs are aligned
        // customData.stationName = currentStation.name ? currentStation.name : currentStation.subtitle;
        // customData.stationSlug = currentStation.slug;
        customData.stationName = currentStation.name === '538' ? 'Radio 538' : currentStation.name;
        customData.stationSlug = currentStation.slug === '538' ? 'radio-538' : currentStation.slug;
        break;
      case playbackModes.LIVE_VIDEO:
        this.station = currentStation;
        this.onDemandClip = null;
        this.currentTime = 0;
        analytics.trackStartLiveVideo(this.station, null, true);

        contentId = currentStation && currentStation.videoUrl;
        contentType = 'video/mp4';
        metadata.title = currentStation.name ? currentStation.name : currentStation.subtitle;
        metadata.images = [currentStation.logo.url];
        customData.isOnDemand = false;
        // Quickfix until slugs are aligned
        // customData.stationSlug = currentStation.slug;
        customData.stationName = currentStation.name === '538' ? 'Radio 538' : currentStation.name;
        customData.stationSlug = currentStation.slug === '538' ? 'radio-538' : currentStation.slug;
        break;
      case playbackModes.ON_DEMAND_CLIP:
        this.station = null;
        this.currentTime = onDemandPlaybackTime;
        this.onDemandClip = currentOnDemandClip;
        this.duration = currentOnDemandClip.duration;
        analytics.trackStartOnDemandClip(this.onDemandClip, this.currentTime, this.duration, true);

        contentId = currentOnDemandClip && currentOnDemandClip.audioUrl;
        contentType = 'audio/mp3';
        metadata.title = currentOnDemandClip.title;
        metadata.subtitle = currentOnDemandClip.publishedUtc && getAirDate(currentOnDemandClip.publishedUtc);
        metadata.images = [currentOnDemandClip.imageUrl];
        customData.isOnDemand = true;
        customData.stationName =
          currentOnDemandClip.show && currentOnDemandClip.show.station && currentOnDemandClip.show.station.name;
        customData.stationSlug =
          currentOnDemandClip.show && currentOnDemandClip.show.station && currentOnDemandClip.show.station.slug;
        // Quickfix until slugs are aligned
        if (customData.stationName === '538') {
          customData.stationSlug = 'Radio 538';
        }
        if (customData.stationSlug === '538') {
          customData.stationSlug = 'radio-538';
        }
        break;
      default:
        this.station = null;
        this.onDemandClip = null;
        break;
    }

    if (!contentId) {
      this.playSessionId = null;
      this.playbackMode = null;
      return;
    }

    this.playSessionId = playSessionId;
    this.playingSessionId = null;
    this.playbackMode = playbackMode;

    const mediaInfo = new chrome.cast.media.MediaInfo(contentId, contentType);
    mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
    mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.GENERIC;
    mediaInfo.metadata = metadata;

    mediaInfo.customData = customData;

    const request = new chrome.cast.media.LoadRequest(mediaInfo);
    await this.castSession
      .loadMedia(request)
      .then(() => onDemandPlaybackTime && onDemandPlaybackTime > 0 && this.skipTo(onDemandPlaybackTime), this.onError);

    // Stop any heartbeat that is active
    analytics.stopHeartBeat();
    if (this.station || this.onDemandClip) analytics.startHeartBeat();
  };

  /**
   * Stops playback on cast device
   */
  stop = () => {
    if (this.isCasting()) {
      if (this.isOnDemand()) {
        this.remotePlayerController.playOrPause();
        analytics.trackStopOnDemand(this.onDemandClip, this.currentTime, this.duration, true);
      } else {
        this.remotePlayerController.stop();
        if (this.isLiveAudio()) analytics.trackStopLiveAudio(this.station, this.currentTime, true);
        if (this.isLiveVideo()) analytics.trackStopLiveVideo(this.station, null, true);
      }
    }
  };

  /**
   * Moves the audio playback forward using the given skipTime
   * @param {number} skipTime
   */
  skipForward(skipTime) {
    if (!this.isCasting()) return;

    this.remotePlayer.currentTime = Math.min(this.currentTime + skipTime, this.duration);
    this.remotePlayerController.seek();
    if (this.isOnDemand()) {
      analytics.trackSkipForwardOnDemand(skipTime, this.onDemandClip, this.currentTime, this.duration, true);
    }
  }

  /**
   * Moves the audio playback backward using the given skipTime
   * @param {number} skipTime
   */
  skipBackward(skipTime) {
    if (!this.isCasting()) return;

    this.remotePlayer.currentTime = Math.max(this.currentTime - skipTime, 0);
    this.remotePlayerController.seek();
    if (this.isOnDemand()) {
      analytics.trackSkipBackwardOnDemand(skipTime, this.onDemandClip, this.currentTime, this.duration, true);
    }
  }

  /**
   *  Moves the audio playback to the given skipTime
   * @param {number} skipTime
   */
  skipTo(skipTime) {
    if (!this.isCasting()) return;

    this.remotePlayer.currentTime = skipTime;
    this.remotePlayerController.seek();
    if (this.isOnDemand()) {
      analytics.trackSkipToOnDemand(skipTime, this.onDemandClip, this.currentTime, this.duration, true);
    }
  }

  /**
   * Sets Volume on cast device using the given volume
   * @param {number} volume
   */
  setVolumeLevel = (volume) => {
    if (this.isCasting()) {
      this.remotePlayer.volumeLevel = volume;
      this.remotePlayerController.setVolumeLevel();
    }
  };

  /**
   * Mutes or unmutes cast device using the given shouldBeMuted
   * @param {boolean} shouldBeMuted
   */
  setMuted = (shouldBeMuted) => {
    if (this.isCasting() && this.remotePlayer.isMuted !== shouldBeMuted) {
      this.remotePlayerController.muteOrUnmute();
    }
  };

  /**
   * Makes human-readable message from chrome.cast.Error
   * @param {chrome.cast.Error} error
   * @return {string} error message
   */
  getErrorMessage = (errorCode) => {
    if (typeof ERROR_CODES[errorCode] === 'undefined') return ERROR_CODES.DEFAULT;

    return ERROR_CODES[errorCode];
  };

  /**
   * Set Callbacks to be used by Chrome Sender
   */
  setCastingAvailableCallback = (callback) => {
    this.castingAvailableCallback = callback;
  };

  setCastingUnavailableCallback = (callback) => {
    this.castingUnavailableCallback = callback;
  };

  setCastingStopCallback = (callback) => {
    this.castingStopCallback = callback;
  };

  setCastingStartCallback = (callback) => {
    this.castingStartCallback = callback;
  };

  setPlayerPlayingCallback = (callback) => {
    this.playerPlayingCallback = callback;
  };

  setSetMutedCallback = (callback) => {
    this.setMutedCallback = callback;
  };

  setSetVolumeLevelCallback = (callback) => {
    this.setVolumeLevelCallback = callback;
  };

  setEndedPlayCallback(callback) {
    this.endedPlayCallback = callback;
  }

  setTimeUpdateCallback = (callback) => {
    this.timeUpdateCallback = callback;
  };

  setDurationChangedCallback = (callback) => {
    this.durationChangedCallback = callback;
  };

  setErrorCallback(callback) {
    this.errorCallback = callback;
  }
}

const chromeSender = new ChromeSender();

window['__onGCastApiAvailable'] = (isAvailable) => {
  //eslint-disable-line
  if (isAvailable) {
    chromeSender.initializeCastPlayer();
  }
};
export default chromeSender;
