import { importScript } from 'util/script';

// Depends on the Gigya script being initialized on the Tealium side
export const isGigyaReady = (): boolean => !!window?.gigya?.isReady;

export const importGigyaScript = async (): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const apiKey = process.env.REACT_APP_GIGYA_API_KEY;
    if (apiKey) {
      await importScript(`https://cdns.gigya.com/js/gigya.js?apikey=${process.env.REACT_APP_GIGYA_API_KEY}&lang=nl-inf`)
        .then(() => {
          const interval = setInterval(() => {
            // The promise will be resolved when the script is loaded and Tealium/TAQ initializes Gigya
            if (isGigyaReady()) {
              resolve();
              clearInterval(interval);
            }
          }, 50);
        })
        .catch((error) => {
          reject();
          throw error;
        });
    } else {
      throw new Error('Gigya apiKey is invalid!');
    }
  });
};

/**
 * Function initializes everything needed for Gigya
 * if init was already preformed it will be skipped
 */
export async function initGigya(): Promise<void> {
  if (!isGigyaReady()) {
    // This makes sure gigya profile fields are done automatically
    window.Taq.push([
      'load',
      'Gigya',
      {
        apiKey: process.env.REACT_APP_GIGYA_API_KEY,
      },
    ]);

    await importGigyaScript();

    window.__gigyaConf = {
      lang: 'nl',
      sessionExpiration: '-2',
      enableSSOToken: true,
    };
  }
}
