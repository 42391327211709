import * as cssVariable from 'cssVariables';
import { PLAYER_BOTTOM_HEIGHT } from './PlayerBottom.const';

export default (theme) => ({
  container: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    left: 0,
    width: '100%',
    height: PLAYER_BOTTOM_HEIGHT,
    backgroundColor: cssVariable.grey600,
    zIndex: theme.zIndex.playerBottom,
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    width: '100%',
    maxWidth: '1440px',
    height: PLAYER_BOTTOM_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
  },
});
