import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectStyles } from 'injectStyles';
import { isTouch } from 'util/device';

import styles from './Scrubber.styles';
import { omit } from 'util/index';

class Scrubber extends PureComponent {
  static defaultProps = {
    elapsedTime: 0,
    duration: 0,
    lightVariant: false,
    isPlaying: false,
  };

  static propTypes = {
    elapsedTime: PropTypes.number,
    duration: PropTypes.number,
    onScrubEnd: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    lightVariant: PropTypes.bool,
    isPlaying: PropTypes.bool,
  };

  /* A reference to the scrubbers bounding client rectangle */
  boundingClientRect = null;

  constructor(props) {
    super(props);
    this.trackContainerRef = null;
  }

  state = {
    isScrubbing: false,
    percentageDragged: 0,
    scrubTime: 0,
  };

  /**
   * React lifecycle function, removes event listeners when unmounted
   * @returns {number}
   */
  componentWillUnmount() {
    this.removeDraggingListeners();
  }

  /**
   * Calculates the time scrubbed based on the distance dragged
   * @returns {number}
   */
  getTimeScrubbed() {
    return (this.state.percentageDragged / 100) * this.props.duration;
  }

  /**
   * Calculates the percentage of the distance dragged within the bounding box
   * @param {number} dragAmount
   * @returns {number}
   */
  getPercentageDragged(dragAmount) {
    const draggedRatio = dragAmount / this.boundingClientRect.width;
    return draggedRatio * 100;
  }

  /**
   * Returns the completed percentage
   * @param {number} time
   * @returns {number}
   */
  getPercentageElapsedTime(time) {
    if (typeof time !== 'number' || time === 0) {
      return 0;
    }

    const ratio = time / this.props.duration;
    return ratio * 100;
  }

  /**
   * Returns the number of pixels dragged with mouse relative to the bounding box
   * @param {number} xPos
   * @returns {number}
   */
  getHorizontalDragValue = (xPos) => {
    if (typeof xPos !== 'number') {
      return 0;
    }

    let draggedX = xPos - (this.boundingClientRect.left + window.pageXOffset);

    draggedX = Math.max(draggedX, 0);
    draggedX = Math.min(draggedX, this.boundingClientRect.width);

    return draggedX;
  };

  /**
   * Function for handling the start of a scrubbing/dragging event
   */
  handleScrubStart = (event) => {
    this.setState({ isScrubbing: true });

    this.boundingClientRect = this.trackContainerRef.getBoundingClientRect();

    // Call handleMove once for single clicks
    this.handleMove(event);

    // Attach global listeners
    this.addDraggingListeners();
  };

  /**
   * Function which handles mouse move event focussing on horizontal movement
   * @param {MouseEvent} event
   */
  handleMove = (event) => {
    let userInputXPosition = 0;
    switch (event.type) {
      case 'touchmove':
        userInputXPosition = event.changedTouches[0].pageX;
        break;
      case 'touchstart':
        userInputXPosition = event.touches[0].pageX;
        break;
      default:
        userInputXPosition = event.pageX;
    }

    const dragAmount = this.getHorizontalDragValue(userInputXPosition);
    const percentageDragged = this.getPercentageDragged(dragAmount);

    this.setState({ percentageDragged });
  };

  /**
   * Function for handling the end of a scrubbing/dragging event
   */
  handleScrubEnd = () => {
    this.setState({ isScrubbing: false });

    // Remove global listeners
    this.removeDraggingListeners();

    // Finalize intent
    this.props.onScrubEnd(this.getTimeScrubbed());
  };

  /**
   * Creates event listeners for dragging behaviour
   */
  addDraggingListeners = () => {
    window.addEventListener('mousemove', this.handleMove);
    window.addEventListener('touchmove', this.handleMove);
    window.addEventListener('mouseup', this.handleScrubEnd);
    window.addEventListener('touchend', this.handleScrubEnd);
    window.addEventListener('touchcancel', this.handleScrubEnd);
  };

  /**
   * Removes event listeners for dragging behaviour
   */
  removeDraggingListeners = () => {
    window.removeEventListener('mousemove', this.handleMove);
    window.removeEventListener('touchmove', this.handleMove);
    window.removeEventListener('mouseup', this.handleScrubEnd);
    window.removeEventListener('touchend', this.handleScrubEnd);
    window.removeEventListener('touchcancel', this.handleScrubEnd);
  };

  setRef = (ref) => {
    this.trackContainerRef = ref;
  };

  secTommss = (seconds) => {
    const sign = seconds < 0 ? '-' : '';
    const min = Math.floor(Math.abs(seconds) / 60);
    const sec = Math.floor(Math.abs(seconds) % 60);
    return `${sign + (min < 10 ? '0' : '') + min}:${sec < 10 ? '0' : ''}${sec}`;
  };

  render() {
    const { isScrubbing, percentageDragged } = this.state;
    const { elapsedTime, duration, classes, lightVariant, isPlaying, ...rest } = this.props;

    const scrubbingTime = duration * (percentageDragged / 100);
    const formattableTime = isScrubbing ? scrubbingTime : elapsedTime;

    const formattedTime = this.secTommss(formattableTime);

    const trackWidth = isScrubbing ? percentageDragged : (elapsedTime / duration || 0) * 100;

    return (
      <div
        className={classNames(classes.container, {
          [classes.scrubberMobile]: lightVariant,
          [classes.animation]: isPlaying && !isScrubbing && elapsedTime > 0,
          [classes.hideOnMobile]: isTouch,
        })}
        {...omit(rest, ['onScrubEnd'])}
      >
        <div
          className={classes.trackContainer}
          ref={this.setRef}
          onMouseDown={this.handleScrubStart}
          onTouchStart={this.handleScrubStart}
          role="presentation"
        >
          <div className={classes.track} />
          <div className={classes.trackComplete} style={{ width: `${trackWidth}%` }} />
          <div className={classes.handle} style={{ left: `${trackWidth}%` }} />
        </div>
        <div className={classes.timeContainer}>
          <span>{formattedTime}</span>
        </div>
      </div>
    );
  }
}

export default injectStyles(styles, { name: 'PBScrubber' })(Scrubber);
