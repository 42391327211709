import React from 'react';
import PropTypes from 'prop-types';

import spinnerGradient from './spinner-gradient.svg';
import spinnerWhite from './spinner-white.svg';
import { DEFAULT_SIZE, DEFAULT_COLOR } from './Loader.const';
import { createUseStyles } from 'react-jss';

const styles = {
  '@keyframes loop': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  spinner: {
    position: 'relative',
    animation: '$loop 1.1s infinite linear',
    backgroundRepeat: 'no-repeat',
  },
  wrapper: {
    maxHeight: '75px',
  },
};

const useStyles = createUseStyles(styles, { name: 'Loader' });
const Loader = (props) => {
  const classes = useStyles();
  const { color, size, ...rest } = props;
  const spinnerColor = color === DEFAULT_COLOR ? `url(${spinnerGradient})` : `url(${spinnerWhite})`;

  return (
    <div data-testid={rest['data-testid']} className={classes.wrapper}>
      <div className={classes.spinner} style={{ background: spinnerColor, width: size, height: size }} />
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

Loader.defaultProps = {
  size: DEFAULT_SIZE,
  color: DEFAULT_COLOR,
};

export default Loader;
