export default (theme) => ({
  list: {
    backgroundColor: theme.palette.navigation.background,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
    zIndex: theme.zIndex.navigation + 4,
  },
});
