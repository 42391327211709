export default class Timer {
  alertAfterTime;

  alertCallback;

  recordTime = false;

  elapsedTime = 0;

  lastLoopTime = 0;

  alertCallbackCalled = false;

  constructor(alertAfterTime, loopIntervalTime, alertCallback) {
    this.alertAfterTime = alertAfterTime;
    this.alertCallback = alertCallback;
    this.lastLoopTime = Date.now();
    window.setInterval(this.loop.bind(this), loopIntervalTime);
  }

  loop() {
    if (!this.recordTime || this.alertCallbackCalled) {
      return;
    }

    this.addElapsedTimeSinceLastLoop();

    if (this.elapsedTime >= this.alertAfterTime) {
      this.alertCallback();
      this.alertCallbackCalled = true;
    }
  }

  startNew() {
    this.recordTime = true;
    this.elapsedTime = 0;
    this.lastLoopTime = Date.now();
    this.alertCallbackCalled = false;
  }

  pause() {
    this.addElapsedTimeSinceLastLoop();
    this.recordTime = false;
  }

  resume() {
    this.recordTime = true;
  }

  addElapsedTimeSinceLastLoop() {
    const now = Date.now();
    const elapsedSinceLastLoop = now - this.lastLoopTime;
    this.elapsedTime += elapsedSinceLastLoop;
    this.lastLoopTime = now;
  }
}
