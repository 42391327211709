import { UniversalTab } from 'api/interfaces';
import { isEmpty, parseConfig } from 'util/index';
import { Config } from '../interfaces';
import logger from 'util/logger';

/**
 * @class
 * A representation of an tab
 *
 * @param {object} tab - tab model
 */
export default class Tab {
  uuid: string;
  title: string;
  content: [
    {
      contentTypeId: string;
      slug: string;
      uuid: string;
      config: Config;
      options?: string[];
    }
  ];
  icon: {
    altText: string;
    title: string;
    url: string;
  };
  slug: string;

  constructor(tab: UniversalTab) {
    if (!tab) {
      return null;
    }

    this.title = tab.title;
    if (!isEmpty(tab.refs)) {
      this.slug = tab.refs[0].uri;
      this.uuid = tab.refs[0].id;
      this.content = [
        {
          contentTypeId: tab.refs[0].targetType,
          slug: tab.refs[0].uri,
          uuid: tab.refs[0].id,
          config: parseConfig(tab.config),
          options: tab.options,
        },
      ];
    } else {
      logger.warn('Tab model needs refs to create the content');
    }
    if (!isEmpty(tab.images)) {
      this.icon = {
        altText: tab.images[0].title,
        title: tab.images[0].title,
        url: tab.images[0].uri,
      };
    }
    // Make this class read-only
    Object.freeze(this);
  }
}
