import React, { useEffect, useRef, useState, ReactNode } from 'react';

import { Station, PodcastEpisode, Promotion } from 'api/models';
import { createUseStyles } from 'react-jss';
import styles from './Collection.styles';
import classNames from 'classnames';
import { DisplayLayout } from './Collection.const';
import { isMobile, isTablet, isTouch } from 'util/device';
import { scrollY } from 'util/noscroll';
import { TITLE, CLIPS_IN_PROGRESS_SLUG } from 'globalConst/const';
import PodcastFold from '../PodcastFold';
import CollectionContext from './CollectionContext';

import { isCarousel, withBackground, checkFullWidthList } from './Collection.util';

import Swimlane from '../Swimlane';
import CollectionTiles from './CollectionTiles';
import useFilterCollectionItems from './useFilterCollectionItems';
import * as analytics from 'analytics';
import { contains, isEmpty } from 'util/index';
import ShowMore from './ShowMore';
import CollectionEmpty from './CollectionEmpty';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import HeroImage, { HeroImageProps } from 'components/HeroImage/HeroImage';

export interface CollectionProps {
  contentTypeId: string;
  slug: string;
  uuid?: string;
  tileComponent: string;
  display?: string;
  sectionTitle?: string;
  title: string;
  description?: string;

  tileSizes?: string;

  rows: number;
  mobileRows: number;

  itemsPerRow?: number;
  items?: Array<Station | PodcastEpisode | Promotion>;

  // Context values options
  isInSearch?: boolean;
  isPodcastPage?: boolean;
  isInFold?: boolean;
  recentSearch?: boolean;
  isContinueListening?: boolean;

  // useFilterCollectionItems
  setUpcomingTracks?: (track: Array<PodcastEpisode> | null) => void;
  error?: boolean;
  lightText?: boolean;

  // Options
  playoutsDisabled?: boolean;
  isHighlighted?: boolean;
  showSectionTitle?: boolean;
  hideTitle?: boolean;
  showEmptyState?: boolean;
  hasEmptyStateComponent?: boolean;
  showCount?: boolean;
  showCollectionLink?: boolean;
  swipe?: boolean;

  // useFetch
  isComponentLoading?: boolean;

  // Pagination
  loadMore?: () => void;
  nextLoadCount?: number;
  hasMoreItems?: boolean;

  // top lists
  isListTile?: boolean;
  showNumbers?: boolean;
  Rows?: number;
}

interface CollectionTitleProps {
  isPodcastPage: boolean;
  className: string;
  children: ReactNode;
}

const SCROLL_OFFSET = 80;
const useStyles = createUseStyles(styles, { name: 'Collection' });

const CollectionTitle: React.FC<CollectionTitleProps> = ({ isPodcastPage, children, className }) => {
  return isPodcastPage ? <h4 className={className}>{children}</h4> : <h2 className={className}>{children}</h2>;
};

const Collection = (props: CollectionProps): JSX.Element => {
  const {
    contentTypeId,
    slug,
    uuid: collectionId,
    tileComponent,
    display,
    sectionTitle,
    title,
    description,

    rows: initialRows = 1,
    mobileRows = 1,

    itemsPerRow,
    items: initialItems,

    // Context values
    isInSearch,
    isPodcastPage,
    isInFold,
    recentSearch,

    // Options
    playoutsDisabled,
    isHighlighted,
    showSectionTitle = false,
    hideTitle = false,
    showEmptyState = false,
    hasEmptyStateComponent = false,
    showCount = false,
    showCollectionLink = true,
    swipe,

    // useFetch
    isComponentLoading,

    // Pagination
    loadMore,
    nextLoadCount = null,
    hasMoreItems = null,

    // top lists
    isListTile,
    showNumbers,
  } = props;
  const collectionRef: React.Ref<HTMLDivElement> = useRef();
  const classes = useStyles();
  const [failedItems, setFailedItems] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [items, setItems] = useState(initialItems);
  const rows = isExpanded ? Number.MAX_SAFE_INTEGER : initialRows;
  const isTouchForPromotionCollection = contentTypeId === CONTENT_TYPES.PROMOTION_COLLECTION && isTouch;

  useEffect(() => {
    setItems(initialItems);
    if (!isEmpty(initialItems)) {
      if (itemsPerRow * initialRows >= initialItems.length) {
        setIsExpanded(false);
      }
    }
  }, [initialItems]);

  useEffect(() => {
    setPageLoading(!pageLoading);
  }, []);

  const isFullWidthList = checkFullWidthList(
    rows,
    initialItems && initialItems.length ? initialItems.length : 0,
    isListTile
  );

  const displayInCarousel = isListTile ? !isMobile && !isTablet() : isCarousel(display);
  const { filteredItems, visibleItems } = useFilterCollectionItems(
    {
      ...props,
      items,
    },
    failedItems,
    displayInCarousel,
    isExpanded
  );

  if (!tileComponent || (isEmpty(filteredItems) && !showEmptyState)) {
    return null;
  }

  let ComponentWrapper = displayInCarousel ? Swimlane : CollectionTiles;

  if (!isComponentLoading && isEmpty(items) && showEmptyState) {
    if (!hasEmptyStateComponent) {
      return null;
    }
    ComponentWrapper = CollectionEmpty;
  }

  const enableCollectionModal = !isComponentLoading && showCollectionLink && items && items.length > visibleItems;

  const isHome = analytics.getIsOnHome();

  const handleShowMoreToggle = (nextIsExpanded: boolean) => {
    setIsExpanded(nextIsExpanded);

    if (hasMoreItems && loadMore) {
      if (nextIsExpanded) {
        loadMore();
      }
    }

    if (nextIsExpanded) {
      analytics.trackCollectionExpand(title);
    } else {
      analytics.trackCollectionCollapse(title);
    }

    if (!nextIsExpanded && collectionRef.current) {
      const { top } = collectionRef.current.getBoundingClientRect();
      scrollY(top - SCROLL_OFFSET, 1000);
    }
  };

  const handleError = (slug: string) => {
    if (slug && !contains(failedItems, slug)) {
      setFailedItems([...failedItems, slug]);
    }
  };

  const withFold = display === DisplayLayout.CAROUSEL_WITH_FOLD && !isMobile;
  const isContinueListeningCollection = slug === CLIPS_IN_PROGRESS_SLUG;

  const renderCollection = () => {
    return (
      <ShowMore
        hiddenItemsCount={
          !hasMoreItems || nextLoadCount === null ? items && items.length - itemsPerRow * rows : nextLoadCount
        }
        isCarousel={displayInCarousel}
        isExpanded={isExpanded}
        onClick={handleShowMoreToggle}
        isLoading={isComponentLoading}
        hideShowMoreButton={isTouchForPromotionCollection}
      >
        <>
          {(isComponentLoading || title || description) && !pageLoading && (
            <div
              className={classNames(classes.header, {
                [classes.hideHeader]: hideTitle,
              })}
            >
              {showSectionTitle && (
                <h2 data-testid="collection-section-title" className={classes.sectionTitle}>
                  {sectionTitle}
                </h2>
              )}

              <div
                className={classNames(classes.titleWrap, {
                  [classes.titleWrapMobile]: !enableCollectionModal && isHome && title !== TITLE.POPULAR_SEARCH,
                })}
              >
                <CollectionTitle className={classes.title} isPodcastPage={isPodcastPage}>
                  {title}
                  {showCount && !displayInCarousel && (
                    <span className={classes.count} data-testid="collection-count">
                      {' '}
                      {items && items.length}
                    </span>
                  )}
                </CollectionTitle>
                {description && <p className={classes.description}>・ {description}</p>}
              </div>
            </div>
          )}

          <div className={classes.collectionWrapper}>
            {/* FIX: should be included as regular props when CollectionTiles is refactored to TS component */}
            <ComponentWrapper
              {...{
                key: collectionId,
                contentTypeId,
                slug,
                tileComponent,
                swipe,
                itemsPerRow,
                mobileRows,
                rows,
                display,
                items: filteredItems,
                onError: handleError,
                peekNextItem: true,
                title,
                collectionId,
                isListTile,
                isFullWidthList,
                showNumbers,
                sectionTitle,
              }}
            />
          </div>
        </>
      </ShowMore>
    );
  };

  const renderHeroImage = () => {
    return <HeroImage {...(initialItems[0] as HeroImageProps)} />;
  };

  const retVal = (
    <CollectionContext.Provider
      value={{
        showNowPlayingInfo: !playoutsDisabled,
        isPodcastPage,
        isInSearch,
        recentSearch,
        withFold,
        isInFold,
        isContinueListening: isContinueListeningCollection,
      }}
    >
      <div
        ref={collectionRef}
        data-testid={`collection-${slug}`}
        className={classNames(classes.container, {
          [classes.carouselContainer]: displayInCarousel,
          [classes.containerBackground]: withBackground(display, contentTypeId) || isInFold,
          [classes.promotionContainerTouch]: isTouchForPromotionCollection,
        })}
      >
        {slug !== 'hero-collection' ? renderCollection() : renderHeroImage()}
        {withFold && <PodcastFold collectionId={collectionId} items={filteredItems} />}
      </div>
    </CollectionContext.Provider>
  );

  if (isHighlighted) {
    return (
      <div data-testid={`collection-highlighted-${slug}`} className={classes.highlight}>
        {retVal}
      </div>
    );
  }
  return retVal;
};

export default Collection;
