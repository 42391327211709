import { DEFAULT_BREAKPOINTS, TILE_BREAKPOINTS } from './Swimlane.sizes';
import { TILE_COMPONENT_TYPES } from '../TileComponent/TileComponent.const';
import { mediaQuery, breakpoints as BREAKPOINTS } from 'components/theme/breakpoints';
import tileStyles from 'tile-components-styles';
import * as cssVariable from 'cssVariables';

const generateBreakpoints = () => {
  const retVal = {};
  Object.values(TILE_COMPONENT_TYPES).forEach((tile) => {
    retVal[tile] = {};
    let minWidth = 0;
    let size = 0;

    const breakpoints = {
      ...DEFAULT_BREAKPOINTS,
      ...TILE_BREAKPOINTS[tile],
    };

    Object.keys(breakpoints).forEach((breakpoint) => {
      size = breakpoints[breakpoint];
      const maxWidth = BREAKPOINTS[breakpoint];

      // Base on tile breakpoints set what size will ti be between breakpoints
      const key = mediaQuery.betweenWidth(minWidth, maxWidth);

      retVal[key] = {
        // Include other tile breakpoints
        ...retVal[key],

        // Create new size for tile
        [tile]: {
          minWidth: `${size}%`,
          maxWidth: `${size}%`,
        },
      };
      // set current maxWidth as next min width for next breakpoint
      minWidth = maxWidth;
    });

    // set tile size for larger screens
    retVal[mediaQuery.minWidth(minWidth)] = {
      ...retVal[mediaQuery.minWidth(minWidth)],
      [tile]: {
        minWidth: `${size}%`,
        maxWidth: `${size}%`,
      },
    };
  });

  return retVal;
};

export default {
  swimlane: {
    '&:hover > div[data-testid*="swimlane-arrow"]': {
      display: 'flex',
    },
  },
  wrapper: {
    position: 'relative',
    overflow: 'scroll',
    overflowY: 'hidden',
    'scroll-behavior': 'smooth',
    marginRight: -40,
    paddingRight: 40,
    marginLeft: -45,
    paddingLeft: 45,
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&:before, &:after': {
      content: '""',
      display: 'block',
      minWidth: 40,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  hoverOverlayDefault: {
    ...tileStyles.hoverOverlayDefault,
    zIndex: 6,
    opacity: 1,
  },
  hoverBackdropDefault: {
    zIndex: 11,
    display: 'flex',
    padding: [0, 5, 0, 0],
  },
  button: {
    width: 35,
    height: 35,
    padding: 0,
    margin: [0, 0, 0, 14.5],
    color: cssVariable.white,
    backgroundColor: cssVariable.transparentLightmode040,
    borderRadius: '50%',
    border: 'none',
    '& > span': {
      padding: 0,
      '& > svg': {
        fill: 'none',
        stroke: '#FFF',
        width: '100%',
        height: '100%',
      },
    },
    '&:hover': {
      width: 44,
      height: 44,
      margin: [0, 0, -5, 10],
      backgroundColor: cssVariable.white,
      color: cssVariable.green500,
      '& > span': {
        '& > svg': {
          color: cssVariable.black,
          '& polygon': {
            fill: cssVariable.green500,
          },
        },
      },
    },
  },
  chevron: {
    verticalAlign: 'middle',
    marginTop: -4,
    height: 26,
    width: 26,
  },
  link: {
    position: 'absolute',
    left: 10,
    bottom: 5,
    fontSize: 13,
    fontWeight: cssVariable.fontWeights.light,
    color: cssVariable.white,
    textDecoration: 'none',
  },
  [mediaQuery.maxWidth(BREAKPOINTS.sm)]: {
    wrapper: {
      marginRight: -20,
      paddingRight: 20,
      marginLeft: -15,
      paddingLeft: 15,
      '&:before, &:after': {
        content: '""',
        display: 'block',
        minWidth: 20,
      },
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '4px',
  },
  items: {
    display: 'flex',
  },
  item: {
    display: 'relative',
    padding: 5,
    transition: 'width 250ms, height 250ms',
    zIndex: 5,
  },
  itemList: {
    minWidth: '50% !important',
    width: '50% !important',
    height: 'auto',
    paddingRight: 15,
  },
  itemListFullWidth: {
    minWidth: '100% !important',
    width: '100% !important',
    height: 'auto',
    paddingRight: 15,
  },
  ...generateBreakpoints(),
};
