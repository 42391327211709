import { isEmpty } from './index';

type CompareValue = boolean | string | Array<string>;

/**
 * Check if value1 contains value2
 *
 * @param value1
 * @param value2
 * @param caseSensitive
 */
const contains = (value1: CompareValue, value2: CompareValue, caseSensitive = false): boolean => {
  if (isEmpty(value1) && isEmpty(value2)) {
    return true;
  }

  if (isEmpty(value1) || isEmpty(value2)) {
    return false;
  }

  if (value1 === value2) {
    return true;
  }

  if (Array.isArray(value1)) {
    return value1.some((val) => {
      return contains(val, value2, caseSensitive);
    });
  }

  if (typeof value1 === 'string') {
    if (caseSensitive) {
      return value1.indexOf(<string>value2) > -1;
    }
    return value1.toLowerCase().indexOf((<string>value2).toLowerCase()) > -1;
  }

  // TODO: If value1 is object check if value2 is in it
  // If value2 is string check if key or value is in value1 object with that string
  // If value2 is object check if key/value pars are in value1 object

  return false;
};

export default contains;
