import React, { useEffect, useState, ReactNode } from 'react';
import { DEFAULT_REMOTE_CONFIG_FLAGS } from './featureFlags.const';
import { IRemoteConfigFlags } from './featureFlags.types';
import { getFlagStore } from './featureFlags.util';
import FlagsContext from './FlagsContext';

export const FlagProvider: React.FC<{ children: ReactNode }> = ({ children }): JSX.Element => {
  const [flagList, setFlagList] = useState<IRemoteConfigFlags>(DEFAULT_REMOTE_CONFIG_FLAGS);

  useEffect(() => {
    getFlagStore().then((featureFlags) => setFlagList(featureFlags));
  }, []);

  return <FlagsContext.Provider value={flagList}>{children}</FlagsContext.Provider>;
};
