import { connect } from 'react-redux';
import { getIsOpen, getOpenCollectionId, getPodcast } from 'store/reducers/podcastFold';
import { closeFold } from 'actions/podcastFold-actions';
import PodcastFold from './PodcastFold';

function mapStateToProps(state, { items, collectionId }) {
  const slug = getPodcast(state);
  return {
    isOpen: getIsOpen(state) && collectionId === getOpenCollectionId(state),
    podcast: items.find((podcast) => podcast.slug === slug),
    slug,
  };
}

const mapDispatchToProps = {
  closeFold,
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastFold);
