import gql from 'graphql-tag';
import { MEDIA_FRAGMENT, IMAGE_FRAGMENT } from './fragments';

export const getPodcast = gql`
  query GetPodcast($slug: String!, $limit: Int!) {
    podcast(slug: $slug) {
      title
      description
      slug
      id
      subTitle
      type
      feedUrl
      episodes(limit: $limit) {
        id
        media {
          ...MediaFragment
        }
        type
        slug
        title
        description
        publishDate
        durationSeconds
      }
      tags {
        slug
        title
        type
      }
      images {
        ...ImageFragment
      }
      refs {
        id
        type
        targetType
        uri
      }
      metadata {
        title
        description
        shareTitle
        shareText
        shareLink
      }
      labels
      author
      language
      publisher
    }
  }
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

export const getPodcastBasic = gql`
  query GetPodcast($slug: String!) {
    podcast(slug: $slug) {
      title
      description
      slug
      id
      subTitle
      type
      images {
        ...ImageFragment
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const getPodcastProgress = gql`
  query GetPodcast($slug: String!) {
    item: podcast(slug: $slug) {
      episodes(limit: 100) {
        id
        progress {
          completed
          durationLength
          contentLength
        }
      }
    }
  }
`;

export const getPodcastEpisodes = gql`
  query GetPodcast($slug: String!, $limit: Int!) {
    podcast(slug: $slug) {
      episodes(limit: $limit) {
        id
        media {
          ...MediaFragment
        }
        type
        title
        description
        publishDate
        durationSeconds
      }
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const getClip = gql`
  query getClip($slug: String) {
    clip(slug: $slug) {
      id
      mediaId
      type
      title
      slug
      description
      descriptionMarkdown
      durationSeconds
      broadcastDate
      publishDate
      episode
      progress {
        contentId
        contentLength
        contentType
        completed
        durationLength
        inProgress
      }
      media {
        ...MediaFragment
      }
      images {
        ...ImageFragment
      }
      reference {
        ... on Podcast {
          id
          slug
          title
          author
          type
          feedUrl
          labels
          subTitle
          publisher
        }
        ... on Show {
          id
          slug
          title
          type
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${MEDIA_FRAGMENT}
`;
