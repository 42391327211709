import { UniversalCollection, UniversalConfig } from 'api/interfaces';
import { isEmpty } from 'util/index';
import CollectionOptions from './CollectionOptions';
/**
 * @class
 * A representation of a universal collection
 *
 * @param {object} collection - collection model
 * @param {object} sectionConfig - universal api config
 * @param {object} ownProps - collection props
 */
export default class Collection extends CollectionOptions {
  id: string;
  uuid: string;
  slug: string;
  title: string;
  subtitle: string;
  description: string;
  sectionTitle: string;
  hasEmptyStateComponent = false;

  disableLazyFetch?: boolean;

  constructor(collection: UniversalCollection, config: UniversalConfig[], options: string[]) {
    super(collection, config, options);
    if (isEmpty(collection)) {
      return undefined;
    }

    this.disableLazyFetch = collection.disableLazyFetch;
    this.uuid = collection.id;
    this.id = collection.id;

    this.title = collection.title;
    this.slug = collection.slug;
    this.description = collection.description;
    this.subtitle = collection.description;
    this.sectionTitle = collection.sectionTitle;
  }
}
