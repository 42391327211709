import { fetchAppRating } from 'api/juke';
import { ratingActions } from 'actions/actions-const';
import logger from 'util/logger';
import { AppDispatch } from 'store';

export default function downloadAppRating() {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      const appRating = await fetchAppRating();

      dispatch({
        type: ratingActions.SET_APP_RATING,
        appRating,
      });
    } catch (error) {
      logger.error('Something went wrong while downloading app rating.', error);
    }
  };
}
