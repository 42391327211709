// Sorted ASC by size. That's important.
// It can't be configured as it's used statically for propTypes.
export const keys = ['xs', 'sm', 'md', 'sl', 'ml', 'll', 'lg'];

// Keep in mind that @media is inclusive by the CSS specification.
export function createBreakpoints(breakpoints) {
  const {
    values = {
      xs: 480,
      sm: 768,
      md: 1025,
      sl: 1281, // small laptop size
      ml: 1371, // medium laptop size
      ll: 1441, // large laptop size
      lg: 1601,
    },
    unit = 'px',
    step = 10,
    ...other
  } = breakpoints;

  function up(key) {
    const value = typeof values[key] === 'number' ? values[key] : key;
    return `@media (min-width:${value}${unit})`;
  }

  function down(key) {
    const endIndex = keys.indexOf(key);
    const upperbound = values[keys[endIndex]];

    if (endIndex === keys.length) {
      return up('xs');
    }

    const value = typeof upperbound === 'number' && endIndex >= 0 ? upperbound : key;
    return `@media (max-width:${value - step / 100}${unit})`;
  }

  function between(start, end) {
    const endIndex = keys.indexOf(end) + 1;

    if (endIndex === keys.length) {
      return up(start);
    }

    return (
      `@media (min-width:${values[start]}${unit}) and ` + `(max-width:${values[keys[endIndex]] - step / 100}${unit})`
    );
  }

  function only(key) {
    return between(key, key);
  }

  function width(key) {
    return values[key];
  }

  return {
    keys,
    values,
    up,
    down,
    between,
    only,
    width,
    ...other,
  };
}
