import { UniversalCollectionItem, UniversalConfig, UniversalImage, UniversalMedia } from 'api/interfaces/universalApi';
import { Image } from 'api/interfaces/image';
import CollectionItem from './CollectionItem';
import isEmpty from 'util/isEmpty';
import logger from 'util/logger';
import { CONFIG_KEY } from 'globalConst/const';
/**
 * @class
 * A representation of a Station
 *
 * @param {object} station - station model
 */

declare const __URL_PREFIX__: string;

interface Metadata {
  title: string;
  tags: string[];
  description: string;
  contentType: string;
  share: {
    image: Image;
    url: string;
    title: string;
    description: string;
  };
}
interface UniversalMetadata {
  shareTitle: string;
  shareLink: string;
  shareText: string;
  contentType: string;
  tags: string[];
}

export interface UniversalStation extends UniversalCollectionItem {
  shortTitle: string;
  getPlayouts: {
    playouts?: [{ track?: { artistName: string; title: string } }];
  };
  media: Array<UniversalMedia>;
  tags: [{ type: string; title: string }];
  images: Array<UniversalImage>;
  config: Array<UniversalConfig>;
  metadata?: UniversalMetadata;
  labels: string[];
}

export default class Station extends CollectionItem {
  name?: string;
  subtitle?: string;
  shortTitle?: string;
  playouts?: string;
  genres?: string[];
  metadata?: Metadata;
  labels?: string[];

  tritonMount?: string;
  tritonMountHD?: string;
  audioUrl?: string;
  audioUrlAac?: string;
  audioUrlHls?: string;
  videoUrl?: string;
  media?: Array<UniversalMedia>;

  image: Image;
  logo: Image;
  logoSecondary?: Image;
  headerImage?: Image;
  shareImage?: Image;

  brandColor?: string;
  brandName?: string;
  brandSlug?: string;
  linkedPage?: string;

  hasPage: boolean;

  // Check for station to see if its needed to be re fetch
  hasMedia? = false;

  constructor(item: UniversalCollectionItem) {
    super(item);
    if (!item) {
      return undefined;
    }

    const station = <UniversalStation>item;

    this.name = station.title;

    if (station.description) {
      this.subtitle = station.description;
    }
    this.shortTitle = station.shortTitle;

    if (station.getPlayouts && station.getPlayouts.playouts && station.getPlayouts.playouts.length) {
      const [playout] = station.getPlayouts.playouts;
      this.playouts = `${playout.track?.artistName} - ${playout.track?.title}`;
    }

    this.media = station.media;

    this.genres =
      station.tags && Array.isArray(station.tags)
        ? station.tags.reduce((genres: string[], item: { type: string; title: string }) => {
            if (item.type === 'genre') {
              genres.push(item.title);
            }
            return genres;
          }, [])
        : [];

    if (station.media && Array.isArray(station.media)) {
      this.hasMedia = true;
      station.media.forEach(({ source, uri }) => {
        switch (source) {
          case 'mount_point':
            this.tritonMount = uri;
            break;
          case 'mount_point_hd':
            this.tritonMountHD = uri;
            break;
          case 'audio_mp3':
            this.audioUrl = uri;
            break;
          case 'audio_aac':
            this.audioUrlAac = uri;
            break;
          case 'audio_hls':
            this.audioUrlHls = uri;
            break;
          case 'video_hls':
            this.videoUrl = uri;
            break;
          default:
            logger.warn(`Station missing source: ${source}`);
            break;
        }
      });
    }

    this.type = this.tritonMount ? 'triton' : 'generic';

    let shareImage;

    let background = null;

    if (station.images && Array.isArray(station.images)) {
      station.images.forEach(({ imageType, uri, title }) => {
        switch (imageType) {
          case 'background':
            background = {
              url: uri,
              altText: title || this.title,
            };
            break;
          case 'logo':
            this.logoSecondary = {
              url: uri,
              altText: title || this.title,
            };
            break;
          case 'logoWhite':
            this.logo = {
              url: uri,
              altText: title || this.title,
            };
            break;
          case 'headerImage':
            this.headerImage = {
              url: uri,
              altText: title,
            };
            break;
          default:
            break;
        }
      });
    }

    this.image = background;

    if (station.config && Array.isArray(station.config)) {
      station.config.forEach(({ entries }) =>
        entries.forEach(({ key, value }) => {
          // No brand Color for juke JUKE Originals
          if (key === CONFIG_KEY.BRAND_NAME && value === 'JUKE Originals') {
            this.brandColor = 'linear-gradient(148.06deg, #37B376 0%, #93C01F 62.14%, #FACF18 100%';
          }

          switch (key) {
            case CONFIG_KEY.BRAND_NAME:
              this.brandName = value;
              break;
            case CONFIG_KEY.BACKGROUND_COLOR:
              this.brandColor = value;
              break;
            case CONFIG_KEY.MAIN_STATION_SLUG:
              //this.linkedPage = value;
              break;
            case CONFIG_KEY.BRAND_SLUG:
              this.brandSlug = value;
              this.linkedPage = value === this.slug ? `/radiozenders/${value}` : `/radiozenders/${value}/${this.slug}`;
              break;
            default:
              // logger.warn(`Station missing config: ${key}`);
              break;
          }
        })
      );

      const currentPath = window.location.pathname.replace(__URL_PREFIX__, '');
      this.hasPage = this.linkedPage === currentPath ? null : !!this.linkedPage;
    }

    if (!isEmpty(station.metadata) && !isEmpty(station.metadata[0])) {
      const universalMetadata = station.metadata[0];
      this.metadata = {
        tags: station.labels,
        title: universalMetadata.shareTitle,
        description: universalMetadata.shareText,
        contentType: universalMetadata.contentType,
        share: {
          image: shareImage,
          url: universalMetadata.shareLink,
          description: universalMetadata.shareText,
          title: universalMetadata.shareTitle,
        },
      };
    }

    // this.tags = item.fields.tags; // Not related to collection <------------
    // this.relatedStations = new stationModel.StationCollection(item.fields.relatedStations); // Not related to collection <------------
    // this.relatedShows = new showModel.ShowCollection(item.fields.relatedShows); // Not related to collection <------------
    // this.relatedGenres = new genreModel.GenreCollection(item.fields.relatedGenres); // Not related to collection <------------

    if (!this.contentTypeId) {
      this.contentTypeId = 'station';
    }

    // Make this class read-only
    Object.freeze(this);
  }
}
