import { Styles } from 'jss';
import * as cssVariables from 'cssVariables';

const styles = (): Styles => ({
  container: {
    width: 'calc(100% + 40px)',
    marginTop: '-80px',
  },
  promotionImage: {
    height: 400,
    width: 'inherit',
    maxWidth: 'none',
    objectFit: 'cover',
  },
  promotionImageTablet: {
    width: 'initial',
    transform: 'translateX(-20%)',
  },
  promotionImageMobile: {
    height: 200,
    width: 'initial',
    transform: 'translateX(-28%)',
  },
  promotionImageLandscape: {
    transform: 'translateX(0%)',
    width: '100%',
  },
  promotionImageOpacity: {
    opacity: '60%',
  },
  content: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '0',
    width: 550,
    textAlign: 'left',
  },
  contentMobile: {
    transform: 'translateY(-30%)',
  },
  contentText: {
    fontSize: 44,
    lineHeight: '56px',
    fontWeight: 'bold',
    marginBottom: 20,
    hyphens: 'none',
    textShadow: `
    -1px -1px 20px #fff,
    1px -1px 20px #fff,
    -1px 1px 20px #fff,
    1px 1px 20px #fff,
    0px 0px 20px #fff
  `,
  },
  contentTextTablet: {
    width: 450,
    fontSize: 32,
    lineHeight: '40px',
  },
  contentTextMobile: {
    width: 300,
    fontSize: 22,
    lineHeight: '26px',
  },
  contentButton: {
    flexDirection: 'row-reverse',
    padding: '0 0 0 20px !important',
    width: 200,
    height: 50,
    justifyContent: 'space-between',
    marginRight: 20,
    fontSize: 16,
  },
  contentButtonTablet: {
    width: 180,
    height: 45,
    fontSize: 15,
    paddingLeft: '15px !important',
  },
  contentButtonMobile: {
    width: 160,
    height: 40,
    fontSize: 13,
    paddingLeft: '15px !important',
    marginRight: 10,
  },
  contentButtonWhite: {
    backgroundColor: cssVariables.white,
  },
});

export default styles;
