import { isEmpty, isFunction } from './index';

function omit(obj: Record<string, unknown>, condition: unknown): Record<string, unknown> {
  if (isEmpty(condition) || isEmpty(obj)) {
    return obj;
  }
  if (Array.isArray(condition)) {
    const keys: Array<string> = condition;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [keys.pop()]: omitted, ...rest } = obj;

    return omit(rest, keys);
  }

  if (isFunction(condition)) {
    const isValid = <(val: unknown) => boolean>condition;
    const retVal = {};
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!isValid(value)) {
        retVal[key] = value;
      }
    });
    return retVal;
  }
}

export default omit;
