import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import { getImageWithResamplingQuery } from 'util/resampling';
import styles from '../OnDemandTile/OnDemandTileThumbStyles';

class ShowTileBrand extends PureComponent {
  static propTypes = {
    station: PropTypes.object.isRequired,
    altStationLogo: PropTypes.object,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    altStationLogo: null,
  };

  render() {
    const { station, altStationLogo, classes } = this.props;
    const logoUrl = (altStationLogo && altStationLogo.url) || (station && station.logo && station.logo.url);

    return (
      <div className={classes.brandWrapper}>
        {station && station.hideLogo && station.logo.altText ? (
          <div className={classes.subTitle}>{station.logo.altText}</div>
        ) : (
          <div className={classes.logoContainer}>
            <div
              className={classes.image}
              style={{
                backgroundImage: `url(${getImageWithResamplingQuery({
                  url: logoUrl,
                  isTransparent: true,
                  customWidth: 80,
                })})`,
              }}
              title={station && station.logo ? station.logo.altText : ''}
            />
          </div>
        )}
      </div>
    );
  }
}

export default injectStyles(styles)(ShowTileBrand);
