// Show more config for the search result page
const SHOW_MORE = {
  STATIONS: {
    LABEL: 'Bekijk alle radiozenders',
    SLUG: 'radiozenders',
  },
  GENRES: {
    LABEL: 'Bekijk alle muziekgenres',
    SLUG: 'muziekgenres',
  },
  MOODS: {
    LABEL: 'Bekijk alle moods',
    SLUG: 'moods-en-momenten',
  },
  PODCASTS: {
    LABEL: 'Bekijk alle podcasts',
    SLUG: 'podcasts',
    EPISODE_LIMIT: 3,
    DEFAULT_EPISODE_LIMIT: 5,
  },
};

export { SHOW_MORE as default };
