import { connect } from 'react-redux';
import Promotion, { PromotionProps } from './Promotion';
import { isEmpty } from 'util/index';
import { withAnalytics } from 'analytics';
import { loadOnDemandClip, loadStation } from 'actions/player-actions';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { Station, PodcastEpisode } from 'api/models';
import { fetchPodcast } from 'api/graphql/podcast.service';
import { fetchStation } from 'api/graphql/station.service';
import { Podcast } from 'api/models';

const playStation = withAnalytics(loadStation, {
  player_name: CONTENT_TYPES.PROMOTION,
  link_id: CONTENT_TYPES.PROMOTION,
});

const playOnDemand = withAnalytics(loadOnDemandClip, {
  player_name: CONTENT_TYPES.PROMOTION,
  link_id: CONTENT_TYPES.PROMOTION,
});

interface DispatchProps {
  playOnDemand: (podcast: PodcastEpisode) => void;
  playStation: (station: Station) => void;
}

const mergeProps = (
  stateProps: null,
  dispatchProps: DispatchProps,
  {
    disabled,
    title,
    urlTarget,
    pageUrl,
    imageSmall,
    imageMedium,
    imageLarge,
    image,
    playContent,
  }: { podcast?: Podcast; station?: Station } & PromotionProps
) => ({
  disabled,
  title,
  urlTarget,
  pageUrl,
  imageSmall,
  imageMedium,
  imageLarge,
  image,
  play: async () => {
    // Map playable state prop to corresponding play action
    if (!isEmpty(playContent)) {
      if (playContent.station) {
        let station = playContent.station;
        if (!playContent.station.hasMedia) {
          station = await fetchStation({ slug: playContent.station.slug });
        }
        return dispatchProps.playStation(station);
      }
      if (playContent.podcast) {
        const { slug } = playContent.podcast;
        let episode;
        if (!isEmpty(playContent.podcast.episodes)) {
          episode = playContent.podcast.episodes[0];
        } else {
          const podcast = await fetchPodcast({ slug, limit: 1 });
          episode = podcast.episodes[0];
        }
        return dispatchProps.playOnDemand(episode);
      }
    }
  },
});

export default connect(
  null,
  {
    playStation,
    playOnDemand,
  },
  mergeProps
)(Promotion);
