export default function trackCarouselNavigation(index, swimlaneId, swimlaneName, pageUrl) {
  const flooredIndex = Math.floor(index);
  window.Taq.push([
    'track',
    'link',
    {
      event_action: 'carousel',
      event_category: 'navigation',
      event_label: flooredIndex,
      content_id: swimlaneId,
      content_name: swimlaneName,
      page_url: pageUrl,
    },
  ]);
}
