import { isHuawei } from 'util/device';
import { getStore } from 'store';
import { playerPlaying, playerStop, attemptAutoPlayNext, trackOnDemandPlaybackTime } from 'actions/player-actions';
import { playerActions } from 'actions/actions-const';
const store = getStore();

export const message = (event, payload) => {
  if (!isHuawei || typeof system === 'undefined') return;

  const message = {
    event: event,
  };

  if (payload && event === 'play') {
    message['audioUrl'] = payload.audioUrl;
    message['title'] = payload.title;
    message['artist'] = payload.pageName;
    message['imageUrl'] = payload.logoSecondary && payload.logoSecondary.url;
  }

  if (payload && event === 'skipForward') {
    message['time'] = payload;
  }

  if (payload && event === 'skipBackward') {
    message['time'] = payload;
  }
  // eslint-disable-next-line no-undef
  system.postMessage(JSON.stringify(message));
};

export const listener = () => {
  //eslint-disable-next-line no-undef
  if (!isHuawei || typeof system === 'undefined') return;

  //eslint-disable-next-line no-undef
  system.onmessage = (message) => {
    switch (message.event) {
      case 'load':
        store.dispatch({ type: playerActions.PLAYER_LOADING });
        break;
      case 'play':
        store.dispatch(playerPlaying());
        break;
      case 'stop':
        store.dispatch(playerStop());
        break;
      case 'ended':
        store.dispatch(playerStop());
        store.dispatch(attemptAutoPlayNext());
        break;
      case 'progress':
        store.dispatch(trackOnDemandPlaybackTime(message.progress));
        break;
      case 'error': {
        store.dispatch({ type: playerActions.PLAYER_ERRORED });
        break;
      }
    }
  };
};
