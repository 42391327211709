/**
 * prepare the meta data object that is used for react-document-meta
 * @param {object} [metadata]
 * @param {string} [title]
 */

export default function prepareMetaData(metadata, title) {
  const meta = {
    meta: {
      name: {},
      property: {},
    },
  };

  if (metadata) {
    // Site title
    if (metadata.title) {
      Object.assign(meta, {
        title: metadata.title,
      });
    }

    // Content type
    if (metadata.contentType) {
      Object.assign(meta.meta.property, {
        'og:type': metadata.contentType.toLowerCase(),
      });
    }

    // Description
    if (metadata.description) {
      Object.assign(meta.meta.name, {
        description: metadata.description,
      });
    }

    // Keywords
    if (metadata.tags) {
      Object.assign(meta.meta.name, {
        keywords: metadata.tags.join(','),
      });
    }

    if (metadata.share) {
      // Share title
      if (metadata.share.title) {
        Object.assign(meta.meta.property, {
          'og:title': metadata.share.title,
        });
      }

      // Share url
      if (metadata.share.url) {
        Object.assign(meta.meta.property, {
          'og:url': metadata.share.url,
        });
      }

      // Share description
      if (metadata.share.description) {
        Object.assign(meta.meta.property, {
          'og:description': metadata.share.description,
        });
      }

      // Share image
      if (metadata.share.image) {
        Object.assign(meta.meta.property, {
          'og:image': metadata.share.image.url,
        });
      }
    }
  } else {
    Object.assign(meta, {
      title: title ? `${title} | JUKE.nl` : 'JUKE.nl',
    });
  }

  return meta;
}
