import { jwPlayerActions } from 'actions/actions-const';

const initialState = {
  isActive: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case jwPlayerActions.SET_IS_ACTIVE:
      return {
        ...state,
        isActive: action.payload,
      };

    default:
      return state;
  }
}

/**
 * Given the current state, it returns whether jwPlayer is active
 *
 * @param {object} state
 * @returns {object}
 */
export const getJWPlayerActive = (state) => state.jwPlayer.isActive;
