import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import svgIcon from 'components/hoc/svgIcon';

const styles = {
  fill: 'none',
  stroke: 'currentColor',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: 2,
};
const svg = ({ volumeLevel }) => (
  <svg viewBox="0 0 40 40">
    <polygon {...styles} points="13 16 8 16 8 24 13 24 20 29 20 11 13 16" />
    {volumeLevel === 0 && (
      <Fragment>
        <line {...styles} x1="24" y1="16" x2="32" y2="24" />
        <line {...styles} x1="32" y1="16" x2="24" y2="24" />
      </Fragment>
    )}
    {volumeLevel > 0 && <path {...styles} d="M23.45,22a4,4,0,0,0,0-4.05" />}
    {volumeLevel > 0.33 && <path {...styles} d="M26.32,15.09a8,8,0,0,1,0,9.82" />}
    {volumeLevel > 0.66 && <path {...styles} d="M29.16,12.25a12,12,0,0,1,0,15.5" />}
  </svg>
);

svg.propTypes = {
  volumeLevel: PropTypes.number,
};

svg.defaultProps = {
  volumeLevel: 100,
};

export default svgIcon(svg);
