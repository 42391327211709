import * as cssVariable from 'cssVariables';
import { getPlaceholderPseudo } from 'util/device';

export default {
  search: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  searchRight: {
    justifyContent: 'flex-end',
  },
  paddingLeft: {
    paddingLeft: 20,
  },
  form: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 40,
    border: `1px solid transparent`,
    backgroundColor: cssVariable.grey100,
    borderRadius: 4,
    transition: 'all 250ms ease',
    '&:hover': {
      borderColor: cssVariable.green500,
      boxShadow: '0 5px 20px 0 rgba(147, 192, 31, 0.2)',
      '& > svg:first-of-type': {
        color: cssVariable.green500,
      },
    },
  },
  formFocus: {
    borderColor: cssVariable.green500,
    boxShadow: '0 5px 20px 0 rgba(147, 192, 31, 0.2)',
    '& > svg:first-of-type': {
      color: cssVariable.green500,
    },
  },

  input: {
    width: '100%',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    fontSize: 18,
    lineHeight: '24px',
    fontFamily: cssVariable.fontFamily,
    fontWeight: cssVariable.fontWeights.regular,
    [getPlaceholderPseudo()]: {
      fontWeight: cssVariable.fontWeights.light,
      color: cssVariable.grey500,
    },
  },
  searchIcon: {
    margin: [0, 10],
    '& > svg': {
      width: 32,
      height: 32,

      color: cssVariable.grey500,
    },
  },
  searchIconBtn: {
    cursor: 'pointer',
    '&:hover > svg': {
      color: cssVariable.green500,
    },
  },
  closeIcon: {
    width: 32,
    height: 32,
    padding: 10,
    color: cssVariable.grey500,
    cursor: 'pointer',
    '&:hover': {
      color: cssVariable.black,
    },
  },
};
