import { jukeProfile } from './client';
import { playoutSubscription, scheduleSubscription } from './queries';
import Show from '../models/Show';
import logger from 'util/logger';

const eventType = {
  scheduledEvent: 'scheduledEvent',
  stationEvent: 'stationEvent',
  playoutEvent: 'playoutEvent',
};

const onPlayoutEventMessage = (data) => {
  if (!data || !data.playoutEvent?.getPlayouts?.playouts[0]?.track) return;
  const playoutItem = data?.playoutEvent?.getPlayouts?.playouts.map(({ track }) => track);
  return playoutItem;
};

const onToScheduledEventMessage = (data) => {
  if (!data) return;
  const schedule = data?.scheduleEvent?.schedule;
  const {
    schedule: { show, broadcastStartDate, broadcastEndDate },
  } = schedule;
  const liveShow = new Show({
    ...show,
    broadcastStartDate,
    broadcastEndDate,
  });
  return liveShow;
};

const onEventError = (err, eventType) => {
  logger.error(`${eventType} Connection Error: ${err.message}`);
};

async function fetchSubscription(slug, query) {
  try {
    const { awsAppsyncClient } = await import('../../api/graphql/awsAppsyncClient');
    const client = await awsAppsyncClient.subscribe({
      query: query,
      variables: { profile: jukeProfile, slug },
    });
    return client;
  } catch (error) {
    logger.error(error);
  }
}

/**
 * Add subscription for playoutEvent
 * @param {string} slug - Slug of a given station
 * @callback callback - A function to run if we had a successful fetching of tracks for given station
 * @callback errorCallback - A function to run if we get error
 * @returns {Client} client
 */
export const subscribeToPlayoutEvents = async (slug, callback, errorCallback) => {
  try {
    const client = await fetchSubscription(slug, playoutSubscription);

    const awsSubscriptionResponse = client.subscribe({
      next: ({ data }) => {
        callback(onPlayoutEventMessage(data));
      },
      error: (err) => {
        errorCallback(onEventError(err, eventType.stationEvent));
      },
    });
    return awsSubscriptionResponse;
  } catch (error) {
    logger.error(error);
  }
  return null;
};

/**
 * Add subscription for scheduleEvent
 * @param {string} slug- Slug of a given station
 * @callback callback - A function to run if we had a successful fetching of tracks for given station
 * @callback errorCallback- A function to run if we get error
 * @returns {Client} client
 */
export const subscribeToScheduleEvents = async (slug, callback, errorCallback) => {
  try {
    const client = await fetchSubscription(slug, scheduleSubscription);

    const awsSubscriptionResponse = client.subscribe({
      next: ({ data }) => {
        callback(onToScheduledEventMessage(data));
      },
      error: (err) => {
        errorCallback(onEventError(err, eventType.scheduledEvent));
      },
    });
    return awsSubscriptionResponse;
  } catch (error) {
    logger.error(error);
  }
  return null;
};
