import { useEffect, useState } from 'react';
import { isTouch } from 'util/device';
import generatePlaceholders from 'util/generatePlaceholders';
import { CollectionProps } from './Collection';
import { PodcastEpisode } from 'api/models';
import { isEmpty } from 'util/index';

interface State {
  filteredItems: Array<unknown>;
  visibleItems: number;
}

export default function useFilterCollectionItems(
  {
    contentTypeId,
    display,
    isComponentLoading,
    items,

    setUpcomingTracks,
    error,
    itemsPerRow,
    lightText,
    mobileRows = 1,
    rows = 1,
    swipe,
    title,
  }: CollectionProps,
  failedItems: Array<string>,
  displayInCarousel: boolean,
  isExpanded: boolean
): State {
  const [state, setState] = useState({
    filteredItems: [],
    visibleItems: 0,
  });

  const onOndemandStart = (id: string): void => {
    const episodes = <Array<PodcastEpisode>>items;

    const index = episodes.findIndex((item) => item.id === id);

    const upcomingTracks =
      index !== -1 &&
      episodes
        .slice(index + 1)
        // skip completed tracks
        .filter(({ progress }) => !progress || progress.completed === false);
    setUpcomingTracks(upcomingTracks || null);
  };

  const filterItems = () => {
    const ondemandProps = ['podcastCollection', 'onDemandCollection'].includes(contentTypeId)
      ? {
          onOndemandStart,
        }
      : null;

    let filteredItems =
      isComponentLoading && !error && isEmpty(items)
        ? generatePlaceholders(20, { contentTypeId: 'generated' })
        : items &&
          items
            .filter(({ slug }) => !failedItems.includes(slug))
            .map((item) => ({
              ...item,
              collectionTitle: title,
              lightText,
              display,
              ...ondemandProps,
            }));

    let visibleItems;

    if (isTouch && swipe) {
      visibleItems = mobileRows * itemsPerRow;
    } else if (displayInCarousel) {
      // display all items in carousel
      visibleItems = items && items.length;
    } else {
      visibleItems = (isExpanded ? Number.MAX_SAFE_INTEGER : rows) * itemsPerRow;
      filteredItems = filteredItems && filteredItems.slice(0, visibleItems);
    }

    setState({ filteredItems, visibleItems });
  };

  useEffect(() => {
    filterItems();
  }, [items, isComponentLoading, itemsPerRow, failedItems, isExpanded]);

  return state;
}
