import * as cssVariable from 'cssVariables';
const DISCONNECTED_COLOR = '--disconnected-color';
const CONNECTED_COLOR = '--connected-color';

export default {
  googleCastWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',

    '& google-cast-launcher': {
      position: 'relative',

      // Here we define that google-cast-launcher has the same height and width of the parent (in this case the PlayerButton).
      // We do this with a after so the icon in the component keeps the correct dimension.
      // We do this so the whole parent is clickable and can trigger the cast functionality
      '&:after': {
        content: '" "',
        width: '100%',
        height: 90,
        top: -30,
        left: 0,
        position: 'absolute',
      },
    },

    '& > *': {
      [DISCONNECTED_COLOR]: cssVariable.white,
      [CONNECTED_COLOR]: cssVariable.green500,
    },
  },
};
