import { DisplayLayout } from './Collection.const';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { isMobile, isTouch, isTablet } from 'util/device';
import { contains } from 'util/index';

export const withBackground = (contentTypeId: string, display: string): boolean => {
  return (
    display === DisplayLayout.TILES_WITH_BACKGROUND ||
    (display === DisplayLayout.LIST && contentTypeId === CONTENT_TYPES.PODCAST_COLLECTION && isMobile)
  );
};

export const isCarousel = (display: string): boolean => {
  return (
    !isTouch &&
    contains(
      [
        DisplayLayout.CAROUSEL,
        DisplayLayout.CAROUSEL_WITH_FOLD,
        DisplayLayout.CAROUSEL_TWO_ROWS,
        DisplayLayout.SWIMLANE,
      ],
      display
    )
  );
};

export const checkFullWidthList = (rows: number, itemsLength: number, isListTile: boolean): boolean => {
  return isListTile && (isMobile || isTablet() || rows >= itemsLength);
};
