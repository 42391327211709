import React from 'react';
import StopIcon from 'components/svg/Stop';
import Button from 'components/Button';
import Loader from 'components/Loader';
import ButtonTypes from 'components/Button/Button.const';
import { createUseStyles } from 'react-jss';
import * as cssVariable from 'cssVariables';
import PlayWithoutPadding from 'components/svg/PlayWithoutPadding';

interface PlayStationButton {
  isLoading: boolean;
  isPlayingCurrentStation: boolean;
  onPlayToggle: () => void;
}
const PLAY_CONTROL_ICON_SIZE = {
  width: '40px',
  height: '40px',
};

const styles = {
  loaderHolder: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  playButton: {
    '& svg': {
      width: 'auto',
      height: '17px',
    },
  },
};

const useStyles = createUseStyles(styles, { name: 'PlayStationButton' });

const PlayStationButton = (props: PlayStationButton): JSX.Element => {
  const { isLoading, isPlayingCurrentStation, onPlayToggle } = props;

  const classes = useStyles();
  const loaderProps = {
    size: 20,
    color: cssVariable.white,
  };
  const ButtonLoader = (
    <div className={classes.loaderHolder}>
      <Loader {...loaderProps} />
    </div>
  );
  const Icon = isPlayingCurrentStation ? <StopIcon style={PLAY_CONTROL_ICON_SIZE} /> : <PlayWithoutPadding />;

  return (
    <Button
      isActive={isPlayingCurrentStation}
      data-testid="station_page_play"
      type={ButtonTypes.PRIMARY}
      onClick={onPlayToggle}
      text={!isLoading && isPlayingCurrentStation ? 'Speelt af' : 'Nu luisteren'}
      icon={isLoading ? ButtonLoader : Icon}
      iconClassName={classes.playButton}
    />
  );
};

export default PlayStationButton;
