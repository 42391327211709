import React from 'react';

interface CollectionEmpty {
  contentTypeId: string;
}

const CollectionEmpty = ({ contentTypeId }: CollectionEmpty): JSX.Element => {
  switch (contentTypeId) {
    default:
      return <div data-testid="collection-empty"></div>;
  }
};

export default CollectionEmpty;
