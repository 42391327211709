import { connect } from 'react-redux';

import { withAnalytics } from 'analytics';
import { loadStation, play, stop } from 'actions/player-actions';
import {
  getIsPlaying,
  getIsLoading,
  getPlaybackMode,
  getCurrentOnDemandClip,
  playbackModes,
} from 'store/reducers/player';
import { getIsPopoutPlayerActive } from 'store/reducers/popout';

import PlayerControls from './PlayerControls';
import { setVolumeLevel, toggleMute } from 'actions/playerVolume-actions';
import { getRandomStation } from 'store/reducers/content';
import { hasMediaInPlayer } from 'store/reducers/player';
import { getBrowser } from 'store/reducers/browser';

const mapStateToProps = (state) => {
  const currentOnDemandClip = getCurrentOnDemandClip(state);

  const isTrackPreview = !!(currentOnDemandClip && currentOnDemandClip.track);
  const isOnDemand = getPlaybackMode(state) === playbackModes.ON_DEMAND_CLIP;
  return {
    isPlaying: getIsPlaying(state),
    isLoading: getIsLoading(state),
    isPopoutPlayerActive: getIsPopoutPlayerActive(state),
    isTrackPreview,
    isOnDemand,
    randomStation: getRandomStation(state),
    hasContent: hasMediaInPlayer(state),
    browser: getBrowser(state),
  };
};

const playWithAnalytics = withAnalytics(play, {
  player_name: 'player_bottom',
});

const stopWithAnalytics = withAnalytics(stop, {
  player_name: 'player_bottom',
});

const loadStationWithAnalytics = withAnalytics(loadStation, {
  player_name: 'player_bottom',
});

function mergeProps(
  { isPlaying, hasContent, randomStation, ...stateProps },
  { onStop, onResume, onLoadStation, ...dispatchProps },
  ownProps
) {
  return {
    ...ownProps,
    isPlaying,
    ...stateProps,
    onPlayStopToggle: () => {
      if (!hasContent) {
        onLoadStation(randomStation);
        return;
      }
      if (isPlaying) {
        onStop();
        return;
      }
      onResume();
    },
    ...dispatchProps,
  };
}

export default connect(
  mapStateToProps,
  {
    onResume: playWithAnalytics,
    onStop: stopWithAnalytics,
    onLoadStation: loadStationWithAnalytics,
    onSetVolumeLevel: setVolumeLevel,
    onToggleMute: toggleMute,
  },
  mergeProps
)(PlayerControls);
