import { handleConsentManagerDisplay } from 'actions/gigya-router-actions';
import { initPlayer, loadPlayItemWithParameters } from 'actions/player-actions';
import { downloadContent, isOnline } from 'actions/content-actions';
import downloadAppRating from 'actions/rating-actions';
import { getIsInMobileApp } from 'store/reducers/urlParams';
import { AppDispatch, RootState } from 'store';

export function appBoot() {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    dispatch(handleConsentManagerDisplay());
    dispatch(initPlayer());
    if (!getIsInMobileApp(getState())) {
      dispatch(downloadContent());
      dispatch(downloadAppRating());
    }
    dispatch(isOnline());
  };
}

export function contentDownloaded() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(loadPlayItemWithParameters());
  };
}
