import React from 'react';

import classNames from 'classnames';

import CONTENT_TYPES from 'globalConst/contentTypes-const';

import styles from './Results.styles';
import { createUseStyles } from 'react-jss';
import Loader from 'components/Loader';
import NoResults from 'components/Search/NoResults';
import { IFetchResults, useFetchData } from 'components/hooks';

const useStyles = createUseStyles(styles, { name: 'Results' });

import ContentTypeComponent from 'components/ContentTypeComponent';
import RecentSearchCollection from '../RecentSearchCollection';
import PopularSearch from './PopularSearch/index';

/**
 * Results functional component represents dynamic page for search results of Algolia.
 * @param {searchTerm} - Term for searching
 * @param {isActive} - Is search active
 * @param {isLoading} - Redux loading state from search slice
 * @param {setSearchResults} - Redux action for setting search results into state
 * @param {setActive} - Redux action for setting search active
 * @returns {JSX.Element} The rendered component.
 */
export const SearchResults = ({
  searchTerm,
  isActive,
  isLoading,
  setSearchResults,
  setActive,
  hasRecentSearchItems,
}: {
  searchTerm: string;
  isActive: boolean;
  isLoading: boolean;
  setActive: (isActive: boolean) => void;
  hasRecentSearchItems: boolean;
  setSearchResults: (results: Pick<IFetchResults, 'sections'> | null) => void;
}): JSX.Element => {
  const classes = useStyles();

  const {
    data,
    isLoading: isLoadingData,
    error,
  } = useFetchData({
    slug: searchTerm,
    contentTypeId: CONTENT_TYPES.SEARCH,
  });

  const sectionProps = {
    isInSearch: true,
    isComponentLoading: isLoading,
    onError: error,
  };

  const { sections } = data || {};

  if (!isActive) {
    return null;
  }

  if (error) {
    setSearchResults(null);
  }

  const displayRecentAndPopularSearchResults = !isLoadingData && searchTerm.length < 1;
  const noResultsFoundForSearchTerm =
    sections &&
    !isLoading &&
    sections.every((section) => {
      return section.items.length === 0;
    });

  if (displayRecentAndPopularSearchResults) {
    return (
      <div className={classNames(classes.container)}>
        <div className={classes.containerRecentPopular}>
          {hasRecentSearchItems && <RecentSearchCollection isInSearch />}
          <PopularSearch />
        </div>
      </div>
    );
  }

  if (isLoading || isLoadingData) {
    return (
      <div className={classes.loaderScreen}>
        <Loader />
      </div>
    );
  }

  if (noResultsFoundForSearchTerm || error) {
    return <NoResults />;
  }

  return (
    <div className={classNames(classes.container)}>
      {sections &&
        sections.map((section) => {
          return (
            <ContentTypeComponent isComponentLoading={isLoading} key={section.uuid} {...section} {...sectionProps} />
          );
        })}

      <button className={classes.screenReadersOnly} onBlur={() => setActive(false)} type="button" />
    </div>
  );
};
