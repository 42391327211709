export const CALL_TO_ACTION_BLOCK_LAYOUT_TYPES = {
  DEFAULT: 'Default',
  GRADIENT: 'Gradient',
  DARK: 'Dark',
  LIGHT: 'Light',
  imageLeft: 'Image left',
};

export const CALL_TO_ACTION_TYPES = {
  BLUE: 'blue',
  DARK: 'dark',
  WHITE_OUTLINED: 'whiteOutlined',
};
