import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import CollectionContext from 'components/Collection/CollectionContext';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';
import * as cssVariable from 'cssVariables';
import HighlightText from './HighlightText';

import { LABEL } from 'globalConst/const';
import { isIE11andLower } from 'util/device';

function Description({ classes, text }) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [descriptionIsOverflowing, setDescriptionIsOverflowing] = useState(false);
  const { isInSearch } = useContext(CollectionContext);

  const descriptionNode = useRef(null);

  useEffect(() => {
    const { current } = descriptionNode;

    function checkDescription() {
      if (!current) return;
      setShowFullDescription(false);
      setDescriptionIsOverflowing(current.offsetHeight < current.scrollHeight);
    }
    checkDescription();
    window.addEventListener('resize', checkDescription);
    return () => window.removeEventListener('resize', checkDescription);
  }, [text]);

  if (!text) return null;

  return (
    <div className={classes.container}>
      <Fragment>
        <div
          ref={descriptionNode}
          className={classNames(classes.description, {
            [classes.descriptionTwoLines]: !showFullDescription,
            [classes.descriptionIE]: isIE11andLower,
            [classes.dotsForIE]: !showFullDescription && descriptionIsOverflowing && isIE11andLower,
          })}
        >
          {isInSearch && text ? <HighlightText text={text} /> : <p className={classes.paragraph}>{text}</p>}
        </div>
        {descriptionIsOverflowing && (
          <button className={classes.descriptionButton} type="button">
            {showFullDescription ? LABEL.HIDE : LABEL.SHOW_MORE}
          </button>
        )}
      </Fragment>
    </div>
  );
}

Description.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
};

Description.defaultProps = {
  text: null,
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  descriptionButton: {
    height: 30,
    padding: [0, 15],
    margin: [-5, 0],
    color: cssVariable.green500,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: cssVariable.fontSizes.xsmall,
    fontWeight: cssVariable.fontWeights.regular,
    minWidth: 100,
  },
  description: {
    maxWidth: '80%',
    height: 'auto',
    color: cssVariable.black,
    margin: [10, 0, 0],
    lineHeight: 1.43,
  },
  descriptionTwoLines: {
    '@supports (-webkit-line-clamp: 2)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
      display: '-webkit-box',
      [cssVariable.WEBKIT_LINE_CLAMP]: 2,
      [cssVariable.WEBKIT_BOX_ORIENT]: 'vertical',
    },
  },
  descriptionIE: {
    textAlign: 'justify',
    paddingRight: '0.6em',
    '&$descriptionTwoLines': {
      ...cssVariable.description2LinesStyles,
      lineHeight: 1.43,
      maxHeight: '2.9em',
      '&::after': {
        content: '',
      },
      '&::before': {
        content: '',
      },
    },
  },
  dotsForIE: {
    '&::before': {
      content: '"..."',
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
  },
  searchedText: {
    color: cssVariable.blue500,
    fontWeight: 'bold',
  },
  paragraph: {
    margin: 0,
  },
};

export default injectStyles(styles)(Description);
