export const SHOW_COLLECTION_FRAGMENT = `
  fragment ShowCollectionFragment on ShowCollection {
    id
    title
    description
    type
    config {
      ...ConfigFragment
    }
    items {
      id
      type
      title
      shortTitle
      slug
      description
      media {
        ...MediaFragment
      }
      images {
        ...ImageFragment
      }
      config {
        ...ConfigFragment
      }
      startTime
      endTime
      refs {
        targetType
        uri
      }
      episodes(limit: 100) {
        id
        title
        description
        media {
          ...MediaFragment
        }
        durationSeconds
        broadcastDate
        publishDate
      }
    }
  }
  `;
