import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import makeModal from 'lib/makeModal';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';
import * as cssVariable from 'cssVariables';
import { SEARCH_CONTAINER_ID } from 'globalConst/const';
import { SearchResults } from './Results';
import { DEFAULT_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from 'components/PageHeader/PageHeader.const';
import { isTouch } from 'util/device';

class Search extends PureComponent {
  static defaultProps = {
    hasMobileHeader: false,
    page: null,
    show: true,
  };

  static propTypes = {
    hasMobileHeader: PropTypes.bool,
    isActive: PropTypes.bool.isRequired,
    isHidden: PropTypes.bool.isRequired,
    onSetActive: PropTypes.func.isRequired,
    show: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    page: PropTypes.object,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.manageModalListeners = makeModal({
      dismissFunction: () => this.props.onSetActive(false),
      container: this.container,
      closeOnClick: false,
    });
  }

  componentDidUpdate(prevProps) {
    const { isActive } = this.props;

    if (prevProps.isActive !== isActive) {
      if (this.manageModalListeners) this.manageModalListeners(isActive);
    }
  }

  componentWillUnmount() {
    this.manageModalListeners(false);
  }

  bindInput = (input) => {
    this.container = input;
  };

  render() {
    const { hasMobileHeader, isActive, isHidden, show, classes } = this.props;

    const className = classNames(classes.container, {
      [classes.containerActive]: isActive,
      [classes.containerActiveMobile]: isActive && hasMobileHeader,
      [classes.containerNotActive]: !isActive,
      [classes.containerMobile]: hasMobileHeader,
    });

    if (show && !hasMobileHeader && !isHidden) {
      return (
        <div id={SEARCH_CONTAINER_ID} className={className} ref={this.bindInput}>
          <SearchResults />
        </div>
      );
    }
    return null;
  }
}

const styles = (theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    top: isTouch ? MOBILE_HEADER_HEIGHT : DEFAULT_HEADER_HEIGHT, // Deafultheader is fixed and has a zIndex higher then search, so render underneath the
    right: 0,
    minHeight: 0,
    transform: 'scale(1) perspective(1px)',
    transition: 'all 400ms linear',
    transitionProperty: 'min-height, z-index, opacity',
    height: '100%',
    zIndex: theme.zIndex.modal,
    ...cssVariable.contentPadding({ smallScreen: false }),
    paddingBottom: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: 0,
      width: '100%',
      background: theme.palette.background,
      transition: 'height 400ms ease',
      zIndex: -1,
      transform: 'scale(1) perspective(1px)',
    },
  },

  containerMobile: {
    paddingTop: cssVariable.mobileHeaderHeight,
    opacity: 0,
    backgroundColor: cssVariable.white,
    zIndex: 1,
  },
  containerActive: {
    opacity: 1,
    position: 'relative',
    flexGrow: 1,
    minHeight: '100vh',
    transitionDelay: '0s',
    '&::before': {
      height: '100%',
      transitionDelay: cssVariable.transitionDelay,
    },
  },
  containerNotActive: {
    pointerEvents: 'none',
  },
  containerActiveMobile: {
    minHeight: '100%',
    height: '100%',
    width: '100%',
    position: 'fixed',
    background: theme.palette.background,
    zIndex: theme.zIndex.modal,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  '@keyframes footerDelay': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  searchTitle: {
    ...cssVariable.pageTitle,
    paddingTop: 40,
    animation: '$footerDelay 0.6s forwards',
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      ...cssVariable.contentPadding({ smallScreen: true }),
      paddingBottom: 0,
    },
  },
});

export default withRouter(injectStyles(styles)(Search));
