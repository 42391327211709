import React, { useContext } from 'react';

import * as cssVariable from 'cssVariables';

import classNames from 'classnames';
import PlayIcon from 'components/svg/Play';
import PauseIcon from 'components/svg/Pause';
import StopIcon from 'components/svg/Stop';
import OnDemandPlayIcon from 'components/svg/OnDemandPlay';

import Loader from 'components/Loader';
import { DEFAULT_COLOR, DIAP_COLOR } from 'components/Loader/Loader.const';
import { createUseStyles } from 'react-jss';
import * as analytics from 'analytics';

import styles from './PlayControl.styles';

import CollectionContext from 'components/Collection/CollectionContext';

interface PlayControl {
  slug: string;
  contentTypeId: string;
  isPlaying: boolean;
  isLoading: boolean;
  size: number;

  isCTA?: boolean;
  isPrimary?: boolean;
  isTransparent?: boolean;
  isOnDemand?: boolean;
  isClipList?: boolean;
  listItem?: boolean;

  onFocus?: () => boolean;
  onBlur?: () => boolean;
  disabled?: boolean;
  playToggle: () => void;
  saveRecentSearch: (contentTypeId: string, slug: string) => boolean;
  podcastEpisode?: string;
  podcastTitle?: string;
  customTracking?: () => void;
}

const svgProps = {
  width: '100%',
  height: '100%',
  position: 'relative',
  left: '0',
};

const svgPropsSmallIcon = {
  width: 45,
  height: 45,
  position: 'relative',
  left: '0',
  color: cssVariable.blue500,
};

const useStyles = createUseStyles(styles, { name: 'PlayControl' });

const PlayControl = (props: PlayControl): JSX.Element => {
  const {
    slug,
    contentTypeId,
    playToggle,
    saveRecentSearch,
    listItem,
    isCTA,
    isPlaying,
    isLoading,
    size,
    isPrimary,
    isTransparent,
    onFocus,
    onBlur,
    disabled,
    isOnDemand,
    isClipList,
    podcastEpisode = '',
    podcastTitle = '',
    customTracking,
  } = props;

  const { isInSearch = false } = useContext(CollectionContext);
  const loaderProps = {
    size,
    color: isPrimary ? DEFAULT_COLOR : DIAP_COLOR,
  };

  const classes = useStyles();

  const setPlayIcon = () => {
    if (isOnDemand && listItem) {
      return <PlayIcon style={svgPropsSmallIcon} hasStroke={false} />;
    }
    if (isOnDemand && !listItem) {
      return <OnDemandPlayIcon style={svgProps} hasStroke={!isPrimary} />;
    }
    return (
      <PlayIcon style={svgProps} filled={!isCTA && !isTransparent} hasStroke={!isPrimary && !isCTA && !isTransparent} />
    );
  };

  const setStopIcon = () => {
    if (isOnDemand || isClipList) {
      return <PauseIcon style={listItem ? svgPropsSmallIcon : svgProps} hasStroke={false} />;
    }
    return <StopIcon style={svgProps} filled color={cssVariable.green500} />;
  };

  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (customTracking) {
      customTracking();
    }
    if (podcastEpisode && podcastTitle) {
      if (!isPlaying) {
        analytics.trackPlayOnDemandClip({}, null, null, false, podcastEpisode, podcastTitle);
      } else {
        analytics.trackStopOnDemand({}, null, null, false, podcastEpisode, podcastTitle);
      }
    }
    if (disabled) {
      return;
    }
    if (isInSearch) {
      saveRecentSearch(contentTypeId, slug);
    }

    if (typeof onBlur === 'function') {
      onBlur();
    }
    playToggle();
  };

  if (isLoading) {
    return <Loader data-testid="player_loader" {...loaderProps} />;
  }

  return (
    <button
      type="button"
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={clickHandler}
      data-testid={`player_${isPlaying ? 'stop' : 'play'}`}
      className={classNames(classes.button, {
        [classes.primary]: isPrimary,
        [classes.buttonTransparent]: isTransparent,
        [classes.buttonWhite]: !isOnDemand && !isPrimary && !isTransparent,
      })}
      style={{ width: size, height: size }}
    >
      {isPlaying ? setStopIcon() : setPlayIcon()}
    </button>
  );
};

export default PlayControl;
