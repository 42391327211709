import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const styles = {
  fill: 'none',
  stroke: 'currentColor',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: 2,
};
const svg = (
  <svg viewBox="0 0 40 40">
    <line {...styles} x1="13" y1="13" x2="27" y2="27" />
    <line {...styles} x1="27" y1="13" x2="13" y2="27" />
  </svg>
);

export default svgIcon(svg);
