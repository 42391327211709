import React from 'react';
import PromotionImage from './PromotionImage';
import { createUseStyles } from 'react-jss';
import * as analytics from 'analytics';
import { Promotion as PromotionModel } from 'api/models';
import NavigationLink from 'components/NavigationLink';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import styles from './Promotion.styles';

export interface PromotionProps extends PromotionModel {
  tileComponent: string;
  disabled?: boolean;
  play: () => void;
}

const useStyles = createUseStyles(styles, { name: 'Promotion' });

const Promotion = (props: PromotionProps): JSX.Element => {
  const { disabled = false, title, urlTarget, pageUrl, play } = props;
  const classes = useStyles();

  if (disabled) {
    return <div data-testid="promotion-disabled" className={classes.disabled} />;
  }

  const onClick = () => {
    // If there is any playable content it will be played on click
    analytics.trackEvent(analytics.generateCTAClickData(title, pageUrl, CONTENT_TYPES.PROMOTION));
    play();
  };

  const navigationOnClick = () => {
    analytics.trackEvent(analytics.generateCTAClickData(title, pageUrl, CONTENT_TYPES.PROMOTION));
    onClick();
  };

  if (pageUrl) {
    return (
      <NavigationLink
        target={urlTarget}
        source={CONTENT_TYPES.PROMOTION}
        pageUrl={pageUrl}
        title={title}
        onClick={navigationOnClick}
        data-testid="promotion-navigationlink"
      >
        <PromotionImage {...props} />
      </NavigationLink>
    );
  }

  return <PromotionImage onClick={onClick} data-testid="promotion-navigationlink" {...props} />;
};

export default Promotion;
