const KeyCode = {
  BACKSPACE: 8,
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46,
};

class Modal {
  constructor(options) {
    this.dismissFunction = options.dismissFunction;
    this.container = options.container;
    this.closeOnClick = options.closeOnClick !== false;

    this.manageListeners = this.manageListeners.bind(this);
    this.captureClick = this.captureClick.bind(this);
    this.captureKey = this.captureKey.bind(this);
  }

  manageListeners(shouldBind) {
    if (shouldBind) {
      if (this.closeOnClick) {
        window.addEventListener('click', this.captureClick, true);
      }
      window.addEventListener('keydown', this.captureKey, true);
    } else {
      if (this.closeOnClick) {
        window.removeEventListener('click', this.captureClick, true);
      }
      window.removeEventListener('keydown', this.captureKey, true);
    }
  }

  captureClick(event) {
    if (!this.isContainerInPath(event)) {
      this.dismissFunction();
    }
  }

  captureKey(event) {
    if (event.keyCode === KeyCode.ESC) {
      this.dismissFunction();
    }
  }

  // Object type because we're using event props that Flow doesn't know about.
  isContainerInPath(event) {
    const path = (event.composedPath && event.composedPath()) || event.path;

    if (path) {
      return path.includes(this.container);
    }

    return this.findContainer(event.target);
  }

  findContainer(element) {
    if (element === this.container) {
      return true;
    }

    return element && this.findContainer(element.parentElement);
  }
}

export default (options) => {
  const modalInstance = new Modal(options);
  return modalInstance.manageListeners;
};
