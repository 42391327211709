import React from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';

const styles = {
  svg: {
    display: 'inline',
    height: '1.725em',
    width: '1.725em',
    verticalAlign: 'sub',
    fill: 'currentColor',
  },
};

export default (Component) =>
  injectSheet(styles)((props) => {
    const { classes, className, style } = props;
    const Element = Component instanceof Function ? Component({ ...Component.defaultProps, ...props }) : Component;

    return <Element.type style={style} {...Element.props} className={classNames(classes.svg, className)} />;
  });
