import * as cssVariable from 'cssVariables';

const styles = (theme) => ({
  navigationWrapper: {
    position: 'relative',
    height: '100%',
    backgroundColor: theme.palette.background,
    maxWidth: 0,
    width: 0,
    minWidth: 0,
  },
  buttonContainer: {
    padding: 20,
    flexDirection: 'column',
    zIndex: theme.zIndex.navigation + 4,
  },
  noPadding: {
    padding: 0,
  },
  navigation: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    backfaceVisibility: 'hidden',
    backgroundColor: theme.palette.navigation.background,
    transition: 'transform 0.3s ease, opacity .3s ease',
    transform: 'translateX(0)',
    zIndex: theme.zIndex.navigation + 4,
    overflowX: 'visible',
    overflowY: 'auto',
    flex: 'none',
    maxWidth: cssVariable.desktopMenuMaxWidth,
    width: cssVariable.desktopMenuMaxWidth,
    minWidth: cssVariable.desktopMenuMaxWidth,
    opacity: 1,
    borderRight: `solid 1px ${theme.palette.navigation.border}`,
    height: 'calc(100% - 90px)',
  },
  hideNavigation: {
    display: 'none',
  },
  navigationOpen: {},
  navigationMobile: {
    maxWidth: cssVariable.mobileMenuMaxWidth,
    width: cssVariable.mobileMenuMaxWidth,
    minWidth: cssVariable.mobileMenuMaxWidth,
    transform: 'translateX(-100%)',
    overflowY: 'scroll',
    opacity: 0,
    '&$navigationOpen': {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: cssVariable.grey600,
    zIndex: theme.zIndex.navigation,
    transition: 'opacity 0.25s ease',
    pointerEvents: 'none',
    opacity: 0,
  },
  backdropOpen: {
    pointerEvents: 'all',
    opacity: 0.3,
  },
  desktopNavigation: {
    maxWidth: cssVariable.desktopMenuMaxWidth,
    width: cssVariable.desktopMenuMaxWidth,
    minWidth: cssVariable.desktopMenuMaxWidth,
  },

  collapsibleNavigation: {
    maxWidth: cssVariable.desktopSmallMenuWidth,
    width: cssVariable.desktopSmallMenuWidth,
    minWidth: cssVariable.desktopSmallMenuWidth,
    '& $navigation': {
      maxWidth: cssVariable.desktopSmallMenuWidth,
      width: cssVariable.desktopSmallMenuWidth,
      minWidth: cssVariable.desktopSmallMenuWidth,
      transition: 'min-width 100ms ease-in',
      '&:hover': {
        transition: 'min-width 400ms ease-out',
        width: cssVariable.desktopMenuMaxWidth,
        minWidth: cssVariable.desktopMenuMaxWidth,
        boxShadow: `5px 0 5px -5px ${cssVariable.transparentLightmode040}`,
      },
    },
  },

  smallDesktopMenu: {
    maxWidth: cssVariable.desktopSmallMenuWidth,
    width: cssVariable.desktopSmallMenuWidth,
    minWidth: cssVariable.desktopSmallMenuWidth,
    '& $navigation': {
      maxWidth: cssVariable.desktopSmallMenuWidth,
      width: cssVariable.desktopSmallMenuWidth,
      minWidth: cssVariable.desktopSmallMenuWidth,
    },
  },

  '@media screen and ( max-height: 660px )': {
    navigation: {},
    smallDesktopMenu: {
      '& $navigation': {
        overflowY: 'scroll',
      },
    },
  },
  iconContainer: {
    flex: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: cssVariable.mobileHeaderHeight,
    height: cssVariable.mobileHeaderHeight,
    marginLeft: 10,
    padding: 10,
    background: 'none',
    border: 'none',
    outline: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  [theme.breakpoints.up('sm')]: {
    navigation: {
      zIndex: theme.zIndex.navigation + 4,
    },
  },
});

export default styles;
