import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import CONTENT_TYPES from 'globalConst/contentTypes-const';
import Collection from 'components/Collection';
import { DisplayLayout } from 'components/Collection/Collection.const';
import CrossIcon from 'components/svg/Cross';
import { TILE_COMPONENT_TYPES } from 'components/TileComponent/TileComponent.const';
import Loader from 'components/Loader';

import styles from './PodcastFold.styles';
import { isEmpty, toMinutes } from 'util/index';
import { fetchPodcast } from 'api/graphql/podcast.service';

const useStyles = createUseStyles(styles, { name: 'PodcastFold' });

function PodcastFold(props) {
  const classes = useStyles();
  const { closeFold, isOpen, slug } = props;

  const [podcasts, setPodcasts] = useState({});

  const podcast = podcasts[slug] || {};

  let { episodes: podcastEpisodes = [], loaded = false, fetchTime } = podcast;

  useEffect(() => {
    if (isOpen) {
      // Prevent force fetch debounce for 1 minute
      if (fetchTime && toMinutes(new Date().getTime()) - fetchTime < 1) {
        return;
      }
      fetchPodcast({ slug, limit: 3 }).then((podcast) => {
        if (podcast) {
          setPodcasts({
            ...podcasts,
            [slug]: { ...podcast, loaded: true, fetchTime: toMinutes(new Date().getTime()) },
          });
        } else {
          setPodcasts({
            ...podcasts,
            [slug]: {
              podcastEpisodes: [],
              loaded: true,
            },
          });
        }
      });
    }
  }, [isOpen, slug]);

  let episodes = podcastEpisodes;

  if (!slug) {
    return null;
  }

  return (
    <div className={classNames(classes.fold, { [classes.unfolded]: isOpen })} data-testid={`podcastFold-${slug}`}>
      <div className={classes.container}>
        {!loaded && <Loader size={30} />}
        {podcast && loaded && (
          <>
            {isEmpty(episodes) ? (
              <p>Helaas, er ging iets mis bij het ophalen van de podcasts</p>
            ) : (
              <Collection
                uuid={podcast.uuid}
                title={podcast.title}
                description={podcast.author}
                items={episodes}
                rows={3}
                display={DisplayLayout.LIST}
                tileComponent={TILE_COMPONENT_TYPES.ON_DEMAND}
                contentTypeId={CONTENT_TYPES.ON_DEMAND_COLLECTION}
                showCollectionLink={false}
                isInFold
              />
            )}
            <div className={classes.closeIcon} role="presentation" onClick={closeFold}>
              <CrossIcon />
            </div>
            <div className={classes.pageLinkWrapper}>
              <NavLink className={classes.pageLink} to={`/podcasts/${podcast.slug}`} data-testid="podcastFold_button">
                Luister alle afleveringen
              </NavLink>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

PodcastFold.defaultProps = {
  collectionId: null,
  isOpen: false,
  podcast: null,
  slug: null,
  items: [],
};

PodcastFold.propTypes = {
  collectionId: PropTypes.string,
  items: PropTypes.array,
  closeFold: PropTypes.func,
  isOpen: PropTypes.bool,
  podcast: PropTypes.object,
  slug: PropTypes.string,
};

export default PodcastFold;
