import React from 'react';

import { createUseStyles } from 'react-jss';
import * as cssVariable from 'cssVariables';

import MobileMenuIcon from 'components/svg/MobileMenu';
import styles from './MenuButton.styles';

const svgIconPropsSmall = {
  width: cssVariable.svgIconSizeSmall,
  height: cssVariable.svgIconSizeSmall,
};

interface MenuButton {
  onToggleNavigation: () => void;
}
const useStyles = createUseStyles(styles, { name: 'MenuButton' });

const MenuButton = ({ onToggleNavigation }: MenuButton): JSX.Element => {
  const classes = useStyles();

  return (
    <button
      data-testid="mobile-header-menu-button"
      className={classes.button}
      onClick={onToggleNavigation}
      type="button"
    >
      <MobileMenuIcon style={svgIconPropsSmall} />
    </button>
  );
};

export default MenuButton;
