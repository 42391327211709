import { getStore } from 'store';
import { v4 as uuid } from 'uuid';

let sessionId = '';
let clipId = '';
let ourClipId = '';
let seqNumber = 1;
let events = [];
let organizationId = '';

window.addEventListener('pagehide', () => {
  if (events.length) {
    if (events.length % 2 !== 0) {
      const heartbeat = window.Taq.getExtension('HeartBeat');
      const {
        player: { currentOnDemandClip, onDemandPlaybackTime },
      } = getStore().getState();

      const isOmny = currentOnDemandClip?.podcast?.feedUrl?.split('/')[4] === 'playlist';
      if (isOmny && heartbeat?.counter !== -1) {
        const currentOrganizationId = currentOnDemandClip?.podcast?.feedUrl?.split('/')[5];
        seqNumber++;

        events.push({
          OrganizationId: currentOrganizationId,
          ClipId: clipId,
          SessionId: sessionId,
          Type: 'Stop',
          Position: onDemandPlaybackTime,
          SeqNumber: seqNumber,
          Timestamp: `${Math.round(new Date().getTime() / 1000)}`,
        });
      }
    }
    const data = {
      Source: 'CustomWeb',
      Events: events,
      Completed: true,
    };

    const headers = { 'Content-Type': 'application/json' };
    const blob = new Blob([JSON.stringify(data)], headers);
    navigator.sendBeacon(process.env.REACT_APP_OMNY_URL + organizationId, blob);
  }
});

export async function omnyEventsStart() {
  const heartbeat = window.Taq.getExtension('HeartBeat');
  const {
    player: { currentOnDemandClip, onDemandPlaybackTime },
  } = getStore().getState();

  const isOmny = currentOnDemandClip?.podcast?.feedUrl?.split('/')[4] === 'playlist';

  if (isOmny) {
    const currentOrganizationId = currentOnDemandClip?.podcast?.feedUrl?.split('/')[5];
    if (!ourClipId) {
      ourClipId = currentOnDemandClip?.id;
      sessionId = uuid();
      organizationId = currentOrganizationId;
    } else if (currentOnDemandClip?.id !== ourClipId) {
      const data = {
        Source: 'CustomWeb',
        Events: events,
        Completed: true,
      };

      await fetch(process.env.REACT_APP_OMNY_URL + currentOrganizationId, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(() => {
          sessionId = uuid();
          ourClipId = currentOnDemandClip?.id;
          events = [];
          seqNumber = 1;
          organizationId = currentOrganizationId;
        });
    }

    if (seqNumber !== 1) {
      seqNumber++;
    }

    await fetch(currentOnDemandClip?.podcast?.feedUrl)
      .then((response) => response.text())
      .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
      .then((data) => {
        const items = data?.querySelectorAll('item');
        items.forEach((item) => {
          if (item?.querySelector('title')?.textContent === currentOnDemandClip?.title) {
            clipId = item?.querySelector('enclosure')?.getAttribute('url')?.split('/')[7];
          }
        });
      });

    if (heartbeat?.counter !== -1) {
      events.push({
        OrganizationId: currentOrganizationId,
        ClipId: clipId,
        SessionId: sessionId,
        Type: 'Start',
        Position: onDemandPlaybackTime,
        SeqNumber: seqNumber,
        Timestamp: `${Math.round(new Date().getTime() / 1000)}`,
      });
    }
  }
}

export function omnyEventsStop() {
  const heartbeat = window.Taq.getExtension('HeartBeat');
  const {
    player: { currentOnDemandClip, onDemandPlaybackTime },
  } = getStore().getState();

  const isOmny = currentOnDemandClip?.podcast?.feedUrl?.split('/')[4] === 'playlist';
  if (isOmny && heartbeat?.counter !== -1 && clipId) {
    const currentOrganizationId = currentOnDemandClip?.podcast?.feedUrl?.split('/')[5];
    seqNumber++;

    events.push({
      OrganizationId: currentOrganizationId,
      ClipId: clipId,
      SessionId: sessionId,
      Type: 'Stop',
      Position:
        currentOnDemandClip?.id === clipId
          ? Math.ceil(onDemandPlaybackTime)
          : Math.ceil(events[events.length - 1]?.Position + heartbeat?.counter),
      SeqNumber: seqNumber,
      Timestamp: `${Math.round(new Date().getTime() / 1000)}`,
    });

    const endListenPosition =
      currentOnDemandClip?.id === clipId
        ? Math.ceil(onDemandPlaybackTime)
        : Math.ceil(events[events.length - 1]?.Position + heartbeat?.counter);
    if (endListenPosition === currentOnDemandClip?.durationSeconds) {
      const data = {
        Source: 'CustomWeb',
        Events: events,
        Completed: true,
      };

      fetch(process.env.REACT_APP_OMNY_URL + currentOrganizationId, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(() => resetState());
    }
  } else {
    if (clipId && events.length) {
      if (events.length % 2 !== 0) {
        seqNumber++;

        events.push({
          OrganizationId: events[0]?.OrganizationId,
          ClipId: clipId,
          SessionId: sessionId,
          Type: 'Stop',
          Position: Math.ceil(events[events.length - 1]?.Position + heartbeat?.counter),
          SeqNumber: seqNumber,
          Timestamp: `${Math.round(new Date().getTime() / 1000)}`,
        });
      }
      if (!isOmny) {
        const data = {
          Source: 'CustomWeb',
          Events: events,
          Completed: true,
        };

        fetch(process.env.REACT_APP_OMNY_URL + events[0]?.OrganizationId, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then(() => resetState());
      }
    }
  }
}

function resetState() {
  sessionId = '';
  clipId = '';
  ourClipId = '';
  seqNumber = 1;
  events = [];
  organizationId = '';
}
