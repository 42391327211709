export enum GIGYA_SCREENSET {
  REGISTRATION_LOGIN = 'TA_2021-RegistrationLogin',
  RESET_PASSWORD_SCREEN = 'gigya-reset-password-screen',
}

export enum GIGYA_ACCOUNT_ACTION {
  RESET_PASSWORD = 'reset-password',
  ACTIVATION = 'activation',
}

export enum GIGYA_MODAL_MESSAGE {
  DIALOG_TITLE_SUCCESS = 'Je hebt nu een JUKE account!',
  DIALOG_TITLE_FAILED = 'JUKE Account',
  CONTENT_TITLE_SUCCESS = 'Log nu in op de JUKE app',
  CONTENT_TITLE_FAILED = 'Aanmelden bij JUKE mislukt',
  // eslint-disable-next-line max-len
  TEXT_SUCCESS = `Je hebt met succes een account aangemaakt. Open de app en login om jouw favoriete radiostations toe te voegen, luister in HD kwaliteit of ga verder waar je gebleven was met je favoriete podcast.`,
  TEXT_FAILED = 'Helaas is er iets niet goed gegaan bij je registratie voor een JUKE  account. Probeer het nogmaals.',
  BTN_SUCCESS = 'Log meteen in',
  BTN_FAILED = 'Sluit scherm',
}
