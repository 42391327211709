import { currentYear, currentTimeISO } from 'util/time';

const LOGO = 'https://static.talparadio.nl/images/juke/logo-juke.png';
const IMAGE = `https://juke.nl${process.env.PUBLIC_URL}/JUKE-Share-image.jpg`;

const general = {
  '@context': 'http://schema.org',
  '@graph': [
    {
      '@type': 'BroadCastService',
      BroadCaster: {
        '@type': 'Organization',
        name: 'Talpa Network',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'JUKE',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Hilversum, Nederland',
        postalCode: '1217 SC',
        streetAddress: 'Bergweg 70',
      },
      telephone: '035 538 5538',
      priceRange: 'free',
      logo: LOGO,
      image: IMAGE,
      url: 'https://juke.nl',
    },
    {
      '@type': 'ContactPoint',
      email: 'info@juke.nl',
      url: 'https://juke.nl/contact',
    },
    {
      '@type': 'Website',
      about:
        'Ontdek online radio luisteren met JUKE. Meer dan 200 radiozenders uit binnen- en buitenland, non-stop muziek voor elk moment en in elk genre én de beste podcasts.',
      author: {
        '@type': 'Organization',
        name: 'JUKE',
      },
      url: 'https://juke.nl',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://juke.nl/?search={search_term_string}',
        'query-input': 'required name=search_term_string',
      },
      copyrightHolder: 'Talpa Network',
      copyrightYear: currentYear,
      dateCreated: currentTimeISO,
      inLanguage: 'NL',
      keywords:
        'muziek,audio,radio,non-stop muziek,podcasts,radio luisteren,online radio,podcast luisteren,radio app,radio site,',
    },
  ],
};

export default general;
