import invariant from 'invariant';

import { fetchPages } from 'api/jukeContent';

import { fetchNavigation, fetchMobileLightNavigation } from 'api/contentfulContent';

import graphql from 'api/graphql';

import { contentActions } from 'actions/actions-const';
import { contentDownloaded } from 'actions/lifecycle-actions';
import { showNotification } from 'actions/notifications-actions';
import { setNavigation, setMobileLightNavigation } from 'actions/navigation-actions';

import { NotificationTypes, NotificationTexts } from 'components/Notifications/Notification';

import { MIN_TILE_SIZES } from 'globalConst/const';
import { CONTENT_TYPES } from 'globalConst/contentTypes-const';
import * as cssVariable from 'cssVariables';
import { isTouch } from 'util/device';
import logger from 'util/logger';
import { AppDispatch } from 'store';
import { Action } from 'redux';

/**
 * Gets main and footer navigation in non-blocking way.
 */
function getNavigations(dispatch: AppDispatch): void {
  fetchNavigation().then((navigationItems) => dispatch(setNavigation(navigationItems)));
  fetchMobileLightNavigation().then((mobileLightNavigation) =>
    dispatch(setMobileLightNavigation(mobileLightNavigation))
  );
}

/**
 * Gets main content: JSONs, random station and for pages.
 */
async function getMainContent(dispatch: AppDispatch): Promise<void> {
  const pPages = fetchPages();

  const [pages] = await Promise.all([pPages]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const randomStation = (await graphql.fetchRandomStation()) as any;

  // The minimum content we need are pages and station,
  // so check on this before dispatching a success
  invariant(pages && pages.length > 0, 'Pages should exists while loading Juke');
  // TODO we doen nu een Object.values, later willen juist objecten hebben in onze store

  // Dispatch the Contentful content first so the pages can start rendering (improved percieved page load)
  dispatch({
    type: contentActions.FETCH_PAGES_SUCCESS,
    pages: Object.values(pages),
  });

  const ranStation = randomStation
    ? {
        station: randomStation.station,
        cta: randomStation.cta,
      }
    : null;

  dispatch(setContent(ranStation, CONTENT_TYPES.RANDOM_STATION));

  return null;
}

export function downloadContent() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: AppDispatch): Promise<boolean | (Action<string> & { error: any })> => {
    try {
      dispatch({ type: contentActions.FETCH_CONTENT_REQUEST });

      getNavigations(dispatch);

      await getMainContent(dispatch);

      dispatch(contentDownloaded());
    } catch (error) {
      logger.error('Something went wrong while downloading and indexing content.', error);
      return dispatch({ type: contentActions.FETCH_CONTENT_FAILURE, error });
    }

    return true;
  };
}

export function isOnline() {
  return async (dispatch: AppDispatch): Promise<void> => {
    function checkConnection() {
      if (navigator.onLine) {
        dispatch({ type: contentActions.IS_ONLINE });
      } else {
        dispatch(
          showNotification({
            type: NotificationTypes.ERROR,
            message: NotificationTexts.NO_INTERNET,
          })
        );
        dispatch({ type: contentActions.IS_OFFLINE });
      }
    }

    window.addEventListener('online', checkConnection);
    window.addEventListener('offline', checkConnection);
    window.addEventListener('load', checkConnection);
  };
}

export function setCurrentPageSlug(pageSlug: string, pageSubSlug: string) {
  return (dispatch: AppDispatch): void => {
    dispatch({
      type: contentActions.SET_CURRENT_PAGE_SLUG,
      pageSlug,
      pageSubSlug,
    });
  };
}

export function setTileAmount(contentWidth: number) {
  return (dispatch: AppDispatch): void => {
    const tileAmount = {};
    Object.keys(MIN_TILE_SIZES).forEach((key) => {
      const [minWidthTile, minWidthTileMobile] = MIN_TILE_SIZES[key];
      const minWidth = cssVariable.gutter + (isTouch || contentWidth < 500 ? minWidthTileMobile : minWidthTile);
      let visibleItems = 1;
      while (contentWidth / (visibleItems + 1) >= minWidth) {
        visibleItems += 1;
      }
      tileAmount[key] = visibleItems;
    });

    dispatch({
      type: contentActions.SET_TILE_AMOUNT,
      tileAmount,
      contentWidth,
    });
  };
}

/**
 * Store the fetched content in the store
 */
export function setContent(data: unknown, contentType: string) {
  return (dispatch: AppDispatch): void => {
    dispatch({
      type: contentActions.FETCH_CONTENT_SUCCESS,
      payload: {
        data,
        type: contentType,
      },
    });
  };
}
