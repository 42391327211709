/** Supported schema.org PageTypes */
export const SCHEMA_PAGE_TYPE = {
  WEB_PAGE: 'WebPage',
  ITEM_PAGE: 'ItemPage',
  COLLECTION_PAGE: 'CollectionPage',
  CONTACT_PAGE: 'ContactPage',
};

export const SCHEMA_ACTION_TYPE = {
  LISTEN_ACTION: 'ListenAction',
  WATCH_ACTION: 'WatchAction',
  VIEW_ACTION: 'ViewAction',
};

export const SCHEMA_MY_JUKE_DEEPLINK = 'myjuke';

export const SCHEMA_OFFERS = {
  '@type': 'Offer',
  priceCurrency: 'EUR',
  price: '0.00',
};
