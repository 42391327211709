import React, { useRef, useState, useEffect } from 'react';

import { Image } from 'api/interfaces';
import { Station } from 'api/models';
import { getImageWithResamplingQuery, roundThreeDigits } from 'util/index';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import LazyTileImage from 'components/LazyTileImage';
import NavigationLink from 'components/NavigationLink';
import ProfilePlaceholder from 'components/svg/ProfilePlaceholder';
import ShowProgress from './ShowProgress';
import AirTime from './AirTime';
import { SHOW_PROGRESS_INTERVAL } from './ShowTile.const';
import classNames from 'classnames';
import { generateCTAClickData } from 'analytics/index';
import useBrowser from 'components/hooks/useBrowser';

import styles from './ShowTile.styles';
import { createUseStyles } from 'react-jss';

// @todo: use show model
export interface ShowTileProps {
  brandColor?: string;
  brandName?: string;
  broadcastEndDate?: Date;
  broadcastStartDate?: Date;
  disabled?: boolean;
  image?: Image;
  linkedPage?: string;
  logoSecondary?: Image;
  station?: Station;
  slug?: string;
  title?: string;
  isHorizontal?: boolean;
}

const useStyles = createUseStyles(styles, { name: 'ShowTile' });

const ShowTile = ({
  brandColor,
  brandName,
  broadcastEndDate,
  broadcastStartDate,
  disabled = false,
  image,
  linkedPage,
  logoSecondary,
  title,
  station,
  isHorizontal,
}: ShowTileProps): JSX.Element => {
  let interval: null | ReturnType<typeof setTimeout> = null;
  const classes = useStyles();
  const browser = useBrowser();

  const tileRef = useRef(null);
  const [size, setSize] = useState(0);
  const [progress, setProgress] = useState(null);

  function calculateProgress() {
    const now = new Date();
    const startDate = new Date(broadcastStartDate);
    const endDate = new Date(broadcastEndDate);
    if (startDate > now) {
      // show hasn't started yet
      setProgress(0);
      return;
    } else if (endDate < now) {
      // show ended
      setProgress(1);
      clearInterval(interval);
      return;
    }
    const duration = endDate.getTime() - startDate.getTime();
    const progressed = now.getTime() - startDate.getTime();

    setProgress(Math.min(Math.max(roundThreeDigits(progressed / duration), 0), 1));

    // check wheather or not size has changend
    const width = tileRef.current ? tileRef.current.getBoundingClientRect().width : null;
    if (Math.round(width) !== size) {
      setSize(Math.round(width));
    }
  }

  function onResize() {
    const width = tileRef.current ? tileRef.current.getBoundingClientRect().width : null;
    setSize(Math.round(width));
  }

  useEffect(() => {
    onResize();
  }, [browser.size]);

  useEffect(() => {
    if (broadcastStartDate && broadcastEndDate) {
      calculateProgress();

      interval = setInterval(calculateProgress, SHOW_PROGRESS_INTERVAL);
    }

    return () => {
      clearInterval(interval);
    };
  }, [broadcastStartDate]);

  if (disabled) {
    return <div className={classes.placeholder} data-testid="show-placeholder" />;
  }

  const now = new Date();
  const dateStart = new Date(broadcastStartDate);
  const dateEnd = new Date(broadcastEndDate);

  const isLive = now > dateStart && now < dateEnd;

  return (
    <NavigationLink
      aria-label={`Ga naar ${station ? station.title : brandName}`}
      pageUrl={linkedPage}
      source={CONTENT_TYPES.SHOW_TILE}
      title={title}
      className={classNames(classes.link, {
        [classes.horizontal]: isHorizontal,
      })}
      {...(isHorizontal && {
        onClick: () => {
          generateCTAClickData('showtile');
        },
      })}
    >
      <div
        className={classNames(classes.tile, {
          [classes.horizontalTile]: isHorizontal,
        })}
        ref={tileRef}
      >
        <div className={classes.hoverAnimation}>
          <div className={classes.imageWrapper}>
            {image ? (
              <LazyTileImage
                className={classes.image}
                imageUrl={getImageWithResamplingQuery({
                  url: image.url,
                  isSquare: true,
                })}
                alt={title}
                isImg={true}
              />
            ) : (
              <ProfilePlaceholder className={classes.image} />
            )}
            {isLive && (
              <div
                className={classNames(classes.onAirBadgeWrapper, {
                  [classes.onAirBadgeWrapperSmall]: isHorizontal,
                })}
              >
                <span
                  className={classNames(classes.onAirBadge, {
                    [classes.onAirBadgeSmall]: isHorizontal,
                  })}
                >
                  On-Air
                </span>
              </div>
            )}
          </div>
          <ShowProgress size={size} brandColor={brandColor} progress={progress} />
        </div>
      </div>
      <div
        className={classNames(classes.info, {
          [classes.infoHorizontal]: isHorizontal,
        })}
      >
        {!isHorizontal && (
          <div className={classes.logoWrapper}>
            {logoSecondary ? (
              <img
                className={classes.logo}
                src={getImageWithResamplingQuery({ url: logoSecondary.url, isLandcape: true })}
                alt={'logo'}
              />
            ) : (
              <div className={classes.title}>{station ? station.title : brandName}</div>
            )}
          </div>
        )}
        <div>
          <p className={classes.title}>{title}</p>
          <p className={classes.airTime}>
            <AirTime timeStart={broadcastStartDate} timeEnd={broadcastEndDate} />
          </p>
        </div>
      </div>
    </NavigationLink>
  );
};

export default ShowTile;
