import React, { useContext } from 'react';
import classNames from 'classnames';

import CustomLink from 'components/CustomLink';
import CollectionContext from 'components/Collection/CollectionContext';
import LazyTileImage from 'components/LazyTileImage';

import * as cssVariable from 'cssVariables';
import * as analytics from 'analytics';
import { getImageWithResamplingQuery } from 'util/resampling';

import { isTouch } from 'util/device';
import { createUseStyles } from 'react-jss';
import styles from './TagTile.styles';

interface ImageProps {
  url: string;
}

export interface TagTileProps {
  brandColor?: string;
  contentTypeId?: string;
  disabled?: boolean;
  image?: ImageProps;
  name?: string;
  slug?: string;
  title?: string;
  userSearched?: string;
  saveRecentSearch: (contentTypeId: string, slug: string) => void;
  pageLink?: string;
}

function navLink(pathname: string, genreName: string, userSearched: string) {
  return {
    pathname,
    state: {
      analytics: analytics.generateTileClick(genreName, pathname, 'genre page', userSearched),
    },
  };
}

const useStyles = createUseStyles(styles, { name: 'TagTile' });
const TagTile = ({
  brandColor,
  image,
  disabled,
  name,
  slug,
  title,
  userSearched,
  contentTypeId,
  pageLink,
  saveRecentSearch,
}: TagTileProps): JSX.Element => {
  const classes = useStyles();
  const { isInSearch } = useContext(CollectionContext);

  const saveToLocalStorage = () => {
    saveRecentSearch(contentTypeId, slug);
  };

  if (disabled) {
    return <div className={classes.tagTile} />;
  }

  const isBrand = contentTypeId === 'brand';
  const handleClick = isInSearch ? saveToLocalStorage : undefined;

  return (
    <CustomLink
      data-testid={`genre-${slug}`}
      className={classNames('genre-tile-default', classes.tagTile, {
        [classes.noHoverAnimationDefault]: isTouch,
      })}
      isLink
      to={navLink(pageLink, name || title, userSearched)}
      style={{
        background: brandColor && cssVariable.hexToRgba(brandColor, 0.6),
      }}
      onClick={handleClick}
    >
      <div className={classes.trianglesDefault}>
        {image && (
          <LazyTileImage
            data-key="tile-image"
            className={classes.genreBackdrop}
            imageUrl={getImageWithResamplingQuery({
              url: image.url,
              isLandcape: true,
              isTransparent: true,
            })}
          />
        )}
      </div>
      {!isBrand && (
        <div className={classes.genreContent}>
          {(name || title) && (
            <h3
              className={classNames(classes.title, {
                [classes.titleMobile]: isTouch,
              })}
            >
              {name || title}
            </h3>
          )}
        </div>
      )}
    </CustomLink>
  );
};

export default TagTile;
