import { queryRequest } from './client';
import { getTags, getTag, getOverview } from './queries';
import { TagCollection, Overview, Tag } from 'api/models';

/**
 * Fetch a genre collections from the universal api
 *
 * @param {object} param
 * @param {string} param.slug
 * @param {object} param.sectionConfig
 * @param {object} param.collectionProps
 *
 * @returns {Object} PodcastCollection
 */
export const fetchTagCollection = async ({ slug, config, options }) => {
  const { data } = (await queryRequest({ query: getTags, variables: { slug } })) || {};
  if (data && data.collection) {
    return new TagCollection(data.collection, config, options);
  }
  return null;
};

/**
 * Fetch all details for the genre page from the Universal API
 * @param {string} slug
 * @returns {Object} including fields genre {Genre} and overview {Overview}
 */
export const fetchTagPage = async ({ slug }) => {
  const { data } = (await queryRequest({ query: getTag, variables: { slug } })) || {};
  const genre = new Tag(data.tag);

  if (!genre.overviewSlug) {
    return {
      genre,
      overview: {
        sections: genre.refs,
      },
    };
  }

  const {
    data: { overview },
  } =
    (await queryRequest({
      query: getOverview,
      variables: { slug: genre.overviewSlug },
    })) || {};

  return {
    genre,
    overview: new Overview(overview),
  };
};

/**
 * Fetch a single genre from the universal api
 * @param {string} slug
 * @returns {Object} Tag
 */
export const fetchTag = async ({ slug }) => {
  const { data } = (await queryRequest({ query: getTag, variables: { slug } })) || {};
  return new Tag(data.tag);
};
