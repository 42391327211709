import React from 'react';
import PropTypes from 'prop-types';

import { SCHEMA_PAGE_TYPE } from 'globalConst/schema-const';

import Schema from './Schema';

const PageTypeSchema = ({ type, metadata }) => {
  const location = `https://juke.nl${window.location.pathname}`;
  let mainEntity;

  if (!metadata) {
    const basicSchema = {
      '@context': 'http://schema.org',
      '@type': type,
      url: location,
      mainEntity,
    };

    return <Schema data={basicSchema} />;
  }

  const { title, description, tags, share } = metadata;

  const schema = {
    '@context': 'http://schema.org',
    '@type': type,
    url: location,
    name: title,
    description,
    mainEntity,
  };

  if (tags && tags.length) {
    schema.keywords = tags.join();
  }

  if (type === SCHEMA_PAGE_TYPE.ITEM_PAGE && share && share.image) {
    schema.image = share.image.url;
  }

  return <Schema data={schema} />;
};

PageTypeSchema.propTypes = {
  type: PropTypes.string,
  metadata: PropTypes.object,
  question: PropTypes.string,
  answer: PropTypes.array,
};

PageTypeSchema.defaultProps = {
  type: SCHEMA_PAGE_TYPE.WEB_PAGE,
  metadata: null,
  question: null,
  answer: null,
};

export default PageTypeSchema;
