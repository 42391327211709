import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NO_PLAYOUTS_MESSAGE, PLAYOUTS_MESSAGE } from 'globalConst/const';
import { createUseStyles } from 'react-jss';

import NowPlayingTrack from '../NowPlayingTrack';
import Track from '../Track';

import styles from './PlayoutHistory.styles';
import { isEmpty } from 'util/index';

const useStyles = createUseStyles(styles, { name: 'PlayoutHistory' });

const PlayoutHistory = ({ playouts, isLoading }) => {
  const classes = useStyles();

  if (isLoading) return null;

  if (isEmpty(playouts)) {
    return <p className={classes.noPlayoutTitle}>{NO_PLAYOUTS_MESSAGE}</p>;
  }
  return (
    <Fragment>
      <NowPlayingTrack lastPlayout={playouts[0]} />
      <div className={classes.containerWrapper}>
        <div className={classes.container} data-testid="player_previously_played">
          <p className={classes.title}>{PLAYOUTS_MESSAGE}</p>
          <div className={classes.trackListContainer}>
            {playouts.slice(1).map((track) => (
              <div key={`${track.id}-${track.isrc}`} className={classes.trackWrapper}>
                <Track track={track} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PlayoutHistory.propTypes = {
  playouts: PropTypes.array,
  isLoading: PropTypes.bool,
};

PlayoutHistory.defaultProps = {
  playouts: null,
  isLoading: false,
};

export default PlayoutHistory;
