/**
 * generate icon object for new icons from contnenful
 * also provide fallback for old icons we have in juke
 * @param {object} item
 * @returns {{iconType: *, iconUrl: *}} object containing icon type fro fallback icons and iconUrl from contenful if any
 */
function generateIconForMainNavigation(item) {
  const iconsPagesMatch = {
    home: 'IconHome',
    radiozenders: 'IconRadio',
    muziekgenres: 'IconGenre',
    radioshows: 'IconShow',
    podcasts: 'IconPodcast',
    terugluisteren: 'IconTerugluisteren',
    mijnjuke: 'IconMusic',
    jukeapp: 'IconCrown',
  };
  return {
    iconType: iconsPagesMatch[item.fields.name.toLowerCase().replace(/\s/g, '')],
    iconUrl: item.fields.icon && item.fields.icon.fields.file.url,
  };
}

/**
 * generate navigation item object from data from contenful
 * pageLink - has linked page to it, if not the url must be provided
 * if has none, item will be ignored
 * @param {object} item - navigation item
 * @param {bool} isChild - set true if item is a child item, navigation goes only 2 levels
 * @returns {{id: *, icon: {iconType: *, iconUrl: *}, exact: boolean, children: (boolean|*)}} formatted item for navigation
 */
function generateNavItem(item) {
  if (item.fields.pageLink || item.fields.pageUrl) {
    return {
      id: item.fields.name,
      title: item.fields.title,
      icon: generateIconForMainNavigation(item),
      pageLink: item.fields.pageLink ? { slug: item.fields.pageLink.fields.slug } : null,
      pageUrl: item.fields.pageUrl ? item.fields.pageUrl : null,
      isNew: item.fields.isNew || false,
    };
  }
  return null;
}

/**
 * create default navigation structure for main juke navigation
 * @param {object} navigation from contenful
 * @returns {array} {*}
 */
export default function formatNavigationItemsForMainNavigation(navigation) {
  if (!navigation.navigationItems.length) {
    return null;
  }
  const formattedNavigation = [];
  navigation.navigationItems.forEach((item) => {
    const formattedItem = generateNavItem(item);
    if (formattedItem) {
      formattedNavigation.push(formattedItem);
    }
  });
  return formattedNavigation;
}
