import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import IconPause from 'components/svg/Pause';
import * as cssVariable from 'cssVariables';
import { getImageWithResamplingQuery } from 'util/resampling';

class Overlay extends PureComponent {
  static defaultProps = {
    image: null,
    title: '',
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    image: PropTypes.object,
    title: PropTypes.string,
    clickHandlerPause: PropTypes.func.isRequired,
  };

  render() {
    const { classes, image, title, clickHandlerPause } = this.props;

    const imageUrl =
      image && image.url
        ? `${getImageWithResamplingQuery({
            url: image.url,
            customWidth: 800,
            customHeight: 470,
          })}`
        : '';

    return (
      <section>
        <div className={classes.wrapper}>
          <div
            className={classes.overlayWrapper}
            style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : { backgroundImage: 'none' }}
            onClick={clickHandlerPause}
            onKeyPress={clickHandlerPause}
            role="button"
            tabIndex="0"
          >
            <button type="button" className={classes.pauseButton}>
              <IconPause className={classes.icon} hasStroke={false} />
            </button>
            <h2 className={classes.ctaText}>Stop {title}</h2>
          </div>
        </div>
      </section>
    );
  }
}

const ICON_DIMENSIONS = {
  width: '60px',
  height: '60px',
};

const styles = {
  overlayWrapper: {
    ...cssVariable.fillAspectRatio,
    ...cssVariable.backgroundCenterCoverNoRepeat,
    zIndex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: cssVariable.grey300,
    transition: '.4s ease opacity',
    '&:before': {
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: cssVariable.grey600,
      content: '""',
      opacity: 0.2,
      width: '100%',
      height: '100%',
      transition: 'opacity .3s ease',
    },
    '&:hover': {
      cursor: 'pointer',
      '&:before': {
        opacity: 0.4,
      },
    },
    '&:hover button': {
      backgroundColor: cssVariable.grey600,
    },
  },
  icon: {
    ...ICON_DIMENSIONS,
  },
  pauseButton: {
    ...ICON_DIMENSIONS,
    borderRadius: '50%',
    backgroundColor: cssVariable.blue500,
    transition: '.4s ease background-color',
    border: 'none',
    padding: '0',
    zIndex: 1,
  },
  ctaText: {
    textTransform: 'uppercase',
    color: cssVariable.white,
    fontSize: cssVariable.fontSizes.xlarge,
    fontWeight: cssVariable.fontWeights.bold,
    marginLeft: '20px',
    zIndex: 1,
    display: 'inline-block',
  },
};

export default injectStyles(styles)(Overlay);
