import { UniversalSection, Config } from 'api/interfaces';
import { isEmpty, parseConfig } from 'util/index';
import { TAB_TYPES, COLLECTION_OPTIONS } from 'globalConst/const';
import CONTENT_TYPES from 'globalConst/contentTypes-const';

/**
 * @class
 * A representation of an Section
 *
 * @param {object} section - section model
 */
export default class Section {
  uuid: string;
  slug: string;
  contentTypeId: string;
  config: Config;
  options: [];
  tabs: unknown;
  hasAnchorTabs: boolean;
  indexableTabs: boolean;
  title: string;
  isHighlighted: boolean;
  playoutsDisabled: boolean;

  constructor(section: UniversalSection) {
    if (!section) {
      return null;
    }

    /*
     * if the section has a ondemand option we set the contentTypeId to onDemandCollection,
     * with this the DynamicContentComponent knows it needs to render the epsiodes of the ondemand item
     * The ref item in the section needs to be a showCollection otherwise the collection will not be shown.
     */

    const ondemandCollection =
      section.options && section.options.some((option: string) => option.toLowerCase().includes('ondemand'));

    this.uuid = section.id;
    if (!isEmpty(section.refs)) {
      const [{ uri, targetType }] = section.refs;
      this.slug = uri;
      this.contentTypeId = ondemandCollection ? CONTENT_TYPES.ON_DEMAND_COLLECTION : targetType;
    }
    this.config = parseConfig(section.config);
    this.options = section.options;

    if (section.hasTabs) {
      this.contentTypeId = 'tabs';
      this.indexableTabs = section.options.some((option) => option === TAB_TYPES.INDEXABLE);
      this.hasAnchorTabs = section.options.some((option) => option === TAB_TYPES.ANCHOR);
      this.tabs = section.tabs;
    }
    this.isHighlighted = section.options.some((option) => option === COLLECTION_OPTIONS.HIGHLIGHT);
    this.playoutsDisabled = section.options.some((option) => option === COLLECTION_OPTIONS.NO_PLAYOUTS);

    // Make this class read-only
    Object.freeze(this);
  }
}
