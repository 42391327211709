import * as cssVariable from 'cssVariables';

export default () => ({
  noEpisodesInfo: {
    fontSize: '22px',
    fontWeight: cssVariable.fontWeights.bold,
  },
  noEpisodesInfoMobile: {
    fontSize: '17px',
    textAlign: 'center',
  },
});
