import React from 'react';
import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg viewBox="0 0 30 30">
    <title>{'Atom / Icon / Navigation / No_promotion'}</title>
    <path
      d="M3.854 3.146l23 23a.502.502 0 01-.708.708l-23-23a.502.502 0 01.708-.708zm5.396 8.225l1 1V15.9c0 2.812 2.131 5.1 4.75 5.1 1.069 0 2.046-.396 2.841-1.038l.713.713c-.858.719-1.905 1.192-3.054 1.298V24H18a.5.5 0 010 1h-6a.5.5 0 010-1h2.5v-2.027c-2.937-.27-5.25-2.888-5.25-6.073v-4.529zM20.25 5a.5.5 0 01.5.5v10.4c0 .815-.155 1.589-.429 2.3l-.803-.803c.136-.477.232-.973.232-1.497V6h-9.5v2.129l-1-1v-1.63a.5.5 0 01.5-.5zM24 7.5a.5.5 0 01.5.5v2.5c0 1.654-1.233 3-2.75 3v-1c.965 0 1.75-.897 1.75-2v-2h-1.75v-1zm-18.402.219l.902.902V10.5c0 1.103.785 2 1.75 2v1c-1.517 0-2.75-1.346-2.75-3V8c0-.106.041-.2.098-.281z"
      fill="#3A3C42"
      fillRule="nonzero"
    />
  </svg>
);

export default svgIcon(svg);
