import { createSelector } from 'reselect';
import { playerHintActions } from '../../actions/actions-const';

const initialState = { visible: false, keepHidden: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case playerHintActions.PLAYER_SET_PLAYER_HINT:
      return {
        ...state,
        visible: action.visible,
        keepHidden: action.keepHidden || state.keepHidden,
      };

    default:
      return state;
  }
}

const visibleSelector = (state) => state.playerhint.visible;

/**
 * Given the current state, it returns the whether or not the miniPlayer is shown fully
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getKeepHidden = (state) => state.playerhint.keepHidden;

/**
 * Given the current state, it returns the whether or not the player hint is visible
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIsVisible = createSelector(
  [visibleSelector, getKeepHidden],
  (visible, keepHidden) => visible && !keepHidden
);
