import * as React from 'react';
import { isEmpty } from 'util/index';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { isMobile, isTablet } from 'util/device';
import classNames from 'classnames';
import FOOTER from '../Footer/Footer.const';
import { Boxes } from './footerNavigation.utils';
import FooterNavigationItem from './FooterNavigationItem';
import { NavigationItemProps } from 'api/models/Navigation';
import styles from './FooterNavigationCollection.styles';

type NavigationCollectionItemProps = {
  title: string;
  items: [NavigationItemProps];
};

export interface FooterNavigationCollectionProps {
  variant: string;
  items: Array<NavigationCollectionItemProps>;
}

const useStyles = createUseStyles(styles, { name: 'FooterNavigationCollection' });

const FooterNavigationCollection: React.FC<FooterNavigationCollectionProps> = ({
  items,
  variant = FOOTER.ORDER_SECONDARY,
}) => {
  const classes = useStyles();

  if (isEmpty(items)) return null;

  const wrapper = classNames({
    [classes.list]: variant === FOOTER.ORDER_SECONDARY,
    [classes.grid]: variant === FOOTER.ORDER_PRIMARY,
  });

  return (
    <div className={wrapper}>
      {items?.map((links, index) => (
        <div
          key={`footer-${variant}-${links?.title}-${index}`}
          className={classNames({
            [classes.sectionMobile]: index === Boxes.SOCIALS && isMobile && variant === FOOTER.ORDER_PRIMARY,
            [classes[`sectionTablet${index + 1}`]]: isTablet() && variant === FOOTER.ORDER_PRIMARY,
          })}
        >
          {variant === FOOTER.ORDER_PRIMARY && <div className={classes.title}>{links.title}</div>}

          <ul
            className={classNames(classes.elements, {
              [classes.socialIcons]: variant === FOOTER.ORDER_PRIMARY && index === Boxes.SOCIALS,
              [classes.secondaryElements]: variant === FOOTER.ORDER_SECONDARY,
            })}
          >
            {links?.items?.map(({ title, pageLink, uri, icon }, iIndex) => (
              <FooterNavigationItem
                key={`footer-${variant}-${title}-${iIndex}`}
                variant={variant}
                title={title}
                pageLink={pageLink}
                uri={uri}
                icon={icon}
                order={index}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

FooterNavigationCollection.propTypes = {
  variant: PropTypes.string,
  items: PropTypes.array,
};

export default FooterNavigationCollection;
