import { isIE11andLower } from 'util/device';

export default (theme) => ({
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: isIE11andLower ? 'flex-start' : 'center',
    height: '100%',
    overflow: 'hidden',
    paddingLeft: isIE11andLower ? 30 : 0,
    marginLeft: isIE11andLower ? -30 : 0,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'space-around',
  },
  play: {
    position: 'relative',
    height: 50,
    zIndex: 1000,
  },
  mr40: {
    marginRight: 40,
  },
  smallTouch: {
    justifyContent: 'flex-end',
    paddingRight: 25,
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    mr40: {
      marginRight: 0,
    },
  },
  [`@media (max-width: ${theme.breakpoints.values.xs}px)`]: {
    smallTouch: {
      paddingRight: 15,
    },
  },
});
