const defaultTheme = {
  palette: {
    primary: {},
    secondary: {},
    grey: {},
    default: {},
    misc: {},
  },

  icons: {},

  shape: {},

  shadows: {
    large: '0 0 5px 0 rgba(0, 0, 0, 0.3)',
    medium: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
    small: '1px 1px 5px 0 rgba(0, 0, 0, 0.2)',
  },

  transitions: {
    easing: {},
    duration: {},
  },
  typography: {
    fontFamilyHeavy: 'BrutalTypeBlack',
    fontFamilyBold: 'BrutalTypeBold',
    fontFamilyRegular: 'BrutalTypeRegular',
    fontFamilyBoldAlt1: 'ReplicaBoldSub',
    fontFamilyHeavyAlt1: 'ReplicaHeavy',

    headerLarge: {},
    headerMedium: {},
    headerSmall: {},
    articleHeadline: {},
    headlineLarge: {},
    headlineMedium: {},
    headlineMediumRelatedArticle: {},
    headlineSmall: {},
    buttonCta: {},
    correctGuesses: {},
    guessIndex: {},
    contactItem: {},
    frequencySearchClearText: {},
    titleMedium: {},
    signatureHeadlineLarge: {},
    signatureInitial: {},
    signatureHeadlineMedium: {},
    tag: {},
    infoMedium: {},
    infoSmall: {},
    paragraph: {},
    paragraphBold: {},
    paragraphLink: {},
    inputMedium: {},
    inputSmall: {},
    caption: {},
    labelLarge: {},
    labelMedium: {},
    labelSmall: {},
    link: {},
    linkSmall: {},
    trackRegular: {},
    trackBold: {},
    date: {},
    dateClip: {},
    trackDescription: {},
    quote: {},
    videoListTitle: {},
    notFoundTitle: {},
    loginProfileName: {},
    stationTitle: {},
  },
  components: {},
  utils: {
    grid: {
      width: {},

      maxWidth: {},
    },

    smallGrid: {},
  },
};

export default defaultTheme;
