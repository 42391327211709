import React from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import * as cssVariable from 'cssVariables';
import Radio from 'components/svg/Radio';
import Loader from 'components/Loader';

const Placeholder = ({ classes, isLoading }) => (
  <div className={classes.container}>
    <div className={classes.radio}>{isLoading ? <Loader /> : <Radio />}</div>
    {isLoading ? <p>laden....</p> : <p> Zoek naar station, show, terugluisteren of genres </p>}
  </div>
);

Placeholder.defaultProps = {
  isLoading: false,
};

Placeholder.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

const styles = (theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: '0',
    },
    to: {
      opacity: '1',
    },
  },
  container: {
    position: 'absolute',
    top: 'calc(50% - 125px)',
    width: 'calc(100% - 40px)',
    opacity: '0',
    textAlign: 'center',
    animation: `$fadeIn ${cssVariable.transitionTime} ease-in ${cssVariable.transitionDelay} forwards`,
    '& > p': {
      fontSize: cssVariable.fontSizes.medium,
      color: cssVariable.grey500,
    },
  },
  // fix for 16:9 smartphones, to avoid overlaping of footer and mini player
  [`@media (max-height: 610px) and (max-width: ${theme.breakpoints.values.xs}px)`]: {
    container: {
      top: 70,
    },
  },
  // hide image if height is very small
  '@media (max-height: 410px)': {
    radio: {
      display: 'none',
    },
    // depending on layout type (mobile od desktop), move text
    [theme.breakpoints.down('md')]: {
      container: {
        top: 90,
      },
    },
    [theme.breakpoints.up('md')]: {
      container: {
        top: 140,
      },
    },
  },
});

export default injectStyles(styles)(Placeholder);
