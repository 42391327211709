import { ratingActions } from 'actions/actions-const';

const initialState = {
  appRating: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ratingActions.SET_APP_RATING:
      return {
        ...state,
        appRating: action.appRating,
      };

    default:
      return state;
  }
}

/**
 * Given the current state, it returns app rating for Android
 *
 * @param {object} state
 * @returns {object}
 */
export const getRatingAndroidApp = (state) =>
  state.rating.appRating && (state.rating.appRating.AndroidPlayStore || null);

/**
 * Given the current state, it returns app rating for IOS
 *
 * @param {object} state
 * @returns {object}
 */
export const getRatingIOSApp = (state) => state.rating.appRating && (state.rating.appRating.iOSAppStore || null);

/**
 * Given the current state, it returns app ratings
 *
 * @param {object} state
 * @returns {object}
 */
export const getRating = (state) => state.rating.appRating || null;
