import isEmpty from './isEmpty';

const capitalizeFirstLetter = (string: string): string => {
  if (isEmpty(string)) {
    return null;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default capitalizeFirstLetter;
