import * as analytics from 'analytics';
import AudioPlayerStation from 'players/AudioPlayerStation';
import AudioPlayerOnDemand from 'players/AudioPlayerOnDemand';
import VideoJWPlayer from 'players/VideoJWPlayer';
import ChromeSender from 'players/ChromeSender';

import { playerVolumeActions } from './actions-const';
import { AppDispatch, RootState } from 'store';

// Add new players here
const players = [AudioPlayerStation, AudioPlayerOnDemand, VideoJWPlayer];

export function setVolumeLevel(
  volumeLevel: number,
  trackAnalytics = true
): (dispatch: AppDispatch, getState: () => RootState) => Promise<void> {
  return async (dispatch, getState) => {
    const { jwPlayer } = getState();

    const muted = volumeLevel === 0;

    // we do not want to change volume level when jwplayer is active because it can mess up the triton behavior
    // because triton stops automatically when it's muted and vice-versa
    if (!jwPlayer.isActive) {
      players.forEach((player) => {
        player.setVolumeLevel(volumeLevel);
        player.setMuted(muted);
      });
    }
    ChromeSender.setVolumeLevel(volumeLevel);
    ChromeSender.setMuted(muted);

    if (trackAnalytics) {
      analytics.trackVolume(volumeLevel);
    }
    dispatch({ type: playerVolumeActions.SET_VOLUME_LEVEL, volumeLevel });
  };
}

export function setMuted(
  shouldBeMuted: boolean,
  trackAnalytics = true
): (dispatch: AppDispatch, getState: () => RootState) => Promise<void> {
  return async (dispatch, getState) => {
    const {
      player: { isMuted, volumeLevel: currentVolumeLevel },
    } = getState();

    players.forEach((player) => player.setMuted(shouldBeMuted));
    ChromeSender.setMuted(shouldBeMuted);

    dispatch({ type: playerVolumeActions.SET_MUTED, muted: shouldBeMuted });
    if (shouldBeMuted || shouldBeMuted === isMuted) {
      if (trackAnalytics) {
        analytics.trackVolume(0);
      }
      return;
    }
    const targetVolumeLevel = currentVolumeLevel === 0 ? 1 : currentVolumeLevel;
    dispatch(setVolumeLevel(targetVolumeLevel, trackAnalytics));
  };
}

export function toggleMute(): (dispatch: AppDispatch, getState: () => RootState) => Promise<void> {
  return async (dispatch, getState) => {
    const {
      player: { isMuted },
    } = getState();
    const nextMutedState = !isMuted;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch<any>(setMuted(nextMutedState));
  };
}
