import { breakpoints } from 'components/theme/breakpoints';

import { useEffect, useState } from 'react';

interface Browser {
  size: {
    current: number;
    next: number;
  };
  up: (breakpoint: number) => boolean;
  down: (breakpoint: number) => boolean;
}

const useBrowser = (): Browser => {
  const [size, setSize] = useState({
    current: 0,
    next: 0,
  });

  const handleResize = () => {
    const { innerWidth } = window;
    if (innerWidth < size.current || innerWidth >= size.next) {
      setBreakpoints(innerWidth);
    }
  };

  const setBreakpoints = (width: number) => {
    let current = 0;
    let next = 0;
    Object.values(breakpoints).some((val) => {
      if (val <= width) {
        current = val;
        return false;
      }
      if (val > width) {
        next = val;
        return true;
      }
    });

    setSize({
      current,
      next,
    });
  };

  useEffect(() => {
    setBreakpoints(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size]);

  const up = (breakpoint: number): boolean => {
    return window.innerWidth >= breakpoint;
  };

  const down = (breakpoint: number): boolean => {
    return window.innerWidth < breakpoint;
  };

  return {
    size,
    up,
    down,
  };
};

export default useBrowser;
