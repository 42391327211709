import * as cssVariable from 'cssVariables';

export default (theme) => ({
  container: {
    padding: [10, 26],
    zIndex: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  iconContainer: {
    width: 70,
    height: 70,
    backgroundSize: 'contain',
  },

  icon: {
    color: cssVariable.white,
    padding: [0, 5],
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    fontSize: cssVariable.fontSizes.large,
  },
  infoArea: {
    paddingLeft: 15,
    paddingTop: 16,
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
    color: cssVariable.white,
  },
  title: {
    fontSize: cssVariable.fontSizes.small,
    fontWeight: cssVariable.fontWeights.bold,
    lineHeight: '19px',
  },
  subtitle: {
    fontSize: cssVariable.fontSizes.small,
    lineHeight: '19px',
  },
  smallDesktop: {},
  [`@media (max-width: ${theme.breakpoints.values.sl}px)`]: {
    infoArea: {
      maxWidth: '70%',
      paddingTop: 8,
    },
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    container: {
      position: 'relative',
      left: -20,
    },
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    smallDesktop: {
      left: -10,
      '& $iconContainer': {
        display: 'none',
      },
      '& $infoArea': {
        maxWidth: '60%',
      },
    },
  },
  [`@media (max-width: ${theme.breakpoints.values.xs}px)`]: {
    container: {
      left: -30,
    },
    infoArea: {
      maxWidth: '67%',
      paddingLeft: 0,
    },
    smallDesktop: {
      left: -10,
      '& $infoArea': {
        width: 110,
        maxWidth: '100%',
        paddingTop: 0,
      },
    },
  },
});
