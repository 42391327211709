import React from 'react';
import Schema from './Schema';

const BreadcrumbsSchema = (piece) => {
  const loc = window.location.pathname.replace(__URL_PREFIX__, '');
  const breadcrumbArray = loc.split('/');
  const itemListElement = breadcrumbArray.reduce((bread, loaf, i) => {
    let crumb;
    if (i === 0) {
      crumb = {
        '@type': 'ListItem',
        position: 1,
        item: 'https://juke.nl',
        name: 'JUKE',
      };
    } else if (i !== 0 && loaf === '') {
      return bread;
    } else {
      crumb = {
        '@type': 'ListItem',
        position: i + 1,
        item: `${bread && bread[i - 1].item}/${loaf}`,
        name: piece[`level${i + 1}`],
      };
    }
    return [...bread, crumb];
  }, []);
  return (
    <Schema
      data={{
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement,
      }}
    />
  );
};

export default BreadcrumbsSchema;
