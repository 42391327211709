export const CONFIG_FRAGMENT = `
  fragment ConfigFragment on Config {
    type
    entries {
      key
      value
      type
    }
  }
`;
