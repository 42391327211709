import history from 'lib/history';
import { getSlugfromUrl, setCustomData, setPersistentData, taqPersistentData } from 'analytics';
import { getStore } from 'store';

export function beforeUnload() {
  window.addEventListener('beforeunload', () => {
    const { currentStation } = getStore().getState().player;
    window.Taq.push([
      'setEventData',
      {
        event_name: 'finish',
        event_category: 'finish',
        event_action: 'finish',
        event_label: currentStation ? currentStation.name : '',
      },
    ]);
    window.Taq.push(['track', 'link']);
  });
}

// Setup listener for route changes
export function historyListen() {
  history.listen((location) => {
    if (!location.state || !location.state.analytics) {
      return;
    }

    taqPersistentData.page_id = getSlugfromUrl().length ? getSlugfromUrl() : 'home';
    taqPersistentData.page_category = location.state.analytics.page_category
      ? location.state.analytics.page_category
      : null;

    window.Taq.push(['setEventData', location.state.analytics.event || location.state.analytics]);

    if (location.state.analytics.custom) {
      setCustomData(location.state.analytics.custom);
    }

    window.Taq.push(['track', 'link']);

    // Timeout is needed to give enough time for page metadata to load
    setTimeout(() => {
      setPersistentData(taqPersistentData);
      window.Taq.push(['track', 'view']);
    }, 1000);
  });
}
