import PodcastEpisode from './PodcastEpisode';
import Podcast from './Podcast';
import { isEmpty } from 'util/index';
import { UniversalConfig, UniversalClipCollection, UniversalClip } from '../interfaces';
import Collection from './Collection';
import { TILE_SIZES } from 'globalConst/const';
import { DisplayLayout } from 'components/Collection/Collection.const';
import SHOW_MORE from '../../globalConst/searchResults-const';

/**
 * @class
 * A representation of a ClipCollection
 *
 * @param {object} collection - collection model
 */
export default class ClipCollection extends Collection {
  tileSizes: string = TILE_SIZES.SMALL;
  items: Array<PodcastEpisode>;
  display = DisplayLayout.LIST;
  rows: number = SHOW_MORE.PODCASTS.DEFAULT_EPISODE_LIMIT;
  isPodcastPage = true;
  itemsPerRow?: number;
  isListTile?: boolean;
  isContinueList?: boolean;
  isClipList?: boolean;

  constructor(collection: UniversalClipCollection, config: UniversalConfig[], options: string[]) {
    super(collection, config, options);

    if (isEmpty(collection)) {
      return undefined;
    }
    this.tileSizes = TILE_SIZES.SMALL;

    this.display =
      this.config?.PresentationType === DisplayLayout.LIST || this.display === DisplayLayout.LIST
        ? DisplayLayout.LIST
        : this.display;
    this.itemsPerRow =
      this.config?.PresentationType === DisplayLayout.LIST || this.display === DisplayLayout.LIST
        ? 1
        : this.itemsPerRow;
    this.isClipList = options ? Array.from(options).some((item) => item === 'clipList') : false;
    this.isListTile =
      this.isClipList && (this.config?.PresentationType === DisplayLayout.LIST || this.display === DisplayLayout.LIST);

    this.rows = this.config?.Rows ? parseInt(this.config.Rows) : this.rows;
    const clipCollection = <UniversalClipCollection>collection;
    this.items =
      clipCollection?.getClips?.clips &&
      clipCollection.getClips.clips.map((item: UniversalClip) => {
        const podcast = new Podcast(item.reference);
        return new PodcastEpisode(
          {
            ...item,
            podcastTitle: podcast.title,
            subTitle: item.title,
            config: clipCollection.config,
          },
          podcast,
          this.isClipList
        );
      });
    // Make this class read-only
    Object.freeze(this);
  }
}
