import gql from 'graphql-tag';
import {
  IMAGE_FRAGMENT,
  MEDIA_FRAGMENT,
  STATION_FRAGMENT,
  PODCAST_FRAGMENT,
  TAG_FRAGMENT,
  STATION_COLLECTION_FRAGMENT,
  SHOW_COLLECTION_FRAGMENT,
  PODCAST_COLLECTION_FRAGMENT,
  TAG_COLLECTION_FRAGMENT,
  PROMOTION_COLLECTION_FRAGMENT,
  CLIP_COLLECTION_FRAGMENT,
  CONFIG_FRAGMENT,
  NAVIGATION_COLLECTION_FRAGMENT,
  PODCAST_EPISODE_FRAGMENT,
  CLIP_FRAGMENT,
} from './fragments';

export const getOverview = gql`
  query GetOverview($slug: String!) {
    overview: collection(slug: $slug) {
      ... on OverviewCollection {
        id
        title
        slug
        description
        type
        images {
          ...ImageFragment
        }
        items {
          id
          description
          title
          type
          options
          images {
            ...ImageFragment
          }
          refs {
            id
            uri
            targetType
          }
          config {
            ...ConfigFragment
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${CONFIG_FRAGMENT}
`;

// TODO - After cleanup ticket and deleting deprecated files
// Adjust this query to use non-deprecated fields (into fragments)
export const getOverviewWithReverences = gql`
  query GetOverview($slug: String!) {
    overview: collection(slug: $slug) {
      ... on OverviewCollection {
        id
        title
        type
        description
        slug
        config {
          type
          entries {
            key
            value
            type
          }
        }
        items {
          title
          options
          config {
            type
            entries {
              key
              value
              type
            }
          }
          references {
            ... on OverviewCollection {
              slug
              type
              config {
                type
                entries {
                  key
                  value
                  type
                }
              }
            }
            ... on StationCollection {
              ...StationCollectionFragment
            }
            ... on ShowCollection {
              ...ShowCollectionFragment
            }
            ... on PodcastCollection {
              ...PodcastCollectionFragment
            }
            ... on TagCollection {
              ...TagCollectionFragment
            }
            ... on PromotionCollection {
              ...PromotionCollectionFragment
            }
            ... on ClipCollection {
              ...ClipCollectionFragment
            }
            ... on NavigationCollection {
              ...NavigationCollectionFragment
            }
          }
        }
      }
    }
  }
  ${STATION_COLLECTION_FRAGMENT}
  ${SHOW_COLLECTION_FRAGMENT}
  ${PODCAST_COLLECTION_FRAGMENT}
  ${TAG_COLLECTION_FRAGMENT}
  ${PROMOTION_COLLECTION_FRAGMENT}
  ${CLIP_COLLECTION_FRAGMENT}
  ${CLIP_FRAGMENT}
  ${NAVIGATION_COLLECTION_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${CONFIG_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${PODCAST_EPISODE_FRAGMENT}
`;

export const getMixedOverview = gql`
  query getMixedOverview($slug: String!) {
    mixed: collection(slug: $slug) {
      ... on OverviewCollection {
        id
        slug
        type
        title
        items {
          references {
            ... on Station {
              ...StationFragment
            }
            ... on Podcast {
              ...PodcastFragment
            }
            ... on Tag {
              ...TagFragment
            }
          }
        }
      }
    }
  }
  ${CONFIG_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${TAG_FRAGMENT}
  ${PODCAST_FRAGMENT}
  ${STATION_FRAGMENT}
  ${PODCAST_EPISODE_FRAGMENT}
`;

export const getMixedContents = (query) => gql`
  query {
    ${query}
  }
`;
