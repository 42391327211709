import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getImageWithResamplingQuery } from 'util/resampling';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import * as cssVariable from 'cssVariables';
import IsPlayingOverlay from 'components/IsPlayingOverlay';
import styles from './OnDemandTileThumbStyles';
import { isMobile } from 'util/device';
import tileStyles from 'tile-components-styles';
import ShowTileBrand from './ShowTileBrand';
import AirDate from './AirDate';
import PlayControl from 'components/PlayControl';
import IconChevron from 'components/svg/Chevron';
import LazyTileImage from 'components/LazyTileImage';

class OnDemandTileThumb extends PureComponent {
  static defaultProps = {
    id: null,
    show: null,
    title: '',
    isPlaying: false,
    onPlayPauseToggle: undefined,
    publishedUtc: null,
    disabled: null,
    isLoading: null,
    track: null,
    onDemandClip: null,
    onDemandTitle: null,
    station: null,
    tileTo: null,
  };

  static propTypes = {
    id: PropTypes.string,
    show: PropTypes.object,
    title: PropTypes.string,
    isPlaying: PropTypes.bool,
    onPlayPauseToggle: PropTypes.func,
    publishedUtc: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    track: PropTypes.object,
    onDemandClip: PropTypes.object,
    onDemandTitle: PropTypes.string,
    station: PropTypes.object,
    tileTo: PropTypes.object,
    classes: PropTypes.object.isRequired,
  };

  state = {
    isFocused: false,
  };

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  render() {
    const { disabled, classes, isLoading, isPlaying, onDemandClip, onDemandTitle, onPlayPauseToggle, station, tileTo } =
      this.props;

    const { id, show, publishedUtc, title, image, imageUrl, brandColor, track, logoWhite } = onDemandClip || {};

    if (disabled) {
      return <div className={classes.tile} />;
    }
    const isPodcast = !!(onDemandClip && onDemandClip.podcast);
    const usableImage = image && image.url ? image.url : imageUrl;

    return (
      <div
        className={classes.tile}
        data-testid={`on_demand_clip_${id}`}
        {...tileTo}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        style={{
          backgroundColor: brandColor && cssVariable.hexToRgba(brandColor, 0.6),
        }}
      >
        {!isPodcast && !disabled && (
          <div className={classNames(classes.trianglesDefault, classes.trianglesDefaultAbove)}>
            <div
              data-key="tile-image"
              className={classes.triangleSmallDefault}
              style={{ background: brandColor || cssVariable.black }}
            />
            <div className={classes.triangleBigDefault} style={{ background: brandColor || cssVariable.black }} />
          </div>
        )}
        {isPlaying && <IsPlayingOverlay isPlaying isPlayingWrapperClass={classes.isPlayingWrapperClass} />}
        {usableImage && (
          <LazyTileImage
            data-key="tile-image"
            className={classes.backdropRounded}
            imageUrl={getImageWithResamplingQuery({
              url: usableImage,
              isTransparent: !isPodcast,
            })}
          />
        )}
        {station && (
          <div className={classes.content}>
            <ShowTileBrand station={station} altStationLogo={show.altStationLogo} />
            <Fragment>
              <h3 className={classes.title}>{onDemandTitle}</h3>
              <p
                className={classNames(classes.scheduleDefault, {
                  [classes.scheduleMobile]: isMobile,
                })}
              >
                <AirDate date={publishedUtc} />
              </p>
            </Fragment>
          </div>
        )}
        {!station && !show && !track && (
          <div className={classes.content}>
            {/* This 2 divs are here for stylistic purpose, so don't change to Fragment or delete */}
            {logoWhite && <ShowTileBrand altStationLogo={logoWhite} />}
            <div />
            <div>
              <h3 className={classes.title}>{title}</h3>
              <p className={classes.scheduleDefault}>
                <AirDate date={publishedUtc} />
              </p>
            </div>
          </div>
        )}
        {!disabled && (
          <div
            className={classNames(classes.hoverOverlayDefault, {
              [classes.hoverOverlayFocusedDefault]: this.state.isFocused,
            })}
            onClick={isMobile ? onPlayPauseToggle : undefined}
            data-testid="show_overlay"
            role="presentation"
          >
            {show && show.hasPage && !isMobile && (
              <Fragment>
                <NavLink
                  className={classes.pageLinkIcon}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  to={`/radioshows/${show.slug}`}
                  aria-label={`Ga naar ${show.name}`}
                  title="Bekijk meer"
                >
                  <IconChevron />
                </NavLink>
                <NavLink
                  className={classes.pageLinkDefault}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  tabIndex="0"
                  to={`/radioshows/${show.slug}`}
                  aria-label={`Ga naar ${show.name}`}
                />
              </Fragment>
            )}
            <div className={classes.actionsDefault}>
              <span className={classes.buttonPlaceholderDefault} />
              <PlayControl
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                playToggle={onPlayPauseToggle}
                isPlaying={isPlaying && !isLoading}
                isLoading={isPlaying && isLoading}
                isOnDemand
                size={55}
              />
              {/* NOTE: The context menu is not shown for the OnDemandTile */}
              <span className={classes.buttonPlaceholderDefault} />
            </div>
            <div className={classes.hoverBackdropRounded} />
          </div>
        )}
      </div>
    );
  }
}

export default injectStyles(styles)(injectStyles(tileStyles)(OnDemandTileThumb));
