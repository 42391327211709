import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';

import OnDemandSkipIcon from 'components/svg/OnDemandSkip';

import styles from './SkipControl.styles';

class SkipControl extends PureComponent {
  static defaultProps = {
    skipTime: 15,
    isSkipForward: true,
    classes: {},
    color: null,
    size: null,
  };

  static propTypes = {
    skipTime: PropTypes.number,
    isSkipForward: PropTypes.bool,
    skipForward: PropTypes.func.isRequired,
    skipBackward: PropTypes.func.isRequired,
    classes: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.number,
  };

  render() {
    const { skipTime, isSkipForward, skipForward, skipBackward, classes } = this.props;
    const skipHandler = isSkipForward ? skipForward : skipBackward;

    return (
      <div className={classes.container}>
        <button
          className={classNames(classes.iconButton, {
            [classes.iconButtonIsSkipForward]: isSkipForward,
          })}
          onClick={() => {
            skipHandler(skipTime);
          }}
          aria-label={`skip ${skipTime} ${isSkipForward ? 'forward' : 'backward'}`}
          data-testid={`skipControl_${isSkipForward ? 'forward' : 'backward'}`}
          type="button"
        >
          <span className={classes.skipLabel}> {skipTime} </span>
          <OnDemandSkipIcon />
        </button>
      </div>
    );
  }
}

export default injectStyles(styles, { name: 'PBSkipControl' })(SkipControl);
