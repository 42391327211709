import React from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import { calculateProgress } from 'lib/onDemand';

import * as cssVariable from 'cssVariables';

const Progressbar = ({ progress, duration, classes }) => (
  <div className={classes.progressWrap}>
    <div className={classes.progressBar} style={{ width: `${calculateProgress(progress, duration)}%` }} />
  </div>
);

Progressbar.defaultProps = {
  progress: 0,
  duration: 0,
  isPlaying: false,
};

Progressbar.propTypes = {
  classes: PropTypes.object.isRequired,
  progress: PropTypes.number,
  duration: PropTypes.number,
  // Its used in styles for progressBar transition
  // eslint-disable-next-line react/no-unused-prop-types
  isPlaying: PropTypes.bool,
};

const styles = {
  progressWrap: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 4,
    backgroundColor: cssVariable.transparentLightmode005,
  },
  progressBar: {
    position: 'absolute',
    height: '100%',
    backgroundColor: cssVariable.green500,
    borderRadius: 1,
    transition: ({ isPlaying, progress }) => isPlaying && progress > 0 && 'width 1s linear',
  },
};

export default injectStyles(styles)(Progressbar);
