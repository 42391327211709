import gql from 'graphql-tag';
import { IMAGE_FRAGMENT, TAG_FRAGMENT, CONFIG_FRAGMENT } from './fragments';

export const getTag = gql`
  query GetTag($slug: String!) {
    tag(slug: $slug) {
      ... on Tag {
        ...TagFragment
      }
    }
  }
  ${TAG_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${CONFIG_FRAGMENT}
`;

/*
 * TODO - Double check in clean up ticket, this should probably be updated
 * getTags query fetch deprecated fields
 */
export const getTags = gql`
  query GetTags($slug: String!) {
    collection(slug: $slug) {
      ... on TagCollection {
        id
        description
        slug
        title
        options
        config {
          entries {
            key
            value
          }
        }
        images {
          ...ImageFragment
        }
        items {
          slug
          title
          type
          refs {
            type
            uri
            targetType
          }
          images {
            ...ImageFragment
          }
          config {
            entries {
              key
              value
            }
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;
