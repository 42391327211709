export const TAG_FRAGMENT = `
  fragment TagFragment on Tag {
    id
    description
    slug
    title
    type
    metadata {
      id
      type
      contentType
      title
      description
      shareLink
      shareTitle
      shareText
    }
    images {
      ...ImageFragment
    }
    refs {
      id
      uri
      targetType
    }
    labels
    config {
      ...ConfigFragment
    }
  }
  `;
