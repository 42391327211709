import { queryRequest } from './client';
import { getStations, getStation, getPlayouts, getStationsTrack, getOverview, getStationShareImage } from './queries';
import { StationCollection, Station, Overview } from 'api/models';

import logger from 'util/logger';

/**
 * Fetch a list of station collections from the universal api
 *
 * @param {object} param
 * @param {string} param.slug
 * @param {object} param.sectionConfig
 * @param {object} param.collectionProps
 *
 * @returns {Object} StationCollection
 */
export const fetchStationCollection = async ({ slug, config, options }) => {
  const result = await queryRequest({
    query: getStations,
    variables: { slug },
  });
  if (!result || !result.data || !result.data.collection) {
    return null;
  }
  return new StationCollection(result.data.collection, config, options || result.data.collection.options);
};

/**
 * Fetch a single station from the universal api
 * @param {object} config
 * @param {string} config.slug
 * @returns {Station} Station
 */
export const fetchStation = async ({ slug }) => {
  const { data } = (await queryRequest({ query: getStation, variables: { slug } })) || {};
  return new Station(data.station);
};

const sonosProfile = process.env.REACT_APP_GRAPH_QL_PROFILE_SONOS;
export const fetchShareImageFromSonos = async ({ slug }) => {
  try {
    const { data } =
      (await queryRequest({ query: getStationShareImage, variables: { slug }, profile: sonosProfile })) || {};
    const shareImage = data.station.images.filter((image) => image.imageType === 'image');
    return shareImage[0].uri;
  } catch (error) {
    logger.error(`${error} while fetchShareImageFromSonos with graphQL for ${slug}`);
    return null;
  }
};
/**
 * Fetch a list of playouts in a station from the universal api
 * @param {string} slug
 * @param {int} limit
 * @returns {Object} Playouts
 */
export const fetchPlayouts = async ({ slug, limit = 10 }) => {
  const { data } =
    (await queryRequest({
      query: getPlayouts,
      variables: { slug, limit },
    })) || {};
  return {
    playouts: data.station && data.station.getPlayouts.playouts.map(({ track }) => track),
  };
};

/**
 * Fetch a playout in a station from the universal api
 * @param {string} slug
 * @param {int} limit
 * @returns {Object} Playouts
 */
export const fetchUnderlineTrack = async ({ slug }) => {
  const limit = 1;
  const { data } =
    (await queryRequest({
      query: getStationsTrack,
      variables: { slug, limit },
    })) || {};
  return data.collection.items.reduce((acc, { slug: stationSlug, getPlayouts }) => {
    acc[stationSlug] =
      getPlayouts.playouts &&
      getPlayouts.playouts[0] &&
      `${getPlayouts.playouts[0].track?.artistName} - ${getPlayouts.playouts[0].track?.title}`;
    return acc;
  }, {});
};

/**
 * Fetch all details for the station page from the universal api
 *
 * @param {object} params
 * @param {string} params.slug
 * @param {string} params.subSlug
 *
 * @returns {Object} including fields station {Station}, overview {Overview}, stationCollection {StationCollection}
 */
export const fetchStationPage = async ({ slug, subSlug = '' }) => {
  let overview;
  let station;
  // check for invalid url, brand pages do not include the station slug
  if (slug === subSlug) return {};

  try {
    const { data } =
      (await queryRequest({
        query: getOverview,
        variables: { slug },
      })) || {};

    overview = new Overview(data.overview);
  } catch (error) {
    logger.error(`${error} while fetchData with graphQL for ${slug}`);
  }

  /**
   * if no OverviewCollection can be found we need
   * to check whether or not the slug matches a stationCollection
   */
  if (!overview) {
    const { data } =
      (await queryRequest({
        query: getStations,
        variables: { slug },
      })) || {};
    const stationCollection = new StationCollection(data.collection);
    if (stationCollection && stationCollection.items && stationCollection.items[0]) {
      return {
        stationCollection,
        station: stationCollection.items[0],
      };
    }
  }

  /**
   * fetch Station to be rendered in PageHeader
   */
  if (subSlug) {
    try {
      const { data: stationData } =
        (await queryRequest({
          query: getStation,
          variables: { slug: subSlug },
        })) || {};
      station = new Station(stationData.station);
    } catch (error) {
      logger.error(`${error} while fetchData with graphQL for ${subSlug}`);
      return {};
    }
  } else {
    try {
      const { data: stationData } =
        (await queryRequest({
          query: getStation,
          variables: { slug },
        })) || {};
      station = new Station(stationData.station);
    } catch (error) {
      logger.error(`${error} while fetchData with graphQL for ${slug}`);
    }
  }

  /**
   * if no station could be fetched with the slug we
   * check the Overview and use the first station item
   * to be rendered in the station header
   */
  if (!station) {
    try {
      const section =
        overview &&
        overview.sections &&
        overview.sections.find(({ contentTypeId }) => contentTypeId === 'stationCollection');
      if (section) {
        const { items: stations } = (await fetchStationCollection({ slug: section.slug })) || {};
        [station] = stations || [];
      }
    } catch (error) {
      logger.error('Failed to load station from overview');
    }
  }

  return {
    station,
    overview,
  };
};

/**
 * Fetches random stations data from Universal API
 * @returns {Object} including station and cta
 */
export async function fetchRandomStation() {
  try {
    const collection = await fetchStationCollection({
      slug: 'speel-willekeurige-zender',
    });

    if (!collection.items || !collection.items.length) {
      logger.warn('No defined random stations in Universal API.');
      return null;
    }

    const randomStationIndex = Math.floor(Math.random() * collection.items.length);
    const randomStation = collection.items[randomStationIndex];

    return {
      station: randomStation,
      cta: collection.title,
    };
  } catch (error) {
    logger.error('Error requesting random stations from Universal API: ', error);
    return null;
  }
}
