import { isPopoutPlayer } from 'globalConst/const';
import { getSlugfromUrl } from './util';
import { isDesktopApp, getDesktopOs, isHuawei } from '../util/device';

const DAYS_OF_THE_WEEK = ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'];

const appPlatform = () => {
  if (isDesktopApp()) {
    return `${getDesktopOs()}_desktop_app`;
  }
  if (isHuawei) {
    return 'huawei_quick_app';
  }
  return 'web';
};

export const taqPersistentData = {
  app_name: 'Juke Website',
  app_brandname: 'Juke',
  app_broadcastername: 'Talpa Radio',
  // app_channelname: 'Juke', // Removed from DL
  app_companyname: 'Talpa Radio',
  app_formatcountry: 'nl',
  app_platform: appPlatform(),
  app_version: process.env.REACT_APP_VERSION,
  // call_viewtype: 'pageview', // TAQ does this
  player_source: 'Juke Free',
  player_type: 'html',
  player_casting: false,
  player_id: 'juke_free',
  player_autoplay: false,
  player_fullscreen: false,
  player_embedurl: null,
  page_id: getSlugfromUrl().length ? getSlugfromUrl() : 'home',
  page_category: null,
  nb_01: 'talparadio',
  nb_02: 'talparadio',
  player_name: isPopoutPlayer ? 'popout-player' : 'player', // Not in DL
};

export const taqPersistentCustomData = {
  player_setting: 'audio', // Not in DL

  c_media_music_type: null,
  c_media_music_trackname: null,
  c_profile_spotify_membershiptype: null,

  player_weekday: DAYS_OF_THE_WEEK[new Date().getDay()], // Not in DL
  player_program: null, // Not in DL

  'player_add-type': null, // Not in DL

  'c_player_tune-in-item': null,
  'c_player_tune-out-item': null,
};

export const mediaAudiotype = {
  radioLive: 'radio-live',
  radioOnDemand: 'radio-on-demand',
  podcast: 'podcast',
};

export const navigationEvents = {
  closeMenu: 'close_menu',
  openMenu: 'open_menu',
  goToHome: 'go_to_home',
  mainNav: 'main nav',
};
