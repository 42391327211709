import { useEffect } from 'react';
import graphql from 'api/graphql';

const client = {
  schedule: null,
  station: null,
};

const subscriptionState = {
  closed: 'closed',
};

export default function useNowPlaying({ slug, isDisabled, onPlayouts, onShow, onError }) {
  useEffect(() => {
    if (!slug || isDisabled) {
      return;
    }

    graphql.subscribeToScheduleEvents(slug, onShow, onError).then((result) => {
      client.schedule = result;
    });

    graphql.subscribeToPlayoutEvents(slug, onPlayouts, onError).then((result) => {
      client.station = result;
    });

    return () => {
      Object.values(client).forEach((subscription) => {
        if (subscription?._state && subscription._state !== subscriptionState.closed) {
          subscription.unsubscribe();
        }
      });
    };
  }, [slug, isDisabled]);
}
