import React from 'react';
import svgIcon from 'components/hoc/svgIcon';

const styles = {
  fill: 'none',
  stroke: 'currentColor',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: 2,
};
const svg = () => (
  <svg viewBox="0 0 40 40" height="40" width="40">
    <line {...styles} x1="12" y1="20" x2="28" y2="20" />
  </svg>
);

export default svgIcon(svg);
