import { breakpoints, mediaQuery } from 'components/theme/breakpoints';

export default {
  container: {
    position: 'relative',
    height: '90px',
    minWidth: '250px',
  },

  content: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },

  popout: {
    position: 'absolute',
    minHeight: 1,
    minWidth: 1,
    bottom: 'calc(100% + 11px)',
    right: '78px',
    borderRadius: '4px',
  },

  containerRelative: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
  },

  popoutItem: {
    marginLeft: 10,
  },

  [mediaQuery.minWidth(breakpoints.sm)]: {
    container: {
      minWidth: 'auto',
    },
  },
};
