import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import EqualizerIcon from 'components/svg/Equalizer';
import { getImageWithResamplingQuery } from 'util/resampling';
import { createUseStyles } from 'react-jss';
import styles from './Track.styles';
import { isEmpty } from 'util/index';

const fallbackUrl = `${__URL_PREFIX__}/album-cover-fallback.png`;

const useStyles = createUseStyles(styles, { name: 'Track' });
const Track = ({ isNowPlaying, track }) => {
  const classes = useStyles();

  if (isEmpty(track)) {
    return null;
  }

  return (
    <div className={classes.container} data-testid={`playouts-track${isNowPlaying ? '-now' : ''}`} role="presentation">
      <div
        className={classNames(classes.albumCover, {
          [classes.nowPlayingTrack]: isNowPlaying,
        })}
      >
        {isNowPlaying && <EqualizerIcon className={classes.iconOverlay} />}
        <img
          src={getImageWithResamplingQuery({
            url: track.images && track.images[0] ? track.images[0].uri : fallbackUrl,
            isSquare: true,
            isThumbnail: true,
            omitQuality: true,
            omitType: true,
          })}
          alt="Album cover"
        />
      </div>

      <div className={classes.info} data-testid="track-title-artist">
        {track.title && <p className={classes.title}>{track.title.toLowerCase()}</p>}
        {track.artistName && <p className={classes.artist}>{track.artistName.toLowerCase()}</p>}
      </div>
    </div>
  );
};

Track.propTypes = {
  isNowPlaying: PropTypes.bool,
  track: PropTypes.object.isRequired,
};

Track.defaultProps = {
  isNowPlaying: false,
};

export default Track;
