import { UniversalClip, UniversalMetadata } from './../interfaces/universalApi.d';
import { getDaysAgo } from 'lib/onDemand';
import PodcastEpisode from './PodcastEpisode';
import { Image, UniversalCollectionItem, UniversalPodcast } from '../interfaces';
import { Genre } from '../interfaces/Genre';
import CollectionItem from './CollectionItem';

export interface RelatedPodcast {
  id: string;
  type: string;
  targetType: string;
  slug: string;
}

/**
 * @class
 * A representation of a Podcast
 *
 * @param {object} podcast - podcast model
 */
export default class Podcast extends CollectionItem {
  labels: string[];
  subTitle: string;
  author: string;
  image: Image;
  mainPodcastImage: Image;
  relatedPodcast: RelatedPodcast[];
  genres: Genre[];
  episodes: PodcastEpisode[];
  pageLink?: string;
  feedUrl?: string;
  publisher?: string;
  language?: string;
  metadata?: UniversalMetadata;

  constructor(item: UniversalCollectionItem) {
    super(item);
    if (!item) {
      return undefined;
    }

    const podcast = <UniversalPodcast>item;

    this.metadata = podcast?.metadata;
    this.author = podcast.subTitle;
    this.labels = podcast.labels;
    this.mainPodcastImage = {
      url: podcast.images && podcast.images[0] && podcast.images[0].uri,
    };

    this.image = this.mainPodcastImage;
    this.relatedPodcast =
      !!podcast.refs &&
      podcast.refs.map((relatedPodcast) => ({
        id: relatedPodcast.id,
        type: relatedPodcast.type,
        targetType: relatedPodcast.targetType,
        slug: relatedPodcast.uri,
      }));
    this.genres =
      podcast.tags && Array.isArray(podcast.tags)
        ? podcast.tags.reduce((genres, item) => {
            if (item.type === 'genre') {
              genres.push(item.title);
            }
            return genres;
          }, [])
        : [];

    this.feedUrl = podcast?.feedUrl;
    this.publisher = podcast?.publisher;
    this.language = podcast?.language;

    this.episodes =
      podcast.episodes &&
      podcast.episodes.map((episode, index) => {
        let podcastEpisode = episode;
        // @Note: If first episode is published with-in 30 days, it should have the 'NIEUW' label
        if (index === 0 && getDaysAgo(episode.publishDate) < 30) {
          podcastEpisode = { ...podcastEpisode, showNewLabel: true };
        }
        return new PodcastEpisode(podcastEpisode, this);
      });

    // This is added to support both way of podcast attributes deprecated (episodes) and non-deprecated (getClips.clips)
    if (!podcast.episodes && podcast.getClips) {
      this.episodes = podcast.getClips.clips.map((item: UniversalClip) => {
        const podcast = new Podcast(item.reference);
        return new PodcastEpisode(
          {
            ...item,
            podcastTitle: podcast.title,
            subTitle: item.title,
          },
          podcast
        );
      });
    }

    this.pageLink = `/podcasts/${this.slug}`;
    // Make this class read-only
    Object.freeze(this);
  }
}
