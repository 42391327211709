import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setCurrentPageSlug, setTileAmount } from 'actions/content-actions';
import { getModalOpen, getModalStandaloneOpen } from 'store/reducers/modal';
import { getSearchIsActive } from 'store/reducers/search';
import { getForceMiniPlayer } from 'store/reducers/player';
import { getHasMobileHeader, getMobileLightNavigation } from 'store/reducers/navigation';
import { getIsInMobileApp } from 'store/reducers/urlParams';

import Content from './Content';

const mapStateToProps = (state) => ({
  modalIsOpen: getModalOpen(state),
  modalStandaloneIsOpen: getModalStandaloneOpen(state),
  forceMiniPlayer: getForceMiniPlayer(state), // Needs to be included to update content width in store
  hasMobileHeader: getHasMobileHeader(state),
  searchIsActive: getSearchIsActive(state),
  isInMobileApp: getIsInMobileApp(state),
  mobileLightNavigationItems: getMobileLightNavigation(state),
});

const mapDispatchToProps = {
  setCurrentPageSlug,
  setTileAmount,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
