import { v4 as uuid } from 'uuid';

export const DEFAULT_NUM_OF_TILES = 20;

/**
 * Creates placeholders which can be rendered before content is available
 * @param {number} amount
 * @returns {array} empty placeholders
 */
const generatePlaceholders = (amount, additonalProps) =>
  Array.from({ length: amount || DEFAULT_NUM_OF_TILES }, () => ({
    id: uuid(),
    disabled: true,
    tile: {},
    logo: {},
    ...additonalProps,
  }));

export default generatePlaceholders;
