import React, { useState, useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { isContentWider } from 'util/device';
import styles from './Marquee.styles';
import { isEmpty } from '../../util';
interface Marquee {
  text?: string;
  icon?: JSX.Element;
}

const useStyles = createUseStyles(styles, { name: 'Marquee' });

const Marquee = (props: Marquee): JSX.Element => {
  const { text, icon } = props;
  const classes = useStyles();
  const marqueeContainerRef = useRef(null);
  const marqueeContentRef = useRef(null);

  const [marginLeft, setMarginLeft] = useState(0);
  const [transition, setTransition] = useState('none');
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (!text) return;
    setShouldScroll(isContentWider(marqueeContainerRef.current, marqueeContentRef.current));
  }, [text]);

  const onMouseEnterHandler = () => {
    if (!shouldScroll) {
      return;
    }

    const marginLeft = marqueeContainerRef.current.offsetWidth - marqueeContentRef.current.offsetWidth - 10;
    // calculate transition time based on margin value to have consistent "scrolling speed" for different now playings
    const transitionTime = -marginLeft * 15;
    setMarginLeft(marginLeft);
    setTransition(`margin-left ${transitionTime}ms linear`);
  };

  const onMouseLeaveHandler = () => {
    if (!shouldScroll) {
      return;
    }
    setMarginLeft(0);
    setTransition('none');
  };

  if (isEmpty(text)) {
    return <div className={classes.bottomInfo} />;
  }

  return (
    <div
      data-testid="marquee"
      ref={marqueeContainerRef}
      className={classes.bottomInfo}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      // onClick={onMouseEnterHandler}
    >
      <span data-testid="marquee-text" className={classes.nowPlaying} style={{ marginLeft, transition }}>
        {icon} {text.toLowerCase()}
      </span>
      <div ref={marqueeContentRef} className={classNames(classes.nowPlaying, classes.nowPlayingHidden)}>
        {icon} {text.toLowerCase()}
      </div>
    </div>
  );
};

export default Marquee;
