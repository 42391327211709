import React from 'react';
import PropTypes from 'prop-types';
import { useFetchData } from 'components/hooks';
import { omit, isEmpty } from 'util/index';
import { connect } from 'react-redux';

export default (WrappedComponent) => {
  function WithGraphqlDataHOC(props) {
    const { contentTypeId, slug, subSlug, onError, options, config, store, query, refetchDeps } = props;

    let componentProps = omit(props, isEmpty);

    const { data, isLoading, error } = useFetchData({
      contentTypeId,
      slug,
      subSlug,
      query,

      onError,
      options,
      config,
      store,
      refetchDeps,
    });

    if (!slug || !contentTypeId) {
      return <WrappedComponent {...componentProps} />;
    }

    componentProps = { ...componentProps, ...omit(data, isEmpty) };

    return <WrappedComponent isComponentLoading={isLoading} error={error} {...componentProps} />;
  }

  WithGraphqlDataHOC.propTypes = {
    contentTypeId: PropTypes.string,
    slug: PropTypes.string,
    subSlug: PropTypes.string,
    query: PropTypes.string,
    store: PropTypes.bool,
    onError: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    config: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    refetchDeps: PropTypes.array,
  };

  WithGraphqlDataHOC.defaultProps = {
    contentTypeId: null,
    slug: null,
    subSlug: null,
    query: null,
    store: false,
    onError: null,
    options: null,
    config: null,
    refetchDeps: undefined,
  };

  const mapStateToProps = () => {
    return {};
  };

  return connect(mapStateToProps)(WithGraphqlDataHOC);
};
