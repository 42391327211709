import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import ButtonTypes from 'components/Button/Button.const';
import { getImageWithResamplingQuery } from 'util/resampling';
import Chevron from 'components/svg/Chevron';
import styles from './HeroImage.styles';
import { createUseStyles } from 'react-jss';
import NavigationLink from 'components/NavigationLink/NavigationLink';
import classNames from 'classnames';
import { isLandscapeMode, isMobile, isTablet } from '../../util';
import { useFetchData } from 'components/hooks';
import { CONTENT_TYPES } from 'globalConst/contentTypes-const';
import * as analytics from 'analytics';

export interface PromotionItems {
  items: { title: string; pageUrl: string }[];
}

export interface HeroImageProps {
  image: { url: string; altText: string };
  title: string;
  reference: { uri: string };
}

const useStyles = createUseStyles(styles, { name: 'HeroImage' });

const HeroImage = ({ image, title, reference }: HeroImageProps): JSX.Element => {
  const classes = useStyles();
  const customWidth = 1290;

  const tablet = isTablet();
  const mobile = isMobile;
  const isLandscape = isLandscapeMode();

  const { data } = useFetchData({
    slug: reference.uri,
    contentTypeId: CONTENT_TYPES.PROMOTION_COLLECTION,
  });

  const [primaryButton, setPrimaryButton] = useState({ title: '', url: '' });
  const [secondaryButton, setSecondaryButton] = useState({ title: '', url: '' });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data) {
      const { items = [] } = data as PromotionItems;

      setPrimaryButton({ title: items[0].title || '', url: new URL(items[0].pageUrl).pathname || '/home' });
      setSecondaryButton({ title: items[1].title || '', url: new URL(items[1].pageUrl).pathname || '/home' });
      setIsLoading(false);
    }
  }, [data]);

  const onTrackData = (eventLabel: string, url: string) => {
    analytics.trackEvent(analytics.generateCTAClickData(`hero - web - ${eventLabel}`, url));
  };

  if (isLoading) return <></>;

  return (
    <>
      <div className={classes.container}>
        <img
          className={classNames(classes.promotionImage, {
            [classes.promotionImageMobile]: mobile,
            [classes.promotionImageTablet]: tablet,
            [classes.promotionImageOpacity]: mobile || tablet,
            [classes.promotionImageLandscape]: isLandscape,
          })}
          data-key="promotion-image"
          data-testid="hero-image"
          alt={image.altText}
          src={getImageWithResamplingQuery({
            url: image.url,
            customWidth,
          })}
        />
        <div className={classNames(classes.content, { [classes.contentMobile]: mobile })}>
          <div
            className={classNames(classes.contentText, {
              [classes.contentTextMobile]: mobile,
              [classes.contentTextTablet]: tablet,
            })}
          >
            {title}
          </div>
          <NavigationLink pageUrl={primaryButton.url} title={primaryButton.title} target="_self">
            <Button
              data-testid={`promotion${primaryButton.title}-button`}
              type={ButtonTypes.PRIMARY}
              text={primaryButton.title}
              icon={<Chevron />}
              className={classNames(classes.contentButton, {
                [classes.contentButtonMobile]: mobile,
                [classes.contentButtonTablet]: tablet,
              })}
              onClick={() => onTrackData(primaryButton.title.toLowerCase(), primaryButton.url)}
            />
          </NavigationLink>
          <NavigationLink pageUrl={secondaryButton.url} title={secondaryButton.title} target="_self">
            <Button
              data-testid={`promotion${secondaryButton.title}-button`}
              type={ButtonTypes.SECONDARY}
              text={secondaryButton.title}
              icon={<Chevron />}
              className={classNames(classes.contentButton, classes.contentButtonWhite, {
                [classes.contentButtonMobile]: mobile,
                [classes.contentButtonTablet]: tablet,
              })}
              onClick={() => onTrackData(secondaryButton.title.toLowerCase(), secondaryButton.url)}
            />
          </NavigationLink>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default HeroImage;
