import React, { useContext } from 'react';
import { getImageWithResamplingQuery } from 'util/index';
import { createUseStyles } from 'react-jss';
import styles from './StationTile.styles';
import HoverOverlay from 'components/Tiles/StationTile/HoverOverlay';
import AudioIcon from 'components/svg/Audio';
import Marquee from 'components/Marquee';
import CollectionContext from 'components/Collection/CollectionContext';
import { isMobile, isTouch } from 'util/device';
import { IStationTile } from './StationTile.interface';
import classNames from 'classnames';

const useStyles = createUseStyles(styles, { name: 'StationTile' });

const StationTile = (props: IStationTile): JSX.Element => {
  const { station, browser, onPlayToggle, saveRecentSearch, customTracking } = props;

  const { showNowPlayingInfo = true, isInSearch = false } = useContext(CollectionContext);
  const classes = useStyles();
  const PLAY_BUTTON_SIZE = browser.down.sm ? 45 : 55;

  const marqueeProps = {
    text: station.playouts,
    icon: <AudioIcon />,
  };

  const clickHandler = () => {
    if (isTouch) {
      onPlayToggle();
      if (customTracking) {
        customTracking();
      }

      if (isInSearch) {
        saveRecentSearch(station.contentTypeId, station.slug);
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <div
        data-key="tile-image"
        data-testid={`station-tile-${station.slug}`}
        style={{
          backgroundColor: station.brandColor,
          backgroundImage: `url(${getImageWithResamplingQuery({ url: station.image?.url })})`,
        }}
        className={classNames(classes.tile, {
          [classes.nonTouchTile]: !isTouch,
        })}
        onClick={isTouch ? clickHandler : undefined}
      >
        <div className={classes.content}>
          {station.audioUrl && (
            <h3
              className={classNames(classes.title, {
                [classes.touch]: isTouch,
              })}
            >
              {station.shortTitle || station.title || 'Radio'}
            </h3>
          )}
        </div>
        {!isTouch && !isMobile && (
          <HoverOverlay
            station={station}
            onPlayToggle={onPlayToggle}
            isPlaying={props.isPlaying}
            isLoading={props.isLoading}
            playButtonSize={PLAY_BUTTON_SIZE}
            customTracking={customTracking}
          />
        )}
      </div>
      {!isTouch && showNowPlayingInfo && <Marquee {...marqueeProps} />}
    </div>
  );
};

export default StationTile;
