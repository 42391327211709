import React from 'react';
import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg width="147" height="117" viewBox="0 0 147 117">
    <g fill="none" fillRule="evenodd">
      <path
        className="cls-1"
        fill="#e8e8f2"
        d="M144.437 37.027A114.765 114.765 0 0 0 88.153 13.15v9.982a104.647 104.647 0 0 1 25.074 6.924 104.058 104.058 0 0 1 24.883 14.656c2.006 1.594 4.903 1.458 6.726-.333l.004-.004a4.891 4.891 0 0 0-.403-7.348M1.915 36.225a4.89 4.89 0 0 0-.443 7.377l.004.004c1.808 1.777 4.679 1.927 6.685.366A104.06 104.06 0 0 1 32.1 30.056a104.517 104.517 0 0 1 26.225-7.085V13c-21.14 2.598-40.486 10.873-56.41 23.225M18.014 57.965c-2.451 1.837-2.703 5.46-.546 7.643l.004.003c1.764 1.785 4.545 1.965 6.554.462a80.257 80.257 0 0 1 16.638-9.6 79.492 79.492 0 0 1 17.331-5.193V41c-14.81 2.352-28.439 8.311-39.981 16.965m109.583.804c-11.468-8.86-25.07-15.03-39.892-17.581v10.307a79.507 79.507 0 0 1 16.184 4.978 80.643 80.643 0 0 1 17.644 10.363c1.983 1.533 4.79 1.327 6.558-.462a5.076 5.076 0 0 0 1.467-3.585 5.061 5.061 0 0 0-1.96-4.02M33.229 78.408c-2.64 1.824-2.99 5.729-.753 8.062l.003.004c1.69 1.763 4.336 2.046 6.324.67 5.592-3.874 11.744-6.698 18.238-8.374V68c-8.642 1.902-16.69 5.484-23.812 10.408m77.422.793c-7.08-5.106-15.122-8.867-23.783-10.932V79.08c6.45 1.812 12.546 4.76 18.067 8.75 1.991 1.44 4.687 1.175 6.398-.61l.004-.004c2.215-2.31 1.89-6.157-.686-8.016"
      />
      <path
        className="cls-2"
        fill="#8D929E"
        d="M78.653 7.242v74.652c0 3.986-3.295 7.243-7.327 7.243-4.031 0-7.326-3.257-7.326-7.243V7.242c0-1.99.822-3.803 2.153-5.114A7.334 7.334 0 0 1 71.326 0c4.032 0 7.327 3.257 7.327 7.242M73.158 116.316c-5.05 0-9.158-4.108-9.158-9.158S68.108 98 73.158 98s9.158 4.108 9.158 9.158-4.108 9.158-9.158 9.158"
      />
    </g>
  </svg>
);

export default svgIcon(svg);
