export const PODCAST_EPISODE_FRAGMENT = `
 fragment PodcastEpisodeFragment on Clip {
    id
    title
    description
    media {
      ...MediaFragment
    }
    durationSeconds
    broadcastDate
    publishDate
    reference {
      ... on Podcast {
        id
        feedUrl
      }
    }
    slug
 }`;
