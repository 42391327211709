import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import { isEmpty } from 'util/index';
import { NOW_PLAYING } from 'globalConst/const';

import Track from '../Track';
import styles from './NowPlayingTrack.styles';

const useStyles = createUseStyles(styles, { name: 'NowPlayingTrack' });

const NowPlayingTrack = ({ lastPlayout }) => {
  const classes = useStyles();

  if (isEmpty(lastPlayout)) {
    return null;
  }

  return (
    <div data-testid="playouts-now-playing-track" className={classes.container}>
      <p data-testid="playouts-now-playing-track-title" className={classes.title}>
        {NOW_PLAYING}
      </p>

      <div className={classes.trackHolder}>
        <Track track={lastPlayout} isNowPlaying />
      </div>
    </div>
  );
};

NowPlayingTrack.propTypes = {
  lastPlayout: PropTypes.object,
  lightVariant: PropTypes.bool,
};

NowPlayingTrack.defaultProps = {
  lastPlayout: null,
  lightVariant: false,
};

export default NowPlayingTrack;
