import React from 'react';
import PropTypes from 'prop-types';

import svgIcon from 'components/hoc/svgIcon';

const svg = (props) => (
  <svg viewBox="0 0 57 57">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(1.00000, 1.00000)">
      {props.hasStroke ? (
        <path
          d="M5.50877621,44.0143556 C10.5265693,50.6856467 18.5094491,55 27.5,55 C42.6878306,55 55,42.6878306 55,27.5 C55,12.3121694 42.6878306,0 27.5,0 C18.9573039,0 11.3243874,3.89522905 6.28047552,10.0064619"
          stroke="#FFFFFF"
          strokeLinecap="round"
        />
      ) : (
        ''
      )}
      {props.hasStroke ? <polyline stroke="#FFFFFF" strokeLinecap="round" points="6 2 6 10 14 10" /> : ''}
      <path
        d="M35.67952,29.6809223 L24.0705274,37.0373593 C23.1375141,37.6285953 21.9018659,37.35153 21.31063,36.4185167 C21.1077279,36.0983225 21,35.7270586 21,35.3479893 L21,20.6351152 C21,19.5305457 21.8954305,18.6351152 23,18.6351152 C23.3790693,18.6351152 23.7503332,18.7428431 24.0705274,18.9457452 L35.67952,26.3021822 C36.6125333,26.8934181 36.8895986,28.1290663 36.2983627,29.0620796 C36.1402979,29.3115174 35.9289578,29.5228575 35.67952,29.6809223 Z"
        fill="#FFFFFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

svg.propTypes = {
  hasStroke: PropTypes.bool,
};

svg.defaultProps = {
  hasStroke: false,
};

export default svgIcon(svg);
