import { connect } from 'react-redux';
import { State } from 'redux';

import PodcastEpisodePageHeader from './PodcastEpisodePageHeader';
import { stop, loadOnDemandClip } from 'actions/player-actions';
import { getCurrentOnDemandClip } from 'store/reducers/player';

import { getIsPlaying } from 'store/reducers/player';

const mapStateToProps = (state: State) => ({
  isPlaying: getIsPlaying(state),
  currentClip: getCurrentOnDemandClip(state),
});
const mapDispatchToProps = {
  onPlay: loadOnDemandClip,
  onStop: stop,
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastEpisodePageHeader);
