export const aspectRatios = {
  SQUARE: '1:1',
  FOUR_THREE: '4:3',
  PORTRAIT: '2:3',
  LANDSCAPE: '16:9',
};

export const STREAMING_APIS = [
  { url: 'facebook.com', ...VENDORS.FACEBOOK },
  { url: 'instagram.com', ...VENDORS.INSTAGRAM },
  { url: 'spotify.com', ...VENDORS.SPOTIFY },
  { url: 'twitter.com', ...VENDORS.TWITTER },
  { url: 'youtu.be', ...VENDORS.YOUTUBE },
  { url: 'youtube.com', ...VENDORS.YOUTUBE },
];
