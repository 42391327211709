import * as cssVariable from 'cssVariables';

const thumbDimensions = {
  DESKTOP: 252,
  DESKTOP_MEDIUM: 197,
  MOBILE: 106,
};

const styles = (theme) => ({
  headerContent: {
    padding: `0 40px ${cssVariable.gutter}px 40px`,
  },
  flex: {
    display: 'flex',
  },
  podcastHeaderInfo: {
    flex: 1,
  },
  hideDownSm: {
    display: 'block',
  },
  showDownSm: {
    display: 'none',
  },
  wrapper: {
    margin: [40, 0, 20],
  },
  podcastTitle: {
    ...cssVariable.pageTitle,
    marginBottom: 40,
  },
  buttonWrapper: {
    '& svg': {
      width: 25,
      height: 25,
    },
  },
  playPauseButton: {
    marginRight: 23,
    height: 50,
    minWidth: 180,
  },
  buttonIcon: {
    '& > svg': {
      width: 40,
      height: 40,
    },
  },
  playLatestEpisodeMobile: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  shareholder: {
    width: 50,
    alignSelf: 'center',
    '& button': {
      height: 50,
    },
  },
  image: {
    flex: `0 0 ${thumbDimensions.DESKTOP}px`,
    height: thumbDimensions.DESKTOP,
    marginRight: 40,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: cssVariable.grey100,
    borderRadius: 5.26,
  },
  podcastHeaderTitle: {
    color: cssVariable.grey600,
    fontWeight: cssVariable.fontWeights.bold,
    marginBottom: 2,
  },
  podcastHeaderSubtitle: {
    ...cssVariable.headerText,
    color: cssVariable.grey500,
    fontSize: cssVariable.fontSizes.medium,
    fontWeight: cssVariable.fontWeights.bold,
    margin: [30, 0, 10, 0],
  },
  podcastHeaderSubtitleNoMargin: {
    marginTop: 0,
  },

  toggleBtn: {
    width: '70%',
    height: 40,
    color: cssVariable.green500,
    background: cssVariable.white,
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    textAlign: 'left',
  },

  socialShareDetailPage: {
    display: 'inline',
  },

  podcastHeaderDescription: {
    ...cssVariable.headerText,
    ...cssVariable.headerDescription,
    '& p': {
      marginBlockStart: 0,
    },
    '& a': {
      fontWeight: cssVariable.fontWeights.bold,
    },
  },
  descriptionHolder: {
    position: 'relative',
    fontSize: cssVariable.fontSizes.medium,
    color: cssVariable.grey600,

    '& $overflowEllipsis': {
      '@supports (-webkit-line-clamp: 3)': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        [cssVariable.WEBKIT_LINE_CLAMP]: 3,
        [cssVariable.WEBKIT_BOX_ORIENT]: 'vertical',
      },
      // IE fix
      height: '3.99em',
      overflow: 'hidden',
      position: 'relative',
      '&:after': {
        content: '...',
        textAlign: 'right',
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '70%',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%)',
      },
    },
  },
  buttonHolder: {
    borderRadius: 4,
    overflow: 'hidden',
    '& button': {
      padding: [0, 10],
      cursor: 'pointer',
    },
    // IE fix
    '& svg': {
      marginRight: 10,
    },
  },
  [theme.breakpoints.down('ml')]: {
    image: {
      marginRight: 20,
      flex: `0 0 ${thumbDimensions.DESKTOP_MEDIUM}px`,
      height: thumbDimensions.DESKTOP_MEDIUM,
    },
    socialShareDetailPage: {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sl')]: {
    actionsHolder: {
      flexDirection: 'row',
    },
  },
  [theme.breakpoints.down('sm')]: {
    wrapper: {
      margin: '40px 20px',
      marginLeft: '0',
    },
    buttonIcon: {
      padding: 0,
    },
    shareholder: {
      alignSelf: 'auto',
    },
    headerContent: {
      padding: `0 20px ${cssVariable.gutter}px 20px`,
    },
    flex: {
      alignItems: 'initial',
    },
    hideDownSm: {
      display: 'none',
    },
    showDownSm: {
      display: 'block',
    },
    buttonWrapper: {
      marginBottom: 10,
    },
    image: {
      flex: `0 0 ${thumbDimensions.DESKTOP_MEDIUM}px`,
      height: thumbDimensions.DESKTOP_MEDIUM,
    },
  },
  [theme.breakpoints.down('xs')]: {
    image: {
      flex: `0 0 ${thumbDimensions.MOBILE}px`,
      height: thumbDimensions.MOBILE,
    },
    podcastHeaderDescription: {
      fontSize: cssVariable.fontSizes.small,
    },
    actionsHolder: {
      flexDirection: 'column',
    },
  },

  // mobile header
  containerMobile: {
    marginTop: -30,
    display: 'flex',
    justifyContent: 'center',
    height: 230,
    overflow: 'hidden',
    marginBottom: 65,
  },

  backgroundImage: {
    backgroundColor: 'grey',
    backgroundPosition: '-45px -120px',
    backgroundSize: '120%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    filter: 'blur(8px)',
    opacity: '.4',
  },
  tileImage: {
    width: 235,
    height: 235,
    borderRadius: 8,
    marginTop: 30,
    position: 'absolute',
    backgroundSize: 'contain',
  },

  overflowEllipsis: {},
  descriptionContainer: {
    padding: '0 20px',
    marginBottom: 20,
    position: 'relative',
    fontSize: cssVariable.fontSizes.medium,
    color: cssVariable.grey500,

    '& $overflowEllipsis': {
      '@supports (-webkit-line-clamp: 4)': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        [cssVariable.WEBKIT_LINE_CLAMP]: 4,
        [cssVariable.WEBKIT_BOX_ORIENT]: 'vertical',
      },
    },
  },

  fullDescription: {
    '& $podcastHeaderDescription': {
      height: 'auto',
    },
  },
  podcastHeaderSubtitleMobile: {
    fontSize: cssVariable.fontSizes.small,
    margin: 0,
  },

  toggleCollapseBtn: {
    width: '100%',
    height: 40,
    color: cssVariable.blue500,
    background: cssVariable.white,
    border: 'none',
    cursor: 'auto',
  },
});

export default styles;
