import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';
import styles from './ContextMenu.styles';
import { createUseStyles } from 'react-jss';
import { trackShareContent, trackOpenShareMenu, trackGoToPodcast, trackNavigateToPodcastEpisodePage } from 'analytics';
import { SHARE_MENU_ID } from 'globalConst/const';
import ShareButton from './ShareButton';
import { isIos } from 'util/device';
import { removeTrailingSlash } from 'util/removeTrailingSlash';
import CrossIcon from 'components/svg/Cross';
import ShareIcon from 'components/svg/Share';
import ThreeDots from 'components/svg/ThreeDots';
import CopyIcon from 'components/svg/Copy';
import FacebookIcon from 'components/svg/Facebook';
import MailIcon from 'components/svg/Mail';
import TwitterIcon from 'components/svg/Twitter';
import WhatsappIcon from 'components/svg/Whatsapp';
import EpisodesIcon from 'components/svg/Episodes';
import ArrowIcon from 'components/svg/ArrowCarousel';
import Button from 'components/Button';
import ButtonTypes from 'components/Button/Button.const';
import { useHistory } from 'react-router-dom';
import NewPodcast from 'components/svg/NewPodcast';

const SHARE_ITEMS = [
  {
    type: 'facebook',
    name: 'Facebook',
    Button: FacebookShareButton,
    Icon: FacebookIcon,
  },
  {
    type: 'twitter',
    name: 'Twitter',
    Button: TwitterShareButton,
    Icon: TwitterIcon,
  },
  {
    type: 'whatsapp',
    name: 'WhatsApp',
    Button: WhatsappShareButton,
    Icon: WhatsappIcon,
  },
  { type: 'email', name: 'Email', Button: EmailShareButton, Icon: MailIcon },
];

export interface Item {
  slug?: string;
  title?: string;
}

interface OnDemandClip {
  name?: string;
  id?: string;
}

interface ContextMenu {
  description?: string;
  item?: Item;
  color?: string;
  viewBox?: string;
  width?: number;
  isInPodcastSwimlane?: boolean;
  hasMobileHeader?: boolean;
  showNotification?: () => void;
  right?: boolean;
  params?: Record<string, string | number>;
  onDemandClip?: OnDemandClip;
  clipDuration?: number;
  setClipDeleted?: () => void;
  podcastEpisodeSlug?: string;
  isHoverShown: boolean;
  toggleContextMenu?: () => void;
  collectionTitle?: string;
  isOnDemandTile?: boolean;
}

interface handleToggleOutside extends MouseEvent {
  target: HTMLInputElement;
}

const useStyles = createUseStyles(styles, { name: 'ContextMenu' });

const ContextMenu = (props: ContextMenu): JSX.Element => {
  const {
    item,
    description,
    hasMobileHeader,
    showNotification,
    color,
    right = false,
    params,
    podcastEpisodeSlug,
    viewBox = '0, 0, 40, 40',
    width = '',
    isInPodcastSwimlane = false,
    isHoverShown,
    toggleContextMenu,
    collectionTitle,
    isOnDemandTile = false,
    onDemandClip,
  } = props;
  const [showSocialMenu, setShowSocialMenu] = useState(false);
  const [sharePortal, setSharePortal] = useState(null);
  const [showSocialList, setShowSocialList] = useState(false);

  const contextMenuDropdown = useRef<HTMLDivElement>(null);
  const contextMenuIconButton = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const history = useHistory();

  const handleToggleOutside = (e: handleToggleOutside) => {
    if (
      contextMenuDropdown &&
      contextMenuDropdown.current &&
      !contextMenuDropdown?.current?.contains(e.target) &&
      contextMenuIconButton &&
      contextMenuIconButton.current &&
      !contextMenuIconButton.current.contains(e.target)
    ) {
      handleToggle();
    }
  };

  useEffect(() => {
    if (showSocialMenu && isInPodcastSwimlane) {
      window.addEventListener('resize', closeIfOpened);
      return () => {
        window.removeEventListener('resize', closeIfOpened);
      };
    }
  }, [showSocialMenu, isInPodcastSwimlane]);

  useEffect(() => {
    setSharePortal(document.getElementById(SHARE_MENU_ID));
    window.addEventListener('mousedown', handleToggleOutside);

    return () => {
      window.removeEventListener('mousedown', handleToggleOutside);
    };
  }, []);

  useEffect(() => {
    if (isHoverShown && isInPodcastSwimlane) {
      handleToggle();
    }
  }, [isHoverShown, item?.slug]);

  if (!item) return null;

  const handleToggle = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!showSocialMenu) {
      trackOpenShareMenu();
    }
    if (showSocialList) {
      setShowSocialList(() => false);
    }

    if (isInPodcastSwimlane) {
      if (e?.currentTarget?.id === 'closeButtonContextMenu') {
        toggleContextMenu();
      }
    }

    setShowSocialMenu((showSocialMenu) => !showSocialMenu);
  };

  const handleSocialList = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setShowSocialList((showSocialList) => !showSocialList);
  };

  const handleClickEvent = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const pathName = history.location.pathname;
  const link = `/${['podcasts', item.slug].join('/')}`;
  const episodeLink = `/${['podcasts', item.slug, podcastEpisodeSlug].join('/')}`;
  const goToPodcast = pathName != link;

  const handleGoToPodcast = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    trackGoToPodcast(collectionTitle);
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    history.push(link);
  };

  const handleGoToPodcastEpisode = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { onDemandClip, item } = props;
    const { title } = item;
    const { name } = onDemandClip;
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    history.push(removeTrailingSlash(episodeLink));
    trackNavigateToPodcastEpisodePage(name, title);
  };

  const handleToClipboard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const { origin, pathname, search } = window.location;
    const searchParams = {};
    if (search) {
      search
        .split('?')[1]
        .split('&')
        .forEach((value) => {
          const keyValue = value.split('=');
          searchParams[keyValue[0]] = keyValue[1];
        });
    }

    let url = [
      origin + pathname,
      Object.keys({
        ...searchParams,
        ...params,
      }).map((key) => `${key}=${params[key]}`),
    ].join('?');
    const clipboardElement = document.createElement('textarea');

    if (isInPodcastSwimlane) {
      url = origin + `/podcasts/${item?.slug}`;
    }

    if (isOnDemandTile && podcastEpisodeSlug) {
      url = [
        origin + `/podcasts/${item?.slug}/${podcastEpisodeSlug}`,
        Object.keys({
          ...params,
        }).map((key) => `${key}=${params[key]}`),
      ].join('?');
    }

    clipboardElement.value = url;
    clipboardElement.setAttribute('style', 'position:absolute; left:-9999px');
    clipboardElement.setAttribute('readonly', '');
    document.body.appendChild(clipboardElement);

    if (isIos()) {
      // save current contentEditable/readOnly status
      const { readOnly, contentEditable: editable } = clipboardElement;

      // convert to editable with readonly to stop iOS keyboard opening
      clipboardElement.contentEditable = 'true';
      clipboardElement.readOnly = true;

      // create a selectable range
      const range = document.createRange();
      range.selectNodeContents(clipboardElement);

      // select the range
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      clipboardElement.setSelectionRange(0, 999999);

      // restore contentEditable/readOnly to original state
      clipboardElement.contentEditable = editable;
      clipboardElement.readOnly = readOnly;
    } else {
      clipboardElement.select();
    }
    document.execCommand('copy');
    document.body.removeChild(clipboardElement);

    const shareProps = {
      collectionTitle,
      podcastEpisodeTitle: onDemandClip?.name,
      podcastTitle: item?.title,
      podcastEpisodeSlug,
      isOnDemandTile,
    };
    trackShareContent('copy-link-button', url, shareProps);
    handleToggle();
    if (isInPodcastSwimlane) {
      toggleContextMenu();
    }
    showNotification();
  };

  const closeIfOpened = () => {
    setShowSocialMenu(false);
    toggleContextMenu();
  };

  const renderContextMenu = () => {
    const { origin, search, href } = window.location;
    let url = href;

    const searchParams = {};
    if (search) {
      search
        .split('?')[1]
        .split('&')
        .forEach((value) => {
          const keyValue = value.split('=');
          searchParams[keyValue[0]] = keyValue[1];
        });
    }

    if (isInPodcastSwimlane) {
      url = origin + `/podcasts/${item?.slug}`;
    }

    if (isOnDemandTile && podcastEpisodeSlug) {
      url = [
        origin + `/podcasts/${item?.slug}/${podcastEpisodeSlug}`,
        Object.keys({
          ...params,
        }).map((key) => `${key}=${params[key]}`),
      ].join('?');
    }

    return (
      <div
        data-testid="context-menu"
        ref={contextMenuDropdown}
        className={classNames(classes.contextMenu, {
          [classes.contextMenuOffset]: !hasMobileHeader,
          [classes.contextMenuMobile]: hasMobileHeader,
          [classes.pullToRight]: right,
        })}
        onClick={handleClickEvent}
        style={{ opacity: 1, width: width, minWidth: isInPodcastSwimlane ? 139 : 250 }}
        id="context-menu"
      >
        <header
          className={classNames(
            classes.contextMenuHeader,
            { [classes.contextMenuHeaderWithTitle]: !showSocialList && isInPodcastSwimlane },
            classes.contextMenuTitlePadding
          )}
        >
          {isInPodcastSwimlane && !showSocialList && (
            <div className={classes.contextMenuHeaderTitle}>{item?.title}</div>
          )}
          <button
            className={classes.contextMenuCloseButton}
            type="button"
            onClick={handleToggle}
            id="closeButtonContextMenu"
          >
            <CrossIcon />
          </button>
        </header>
        <ul
          className={classNames(classes.contextMenuList, {
            [classes.contextMenuListHidden]: showSocialList,
          })}
        >
          <li className={classes.contextMenuListItem}>
            <button onClick={handleSocialList} type="button" data-testid="context-social-share-btn">
              <div className={classes.icon}>
                <ShareIcon />
              </div>
              <span className={classes.contextMenuListItemLabel}>Delen</span>
              {!isInPodcastSwimlane && (
                <div className={classes.listItemArrowRight}>
                  <ArrowIcon />
                </div>
              )}
            </button>
          </li>
          {goToPodcast && (
            <li className={classes.contextMenuListItem}>
              <button type="button" onClick={handleGoToPodcast} data-testid="go-to-podcast-btn">
                <div className={classes.icon}>
                  <NewPodcast />
                </div>
                <span className={classes.contextMenuListItemLabel}>
                  {isInPodcastSwimlane ? 'Naar podcast' : 'Ga naar deze podcast'}
                </span>
              </button>
            </li>
          )}
          {podcastEpisodeSlug && (
            <li className={classes.contextMenuListItem}>
              <button type="button" onClick={handleGoToPodcastEpisode} data-testid="go-to-podcast-episode-btn">
                <div className={classes.icon}>
                  <EpisodesIcon />
                </div>
                <span className={classes.contextMenuListItemLabel}>Naar de aflevering</span>
              </button>
            </li>
          )}
        </ul>

        <div
          className={classNames(classes.contextMenuSocialListWrapper, {
            [classes.contextMenuSocialListActive]: showSocialList,
          })}
        >
          <div className={classes.contextMenuSocialListBack}>
            <button className={classes.contextMenuSocialListBackButton} onClick={handleSocialList} type="button">
              <ArrowIcon />
            </button>
          </div>
          <ul className={classes.contextMenuSocialList} data-testid="context-menu-social-list">
            {SHARE_ITEMS.map(({ type, name, Icon, Button }) => (
              <li key={`share-item-${type}`} className={classes.contextMenuSocialListItem}>
                <ShareButton
                  Button={Button}
                  description={description}
                  handleToggle={handleToggle}
                  type={type}
                  url={url}
                  toggleContextMenu={toggleContextMenu}
                  isInPodcastSwimlane={isInPodcastSwimlane}
                  collectionTitle={collectionTitle || ''}
                  podcastTitle={item.title || ''}
                  isOnDemandTile={isOnDemandTile}
                  podcastEpisodeSlug={podcastEpisodeSlug}
                  podcastEpisodeTitle={onDemandClip?.name}
                >
                  <div className={classes.icon}>
                    <Icon />
                  </div>
                  <span className={classes.contextMenuListItemLabel}>{name}</span>
                </ShareButton>
              </li>
            ))}
            <li className={classes.contextMenuSocialListItem}>
              <div
                role="presentation"
                className={classes.buttonWrapper}
                onClick={handleToClipboard}
                data-testid="copy-to-clipboard-btn"
              >
                <div className={classes.icon}>
                  <CopyIcon />
                </div>
                <span className={classes.contextMenuListItemLabel}>Link kopieren</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.menuButton}>
      <div className={isInPodcastSwimlane ? classes.menuButtonHide : classes.menuButtonWrapper}>
        <Button
          type={ButtonTypes.ICON}
          className={classNames(classes.menuButtonComponent, {
            [classes.additionalMargin]: isInPodcastSwimlane,
          })}
          ref={contextMenuIconButton}
          onClick={handleToggle}
          icon={<ThreeDots fill={color} viewBox={viewBox} />}
          data-testid="context-menu-btn"
        />
      </div>
      {showSocialMenu &&
        (hasMobileHeader && sharePortal
          ? ReactDOM.createPortal(renderContextMenu(), sharePortal)
          : renderContextMenu())}
    </div>
  );
};

export default ContextMenu;
