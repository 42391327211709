import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Collection from 'components/Collection';
import PageHeader from 'components/PageHeader';
import PageContent from 'components/PageContent';
import SocialShare from 'components/SocialShare';
import shouldDisplayCollection from 'lib/collection';
import asContentPage from 'components/hoc/asContentPage';
import { TILE_COMPONENT_TYPES } from 'components/TileComponent/TileComponent.const';

class StationPage extends PureComponent {
  static defaultProps = {
    name: null,
    headerImage: null,
    liveShows: [],
    relatedStations: null,
    relatedShows: null,
    relatedGenres: null,
    onDemandClips: [],
    headerTitleColor: null,
    metadata: null,
  };

  static propTypes = {
    name: PropTypes.string,
    headerImage: PropTypes.object,
    liveShows: PropTypes.array,
    relatedStations: PropTypes.object,
    relatedShows: PropTypes.object,
    relatedGenres: PropTypes.object,
    onDemandClips: PropTypes.array,
    headerTitleColor: PropTypes.object,
    metadata: PropTypes.object,
  };

  render() {
    const {
      name,
      headerImage,
      liveShows,
      relatedStations,
      relatedShows,
      relatedGenres,
      onDemandClips,
      headerTitleColor,
      metadata,
    } = this.props;
    return (
      <Fragment>
        <PageHeader
          image={headerImage && headerImage.url ? headerImage : null}
          title={name}
          titleColor={headerTitleColor}
          metadata={metadata}
        />
        <PageContent>
          {metadata && (
            <SocialShare subject={name} description={metadata.share.description} link={metadata.share.url} />
          )}
          {liveShows.length > 0 && (
            <Collection
              items={liveShows}
              rows={1}
              itemsPerRow={4}
              title="On-air"
              tileComponent={TILE_COMPONENT_TYPES.SHOW}
            />
          )}
          {onDemandClips.length > 0 && (
            <Collection
              items={onDemandClips}
              rows={1}
              itemsPerRow={4}
              title="Terugluisteren"
              tileComponent={TILE_COMPONENT_TYPES.ON_DEMAND}
            />
          )}
          {shouldDisplayCollection(relatedShows) && (
            <Collection
              items={relatedShows.collection}
              rows={2}
              itemsPerRow={4}
              title="Gerelateerde shows"
              description={relatedShows.description}
              tileComponent={TILE_COMPONENT_TYPES.SHOW}
            />
          )}
          {shouldDisplayCollection(relatedStations) && (
            <Collection
              items={relatedStations.collection}
              rows={2}
              itemsPerRow={4}
              title="Gerelateerde stations"
              description={relatedStations.description}
              tileComponent={TILE_COMPONENT_TYPES.STATION}
            />
          )}
          {shouldDisplayCollection(relatedGenres) && (
            <Collection
              items={relatedGenres.collection}
              rows={2}
              itemsPerRow={3}
              title="Gerelateerde genres"
              description={relatedGenres.description}
              tileComponent={TILE_COMPONENT_TYPES.TAG}
            />
          )}
        </PageContent>
      </Fragment>
    );
  }
}

export default asContentPage('show', StationPage);
