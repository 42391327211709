import Show, { UniversalShow } from './Show';
import Collection from './Collection';
import { isEmpty } from 'util/index';
import { DisplayLayout } from 'components/Collection/Collection.const';
import { UniversalCollection, UniversalConfig } from '../interfaces';

interface UniversalShowCollection extends UniversalCollection {
  getShows: {
    shows: Array<UniversalShow>;
  };
  items: Array<UniversalShow>;
}

/**
 * @class
 * A representation of a ShowCollection
 *
 * @param {object} collection - collection model
 * @param {object} config - config entries
 * @param {object} options - options e.g. no-playouts, highlighted...
 */
export default class ShowCollection extends Collection {
  items: Array<Show>;
  itemsPerRow: number;
  isExpandable = false;

  constructor(collection: UniversalCollection, config: UniversalConfig[], options: string[]) {
    super(collection, config, options);
    if (isEmpty(collection)) {
      return null;
    }

    const showCollection = <UniversalShowCollection>collection;

    const ondemandCollection = this.options.some((option) => option.toLowerCase().includes('ondemand'));
    this.isExpandable = ondemandCollection;

    this.items = showCollection.getShows && showCollection.getShows?.shows.map((show) => new Show(show));

    // Support for deprecated fields
    if (showCollection.items) {
      this.items = showCollection.items && showCollection.items.map((show) => new Show(show));
    }

    // I am sorry for this hack, need to refactor so we can support generatedCollections.
    // For now this way we have a grid layout on the radioshows page
    if (window.location.href.indexOf('radioshows') > -1) {
      this.display = DisplayLayout.GRID;
    }

    if (ondemandCollection) {
      const presentationType = this.config.PresentationType;
      this.display = presentationType === DisplayLayout.GRID ? DisplayLayout.TILES : DisplayLayout.LIST;

      const rows = this.config.Rows;
      if (rows) {
        this.rows = Math.max(+rows, 1);
      } else {
        this.rows = this.display === DisplayLayout.LIST ? 3 : 1;
      }

      this.itemsPerRow = this.display === DisplayLayout.LIST ? 1 : 4;
    }

    // Make this class read-only
    Object.freeze(this);
  }
}
