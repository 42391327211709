import Metadata from './Metadata';
import { isEmpty } from 'util/index';
import { UniversalConfig, UniversalMetadata } from '../interfaces';
import { CONFIG_KEY, TALPA_RADIO_EMBED_DOMAINS } from 'globalConst/const';
import logger from 'util/logger';

/**
 * @class
 * A representation of an Campaign
 *
 * @param {object} campaign - campaign model
 */
export default class Campaign {
  slug: string;
  uri: string;
  contentTypeId: string;
  metadata: Metadata;
  brandSlug: string;
  brandName: string;

  constructor(campaign: {
    slug: string;
    uri: string;
    type: string;
    config: UniversalConfig[];
    metadata: UniversalMetadata[];
  }) {
    if (!campaign) {
      return null;
    }

    const addDeeplinkParameter = TALPA_RADIO_EMBED_DOMAINS.some(
      (domain) => campaign.uri.includes(domain) && !campaign.uri.includes('deeplink=web')
    );
    const uriHasParameters = campaign.uri.includes('?');
    const uriWithDeeplinkParameter =
      addDeeplinkParameter && uriHasParameters ? `${campaign.uri}&deeplink=web` : `${campaign.uri}?deeplink=web`;

    this.slug = campaign.slug;
    this.uri = addDeeplinkParameter ? uriWithDeeplinkParameter : campaign.uri;

    this.contentTypeId = campaign.type;

    if (campaign.config && Array.isArray(campaign.config)) {
      campaign.config.forEach(({ entries }) => {
        entries.forEach((entry: { key: string; value: string }) => {
          switch (entry.key) {
            case CONFIG_KEY.BRAND_SLUG:
              this.brandSlug = entry.value;
              break;
            default:
              logger.warn(`Campaign model missing config: ${entry.key}`);
              break;
          }
        });
      });
    }
    if (!isEmpty(campaign.metadata)) {
      this.metadata = new Metadata(campaign.metadata[0]);
    }

    if (Array.isArray(campaign.config)) {
      this.brandSlug = campaign.config.reduce((acc, { entries }) => {
        const brandSlug = entries.find(({ key }) => key === CONFIG_KEY.BRAND_SLUG);
        return brandSlug ? brandSlug.value : acc;
      }, '');
    }

    if (Array.isArray(campaign.config)) {
      this.brandName = campaign.config.reduce((acc, { entries }) => {
        const brandName = entries.find(({ key }) => key === CONFIG_KEY.BRAND_NAME);
        return brandName ? brandName.value : acc;
      }, '');
    }

    // Make this class read-only
    Object.freeze(this);
  }
}
