import React from 'react';
import svgIcon from 'components/hoc/svgIcon';

const styles = {
  fill: 'none',
  stroke: 'currentColor',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: 2,
};
const svg = (
  <svg viewBox="0 0 40 40">
    <polyline {...styles} points="17 28 25 20 17 12" />
  </svg>
);

export default svgIcon(svg);
