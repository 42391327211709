/**
 * Generic async script importer
 * It will return the script tag if the script can be loaded
 */
export const importScript = async (
  source: string,
  attributes?: [{ name: string; value: string }],
  placement: 'body' | 'head' = 'body'
): Promise<null | HTMLScriptElement> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = source;

    // Event handlers
    script.onload = () => {
      resolve(script);
    };
    script.onerror = (error) => {
      reject(null);
      throw error;
    };

    // Handle attributes
    if (attributes && attributes.length > 0) {
      attributes.forEach((attribute) => {
        if (attribute.name.includes('data')) {
          script.setAttribute(attribute.name, attribute.value);
        } else {
          script[attribute.name] = attribute.value;
        }
      });
    }

    document[placement].append(script);
  });
