import * as cssVariable from 'cssVariables';
import { Styles } from 'jss';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  list: {
    marginBottom: 15,
    textAlign: 'left',
  },

  grid: {
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    alignItems: 'baseline',
    textAlign: 'left',
  },

  title: {
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.grey500,
    lineHeight: '25px',
    padding: '0 5px',
    textTransform: 'uppercase',
  },

  elements: {
    display: 'flex',
    listStyle: 'none',
    padding: '0px',
    flexDirection: 'column',
    marginTop: '10px',
    '& > li': {
      display: 'inline-block',
      fontSize: cssVariable.fontSizes.xsmall,
      padding: '0 5px',
      textTransform: 'capitalize',
    },
    '& > a': {
      color: cssVariable.grey500,
    },
  },

  navItemWrapper: {
    display: 'inline-block',
  },

  secondaryElements: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '5px',

    '& > li': {
      padding: '0 20px 0 5px',
    },
  },

  subscriptionButton: {
    '&:hover': {
      textDecoration: 'none',
    },

    '& span': {
      textTransform: 'capitalize',
    },
  },

  footerLink: {
    color: cssVariable.grey500,
    lineHeight: '25px',
  },

  footerLinkPrimary: {
    color: cssVariable.grey600,
  },

  socialIcon: {
    width: '40px',
    height: '40px',
    '&:hover': {
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.1), 0 5px 10px 0 rgba(0,0,0,0.1)',
    },
  },

  socialIcons: {
    display: 'inline-block',
  },

  sectionMobile: {},
  sectionTablet1: {},
  sectionTablet2: {},
  sectionTablet3: {},
  sectionTablet4: {},

  [theme.breakpoints.down('md')]: {
    list: {
      marginBottom: 15,
      '& > li': {
        padding: '0 10px',
      },
    },

    grid: {
      gridTemplateColumns: 'repeat(2,1fr)',
      gridAutoFlow: 'dense',
    },

    elements: {
      marginTop: '7px',
    },

    secondaryElements: {
      '& > li': {
        padding: '0 10px 0 5px',
      },
    },

    sectionTablet1: {
      gridColumn: '1/1',
      marginBottom: '5px',
    },

    sectionTablet2: {
      gridColumn: '2/1',
    },

    sectionTablet3: {
      gridColumn: '2',
    },

    sectionTablet4: {
      gridColumn: '2',
    },
  },

  [theme.breakpoints.down('sm')]: {
    list: {
      '& > li': {
        padding: '7.5px 10px',
      },
    },

    grid: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    elements: {
      marginTop: '5px',
    },

    secondaryElements: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',

      '& > li': {
        alignItems: 'center',
        marginBottom: '5px',
      },
    },

    sectionMobile: {
      gridColumn: '1/3',
    },

    title: {
      padding: '5px',
    },
  },
});
