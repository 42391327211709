import * as cssVariable from 'cssVariables';

export default (theme) => ({
  container: {
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    userSelect: 'none',
    marginBottom: '-15px',
  },
  timeContainer: {
    alignSelf: 'center',
    color: cssVariable.grey500,
    fontSize: cssVariable.fontSizes.xxsmall,
    fontFamily: 'Arial',
    width: 35,
    marginLeft: 10,
    textAlign: 'center',
  },
  trackContainer: {
    width: '100%',
    alignSelf: 'center',
    cursor: 'pointer',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible',
    userSelect: 'none',
  },
  track: {
    width: '100%',
    height: 3,
    background: 'white',
    userSelect: 'none',
  },
  trackComplete: {
    height: 3,
    position: 'absolute',
    left: 0,
    top: 14.5,
    background: cssVariable.green500,
    userSelect: 'none',
  },
  handle: {
    backgroundColor: cssVariable.green500,
    height: 9,
    width: 9,
    borderRadius: '50%',
    position: 'absolute',
    left: 0,
    top: 11,
    userSelect: 'none',
    marginLeft: '-4.5px',
  },
  animation: {
    '& $handle': {
      transition: 'left 1s linear',
    },
    '& $trackComplete': {
      transition: 'width 1s linear',
    },
  },
  scrubberMobile: {
    '& $trackContainer': {
      cursor: 'auto',
    },
    '& $trackComplete': {
      top: 12.5,
    },
    '& $trackComplete, & $track': {
      height: 6,
      borderRadius: 6,
    },
    '& $track': {
      background: cssVariable.grey200,
    },
    '& $handle': {
      top: 8,
      height: 16,
      width: 16,
    },
  },
  label: {
    background: 'linear-gradient(to right, #ffffff00, #fff 30%, #fff 70%, #ffffff00)',
    position: 'absolute',
    top: 6,
    left: '50%',
    width: 80,
    marginLeft: -40,
    textAlign: 'center',
    fontWeight: cssVariable.fontWeights.light,
    color: cssVariable.grey600,
  },
  hideOnMobile: {},
  [`@media (max-width: ${theme.breakpoints.values.sl}px)`]: {
    container: {
      minWidth: 290,
    },
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    hideOnMobile: {
      display: 'none',
    },
  },
  [`@media (max-width: ${theme.breakpoints.values.xs}px)`]: {
    container: {
      minWidth: 120,
    },
  },
});
