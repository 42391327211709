import React from 'react';
import { ThemeProvider } from 'react-jss';
import { useSelector } from 'react-redux';

import { getPalette } from 'store/reducers/palette';
import theme from '../theme';

// We need to connect the ThemeProvider to the store
const Theme = ({ children }) => {
  const palette = useSelector(getPalette);

  return <ThemeProvider theme={theme(palette)}>{children}</ThemeProvider>;
};

export default Theme;
