export function trackRecommendationTilesClick() {
  window.Taq.push([
    'track',
    'link',
    {
      ...window.Taq.jukeCustomData,
      event_name: 'content_listing_play',
      event_category: 'content_listing',
      event_label: 'aanbevolen - player',
      event_action: 'content_listing_play',
    },
  ]);
}
