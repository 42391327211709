import * as cssVariable from 'cssVariables';

export default {
  recommendationTilesWrapper: {
    display: 'flex',
    margin: [0, -5],
    '& > div': {
      minWidth: 108,
      minHeight: 108,
      margin: [0, 5],
    },
  },
  recommendationTiles: {
    padding: [0, 10],
    '& h3[class^="StationTile-title"]': {
      fontSize: '1em',
    },
    '& div[class^="StationTile"]:hover': {
      transform: 'scale(1)',
    },
  },

  title: {
    margin: 0,
    padding: [8, 0, 10, 0],
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.white,
    letterSpacing: -0.4,
    textAlign: 'left',
  },
};
