import React from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import * as cssVariable from 'cssVariables';
import { getImageWithResamplingQuery } from 'util/resampling';
import classNames from 'classnames';
import IEClipPathPolyfill from 'components/svg/IEClipPathPolyfill';
import BannerButton from './BannerButton';
import BannerTileCollection from './BannerTileCollection';
import { isEdge, isIE11andLower } from 'util/device';

function Banner({ buttonText, classes, media, mediaPosition, pageLink, text, title, url, playStationSlug, tileItems }) {
  return (
    <div
      data-testid="banner"
      className={classNames(classes.container, {
        [classes.containerMediaLeft]: mediaPosition === 'left',
      })}
    >
      <div className={classes.contentContainer}>
        {title && <h3 className={classes.title}>{title}</h3>}
        {text && <div className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />}
        <BannerButton
          pageLink={pageLink}
          buttonText={buttonText}
          url={url}
          title={title}
          playStationSlug={playStationSlug}
        />
      </div>
      {tileItems && <BannerTileCollection tileItems={tileItems} />}
      {media && media.url && !tileItems && (
        <div className={classes.imageContainer}>
          <div
            className={classes.image}
            style={{ backgroundImage: `url(${getImageWithResamplingQuery({ url: media.url, isHeader: true })})` }}
          />

          {(isIE11andLower || isEdge) && (
            <div className={classes.triangleContainer}>
              <IEClipPathPolyfill className={classes.triangle} color={cssVariable.white} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

Banner.defaultProps = {
  title: null,
  text: null,
  pageLink: null,
  url: null,
  buttonText: null,
  media: null,
  mediaPosition: null,
  hideOn: null,
  playStationSlug: null,
  tileItems: null,
};

Banner.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  pageLink: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  playStationSlug: PropTypes.string,
  url: PropTypes.string,
  buttonText: PropTypes.string,
  media: PropTypes.shape({
    uuid: PropTypes.string,
    contentTypeId: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    altText: PropTypes.string,
  }),
  mediaPosition: PropTypes.string,
  hideOn: PropTypes.shape({
    touch: PropTypes.bool,
    desktop: PropTypes.bool,
  }),
  tileItems: PropTypes.array,
};

const styles = (theme) => ({
  container: {
    ...cssVariable.fullWidthStyles(theme, -5),
    display: 'flex',
    border: `1px solid ${cssVariable.grey300}`,
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: cssVariable.gutter * 2,
    marginBottom: cssVariable.gutter * 2,
    minHeight: isIE11andLower ? 'auto' : 260,
  },

  contentContainer: {
    width: '40%',
    padding: '34px 0 34px 70px',
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    fontSize: cssVariable.fontSizes.xlarge,
    margin: 0,
  },

  text: {
    color: cssVariable.grey500,
    fontSize: cssVariable.fontSizes.small,
    '& > *': {
      '@supports (-webkit-line-clamp: 4)': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        [cssVariable.WEBKIT_LINE_CLAMP]: 7,
        [cssVariable.WEBKIT_BOX_ORIENT]: 'vertical',
      },
    },
  },

  button: {
    ...cssVariable.baseButtonStyle,
    color: cssVariable.white,
    fontSize: cssVariable.fontSizes.xsmall,
    backgroundColor: cssVariable.green500,
    marginTop: 'auto',
    alignSelf: 'start',
    maxWidth: isIE11andLower ? 210 : 'auto',
    padding: '0 30px',
    '&:hover': {
      backgroundColor: cssVariable.grey600,
      textDecoration: 'none',
    },
  },

  imageContainer: {
    width: '60%',
    position: 'relative',
    overflow: 'hidden',
  },

  image: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    // JSS does not auto prefix webkit to clip path, see: https://github.com/cssinjs/jss/issues/1223
    '-webkit-clip-path': 'polygon(0 0, 100% 0%, 100% 100%, 65% 100%)',
    clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 65% 100%)',
  },

  containerMediaLeft: {
    flexDirection: 'row-reverse',
    '& $contentContainer': {
      padding: '34px 70px 34px 0',
    },

    '& $image': {
      backgroundPosition: 'left',
      // JSS does not auto prefix webkit to clip path, see: https://github.com/cssinjs/jss/issues/1223
      '-webkit-clip-path': 'polygon(0 0, 100% 0, 35% 100%, 0 100%)',
      clipPath: 'polygon(0 0, 100% 0, 35% 100%, 0 100%)',
    },

    '& $triangleContainer': {
      right: -1,
    },

    '& $triangle': {
      transform: 'scaleX(1)',
    },
  },

  // ie fallback
  triangleContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: -1,
    right: 1,
  },
  triangle: {
    height: '101%',
    width: '100%',
    transform: 'scaleX(-1)',
  },

  [theme.breakpoints.up('lg')]: {
    tileContainer: {
      padding: '0 100px',
    },
  },

  [theme.breakpoints.down('md')]: {
    container: {
      minHeight: isIE11andLower ? 'auto' : 200,
      flexDirection: 'column-reverse',
    },

    imageContainer: {
      height: 260,
      width: '100%',
    },

    title: {
      fontSize: cssVariable.fontSizes.mediumLarge,
    },

    containerMediaLeft: {
      '& $contentContainer': {
        padding: 10,
      },
    },

    contentContainer: {
      width: '100%',
      padding: 10,
    },
  },
});

export default injectStyles(styles)(Banner);
