import Podcast from './Podcast';
import Collection from './Collection';
import { isEmpty } from 'util/index';
import { UniversalCollection, UniversalConfig, UniversalPodcast } from '../interfaces';
import { TILE_SIZES } from 'globalConst/const';
import { DisplayLayout } from 'components/Collection/Collection.const';

interface UniversalPodcastCollection extends UniversalCollection {
  getPodcasts: {
    podcasts: UniversalPodcast[];
  };
  items: UniversalPodcast[];
}

/**
 * @class
 * A representation of a PodcastCollection
 *
 * @param {object} collection - collection model
 * @param {object} config - config entries
 * @param {object} options - options e.g. no-playouts, highlighted...
 */
export default class PodcastCollection extends Collection {
  tileSizes: string = TILE_SIZES.SMALL;
  items: Array<Podcast>;
  itemsPerRow?: number;
  showNumbers?: boolean;
  isListTile?: boolean;

  constructor(collection: UniversalCollection, config?: UniversalConfig[], options?: string[]) {
    super(collection, config, options);

    if (isEmpty(collection)) {
      return null;
    }

    const podcastCollection = <UniversalPodcastCollection>collection;

    this.tileSizes = TILE_SIZES.SMALL;

    this.items =
      podcastCollection.getPodcasts && podcastCollection.getPodcasts?.podcasts.map((podcast) => new Podcast(podcast));

    // Suport for deprecated fields
    if (podcastCollection.items) {
      this.items = podcastCollection.items && podcastCollection.items.map((podcast) => new Podcast(podcast));
    }

    this.display =
      (this.config && this.config.PresentationType === DisplayLayout.LIST) || this.display === DisplayLayout.LIST
        ? DisplayLayout.LIST
        : this.display;
    this.itemsPerRow =
      (this.config && this.config.PresentationType === DisplayLayout.LIST) || this.display === DisplayLayout.LIST
        ? 1
        : this.itemsPerRow;
    this.showNumbers = this.config && this.config.ListNumber === 'true' ? true : false;
    this.isListTile =
      (this.config && this.config.PresentationType === DisplayLayout.LIST) || this.display === DisplayLayout.LIST;

    // Make this class read-only
    Object.freeze(this);
  }
}
