import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import { isTouch } from 'util/device';
import classNames from 'classnames';
import { debounce } from 'util/helpers';
import styles from '../collectionItemStyle';
import { DisplayLayout } from '../Collection.const';
import { trackCarouselNavigation } from 'analytics';
import TileComponent from 'components/TileComponent';

class CollectionTiles extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    disableTiles: PropTypes.bool,
    display: PropTypes.string,
    dots: PropTypes.bool,
    itemsPerRow: PropTypes.number,
    items: PropTypes.array,
    mobileRows: PropTypes.number,
    rows: PropTypes.number,
    swipe: PropTypes.bool,
    tileComponent: PropTypes.string.isRequired,
    collectionId: PropTypes.string,
    contentTypeId: PropTypes.string,
    title: PropTypes.string,
    isListTile: PropTypes.bool,
    showNumbers: PropTypes.bool,
  };

  static defaultProps = {
    classes: {},
    disableTiles: false,
    display: null,
    dots: false,
    itemsPerRow: undefined,
    mobileRows: 1,
    rows: 1,
    swipe: false,
    collectionId: null,
    contentTypeId: null,
    items: [],
    showNumbers: false,
  };

  state = {
    currentIndex: 0,
  };

  componentDidMount() {
    if (this.collectionRef) {
      this.collectionRef.addEventListener('scroll', this.debouncedScroll);
    }
  }

  componentWillUnmount() {
    if (this.collectionRef) {
      this.collectionRef.removeEventListener('scroll', this.debouncedScroll);
    }
  }

  setRef = (ref) => {
    this.collectionRef = ref;
  };

  getSlideAmount = () => {
    const { dots, items, mobileRows, itemsPerRow } = this.props;
    const rowItems = Math.ceil(items.length / mobileRows);
    const shownTiles = dots ? rowItems : Math.min(rowItems, itemsPerRow * 3);
    return Math.round(shownTiles / itemsPerRow);
  };

  checkScrollPosition = () => {
    const { scrollLeft, scrollWidth } = this.collectionRef;
    const { collectionId, title, dots } = this.props;

    const currentIndex = Math.round((scrollLeft / scrollWidth) * this.getSlideAmount());
    if (dots && this.state.currentIndex !== currentIndex) {
      this.setState({ currentIndex });
    }
    const pageUrl = window.location.origin.concat(window.location.pathname);
    trackCarouselNavigation(currentIndex, collectionId, title, pageUrl);
  };

  debouncedScroll = debounce(this.checkScrollPosition, 500);

  render() {
    const {
      classes,
      display,
      disableTiles,
      dots,
      items,
      mobileRows,
      swipe,
      itemsPerRow,
      tileComponent,
      collectionId,
      contentTypeId,
      isListTile,
      title,
      ...rest
    } = this.props;

    const listViewSimple = display === DisplayLayout.LIST;
    const listViewGradient =
      display === DisplayLayout.LIST_NOW_PLAYING_HALF || display === DisplayLayout.LIST_NOW_PLAYING_FULL;
    const tileRows = [];
    const rowItems = Math.ceil(items.length / mobileRows);
    let tileClass = classes[`tileView${itemsPerRow}Items`];
    if (isTouch && swipe) {
      // Render a swipeable list of tiles for touch devices
      tileClass = classes[`tileView${itemsPerRow}ItemsSwipeable`]; // Visible row has a width of 96% to indicate that there are more tiles to scroll
      for (let i = 0; i < items.length; i += rowItems) {
        const tileRow = items.slice(i, i + rowItems);
        tileRows.push({
          key: `collection-tiles-${collectionId}-items=${i}-${i + rowItems}`,
          items: tileRow,
        });
      }
    } else {
      tileRows.push({
        key: `collection-tiles-${collectionId}`,
        items,
      });
    }

    return (
      <div
        data-testid={`collection-tiles-${contentTypeId}-${rest.slug}`}
        className={classNames({ [classes.withDots]: dots })}
      >
        <div
          ref={this.setRef}
          className={classNames(classes.collectionWrapper, {
            [classes.collectionWrapperList]: listViewSimple || listViewGradient,
            [classes.swipe]: swipe && isTouch,
            [classes.cardDeck]: collectionId === 'CardDeck',
          })}
        >
          {tileRows.map((row) => {
            return (
              <div
                key={row.key}
                className={classNames(classes.collection, {
                  [classes.collectionSwipe]: swipe && isTouch,
                })}
              >
                {row.items.map((tile, index) => {
                  return (
                    <div
                      key={`${row.key}-${tile.slug || tile.id || tile.name}-${index}`}
                      className={classNames(
                        classes.collectionItem,
                        isListTile ? classes.tileListView : classes.tileView,
                        tileClass,
                        {
                          [classes.listViewSimple]: listViewSimple,
                          [classes.tileViewSwipeable]: swipe && isTouch,
                          [classes.listViewGradientFullWidth]: display === DisplayLayout.LIST_NOW_PLAYING_FULL,
                          [classes.listViewGradientHalfWidth]: display === DisplayLayout.LIST_NOW_PLAYING_HALF,
                        }
                      )}
                    >
                      <TileComponent
                        disabled={!!disableTiles}
                        index={index}
                        collectionId={collectionId}
                        {...rest}
                        {...tile}
                        tileComponent={tileComponent}
                        collectionTitle={title}
                        isListTile={isListTile}
                        rowNum={index + 1}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default injectStyles(styles)(CollectionTiles);
