import { queryRequest } from './client';
import { getOverview, getMixedOverview, getOverviewWithReverences, getMixedContents } from './queries';

import { parseContentModal } from './parsers/overview';
import { isEmpty } from 'util/index';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { TAB_TYPES } from 'globalConst/const';
import logger from 'util/logger';
import MixedCollection from '../models/MixedCollection';
import mixedFetchBuilder from './gqlHelper';

import Tabs from 'api/models/Tabs';

/**
 * Fetch a overview collection from the universal api
 * @param {string} slug
 *
 * @returns {Object} `Overview`
 */
export const fetchOverviewWithReferences = async ({ slug, config, options }) => {
  const sections = [];

  const isTabOverview =
    !isEmpty(options) && options.some((option) => option === TAB_TYPES.INDEXABLE || option === TAB_TYPES.ANCHOR);

  if (isTabOverview) {
    try {
      const result = await queryRequest({
        query: getOverview,
        variables: { slug },
      });

      if (!isEmpty(result)) {
        const { overview } = result?.data || {};
        sections.push(new Tabs(overview, config, options));
      }
    } catch (error) {
      logger.error(`Something went wrong while fetching the data for the tabs with slug ${slug}`);
    }
    return { sections };
  }

  try {
    const { data } = await queryRequest({
      query: getOverviewWithReverences,
      variables: { slug },
    });

    // check if section contains a mixedcollection
    const mixedCollectionsPromise = [];
    data.overview.items.forEach((section) => {
      const { options } = section;
      const isMixedcollection = !isEmpty(options) && options.some((option) => option === 'mixed-collection');

      if (isMixedcollection) {
        const mixedCollectionSlug = section.references[0].slug;
        const mixedCollection = queryRequest({
          query: getMixedOverview,
          variables: { slug: mixedCollectionSlug },
        });
        mixedCollectionsPromise.push(mixedCollection);
      }
    });

    const mixedCollectionsResolved = await Promise.all(mixedCollectionsPromise);
    const mixedCollectionSections = {};

    // parse the mixedcollections data to create the the MixedCollection model
    mixedCollectionsResolved.forEach((section) => {
      const { data } = section;

      const rawMixedCollection = {
        id: data.mixed.id,
        items: data.mixed.items[0].references,
        slug: data.mixed.slug,
        type: CONTENT_TYPES.MIXED_COLLECTION,
        title: data.mixed.title,
        disableLazyFetch: true,
      };

      const parsedMixedCollection = parseContentModal(
        rawMixedCollection,
        CONTENT_TYPES.MIXED_COLLECTION,
        data.mixed.config,
        data.mixed.options
      );
      mixedCollectionSections[parsedMixedCollection.slug] = parsedMixedCollection;
    });

    data.overview.items.forEach((section) => {
      const { options } = section;

      const isTabCollection =
        !isEmpty(section.options) &&
        section.options.some((option) => option === TAB_TYPES.INDEXABLE || option === TAB_TYPES.ANCHOR);

      if (isTabCollection) {
        sections.push({
          config: section.config,
          options: section.options,
          slug: section.references[0].slug,
          contentTypeId: CONTENT_TYPES.OVERVIEW_COLLECTION,
        });
        return;
      }

      const mixedCollection = !isEmpty(options) && options.some((option) => option === 'mixed-collection');

      // if it is a mixedCollection push the already parsed mixedCollectionSections else parse collection first then push
      if (mixedCollection) {
        sections.push(mixedCollectionSections[section.references[0].slug]);
      } else {
        section.references[0].disableLazyFetch = true;

        const res = parseContentModal(
          {
            ...section.references[0],
            sectionTitle: section.title,
          },
          section.references[0].type,
          section.config,
          section.options
        );

        // Check if section items are not empty or if its not recently played station
        if (
          (!isEmpty(res) && !isEmpty(res.items)) ||
          res.contentTypeId === CONTENT_TYPES.RECENTLY_PLAYED_STATIONS_COLLECTION ||
          res.showEmptyState
        ) {
          sections.push(res);
        }
      }
    });
  } catch (e) {
    logger.error(`Fail to fetch overview with slug ${slug}`, e);
  }

  // TODO does overview need config ?
  return { sections };
};

/**
 * Fetch mixed content from the universal api
 * @param {string} slug
 * @returns {Object} Mixed
 */
export const fetchMixed = async ({ slug }) => {
  // fetch data from overview collection on universal API
  const { data } =
    (await queryRequest({
      query: getMixedOverview,
      variables: { slug },
    })) || {};
  const searchItems = data.mixed && data.mixed.items[0].refs;

  // Then use data to build most popular or most recent query
  const mixedCall = searchItems.reduce(
    (acc, item, i) => `${acc} ${mixedFetchBuilder(item.uri, item.targetType, i)}`,
    ''
  );
  // Call for data from universal API
  const { data: mixedContents } = (await queryRequest({ query: getMixedContents(mixedCall) })) || {};
  return new MixedCollection(mixedContents);
};
