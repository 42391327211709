export default function trackInlineMediaPlayback(url) {
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'content-interactions_inline-media-playback',
      event_category: 'content-interactions',
      event_action: 'play-inline-media',
      event_label: url || null,
    },
  ]);
}
