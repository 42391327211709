export const NAVIGATION_COLLECTION_FRAGMENT = `
  fragment NavigationCollectionFragment on NavigationCollection {
     title
     type
     slug
     options
     getNavigations {
        navigations {
            slug
            title
            uri
            options
            type
            reference {
                ... on NavigationCollection {
                    title
                    getNavigations {
                        navigations {
                            slug
                            type
                            title
                            uri
                            options
                            images {
                              ...ImageFragment
                            }
                        }
                    }
                }
            }
        }
    }
  }
  `;
