import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'util/index';
import { trackShareContent } from 'analytics';

export default class ShareButton extends PureComponent {
  static defaultProps = {
    handleToggle: noop,
  };

  static propTypes = {
    Button: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    description: PropTypes.string.isRequired,
    handleToggle: PropTypes.func,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isInPodcastEpisodePage: PropTypes.bool,
    media_data: PropTypes.object,
  };

  handleShareClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { handleToggle, type, url, isInPodcastEpisodePage, media_data } = this.props;
    const shareProps = {
      isInPodcastEpisodePage,
      media_data,
    };
    trackShareContent(type, url, shareProps);
    handleToggle();
  };

  render() {
    const { Button, description, url, children } = this.props;
    return (
      <div role="presentation" onClick={this.handleShareClick}>
        <Button
          data-testid="share-button"
          url={url}
          subject={description}
          title={description}
          quote={description}
          via="JUKE_NL"
        >
          {children}
        </Button>
      </div>
    );
  }
}
