import { connect } from 'react-redux';

//import { favoriteTrackToggle } from 'actions/favoriteTrack-actions';
//import { getIsFavoriteTrack } from 'store/reducers/favorites';

import Track from './Track';

const mapStateToProps = () => ({
  //isFavorite: getIsFavoriteTrack(state, ownProps),
});

function mapDispatchToProps() {
  return {
    // onFavoriteToggle: () => dispatch(favoriteTrackToggle(ownProps.track)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Track);
