import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectStyles } from 'injectStyles';
import { grey100, fontSizes, ellipsis, white } from 'cssVariables';
import PlusIcon from 'components/svg/Plus';
import MinusIcon from 'components/svg/Minus';

const iconSize = {
  width: 30,
  height: 30,
};

function AccordionItem({ classes, text, isOpen, title, uuid, handleClick }) {
  return (
    <div
      className={classNames(classes.item, { [classes.isOpen]: isOpen })}
      onClick={handleClick(uuid, title)}
      role="presentation"
      data-testid="accordion-item"
    >
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.icon}>{isOpen ? <MinusIcon style={iconSize} /> : <PlusIcon style={iconSize} />}</div>
      <p className={classes.content} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

AccordionItem.defaultProps = {
  text: null,
  handleClick: null,
  isOpen: false,
  title: null,
};

AccordionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  handleClick: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  uuid: PropTypes.string.isRequired,
};

const styles = {
  item: {
    position: 'relative',
    margin: '10px 0',
    maxHeight: 70,
    overflow: 'hidden',
    transition: 'all .35s ease-out',
    border: `1px solid ${grey100}`,
    backgroundColor: white,
    borderRadius: 3,
    cursor: 'pointer',
  },
  isOpen: {
    backgroundColor: grey100,
    maxHeight: '100vh',
  },
  title: {
    height: 36,
    lineHeight: '36px',
    margin: '17px 47px 17px 17px',
    fontSize: fontSizes.medium,
    ...ellipsis,
  },
  icon: {
    position: 'absolute',
    zIndex: 1,
    top: 20,
    right: 10,
    width: 30,
    height: 30,
  },
  content: {
    margin: '0px 17px 17px',
    fontSize: fontSizes.small,
  },
};

export default injectStyles(styles)(AccordionItem);
