import PropTypes from 'prop-types';
import { useFetchData } from 'components/hooks';

export default function Query({ children, contentTypeId, slug }) {
  const { isLoading, data, error } = useFetchData({ contentTypeId, slug });

  if (!slug || !contentTypeId) {
    return children({});
  }

  return children({ data, isLoading, error });
}

Query.propTypes = {
  contentTypeId: PropTypes.string,
  slug: PropTypes.string,
};

Query.defaultProps = {
  contentTypeId: null,
  slug: null,
};
