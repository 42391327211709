import { connect } from 'react-redux';
import { withAnalytics } from 'analytics';
import { loadStation, stop } from 'actions/player-actions';
import { getIsLoading, getIsCurrentStationPlaying } from 'store/reducers/player';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import Card from './Card';

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: getIsLoading(state),
    isPlaying: getIsCurrentStationPlaying(state, {
      station: { slug: ownProps.playStationSlug },
    }),
  };
};

const actionWithAnalytics = (action, { source, ...station }) =>
  withAnalytics(action, {
    player_name: CONTENT_TYPES.CARD,
    link_id: CONTENT_TYPES.CARD,
    content_id: source || null,
  })(station);

const mapDispatchToProps = {
  onActionWithAnalytics: actionWithAnalytics,
};

const mergeProps = ({ isLoading, isPlaying }, { onActionWithAnalytics }, ownProps) => ({
  ...ownProps,
  isLoading: isLoading && isPlaying,
  isPlaying,
  onStop: () => onActionWithAnalytics(stop, { source: ownProps.collectionTitle || null }),
  onLoad: (station) => {
    onActionWithAnalytics(loadStation, {
      ...station,
      source: ownProps.collectionTitle || null,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Card);
