import { connect } from 'react-redux';
import { skipOnDemandClipForward, skipOnDemandClipBackward } from 'actions/player-actions';
import SkipControl from './SkipControl';
import { getMobileLightVariant } from 'util/index';

const mapStateToProps = () => ({
  lightVariant: getMobileLightVariant(),
});

const mapDispatchToProps = (dispatch) => ({
  skipForward: (skipTime) => {
    dispatch(skipOnDemandClipForward(skipTime));
  },
  skipBackward: (skipTime) => {
    dispatch(skipOnDemandClipBackward(skipTime));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SkipControl);
