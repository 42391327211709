import * as cssVariable from 'cssVariables';

export default {
  bottomInfo: {
    position: 'relative',
    height: 22,
    display: 'block',
    maxWidth: '100%',
    marginTop: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    transition: 'opacity 800ms ease',
    color: cssVariable.grey500,
  },
  nowPlaying: {
    display: 'inline',
    fontSize: cssVariable.fontSizes.small,
    letterSpacing: cssVariable.letterSpacing.small,
    textTransform: 'capitalize',
    '& > svg': {
      width: 18,
      height: 18,
    },
  },
  nowPlayingHidden: {
    display: 'block',
    position: 'absolute',
    visibility: 'hidden',
  },
};
