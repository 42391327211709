import { queryRequest } from './client';
import { getSearch } from './queries';
import { isEmpty } from 'util/index';
import { parseContentModal } from './parsers/overview';
import CONTENT_TYPES from 'globalConst/contentTypes-const';

const parseSearchData = (searchData) => {
  let sections = [];

  if (!searchData) {
    return { sections };
  }

  searchData.searchResults?.results.items.forEach((section) => {
    if (section.references.length > 0) {
      const parsedSection = parseContentModal(
        {
          ...section.references[0],
          sectionTitle: section.title,
        },
        section.references.length > 0 && section.references[0].type,
        section.config,
        section.options
      );

      if (
        (!isEmpty(parsedSection) && !isEmpty(parsedSection.items)) ||
        parsedSection.contentTypeId === CONTENT_TYPES.RECENTLY_PLAYED_STATIONS_COLLECTION ||
        parsedSection.showEmptyState
      ) {
        const doItemsHaveReferences = parsedSection.items.every((item) => {
          return item.reference !== null;
        });

        if (doItemsHaveReferences) {
          sections.push(parsedSection);
        }
      }
    }
  });
  return { sections };
};

/**
 * Fetch search results from the universal api using Algolia
 * @param {string} slug - search term
 * @returns {Array} collections
 */
export const fetchSearchAlgolia = async ({ slug }) => {
  const { data } =
    (await queryRequest({
      query: getSearch,
      variables: { query: slug },
    })) || {};
  return parseSearchData(data);
};
