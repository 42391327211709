import { queryRequest } from './client';
import { getCampaign } from './queries';
import Campaign from '../models/Campaign';

/**
 * Fetch a single campaign from the universal api
 * @param {object} config
 * @param {string} config.slug
 * @returns {Object} Campaign
 */
export const fetchCampaign = async ({ slug }) => {
  const { data } = await queryRequest({ query: getCampaign, variables: { slug } });
  if (data && data.campaign) {
    return new Campaign(data.campaign);
  }
  return null;
};
