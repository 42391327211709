import { Image, UniversalClip, UniversalMedia } from 'api/interfaces';
import { Genre } from 'api/interfaces/Genre';
import parseConfig from 'util/parseConfig';
import { convertSecondsToHMS } from 'util/time';
import Podcast, { RelatedPodcast } from './Podcast';
import Station from './Station';

export type Duration = {
  hours: number;
  minutes: number;
  seconds: number;
};

/**
 * @class
 * A representation of a PodcastEpisode
 *
 * @param {object} episode - episode from graphQL response
 * @param {object} podcast - podcast content model from graphQL response
 */

export interface Progress {
  completed?: boolean;
  durationLength?: number;
  contentLength?: number;
}
export default class PodcastEpisode implements UniversalClip {
  broadcastDate?: string;
  contentTypeId?: string;
  durationSeconds?: number;
  images?: Image[];
  media?: UniversalMedia[];
  type?: string;
  id?: string;
  name?: string;
  slug?: string;
  podcastTitle?: string;
  author?: string;
  title?: string;
  subTitle?: string;
  description?: string;
  audioUrl?: string;
  duration?: Duration;
  imageUrl?: string;
  publishDate?: string;
  // eslint-disable-next-line
  config?: any;
  showNewLabel?: boolean;
  progress?: Progress;
  descriptionMarkdown?: string;
  isClip?: boolean;
  isClipList?: boolean;
  rowNumber?: number;
  rows?: number;
  showNumbers?: boolean;
  reference?: {
    id: string;
    contentTypeId?: string;
    slug: string;
    author?: string;
    title: string;
    type: string;
    labels?: Array<string>;
    subTitle?: string;
    publisher?: string;
    feedUrl?: string;
  };
  mainPodcastTitle?: string;
  pageUrl?: string;
  pageName?: string;
  podcast?: {
    id: string;
    uuid?: string;
    author: string;
    slug: string;
    title: string;
    description: string;
    contentTypeId: string;
    relatedPodcast: RelatedPodcast[];
    genres: Genre[];
    feedUrl?: string;
  };
  episodeNumber?: number;
  show?: {
    station: Station;
  };

  constructor(item?: UniversalClip, podcast?: Podcast, isClipList?: boolean) {
    if (!item || !podcast) {
      return null;
    }

    const episode = <UniversalClip>item;

    this.id = episode.id;
    this.name = episode.title;
    this.slug = episode.slug;
    this.podcastTitle = episode.podcastTitle;
    this.title = episode.title;
    this.subTitle = episode.subTitle;
    this.description = episode.description;
    this.author = episode?.reference?.title || null;
    this.media = episode.media;
    this.audioUrl = episode.media && !!episode.media.length && episode.media[0].uri;
    this.duration = convertSecondsToHMS(episode.durationSeconds);
    this.durationSeconds = episode?.durationSeconds;
    this.imageUrl = (podcast?.image && podcast?.image?.url) || (episode.images && episode.images[0].uri);
    this.publishDate = episode.publishDate;
    this.showNewLabel = episode.showNewLabel;
    this.progress = episode.progress;
    this.contentTypeId = episode.type;
    this.descriptionMarkdown = episode.descriptionMarkdown;
    this.reference = episode.reference;
    this.config = parseConfig(episode.config);
    this.isClipList = isClipList;
    this.episodeNumber = episode?.episode;

    if (this.reference && this.reference.type === 'podcast') {
      this.mainPodcastTitle = this.reference.title;
    }

    if (this.isClipList) {
      this.showNumbers = this.config?.ListNumber;
      this.rows = this.config?.Rows;
    }

    // Podcast specific props
    this.pageUrl = podcast.slug;
    this.pageName = podcast.title;
    // add reference to Podcast
    this.podcast = {
      id: podcast.id,
      uuid: podcast.id,
      author: podcast.subTitle,
      slug: podcast.slug,
      title: podcast.title,
      description: podcast.description,
      contentTypeId: podcast.type,
      relatedPodcast: podcast.relatedPodcast,
      genres: podcast.genres,
      feedUrl: podcast?.feedUrl,
    };
    // Make this class read-only
  }
}
