import deepmerge from 'deepmerge';

function round(value) {
  return Math.round(value * 1e5) / 1e5;
}

export function createTypography(palette, typography, breakpoints) {
  const {
    fontFamily = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyLight = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyRegular = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyRegularNarrow = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyRegularNarrowItalic = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyBold = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyBoldNarrow = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyExtraBold = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyHeavy = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyHeavyItalic = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyHeavyAlt1 = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontFamilyBoldAlt1 = '"Helvetica", "Arial", sans-serif', // Fallback fonts
    fontSize = 16, // 16px is the default font-size used by browsers on the html element.
    allVariants = {
      fontStyle: 'normal',
      fontStretch: 'normal',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      fontSmoothing: 'antialiased',
    },
    ...other
  } = typeof typography === 'function' ? typography(palette) : typography;

  function pxToEm(value) {
    return `${value / fontSize}em`;
  }

  return deepmerge(
    {
      pxToEm,
      round,
      fontFamily,
      fontFamilyLight,
      fontFamilyRegular,
      fontFamilyRegularNarrow,
      fontFamilyRegularNarrowItalic,
      fontFamilyBold,
      fontFamilyBoldNarrow,
      fontFamilyExtraBold,
      fontFamilyHeavy,
      fontFamilyHeavyAlt1,
      fontFamilyHeavyItalic,
      fontFamilyBoldAlt1,
      fontSize,
      allVariants,

      // TYPOGRAPHY STYLES
      campaignHeader: {
        fontFamily: fontFamilyHeavyAlt1,
        fontSize: pxToEm(64),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('lg')]: {
          fontSize: pxToEm(46),
        },

        [breakpoints.down('md')]: {
          fontSize: pxToEm(46),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(28),
        },
      },

      headerLarge: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(28),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(26),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(24),
        },
      },

      headerMedium: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(28),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(26),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(24),
        },
      },

      frequencySearchClearText: {
        fontFamily: fontFamilyRegular,
        [breakpoints.down('sm')]: {
          fontSize: pxToEm(10),
        },
      },

      headerSmall: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(20),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
        },
      },

      articleHeadline: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(38),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(32),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(24),
        },
      },

      notFoundTitle: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(28),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(26),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(24),
        },
      },

      headlineLarge: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(30),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(24),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(20),
        },
      },

      loginProfileName: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(20),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
        },
      },

      headlineMedium: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(20),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
        },
      },

      headlineMediumRelatedArticle: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(20),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
        },
      },

      correctGuesses: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(20),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
        },
      },

      headlineSmall: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(16),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(14),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(12),
        },
      },
      buttonCta: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(20),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
        },
      },

      titleMedium: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(24),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(20),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(18),
        },
      },

      videoListTitle: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(28),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(26),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(24),
        },
      },

      signatureHeadlineLarge: {
        fontFamily: fontFamilyBoldAlt1,
        fontSize: pxToEm(38),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(32),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(24),
        },
      },

      signatureInitial: {
        fontFamily: fontFamilyBoldAlt1,
        fontSize: pxToEm(38),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(32),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(24),
        },
      },

      signatureHeadlineMedium: {
        fontFamily: fontFamilyHeavyAlt1,
        fontSize: pxToEm(24),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(20),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(18),
        },
      },

      tag: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(14),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(12),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(10),
        },
      },

      infoMedium: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(24),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(20),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(18),
        },
      },

      paragraph: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(20),
        lineHeight: '30px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
          lineHeight: '28px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
          lineHeight: '24px',
        },
      },

      paragraphBold: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(20),
        lineHeight: '30px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
          lineHeight: '28px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
          lineHeight: '24px',
        },
      },

      paragraphLink: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(19),
        lineHeight: '36px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(16),
          lineHeight: '28px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(15),
          lineHeight: '26px',
        },
      },

      infoSmall: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(14),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(12),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(10),
        },
      },

      inputMedium: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(30),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(24),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(20),
        },
      },

      inputSmall: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(18),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(16),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(14),
        },
      },

      caption: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(16),
        lineHeight: '20px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(14),
          lineHeight: '18px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(12),
          lineHeight: '16px',
        },
      },

      labelLarge: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(30),
        lineHeight: '37px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(20),
          lineHeight: '24px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
          lineHeight: '19px',
        },
      },

      labelMedium: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(16),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(14),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(12),
        },
      },

      labelSmall: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(14),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(12),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(10),
        },
      },

      link: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(20),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
        },
      },

      linkSmall: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(14),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(12),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(10),
        },
      },

      trackRegular: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(16),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(14),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(12),
        },
      },

      trackBold: {
        fontFamily: fontFamilyBoldAlt1,
        fontSize: pxToEm(18),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(16),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(14),
        },
      },

      guessIndex: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(18),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(16),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(14),
        },
      },

      contactItem: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(18),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(16),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(14),
        },
      },

      date: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(16),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(14),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(12),
        },
      },

      dateClip: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(20),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
        },
      },

      trackDescription: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(14),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(12),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(10),
        },
      },

      quote: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(30),
        lineHeight: 1,
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(24),
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(20),
        },
      },

      voteTrackTitle: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(24),
        lineHeight: '20px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(20),
          lineHeight: '18px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
          lineHeight: '18px',
        },
      },

      voteTrackArtist: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(16),
        lineHeight: '20px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(14),
          lineHeight: '18px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(10),
          lineHeight: '12px',
        },
      },
      voteTrackTitleSmall: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(20),
        lineHeight: '24px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(16),
          lineHeight: '18px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(12),
          lineHeight: '16px',
        },
      },

      voteTrackArtistSmall: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(12),
        lineHeight: '14px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(10),
          lineHeight: '12px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(8),
          lineHeight: '10px',
        },
      },

      voteButtonCta: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(26),
        lineHeight: '28px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(20),
          lineHeight: '24px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
          lineHeight: '11px',
        },
      },

      voteTitle: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(44),
        lineHeight: '1.02em',
        ...allVariants,

        [breakpoints.down('lg')]: {
          fontSize: pxToEm(32),
          lineHeight: '1.03em',
        },

        [breakpoints.down('md')]: {
          fontSize: pxToEm(32),
          lineHeight: '1.03em',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(24),
          lineHeight: '1.04em',
        },
      },

      voteText: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(22),
        lineHeight: '28px',
        ...allVariants,

        [breakpoints.down('lg')]: {
          fontSize: pxToEm(18),
          lineHeight: '24px',
        },

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
          lineHeight: '24px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(12),
          lineHeight: '16px',
        },
      },

      voteTextBold: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(22),
        lineHeight: '28px',
        ...allVariants,

        [breakpoints.down('lg')]: {
          fontSize: pxToEm(18),
          lineHeight: '24px',
        },

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
          lineHeight: '24px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(12),
          lineHeight: '16px',
        },
      },

      voteByline: {
        fontFamily: fontFamilyBold,
        fontSize: pxToEm(18),
        lineHeight: '20px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(14),
          lineHeight: '18px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(10),
          lineHeight: '14px',
        },
      },

      voteBylineTelephone: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(14),
        lineHeight: '10px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(12),
          lineHeight: '9px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(10),
          lineHeight: '7px',
        },
      },

      telephoneInputText: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(18),
        lineHeight: '18px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(16),
          lineHeight: '16px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(14),
          lineHeight: '14px',
        },
      },

      artistFirstTop50: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(22),
        lineHeight: '18px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(22),
          lineHeight: '18px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(16),
          lineHeight: '13px',
        },
      },

      trackFirstTop50: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(35),
        lineHeight: '26px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(35),
          lineHeight: '26px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(21),
          lineHeight: '18px',
        },
      },

      artistRestTop50: {
        fontFamily: fontFamilyRegular,
        fontSize: pxToEm(18),
        lineHeight: '18px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(18),
          lineHeight: '18px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(13),
          lineHeight: '13px',
        },
      },

      trackRestTop50: {
        fontFamily: fontFamilyHeavy,
        fontSize: pxToEm(25),
        lineHeight: '26px',
        ...allVariants,

        [breakpoints.down('md')]: {
          fontSize: pxToEm(25),
          lineHeight: '26px',
        },

        [breakpoints.down('sm')]: {
          fontSize: pxToEm(18),
          lineHeight: '18px',
        },
      },
    },
    other,
    {
      clone: false, // No need to clone deep
    }
  );
}
