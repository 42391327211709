import React, { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../PlayerButton.styles';
import Close from 'components/svg/Close';
import { createUseStyles } from 'react-jss';
import Video from 'components/svg/Video';
import { PlayerButtonsContext } from '../../PlayerButtons';
import { useDispatch, useSelector } from 'react-redux';
import { getJWPlayerActive } from 'store/reducers/jwPlayer';
import { setIsJWPlayerActive } from 'actions/jwPlayer-actions';

const useStyles = createUseStyles(styles, { name: 'PlayerVideoButton' });

const PlayerVideoButton = ({ renderPopout, onPress }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isJWPlayerActive = useSelector((state) => getJWPlayerActive(state));
  const videoOpenedByIconRef = useRef(false);

  const { displayPopup, hidePopup } = useContext(PlayerButtonsContext);

  useEffect(() => {
    // if we call handleToggleVideo by clicking on video icon ref
    // it will change `isJWPlayerActive` state and it will trigger this useEffect
    // so we want to prevent extra useEffect call by videoOpenedByIconRef.current check
    if (!isJWPlayerActive || videoOpenedByIconRef.current) {
      videoOpenedByIconRef.current = false;
      return;
    }
    handlePlayVideo();
  }, [isJWPlayerActive]);

  useEffect(() => {
    return () => {
      handleCloseVideo();
    };
  }, []);

  const handleToggleVideo = () => {
    const nextVal = !isJWPlayerActive;
    dispatch(setIsJWPlayerActive(nextVal));
    onPress(nextVal);
    videoOpenedByIconRef.current = true;

    if (nextVal) {
      displayPopup('video', renderPopout(closeBtn, handleCloseVideo));
      return;
    }
    hidePopup('video');
  };

  const handlePlayVideo = () => {
    onPress(true);
    displayPopup('video', renderPopout(closeBtn, handleCloseVideo));
  };

  const handleCloseVideo = (event) => {
    dispatch(setIsJWPlayerActive(false));
    if (event?.type === 'click') {
      onPress(false);
    }
    hidePopup('video');
  };

  const closeBtn = (
    <div onClick={handleCloseVideo} className={classes.close}>
      <Close inherent />
    </div>
  );

  return (
    <div className={classNames(classes.container, classes.hideOnSmal)}>
      <div className={classes.separator} />
      <div
        data-testid="player-bottom-player-video-btn"
        className={classNames(
          {
            [classes.active]: isJWPlayerActive,
          },
          classes.content
        )}
        onClick={handleToggleVideo}
      >
        <div className={classes.icon}>
          <Video />
        </div>
        <span className={classes.title}>Video</span>
      </div>
    </div>
  );
};

PlayerVideoButton.propTypes = {
  renderPopout: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
};

PlayerVideoButton.defaultProps = {
  renderPopout: () => {},
  onPress: () => {},
};

export default PlayerVideoButton;
