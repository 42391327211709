import { DisplayLayout } from 'components/Collection/Collection.const';
import { TILE_SIZES } from 'globalConst/const';
import Tag, { UniversalTag } from './Tag';
import Collection from 'api/models/Collection';
import { isEmpty } from 'util/index';
import { UniversalCollection, UniversalConfig } from '../interfaces';

interface UniversalTagCollection extends UniversalCollection {
  getTags: {
    tags: Array<UniversalTag>;
  };
  items: Array<UniversalTag>;
}

/**
 * @class
 * A representation of a TagCollection
 *
 * @param {object} collection - collection model
 * @param {object} config - config entries
 * @param {object} options - options e.g. no-playouts, highlighted...
 */
export default class TagCollection extends Collection {
  items: Array<Tag>;
  tileSizes: string;

  constructor(collection: UniversalCollection, config: UniversalConfig[], options: string[]) {
    super(collection, config, options);
    if (isEmpty(collection)) {
      return null;
    }

    const tagCollection = <UniversalTagCollection>collection;

    this.items = tagCollection?.getTags?.tags.map((genre) => new Tag(genre)).filter((genre) => genre && genre.pageLink);

    //Support for deprecated fields
    if (tagCollection.items) {
      this.items =
        tagCollection.items &&
        tagCollection.items.map((genre) => new Tag(genre)).filter((genre) => genre && genre.pageLink);
    }

    this.tileSizes = TILE_SIZES.MEDIUM;

    const rows = parseInt(this.config.Rows, 10);
    this.rows = !isNaN(rows) ? rows : 1;

    const tileSizes = this.config.TileSize;
    this.tileSizes = Object.values(TILE_SIZES).includes(tileSizes) ? tileSizes : TILE_SIZES.MEDIUM;

    const display = this.config.PresentationType;
    if (display) {
      this.display = display === DisplayLayout.SWIMLANE ? DisplayLayout.CAROUSEL : DisplayLayout.TILES;
    }
    // Make this class read-only
    Object.freeze(this);
  }
}
