import { connect } from 'react-redux';

import Search from './Search';
import { setActive, determineSearchState, searchAlgolia } from 'actions/search-actions';
import { getSearchIsActive } from 'store/reducers/search';

const mapStateToProps = (state) => ({
  isActive: getSearchIsActive(state),
});

const mapDispatchToProps = {
  onSetActive: setActive,
  onDetermineSearchState: determineSearchState,
  onSearchAlgolia: searchAlgolia,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
