import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { navigationEvents } from 'analytics/analytics-const';
import { closeNavigation, openNavigation, toggleNavigation } from 'actions/navigation-actions';
import { getIsOpen, getNavigationItems, getMobileLightNavigation } from 'store/reducers/navigation';
import { getBrowser } from 'store/reducers/browser';
import getMobileLightVariant from 'util/getMobileLightVariant';
import { getModalOpen } from 'store/reducers/modal';
import Navigation from './Navigation';
import { isTouch } from 'util/device';

const mapStateToProps = (state) => {
  const mobileLightVariant = getMobileLightVariant();
  const browser = getBrowser(state);
  return {
    isOpen: getIsOpen(state),
    navigationItems: mobileLightVariant ? getMobileLightNavigation(state) : getNavigationItems(state),
    browser,
    modalIsOpen: getModalOpen(state),
    isCollapsible: !isTouch && browser.down.lg,
  };
};

const mapDispatchToProps = {
  closeNavigation,
  openNavigation,
  onToggleNavigation: () => {
    return toggleNavigation(navigationEvents.mainNav);
  },
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
