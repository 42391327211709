import * as cssVariable from 'cssVariables';
import tileStyles from 'tile-components-styles';

export default (theme) => ({
  wrapper: {
    position: 'relative',
  },
  listItemWrapper: {
    position: 'relative',
    transition: 'transform 300ms ease',

    '&:hover': {
      transform: 'scale(1.05)',
    },
    '&:hover $playControl': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 45,
      height: 45,
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'translate(-50%, -50%)',
    },
  },
  listItemWrapperTouch: {
    position: 'relative',
  },
  listItem: {
    position: 'relative',
    display: 'flex',
    minHeight: 120,
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none !important',
  },
  listItemPlaceholder: {
    backgroundColor: cssVariable.grey100,
    minHeight: 100,
    marginBottom: 10,
    marginTop: 10,
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    borderRadius: 4,
    backgroundColor: cssVariable.grey500,
  },
  image: {
    width: '100%',
    height: 'auto',
    paddingTop: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: 4,
  },
  imageGenreOrMood: {
    width: 100,
    height: 72,
    borderRadius: 4,
  },
  imageShow: {
    width: 100,
    height: 135,
    borderRadius: 4,
  },
  title: {
    position: 'absolute',
    bottom: 5,
    width: '100%',
    margin: 0,
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.white,
    fontWeight: cssVariable.fontWeights.bold,
    textTransform: 'uppercase',
    textAlign: 'center',
    lineHeight: 1,
  },
  playControl: {
    display: 'none',
  },
  playControlTouch: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 45,
    height: 45,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)',
    opacity: '0',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  contentTitle: {
    color: theme?.palette?.text?.primary,
    fontSize: cssVariable.fontSizes.small,
    fontWeight: cssVariable.fontWeights.bold,
    margin: 0,
    paddingTop: 3,
  },
  contentAuthor: {
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.grey500,
    margin: 0,
    paddingBottom: 5,
  },
  contentTypeId: {
    fontSize: cssVariable.fontSizes.small,
    fontWeight: cssVariable.fontWeights.bold,
    color: cssVariable.grey500,
    textTransform: 'capitalize',
  },
  remove: {
    position: 'absolute',
    right: -5,
    top: -5,
    width: 30,
    height: 30,
    borderRadius: 15,
    fontSize: cssVariable.fontSizes.small,
    zIndex: 1,
    backgroundColor: cssVariable.grey600,
    border: 'none',
    transition: 'all 0.3s ease',
    cursor: 'pointer',

    '& > svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 30,
      height: 30,
      color: cssVariable.white,
      transition: 'all 0.3s ease',
    },
    '&:hover > svg': {
      color: cssVariable.green500,
    },
    '&:focus': {
      transform: 'scale(0.9)',
    },
  },
  triangles: {
    ...tileStyles.trianglesDefault,
  },
  triangleBig: {
    ...tileStyles.triangleBigDefault,
  },
  triangleSmall: {
    ...tileStyles.triangleSmallDefault,
  },
});
