import { Action } from 'redux';
import { modalActions } from './actions-const';

export function setModalOpen(): Action<string> {
  return { type: modalActions.SET_MODAL_OPEN };
}

export function setModalClosed(): Action<string> {
  return { type: modalActions.SET_MODAL_CLOSE };
}

export function setModalStandaloneOpen(): Action<string> {
  return { type: modalActions.SET_MODAL_STANDALONE_OPEN };
}

export function setModalStandaloneClosed(): Action<string> {
  return { type: modalActions.SET_MODAL_STANDALONE_CLOSE };
}
