import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as analytics from 'analytics';
import * as cssVariables from 'cssVariables';
import { injectStyles } from 'injectStyles';
import { isIE11andLower, isTouch } from 'util/device';
import { SCROLL_CONTAINER_ID } from 'globalConst/const';
import TabsHeader from './TabsHeader';
import zenscroll from 'zenscroll';
import { DEFAULT_HEADER_HEIGHT } from 'components/PageHeader/PageHeader.const';
import ContentTypeComponent from 'components/ContentTypeComponent';
import { isEmpty } from 'util/index';

class Tabs extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    hasAnchorTabs: PropTypes.bool,
    hasMobileHeader: PropTypes.bool,
    indexableTabs: PropTypes.bool,
    pageSlug: PropTypes.string,
    subSlug: PropTypes.string,
    tabs: PropTypes.array,
    setCurrentPageSlug: PropTypes.func,
    isModalOpen: PropTypes.bool,
  };

  static defaultProps = {
    hasAnchorTabs: false,
    hasMobileHeader: false,
    indexableTabs: false,
    pageSlug: null,
    subSlug: null,
    tabs: [],
    setCurrentPageSlug: null,
    isModalOpen: false,
  };

  state = {
    activeTab: 0,
    activeTabId: null,
    isStuck: false,
    lastTabActive: false,
  };

  contentItemNodes = {};

  tabNodes = {};

  pushingDown = false;

  isDragging = false;

  dragStartPosition = {
    scrollLeft: 0,
    startX: 0,
  };
  componentContainerRef = {};

  tabsStickPosition = isTouch ? 0 : DEFAULT_HEADER_HEIGHT;
  scrollContainer = isTouch ? window : document.getElementById(SCROLL_CONTAINER_ID);

  componentDidMount() {
    const { subSlug } = this.props;
    this.tabWrapperOffsetTop = this.componentContainerRef.offsetTop;
    this.contentItemLength = Object.keys(this.contentItemNodes).length;

    if (subSlug) {
      this.updateInitialTab();
    }

    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.scrollChangeHandler);
    }
  }

  componentDidUpdate(prevProps) {
    const { subSlug, isModalOpen, hasAnchorTabs } = this.props;
    if (subSlug && subSlug !== prevProps.subSlug) {
      this.updateInitialTab();
    }

    if (prevProps.isModalOpen && !isModalOpen && hasAnchorTabs) {
      this.scrollChangeHandler();
    }
  }

  componentWillUnmount() {
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener('scroll', this.scrollChangeHandler);
    }
  }

  updateInitialTab = () => {
    const { hasAnchorTabs, subSlug, tabs } = this.props;
    const index = tabs.findIndex(({ slug }) => slug === subSlug);

    if (index !== -1) {
      if (hasAnchorTabs) {
        setTimeout(this.scrollToAnchorTag, 200, tabs[index].slug, tabs[index].title);
      } else {
        this.setState({ activeTab: index });
      }
    }
  };

  scrollChangeHandler = () => {
    this.setTabActiveOnScroll();
  };

  setContentRefs = (ref) => {
    if (!ref) return false;
    this.contentItemNodes[ref.id] = { ref };
    return null;
  };

  setContentContainerRef = (ref) => {
    this.contentContainerRef = ref;
  };

  setComponentContainerRef = (ref) => {
    this.componentContainerRef = ref;
  };

  handleMenuClick = (index) => (event) => {
    event.preventDefault();
    const { indexableTabs, pageSlug, hasAnchorTabs, tabs, setCurrentPageSlug } = this.props;

    // const { metadata, slug, title } = tabs[index] || {};
    const { slug, title } = tabs[index] || {};

    if (indexableTabs) {
      // Because meta data is currently not provided for the tabs we use the default page title
      //document.title = (metadata || {}).title || title;
      setCurrentPageSlug(pageSlug, slug);
      window.history.pushState(null, title, `${__URL_PREFIX__}/${pageSlug}/${slug}`);
    }

    analytics.trackContentNavigate(title);
    if (['Radiomerken', 'Alle Genres', 'Podcasts'].includes(title)) {
      analytics.trackHomepageNavigation(title);
    }
    if (hasAnchorTabs) {
      this.scrollToAnchorTag(slug, title);
    } else {
      this.setState({ activeTab: index });
    }
  };

  scrollToAnchorTag = (slug) => {
    if (this.isDragging) return;

    const contentItemTop = this.contentItemNodes[slug].ref.getBoundingClientRect().top;
    const containerOffsetTop = this.contentContainerRef.offsetTop + DEFAULT_HEADER_HEIGHT;

    const scrollContainer = isTouch
      ? document.getElementsByTagName('html')[0]
      : document.getElementById(SCROLL_CONTAINER_ID);

    const scroller = zenscroll.createScroller(scrollContainer);

    scroller.toY(contentItemTop - containerOffsetTop + scroller.getY());
  };

  setTabActiveOnScroll = () => {
    // const { indexableTabs, tabs } = this.props;
    const { scrollTop, scrollY } = this.scrollContainer;
    const distanceToTop = (scrollTop || scrollY) + DEFAULT_HEADER_HEIGHT;

    Object.entries(this.contentItemNodes).forEach(([, node], index) => {
      const nodeTop = node.ref.offsetTop + this.tabWrapperOffsetTop;
      const nodeBottom = node.ref.offsetTop + node.ref.getBoundingClientRect().height + this.tabWrapperOffsetTop;

      if (distanceToTop > nodeTop && distanceToTop < nodeBottom) {
        this.setState((prevState) => {
          if (prevState.activeTab === index || prevState.activeTabId === node.ref.id) return false;
          // if (indexableTabs) {
          //   Because meta data is currently not provided for the tabs we use the default page title
          //   const { metadata, title } = tabs[index] || {};
          //   document.title = (metadata || {}).title || title;
          // }
          return {
            activeTab: index,
            activeTabId: node.ref.id,
            lastTabActive: this.contentItemLength === index + 1,
          };
        });
      }
    });
  };

  render() {
    const { classes, hasAnchorTabs, tabs, pageSlug, indexableTabs, isModalOpen } = this.props;
    const { activeTabId, activeTab, lastTabActive } = this.state;

    const headerProps = {
      activeTabId,
      activeTab,
      handleMenuClick: this.handleMenuClick,
      hasAnchorTabs,
      indexableTabs,
      isIE11andLower,
      lastTabActive,
      pageSlug,
      tabs,
      isModalOpen,
    };

    return (
      <div data-testid="tabs" ref={this.setComponentContainerRef} className={classes.tabsWrapper}>
        <TabsHeader {...headerProps} />
        <div className={classes.contentContainer} ref={this.setContentContainerRef}>
          {hasAnchorTabs
            ? !isEmpty(tabs) &&
              tabs.map((item) => (
                <div id={item.slug} ref={this.setContentRefs} key={`${item.uuid}`} className={classes.contentItem}>
                  {item.content.map((contentItem) => (
                    <ContentTypeComponent key={`${contentItem.uuid}`} {...contentItem} />
                  ))}
                </div>
              ))
            : !isEmpty(tabs) &&
              tabs[activeTab] &&
              tabs[activeTab].content.map((item) => <ContentTypeComponent key={`${item.uuid}`} {...item} />)}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  tabsWrapper: {
    position: 'relative',
    marginTop: cssVariables.gutter,
    marginBottom: cssVariables.gutter,
  },

  contentContainer: {
    position: 'relative',
    overflowAnchor: 'none',
    zIndex: 0,
    paddingBottom: 210,
  },

  contentItem: {
    marginBottom: 40,
    position: 'relative',
    '&:last-of-type': {
      marginBottom: '50vh',
    },
  },

  [theme.breakpoints.down('sm')]: {
    contentItem: {
      '&:last-of-type': {
        marginBottom: '30vh',
      },
    },
  },
});

export default injectStyles(styles)(Tabs);
