// Styling that is being shared by CollectionDesktop, CollectionSwipeable and CollectionModal
import * as cssVariable from '../../cssVariables';

export default (theme) => ({
  collectionWrapper: {
    // overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    margin: -(cssVariable.gutter / 2),
  },
  collectionWrapperList: {
    margin: 0,
  },
  collection: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexWrap: 'wrap',
  },
  collectionModal: {
    paddingBottom: 30,
  },
  collectionItem: {
    flex: '0 1 auto',
  },
  tileView: {
    padding: cssVariable.gutter / 2,
    flex: '0 1 auto',
    width: '10%',
  },
  tileListView: {
    flex: '0 1 auto',
    width: '10%',
  },
  tileView1Items: {
    width: `100%`,
  },
  tileView2Items: {
    width: `${100 / 2}%`,
  },
  tileView3Items: {
    width: `${100 / 3}%`,
  },
  tileView4Items: {
    width: `${100 / 4}%`,
  },
  tileView5Items: {
    width: `${100 / 5}%`,
  },
  tileView6Items: {
    width: `${100 / 6}%`,
  },
  tileView7Items: {
    width: `${100 / 7}%`,
  },
  tileView8Items: {
    width: `${100 / 8}%`,
  },
  tileView9Items: {
    width: `${100 / 9}%`,
  },
  tileView10Items: {
    width: `${100 / 10}%`,
  },
  tileView11Items: {
    width: `${100 / 11}%`,
  },
  tileView12Items: {
    width: `${100 / 12}%`,
  },
  tileView13Items: {
    width: `${100 / 13}%`,
  },
  tileView14Items: {
    width: `${100 / 14}%`,
  },

  listView: {
    padding: '2px 0',
    width: '100%',
  },
  listViewSimple: {
    padding: '2px 0',
    width: '100%',
  },
  listViewGradientFullWidth: {
    padding: '5px 0',
    width: '100%',
  },
  listViewGradientHalfWidth: {
    padding: '5px',
    width: '50%',
  },
  swipe: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    marginRight: -40,
  },
  collectionSwipe: {
    flexWrap: 'nowrap',
    '&:after': {
      // Add right padding to swipe collection
      content: '""',
      display: 'block',
      width: 35,
      flex: '0 0 auto',
    },
  },
  tileViewSwipeable: {
    padding: cssVariable.gutter / 2,
    flex: '0 0 auto',
  },
  tileView1ItemsSwipeable: {
    width: `${96 / 1}%`,
  },
  tileView2ItemsSwipeable: {
    width: `${96 / 2}%`,
  },
  tileView3ItemsSwipeable: {
    width: `${96 / 3}%`,
  },
  tileView4ItemsSwipeable: {
    width: `${96 / 4}%`,
  },
  tileView5ItemsSwipeable: {
    width: `${96 / 5}%`,
  },
  tileView6ItemsSwipeable: {
    width: `${96 / 6}%`,
  },
  tileView7ItemsSwipeable: {
    width: `${96 / 7}%`,
  },
  tileView8ItemsSwipeable: {
    width: `${96 / 8}%`,
  },
  tileView9ItemsSwipeable: {
    width: `${96 / 9}%`,
  },
  tileView10ItemsSwipeable: {
    width: `${96 / 10}%`,
  },
  tileView11ItemsSwipeable: {
    width: `${96 / 11}%`,
  },
  tileView12ItemsSwipeable: {
    width: `${96 / 12}%`,
  },
  tileView13ItemsSwipeable: {
    width: `${96 / 13}%`,
  },
  tileView14ItemsSwipeable: {
    width: `${96 / 14}%`,
  },
  withDots: {
    position: 'relative',
    paddingBottom: 45,
  },
  cardDeck: {
    '&$swipe': {
      marginLeft: -40,
      marginRight: -40,
      [theme.breakpoints.down('sm')]: {
        marginLeft: -20,
        marginRight: -20,
      },
    },
    '& $collectionSwipe': {
      '&:before': {
        // Add left padding to swipe collection
        content: '""',
        display: 'block',
        width: 20,
        flex: '0 0 auto',
        [theme.breakpoints.down('sm')]: {
          width: 15,
        },
      },
      '&:after': {
        // Add right padding to swipe collection
        width: 20,
        [theme.breakpoints.down('sm')]: {
          width: 15,
        },
      },
    },
  },
  [theme.breakpoints.down('ml')]: {
    listViewGradientHalfWidth: {
      padding: '5px 0',
      width: '100%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    collectionSwipe: {
      '&:after': {
        width: 15,
      },
    },
    swipe: {
      marginRight: -20,
    },
  },
});

/*
 ** This is the original verison of the styling before the refactoring from glamouros to JSS
 ** The model has a transition but this does not work anymore, this was already the case before the refactoring.
 ** The code is kept here so we can use it as a reference when we fix the transition
 */

// const TRANSITION_DURATION = 300;
// const CollectionView = transition({
//   Component: responsive.section(
//     cssVariable.contentPadding, {
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       width: '100%',
//       background: 'white',
//       zIndex: 3, // kick over search
//       transformOrigin: 'center',
//       // transitionTimingFunction: 'ease',
//       // transitionDuration: `${TRANSITION_DURATION}ms`,
//       // transitionProperties: 'opacity, transform',
//       transition: 'all .3s ease',

//       // Add padding so content is not underneath the ios action bar
//       paddingBottom: '130px',
//     },
//     ({ browser }) =>
//       ({
//         height: !browser.down.md && '100%',
//       }),
//   ),
//   animations: {
//     enter: collectionCollapsedStyle,
//     enterActive: collectionExpandedStyle,
//     exit: collectionExpandedStyle,
//     exitActive: {
//       ...collectionCollapsedStyle,
//       transitionDuration: `${TRANSITION_DURATION / 2}ms !important`,
//     },
//   },
//   timeout: {
//     enter: TRANSITION_DURATION,
//     exit: TRANSITION_DURATION / 2,
//   },
// });
