import { navigationActions } from '../../actions/actions-const';

const initialState = {
  hasMobileHeader: false,
  isOpen: false,
  showScrollNavigation: true,
  navigationItems: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case navigationActions.SET_NAVIGATION:
      return { ...state, navigationItems: action.payload };
    case navigationActions.OPEN_NAVIGATION:
      return { ...state, isOpen: true };
    case navigationActions.CLOSE_NAVIGATION:
      return { ...state, isOpen: false };
    case navigationActions.SET_SCROLL_SHOW_NAVIGATION:
      return { ...state, showScrollNavigation: true };
    case navigationActions.SET_SCROLL_HIDE_NAVIGATION:
      return { ...state, showScrollNavigation: false };
    case navigationActions.SET_HAS_MOBILE_HEADER:
      return { ...state, hasMobileHeader: action.hasMobileHeader };
    case navigationActions.SET_MOBILE_LIGHT_NAVIGATION:
      return { ...state, mobileLightNavigationItems: action.payload };
    default:
      return state;
  }
}

/**
 * Given the current state, it returns the showScrollNavigation
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getShowScrollNavigation = (state) => state.navigation.showScrollNavigation;

/**
 * Given the current state, it returns whether or not the navigation is open
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIsOpen = (state) => state.navigation.isOpen;

/**
 * Given the current state, it returns the navigationItems
 *
 * @param {object} state
 * @returns {array}
 */
export const getNavigationItems = (state) => state.navigation.navigationItems;

/**
 * Returns the sub navigation items given a parent navigation id
 * @param {object} state
 */

/**
 * Given the current state, it returns whether or not the mobile header is rendered
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getHasMobileHeader = (state) => state.navigation.hasMobileHeader;

/**
 * Given the current state, it returns the mobile light navigation
 *
 * @param {object} state
 * @returns {array}
 */
export const getMobileLightNavigation = (state) => state.navigation.mobileLightNavigationItems;
