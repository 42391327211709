import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg viewBox="0 0 72 72">
    <path d="M30.8,57.9c-5,0-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1c5,0,9.1,4.1,9.1,9.1S35.8,57.9,30.8,57.9z M30.8,42.3c-3.6,0-6.6,2.9-6.6,6.6s2.9,6.6,6.6,6.6s6.6-2.9,6.6-6.6S34.4,42.3,30.8,42.3z" />
    <path d="M38.7,49.4c-0.7,0-1.3-0.6-1.3-1.3v-35c0-0.5,0.4-1,0.9-1.2c0.5-0.2,1.1,0,1.4,0.5L48.1,24c0.4,0.6,0.3,1.3-0.3,1.7c-0.6,0.4-1.3,0.3-1.7-0.3L40,17v31.1C40,48.8,39.4,49.4,38.7,49.4z" />
  </svg>
);

export default svgIcon(svg);
