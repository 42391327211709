import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';
import styles from './SocialShare.styles';
import { createUseStyles } from 'react-jss';
import { trackShareContent, trackOpenShareMenu } from 'analytics';
import { SHARE_MENU_ID } from 'globalConst/const';
import { isIos } from 'util/device';
import CrossIcon from 'components/svg/Cross';
import CopyIcon from 'components/svg/Copy';
import FacebookIcon from 'components/svg/Facebook';
import MailIcon from 'components/svg/Mail';
import ShareIcon from 'components/svg/Share';
import TwitterIcon from 'components/svg/Twitter';
import WhatsappIcon from 'components/svg/Whatsapp';
import ShareButton from './ShareButton';
import Button from 'components/Button';
import ButtonTypes from 'components/Button/Button.const';

const SHARE_ITEMS = [
  {
    type: 'facebook',
    name: 'Facebook',
    Button: FacebookShareButton,
    Icon: FacebookIcon,
  },
  {
    type: 'twitter',
    name: 'Twitter',
    Button: TwitterShareButton,
    Icon: TwitterIcon,
  },
  {
    type: 'whatsapp',
    name: 'WhatsApp',
    Button: WhatsappShareButton,
    Icon: WhatsappIcon,
  },
  { type: 'email', name: 'Email', Button: EmailShareButton, Icon: MailIcon },
];

interface SocialShare {
  description?: string;
  subject?: string;
  size?: number;
  color?: string;
  hasMobileHeader?: boolean;
  showNotification?: () => void;
  right?: boolean;
  params?: Record<string, string | number>;
  isInPodcastEpisodePage?: boolean;
  media_data?: {
    media_id?: string;
    media_program_name?: string;
    media_name?: string;
  };
  isBrandPage?: boolean;
}

interface handleToggleOutside extends MouseEvent {
  target: HTMLInputElement;
}

const useStyles = createUseStyles(styles, { name: 'SocialShare' });

const SocialShare = (props: SocialShare): JSX.Element => {
  const {
    description,
    subject,
    hasMobileHeader,
    showNotification,
    size,
    color,
    right = false,
    params,
    isInPodcastEpisodePage = false,
    media_data,
    isBrandPage,
  } = props;
  const [showSocialMenu, setShowSocialMenu] = useState(false);
  const [sharePortal, setSharePortal] = useState(null);

  const socialMenuDropwdown = useRef<HTMLDivElement>(null);
  const socialShareIconButton = useRef<HTMLButtonElement>(null);
  const classes = useStyles(props);

  const handleToggleOutside = (e: handleToggleOutside) => {
    if (
      socialMenuDropwdown &&
      socialMenuDropwdown.current &&
      !socialMenuDropwdown?.current?.contains(e.target) &&
      socialShareIconButton &&
      socialShareIconButton.current &&
      !socialShareIconButton.current.contains(e.target)
    ) {
      handleToggle();
    }
  };

  useEffect(() => {
    setSharePortal(document.getElementById(SHARE_MENU_ID));
    window.addEventListener('mousedown', handleToggleOutside);

    return () => {
      window.removeEventListener('mousedown', handleToggleOutside);
    };
  }, []);

  const handleToggle = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!showSocialMenu) {
      trackOpenShareMenu();
    }
    setShowSocialMenu((showSocialMenu) => !showSocialMenu);
  };

  const handleToClipboard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const { origin, pathname, search } = window.location;
    const searchParams = {};
    if (search) {
      search
        .split('?')[1]
        .split('&')
        .forEach((value) => {
          const keyValue = value.split('=');
          searchParams[keyValue[0]] = keyValue[1];
        });
    }

    const url = [
      origin + pathname,
      Object.keys({
        ...searchParams,
        ...params,
      }).map((key) => `${key}=${params[key]}`),
    ].join('?');
    const clipboardElement = document.createElement('textarea');

    clipboardElement.value = url;
    clipboardElement.setAttribute('style', 'position:absolute; left:-9999px');
    clipboardElement.setAttribute('readonly', '');
    document.body.appendChild(clipboardElement);

    if (isIos()) {
      // save current contentEditable/readOnly status
      const { readOnly, contentEditable: editable } = clipboardElement;

      // convert to editable with readonly to stop iOS keyboard opening
      clipboardElement.contentEditable = 'true';
      clipboardElement.readOnly = true;

      // create a selectable range
      const range = document.createRange();
      range.selectNodeContents(clipboardElement);

      // select the range
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      clipboardElement.setSelectionRange(0, 999999);

      // restore contentEditable/readOnly to original state
      clipboardElement.contentEditable = editable;
      clipboardElement.readOnly = readOnly;
    } else {
      clipboardElement.select();
    }
    document.execCommand('copy');
    document.body.removeChild(clipboardElement);

    const shareProps = {
      isInPodcastEpisodePage: isInPodcastEpisodePage,
      media_data,
    };
    trackShareContent('copy-link-button', url, shareProps);
    handleToggle();
    showNotification();
  };

  const renderSocialMenu = () => {
    const { origin, pathname, search, href } = window.location;
    let url = href;
    const pathnameParts = pathname.split('/');

    if (pathnameParts[1] === 'podcasts' && pathnameParts[3]) {
      const searchParams = {};
      if (search) {
        search
          .split('?')[1]
          .split('&')
          .forEach((value) => {
            const keyValue = value.split('=');
            searchParams[keyValue[0]] = keyValue[1];
          });
      }

      url = [
        origin + pathname,
        Object.keys({
          ...searchParams,
          ...params,
        }).map((key) => `${key}=${params[key]}`),
      ].join('?');
    }

    return (
      <div
        data-testid="share-menu"
        ref={socialMenuDropwdown}
        className={classNames(classes.shareMenu, {
          [classes.shareMenuBrandPage]: isBrandPage,
          [classes.shareMenuOffset]: !hasMobileHeader,
          [classes.shareMenuMobile]: hasMobileHeader,
          [classes.pullToRight]: right,
        })}
      >
        <header className={classes.shareMenuHeader}>
          <div className={classes.shareMenuHeaderTitle}>{subject} delen</div>
          <button className={classes.shareMenuCloseButton} type="button" onClick={handleToggle}>
            <CrossIcon />
          </button>
        </header>
        <ul className={classes.shareMenuList}>
          {SHARE_ITEMS.map(({ type, name, Icon, Button }) => (
            <li key={`share-item-${type}`} className={classes.listItem}>
              <ShareButton
                Button={Button}
                description={description}
                handleToggle={handleToggle}
                type={type}
                url={url}
                isInPodcastEpisodePage={isInPodcastEpisodePage}
                media_data={media_data}
              >
                <div className={classes.icon}>
                  <Icon />
                </div>
                <span className={classes.shareMenuListItemLabel}>{name}</span>
              </ShareButton>
            </li>
          ))}
          <li>
            <div role="presentation" className={classes.buttonWrapper} onClick={handleToClipboard}>
              <CopyIcon />
              <div className={classNames(classes.shareMenuListItemLabel, classes.shareMenuCustomListItemLabel)}>
                Link kopieren
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div className={classes.socialShare}>
      <div className={classes.shareButtonWrapper}>
        <Button
          type={ButtonTypes.ICON}
          className={classes.shareButton}
          ref={socialShareIconButton}
          onClick={handleToggle}
          icon={<ShareIcon color={color} size={size} />}
          data-testid="social-share-btn"
        />
      </div>
      {showSocialMenu &&
        (hasMobileHeader && sharePortal ? ReactDOM.createPortal(renderSocialMenu(), sharePortal) : renderSocialMenu())}
    </div>
  );
};

export default SocialShare;
