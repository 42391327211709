import { notificationActions } from '../../actions/actions-const';

const initialState = [];

export default function reducer(state = initialState, action) {
  const containsNotification = action.notification ? state.find((n) => n.id === action.notification.id) : false;
  switch (action.type) {
    case notificationActions.SHOW_NOTIFICATION:
      // prevents duplicate notifications to be added to the state
      if (containsNotification) {
        return [...state];
      }
      // Appends a notification to the state
      return [...state, action.notification];

    case notificationActions.CLEAR_NOTIFICATION:
      return state.filter((n) => n.id !== action.notificationId);

    default:
      return state;
  }
}

/**
 * Given the current state, it returns the notifications
 *
 * @param {object} state
 * @returns {array}
 */
export const getNotifications = (state) => state.notifications;
