import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import IconHome from 'components/svg/Home';
import IconRadio from 'components/svg/RadioIcon';
import IconShow from 'components/svg/Show';
import IconGenre from 'components/svg/Genre';
import IconMusic from 'components/svg/Music';
import IconCrown from 'components/svg/CrownOutline';
import IconPodcast from 'components/svg/Podcast';
import IconTerugluisteren from 'components/svg/Terugluisteren';
import logger from 'util/logger';
import { createUseStyles } from 'react-jss';
import styles from './NavigationItem.styles';

const ICONS = {
  IconHome,
  IconRadio,
  IconShow,
  IconGenre,
  IconMusic,
  IconCrown,
  IconPodcast,
  IconTerugluisteren,
};

const useStyles = createUseStyles(styles, { name: 'NavigationItem' });
const NavigationItem = ({ hasSmallDesktopMenu, icon, title, isNew, navIsExpanded, isCollapsible }) => {
  const classes = useStyles();

  const createIcon = (icon) => {
    if (icon) {
      return React.createElement(ICONS[icon]);
    }
    return null;
  };

  const showNewLabel = isNew && !hasSmallDesktopMenu;

  const newLabelClass = classNames(classes.newLabel, classes.redColor, {
    [classes.fadeIn]: navIsExpanded,
    [classes.newLabelCollapsibleNav]: isCollapsible,
  });

  return (
    <>
      <span
        className={classNames(classes.iconContainer, {
          [classes.smallIcon]: hasSmallDesktopMenu,
        })}
      >
        {icon && icon.iconUrl && (
          <SVG
            src={icon.iconUrl}
            onError={(e) => {
              logger.error(e);
            }}
          />
        )}
        {icon && !icon.iconUrl && createIcon(icon.iconType)}
      </span>
      {hasSmallDesktopMenu ? null : (
        <span
          className={classNames(classes.label, {
            [classes.fadeIn]: navIsExpanded,
            [classes.labelCollapsibleNav]: isCollapsible,
          })}
        >
          {title}
        </span>
      )}
      {showNewLabel && <span className={newLabelClass}>NIEUW</span>}
    </>
  );
};

NavigationItem.defaultProps = {
  hasSmallDesktopMenu: false,
  isNew: false,
  navIsExpanded: false,
  isCollapsible: false,
};

NavigationItem.propTypes = {
  hasSmallDesktopMenu: PropTypes.bool,
  isNew: PropTypes.bool,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  navIsExpanded: PropTypes.bool,
  isCollapsible: PropTypes.bool,
};

export default NavigationItem;
