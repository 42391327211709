import { isTouch } from 'util/device';
import * as cssVariable from 'cssVariables';

export const globalStyle = {
  // --------------------------------------------------------------------- Resets
  '@global': {
    '*, *:before, *:after': {
      // Use a normal understading of element sizing
      boxSizing: 'border-box',
      // Fix IE10 flex defaults
      flex: '0 1 auto',
    },
    // Fix img sizing (and IE11 svg default sizing)
    img: {
      height: 'auto',
      maxWidth: '100%',
    },
    'button, input, textarea, select': {
      font: 'inherit',
      color: 'inherit',
    },
    button: {
      font: 'inherit',
      color: 'inherit',
    },

    // ----------------------------------------- Styling for elements outside React
    // eslint-disable-next-line no-dupe-keys
    'html, body, #root': {
      hyphens: 'auto',
      width: '100%',
    },

    // eslint-disable-next-line no-dupe-keys
    html: {
      minHeight: '100%',
    },

    body: {
      margin: 0,
      fontSize: cssVariable.fontSizes.xsmall,
      fontFamily: cssVariable.fontFamily,
      overflowX: 'hidden',
      overflow: !isTouch && 'hidden',
    },

    'a:not(:hover):not(:focus):not(:active)': {
      textDecoration: 'none',
    },

    // Make outlines slightly more stylistic
    'input:focus, button:focus, textarea:focus, select:focus, a:focus': {
      ...cssVariable.focusOutline,
    },

    // Since we already have focus styles, remove this one for Firefox
    '::-moz-focus-inner': {
      border: 0,
    },

    // Do not display pixels
    '.triton-pixel': {
      display: 'none',
    },

    '.sc-talpa-meer-tab-switcher': {
      fontFamily: 'Axiforma-Medium-Screenset',
    },

    // Animation Styles for ReactCSSTransitionGroup
    '.fadeInRight-enter': {
      transform: 'translate(100%)',
    },

    '.fadeInRight-enter.fadeInRight-enter-active': {
      transition: 'transform .5s ease',
      transform: 'translate(0%)',
    },

    '.fadeInRight-exit': {
      transform: 'translate(0%)',
    },

    '.fadeInRight-exit.fadeInRight-exit-active': {
      transform: 'translate(100%)',
      transition: 'transform .3s ease',
    },
  },
};

export function accessibilityStyleSettings() {
  const focusStyle = cssVariable.enableFocusOutlineCSS;
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');

  style.type = 'text/css';
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = focusStyle;
  } else {
    style.appendChild(document.createTextNode(focusStyle));
  }
  head.appendChild(style);

  document.addEventListener('keydown', (e) => {
    if (e.keyCode === 9 && !document.body.classList.contains('accessibility')) {
      document.body.classList.add('accessibility');
    }
  });

  document.addEventListener('mousedown', () => {
    if (document.body.classList.contains('accessibility')) {
      document.body.classList.remove('accessibility');
    }
  });
}
