import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { injectStyles } from 'injectStyles';
import { isIE11andLower } from 'util/device';
import withGraphQL from 'components/hoc/withGraphqlData';
import styles from './FeaturedPodcast.styles';

import PodcastTile from 'components/Tiles/PodcastTile';

class FeaturedPodcast extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    slug: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
    description: PropTypes.string,
    tile: PropTypes.object,
  };

  static defaultProps = {
    slug: '',
    title: '',
    author: '',
    description: '',
    tile: null,
  };

  render() {
    const { classes, slug, title, author, description } = this.props;
    const podcastPageUrl = `/podcasts/${slug}`;
    const descriptionClass = classNames(classes.description, {
      [classes.descriptionIE]: isIE11andLower,
    });

    return (
      <div className={classes.wrapper}>
        <div className={classes.innter}>
          <div className={classes.image}>
            <PodcastTile {...this.props} hideText />
          </div>
          <div className={classes.content}>
            <div className={classes.contentTopPart}>
              <h3 className={classes.title}>{title}</h3>
              <span className={classes.author}>{author}</span>
              <div className={descriptionClass} dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <div className={classes.contentBottomPart}>
              <NavLink className={classes.linkToPage} to={podcastPageUrl} aria-label={`Ga naar ${title}`}>
                ALLE AFLEVERINGEN BEKIJKEN
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectStyles(styles)(withGraphQL(FeaturedPodcast));
