import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { isIE11andLower } from 'util/device';

import styles from './VolumeSlider.styles';
import { createUseStyles } from 'react-jss';

let volumeTimer = null;

const useStyles = createUseStyles(styles, { name: 'VolumeSlider' });

const VolumeSlider = ({ onSetVolumeLevel, volume }) => {
  const classes = useStyles();

  const [volumeLevel, setVolumeLevel] = useState(volume);

  useEffect(() => {
    setVolumeLevel(volume);
  }, [volume]);

  const handleSetVolumeLevel = (evt) => {
    // parse time and immediately set state to ensure smooth UI
    const nextVolumeLevel = parseFloat(evt.target.value);
    setVolumeLevel(nextVolumeLevel);

    // debounce action to avoid lag in UI and spamming of analytics
    clearTimeout(volumeTimer);
    volumeTimer = setTimeout(() => onSetVolumeLevel(nextVolumeLevel), 150);
  };

  return (
    <div data-testid="player-bottom-volume-slider" className={classes.sliderHolder}>
      <input
        className={isIE11andLower ? classes.ieStyling : classes.volumeSlider}
        data-testid="player-bottom-volume-slider-input"
        type="range"
        min={0}
        max={1}
        step={0.01}
        value={volumeLevel}
        onChange={handleSetVolumeLevel}
        aria-label="volume slider"
      />
      {!isIE11andLower && (
        <div className={classes.progressWrapper}>
          <div className={classes.progress} style={{ width: `${volumeLevel * 100}%` }} />
        </div>
      )}
    </div>
  );
};

VolumeSlider.propTypes = {
  volume: PropTypes.number.isRequired,
  onSetVolumeLevel: PropTypes.func.isRequired,
};

export default VolumeSlider;
