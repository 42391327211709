import { podcastFoldActions } from './actions-const';
import { AppDispatch } from 'store';

export function openFold(podcastSlug: string, collectionId: string) {
  return (dispatch: AppDispatch): void => {
    dispatch({
      type: podcastFoldActions.OPEN_FOLD,
      podcast: podcastSlug,
      collectionId,
    });
  };
}

export function closeFold() {
  return (dispatch: AppDispatch): void => {
    dispatch({
      type: podcastFoldActions.CLOSE_FOLD,
    });
  };
}
