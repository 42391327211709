import React from 'react';
import PropTypes from 'prop-types';
import * as cssVariable from 'cssVariables';

const svg = ({ color, ...props }) => (
  <svg viewBox="0 0 100 100" preserveAspectRatio="none meet" {...props}>
    <polygon points="50,100 100,0 100,100" style={{ fill: color || cssVariable.white }} />
  </svg>
);

svg.propTypes = {
  color: PropTypes.string,
};

svg.defaultProps = {
  color: null,
};

export default svg;
