export const TAG_COLLECTION_FRAGMENT = `
  fragment TagCollectionFragment on TagCollection {
    id
    description
    slug
    title
    options
    type
    config {
      ...ConfigFragment
    }
    images {
      ...ImageFragment
    }
    items {
      slug
      title
      type
      refs {
        type
        uri
        targetType
      }
      images {
        ...ImageFragment
      }
      config {
        ...ConfigFragment
      }
    }
  }
  `;
