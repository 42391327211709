import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Button from 'components/Button';

import * as analytics from 'analytics';
import graphql from 'api/graphql';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { CALL_TO_ACTION_TYPES } from '../CallToActionBlock.const';
import logger from 'util/logger';

import styles from './CallToAction.styles';

const useStyles = createUseStyles(styles, { name: 'CallToAction' });

function CallToAction({ buttonText, ctaType, pageLink, url, onLoad, onStop, isPlaying, playStationSlug, title }) {
  const classes = useStyles();

  const [station, setStation] = useState(null);
  const buttonClass = classNames(classes.button, {
    [classes[ctaType]]: ctaType,
  });

  useEffect(() => {
    async function fetchStation() {
      try {
        const response = await graphql.fetchStation({ slug: playStationSlug });
        setStation(response);
      } catch (error) {
        logger.error(`Couldn't fetch station ${playStationSlug}`);
      }
    }
    if (playStationSlug) {
      fetchStation();
    }
  }, [playStationSlug]);

  function handlePlayToggle() {
    if (isPlaying) {
      return onStop();
    }

    return onLoad({ ...station, collectionTitle: title });
  }

  if (pageLink && pageLink.slug) {
    const pathname = pageLink.parentSlug ? `/${pageLink.parentSlug}/${pageLink.slug}` : `/${pageLink.slug}`;
    return (
      <NavLink
        className={buttonClass}
        to={pathname === '/home' ? '/' : pathname}
        onClick={() =>
          analytics.trackEvent(analytics.generateCTAClickData(title, url, CONTENT_TYPES.CALL_TO_ACTION_BLOCK))
        }
      >
        {buttonText || `ga naar ${pageLink.title}`}
      </NavLink>
    );
  }
  if (url) {
    const hasHash = url.indexOf('#') !== -1;
    if (hasHash) {
      return (
        <a
          className={buttonClass}
          href={url}
          onClick={() =>
            analytics.trackEvent(analytics.generateCTAClickData(title, url, CONTENT_TYPES.CALL_TO_ACTION_BLOCK))
          }
          target="_self"
          rel="nofollow"
        >
          {buttonText || 'ga naar'}
        </a>
      );
    }

    return url.match('^(https|http)') ? (
      <a
        className={buttonClass}
        href={url}
        onClick={() =>
          analytics.trackEvent(analytics.generateCTAClickData(title, url, CONTENT_TYPES.CALL_TO_ACTION_BLOCK))
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonText || 'ga naar'}
      </a>
    ) : (
      <NavLink
        className={buttonClass}
        to={url}
        onClick={() =>
          analytics.trackEvent(analytics.generateCTAClickData(title, url, CONTENT_TYPES.CALL_TO_ACTION_BLOCK))
        }
      >
        {buttonText || `ga naar ${url}`}
      </NavLink>
    );
  }
  if (playStationSlug && station) {
    return <Button onClick={handlePlayToggle} text={!isPlaying ? buttonText || 'Speel af' : 'Speelt af'} />;
  }
  return null;
}

CallToAction.defaultProps = {
  playStationSlug: null,
  ctaType: null,
  url: null,
  buttonText: null,
  isPlaying: false,
  pageLink: null,
  title: null,
};

CallToAction.propTypes = {
  buttonText: PropTypes.string,
  ctaType: PropTypes.oneOf(Object.values(CALL_TO_ACTION_TYPES)),
  isPlaying: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  playStationSlug: PropTypes.string,
  url: PropTypes.string,
  pageLink: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    parentSlug: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default CallToAction;
