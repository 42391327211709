import { connect } from 'react-redux';

import { getVolumeLevel } from 'store/reducers/player';
import { setVolumeLevel } from 'actions/playerVolume-actions';

import VolumeSlider from './VolumeSlider';

const mapStateToProps = (state) => ({
  volume: getVolumeLevel(state),
});

export default connect(mapStateToProps, {
  onSetVolumeLevel: setVolumeLevel,
})(VolumeSlider);
