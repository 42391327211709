export default function playerPodcastClick({
  clickedElement,
  media_name,
  media_program_episodenumber,
  media_program_name,
  media_program_season,
  media_audiotype,
}) {
  window.Taq.push([
    'track',
    'link',
    {
      event_action: 'click',
      event_category: 'player',
      event_label: clickedElement,
      media_name,
      media_program_episodenumber,
      media_program_name,
      media_program_season,
      media_audiotype,
    },
  ]);
}
