import { Styles } from 'jss';
import * as cssVariable from 'cssVariables';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  button: {
    marginTop: 10,
    width: '100%',
    height: 40,
    borderRadius: 2,
    border: `1px solid ${theme.palette.border}`,
    color: theme.palette.text.secondary,
    fontSize: cssVariable.fontSizes.xsmall,
    fontWeight: cssVariable.fontWeights.bold,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
