import React, { Fragment } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import PlayStationButton from 'components/CTAButtons/PlayStationButton';
import * as cssVariable from 'cssVariables';
import SocialShare from 'components/SocialShare';
import { Station } from 'api/models';
import styles from './HeaderBanner.styles';
import Metadata from 'api/models/Metadata';
import { isMobile, isTouch } from 'util/device';
import useBrowser from 'components/hooks/useBrowser';
import { breakpoints } from 'components/theme/breakpoints';
import { BUTTON_SIZE } from 'globalConst/const';

interface HeaderBanner {
  imageUrl: string;
  title: string;
  logoUrl: string;
  description: string;
  color: string;
  station: Station;
  metadata?: Metadata;
  isBrandPage?: boolean;
  showShare?: boolean;
}

const useStyles = createUseStyles(styles, { name: 'HeaderBanner' });
const HeaderBanner = (props: HeaderBanner): JSX.Element => {
  const { imageUrl, logoUrl, title, station, metadata, description, color, isBrandPage, showShare = true } = props;

  const browser = useBrowser();
  const classes = useStyles();
  const mobile = isMobile;

  const socialShareProps = {
    subject: title,
    description: metadata?.share?.description,
    link: metadata?.share?.url,
    title: metadata?.share?.title,
    size: BUTTON_SIZE.medium,
    color: cssVariable.white,
    isBrandPage,
  };

  return (
    <div
      className={classNames(classes.container, {
        [classes.containerTouch]: isTouch,
        [classes.containerMobile]: mobile,
      })}
      style={{ background: color }}
    >
      {logoUrl && (
        <img
          data-testid="header-banner-logo"
          className={classNames(classes.logo, { [classes.logoMobile]: mobile })}
          src={logoUrl}
          alt={title}
        />
      )}
      <div className={classNames(classes.contentContainer, { [classes.contentContainerMobile]: mobile })}>
        <h1 className={classNames(classes.title, { [classes.titleMobile]: mobile })}>{title}</h1>
        {description && (
          <p className={classNames(classes.content, { [classes.contentMobile]: mobile })}>{description}</p>
        )}
      </div>
      <div className={classNames(classes.buttonContainer, { [classes.buttonContainerMobile]: mobile })}>
        {station && !isBrandPage && (
          <Fragment>
            <PlayStationButton station={station} />
          </Fragment>
        )}
        {!mobile && metadata && showShare && browser.up(breakpoints.xs) && <SocialShare {...socialShareProps} />}
      </div>
      {imageUrl && (
        <img className={classNames(classes.image, { [classes.imageTouch]: isTouch })} src={imageUrl} alt={title} />
      )}
    </div>
  );
};

export default HeaderBanner;
