import * as cssVariable from 'cssVariables';

export default {
  container: {
    height: 'auto',
    ...cssVariable.fullPageMinHeight,
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    position: 'relative',
    minHeight: `calc(100vh - ${cssVariable.footerHeight})`,
  },
  contentContainerSearchActive: {
    ...cssVariable.fillAspectRatio,
    overflow: 'hidden',
  },
};
