import { gigyaRouterActions } from '../../actions/actions-const';

const initialState = {
  isActive: false,
  currentScreen: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case gigyaRouterActions.GIGYA_SCREEN_OPENED:
      return { isActive: true, currentScreen: action.screen };
    case gigyaRouterActions.GIGYA_SCREEN_CLOSED:
      return { isActive: false, currentScreen: null };
    default:
      return state;
  }
}

/**
 * Given the current state, it returns wheather or not a gigya screen is active
 *
 * @param {object} state
 * @returns {array}
 */
export const getIsActive = (state) => state.gigyaModal.isActive;
