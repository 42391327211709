export const PODCAST_COLLECTION_FRAGMENT = `
  fragment PodcastCollectionFragment on PodcastCollection {
    id
    title
    slug
    description
    options
    config {
      ...ConfigFragment
    }
    type
    items {
      title
      description
      slug
      id
      subTitle
      type
      feedUrl
      tags {
        slug
        title
        type
      }
      images {
        ...ImageFragment   
      }
      episodes(limit: 1) {
        ...PodcastEpisodeFragment
      }
    }
  }
`;
