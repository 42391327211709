import React from 'react';
import PropTypes from 'prop-types';

import { injectStyles } from 'injectStyles';
import styles from './PlayerControls.styles';
import PlayControl from 'components/PlayControl';
import * as cssVariable from 'cssVariables';
import SkipControl from './SkipControl';
import classNames from 'classnames';
import { isTouch } from 'util/device';
import Scrubber from './Scrubber';
import ButtonAnimation from 'components/ButtonAnimation';

const PlayerControls = ({
  classes,
  isOnDemand,
  isTrackPreview,
  onPlayStopToggle,
  isPlaying,
  isLoading,
  isComponentLoading,
  browser,
}) => {
  return (
    <div className={classes.wrapper}>
      <div
        className={classNames(classes.container, {
          [classes.smallTouch]: isTouch && browser.down.sm,
        })}
      >
        <div className={classes.content}>
          <div className={classes.controls}>
            {isOnDemand && (
              <div className={classes.mr40}>
                <SkipControl
                  size={22}
                  color={cssVariable.grey200}
                  skipTime={isTrackPreview ? 5 : 15}
                  isSkipForward={false}
                />
              </div>
            )}
            <div
              className={classNames(classes.play, {
                [classes.mr40]: isOnDemand,
              })}
            >
              <ButtonAnimation buttonAnimation={'pulse_player'} withAnimation={!isPlaying} buttonPosition="player">
                <PlayControl
                  size={50}
                  isOnDemand={isOnDemand}
                  playToggle={onPlayStopToggle}
                  isPlaying={isPlaying && !isLoading}
                  isLoading={(isPlaying && isLoading) || isComponentLoading}
                  isPrimary
                />
              </ButtonAnimation>
            </div>
            {isOnDemand && (
              <SkipControl size={22} color={cssVariable.grey200} skipTime={isTrackPreview ? 5 : 30} isSkipForward />
            )}
          </div>
          {isOnDemand && <Scrubber data-testid="player_bottom_scrubber" />}
        </div>
      </div>
    </div>
  );
};

PlayerControls.propTypes = {
  classes: PropTypes.object.isRequired,
  isComponentLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPlaying: PropTypes.bool.isRequired,
  onPlayStopToggle: PropTypes.func.isRequired,
  isOnDemand: PropTypes.bool.isRequired,
  isTrackPreview: PropTypes.bool.isRequired,
  browser: PropTypes.object.isRequired,
};

export default injectStyles(styles)(PlayerControls);
