import React, { useRef, Fragment, useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Image } from 'api/interfaces';

import { createUseStyles } from 'react-jss';

import styles from './PodcastTile.styles';

import * as analytics from 'analytics';
import { getImageWithResamplingQuery } from 'util/resampling';
import { scrollY } from 'util/noscroll';
import { isMobile, isTouch } from 'util/device';

import CustomLink from 'components/CustomLink';
import LazyTileImage from 'components/LazyTileImage';
import CollectionContext from 'components/Collection/CollectionContext';

import Marquee from 'components/Marquee';
import Button from 'components/Button';
import PlayIcon from 'components/svg/Play';
import PauseIcon from 'components/svg/Pause';
import ThreeDots from 'components/svg/ThreeDots';
import { PodcastEpisode } from 'api/models';
import CONTENT_TYPES from 'globalConst/contentTypes-const';

function navLink(pathname: string, podcastName: string, userSearched: string) {
  return {
    pathname,
    state: {
      analytics: analytics.generateTileClick(podcastName, pathname, 'podcast page', userSearched),
    },
  };
}

interface handleToggleOutside extends MouseEvent {
  target: HTMLInputElement;
}

interface CurrentOnDemandClip {
  id?: string;
}
export interface PodcastTileProps {
  id?: string;
  author?: string;
  slug?: string;
  collectionId?: string;
  display?: string;
  error?: boolean;
  foldIsOpen?: boolean;
  hideText?: boolean;
  image?: Image;
  isInSearch?: boolean;
  podcastSlugInFold?: string;
  contentTypeId?: string;
  title?: string;
  userSearched?: string;
  openFold: (slug: string, collectionId: string) => void;
  closeFold: () => void;
  saveRecentSearch?: (contentTypeId: string, slug: string) => void;
  episodes: Array<PodcastEpisode>;
  onPlay: (episode: PodcastEpisode) => void;
  onStop: () => void;
  isPlaying: boolean;
  currentClip: CurrentOnDemandClip;
  collectionTitle?: string;
}

const useStyles = createUseStyles(styles, { name: 'PodcastTile' });

const PodcastTile = ({
  author,
  error,
  hideText,
  image,
  slug,
  title,
  userSearched,
  saveRecentSearch,
  contentTypeId,
  id,
  collectionId,
  closeFold,
  foldIsOpen,
  podcastSlugInFold,
  openFold,
  episodes,
  isPlaying,
  currentClip,
  onPlay,
  onStop,
  collectionTitle,
}: PodcastTileProps): JSX.Element => {
  const classes = useStyles();

  const tileRef = useRef(null);

  const isHoverShown = useRef(false);

  const { isInSearch, withFold } = useContext(CollectionContext);
  const [hoverState, setHoverState] = useState(false);

  const scrollToTile = () => {
    const { top, height } = tileRef.current.getBoundingClientRect();
    scrollY(top - height / 2);
  };

  const handleClick = () => {
    if (withFold) {
      if (foldIsOpen && podcastSlugInFold === slug) {
        closeFold();
      } else {
        openFold(slug, collectionId);
        setTimeout(scrollToTile, 200);
      }
    }
  };

  const handleFavoriteClick = () => {
    if (isTouch) {
      handleClick();
      return;
    }
  };

  const saveToLocalStorage = () => {
    saveRecentSearch(contentTypeId, slug);
  };

  let podcastLatestEpisode = episodes && episodes[0];
  const isPodcastLatestEpisode = () => {
    const latestEpisode = podcastLatestEpisode || null;
    const currentOnDemandClip = currentClip || null;
    return isPlaying && currentOnDemandClip?.id === latestEpisode?.id;
  };

  const handlePlayLatestEpisode = () => {
    if (isPodcastLatestEpisode()) {
      analytics.trackPodcastStop(collectionTitle);
      onStop();
    } else {
      analytics.trackPodcastStart(collectionTitle);
      podcastLatestEpisode = {
        ...podcastLatestEpisode,
        contentTypeId: CONTENT_TYPES.CLIP,
      };
      onPlay(podcastLatestEpisode);
    }
  };

  const handleToggleOutsideHoverMenu = (e: handleToggleOutside) => {
    const contextMenu = document.getElementById('context-menu');
    const threeDotsPodcast = document.getElementById(`podcast_dots_${slug}`);

    if (
      contextMenu &&
      !contextMenu.contains(e?.target) &&
      threeDotsPodcast &&
      !threeDotsPodcast.contains(e?.target) &&
      isHoverShown.current
    ) {
      setHoverState(false);
      isHoverShown.current = false;
      window.dispatchEvent(new Event('handleOutsideClick'));
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleToggleOutsideHoverMenu);

    return () => {
      window.removeEventListener('mousedown', handleToggleOutsideHoverMenu);
    };
  }, []);

  const toggleContextMenu = () => {
    const podcastTile = document.getElementById(`${collectionId}_${slug}`);
    const podcastTileLocation = podcastTile.getBoundingClientRect();
    isHoverShown.current = !isHoverShown.current;
    window.dispatchEvent(
      new CustomEvent('toggleContextMenu', {
        detail: {
          onPlay,
          onStop,
          podcastTileLocation,
          slug,
          title,
          contentTypeId,
          id,
          collectionId,
          author,
          image,
          episodes,
          isHoverShown: isHoverShown.current,
          toggleContextMenu,
          collectionTitleOutside: collectionTitle,
        },
      })
    );
    setHoverState(isHoverShown.current);
  };

  return (
    <div>
      <div data-testid={`podcast_${slug}`} ref={tileRef} className={classes.podcastTile} id={`${collectionId}_${slug}`}>
        <LazyTileImage
          data-key="tile-image"
          className={classNames(classes.backdropDefault, classes.backdropDefaultFix)}
          imageUrl={getImageWithResamplingQuery({ url: image?.url })}
        />

        <div
          onClick={isInSearch ? saveToLocalStorage : undefined}
          className={classNames(classes.hoverOverlayDefault, {
            [classes.noHover]: isTouch,
            [classes.hoverAdditionForContextMenu]: hoverState && !isTouch,
          })}
          data-testid="podcastTile_overlay"
          role="presentation"
        >
          {isMobile && (
            <CustomLink
              isLink={!withFold && !error}
              handleClick={error ? handleFavoriteClick : handleClick}
              className={classes.pageLinkDefault}
              to={!withFold ? navLink(`/podcasts/${slug}`, title, userSearched) : null}
              data-testid="podcast_tile_more_open_podcast"
              aria-label={`Ga naar ${title}`}
            />
          )}
          <div className={classes.hoverBackdropDefault}>
            <CustomLink
              isLink={!withFold && !error}
              handleClick={error ? handleFavoriteClick : handleClick}
              className={classes.pageLinkDefault}
              to={!withFold ? navLink(`/podcasts/${slug}`, title, userSearched) : null}
              data-testid="podcast_tile_more_open_podcast"
              aria-label={`Ga naar ${title}`}
            />
            <Button
              className={classNames(classes.button, {
                [classes.notVisible]: isTouch,
              })}
              type="button"
              icon={isPodcastLatestEpisode() ? <PauseIcon /> : <PlayIcon filled={false} />}
              onClick={handlePlayLatestEpisode}
              data-testid={'pause-play-buttonHover'}
            />
            <ThreeDots
              id={`podcast_dots_${slug}`}
              className={classNames(classes.threeDots, { [classes.notVisible]: isTouch })}
              onClick={toggleContextMenu}
            />
          </div>
        </div>
      </div>
      {!hideText && !isMobile && (
        <Fragment>
          <h3 className={classes.title} onClick={isInSearch ? saveToLocalStorage : undefined}>
            <NavLink className={classes.titleLink} to={`/podcasts/${slug}`} title={title}>
              {title}
            </NavLink>
            {error && 'Podcast helaas niet gevonden'}
            <Marquee text={author} />
          </h3>
          <span className={classes.screenReaderTitleDefault}>
            {title} {author}
          </span>
        </Fragment>
      )}
    </div>
  );
};

export default PodcastTile;
