import { handleAfterSubmit, handleBeforeScreenLoad, handleHide } from 'actions/gigya-router-actions';
import { GIGYA_SCREENSET, isGigyaReady } from 'components/Gigya';

export const openScreenset = (screenSet: GIGYA_SCREENSET, startScreen: GIGYA_SCREENSET): void => {
  if (isGigyaReady()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.gigya?.accounts?.showScreenSet?.({
      screenSet,
      ...(startScreen && { startScreen }),
      onHide: handleHide,
      onAfterSubmit: handleAfterSubmit,
      onBeforeScreenLoad: handleBeforeScreenLoad,
      deviceType: 'desktop',
    });
  }
};
