import { connect } from 'react-redux';
import { getCurrentStation } from 'store/reducers/player';

import PlayerRecentlyPlayed from './PlayerRecentlyPlayed';

const mapStateToProps = (state) => ({
  currentStation: getCurrentStation(state),
});

export default connect(mapStateToProps)(PlayerRecentlyPlayed);
