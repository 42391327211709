import React, { ForwardedRef } from 'react';
import classNames from 'classnames';
import { isMobile } from 'util/device';
import styles from './Button.styles';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'util/index';

interface ButtonProps {
  className?: string;
  type: string;
  href?: string;
  disabled?: boolean;
  text?: string;
  isActive?: boolean;
  icon?: JSX.Element;
  iconClassName?: string;
  noHover?: boolean;
  'data-testid': string;
  onClick?: () => void;
}

const useStyles = createUseStyles(styles, { name: 'Button' });

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  ({ className, type, isActive, disabled, text, icon, iconClassName, href, noHover, ...rest }, ref): JSX.Element => {
    const classes = useStyles();

    const buttonClass = classNames(className, classes.button, classes[type], {
      [classes.isActive]: isActive,
      [classes.disabled]: disabled,
      [classes.mobileButton]: isMobile,
      [classes.hover]: !noHover,
      [classNames({
        [classes.hasIcon]: isEmpty(text),
        [classes.hasIconWithText]: !isEmpty(text),
      })]: icon,
    });

    const buttonProps = {
      ...rest,
      className: buttonClass,
      tabIndex: 0,
    };

    if (href) {
      return (
        <a
          ref={ref as unknown as ForwardedRef<HTMLAnchorElement>}
          href={href}
          className={buttonClass}
          tabIndex={0}
          {...rest}
        >
          {icon && <span className={classNames(classes.icon, iconClassName)}>{icon}</span>}
          {text}
        </a>
      );
    }

    return (
      <button
        ref={ref as unknown as ForwardedRef<HTMLButtonElement>}
        aria-label={text}
        value={text}
        type="button"
        {...buttonProps}
      >
        {icon && <span className={classNames(classes.icon, iconClassName)}>{icon}</span>}
        <span>{text}</span>
      </button>
    );
  }
);

export default Button;
