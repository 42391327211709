import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { isEmpty } from 'util/index';
import PlayerButton from '../PlayerButton';
import RecommendationsIcon from 'components/svg/Recommendations';
import styles from './PlayerRecommendations.styles';
import { injectStyles } from 'injectStyles';
import { useFetchData } from 'components/hooks';
import RecommendationTiles from './RecommendationTiles';
import { isNonTalpaStation } from './PlayerRecommendations.utils';
import { getFlagValue } from 'firebase/featureFlags.util';
import { DEFAULT_REMOTE_CONFIG_FLAGS } from 'firebase/featureFlags.const.ts';
import { isMobile, isTablet } from 'util/device';
import { RECOMMENDATIONS_COLLECTION_SLUG, RECOMMENDATIONS_POPOUT_TEST_ID } from './PlayerRecommendations.consts';
import CONTENT_TYPES from 'globalConst/contentTypes-const';

const PlayerRecommendations = ({ classes, currentStation }) => {
  const popoutVisible = sessionStorage.getItem('playerRecommendations') ? false : true;

  const { data } = useFetchData({
    slug: RECOMMENDATIONS_COLLECTION_SLUG,
    contentTypeId: CONTENT_TYPES.STATION_COLLECTION,
  });

  const { items } = data || {};

  const renderPopout = useCallback(
    (closeBtn) => {
      return (
        <div data-testid={RECOMMENDATIONS_POPOUT_TEST_ID} className={classes.recommendations}>
          <span onClick={() => sessionStorage.setItem('playerRecommendations', false)}>{closeBtn}</span>
          <RecommendationTiles items={items} title={data?.title} />
        </div>
      );
    },
    [items]
  );

  const renderIcon = () => {
    return <RecommendationsIcon />;
  };

  /**
   * The component is not being rendered if:
   * - There is no stations in the 'aanbevolen' collection
   * - There is no current station
   * - The current station is a Talpa station
   * - The flag is NOT enabled
   * - A device is mobile or tablet
   * *Note: The component will be active once per session
   */
  if (
    isEmpty(currentStation) ||
    isEmpty(items) ||
    !isNonTalpaStation(currentStation?.title.toLowerCase()) ||
    !getFlagValue(DEFAULT_REMOTE_CONFIG_FLAGS.recommendations) ||
    isMobile ||
    isTablet()
  )
    return null;

  return (
    <PlayerButton
      data-testid="player-bottom-recommendations-btn"
      title="Aanbevolen"
      renderIcon={renderIcon}
      renderPopout={renderPopout}
      hasPopout
      hideOnSmall
      hideOnMedium
      popoutVisible={popoutVisible}
    />
  );
};

PlayerRecommendations.defaultProps = {
  currentStation: null,
};

PlayerRecommendations.propTypes = {
  classes: PropTypes.object.isRequired,
  currentStation: PropTypes.object,
};

export default injectStyles(styles)(PlayerRecommendations);
