import { UniversalCollectionItem, UniversalNavigation } from '../interfaces';
import CollectionItem from './CollectionItem';
import { isEmpty } from 'util/index';

export interface NavigationItemProps {
  uri?: string;
  options?: [];
  pageLink?: { slug: string };
  title?: string;
  icon?: { uri?: string; title?: string };
}
/**
 * @class
 * A representation of a Navigation
 *
 * @param {object} navigation - navigation model
 */
export default class Navigation extends CollectionItem {
  items: NavigationItemProps[];
  uri: string;
  options: [];
  pageLink: { slug: string };

  constructor(item: UniversalCollectionItem) {
    super(item);
    if (!item) {
      return null;
    }

    const navigation = <UniversalNavigation>item;

    this.title = navigation.title;
    this.items = [];

    navigation?.reference?.getNavigations?.navigations?.map((nav) => {
      const res: NavigationItemProps = {};
      res.uri = nav.uri;
      res.title = nav.title;
      res.icon = (!isEmpty(nav.images) && nav.images[0]) || null;

      if (nav.options && Array.isArray(nav.options)) {
        nav.options.forEach((option: string) => {
          if (option.includes('navslug')) {
            res.pageLink = { slug: option.substring(option.indexOf('-') + 1) };
          }

          if (option.includes('#')) {
            res.uri = option;
          }
        });
      }

      this.items.push(res);
    });

    // Make this class read-only
    Object.freeze(this);
  }
}
