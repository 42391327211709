import * as cssVariable from 'cssVariables';
import tileStyles from 'tile-components-styles';

import { breakpoints, mediaQuery } from '../../theme/breakpoints';

const ALBUM_COVER_DIMENSION = 60;

export default (theme) => ({
  listItem: {
    position: 'relative',
  },
  description: {
    textDecoration: 'none',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '80%',
    minHeight: 62,
    position: 'relative',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.navigation.background,
    '&:not($noHoverAnimation):hover': {
      '& $triangleBig': {
        top: '90%',
        right: '50%',
      },
      '& $triangleSmall': {
        right: '80%',
        bottom: '90%',
      },
      '& $isPlayingWrapper': {
        display: 'none',
      },
    },
    '&$hideTriangles': {
      '& $triangleBig': {
        top: '90%',
        right: '50%',
      },
      '& $triangleSmall': {
        right: '80%',
        bottom: '90%',
      },
      '& $isPlayingWrapper': {
        display: 'none',
      },
    },
  },
  mobileRowContainer: {
    backgroundColor: cssVariable.white,
    minHeight: 80,
    paddingLeft: 10,
    '& $albumCover': {
      marginRight: 15,
    },
    '& $title': {
      fontSize: cssVariable.fontSizes.medium,
    },
  },
  noHoverAnimation: {},
  hideTriangles: {},
  lightBackgound: {
    backgroundColor: cssVariable.white,
  },
  speakerIcon: {},
  stopIcon: {
    display: 'none',
  },
  rowContainerEven: {
    backgroundColor: theme.palette.navigation.background,
  },
  clickContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
    '&:hover': {
      cursor: 'pointer',
      outline: 'none',
    },
  },
  albumCover: {
    position: 'relative',
    flex: 'none',
    display: 'flex',
    width: ALBUM_COVER_DIMENSION,
    height: ALBUM_COVER_DIMENSION,
    marginRight: cssVariable.gutter / 2,
    borderRadius: 1,
    backgroundColor: cssVariable.grey500,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    alignItems: 'center',
  },
  albumCoverOverlay: {
    backgroundColor: cssVariable.transparentGrey500,
    color: cssVariable.white,
    height: ALBUM_COVER_DIMENSION,
    width: ALBUM_COVER_DIMENSION,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      width: 45,
      height: 45,
    },
    zIndex: 1,
  },
  albumCoverWithColor: {
    backgroundColor: ({ onDemandClip }) =>
      onDemandClip && onDemandClip.brandColor && cssVariable.hexToRgba(onDemandClip.brandColor, 0.6),
  },
  roundOverlay: {
    backgroundColor: cssVariable.transparentLightmode030,
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  info: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  title: {
    ...cssVariable.ellipsis,
    maxWidth: '100%',
    fontWeight: cssVariable.fontWeights.regular,
    fontSize: cssVariable.fontSizes.xsmall,
    margin: 0,
  },
  titleActivePlayer: {
    color: cssVariable.blue500,
    fontWeight: cssVariable.fontWeights.bold,
  },
  subtitle: {
    '&:after': {
      content: '"."',
      display: 'inline-block',
      padding: [0, 5],
      position: 'relative',
      top: -5,
      color: cssVariable.grey500,
      fontSize: cssVariable.fontSizes.xsmall,
    },
  },
  airDateHolder: {
    fontSize: cssVariable.fontSizes.xsmall,
    color: cssVariable.grey500,
  },
  pageLink: {
    color: cssVariable.blue500,
    marginLeft: 20,
    fontWeight: cssVariable.fontWeights.bold,
  },
  screenReaderTitle: {
    ...cssVariable.screenReadersOnly,
  },
  withHoverEffect: {
    minHeight: 80,
    padding: 10,
    '& $albumCover': {
      borderRadius: 2,
      marginRight: cssVariable.gutter,
    },
    '& $title': {
      color: theme.palette.text.primary,
      fontSize: cssVariable.fontSizes.medium,
      lineHeight: 1.33,
    },
    '& $titleActivePlayer': {
      color: cssVariable.green500,
      fontWeight: cssVariable.fontWeights.regular,
    },
    '& $airDateHolder': {
      fontSize: cssVariable.fontSizes.small,
      lineHeight: 1.33,
    },
    '&:hover': {
      '& $speakerIcon': {
        display: 'none',
      },
      '& $stopIcon': {
        display: 'block',
      },
      '& $title': {
        color: cssVariable.green500,
      },
      '& $roundOverlay': {
        backgroundColor: cssVariable.white,
        color: cssVariable.green500,
      },
    },
  },
  triangles: {
    ...tileStyles.trianglesDefault,
  },
  triangleBig: {
    ...tileStyles.triangleBigDefault,
  },
  triangleSmall: {
    ...tileStyles.triangleSmallDefault,
  },
  highlight: {
    transform: 'scale(1.02)',
    boxShadow: `0 3px 5px ${cssVariable.transparentLightmode010}, 0 0 5px ${cssVariable.transparentLightmode005}`,
    border: `1px solid ${cssVariable.blue500}`,
  },
  podcastEpisode: {
    position: 'relative',
    padding: 10,
    marginBottom: 8,
    borderRadius: cssVariable.borderRadius.default,
    backgroundColor: cssVariable.grey100,
    cursor: 'pointer',
    transition: 'all .3s ease',
    '&:hover': {
      backgroundColor: cssVariable.grey200,
      boxShadow: `0 3px 5px ${cssVariable.transparentLightmode010}, 0 0 5px ${cssVariable.transparentLightmode005}`,
      zIndex: 10,
    },

    '& $rowContainer': {
      backgroundColor: 'transparent',
    },

    '& $albumCover': {
      width: 60,
      height: 60,
      borderRadius: cssVariable.borderRadius.default,
      overflow: 'hidden',
    },

    '& $albumCoverOverlay': {
      backgroundColor: cssVariable.transparentLightmode030,
    },

    '& $title': {
      maxWidth: '80%',
      ...cssVariable.ellipsis,
      color: cssVariable.grey600,
      fontSize: cssVariable.fontSizes.small,
      fontWeight: cssVariable.fontWeights.bold,
    },

    '& $titleActivePlayer': {
      color: cssVariable.green500,
    },

    '& $podcastAirDate': {
      display: 'flex',
      color: cssVariable.grey500,
      fontSize: cssVariable.fontSizes.xsmall,
    },

    '& $podcastDuration': {
      display: 'inline-block',
      color: cssVariable.grey500,
      fontSize: cssVariable.fontSizes.xsmall,
    },
  },
  podcastAirDate: {},
  podcastDuration: {},
  podcastDescription: {},
  buttonGroup: {
    display: 'flex',
    alignSelf: 'flex-start',
    top: 0,
    right: 0,
  },

  [mediaQuery.maxWidth(breakpoints.sm)]: {
    buttonGroup: {
      '& button': {
        '& span': {
          padding: [8, 0],
        },
      },
    },
  },
});
