import { connect } from 'react-redux';
import { getNotifications } from 'store/reducers/notifications';

import NotificationList from './NotificationList';

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
});

export default connect(mapStateToProps)(NotificationList);
