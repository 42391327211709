import { Vendor } from 'api/interfaces';
import { getCookie } from './cookie';
import logger from 'util/logger';
import { TCString } from '@iabtcf/core';

const EUCONSENT = 'eupubconsent-v2';

const talpaDomains = [
  '538.nl',
  'hartvannederland.nl',
  'juke.nl',
  'kijk.nl',
  'kijkers.tv ',
  'linda.nl',
  'new-wave.nl ',
  'partnerplayer.talparadio.nl',
  'radio10.nl',
  'radioveronica.nl ',
  'sbs.nl',
  'show.nl',
  'shownieuws.nl',
  'skyradio.nl',
  'social1nfluencers.nl',
  'stuk.tv',
  'talpanetwork.com',
  'veronicainside.nl',
  'weer.nl',
];

/**
 * getEUConsentString
 * @return {string}
 */
export function getEUConsentString(): string {
  return getCookie(EUCONSENT);
}

/**
 * getConsentedGroups
 * @return {string}
 */
export function getConsentedGroups(): string {
  return window?.OnetrustActiveGroups;
}

/**
 * getUrlWithConsent is used for generating the GET parameters for iframe
 * @returns {string}
 */
export const getUrlWithConsent = (source: string): string => {
  // check if source is a talpa domain
  if (!talpaDomains.some((talpaDomain) => source.includes(talpaDomain))) {
    return source;
  }

  const euconsent = getEUConsentString();
  const consentedGroups = getConsentedGroups();

  let queryString = '';
  if (euconsent && consentedGroups) {
    queryString = `${EUCONSENT}=${euconsent}&onetrust-active-groups=${consentedGroups}`;
  } else if (euconsent) {
    queryString = `${EUCONSENT}=${euconsent}`;
  } else {
    return source;
  }

  if (source.indexOf('?') === -1) {
    return `${source}?${queryString}`;
  }
  return `${source}&${queryString}`;
};

/**
 * Check if vendor has consent
 *
 * @param {Vendor} vendor
 */
export default function vendorAllowed(vendor: Vendor): boolean {
  if (getPurposesAllowed(vendor.purposes) === false) {
    logger.warn(`%c Vendor ${vendor.name} has NOT been given consent`, 'background-color:darkred; color: white');
    return false;
  }
  return true;
}

/**
 * Check a list of vendors for consent
 * @param {array} vendorArray
 * @param {string} source
 */
export const checkVendors = (vendorArray: [Vendor], source: string): boolean => {
  if (!vendorArray.some(({ url }) => source.includes(url))) {
    logger.warn(`source ${source} not in vendorlist`);
    return true;
  }
  return vendorArray.some((vendor) => source.includes(vendor.url) && vendorAllowed(vendor));
};

/**
 * Decodes the TCF string from a OneTrust cookie and
 * compares purposeConsents with the passed array of purposes
 * @param {array} purposes
 * @returns {Boolean}
 */
function getPurposesAllowed(purposes: [number]): boolean {
  if (Array.isArray(purposes) === false) {
    logger.error('TypeError: Function "util/arePurposesAllowed()" expected an array of purposes to check against.');
    return false;
  }
  const tcfString = getCookie(EUCONSENT);
  const consentData = tcfString && TCString.decode(tcfString);
  if (!consentData) return false;

  return purposes.every((purpose) => consentData.purposeConsents.has(purpose));
}

/**
 * Returns the consent query parameter for Triton stream and caches this. We expect
 * the value to only change via external privacy site so a refresh is inevitable.
 * @returns {string}
 * This talpa_consent:1 ttag allows the delivery of IO ads (IO stands for ingest order, which means sold by OMS sales team)
 * even if there is no consent for targeted programmatic ads
 * The value should either be passed to Triton via the SDK, or as a ttag parameter.
 */
export const tritonConsentTag = (): string => {
  const { purposes } = window.VENDORS.TALPA_CONSENT;
  return getPurposesAllowed(purposes) ? 'talpa_consent:1' : 'talpa_consent:0';
};

export const CONSENT_CHANGED_EVENT = 'OTConsentChanged';

/**
 * OneTrust is using OptanonWrapper as callback function whenever consent changes.
 * We use the function to dispatch synthetic event that can be consumed whenever we need it.
 */
window.OptanonWrapper = () => {
  dispatchEvent(new Event(CONSENT_CHANGED_EVENT));
};

/**
 * The event handler is listening the consent change event and
 * invokes the callback each time the event occurs
 */
export const onConsentChange = (callback: () => void): void => {
  window.addEventListener(CONSENT_CHANGED_EVENT, callback);
};
