import React, { useState } from 'react';

import CONTENT_TYPES from 'globalConst/contentTypes-const';

import { useFetchData } from 'components/hooks';
import Placeholder from 'components/Search/Placeholder';
import ContentTypeComponent from 'components/ContentTypeComponent';

const PopularSearch = (): JSX.Element => {
  const [usePlaceholder, setUsePlaceholder] = useState(false);

  const { data, isLoading } = useFetchData({
    slug: 'search-overview',
    contentTypeId: CONTENT_TYPES.OVERVIEW_COLLECTION,
    store: true,
  });

  const { sections } = data || {};

  function handleFetchError() {
    setUsePlaceholder(true);
  }

  const sectionProps = {
    isInSearch: true,
    onError: handleFetchError,
    isComponentLoading: isLoading,
  };

  if (usePlaceholder) {
    return <Placeholder />;
  }

  return (
    <>
      {sections &&
        sections.map((section) => (
          <ContentTypeComponent isComponentLoading={isLoading} key={section.uuid} {...section} {...sectionProps} />
        ))}
    </>
  );
};

export default PopularSearch;
