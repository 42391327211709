import gql from 'graphql-tag';
import { IMAGE_FRAGMENT, MEDIA_FRAGMENT, CLIP_FRAGMENT } from './fragments';

/**
 * Keep in mind this new query doesn't accept slugs as the previous one
 * Some fields from the previous query are deprecated and won't persist for some moments, pay attention when adjusting the model component
 */
export const getSearch = gql`
  query SearchResults($query: String!) {
    searchResults(input: $query) {
      results {
        id
        slug
        items {
          id
          title
          type
          config {
            type
            entries {
              key
              value
            }
          }
          options
          references {
            ... on StationCollection {
              id
              title
              slug
              type
              getStations {
                stations {
                  id
                  type
                  slug
                  title
                  shortTitle
                  media {
                    ...MediaFragment
                  }
                  images {
                    ...ImageFragment
                  }
                  config {
                    entries {
                      value
                      key
                    }
                  }
                  getPlayouts(limit: 1) {
                    playouts {
                      track {
                        artistName
                        title
                      }
                    }
                  }
                }
              }
            }

            ... on PodcastCollection {
              id
              title
              slug
              type
              getPodcasts {
                podcasts {
                  id
                  type
                  slug
                  title
                  subTitle
                  images {
                    ...ImageFragment
                  }
                  getClips(limit: 1) {
                    clips {
                      id
                      title
                      description
                      media {
                        ...MediaFragment
                      }
                      durationSeconds
                      broadcastDate
                      publishDate
                      reference {
                        ... on Podcast {
                          id
                          feedUrl
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }

            ... on ShowCollection {
              title
              type
              slug
              id
              getShows(limit: 100) {
                shows {
                  title
                  slug
                  type
                  media {
                    ...MediaFragment
                  }
                  images {
                    ...ImageFragment
                  }
                  startTime
                  endTime
                  config {
                    entries {
                      value
                      key
                    }
                  }
                  references {
                    ... on Station {
                      type
                      slug
                      title
                      shortTitle
                    }
                  }
                }
                totalCount
              }
            }
            ... on TagCollection {
              title
              type
              slug
              id
              type
              getTags {
                tags {
                  title
                  type
                  slug
                  config {
                    entries {
                      key
                      value
                    }
                  }
                  images {
                    ...ImageFragment
                  }
                }
              }
            }

            ... on ClipCollection {
              id
              type
              slug
              title
              getClips(limit: 25) {
                clips {
                  ...ClipFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${CLIP_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${MEDIA_FRAGMENT}
`;
