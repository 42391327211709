import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg viewBox="0 0 72 72">
    <path d="M64,62.3H10.3c-1.2,0-2.3-1-2.3-2.3V23.7c0-1.2,1-2.3,2.3-2.3H64c1.2,0,2.3,1,2.3,2.3V60C66.3,61.2,65.2,62.3,64,62.3z M10.6,59.8h53.2V23.9H10.6V59.8z" />
    <path d="M27.7,53.2c-5.8,0-10.5-4.7-10.5-10.6s4.7-10.5,10.5-10.5s10.6,4.7,10.6,10.5S33.5,53.2,27.7,53.2z M27.7,34.6c-4.4,0-8,3.6-8,8c0,4.4,3.6,8.1,8,8.1c4.4,0,8.1-3.6,8.1-8.1C35.7,38.2,32.1,34.6,27.7,34.6z" />
    <path d="M59.7,37.1H43.2c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h16.5c0.7,0,1.3,0.6,1.3,1.3S60.4,37.1,59.7,37.1z" />
    <path d="M59.7,44.1H43.2c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h16.5c0.7,0,1.3,0.6,1.3,1.3S60.4,44.1,59.7,44.1z" />
    <path d="M59.7,51.1H43.2c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h16.5c0.7,0,1.3,0.6,1.3,1.3S60.4,51.1,59.7,51.1z" />
    <path d="M44,23.9c-0.4,0-0.8-0.2-1-0.5L32.6,8.9c-0.4-0.6-0.3-1.3,0.3-1.7c0.6-0.4,1.3-0.3,1.7,0.3L45.1,22c0.4,0.6,0.3,1.3-0.3,1.7C44.6,23.9,44.3,23.9,44,23.9z" />
  </svg>
);

export default svgIcon(svg);
