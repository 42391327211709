export const CONTENT_TYPES = {
  OVERVIEW: 'overview',
  OVERVIEW_COLLECTION: 'overviewCollection',
  NAVIGATION_COLLECTION: 'navigationCollection',
  CLIP: 'clip',
  CLIP_COLLECTION: 'clipCollection',
  PODCAST: 'podcast',
  PODCAST_COLLECTION: 'podcastCollection',
  SHOW: 'show',
  STATION: 'station',
  STATION_PAGE: 'stationPage',
  TRACK: 'track',
  BANNER: 'banner',
  CALL_TO_ACTION_BLOCK: 'call-to-action',
  CARD: 'card',
  MIXED_COLLECTION: 'mixedCollection',
  SHOW_COLLECTION: 'showCollection',
  SCHEDULES: 'schedules',
  ONDEMAND_TILE: 'ondemand-tile',
  SHOW_TILE: 'show-tile',
  STATION_TILE: 'station-tile',
  PROMOTION_COLLECTION: 'promotionCollection',
  PROMOTION: 'promotion',
  CAMPAIGN: 'campaign',
  STATION_COLLECTION: 'stationCollection',
  TAG_COLLECTION: 'tagCollection',
  ON_DEMAND_COLLECTION: 'onDemandCollection',
  RECENTLY_PLAYED_STATIONS_COLLECTION: 'recentlyPlayedStationsCollection',
  TABS: 'tabs',
  RANDOM_STATION: 'randomStation',
  GENERATED: 'generated', // used when generating placeholders
  FEATURED_PODCASTS: 'featuredPodcast',
  FAVORITE_STATION_COLLECTION: 'favoriteStationCollection',
  FAVORITE_PODCAST_COLLECTION: 'favoritePodcastCollection',
  FAVORITE_CLIP_COLLECTION: 'favoriteClipCollection',
  CONTINUE_LISTENING_CLIP_COLLECTION: 'continueListeningClipCollection',
  FOOTER_NAVIGATION_COLLECTION: 'footerNavigationCollection',
  BRAND: 'brand',
  SEARCH: 'search',
};

export { CONTENT_TYPES as default };
