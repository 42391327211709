import { events, ICoreAudioPlayer, PlayerEvents, IEvent, player } from '@4tn/core-audio-player-v2';
import * as analytics from 'analytics';
import { isIE11andLower } from 'util/device';
import Timer from './Timer';
import { PodcastEpisode, Station } from 'api/models';
import { CLIP } from 'globalConst/const';

const HEALTHY_PLAY_DURATION = 30 * 1000;
const HEALTHY_PLAY_CHECK_INTERVAL = 1000;

interface IMediaEvent {
  media?: {
    contentType?: string;
  };
  contentType?: string;
}

export default class AudioPlayer {
  /**
   * Global user setting determining whether we should try to retrieve HD or SD
   * sources
   */
  static prefersHD = false;
  /**
   * Propery which prevents attaching events more than oce
   * sources
   */
  static eventsAttached = false;

  timeDiffUpdate = isIE11andLower ? 1 : 0.5;
  player: ICoreAudioPlayer;
  playSessionId: string | null;
  healthyPlayDetector: Timer | null;
  errorCallback: (...args: unknown[]) => void;
  stopCallback: () => void;
  playCallback: () => void;
  reconnectingCallback: () => void;
  reconnectCallback: (event: IEvent) => void;
  disconnectedCallback: () => void;
  static preferHD: boolean;
  station: Station;
  onDemandClip: PodcastEpisode;
  currentTime: number;

  constructor() {
    this.player = player;
    this.playSessionId = null;
    this.healthyPlayDetector = null;

    this.errorCallback = () => {
      throw new Error('Error callback must be set');
    };
    this.stopCallback = () => {
      throw new Error('Stop callback must be set');
    };
    this.playCallback = () => {
      throw new Error('Play callback must be set');
    };
    this.reconnectingCallback = () => {
      throw new Error('Reconnecting callback must be set');
    };
    this.reconnectCallback = () => {
      throw new Error('Reconnect callback must be set');
    };
    this.disconnectedCallback = () => {
      throw new Error('Disconnected callback must be set');
    };

    if (!AudioPlayer.eventsAttached) {
      this.attachEvents();
      AudioPlayer.eventsAttached = true;
    }
  }

  /**
   * Instance getter for static property "preferHD"
   */
  get prefersHD(): boolean {
    return AudioPlayer.preferHD;
  }

  /**
   * Instance setter for static property "preferHD"
   */
  set prefersHD(useHD: boolean) {
    if (typeof useHD !== 'boolean') {
      throw new TypeError('Failed setting static property "preferHD". Incorrect type. Expected a boolean.');
    }

    AudioPlayer.preferHD = useHD;
  }

  /**
   * Attaches events exported from Core Audio Player
   *
   */
  attachEvents(): void {
    events.on(PlayerEvents.STREAM_START, this.onStreamPlay.bind(this));
    events.on(PlayerEvents.STREAM_RESUMED, this.onStreamPlay.bind(this));
    events.on(PlayerEvents.PLAYER_PLAYING, this.onStreamPlaying.bind(this));
    events.on(PlayerEvents.STREAM_STOP, this.onStreamStopPlaying.bind(this));
    events.on(PlayerEvents.STREAM_PAUSE, this.onStreamStopPlaying.bind(this));
    events.on(PlayerEvents.PLAYER_RECONNECTING, this.onStreamReconnecting.bind(this));
    events.on(PlayerEvents.PLAYER_RECONNECT, this.onStreamReconnect.bind(this));
    events.on(PlayerEvents.PLAYER_DISCONNECTED, this.onStreamDisconnected.bind(this));
  }

  /**
   * Handler for when the audio stream starts/resumes playing
   *
   */
  onStreamPlay(e: IMediaEvent): void {
    analytics.stopHeartBeat();

    if (this.station || this.onDemandClip || e?.media?.contentType === CLIP || e?.contentType === CLIP) {
      analytics.startHeartBeat();
    }
    this.playCallback();
  }

  /**
   * Handler for the audio player_playing event which is triggered by the system
   *
   */
  onStreamPlaying(): void {
    this.playCallback();
  }

  /**
   * Handler for when the audio stream stops/pauses playing
   *
   */
  onStreamStopPlaying(): void {
    analytics.stopHeartBeat();
    this.stopCallback();
  }
  /**
   * Handler for when the audio stream starts reconnecting
   *
   */
  onStreamReconnecting(): void {
    this.reconnectingCallback();
  }

  /**
   * Handler for when the audio stream gets reconnected
   *
   */
  onStreamReconnect(event: IEvent): void {
    this.reconnectCallback(event);
  }

  /**
   * Handler for when the audio stream gets disconnected
   *
   */
  onStreamDisconnected(): void {
    this.disconnectedCallback();
  }

  /**
   * Uses the experimental Android/Chrome Media Session API to set the meta data
   * for the lock screen
   *
   */
  setMediaSessionData = (title: string, imageURL: string): void => {
    if (!title) {
      return;
    }

    if ('mediaSession' in navigator) {
      const config = {
        title,
      };

      if (imageURL) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (config as any).artwork = {
          src: imageURL,
        };
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigator.mediaSession.metadata = new MediaMetadata({ config });
    }
  };

  /**
   * Sets the error callback to be used by the player
   *
   */
  setErrorCallback(
    callback: (
      error: { name: string; message: string },
      mediaItem: Station | PodcastEpisode,
      playSessionId: string
    ) => void
  ): void {
    this.errorCallback = callback;
  }

  /**
   * Sets the play callback to be used by the player
   *
   */
  setPlayCallback(callback: () => void): void {
    this.playCallback = callback;
  }

  /**
   * Sets the stop callback to be used by the player
   *
   */
  setStopCallback(callback: () => void): void {
    this.stopCallback = callback;
  }

  /**
   * Sets the reconnect callback to be used by the player
   *
   */
  setReconnectCallback(callback: (event: IEvent) => void): void {
    this.reconnectCallback = callback;
  }

  /**
   * Sets the reconnecting callback to be used by the player
   *
   */
  setReconnectingCallback(callback: () => void): void {
    this.reconnectingCallback = callback;
  }

  /**
   * Sets the disconnected callback to be used by the player
   *
   */
  setDisconnectedCallback(callback: () => void): void {
    this.disconnectedCallback = callback;
  }

  /**
   * Given a callback, binds it to a Timer with a given duration and interval
   *
   */
  trackHealthyPlay(healthyPlayCallback: (playSessionId: string) => void): void {
    this.healthyPlayDetector = new Timer(HEALTHY_PLAY_DURATION, HEALTHY_PLAY_CHECK_INTERVAL, () =>
      healthyPlayCallback(this.playSessionId)
    );
  }

  /**
   * Binds the given callback to the audioElement's timeupdate event
   */
  timeUpdate(callback: (currentTime: number) => void): void {
    // Lower the amount of dispatches to every second
    events.on(PlayerEvents.TIME_UPDATE, (event: IEvent) => {
      const currentTime = event.time;
      if (Math.abs(this.currentTime - currentTime) > this.timeDiffUpdate) {
        this.currentTime = currentTime;
        callback(currentTime);
      }
    });
  }

  /**
   * Stops the audio playback
   */
  async stop(): Promise<void> {
    this.healthyPlayDetector.pause();
    return await this.player.stop();
  }

  /**
   * Pauses the audio playback
   */
  pause(): void {
    this.healthyPlayDetector.pause();
    this.player.pause();
  }

  /**
   * Sets the playback volume based on the given volumeLevel
   *
   */
  setVolumeLevel(volumeLevel: number): void {
    this.player.setVolumeLevel(volumeLevel);
  }

  /**
   * Mutes/Unmutes playback based on given muted
   *
   */
  setMuted(muted: boolean): void {
    if (!this.player.isReady() || this.player.isMuted === muted) {
      return;
    }

    if (muted) {
      this.player.mute();
      this.healthyPlayDetector.pause();
    } else {
      this.player.unmute();
      this.healthyPlayDetector.resume();
    }
  }

  /**
   * Binds the given callback to the audioElement's ended event
   */
  endedPlay(callback: () => void): void {
    events.on(PlayerEvents.STREAM_ENDED, () => {
      callback();
    });
  }

  /**
   * Unimplemented play function (scaffold for inheriting classes)
   * @abstract
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async play(..._args: unknown[]): Promise<void> {
    throw new Error(`must be implemented by subclass ${this.playSessionId}`);
  }

  /**
   * Unimplemented resume function (scaffold for inheriting classes)
   * @abstract
   */
  async resume(): Promise<void> {
    throw new Error(`must be implemented by subclass ${this.playSessionId}`);
  }

  /**
   * Unimplemented load function (scaffold for inheriting classes)
   * @abstract
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async load(_onDemandPlaybackTime?: number): Promise<void> {
    throw new Error(`must be implemented by subclass ${this.playSessionId}`);
  }
}
