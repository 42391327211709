import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { getImageWithResamplingQuery } from 'util/resampling';

import { CALL_TO_ACTION_BLOCK_LAYOUT_TYPES, CALL_TO_ACTION_TYPES } from './CallToActionBlock.const';
import CallToAction from './CallToAction';

import styles from './CallToActionBlock.styles';

const mediaPositions = {
  LEFT: 'Left',
  RIGHT: 'Right',
  FULL_WIDTH: 'Full width',
};

const backgrounds = {
  GREY: 'greyBackground',
  GRADIENT: 'gradientBackground',
  DARK: 'darkBackground',
};

const textColors = {
  WHITE: 'whiteText',
  BLACK: 'blackText',
  GREY: 'greyText',
};

const layoutConfig = {
  Default: {
    background: backgrounds.GREY,
    ctaType: CALL_TO_ACTION_TYPES.BLUE,
    textColor: textColors.BLACK,
  },
  Gradient: {
    background: backgrounds.GRADIENT,
    ctaType: CALL_TO_ACTION_TYPES.WHITE_OUTLINED,
    textColor: textColors.WHITE,
  },
  Dark: {
    background: backgrounds.DARK,
    ctaType: CALL_TO_ACTION_TYPES.DARK,
    textColor: textColors.WHITE,
  },
  Light: {
    textColor: textColors.GREY,
  },
};

const useStyles = createUseStyles(styles, { name: 'CallToActionBlock' });

function CallToActionBlock({
  buttonText,
  image,
  mediaPosition,
  layout,
  pageLink,
  preferHD,
  playStationSlug,
  showTitle,
  text,
  title,
  url,
  useThumbnail,
}) {
  const classes = useStyles();

  const { url: imageUrl } = image || {};
  const { LIGHT, DEFAULT, DARK, GRADIENT } = CALL_TO_ACTION_BLOCK_LAYOUT_TYPES;
  const { LEFT, FULL_WIDTH } = mediaPositions;

  /**
   * check for deprecated layouts
   * those should be removed after release
   * */
  switch (layout) {
    case 'Horizontal dark':
      layout = DARK;
      break;
    case 'Horizontal light':
      layout = DEFAULT;
      break;
    case 'Gradient Background with Image':
      layout = GRADIENT;
      mediaPosition = LEFT;
      break;
    case 'Image left':
      layout = LIGHT;
      mediaPosition = LEFT;
      break;
    case 'Image right':
      layout = LIGHT;
      break;
    default:
      break;
  }

  const { background, ctaType, textColor } = layoutConfig[layout] || {};

  const longTitleAmount = window.innerWidth < 375 ? 25 : 30;
  const isHorizontal = !text && !imageUrl;
  const isVertical = mediaPosition === FULL_WIDTH;

  return (
    <div
      data-testid="callToActionBlock"
      className={classNames(classes.container, {
        [classes.containerVertical]: isVertical,
        [classes.containerMediaLeft]: mediaPosition === LEFT,
        [classes.containerThumbnail]: !isHorizontal && useThumbnail && imageUrl,
        [classes.containerWithImage]: !isVertical && imageUrl && !useThumbnail,
        [classes.containerHorizontal]: isHorizontal,
        [classes.containerButtonOnly]: !title && isHorizontal,
        [classes.centerContent]: !isHorizontal && !imageUrl && layout === LIGHT,
        [classes[background]]: background && classes[background],
        [classes[textColor]]: !isVertical && textColor && classes[textColor],
      })}
    >
      <div className={classes.contentContainer}>
        {title && showTitle && (
          <h3
            className={classNames(classes.title, {
              [classes.longTitle]: !isVertical && title.length >= longTitleAmount,
            })}
          >
            {title}
          </h3>
        )}
        {text && <div className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />}
        {isVertical && imageUrl && (
          <img
            className={classes.image}
            src={getImageWithResamplingQuery({
              url: imageUrl,
              customWidth: 1290,
            })}
            alt={image.title}
          />
        )}
        <div className={classes.ctaContainer}>
          <CallToAction
            buttonText={buttonText}
            ctaType={isVertical || (useThumbnail && layout === DEFAULT) ? null : ctaType}
            pageLink={pageLink}
            playStationSlug={playStationSlug}
            preferHD={preferHD}
            title={title}
            url={url}
          />
        </div>
      </div>
      {!isVertical && imageUrl && (
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={getImageWithResamplingQuery({
              url: imageUrl,
              customWidth: 600,
              isTransparent: true,
            })}
            alt={image.title}
          />
        </div>
      )}
    </div>
  );
}

CallToActionBlock.defaultProps = {
  buttonText: null,
  image: null,
  mediaPosition: null,
  layout: null,
  pageLink: null,
  playStationSlug: null,
  preferHD: false,
  showTitle: true,
  text: '',
  title: null,
  url: '',
  useThumbnail: false,
};

CallToActionBlock.propTypes = {
  buttonText: PropTypes.string,
  mediaPosition: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
  layout: PropTypes.oneOf(Object.values(CALL_TO_ACTION_BLOCK_LAYOUT_TYPES)),
  pageLink: PropTypes.shape({
    slug: PropTypes.string,
    parentSlug: PropTypes.string,
  }),
  preferHD: PropTypes.bool,
  playStationSlug: PropTypes.string,
  showTitle: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  useThumbnail: PropTypes.bool,
};

export default CallToActionBlock;
