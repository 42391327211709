import React from 'react';
import { createUseStyles } from 'react-jss';

import styles from './PageContent.styles';
import { getSearchIsActive } from '../../store/reducers/search';
import { useSelector } from 'react-redux';

const useStyle = createUseStyles(styles, { name: 'PageContent' });
const PageContent = ({ children }) => {
  const isInSearch = useSelector(getSearchIsActive);
  const classes = useStyle();
  if (isInSearch) {
    return null;
  }

  return <div className={classes.contentWrapper}>{children}</div>;
};

export default PageContent;
