import { connect } from 'react-redux';
import Video from './Video';
import { NotificationTypes, NotificationTexts } from '../Notifications/Notification';
import { stop } from '../../actions/player-actions';
import { showNotification } from '../../actions/notifications-actions';
import {
  inlineMediaError,
  inlineMediaPause,
  inlineMediaPlay,
  inlineMediaStop,
} from '../../actions/inline-media-actions';
import { isCurrentlyPlayingMedia } from '../../store/reducers/inlineMedia';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isPlaying: isCurrentlyPlayingMedia(state, ownProps.url),
});

const mapDispatchToProps = (dispatch) => ({
  onInlineMediaPlay: async (mediaUrl) => {
    // Stop the main player playback
    dispatch(stop(false));
    // Dispatch inline play action
    dispatch(inlineMediaPlay(mediaUrl));
  },
  onInlineMediaStop: () => {
    dispatch(inlineMediaStop());
  },
  onInlineMediaPause: () => {
    dispatch(inlineMediaPause());
  },
  onInlineMediaErrored: () => {
    dispatch(inlineMediaError());
    // Show an error notification
    dispatch(
      showNotification({
        type: NotificationTypes.ERROR,
        message: NotificationTexts.PLAYBACK_CLIP_ERROR,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
