export function isChrome() {
  return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}

export const isHuawei = window.navigator.userAgent.indexOf('hap') >= 0;

export function isIos() {
  return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i) !== null;
}

export function isAndroid() {
  return navigator.userAgent.match(/(android)/i) !== null;
}

export function isIframe() {
  return !!(window.frameElement || window !== window.parent);
}

export const isMacintosh = !!(navigator.platform.indexOf('Mac') > -1);

export const isIE11andLower = !!(
  navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1
);

export const isEdge = !!(navigator.userAgent.indexOf('Edge') > -1);

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export function isTablet() {
  // Prevent having tablet mode active for IE on the PCs with touch screen
  if (isIE11andLower) {
    return false;
  }

  if (
    navigator.userAgent.match(
      /(iPad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i
    )
  ) {
    return true;
  }

  if (navigator.userAgent.match(/android(?!.*Mobile)/i)) {
    return window.matchMedia('(min-device-width: 800px)').matches;
  }

  // Safari on iPad OS
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
  }

  return false;
}

export const isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(hap)|(webOS)/i) !== null && !isTablet();
export const isTouch = isTablet() || isMobile;

export function isDesktopApp() {
  return navigator.userAgent.match(/(electron)/i) !== null;
}

// Getting OS from desktop app userAgent
export function getDesktopOs() {
  const device = window.navigator.userAgent.toLocaleLowerCase();
  let os = null;
  if (device.includes('juke-osx')) {
    os = 'osx';
  } else if (device.includes('juke-windows')) {
    os = 'windows';
  } else if (device.includes('juke-linux')) {
    os = 'linux';
  }
  return os;
}

/**
 * Check whether the HD toggle should be available or not
 * @returns {boolean}
 */
export function isHDsupported() {
  return !isIE11andLower && !isTouch;
}

export function determineAppUrl() {
  let appUrl = '';
  if (isAndroid()) {
    appUrl = process.env.REACT_APP_JUKE_PREMIUM_ANDROID_APP;
  } else if (isIos()) {
    appUrl = process.env.REACT_APP_JUKE_PREMIUM_IOS_APP;
  } else {
    appUrl = process.env.REACT_APP_JUKE_PREMIUM_WEB;
  }
  return appUrl;
}

export function isLandscapeMode() {
  return window.orientation > 0;
}

export function isContentWider(parentElement, childElement) {
  if (parentElement && childElement) {
    return childElement.offsetWidth > parentElement.offsetWidth;
  }
  return null;
}

/**
 * Returns valid pseudo selector for placeholder depending on browser.
 * @param {boolean} [isPrependAmpersand=true] - prepend &, eg: '&::placeholder'
 * @returns {string}
 */
export function getPlaceholderPseudo(isPrependAmpersand = true) {
  let placeholderString = '::placeholder';

  if (isIE11andLower) {
    placeholderString = ':-ms-input-placeholder';
  } else if (isEdge) {
    placeholderString = '::-ms-input-placeholder';
  }

  if (isPrependAmpersand) {
    placeholderString = `&${placeholderString}`;
  }

  return placeholderString;
}

/**
 * Check whether the screen is retina or not
 * @returns {boolean}
 */
export function checkForRetinaScreen() {
  const query = '(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
  return window.matchMedia(query).matches;
}
