export const STATION_FRAGMENT = `
  fragment StationFragment on Station {
    id
    type
    title
    description
    shortTitle
    slug
    labels
    metadata {
      title
      description
      contentType
      shareLink
      shareTitle
      shareText
    }
    media {
      ...MediaFragment
    }
    images {
      ...ImageFragment
    }
    tags {
      slug
      title
      type
    }
    config {
      ...ConfigFragment
    }
  }
  `;
