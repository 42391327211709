import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M16.5,3 C16.776,3 17,3.224 17,3.5 L17,14.5 C17,15.881 15.881,17 14.5,17 C13.119,17 12,15.881 12,14.5 C12,13.119 13.119,12 14.5,12 C15.065,12 15.581,12.195 16,12.511 L16,7 L8,7 L8,14.5 C8,15.881 6.881,17 5.5,17 C4.119,17 3,15.881 3,14.5 C3,13.119 4.119,12 5.5,12 C6.065,12 6.581,12.195 7,12.511 L7,3.5 C7,3.224 7.224,3 7.5,3 L16.5,3 Z M14.5,16 C15.327,16 16,15.327 16,14.5 C16,13.673 15.327,13 14.5,13 C13.673,13 13,13.673 13,14.5 C13,15.327 13.673,16 14.5,16 Z M5.5,16 C6.327,16 7,15.327 7,14.5 C7,13.673 6.327,13 5.5,13 C4.673,13 4,13.673 4,14.5 C4,15.327 4.673,16 5.5,16 Z M8,6 L16,6 L16,4 L8,4 L8,6 Z"
      />
    </g>
  </svg>
);

export default svgIcon(svg);
