import * as cssVariable from 'cssVariables';
import { convertHextoRGB } from 'util/index';

export default (theme) => ({
  switch: {
    width: 42,
    height: 22,
    borderRadius: 12,
    background: cssVariable.grey500,
    position: 'relative',
    fontSize: 0,
    border: 'none',
    transition: 'background, 200ms',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:after': {
      content: '""',
      ...cssVariable.fillAspectRatio,
      zIndex: -1,
      background: cssVariable.grey500,
    },

    '&:before': {
      content: '""',
      width: 20,
      height: 20,
      borderRadius: '100%',
      position: 'absolute',
      background: cssVariable.white,
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
      top: 1,
      left: 0,
      transition: 'transform .4s',
    },

    '&:hover': {
      background: `rgba(${convertHextoRGB(theme.palette.backgroundSecondary, 80)})`,
    },
  },

  light: {
    background: cssVariable.grey300,
  },

  active: {
    background: cssVariable.green500,
    '&:before': {
      transform: 'translateX(22px)',
    },
    '&:hover': {
      background: cssVariable.green400,
    },
  },
});
