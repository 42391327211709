import gql from 'graphql-tag';
import { MEDIA_FRAGMENT, IMAGE_FRAGMENT, STATION_FRAGMENT, CONFIG_FRAGMENT } from './fragments';

export const getStations = gql`
  query GetStations($slug: String!) {
    collection(slug: $slug) {
      ... on StationCollection {
        id
        title
        description
        options
        items {
          id
          type
          title
          shortTitle
          slug
          description
          media {
            ...MediaFragment
          }
          images {
            ...ImageFragment
          }
          tags {
            slug
            title
            type
          }
          config {
            type
            entries {
              key
              value
              type
            }
          }
        }
      }
    }
  }
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

export const getStationsTrack = gql`
  query GetStationsTrack($slug: String!, $limit: Int) {
    collection(slug: $slug) {
      ... on StationCollection {
        id
        items {
          slug
          getPlayouts(limit: $limit) {
            playouts {
              track {
                title
                artistName
              }
            }
          }
        }
      }
    }
  }
`;

export const getStation = gql`
  query GetStation($slug: String!) {
    station(slug: $slug) {
      ... on Station {
        ...StationFragment
      }
    }
  }
  ${STATION_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${CONFIG_FRAGMENT}
`;

export const getStationShareImage = gql`
  query GetStationShareImage($slug: String!) {
    station(slug: $slug) {
      images {
        imageType
        uri
      }
    }
  }
`;

export const getPlayouts = gql`
  query GetPlayouts($slug: String!, $limit: Int) {
    station(slug: $slug) {
      id
      slug
      getPlayouts(limit: $limit) {
        playouts {
          track {
            id
            isrc
            title
            artistName
            images {
              uri
            }
          }
        }
      }
    }
  }
`;

export const playoutSubscription = gql`
  subscription onPlayoutEvent($slug: String!) {
    playoutEvent(slug: $slug) {
      slug
      id
      getPlayouts {
        playouts {
          track {
            id
            isrc
            title
            artistName
            images {
              uri
            }
          }
        }
      }
    }
  }
`;
