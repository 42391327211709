import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import { getAirDate, getDaysAgo, getAirDateWithYear } from 'lib/onDemand';

import { LABEL } from 'globalConst/const';

import * as cssVariable from 'cssVariables';

function AirDate({ classes, date, showDays, showNewLabel }) {
  if (!date) return null;

  if (showDays) {
    const daysAgo = getDaysAgo(date);
    const daysLabels = [LABEL.TODAY, LABEL.YESTERDAY];

    return (
      <Fragment>
        {showNewLabel && daysAgo <= 30 && <span className={classes.newLabel}>{LABEL.NEW}</span>}
        {daysLabels[daysAgo] || getAirDateWithYear(date)}
      </Fragment>
    );
  }
  return getAirDate(date);
}

AirDate.propTypes = {
  classes: PropTypes.object.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showDays: PropTypes.bool,
  showNewLabel: PropTypes.bool,
};

AirDate.defaultProps = {
  showDays: false,
  showNewLabel: false,
};

const styles = {
  newLabel: {
    borderRadius: 2,
    color: cssVariable.white,
    fontSize: cssVariable.fontSizes.xxsmall,
    fontWeight: cssVariable.fontWeights.bold,
    height: 20,
    width: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: cssVariable.red500,
    marginRight: 10,
  },
};

export default injectStyles(styles)(AirDate);
