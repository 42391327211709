export const NonTalpaStations = `100% nl
100% nl feest
100% nl liefde
100% nl puur
arrow bluesbox radio
arrow caz
candlelight
classicnl mind radio
decibel 80's music
decibel eurodance
decibel greatest hits
decibel ibiza
decibel party
decibel sound of ibiza
deep radio
girlpower radio
grand prix radio
grand prix radio classics
grand prix radio dance hits
grolsch 0.0 fm
sublime smooth
hofstad radio
jumbo radio
kink
kink distortion
kink dna
kink indie
masters of hardcore
nostalgie
q dance
radio 121
radio 509
radio 972
radio decibel
rivierenland radio
100% nl carnaval
100% nl kerst
100% nl nederpop
100% nl non stop
100% nl songfestival
100% nl zomer
192 radio
1a deutsche hits
1a partyschlager
1achterhoek
1twente
247hits.nl
24disco
3provincienstreamteam
40rock radio
40up radio
4ever49 radio
4everradio
70s 80s all time greatest
80s alive
80s hitradio
80s power radio
a.d.m. hardstyle radio
aalburg fm 2.0
accentfm
aldiana radio
alfa radio
all time hits radio
allroundradio
allsportsradio
ally radio
altijd hitradio
amorfm
amplivier radio
amstel team drachten
amw amsterdams most wanted
antenne domstad
assembly of zion
astradio
atlantica breda
atos radio
attic radio
aural melodics
aural oldies
aural radio
aural west
bbc radio 1
bbc radio 1 xtra
bbc radio 2
bbc radio 3
bbc radio 4
bbc radio 5 live
bbc radio 6 music
bbc world service
beargroup radio
beatmusic
beethoven channel
belgian dance radio
bem heaven
bestehits.nl
bieos - omroep
bigb21
bingo fm
blue fm 107.5
bnr
bo - bollenstreek
bongo radio
boomradio
bos 960
bouzouky fm
bredanu
bremen eins
bremen next
bremen vier
bremen zwei
brightfm nl
brightfm plus
c dance retro
capital fm
capital radio
capital xtra
cardea music radio
carnaval radio
caroline radio
celtcast
chat2all radio
chatrock
chattersplaza
chÃ©rie fm celine dion
chÃ©rie fm frenchy
chÃ©rie fm love songs
chillout crooze
chillout zone
chillout zone stream
christmas fm
christmas24
classic 21 60's
classic 21 70's
classic 21 80's
classic 21 90's
classic 21 blues
classic 21 metal
classic 21 noire jaune rock
classic 21 route 66
classic 21 soul power
classic hits 106
classical radio stream
Radio Veronica
classicnl mind out
classicnl opera
classicnl soundtracks
classy radio
cloud radio
cobra team
color radio
concertzender filmmuziek
concertzender folk it!
concertzender jazz
concertzender klassiek
cool dance radio
cool radio
cosmo
costa blanca radio
costa hit radio nl
country 4all
country4all
crazy radio
crooze
crossroads country radio
cubefm
cuemusic radio
da beat
dance fm
dance provider
dance radio 1
dancestation
dancevibesradio
dandy radio tenerife
de bandieten uit twente
de beste piratenhits
de club van sinterklaasradio
de goeie ouwe tijd
de hollandse piraten gigant
de piraten bar
de veluwse piraten
de veluwsepiraten
de vrolijke piraat
de westereender piraten
debluesradio (dbr)
def j radio
defouteradio
den haag fm
deutschlandfunk kultur
deutschlandfunk nova
deventer radio
dichtbijfm
dinner crooze
disco classic radio
discoline radio
disconet.fm
disney village radio
ditishelmond
dno radio 1
dno radio 2
dolfijn 80's 90's
dolfijn bright top 40
dolfijn fm
dolfijn hits
dolfijn latin
dolfijn latin pop
dolfijn techno
dolfijn urban
domstad radio
dorpsradio laren
dorpsradio non stop
dorpsradio.nl
double z radio
dragon fm
dream stream radio rotterdam
drentse piraten
dushi ritmo!
easy 95.5 fm
easy crooze
echte piratenhits
echtepiraten.nl
efteling kids radio
el sol bogota
eldora fm
espn radio
europopfm
extra nl
radio veronica
factorystation
fameradio
fantasy radio
favoriet fm
feel good nl
feel good radio
feel good radio 60's & 70's hits
feel good radio costa del sol
feelsogood fm
fg antwerpen
fip radio
flash fm
flash hitradio
flash sound radio
flex radio
fmr
focus 103
forever gold
foute muziek radio
foute radio.nl
free internet radio
free music kerst
free music radio
free music radio kerst
free radio luxembourg
free radio rotterdam
free star radio
freeminded fm
freewave radio
freez fm
fresh radio
frysk fm
funky.radio
funx
funx afro
funx arab
funx hiphop
funx latin
funx reggae
funx slow jamz
gaaas!!! radio
galactica 99.9 fm
geo radio
gerjan op dreef
get back vintage rock radio
gewoon fm
gewoon radio
gewoonpiraten.nl
gigant fm
gigantenteam
gigantvandrenthe
glow fm
glxy
god.radio
gold fm canarias
golden oldies radio
goldenmusicstream
gomes radio
good vibes radio
goodlife radio
goud van oud radio
goudafm
gouden piratenhits
grenslandradio
groningen1
groot nieuws radio
groot nieuws radio blijde klanken
groot nieuws radio non stop
grumpy dutch radio
haarlem shuffle
habbocast
hallokids radio
halloween atmosphere
halloween kids
halloween movies
halloween radio
halloween radio atmosphere
halloween radio kids
halloween radio movies
hardbass radio
hardcore radio
hardradio
hasya katta official
havenstad radio
heart
heart extra
het hapsis huis radio
het stamcafe.nl
hete hits
hetpiratenteam
heuvellandexpress
hietveldfm
higher power radio
hitgolfradio
hitpower
hitradio 915
hitradio centraal fm
hitradio westenwind
hitradio1.nl
hits 70s 80s radio
hits 90s radio
hits4all
hits4you
hitsound fm
hitspowerfm
hitstream.fm
hitz24 the hits only station
hitzzz!!
hoex radio
holland country radio
holland fm
holland fm 2.0
holland fm gran canaria
hollands palet
hollandse hit radio
hollandse piraten jukebox
homeradio
hopparadio
hot dance radio
housebeats.fm
hype radio
ideaal fm
ijsselstreek radio
in progress radio
in tha house
indiexl
indonesian radio
intense radio
intercity radio
intergalactic fm
intergalactic fm disco fetish
intergalactic fm dream machine
iron rtv
iskc blues cafe
iskc extreme metal
iskc hard rock
iskc old mens rock
iskc only live
iskc radio active
iskc rock radio
iskc rock radio rpo
iskc rock radio xxl
j.o.s. radio
jammfm
jazz de ville chill
jazz de ville dance
jazz de ville groove
jazz de ville jazz
jinx radio
joe
joe 10 om te zien
joe 60's & 70's
joe 70's & 80's
joe 80's & 90's
joe belgiÃ«
joe easy
joe lage landen
joe top 2000
jofox radio
jordi
jouradio.fm
jouw 90's radio
joy radio
jukeboxfamilie
juraini feestradio
juraini foute muziek radio
juraini hitradiofm
juraini paradisefm
juraini radio
just.fm
k pop radio
karelfm
kbc radio
keizerstad 80's
keizerstad classics
kerst radio
kerstradio
kick radio
kink 80's
kink classics
kink dna classics
kiss fm
kiss fm r&b
kiss fm sex time
kiss fm urban beats
kiss kiss italia
klasse fm
klassieker uit je speaker
koekstad radio
koempel radio
kommus radio
kool fm 1000
l fm
l.o.v.e. radio
l11alaaf
l1mburg
la carihuela radio
langstraat fm
langstraat nl
layzer
league fm
legends hits radio
leuk.fm
like nl
limburgsejukebox
loatgoan
lodiko mulo
lokaal fm
lokaallive
lokale omroep hoogeveen
lomp radio
loopreizen radio
lostfm
lounge crooze
lovetunes
lucifer fm
luckyonair
maasstad fm
maenda country radio
magic fm
maibus radio
mashup fm charts
masterpowerradio
maxhitradio24
meerradio
megapiratenhits
megastad fm
metal hammer
mijnstreek radio
mnl music
moonlight radio
mp4uradio
music minded
music power radio nl
music radio 4 all
musicbeatsz
musicboxlive
musicpro4u radio
muxlyradio
muziekknallers.nl
mz-radio.nl
nashville fm
nashville radio
nationaal fm
navalia team
ndr 1 niedersachsen
ndr 1 radio mv
ndr 1 welle nord
ndr 2
ndr 90
ndr 90.3
ndr blue
ndr info
ndr info spezial
ndr kultur
ndr schlager
new business radio
new dance radio
news internetradio.nl
nh gooi
nh radio
nicky fm
nieuwerkerk fm
nijhoff fm
nixx fm
n-joy
nl music
nobordersradio
noordzee gold
noordzee kerst
noordzee zomer
nosta+
nostalgie extra 80's
nostalgie extra classics top 2022
nostalgie extra disco
nostalgie extra franstalig
nostalgie extra new wave
nostalgie extra relax
nostalgie extra rock
nostalgie extra vlaams
nostalgie live
nostalgie nederland
nova classic rock
novo3
npo 3fm
npo 3fm alternative
npo 3fm kx
npo klassiek
npo radio 1
npo radio 2
npo radio 2 soul & jazz
npo radio 4
npo radio 4 concerten
npo radio 5
npo sterren.nl
nrg radio
nrj belgiÃ«
nrj non-stop
nshitradio
oke fm
old men's radio
oldies online radio
olleke bolleke radio in thailand
olympia radio
omroep berg en dal
omroep brabant
omroep delft
omroep flevoland
omroep gelderland
omroep het hogeland
omroep horst aan de maas
omroep meierij
omroep p&m
omroep peelrand
omroep sudwest
omroep venlo
omroep vlaardingen
omroep west
omroep zeeland
omroepnoos - hardenberg
omrop fryslân
only 80's radio
oog radio
op volle toeren
orinoco radio
osr 920
ozonop
papendrecht fm
paradise fm
paradise fm 00's & 10's
paradise fm 60's & 70's
paradise fm 80's & 90's
paradise fm non-stop
perfectmoods
pidi radio
pinguin aardschok
pinguin blues
pinguin classics
pinguin fiesta
pinguin grooves
pinguin indie
pinguin on the rocks
pinguin pluche
pinguin pop
pinguin showcase
pinguin vintage
pinguin world
pink!
pink! 90's rewind
piraten stream twente
piratenfamilie radio nl
piratenhits 24 webradio
piratenkanon.fm
piratenpower
piratenschijf
piratensound
piratenteam friesland
piratentukker
piratenvaria
planet 90
plusradio.online
polderpop radio
poprockfm
poprockradio
positiveradio
power dance radio
power online radio
powerhitradio
powerplant classic rock laut fm
powerplant radio
powerplant radio laut fm
powerplay radio
powerxs dance classics
powerxs radio
praatfm
pret radio
prince radio
pro fm
progressieve rock
proradio
pure 24
pure 90s
puur hollandse hits
q the beat
q5 radio
qmusic
qmusic 90s
qmusic 90's
qmusic belgiÃ«
qmusic belgiÃ« 00's
qmusic belgiÃ« 10's
qmusic belgiÃ« 80's
qmusic belgiÃ« 90's
qmusic belgiÃ« downtown
qmusic belgiÃ« foute radio
qmusic belgiÃ« i love the 90's
qmusic belgiÃ« maximum hits
qmusic belgiÃ« maximum summer
qmusic belgiÃ« nederlandstalig
qmusic belgiÃ« non-stop
qmusic belgiÃ« party
qmusic belgiÃ« shut up and dance
qmusic belgiÃ« top 1000
qmusic belgiÃ« top 40
qmusic belgiÃ« workout
qmusic christmas
qmusic het foute uur
qmusic limburg
qmusic nederlandstalig
qmusic non stop
qmusic summer
qmusic top 40
radio 0511
radio 078
radio 079
radio 1224
radio 13
radio 182
radio 202
radio 247
radio 854
radio 864
radio 8fm
radio 92.3 fm
radio 954
radio achterhoek
radio alex fm de / nl
radio alex fm roermond
radio alkmaar
radio amerika rotterdam
radio apintie
radio bellissima christmas
radio bellissima classic
radio bellissima hits
radio bellissima italia
radio bellissima jazz
radio bellissima latina
radio bellissima metal
radio bellissima new wave
radio bellissima relax
radio bliekenstad gorinchem
radio bullet
radio bullit
radio caliente
radio calimero
radio calypso
radio caprice death metal
radio caramba
radio carola
radio caroline
radio caroline flashback
radio city
radio city den haag
radio clasica
radio cmfm!
radio continu
radio cor
radio corrado
radio cosmos
radio crescendo
radio cuna zoetermeer
radio de kleine zwerver
radio de muziekster
radio de orca
radio de vliegende hollander
radio delta
radio diabolus
radio drechtstreek
radio eemland
radio emmeloord
radio emmerdennen
radio emmerhout
radio erasmus rotterdam
radio esperando
radio excellent
radio exclusief
radio expansie
radio experience
radio extra gold
radio fantasy rotterdam
radio favoriet
radio flashback
radio fomix
radio fox
radio gemiva
radio goldenmusicstream
radio goldenwave
radio grensland
radio haneen
radio hanzestad
radio hoeksche waard
radio holland online
radio houtstoker
radio huizen
radio hulchul
radio ideaal
radio ijsselmeervogels
radio israÃ«l
radio italia
radio jnd
radio jnd classics
radio joyride
radio klungelsmurf
radio loho
radio lucky joe
radio m utrecht
radio monique 918
radio moonlights
radio muziekstad
radio n31
radio nacional
radio nelandia
radio niels
radio nónnevot
radio noordzij
radio northsea internationale
radio nostalgia
radio nrg
radio obelix
radio oranje
radio oranje internationaal
radio oude hits
radio president
radio pronkjewail
radio puur hollands
radio readymix
radio regenboog
radio regionaal
radio rimini
radio roks
radio rosita
radio royaal
radio rucphen
radio sbs
radio schalkhaar
radio seabreeze
radio silverbird
radio sitara fm
radio stad den haag
radio starlight urk
radio suc6fm
radio sunday
radio sunstream
radio swiss classic
radio swiss pop
radio tirol
radio totaal
radio trammelant
radio twente gold
radio twente kanaal
radio uniek rotterdam
radio valentina
radio van noord tot zuid.nl
radio vastelaovend
radio venloosch
radio viva
radio vlaamse ardennen
radio volume
radio volumia
radio west
radio westerwolde
radio weststellingwerf centraal
radio worm
radio zaanstad
radio zeeuws vlaanderen
radio zuyd
radio zwolle
radio074
radio1308
radio247
radio320
radio4jou.nl
radio599
radio-chaos
radiodebuffalos
radiodemusketiers
radioemmerhout
radioflitsmeister
radiogeniaal
radiojust4u
radiomagicunicorn
radiomoonlights
radionachtuil
radionl
radionl kids
radiopiratenzender
radioro
radiosunrise
radio-tophits
radioxl nl
rainz fm
ram fm
rco 3fm
rco mnm
rco radio 5
rco topradio
rdd danceclub laut fm
rdd radio laut fm
real hardstyle
red light radio
regio8
regionl
retro radio
rick fm
rick fm - christmas
rick fm - hitradio
ridderkerk fm
riraradio
rixradio
rni radio northsea international
rni rock radio
ro radio 1
ro radio 2
ro radio 3
robintimo radio
rock fm online
rock inferno
rockandblues
rockradio
rog fm
rol radio
roparunradio
rosita plus
rositaplus
roslo radio
roulette fm
roundandsound radio
rtd radio
rtl berlins hitradio top 40
rtl deutsch house
rtl deutschland's hit-radio
rtl weinachtsradio
rtl-radio
rtv albrandswaard
rtv connect
rtv drenthe
rtv horizon
rtv krimpenerwaard
rtv noord
rtv oost
rtv rijnmond
rtv stichtse vecht
rtv stichtste vecht
rtv supersound
rtv zulthe
rucphen rtv
rvm radio
salland1
salsa radio amsterdam
samen1
satellite action
sbs fm
schlagernl
scoutfm
seven fm
shaq fu radio
shineradio
simone fm
sint fm
sinterklaas radio
sleutelstad
smooth chill
smooth jazz crooze
snackie fm
social radio
sol2
songfestival radio
soulshow radio
sound radio
spark radio
spectrumfm
speedyradio gemert
src fm
stadsradio rotterdam
stadsradiodelft
stakenburg kids radio
stanvaste radio
starz 247
station onverwoestbaar
stationoneinternetradio
sterradio
sterren muziek
stream4you
streampower
streekradio alkmaar
streekstad centraal
street signs radio
stubru bruut
stubru de tijdloze
stubru untz
stubru vuurland
studio 040
studio alphen
studio carnaval
studio drentse poort
studio emmeloord
studio rheden
studio040
sublime classics
sublime jazz
sublime playground
sublime soul top 1000
sublime top 500
summerfm
sun fm beachradio
sundanceradio
sunsetradio
supermixfm
surfradio
suri lite radio
sweepmusic
sweet rock radio
swipe radio
team sonnema fm
telstar-fm
that fm
the funky channel
the musical box radio
the trancestation
thejukeboxsound
thuisradio fm
tnt radio
todays hitradio
tomorrowland one world radio
topbam
toppersteam
topradio
topretroarena
topschaamteloos
toptechno
topzen
townradio
traffic radio
trend radio
trendfm den haag
truckersradio
tukker fm
twente fm
ujala radio
unicumfm
unique classic rock
unique gold
unique smooth
unity
up fm
urplay
valkenier radio
vallei.fm
vandaag radio
vanilla radio: deep flavors
vanilla radio: smooth flavors
various radio
vastenavendfm
vathorstradio
vechtdal fm
veluwe centraal
veluwsepiratenstream
veteranen radio
vibe underground
visual fm serious internet radio
vlietstad radio
voorstveluwezoom
vos fm
vrienden-radio
vrije radio twente
vrt ketnet hits
vrt klara
vrt klara continuo
vrt mnm
vrt mnm hits
vrt mnm urbanice
vrt nws
vrt radio 1
vrt radio 2
vrt studio brussel
waterfront radio
waterstad fm
way fm
wdr 1live
wdr 2
wdr 3
wdr 4
wdr 5
webhitsradio
webmusic4you
webradio happy tigers
webradiolimburg
weeff radio
weertfm
wereldstad radio rotterdam
west end radio
wild fm hitradio
xd radio
xenofm
xiiz
xmas radio
xtrafm costa blanca
xxl radio
your choice radio
your latin radio
youradio.nl
yourmusicradio
yumbo fm
zaanradio
zaanstreek radio
zap! fm
zender groenveld
ztack
ê“˜-rock
funx fissa
funx dance
exxact barendrecht
classicnl
radio veronica alternative
radio veronica alternativexl
radio veronica non stop
radio veronica rockradio
radio veronica top 1000 allertijden
vintage veronica
arrow classic rock
slam!
slam! 00's
slam! 40
slam! dance1000
slam! hardstyle
slam! housuh in de pauzuh
slam! juize
slam! mixmarathon
slam! non stop
slam! summer
slam! the boom room
soul radio
sublime
sublime 500
sublime arrow jazz fm
sublime funk & disco
sublime pure jazz
sublime soul
sunlite
team fm
team fm country
team fm drenthe & groningen
team fm friesland
team fm gelderland
team fm hitradio
team fm hits
team fm overijssel
team fm piratenplaten
team fm twente
vib radio
`;

export const isNonTalpaStation = (stationName = '') => {
  const regex = new RegExp(stationName);
  return regex.test(NonTalpaStations) ? true : false;
};
