import axios from 'axios';
import { print } from 'graphql';
import getEnvironmentVariable from 'util/getEnviromentVariable';
import { apiSelector } from 'components/ApiSwitch/ApiSwitch.const';
import { reportFailedAPIRequest } from 'metrics';
import { HTTP_STATUS_CODE } from 'globalConst/const';

const graphqlEndpoint = getEnvironmentVariable('GRAPH_QL_ENDPOINT', apiSelector.UNIVERSAL_API);
const graphqlApiKey = getEnvironmentVariable('GRAPH_QL_API_KEY', apiSelector.UNIVERSAL_API);
export const jukeProfile = process.env.REACT_APP_GRAPH_QL_PROFILE;

const graphQLRequest = (profile = jukeProfile) => {
  return axios.create({
    baseURL: graphqlEndpoint,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-api-key': graphqlApiKey,
      profile,
    },
  });
};
async function request({ query, variables = {}, cache = false, usePost = false, token = '', profile }) {
  const params = {
    query: print(query),
    variables,
  };

  const authConfig = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
  const requestConfig = {
    cache,
    ...authConfig,
  };

  if (usePost) {
    return graphQLRequest(profile).post('/', params, requestConfig);
  }
  return graphQLRequest(profile).get('/', {
    ...requestConfig,
    params,
  });
}

export async function queryRequest(props) {
  const response = await request(props);
  const {
    data: { errors },
  } = response;
  if (errors && errors[0] && errors[0]?.errorInfo?.errorCode !== HTTP_STATUS_CODE.OK) {
    if (errors[0].errorInfo?.errorCode === HTTP_STATUS_CODE.NOT_FOUND) {
      reportFailedAPIRequest('api request error - graphql', props.variables, response?.request?.responseURL);
    }
    throw new Error(errors[0].message);
  }
  return response.data;
}
