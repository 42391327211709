import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'util/index';
import { trackShareContent } from 'analytics';

export default class ShareButton extends PureComponent {
  static defaultProps = {
    handleToggle: noop,
  };

  static propTypes = {
    Button: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    description: PropTypes.string.isRequired,
    handleToggle: PropTypes.func,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    toggleContextMenu: PropTypes.func,
    isInPodcastSwimlane: PropTypes.bool,
    collectionTitle: PropTypes.string,
    podcastTitle: PropTypes.string,
    podcastEpisodeTitle: PropTypes.string,
    isOnDemandTile: PropTypes.bool,
    podcastEpisodeSlug: PropTypes.string,
  };

  handleShareClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {
      handleToggle,
      type,
      url,
      toggleContextMenu,
      isInPodcastSwimlane,
      collectionTitle,
      podcastEpisodeTitle,
      podcastTitle,
      isOnDemandTile = false,
      podcastEpisodeSlug,
    } = this.props;
    const shareProps = { collectionTitle, podcastEpisodeTitle, podcastTitle, isOnDemandTile, podcastEpisodeSlug };
    trackShareContent(type, url, shareProps);
    handleToggle();
    if (isInPodcastSwimlane) {
      toggleContextMenu();
    }
  };

  render() {
    const { Button, description, url, children } = this.props;
    return (
      <div role="presentation" onClick={(e) => this.handleShareClick(e)}>
        <Button
          data-testid="share-button"
          url={url}
          subject={description}
          title={description}
          quote={description}
          via="JUKE_NL"
        >
          {children}
        </Button>
      </div>
    );
  }
}
