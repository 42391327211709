import Station, { UniversalStation } from './Station';
import Collection from './Collection';
import { isEmpty } from 'util/index';
import { COLLECTION_OPTIONS, TILE_SIZES } from 'globalConst/const';
import { UniversalCollection, UniversalConfig } from '../interfaces';

const prioritizeFns = (s1: Station, s2: Station) => {
  if (s1.hasPage < s2.hasPage) {
    return -1;
  }
  if (s1.hasPage === s2.hasPage) {
    return 0;
  }
  return 1;
};

export interface UniversalStationCollection extends UniversalCollection {
  getStations: {
    stations: Array<UniversalStation>;
  };
  items: Array<UniversalStation>;
}

/**
 * @class
 * A representation of a StationCollection
 *
 * @param {object} collection - collection model
 * @param {object} config - config entries
 * @param {object} options - options e.g. no-playouts, highlighted...
 */
export default class StationCollection extends Collection {
  items: Array<Station>;
  tileSizes: string;
  playoutsDisabled: boolean;

  constructor(collection: UniversalCollection, config?: UniversalConfig[], options?: string[]) {
    super(collection, config, options);
    if (isEmpty(collection)) {
      return null;
    }
    const stationCollection = <UniversalStationCollection>collection;

    this.playoutsDisabled = options?.some((option) => option === COLLECTION_OPTIONS.NO_PLAYOUTS);
    this.tileSizes = TILE_SIZES.SMALL;

    this.items = stationCollection?.getStations?.stations.map((station) => new Station(station)).sort(prioritizeFns);

    //Support for deprecated fields
    if (stationCollection.items) {
      this.items =
        stationCollection.items && stationCollection.items.map((station) => new Station(station)).sort(prioritizeFns);
    }

    // Make this class read-only
    Object.freeze(this);
  }
}
