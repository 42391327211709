import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectStyles } from 'injectStyles';
import { gutter, fontSizes, fontWeights } from 'cssVariables';
import { isMobile } from 'util/device';
import { trackAccordionMenuClick } from 'analytics';
import AccordionItem from './AccordionItem';

function AccordionList({ classes, items, title }) {
  const [activeItem, setActiveItem] = useState(null);
  const toggleItem = (id, itemTitle) => () => {
    const target = activeItem === id ? null : id;
    if (target) {
      trackAccordionMenuClick(itemTitle);
    }
    setActiveItem(target);
  };

  return (
    <div className={classes.accordion} data-testid="accordion-list">
      {title && (
        <h2
          className={classNames(classes.title, {
            [classes.titleMobile]: isMobile,
          })}
        >
          {title}
        </h2>
      )}
      {items &&
        items.map((item) => (
          <AccordionItem key={item.uuid} isOpen={activeItem === item.uuid} handleClick={toggleItem} {...item} />
        ))}
    </div>
  );
}

AccordionList.defaultProps = {
  items: null,
  title: null,
};

AccordionList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array,
  title: PropTypes.string,
};

const styles = () => ({
  accordion: {
    margin: `${gutter * 2}px 0`,
  },
  title: {
    margin: 0,
    fontWeight: fontWeights.bold,
    height: 25,
    fontSize: fontSizes.xlarge,
    lineHeight: 1,
    marginBottom: 24,
  },
  titleMobile: {
    textAlign: 'center',
  },
});

export default injectStyles(styles)(AccordionList);
