import React from 'react';

import styles from './PlayerBottom.styles';
import PlayerNowPlaying from './PlayerNowPlaying';
import PlayerControls from './PlayerControls';
import PlayerButtons from './PlayerButtons';
import PlayerUnavailable from './PlayerUnavailable';
import { isTouch } from 'util/device';
import { isEmpty } from 'util/index';
import { createUseStyles } from 'react-jss';

interface IPlayerBottom {
  isComponentLoading: boolean;
  isOnline: boolean;
  playerError: object;
  error: boolean;
  hasContent: boolean;
  browser: { down: { sm: boolean } };
}

const useStyles = createUseStyles(styles, { name: 'PlayerBottom' });

const PlayerBottom = ({
  isComponentLoading = false,
  isOnline = true,
  playerError = null,
  error = false,
  hasContent = true,
  browser,
}: IPlayerBottom): JSX.Element => {
  const classes = useStyles();
  const getPlayerButtons = () => {
    if (isTouch && browser.down.sm) {
      return null;
    } else {
      return <PlayerButtons />;
    }
  };

  const hasError = !isEmpty(playerError) || error;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <PlayerUnavailable {...{ isOnline, hasError, hasContent }} />
        {isOnline && !hasError && (
          <>
            {hasContent && <PlayerNowPlaying />}
            <PlayerControls isComponentLoading={isComponentLoading} />
            {getPlayerButtons()}
          </>
        )}
      </div>
    </div>
  );
};

export default PlayerBottom;
