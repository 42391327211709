import tileStyles from 'tile-components-styles';
import * as cssVariable from 'cssVariables';
import { Styles } from 'jss';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  ...tileStyles,
  tagTile: {
    ...tileStyles.tileDefault,
    backgroundColor: theme.palette.backgroundSecondary,
    overflow: 'hidden',
    position: 'relative',
    zIndex: 0, // Create stacking context
    display: 'block',
    paddingTop: '59.9%',
    backfaceVisibility: 'hidden',

    borderRadius: 4,
    ...tileStyles.tileWithTrianglesHover,

    '&:focus': {
      outline: 0,
      outlineOffset: 0,
      '&::before': {
        transform: 'none',
      },
    },

    '&:hover': {
      transform: 'scale(1.055)',

      '&::before': {
        transform: 'none',
      },
    },
  },

  genreContent: {
    ...cssVariable.fillAspectRatio,
    padding: cssVariable.gutter / 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    color: cssVariable.white,
    zIndex: 1,
    transform: 'scale(1) perspective(1px)',
    textAlign: 'left',
    justifyContent: 'flex-end',
  },

  title: {
    ...tileStyles.titleDefault,
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.mediumLarge,
    textAlign: 'center',
  },

  titleMobile: {
    fontSize: cssVariable.fontSizes.mediumLarge,
  },

  genreBackdrop: {
    ...cssVariable.fillAspectRatio,
    // TODO @d.ravnjak
    // zIndex: ({ image }) => (image && image.url ? 0 : -1),
    zIndex: -1,
    borderRadius: 1,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },

  noGradient: {
    '&::before': {
      display: 'none',
    },
  },

  [`@media (max-width: ${theme.breakpoints.values.sl}px)`]: {
    title: {
      fontSize: cssVariable.fontSizes.small,
    },
  },
});
