export const IMAGE_FRAGMENT = `
  fragment ImageFragment on Image {
    uri
    imageType
    title
    width
    height
    variant {        
      slug
    } 
  }`;
