import logger from 'util/logger';

export function trackGoToPodcast(collectionTitle, podcastTitle = '') {
  if (!collectionTitle) {
    logger.warn("Can't track titleless Collection");
  }
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'click',
      event_category: 'content-listing',
      event_action: 'click',
      event_label: podcastTitle || null,
      media_program_name: collectionTitle || null,
    },
  ]);
}

export function trackPodcastStart(
  collectionTitle,
  media_name = '',
  media_program_episodenumber = '',
  media_program_name = '',
  media_program_season = ''
) {
  if (!collectionTitle) {
    logger.warn("Can't track titleless Collection");
  }
  let payload;
  if (media_name) {
    payload = {
      event_name: 'play',
      event_category: 'content-listing',
      event_action: 'play',
      event_label: `${collectionTitle}`,
      media_name,
      media_program_episodenumber,
      media_program_name,
      media_program_season,
    };
  } else {
    payload = {
      event_name: 'play',
      event_category: 'content-listing',
      event_action: 'play',
      event_label: `${collectionTitle}`,
    };
  }
  window.Taq.push(['track', 'link', payload]);
}

export function trackPodcastStop(
  collectionTitle,
  media_name = '',
  media_program_episodenumber = '',
  media_program_name = '',
  media_program_season = ''
) {
  if (!collectionTitle) {
    logger.warn("Can't track titleless Collection");
  }
  let payload;
  if (media_name) {
    payload = {
      event_name: 'stop',
      event_category: 'content-listing',
      event_action: 'stop',
      event_label: `${collectionTitle}`,
      media_name,
      media_program_episodenumber,
      media_program_name,
      media_program_season,
    };
  } else {
    payload = {
      event_name: 'stop',
      event_category: 'content-listing',
      event_action: 'stop',
      event_label: `${collectionTitle}`,
    };
  }
  window.Taq.push(['track', 'link', payload]);
}

export function trackPodcastEpisodePageBackButton(title) {
  window.Taq.push([
    'track',
    'link',
    {
      event_action: 'click',
      event_category: 'button',
      event_label: `terug - ${title}`,
    },
  ]);
}

export function trackNavigateToPodcastEpisodePage(episodeName = '', podcastName = '', collectionTitle = '') {
  window.Taq.push([
    'track',
    'link',
    {
      event_action: 'click',
      event_category: 'content_listing',
      event_label: episodeName || `Afleveringen`,
      media_name: podcastName || null,
      media_id: episodeName,
      media_program_name: collectionTitle || null,
      media_audiotype: 'podcast',
    },
  ]);
}
