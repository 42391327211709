import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg>
    <path d="M5.9998,9.3655 L5.9998,20.0365 C5.9998,20.1345 6.0798,20.2155 6.1788,20.2155 L23.8208,20.2155 C23.9198,20.2155 23.9998,20.1345 23.9998,20.0365 L23.9998,9.3645 L19.9628,15.1155 C19.7988,15.3485 19.5678,15.4705 19.2978,15.4685 C19.0378,15.4605 18.8048,15.3165 18.6568,15.0745 L15.0018,9.1595 L11.3428,15.0765 C11.1948,15.3165 10.9618,15.4605 10.7018,15.4685 C10.4328,15.4565 10.2008,15.3485 10.0378,15.1165 L5.9998,9.3655 Z M23.8208,21.2155 L6.1788,21.2155 C5.5288,21.2155 4.9998,20.6865 4.9998,20.0365 L4.9998,8.8465 C4.9998,8.4775 5.2188,8.1635 5.5558,8.0475 C5.8938,7.9275 6.2718,8.0405 6.4938,8.3295 L10.6648,14.2705 L14.2378,8.4865 C14.4748,8.0875 14.7828,8.0015 14.9998,8.0015 C15.2168,8.0015 15.5258,8.0875 15.7668,8.4945 L19.3348,14.2705 L23.4928,8.3475 C23.7248,8.0405 24.1028,7.9265 24.4468,8.0485 C24.7808,8.1635 24.9998,8.4775 24.9998,8.8465 L24.9998,20.0365 C24.9998,20.6865 24.4718,21.2155 23.8208,21.2155 L23.8208,21.2155 Z" />
  </svg>
);

export default svgIcon(svg);
