import { connect } from 'react-redux';
import asContentPage from 'components/hoc/asContentPage';
import { forceMiniPlayerState } from 'actions/player-actions';
import { getCurrentPageSlug, getCurrentPageSubSlug, getPageFromSlug } from 'store/reducers/content';
import { getRating } from 'store/reducers/rating';
import DynamicPage from './DynamicPage';

function mapStateToProps(state, ownProps) {
  const currentPageSlug = getCurrentPageSlug(state) || ownProps.slug;
  return {
    page: ownProps,
    subSlug: getCurrentPageSubSlug(state),
    pageTitle: (getPageFromSlug(state, currentPageSlug) || {}).title,
    description: (getPageFromSlug(state, currentPageSlug) || {}).description,
    rating: getRating(state),
  };
}

const mapDispatchToProps = {
  forceMiniPlayerState,
};

export const DynamicPageContainer = connect(mapStateToProps, mapDispatchToProps)(DynamicPage);
export default asContentPage('page', DynamicPageContainer);
