import * as cssVariable from 'cssVariables';

export default (theme) => ({
  contentWrapper: {
    ...cssVariable.contentPadding({ smallScreen: false }),
    maxWidth: '100%',
    overflowAnchor: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    contentWrapper: {
      ...cssVariable.contentPadding({ smallScreen: true }),
    },
  },
});
