export const DESKTOP_HEADER_HEIGHT = 300;
export const DESKTOP_HEADER_HEIGHT_WITH_HIGHLIGHTED_PODCAST = 320;
export const STICKY_HEIGHT = 110;
export const STICKY_HEIGHT_WITH_OFFSET = 115;
export const DEFAULT_HEADER_HEIGHT = 77;
export const MOBILE_HEADER_HEIGHT = 60;

export const HEADER_LAYOUT = {
  DEFAULT: 'default',
  GRADIENT: 'gradient',
  MODERN: 'modern',
  PODCAST: 'podcast',
};
