import React from 'react';
import PropTypes from 'prop-types';

import { SCHEMA_OFFERS } from 'globalConst/schema-const';

import Schema from './Schema';

const shouldShowiOS = (slug, subSlug, iOSAppStore) =>
  (slug === 'juke-app' || subSlug === 'mobiel-en-tablet' || (!subSlug && slug === 'apparaten')) &&
  iOSAppStore &&
  iOSAppStore.score;
const shouldShowAndroid = (slug, subSlug, AndroidPlayStore) =>
  (slug === 'juke-app' || subSlug === 'mobiel-en-tablet' || (!subSlug && slug === 'apparaten')) &&
  AndroidPlayStore &&
  AndroidPlayStore.score;
const shouldShowTv = (slug, subSlug) => slug === 'tv' || subSlug === 'tv' || (!subSlug && slug === 'apparaten');
const shouldShowVoice = (slug, subSlug) =>
  slug === 'google-assistent' || subSlug === 'voice-assistants' || (!subSlug && slug === 'apparaten');
const shouldShowSonos = (slug, subSlug) =>
  slug === 'sonos' || subSlug === 'speaker' || (!subSlug && slug === 'apparaten');
const shouldShowSchema = (slug, subSlug, iOSAppStore, AndroidPlayStore) =>
  shouldShowiOS(slug, subSlug, iOSAppStore) ||
  shouldShowAndroid(slug, subSlug, AndroidPlayStore) ||
  shouldShowTv(slug, subSlug) ||
  shouldShowVoice(slug, subSlug) ||
  shouldShowSonos(slug, subSlug);

const DevicesSchema = ({ rating, slug, subSlug }) => {
  const { iOSAppStore, AndroidPlayStore } = rating || {};

  const devicesSchema = (...devices) =>
    devices
      .filter((data) => data)
      .map((device) => {
        let specificDevice;
        switch (device) {
          case 'iOS':
            specificDevice = {
              operatingSystem: 'iOS',
              id: 'https://juke.nl/juke-app',
              downloadUrl: 'https://apps.apple.com/nl/app/juke-radio-non-stop-muziek/id1191822805',
              countriesSupported: 'nl',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: iOSAppStore.score,
                ratingCount: iOSAppStore.reviews,
              },
              keywords: 'app, radio, muziek, podcasts, non-stop, gratis, radiozenders',
              screenshot: [
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://lh3.googleusercontent.com/1lwSnELhqkrdytLvoHbShk4F6HkyhjiOxwtcnV93hlIWSh9Y234tFPOB9Y-yB5JPSX0t=w720-h310-rw',
                },
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://images.ctfassets.net/r4m7wjlnqcgy/346dODRaZgqnqXQDsxmUrw/0d6bc838e14918c319fca93f37364470/ios_apparaten.jpg',
                },
              ],
              image:
                'https://images.ctfassets.net/r4m7wjlnqcgy/346dODRaZgqnqXQDsxmUrw/0d6bc838e14918c319fca93f37364470/ios_apparaten.jpg',
              description:
                'Je kunt vast niet wachten om de JUKE-app te downloaden en te beginnen met luisteren. ' +
                '200+ radiozenders, non-stop muziek voor elke mood en elk moment, alleen de allerbeste podcasts en luisteren waar en wanneer jij dat wilt. JUKE ze!',
            };
            break;
          case 'android':
            specificDevice = {
              operatingSystem: 'Android',
              downloadUrl: 'https://play.google.com/store/apps/details?id=com.e247.radio538&hl=nl',
              countriesSupported: 'nl',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: AndroidPlayStore.score,
                ratingCount: AndroidPlayStore.reviews,
              },
              keywords: 'app, radio, muziek, podcasts, non-stop, gratis, radiozenders',
              screenshot: [
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://lh3.googleusercontent.com/1lwSnELhqkrdytLvoHbShk4F6HkyhjiOxwtcnV93hlIWSh9Y234tFPOB9Y-yB5JPSX0t=w720-h310-rw',
                },
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://images.ctfassets.net/r4m7wjlnqcgy/346dODRaZgqnqXQDsxmUrw/0d6bc838e14918c319fca93f37364470/ios_apparaten.jpg',
                },
              ],
              image:
                'https://images.ctfassets.net/r4m7wjlnqcgy/346dODRaZgqnqXQDsxmUrw/0d6bc838e14918c319fca93f37364470/ios_apparaten.jpg',
              description:
                'Je kunt vast niet wachten om de JUKE-app te downloaden en te beginnen met luisteren. ' +
                '200+ radiozenders, non-stop muziek voor elke mood en elk moment, alleen de allerbeste podcasts en luisteren waar en wanneer jij dat wilt. JUKE ze!',
            };
            break;
          case 'tv':
            specificDevice = {
              availableOnDevice: 'Samsung TV > 2015',
              operatingSystem: 'Tizen',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: '4',
                ratingCount: '1000',
              },
              keywords:
                'app, tv, samsung, tizen, radio, radiozenders, non-stop, non-stop muziek, muziek, podcasts, podcast, gratis',
              screenshot: [
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://images.ctfassets.net/r4m7wjlnqcgy/54HzhB6i1CEpa6NefkzyeI/66c30a0d19cad90ff6cca4c6618b4d62/devices-productshot-samsung-tv.png',
                },
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://images.ctfassets.net/r4m7wjlnqcgy/54HzhB6i1CEpa6NefkzyeI/66c30a0d19cad90ff6cca4c6618b4d62/devices-productshot-samsung-tv.png',
                },
              ],
              image:
                'https://images.ctfassets.net/r4m7wjlnqcgy/54HzhB6i1CEpa6NefkzyeI/66c30a0d19cad90ff6cca4c6618b4d62/devices-productshot-samsung-tv.png',
              description:
                'Je kunt vast niet wachten om de JUKE tv-app te downloaden en te beginnen met luisteren. ' +
                '200+ radiozenders, non-stop muziek voor elke mood en elk moment, alleen de allerbeste podcasts en luisteren waar en wanneer jij dat wilt. ' +
                'Luister gratis! JUKE ze!',
            };
            break;
          case 'sonos':
            specificDevice = {
              availableOnDevice: 'Sonos',
              operatingSystem: 'iOS,Android',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: '4',
                ratingCount: '1000',
              },
              keywords:
                'app, muziekservice, radio, non-stop muziek, muziek, podcast, podcasts, sonos, 538, streamen, gratis, online',
              screenshot: [
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://images.ctfassets.net/r4m7wjlnqcgy/2pjuVKB1JP1ThioPwafmXG/d4a3c031b2768b7b34920adb8a131d21/Video.png',
                },
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://images.ctfassets.net/r4m7wjlnqcgy/7c9jPFLpDMXv56L7jmPAgr/e90e3ed8e091c6bed9ced763f2d41177/Apparaat.png',
                },
              ],
              description:
                'Je kunt vast niet wachten om de JUKE-app te downloaden en te beginnen met luisteren. ' +
                '200+ radiozenders, non-stop muziek voor elke mood en elk moment, alleen de allerbeste podcasts en luisteren waar en wanneer jij dat wilt. ' +
                'Luister gratis! JUKE ze!',
              isPartOf: [
                {
                  '@type': 'SoftwareApplication',
                  applicationCategory: 'UtilitiesApplication',
                  name: 'Sonos',
                  downloadUrl: 'https://play.google.com/store/apps/details?id=com.sonos.acr&hl=nl',
                  operatingSystem: 'Android',
                  aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue: '3,8',
                    ratingCount: '141143',
                  },
                  offers: SCHEMA_OFFERS,
                },
                {
                  '@type': 'SoftwareApplication',
                  applicationCategory: 'UtilitiesApplication',
                  name: 'Sonos',
                  downloadUrl: 'https://apps.apple.com/nl/app/sonos-controller/id293523031',
                  operatingSystem: 'iOS',
                  aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue: '4,6',
                    ratingCount: '130700',
                  },
                  offers: SCHEMA_OFFERS,
                },
              ],
            };
            break;
          case 'voice':
            specificDevice = {
              url: 'https://assistant.google.com/services/a/uid/00000051c460e0e1?source=web&source=web',
              operatingSystem: 'Android,iOS',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: '5',
                ratingCount: '5',
              },
              keywords:
                'voice, google assistant, alexa, radio, online, non-stop, muziek, podcasts, hey google, praat met juke, juke, gratis, streamen',
              screenshot: [
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://images.ctfassets.net/r4m7wjlnqcgy/7DGBWqui5iIdb7u6ZhRpBU/a07181de34c05aebe1d5d0be31493127/Apparaat.png',
                },
                {
                  '@type': 'imageObject',
                  contentUrl:
                    'https://images.ctfassets.net/r4m7wjlnqcgy/6uCO8ABICuGesjtcxlS97c/550e4064cfc582d02d7a2c49066d947f/Alexa.png',
                },
              ],
              description:
                'Je kunt vast niet wachten om de JUKE-app te downloaden en te beginnen met luisteren. ' +
                '200+ radiozenders, non-stop muziek voor elke mood en elk moment, alleen de allerbeste podcasts en luisteren waar en wanneer jij dat wilt. ' +
                'Luister gratis! JUKE ze!',
              isPartOf: [
                {
                  '@type': 'SoftwareApplication',
                  applicationCategory: 'UtilitiesApplication',
                  name: 'Google Assistant',
                  downloadUrl:
                    'https://play.google.com/store/apps/details?id=com.google.android.apps.googleassistant&hl=nl',
                  operatingSystem: 'Android',
                  aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue: '4,1',
                    ratingCount: '207869',
                  },
                  offers: SCHEMA_OFFERS,
                },
                {
                  '@type': 'SoftwareApplication',
                  applicationCategory: 'UtilitiesApplication',
                  name: 'Google Assistant',
                  downloadUrl: 'https://apps.apple.com/us/app/google-assistant/id1220976145',
                  operatingSystem: 'iOS',
                  aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue: '4,7',
                    ratingCount: '27100',
                  },
                  offers: SCHEMA_OFFERS,
                },
              ],
            };
            break;
          default:
            break;
        }

        return {
          '@context': 'http://schema.org/',
          '@type': 'SoftwareApplication',
          name: 'JUKE',
          applicationCategory: 'EntertainmentApplication',
          offers: SCHEMA_OFFERS,
          ...specificDevice,
        };
      });

  return (
    shouldShowSchema(slug, subSlug, iOSAppStore, AndroidPlayStore) && (
      <Schema
        data={devicesSchema(
          shouldShowiOS(slug, subSlug, iOSAppStore) && 'iOS',
          shouldShowAndroid(slug, subSlug, AndroidPlayStore) && 'android',
          shouldShowTv(slug, subSlug) && 'tv',
          shouldShowVoice(slug, subSlug) && 'voice',
          shouldShowSonos(slug, subSlug) && 'sonos'
        )}
      />
    )
  );
};

DevicesSchema.propTypes = {
  slug: PropTypes.string,
  subSlug: PropTypes.string,
  rating: PropTypes.object,
};

DevicesSchema.defaultProps = {
  slug: null,
  subSlug: null,
  rating: null,
};

export default DevicesSchema;
