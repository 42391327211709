import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { injectStyles } from 'injectStyles';
import classNames from 'classnames';

import { fillAspectRatio } from 'cssVariables';

class AspectContainer extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes, children } = this.props;
    return <div className={classNames(classes.container, classes.innerContainer)}>{children}</div>;
  }
}

const styles = {
  container: ({ width, height }) => ({
    position: 'relative',
    width: '100%',
    paddingBottom: `${(height / width) * 100}%`,
  }),
  innerContainer: {
    '& > *': fillAspectRatio,
  },
};

export default injectStyles(styles)(AspectContainer);
