import React from 'react';
import PropTypes from 'prop-types';

const Duration = ({ duration }) => {
  if (!duration) return null;
  const hasHours = duration.hours > 0;
  const hasMinutes = duration.minutes > 0;
  const isLessThanMinute = duration.hours === 0 && duration.minutes === 0;

  // duration is less than one minute
  if (isLessThanMinute) {
    return <span>{duration.seconds} sec</span>;
  }

  // duration has only hours
  if (hasHours && !hasMinutes) {
    return <span>{duration.hours} uur</span>;
  }

  // duration has only minutes
  if (!hasHours && hasMinutes) {
    return (
      <span>
        {duration.minutes} min {duration.seconds} sec
      </span>
    );
  }

  // duration has both hours and minutes
  if (hasHours && hasMinutes) {
    return (
      <span>
        {duration.hours} uur {duration.minutes} min
      </span>
    );
  }

  return null;
};

Duration.propTypes = {
  duration: PropTypes.object,
};

Duration.defaultProps = {
  duration: null,
};

export default Duration;
