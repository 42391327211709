import ContentPageLayout from 'components/ContentPageLayout';
import PageContent from 'components/PageContent';
import PageHeader from 'components/PageHeader';
import AppleStore from 'components/svg/AppleStore';
import GooglePlay from 'components/svg/GooglePlay';
import QRCode from 'components/svg/QRCode';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isMobile, isTablet } from 'util/device';

import styles from './AudiobookPage.styles';

const useStyles = createUseStyles(styles, { name: 'PromotionBanner' });

const AudiobookPage = (): JSX.Element => {
  const classes = useStyles();

  const luisterboekenSize = isMobile
    ? { width: 220, height: 360 }
    : isTablet()
    ? { width: 300, height: 480 }
    : { width: 340, height: 540 };

  const storeSize = isMobile || isTablet() ? { width: 158, height: 45 } : { width: 140, height: 40 };

  return (
    <ContentPageLayout>
      <PageHeader></PageHeader>
      <PageContent>
        <div className={classes.container}>
          {isMobile ? (
            <div className={classes.storeMobile}>
              <GooglePlay width={storeSize.width} height={storeSize.height} />
              <AppleStore width={storeSize.width} height={storeSize.height} />
            </div>
          ) : null}
          <div className={classes.luisterboeken}>
            <img
              src="/img/luisterboeken-image.jpg"
              width={luisterboekenSize.width}
              height={luisterboekenSize.height}
              alt="Luisterboeken image"
            />
          </div>
          <div className={classes.content}>
            <div className={classes.header}>
              <h4 className={classes.title}>Gratis luisterboeken op je telefoon via de JUKE-app</h4>
              <div className={classes.subtitle}>Onbeperkt boeken luisteren in alle genres</div>
            </div>
            {isMobile ? (
              <div className={classes.storeMobile}>
                <GooglePlay width={storeSize.width} height={storeSize.height} />
                <AppleStore width={storeSize.width} height={storeSize.height} />
              </div>
            ) : null}
            <div className={classes.description}>
              <p>
                Op het puntje van je stoel met een spannende thriller of wegdromen met een romantische roman. Beleef het
                met een gratis luisterboek.
              </p>
              <p>
                Vind bij JUKE het beste luisterboek voor onderweg naar je werk, tijdens het opruimen, bij het uitlaten
                van de hond of gewoon in bed… Met een ruime keuze aan luisterboeken in alle genres, is er altijd iets
                nieuws te ontdekken.
              </p>
              <p>Luisteren doe je eenvoudig via de gratis JUKE-app.</p>
            </div>
            {!isMobile ? (
              <div className={classes.link}>
                <div className={classes.storeDesktop}>
                  <GooglePlay width={storeSize.width} height={storeSize.height} />
                  <AppleStore width={storeSize.width} height={storeSize.height} />
                </div>
                <span className={classes.text}>Of scan de QR code</span>
                <QRCode />
              </div>
            ) : null}
          </div>
        </div>
      </PageContent>
    </ContentPageLayout>
  );
};

export default AudiobookPage;
