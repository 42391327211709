import * as cssVariable from 'cssVariables';

export default () => ({
  wrapper: {
    margin: 0,

    '& > span': {
      color: cssVariable.blue500,
    },
  },
});
