import gql from 'graphql-tag';
import { IMAGE_FRAGMENT } from './fragments';

export const getPromotionCollection = gql`
  query getPromotionCollection($slug: String!) {
    collection(slug: $slug) {
      ... on PromotionCollection {
        id
        slug
        type
        title
        options
        description
        config {
          type
          entries {
            key
            value
            type
          }
        }
        items {
          id
          type
          title
          uri
          options
          images {
            ...ImageFragment
          }
          ref {
            id
            targetType
            type
            uri
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;
