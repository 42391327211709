import * as cssVariable from 'cssVariables';
import { DEFAULT_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from 'components/PageHeader/PageHeader.const';
import { breakpoints, mediaQuery } from 'components/theme/breakpoints';
import { isMobile, isTouch } from 'util/device';

function colorWithAlpha(color, alpha) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default (theme) => ({
  headerWrapper: {
    paddingBottom: 80,
    position: 'relative',
    zIndex: theme.zIndex.header,
  },
  header: {
    display: 'flex',
    backgroundColor: colorWithAlpha(theme.palette.background, 0.6),
    backdropFilter: 'blur(8px)',
    padding: '0 40px',
    position: 'fixed',
    left: cssVariable.desktopMenuMaxWidth,
    right: 0,
    height: DEFAULT_HEADER_HEIGHT,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > svg': {
      height: 40,
      maxWidth: '100%',
    },
    [mediaQuery.maxWidth(breakpoints.lg)]: {
      left: cssVariable.desktopSmallMenuWidth,
    },
    [mediaQuery.maxWidth(breakpoints.sm)]: {
      padding: '0 20px',
    },
  },
  headerTouch: {
    height: MOBILE_HEADER_HEIGHT,
    left: 0,
    padding: '0 10px',
  },
  auth: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
  },
  switch: {
    alignItems: 'center',
    margin: [0, 30],
  },
  logoWrapper: {
    display: 'absolute',
    flex: 1,
  },
  logo: {
    width: 130,
    marginLeft: isTouch && !isMobile ? 20 : 0,
    [mediaQuery.maxWidth(breakpoints.sl)]: {
      width: 96,
    },
  },
  websiteOfTheYear: {
    width: 80,
    height: 80,
  },
  [`@media (max-width: ${theme.breakpoints.values.sl}px)`]: {
    websiteOfTheYear: {
      width: 55,
      height: 55,
    },
  },
});
