import { PLAYER_BOTTOM_HEIGHT } from 'components/PlayerBottom/PlayerBottom.const';
import * as cssVariable from 'cssVariables';

export default {
  container: {
    paddingTop: 20,
    paddingBottom: `calc(${PLAYER_BOTTOM_HEIGHT}px + 150px)`,
    position: 'relative',
    transform: 'scale(1) perspective(1px)',
  },
  containerMobile: {
    WebkitOverflowScrolling: 'touch',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: '0',
    },
    to: {
      opacity: '1',
    },
  },
  containerRecentPopular: {
    opacity: 0,
    animation: '$fadeIn 0.4s ease-in 0.4s forwards',
  },
  screenReadersOnly: cssVariable.screenReadersOnly,
  loaderScreen: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    opacity: 0,
    animation: '$fadeIn 0.2s ease-in 0.2s forwards',
  },
};
