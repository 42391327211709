import { inlineMediaActions } from '../../actions/actions-const';

const initialState = {
  isPlaying: false,
  currentMediaUrl: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case inlineMediaActions.INLINE_MEDIA_PLAYING:
      return {
        ...state,
        isPlaying: true,
        currentMediaUrl: action.mediaUrl,
      };
    case inlineMediaActions.INLINE_MEDIA_PAUSED:
      return {
        ...state,
        isPlaying: false,
      };
    case inlineMediaActions.INLINE_MEDIA_ERRORED:
    case inlineMediaActions.INLINE_MEDIA_STOPPED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

/**
 * Given the current state, returns the currently playing inline media URL
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getCurrentlyPlayingMedia = (state) => state.inlineMedia.currentMediaUrl;

/**
 * Given a url, returns a boolean indicating if that url is currently playing inline
 * @param {object} state
 * @param {string} url
 */
export const isCurrentlyPlayingMedia = (state, url) => getCurrentlyPlayingMedia(state) === url;
