import * as cssVariable from 'cssVariables';
import { StyleTheme } from 'components/theme/StyleTheme';
import { Styles } from 'jss';

export const styles = (theme: StyleTheme): Styles => ({
  title: {
    ...cssVariable.pageTitle,
    maxWidth: 'calc(100% - 210px)',
    position: 'relative',
  },
  gradientHeader: {
    color: theme.palette.gradientHeader.color,
    textAlign: 'center',
  },
  gradientHeaderDesktop: {
    maxWidth: '100%',
    textAlign: 'left',
  },
  smallTitle: {
    fontSize: cssVariable.fontSizes.small,
    textTransform: 'none',
  },
  titleIsHidden: cssVariable.screenReadersOnly,
  fullWidth: {
    maxWidth: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      maxWidth: '100%',
    },
  },
});
