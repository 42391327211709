import { connect } from 'react-redux';
import { getContentWidth } from 'store/reducers/content';
import CardDeck from './CardDeck';

function mapStateToProps(state) {
  return {
    contentWidth: getContentWidth(state),
  };
}

export default connect(mapStateToProps)(CardDeck);
