import convertUtcToUnix from 'util/time';
import { playbackModes } from 'store/reducers/player';
import { getCookie, setCookie } from 'util/cookie';
import { getStore } from 'store';
import {
  mediaAudiotype,
  getMediaGenreForStation,
  getBitrate,
  getMediaIdForOnDemand,
  getMediaGenreForOnDemand,
  getMediaAudiotypeForOnDemand,
} from 'analytics';
import { omnyEventsStart, omnyEventsStop } from 'metrics/omnyMetrics';
import UserSessionManager from 'analytics/UserSessionManager';
/**
 * Tracks first stream start.
 * The dedicated cookie expires after 30 days, after which a new first stream start can be sent
 * @param {string} label name of item to track
 */
function trackStreamStart(label = '') {
  const COOKIE_NAME = 'first_stream_start';
  const COOKIE_EXPIRATION = 30;

  // Only send the tracking event if the cookie hasn't been set yet
  if (!getCookie(COOKIE_NAME)) {
    window.Taq.push([
      'track',
      'link',
      {
        event_name: 'first_streamstart',
        event_category: 'first',
        event_action: 'streamstart',
        event_label: label,
      },
    ]);
  }

  // If cookie was found set new expiration date
  setCookie(COOKIE_NAME, true, COOKIE_EXPIRATION);
}

function heartBeatCallback() {
  if (!window.Taq.getExtension) return null;
  const plugin = window.Taq.getExtension('HeartBeat');
  const heartbeat = plugin.currentHeartBeat;

  const {
    player: { playbackMode, currentStation, currentOnDemandClip, isCasting },
  } = getStore().getState();

  // Only track the stream start if the first 60 seconds have passed
  if (heartbeat === 60) {
    // Decide eventLabel, based on playback mode and content type
    let label;

    if (playbackMode === playbackModes.LIVE_AUDIO || playbackMode === playbackModes.LIVE_VIDEO) {
      label = currentStation.name;
    } else if (playbackMode === playbackModes.ON_DEMAND_CLIP) {
      label = (currentOnDemandClip.show && currentOnDemandClip.show.name) || currentOnDemandClip.title;
    } else {
      label = '';
    }

    trackStreamStart(label);
  }

  const { name: stationName, image, tritonMount, videoUrl, slug } = currentStation || {};

  const {
    audioUrl: onDemandAudioUrl,
    duration: onDemandDuration,
    imageUrl: onDemandImageUrl,
    publishedUtc,
    show: onDemandShow,
    title: onDemandTitle,
  } = currentOnDemandClip || {};

  switch (playbackMode) {
    case playbackModes.LIVE_AUDIO:
      return {
        event_name: 'juke-player_heartbeat',
        event_category: 'juke-player',
        event_action: 'heartbeat',
        event_label: heartbeat || null,
        player_channel: stationName || null,
        player_channelid: tritonMount || null,
        media_thumbnail: (image && image.url) || null,
        media_name: stationName || null,
        media_type: 'audio',
        media_duration: 0, // 0 = live
        media_playbackrate: 1, // 1 = normal play speed
        media_playbackpercentage: 100,
        media_playtime: (plugin && plugin.counter) || null,
        media_id: slug || null,
        media_genre: (currentStation && getMediaGenreForStation(currentStation)) || null,
        media_bitrate: getBitrate(),
        player_casting: isCasting ? 'chromecast' : false,
        c_media_audiotype: mediaAudiotype && mediaAudiotype.radioLive,
      };
    case playbackModes.LIVE_VIDEO:
      return {
        event_name: 'juke-player-live-video_heartbeat',
        event_category: 'juke-player-live-video',
        event_action: 'heartbeat',
        event_label: heartbeat || null,
        player_channel: stationName || null,
        player_channelid: videoUrl || null,
        media_name: stationName || null,
        media_duration: 0, // 0 = live
        media_playbackrate: 1, // 1 = normal play speed
        media_playbackpercentage: 100,
        media_videotype: 'visual-radio',
        media_type: 'Video',
        media_playtime: (plugin && plugin.counter) || null,
        media_id: slug || null,
        media_genre: currentStation ? getMediaGenreForStation(currentStation) : null,
        media_bitrate: '64kbps',
        player_casting: isCasting ? 'chromecast' : false,
      };
    case playbackModes.ON_DEMAND_CLIP:
      return {
        event_name: 'juke-player-on-demand_heartbeat',
        event_category: 'juke-player-on-demand',
        event_action: 'heartbeat',
        event_label: heartbeat,
        player_channel: onDemandShow && onDemandShow.station ? onDemandShow.station.name : null,
        player_channelid: onDemandAudioUrl || null,
        media_type: 'audio',
        media_duration: onDemandDuration || null,
        media_playbackrate: 1, // 1 = normal play speed
        media_playbackpercentage: 100,
        media_id: (onDemandShow && onDemandShow.name) || onDemandTitle,
        media_genre: (currentOnDemandClip && getMediaGenreForOnDemand(currentOnDemandClip)) || null,
        media_name: (onDemandShow && onDemandShow.name) || onDemandTitle,
        media_thumbnail: onDemandImageUrl || null,
        media_datepublished: publishedUtc ? convertUtcToUnix(publishedUtc) : null,
        media_program_name: (currentOnDemandClip && getMediaIdForOnDemand(currentOnDemandClip)) || null,
        media_playtime: plugin && plugin.counter,
        media_playtimepercentage: onDemandDuration ? plugin.counter / onDemandDuration : null,
        media_bitrate: '64kbps',
        player_casting: isCasting ? 'chromecast' : false,
        c_media_audiotype: currentOnDemandClip ? getMediaAudiotypeForOnDemand(currentOnDemandClip) : null,
      };
    default:
      break;
  }
  return null;
}

const heartBeatScheme = [
  { start: 0, interval: 60 },
  { start: 120, interval: 480 },
  { start: 600, interval: 600 },
];

window.Taq.push([
  'load',
  'HeartBeat',
  {
    scheme: heartBeatScheme,
    events: { onHeartBeat: heartBeatCallback },
  },
]);

const userSessionManager = new UserSessionManager();
let sessionInterval;
export function startHeartBeat() {
  if (!window.Taq.getExtension) return;
  const heartbeat = window.Taq.getExtension('HeartBeat');

  if (heartbeat) {
    heartbeat.stop();
    heartbeat.start();
    omnyEventsStart();
    sessionInterval = setInterval(userSessionManager.resetUserSession, 60000);
    userSessionManager.removeEventListenersForUserActions();
  }
}

export function stopHeartBeat() {
  if (!window.Taq.getExtension) return;
  const heartbeat = window.Taq.getExtension('HeartBeat');

  if (heartbeat) {
    omnyEventsStop();
    heartbeat.stop();
    clearInterval(sessionInterval);
    userSessionManager.resetUserSession(true);
  }
}
