import { healthyPlayActions, persistActions } from '../../actions/actions-const';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case healthyPlayActions.HEALTHY_PLAY_STATION: {
      const stationSlug = action.station.slug;
      const historyWithoutCurrentStation = state.filter((slug) => slug !== stationSlug);
      return [stationSlug, ...historyWithoutCurrentStation];
    }
    // we moeten deze handmatig doen omdat onze StationHistoryState een array is
    case persistActions.REHYDRATE:
      if (action.payload) {
        const rehydratedStationHistory = Object.values(action.payload.stationHistory);
        return rehydratedStationHistory;
      }
      return initialState;
    default:
      return state;
  }
}

/**
 * Given the current state, it returns the station history
 *
 * @param {object} state
 * @returns {array}
 */
export const getStationHistory = (state) => state.stationHistory;
