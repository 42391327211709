import * as cssVariable from 'cssVariables';

export default (theme) => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: 50,
    '& svg': {
      width: 30,
      height: 30,
      pointerEvents: 'none',
    },
  },

  label: {
    maxWidth: 'calc(100% - 75px)',
    flexGrow: 1,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: cssVariable.fontWeights.regular,
    margin: 0,
    paddingLeft: 0,
    fontSize: cssVariable.fontSizes.xsmall,
  },
  newLabel: {
    position: 'absolute',
    right: 20,
    top: 14,
    borderRadius: 2,
    color: cssVariable.white,
    fontSize: cssVariable.fontSizes.xxsmall,
    fontWeight: cssVariable.fontWeights.bold,
    height: 20,
    width: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: cssVariable.red500,
  },

  '@keyframes fadeIn': {
    '0%': { opacity: '0' },
    '100%': { opacity: '1' },
  },
  fadeIn: {
    opacity: 0,
    animation: '$fadeIn 500ms forwards',
    animationDelay: '200ms',
  },

  labelCollapsibleNav: {},
  newLabelCollapsibleNav: {},
  [theme.breakpoints.down('sl')]: {
    label: {
      paddingLeft: 8,
      fontSize: cssVariable.fontSizes.medium,
    },
    newLabel: {
      top: 24,
    },

    labelCollapsibleNav: {
      paddingLeft: 0,
      fontSize: cssVariable.fontSizes.xsmall,
    },

    newLabelCollapsibleNav: {
      top: 14,
    },
  },
  smallIcon: {
    '& $label': {
      fontWeight: cssVariable.fontWeights.regular,
    },
  },
});
