import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';

import styles from './Switch.styles';

const useStyles = createUseStyles(styles, { name: 'Switch' });
const Switch = ({ light, active, onChange, ...rest }) => {
  const classes = useStyles();
  const onClick = () => {
    if (onChange) {
      onChange(!active);
    }
  };

  return (
    <button
      {...rest}
      type="button"
      className={classNames(classes.switch, {
        [classes.light]: light,
        [classes.active]: active,
      })}
      onClick={onClick}
    />
  );
};

Switch.propTypes = {
  active: PropTypes.bool.isRequired,
  light: PropTypes.bool,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  light: false,
  onChange: null,
};

export default Switch;
