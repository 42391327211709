import { TILE_COMPONENT_TYPES } from '../TileComponent/TileComponent.const';

// xs: 480, // extraSmall
// sm: 768, // small
// md: 900, // smallMedium
// sl: 1025, // medium
// ml: 1280, // large
// ll: 1440, // extraLarge
// lg: 1680, // extraExtraLarge
// k: 1920 // 1080p
// kk: 2048 // 2k
// kkk: 2880  // 3k
// kkkk: 3840 // 4k

// Breakpoint is value in percentage and can go from 0 to 100

export const DEFAULT_BREAKPOINTS = {
  sm: 29,
  sl: 19,
  ml: 13.7,
  lg: 11.6,
  kk: 8.5,
  kkkk: 7,
};

// Set promotion to be 4 tiles width
const generateWithProportion = (proportion: number) => {
  const retVal = {};
  Object.keys(DEFAULT_BREAKPOINTS).forEach((key) => {
    retVal[key] = DEFAULT_BREAKPOINTS[key] * proportion;
  });
  return retVal;
};

export const TILE_BREAKPOINTS = {
  [TILE_COMPONENT_TYPES.PROMOTION]: {
    ...generateWithProportion(5),
  },
  [TILE_COMPONENT_TYPES.TAG]: {
    ...generateWithProportion(1.25),
  },
};
