import { connect } from 'react-redux';
import { withAnalytics } from 'analytics';
import { stop, loadOnDemandClip } from 'actions/player-actions';
import {
  getOnDemandPlaybackTime,
  getIsLoading,
  getIsCurrentOnDemandPlaying,
  getOnDemandPlaybackDuration,
  isCurrentOnDemandActiveSelector,
} from 'store/reducers/player';
import { getUserSearch } from 'store/reducers/search';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import OnDemandTile from './OnDemandTile';
import { setActive } from 'actions/search-actions';

const mapStateToProps = (state, ownProps) => ({
  isLoading: getIsLoading(state),
  isPlaying: getIsCurrentOnDemandPlaying(state, ownProps),
  isActive: isCurrentOnDemandActiveSelector(state, ownProps),
  onDemandClipInPlayerTime: getOnDemandPlaybackTime(state, ownProps),
  onDemandClipInPlayerDuration: getOnDemandPlaybackDuration(state),
  userSearched: getUserSearch(state),
  clip: ownProps,
});

const loadOnDemandClipWithAnalytics = withAnalytics(loadOnDemandClip, {
  player_name: 'tile',
  link_id: CONTENT_TYPES.ONDEMAND_TILE,
});

const stopWithAnalytics = withAnalytics(stop, {
  player_name: 'tile',
  link_id: CONTENT_TYPES.ONDEMAND_TILE,
});

const mapDispatchToProps = {
  onPlay: loadOnDemandClipWithAnalytics,
  onStop: stopWithAnalytics,
  clearSearch: () => setActive(false),
};

export default connect(mapStateToProps, mapDispatchToProps)(OnDemandTile);
