import { Styles } from 'jss';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  containerClass: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    minWidth: '100%',
    // minHeight: '100vh',
    flex: 1,
    backgroundColor: theme.palette.navigation.background,
  },
});
