const mixedFetchBuilder = (slug, contentType, i) => {
  switch (contentType) {
    case 'station':
      return `${contentType + i}: station(slug: "${slug}") {
        id
        type
        title
        slug
        media {
          uri
          source
        }
        images {
          uri
          imageType
        }
        config {
          type
          entries {
            key
            value
            type
          }
        }
      }`;
    case 'podcast':
      return `${contentType + i}: podcast(slug: "${slug}") {
        title
        description
        slug
        id
        subTitle
        type
        images {
          uri
          imageType
        }
      }`;
    case 'genre':
    case 'mood':
      return `${contentType + i}: tag(slug: "${slug}") {
        id
        slug
        title
        type
        images {
          uri
          imageType
        }
        config {
          entries {
            key
            value
          }
        }
      }`;
    default:
      return '';
  }
};

export default mixedFetchBuilder;
