import * as cssVariable from 'cssVariables';

export default (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: cssVariable.grey600,
  },
  iconButton: {
    padding: 0,
    background: 'none',
    border: 'none',
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: ({ lightVariant, color }) => (color ? color : lightVariant ? cssVariable.grey600 : cssVariable.blue500),
    fill: ({ color }) => color || cssVariable.blue500,
    width: ({ size }) => size || 35,
    height: ({ size }) => size || 35,
    '& > svg': {
      width: ({ size }) => size || 30,
      height: ({ size }) => size || 30,
      transform: 'scaleX(1)',
    },
  },
  iconButtonIsSkipForward: {
    '& > svg': {
      transform: 'scaleX(-1)',
    },
  },
  skipLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: ({ size }) => size || 35,
    height: ({ size }) => size || 35,
    lineHeight: ({ size }) => `${size || 35}px`,
    fontSize: cssVariable.fontSizes.xxsmall,
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    container: {
      display: 'none',
    },
  },
});
