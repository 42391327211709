import * as cssVariable from 'cssVariables';

export default (theme) => ({
  container: {
    padding: [10, 26],
    paddingRight: 0,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '35%',
  },
  containerMobile: {},
  infoArea: {
    paddingLeft: 15,
    paddingTop: 16,
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
    color: cssVariable.white,
    maxWidth: '100%',
  },
  infoAreaMobile: {},
  title: {
    fontSize: cssVariable.fontSizes.small,
    fontWeight: cssVariable.fontWeights.bold,
    lineHeight: '19px',
    ...cssVariable.ellipsis,
  },
  subtitle: {
    fontSize: cssVariable.fontSizes.small,
    lineHeight: '19px',
    textTransform: 'capitalize',
    ...cssVariable.ellipsis,
  },
  link: {
    color: cssVariable.white,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  hideDesktopSmall: {},

  [`@media (max-width: ${theme.breakpoints.values.ml}px)`]: {
    container: {
      maxWidth: '33%',
    },
  },

  [`@media (max-width: ${theme.breakpoints.values.sl}px)`]: {
    containerMobile: {
      maxWidth: '33%',
    },
  },

  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    container: {
      padding: [10, 16],
    },
    containerMobile: {
      maxWidth: '80%',
    },
  },

  [`@media (max-width: ${theme.breakpoints.values.xs}px)`]: {
    hideDesktopSmall: {
      display: 'none',
    },
    containerMobile: {
      maxWidth: '60%',
    },
  },
});
