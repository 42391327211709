import { connect } from 'react-redux';

import { openFold, closeFold } from 'actions/podcastFold-actions';
import { getIsOpen, getPodcast } from 'store/reducers/podcastFold';

import { getUserSearch } from 'store/reducers/search';
import { saveRecentSearch } from 'actions/search-actions';
import { State } from 'redux';
import PodcastTile from './PodcastTile';
import { stop, loadOnDemandClip } from 'actions/player-actions';
import { getIsPlaying } from 'store/reducers/player';
import { getCurrentOnDemandClip } from 'store/reducers/player';

const mapStateToProps = (state: State) => ({
  foldIsOpen: !!getIsOpen(state),
  podcastSlugInFold: getPodcast(state),
  userSearched: getUserSearch(state),
  isPlaying: getIsPlaying(state),
  currentClip: getCurrentOnDemandClip(state),
});

const mapDispatchToProps = {
  closeFold,
  openFold,
  saveRecentSearch,
  onPlay: loadOnDemandClip,
  onStop: stop,
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastTile);
