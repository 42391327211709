import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showTalpaGigyaModal } from 'actions/gigya-actions';
import styles from './GigyaDialog.styles';

import { GIGYA_ACCOUNT_ACTION, GIGYA_MODAL_MESSAGE, GIGYA_SCREENSET } from './Gigya.const';
import { initGigya } from './Gigya.utils';
import ButtonTypes from 'components/Button/Button.const';
import { createUseStyles } from 'react-jss';
import Button from 'components/Button';
import CrossIcon from 'components/svg/Cross';

const useStyles = createUseStyles(styles, { name: 'GigyaDialog' });
function GigyaDialog(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const search = window.location.search;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activationSuccess, setActivationSuccess] = useState(false);
  const DialogTitle = activationSuccess
    ? GIGYA_MODAL_MESSAGE.DIALOG_TITLE_SUCCESS
    : GIGYA_MODAL_MESSAGE.DIALOG_TITLE_FAILED;
  const Title = activationSuccess
    ? GIGYA_MODAL_MESSAGE.CONTENT_TITLE_SUCCESS
    : GIGYA_MODAL_MESSAGE.CONTENT_TITLE_FAILED;
  const Text = activationSuccess ? GIGYA_MODAL_MESSAGE.TEXT_SUCCESS : GIGYA_MODAL_MESSAGE.TEXT_FAILED;

  const onModalCloseRequest = () => {
    // Optionally do stuff here before closing the modal
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (search.includes(`?account-action=${GIGYA_ACCOUNT_ACTION.ACTIVATION}`)) {
      setIsModalOpen(true);
      setActivationSuccess(search.indexOf('errorCode=0') > -1);
    }

    if (search.includes(`?account-action=${GIGYA_ACCOUNT_ACTION.RESET_PASSWORD}`)) {
      initGigya().then(() => {
        dispatch(showTalpaGigyaModal(GIGYA_SCREENSET.REGISTRATION_LOGIN, GIGYA_SCREENSET.RESET_PASSWORD_SCREEN));
      });
    }
  }, [search]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <div data-testid="gigya-dialog" className={classes.modalBackground}>
      <div className={classes.modalBox}>
        <div className={classes.header}>
          <div className={classes.dialogTitle} data-testid="gigya-dialog-title">
            {DialogTitle}
          </div>
          <div onClick={onModalCloseRequest} data-testid="gigya-dialog-close-button" className={classes.showCloseIcon}>
            <CrossIcon />
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <div className={classes.dialogContent}>
            <div className={classes.dialogContentTitle} data-testid="gigya-dialog-content-title">
              {Title}
            </div>
            <div className={classes.dialogContentText}>{Text}</div>
            <div className={classes.dialogButtonSection}>
              {activationSuccess && (
                <Button
                  type={ButtonTypes.SECONDARY}
                  data-testid="gigya-dialog-secondary-button"
                  text="Sluiten"
                  onClick={onModalCloseRequest}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GigyaDialog;
