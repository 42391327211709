import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { isMobile } from 'util/device';

import { PODCASTS_PAGE_TITLE } from 'globalConst/const';
import SHOW_MORE from 'globalConst/searchResults-const';

import { SCHEMA_PAGE_TYPE, SCHEMA_ACTION_TYPE } from 'globalConst/schema-const';
import NotFoundPage from 'components/pages/status/NotFoundPage';
import { BreadcrumbsSchema, PageTypeSchema, PodcastsSchema, DeeplinkSchema } from 'components/Schema';
import PageHeader from 'components/PageHeader';
import PageContent from 'components/PageContent';
import Collection from 'components/Collection';
import { DisplayLayout } from 'components/Collection/Collection.const';
import { TILE_COMPONENT_TYPES } from 'components/TileComponent/TileComponent.const';
import { isEmpty } from 'util/index';
import { createUseStyles } from 'react-jss';

import styles from './PodcastPage.styles';
import classNames from 'classnames';
import LoadingPage from 'components/pages/status/LoadingPage';

const useStyles = createUseStyles(styles, { name: 'PodcastPage' });
function PodcastPage(props) {
  const {
    isComponentLoading,
    title,
    description,
    slug,
    tags,
    mainPodcastImage,
    author,
    labels,
    location,
    pageLink,
    feedUrl,
    language,
    metadata,
  } = props; // error removed
  let { episodes } = props;

  const classes = useStyles();
  const isBadRequest = location.pathname !== pageLink;

  const podcastLatestEpisode = episodes && episodes[0];

  const deeplink = [
    {
      type: SCHEMA_ACTION_TYPE.LISTEN_ACTION,
      link: `play/podcast/${slug}/last`,
    },
  ];

  if (isComponentLoading) {
    return <LoadingPage />;
  }
  if (!isComponentLoading && isBadRequest) {
    return <NotFoundPage />;
  }

  const metaTitle = metadata ? metadata[0].title : null;
  const metaDescription = metadata ? metadata[0].description : null;
  const metaShareTitle = metadata ? metadata[0].shareTitle : null;
  const metaShareDescription = metadata ? metadata[0].shareText : null;

  const metadataObj = {
    title: metaTitle || title,
    description: metaDescription || description,
    tags,
    labels,
    share: {
      url: window.location.href,
      title: metaShareTitle || metaTitle || title,
      description: metaShareDescription || metaDescription || description,
      image: mainPodcastImage,
    },
  };
  const rows = isMobile ? SHOW_MORE.PODCASTS.EPISODE_LIMIT : episodes && episodes.length;
  return (
    <Fragment>
      <PageTypeSchema type={SCHEMA_PAGE_TYPE.ITEM_PAGE} metadata={metadataObj} />
      <DeeplinkSchema deeplinks={deeplink} />
      <PageHeader {...props} podcastLatestEpisode={podcastLatestEpisode} isPodcastDetailPage metadata={metadataObj} />
      <BreadcrumbsSchema level2={PODCASTS_PAGE_TITLE} level3={title} />
      <PageContent>
        {!isEmpty(episodes) ? (
          <Collection
            items={episodes}
            rows={rows || SHOW_MORE.PODCASTS.DEFAULT_EPISODE_LIMIT}
            display={DisplayLayout.LIST}
            tileComponent={TILE_COMPONENT_TYPES.ON_DEMAND}
            contentTypeId="podcastCollection"
            isComponentLoading={isComponentLoading}
            title={isMobile ? null : 'Afleveringen'}
            showMoreButton={isMobile ? ['button'] : null}
            showMoreLabel={isMobile ? 'Toon meer afleveringen' : null}
            isPodcastPage
          />
        ) : (
          <div className={classNames(classes.noEpisodesInfo, { [classes.noEpisodesInfoMobile]: isMobile })}>
            Helaas hebben we geen afleveringen gevonden
          </div>
        )}

        <PodcastsSchema
          name={title}
          description={description}
          abstract={description}
          slug={slug}
          image={mainPodcastImage && mainPodcastImage.url}
          author={author || ''}
          keywords={labels && labels.join()}
          numberOfEpisodes={episodes && episodes.length}
          feedUrl={feedUrl}
          inLanguage={language}
        />
      </PageContent>
    </Fragment>
  );
}

PodcastPage.defaultProps = {
  author: null,
  description: null,
  episodes: null,
  error: false,
  isComponentLoading: false,
  labels: null,
  mainPodcastImage: null,
  slug: null,
  tags: null,
  title: null,
  modalIsOpen: false,
  feedUrl: null,
  language: null,
  metadata: null,
};

PodcastPage.propTypes = {
  author: PropTypes.string,
  description: PropTypes.string,
  episodes: PropTypes.array,
  error: PropTypes.bool,
  isComponentLoading: PropTypes.bool,
  labels: PropTypes.array,
  mainPodcastImage: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  setModalClosed: PropTypes.func.isRequired,
  slug: PropTypes.string,
  tags: PropTypes.array,
  title: PropTypes.string,
  location: PropTypes.object,
  pageLink: PropTypes.string,
  feedUrl: PropTypes.string,
  language: PropTypes.string,
  metadata: PropTypes.object,
};

export default withRouter(PodcastPage);
