import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import { NavLink } from 'react-router-dom';
import * as analytics from 'analytics';
import graphql from 'api/graphql';
import * as cssVariable from 'cssVariables';
import { isIE11andLower } from 'util/device';
import logger from 'util/logger';

function BannerButton({ buttonText, pageLink, url, onLoad, onStop, isPlaying, playStationSlug, classes, title }) {
  const [station, setStation] = useState();

  useEffect(() => {
    if (playStationSlug) {
      graphql
        .fetchStation({ slug: playStationSlug })
        .then((response) => {
          setStation(response);
        })
        .catch((error) => {
          logger.error(`Couldn't fetch station ${playStationSlug}`, error);
        });
    }
  }, [playStationSlug]);

  const handlePlayToggle = () => {
    if (isPlaying) {
      return onStop();
    }
    return onLoad(station);
  };

  if (pageLink && pageLink.slug) {
    const pathname = pageLink.parentSlug ? `/${pageLink.parentSlug}/${pageLink.slug}` : `/${pageLink.slug}`;
    return (
      <NavLink
        className={classes.button}
        to={pathname}
        onClick={() => analytics.trackEvent(analytics.generateCTAClickData(title, url, 'banner'))}
      >
        {buttonText || `ga naar ${pageLink.title}`}
      </NavLink>
    );
  }
  if (url) {
    return url.match('^(https|http)') ? (
      <a
        className={classes.button}
        href={url}
        onClick={() => analytics.trackEvent(analytics.generateCTAClickData(title, url, 'banner'))}
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonText || 'ga naar'}
      </a>
    ) : (
      <NavLink
        className={classes.button}
        to={url}
        onClick={() => analytics.trackEvent(analytics.generateCTAClickData(title, url, 'banner'))}
      >
        {buttonText || `ga naar ${url}`}
      </NavLink>
    );
  }
  if (playStationSlug && station) {
    return (
      <button className={classes.button} onClick={handlePlayToggle} type="button" data-testid="playToggleBtn">
        {!isPlaying ? buttonText || 'Speel af' : 'Speelt af'}
      </button>
    );
  }

  return null;
}

BannerButton.defaultProps = {
  playStationSlug: null,
  url: null,
  buttonText: null,
  onStop: null,
  isPlaying: false,
  onLoad: null,
  pageLink: null,
  title: null,
};

BannerButton.propTypes = {
  playStationSlug: PropTypes.string,
  url: PropTypes.string,
  buttonText: PropTypes.string,
  onStop: PropTypes.func,
  isPlaying: PropTypes.bool,
  onLoad: PropTypes.func,
  pageLink: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    parentSlug: PropTypes.string,
  }),
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const styles = {
  button: {
    ...cssVariable.baseButtonStyle,
    color: cssVariable.white,
    fontSize: cssVariable.fontSizes.xsmall,
    backgroundColor: cssVariable.green500,
    marginTop: 'auto',
    alignSelf: 'start',
    maxWidth: isIE11andLower ? 210 : 'auto',
    padding: '0 30px',
    '&:hover': {
      backgroundColor: cssVariable.grey600,
      textDecoration: 'none',
    },
  },
};

export default injectStyles(styles)(BannerButton);
