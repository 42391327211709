import injectStylesheet from 'react-jss';
import withBrowserState from 'components/hoc/withBrowserState';

/**
 * Shorthand to inject JSS styles in component.
 * @param {jss.Styles} styles - JSS styles definition object
 * @param {React.ComponentType} Component - React Component
 */
export const injectStyles = (styles, options) => (Component) => injectStylesheet(styles, options)(Component);

/**
 * Shorthand to inject JSS styles in component with responsive state.
 * @param {jss.Styles} styles - JSS styles definition object
 * @param {React.ComponentType} Component - React Component
 */
export const injectStylesResponsive = (styles) => (Component) => withBrowserState(injectStylesheet(styles)(Component));
