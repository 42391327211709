import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectStyles } from 'injectStyles';
import SVG from 'react-inlinesvg';
import * as cssVariables from 'cssVariables';
import { SmoothScrolling } from 'util/noscroll';
import { DEFAULT_HEADER_HEIGHT } from 'components/PageHeader/PageHeader.const';
import { isIE11andLower, isTouch } from 'util/device';
import { SCROLL_CONTAINER_ID } from '../../../globalConst/const';

class TabsHeader extends PureComponent {
  static propTypes = {
    activeTabId: PropTypes.string,
    activeTab: PropTypes.number,
    classes: PropTypes.object.isRequired,
    handleMenuClick: PropTypes.func.isRequired,
    hasAnchorTabs: PropTypes.bool,
    indexableTabs: PropTypes.bool,
    lastTabActive: PropTypes.bool,
    pageSlug: PropTypes.string,
    tabs: PropTypes.array,
    isModalOpen: PropTypes.bool,
  };

  static defaultProps = {
    activeTabId: null,
    activeTab: 0,
    hasAnchorTabs: false,
    indexableTabs: false,
    lastTabActive: false,
    pageSlug: null,
    tabs: [],
    isModalOpen: false,
  };

  tabNodes = {};

  state = {
    isStuck: false,
  };

  scrollContainer = isTouch ? window : document.getElementById(SCROLL_CONTAINER_ID);
  tabsStickPosition = isTouch ? 0 : DEFAULT_HEADER_HEIGHT;
  trackerRef = {};

  componentDidMount() {
    this.tabContainerRef.addEventListener('mousedown', this.tabsMouseDown);
    this.tabContainerRef.addEventListener('mousemove', this.tabsMouseMove);
    this.tabContainerRef.addEventListener('mouseleave', this.tabsMouseLeave);
    this.tabContainerRef.addEventListener('mouseup', this.tabsMouseUp);

    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.setIsHeaderInStuckState);
    }
  }

  componentDidUpdate(prevProps) {
    const { activeTabId, isModalOpen } = this.props;
    if (activeTabId !== prevProps.activeTabId || isModalOpen !== prevProps.isModalOpen) {
      this.scrollTabIntoView();
    }
  }

  componentWillUnmount() {
    this.tabContainerRef.removeEventListener('mousedown', this.tabsMouseDown);
    this.tabContainerRef.removeEventListener('mousemove', this.tabsMouseMove);
    this.tabContainerRef.removeEventListener('mouseleave', this.tabsMouseLeave);
    this.tabContainerRef.removeEventListener('mouseup', this.tabsMouseUp);
  }

  setIsHeaderInStuckState = (event) => {
    const {
      target: { scrollTop = window.scrollY },
    } = event;

    if (scrollTop % 4 !== 0) {
      return;
    }

    // TODO: Investigate why ref is sometime null
    try {
      const { top } = this.trackerRef.getBoundingClientRect();
      const newIsStuck = top <= this.tabsStickPosition;

      const { isStuck } = this.state;
      if (isStuck !== newIsStuck) {
        this.setState({ isStuck: newIsStuck });
      }
    } catch {}
  };
  setTrackerRef = (ref) => {
    this.trackerRef = ref;
  };

  setTabContainer = (ref) => {
    this.tabContainerRef = ref;
  };

  setTabRef = (ref) => {
    if (!ref) return false;
    this.tabNodes[ref.id] = {
      ref,
    };
    return null;
  };

  scrollTabIntoView = async () => {
    const { activeTabId } = this.props;

    if (this.isDragging || this.pushingDown || !activeTabId) return;

    const tabRect = this.tabNodes[activeTabId].ref.getBoundingClientRect();
    const conatainerRect = this.tabContainerRef.getBoundingClientRect();

    const tabIsinView = tabRect.left >= conatainerRect.left && tabRect.right <= conatainerRect.right;
    const scrollPosition = tabRect.left + this.tabContainerRef.scrollLeft - conatainerRect.left;

    if (!tabIsinView) {
      SmoothScrolling(this.tabContainerRef, 200, scrollPosition, true);
    }
  };

  tabsMouseDown = (e) => {
    this.pushingDown = true;

    this.dragStartPosition = {
      scrollLeft: this.tabContainerRef.scrollLeft,
      startX: e.pageX - this.tabContainerRef.offsetLeft,
    };
  };

  tabsMouseMove = (e) => {
    if (!this.pushingDown) return;

    this.draggingTimeout = setTimeout(() => {
      const { scrollLeft, startX } = this.dragStartPosition;
      const x = e.pageX - this.tabContainerRef.offsetLeft;
      const walk = (x - startX) * 3;
      this.tabContainerRef.scrollLeft = scrollLeft - walk;
      this.isDragging = true;
    }, 100);
  };

  tabsMouseLeave = () => {
    this.pushingDown = false;
    this.isDragging = false;
    clearTimeout(this.draggingTimeout);
    this.scrollTabIntoView();
  };

  tabsMouseUp = () => {
    this.pushingDown = false;
    clearTimeout(this.draggingTimeout);
    setTimeout(() => {
      this.isDragging = false;
    }, 100);
  };

  render() {
    const {
      activeTab,
      classes,
      indexableTabs,
      hasAnchorTabs,
      handleMenuClick,
      pageSlug,
      tabs,
      lastTabActive,
      isModalOpen,
    } = this.props;

    const { isStuck } = this.state;

    return (
      <>
        <div className={classes.tabsTracker} ref={this.setTrackerRef} />
        <div
          ref={this.setTabContainer}
          className={classNames(classes.tabs, {
            [classes.tabsStuck]: isStuck,
            [classes.tabsTouch]: isTouch,
            [classes.tabsStuckTouch]: isStuck && isTouch,
            [classes.tabsStuckIE]: isStuck && isIE11andLower,
            [classes.tabsNoIcon]: hasAnchorTabs,
            [classes.lastTabActive]: lastTabActive,
            [classes.tabsHidden]: isModalOpen && isTouch,
          })}
        >
          {!!tabs.length &&
            tabs.map((tab, index) => (
              <a
                href={indexableTabs ? `/${pageSlug}/${tab.slug}` : null}
                id={tab.slug}
                ref={this.setTabRef}
                key={`tab-${tab.title}-${tab.key}`}
                onClick={handleMenuClick(index)}
                type="button"
                className={classNames(classes.tab, {
                  [classes.tab_active]: index === activeTab,
                  [classes.tab_hover]: !isTouch,
                  [classes.tab_noIcon]: hasAnchorTabs || !(tab.icon && tab.icon.url),
                })}
              >
                {tab.icon && tab.icon.url && !isStuck && !hasAnchorTabs && (
                  <SVG className={classes.icon} src={tab.icon.url} />
                )}
                <h3 className={classes.title} data-testid={`tabsHeader_${tab.title.replace(/ /g, '-')}`}>
                  {tab.title}
                </h3>
              </a>
            ))}
        </div>
      </>
    );
  }
}

const TAB_HEIGHT_WITH_ICON_DESKTOP = 115;
const TAB_HEIGHT_NO_ICON_DESKTOP = 60;
const TAB_HEIGHT_WITH_ICON_MOBILE = 86;
export const TAB_HEIGHT_NO_ICON_MOBILE = 50;

const fullwidthGutter = cssVariables.gutter * 2;

const fullWidthStyles = {
  marginLeft: -fullwidthGutter,
  padding: `0 ${fullwidthGutter}px`,
  width: `calc(100% + ${fullwidthGutter * 2}px)`,
};

const styles = (theme) => ({
  tab_noIcon: {},
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${cssVariables.transparentLightmode010}`,
    marginBottom: 20,
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    position: 'sticky',
    top: DEFAULT_HEADER_HEIGHT,
    backgroundColor: theme.palette.background,
    zIndex: 3,

    userSelect: 'none',
    '& $tab:last-child': {
      borderRight: 0,
    },

    '&::after': {
      content: '""',
      position: 'sticky',
      right: -40,
      top: 0,
      height: '100%',
      width: 65,
      background: theme.palette.tabsScrollOverflowGradient,
      zIndex: 2,
      display: 'block',
      flexShrink: '0',
      pointerEvents: 'none',
    },

    // disable scroll bars
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent' /* Chrome/Safari/Webkit */,
    },

    '& $tab_noIcon': {
      height: TAB_HEIGHT_NO_ICON_DESKTOP,
      borderRight: 'none',
      minWidth: 155,
      '& $title': {
        fontSize: cssVariables.fontSizes.medium,
      },
    },
  },

  tabsHidden: {
    display: 'none',
  },

  tabsStuck: {
    ...fullWidthStyles,
    '& $tab': {
      height: TAB_HEIGHT_NO_ICON_DESKTOP,
    },
  },

  tabsTouch: {
    top: 0,
  },

  tabsStuckTouch: {
    position: 'fixed',
  },

  tabsStuckIE: {
    position: 'fixed',
    zIndex: theme.zIndex.navigation,
    width: 'auto',
    left: cssVariables.desktopMenuMaxWidth,
    right: ({ forceMiniPlayer }) => (forceMiniPlayer ? 0 : cssVariables.playerWidth),
    marginLeft: 0,
    // Small Desktop Menu
    [theme.breakpoints.down('sl')]: {
      left: cssVariables.desktopSmallMenuWidth,
    },
    // No Side Player
    [theme.breakpoints.down('md')]: {
      right: '0 !important',
    },
  },

  tab: {
    position: 'relative',
    display: 'flex',
    minWidth: 150,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderRight: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.background,
    paddingLeft: 13,
    paddingRight: 13,
    border: 'none',
    height: TAB_HEIGHT_WITH_ICON_DESKTOP,
    textDecoration: 'none',

    '&:active': {
      cursor: 'grabbing',
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: 2,
      backgroundColor: 'transparent',
      transition: 'all 0.2s ease',
    },

    '& $icon': {
      height: 60,
      color: cssVariables.grey500,
    },
  },

  // only enable hover on non touch devices
  tab_hover: {
    '&:hover': {
      '&::after': {
        backgroundColor: cssVariables.green500,
      },
      '& $icon': {
        color: cssVariables.green500,
      },
      '& $title': {
        color: cssVariables.green500,
      },
    },
  },

  tab_active: {
    '&::after': {
      backgroundColor: cssVariables.green500,
    },

    '& $icon': {
      color: cssVariables.green500,
    },
    '& $title': {
      color: cssVariables.green500,
    },
  },

  icon: {
    height: 60,
    transition: 'all 0.2s ease',
    color: cssVariables.grey500,
  },

  lastTabActive: {
    '&::after': {
      display: 'none',
    },
  },

  tabsTracker: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 1,
  },

  title: {
    color: cssVariables.grey500,
    margin: 0,
    paddingTop: 5,
    paddingBottom: 10,
    fontSize: cssVariables.fontSizes.xsmall,
    transition: 'all 0.2s ease',
  },

  [theme.breakpoints.down('sm')]: {
    tab: {
      height: TAB_HEIGHT_WITH_ICON_MOBILE,
    },
    title: {},
    tabs: {
      '&::after': {
        right: -20,
      },
    },
    tabsStuck: {
      height: TAB_HEIGHT_NO_ICON_MOBILE,
    },
    tabsNoIcon: {
      height: TAB_HEIGHT_NO_ICON_MOBILE,
      minWidth: 125,
      '& $title': {
        fontSize: cssVariables.fontSizes.xsmall,
      },
    },
  },
});

export default injectStyles(styles)(TabsHeader);
