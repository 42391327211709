import { connect } from 'react-redux';
import { getSearchIsActive } from 'store/reducers/search';
import ContentPageLayout from './ContentPageLayout';
import { State } from 'redux';

const mapStateToProps = (state: State) => ({
  isSearchActive: getSearchIsActive(state),
});

export default connect(mapStateToProps)(ContentPageLayout);
