import { getRemoteConfig, fetchAndActivate, getValue, getAll } from 'firebase/remote-config';
import { firebase } from './firebase';
import logger from 'util/logger';
import { DEFAULT_REMOTE_CONFIG_FLAGS } from './featureFlags.const';
import { IRemoteConfigFlags } from './featureFlags.types';
import withLock from 'util/withLock';

let cachedFeatureFlags: IRemoteConfigFlags | null = null;

const initializeFlagStore = async (): Promise<IRemoteConfigFlags> => {
  const remoteConfig = getRemoteConfig(firebase);
  remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG_FLAGS;

  return fetchAndActivate(remoteConfig)
    .then(() => getAll(remoteConfig))
    .then((remoteFlags) => {
      const featureFlags: IRemoteConfigFlags = {
        ...DEFAULT_REMOTE_CONFIG_FLAGS,
      };
      for (const [key, config] of Object.entries(remoteFlags || {})) {
        featureFlags[key] = config.asBoolean();
      }
      window.featureFlags = featureFlags;
      cachedFeatureFlags = featureFlags;
      return featureFlags;
    });
};

/**
 * Getting all feature flags from firebase
 * @returns {Promise<IRemoteConfigFlags>}
 */
export const getFlagStore: () => Promise<IRemoteConfigFlags> = withLock(async () => {
  if (cachedFeatureFlags) {
    return Promise.resolve(cachedFeatureFlags);
  }

  const flags = await initializeFlagStore();
  return flags;
});

/**
 * Getting flag value by flag name
 * @param {string} flagName- flag name from firebase
 * @returns {Promise<boolean>}
 */
export const getFlagValue = async (flagName: string): Promise<boolean> => {
  const remoteConfig = getRemoteConfig(firebase);

  let remoteFlag: boolean = DEFAULT_REMOTE_CONFIG_FLAGS[flagName];

  try {
    await fetchAndActivate(remoteConfig);
    remoteFlag = getValue(remoteConfig, flagName).asBoolean();
    return remoteFlag;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
