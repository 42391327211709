export const CLIP_FRAGMENT = `
  fragment ClipFragment on Clip {
  id
  slug
  type
  title
  description
  durationSeconds
  broadcastDate
  publishDate
  media {
    ...MediaFragment
  }
  images {
    ...ImageFragment
  }
  reference {
    ... on Podcast {
      id
      slug
      feedUrl
      images {
        id
        uri
      }
      title
      type
      subTitle
      description
    }
  }
  }
  `;
