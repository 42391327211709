import { connect } from 'react-redux';
import { setActive } from 'actions/search-actions';
import { getSearchIsActive, getSearchIsHidden } from 'store/reducers/search';
import { getHasMobileHeader } from 'store/reducers/navigation';
import { getPageFromSlug } from 'store/reducers/content';
import Search from './Search';

const mapStateToProps = (state) => ({
  isActive: getSearchIsActive(state),
  isHidden: getSearchIsHidden(state),
  hasMobileHeader: getHasMobileHeader(state),
  page: getPageFromSlug(state, state.content.pageSlug),
});
const mapDispatchToProps = {
  onSetActive: setActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
