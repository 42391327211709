import React, { useEffect, useState } from 'react';
import CollectionItem from 'api/models/CollectionItem';
import { createUseStyles } from 'react-jss';
import styles from './Swimlane.styles';
import TileComponent from '../TileComponent';
import classNames from 'classnames';
import SwimlaneArrow from './SwimlaneArrow';
import { isEmpty, isSafari } from 'util/index';
import useBrowser from '../hooks/useBrowser';
import { breakpoints } from 'components/theme/breakpoints';
import logger from 'util/logger';
import ContextMenu from 'components/ContextMenu';
import * as cssVariable from 'cssVariables';
import { getCurrentOnDemandClip } from 'store/reducers/player';
import { useSelector } from 'react-redux';
import { PodcastEpisode } from 'api/models';
import { isTablet } from 'util/device';

type SwimlaneProps = {
  display: string;
  contentTypeId: string;
  items: Array<unknown>;
  tileComponent: string;
  showControlButton?: boolean;
  collectionId: string;
  title?: string;
  rows?: number;
  isListTile?: boolean;
} & Record<string, unknown>;

interface ScrollEvent {
  target: HTMLDivElement;
}

interface PodcastTileDetailsForContextMenu {
  id?: string;
  author?: string;
  slug?: string;
  collectionId?: string;
  contentTypeId?: string;
  title?: string;
  image?: { url: string };
  episodes?: Array<PodcastEpisode>;
  onPlay: (episode: PodcastEpisode) => void;
  onStop: () => void;
  isPlaying: boolean;
  currentClip: Record<string, string>;
  podcastTileLocation: {
    x: number;
    width: number;
  };
  isHoverShown: boolean;
  toggleContextMenu: () => void;
}

const useStyles = createUseStyles(styles, { name: 'Swimlane' });
const Swimlane = ({ items, tileComponent, rows = 1, isListTile, title, ...rest }: SwimlaneProps): JSX.Element => {
  const classes = useStyles();

  const [directions, setDirections] = useState({
    left: false,
    right: false,
  });
  const [contextMenuWidth, setContextMenuWidth] = useState(0);
  const [contextMenuX, setContextMenuX] = useState(0);
  const [isRightSwimlane, setIsRightSwimlane] = useState(false);
  const [details, setDetails] = useState({
    onPlay: null,
    onStop: null,
    isHoverShown: null,
    toggleContextMenu: () => {},
  });
  const [podcast, setPodcast] = useState({
    author: '',
    slug: '',
    title: '',
    contentTypeId: '',
    id: '',
    collectionId: '',
    image: { url: '' },
    episodes: null,
  });

  const [parentPosition, setParentPosition] = useState({
    x: 0,
    height: 0,
  });

  const currentClip = useSelector(getCurrentOnDemandClip);

  const itemStyle = isListTile
    ? items.length <= rows || isTablet()
      ? classes.itemListFullWidth
      : classes.itemList
    : classes.item;
  const browser = useBrowser();
  const swimlaneId = (rest.collectionId || rest.slug) as string;

  const handleScroll = (e: ScrollEvent | Event) => {
    const wrapper = e.target as HTMLDivElement;
    const swimlane = document.getElementById(swimlaneId);
    if (!swimlaneId) {
      logger.error('swimlaneId is undefined in Swimlane.ts');
      return;
    }

    const leftOffset = browser.down(breakpoints.sm) ? 20 : 40;
    const rightOffset = browser.down(breakpoints.sm) ? 15 : 45;

    let nextDirections = {};
    if (wrapper.scrollLeft - leftOffset > swimlane?.scrollLeft && !directions.left) {
      nextDirections = {
        ...nextDirections,
        left: true,
      };
    }
    if (wrapper.scrollLeft - leftOffset <= swimlane?.scrollLeft && directions.left) {
      nextDirections = {
        ...nextDirections,
        left: false,
      };
    }

    const rightCorner = wrapper.scrollWidth - wrapper.scrollLeft - rightOffset - leftOffset;

    if (rightCorner > swimlane?.clientWidth && !directions.right) {
      nextDirections = {
        ...nextDirections,
        right: true,
      };
    }

    if (rightCorner <= swimlane?.clientWidth && directions.right) {
      nextDirections = {
        ...nextDirections,
        right: false,
      };
    }
    if (!isEmpty(nextDirections)) {
      setDirections({
        ...directions,
        ...nextDirections,
      });
    }
  };

  useEffect(() => {
    if (swimlaneId) {
      handleScroll({
        target: document.getElementById(`wrapper-${swimlaneId}`) as HTMLDivElement,
      });
    }
  }, [browser.size, swimlaneId]);

  useEffect(() => {
    const scrollElem = document.getElementById(`wrapper-${swimlaneId}`);
    scrollElem.addEventListener('scroll', handleScroll);

    return () => {
      scrollElem.removeEventListener('scroll', handleScroll);
    };
  }, [directions]);
  // TODO: tile component prop hasActionButtons={match.path !== '/radiozenders'}
  const renderTile = (tile: CollectionItem, idx: number, rowNum: number) => (
    <div key={tile.id || tile.slug} className={classNames(itemStyle, classes[tileComponent])}>
      <TileComponent
        tileComponent={tileComponent}
        isListTile={isListTile}
        {...rest}
        {...tile}
        index={idx}
        rowNum={rowNum}
        collectionTitle={title}
      />
    </div>
  );

  const scroll =
    (direction = 'right') =>
    () => {
      const wrapper = document.getElementById(`wrapper-${swimlaneId}`);
      // 50% for small screens
      let percentage = 0.5;

      if (browser.up(breakpoints.ml)) {
        // 25% for other screens
        percentage = 0.25;
      }

      const amount = window.innerWidth * percentage;

      const left = direction === 'right' ? wrapper.scrollLeft + amount : wrapper.scrollLeft - amount;

      if (isSafari) {
        const smoothScroll = (nextPoint: number) => {
          wrapper.scrollTo({
            left: nextPoint,
            behavior: 'smooth',
          });
          if (direction === 'right' && nextPoint >= left) {
            return;
          }
          if (direction === 'left' && nextPoint <= left) {
            return;
          }
          setTimeout(() => smoothScroll(direction === 'right' ? nextPoint + 10 : nextPoint - 10));
        };

        smoothScroll(wrapper.scrollLeft);
        return;
      }

      wrapper.scrollTo({
        left,
        behavior: 'smooth',
      });
    };

  const { left = false, right = false } = directions;

  const itemsPerRow = {};
  let rowNum = 0;
  const rowList = [...Array(rows)].map((a, idx) => idx);

  items.forEach((item) => {
    rowList.some((row) => {
      if (rowNum === row) {
        if (!itemsPerRow[row]) {
          itemsPerRow[row] = [];
        }
        itemsPerRow[row].push(item);
        return true;
      }
    });
    rowNum += 1;
    if (rowNum >= rows) {
      rowNum = 0;
    }
  });

  const handleOutsideClick = () => {
    const contextMenu = document.getElementById('context-menu');
    const parentOfContextMenu = contextMenu.parentNode.parentNode.parentNode;
    const thisSwimlane = document.getElementById(swimlaneId);
    if (parentOfContextMenu === thisSwimlane) {
      setDetails({ ...details, isHoverShown: false });
    }
  };

  useEffect(() => {
    window.addEventListener('handleOutsideClick', handleOutsideClick);
    return () => {
      window.removeEventListener('handleOutsideClick', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('toggleContextMenu', configureToggle);
    return () => {
      window.removeEventListener('toggleContextMenu', configureToggle);
    };
  }, []);

  const configureToggle = ({
    detail: {
      podcastTileLocation,
      onPlay,
      onStop,
      isHoverShown,
      toggleContextMenu,
      author,
      slug,
      title,
      contentTypeId,
      id,
      collectionId,
      image,
      episodes,
    },
  }: CustomEvent<PodcastTileDetailsForContextMenu>) => {
    setContextMenuX(podcastTileLocation?.x);
    setContextMenuWidth(podcastTileLocation?.width);
    setDetails({
      onPlay,
      onStop,
      isHoverShown,
      toggleContextMenu,
    });
    setPodcast({
      author,
      image,
      slug,
      title,
      contentTypeId,
      id,
      collectionId,
      episodes,
    });

    const swimlaneAsParent = document.getElementById(swimlaneId);
    const itemAsChild = document.getElementById(`${collectionId}_${slug}`);
    const rightSwimlane = swimlaneAsParent ? swimlaneAsParent.contains(itemAsChild) : false;
    setIsRightSwimlane(rightSwimlane);

    if (swimlaneAsParent) {
      setParentPosition({
        x: swimlaneAsParent.getBoundingClientRect()?.x,
        height: swimlaneAsParent.offsetHeight,
      });
    }
  };

  const getShareDescription = () => {
    return `Luister de laatste aflevering van ${podcast?.title}`;
  };

  return (
    <div data-testid={`swimlane-${rest.contentTypeId}-${rest.slug}`} id={swimlaneId} className={classes.swimlane}>
      <SwimlaneArrow visible={left} handleClick={scroll('left')} direction="left" />
      <div id={`wrapper-${swimlaneId}`} key={swimlaneId} className={classes.wrapper}>
        <div className={classes.section}>
          {Object.values(itemsPerRow).map((section: Array<unknown>, idx) => {
            let rowNum = 1 - rows + idx;
            return (
              <div key={idx} className={classes.items}>
                {section.map((tile: CollectionItem) => {
                  rowNum = rowNum + rows;
                  return renderTile(tile, idx, rowNum);
                })}
              </div>
            );
          })}
        </div>
      </div>
      {isRightSwimlane && (
        <div
          className={classes.hoverBackdropDefault}
          style={{
            position: 'relative',
            left: `${contextMenuX - parentPosition?.x + contextMenuWidth}px`,
            top: `-${parentPosition?.height - contextMenuWidth + 45}px`,
          }}
          id={`hover-${swimlaneId}`}
        >
          <ContextMenu
            params={{ id: podcast?.id }}
            right
            item={podcast}
            color={cssVariable.white}
            onDemandClip={currentClip}
            viewBox="0, 0, 33, 33"
            width={contextMenuWidth}
            isInPodcastSwimlane={true}
            description={getShareDescription()}
            isHoverShown={details?.isHoverShown}
            toggleContextMenu={details?.toggleContextMenu}
            collectionTitle={title}
          ></ContextMenu>
        </div>
      )}
      <SwimlaneArrow visible={right} handleClick={scroll()} direction="right" />
    </div>
  );
};

export default Swimlane;
