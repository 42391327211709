import * as cssVariable from 'cssVariables';

export default {
  container: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '80%',
    minHeight: 40,
    padding: [5, 0, 0],
    borderRadius: 2,
  },
  albumCover: {
    position: 'relative',
    flex: 'none',
    width: 38,
    height: 38,
    marginRight: 10,
    borderRadius: 2,
    overflow: 'hidden',
    '& > img': {
      width: '100%',
      height: '100%',
    },
  },
  iconOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: cssVariable.transparentLightmode030,
    color: cssVariable.white,
    padding: 12,
  },
  nowPlayingTrack: {
    boxShadow: `0 1px 3px 0 ${cssVariable.transparentLightmode005}, 0 5px 10px 0 ${cssVariable.transparentLightmode010}`,
  },
  info: {
    flex: 1,
    overflow: 'hidden',
  },
  title: {
    ...cssVariable.ellipsis,
    fontWeight: cssVariable.fontWeights.bold,
    maxWidth: '100%',
    color: cssVariable.grey100,
    fontSize: 12,
    lineHeight: 1,
    marginBottom: 5,
    textTransform: 'capitalize',
    margin: 0,
  },
  artist: {
    ...cssVariable.ellipsis,
    color: cssVariable.grey100,
    fontSize: 12,
    lineHeight: 1,
    fontWeight: cssVariable.fontWeights.light,
    textTransform: 'capitalize',
    margin: 0,
  },
  smallerIcon: {
    marginRight: 5,
    '& > svg': {
      width: 20,
      height: 20,
    },
  },
};
