import { MOOD, GENRE, BRAND, PAGE_TYPE, PODCAST_GENRE, CONFIG_KEY } from 'globalConst/const';
import { Image, Refs, UniversalCollectionItem, UniversalConfig, UniversalImage, UniversalRef } from 'api/interfaces';
import CollectionItem from './CollectionItem';
import isEmpty from 'util/isEmpty';
import Metadata from 'api/models/Metadata';

export interface UniversalTag extends UniversalCollectionItem {
  shortTitle: string;
  images: Array<UniversalImage>;
  config: Array<UniversalConfig>;
  labels: string[];
  refs: Array<UniversalRef>;
  metadata: Metadata;
}
/**
 * @class
 * A representation of a Tag
 *
 * @param {object} tag - tag model
 */

export default class Tag extends CollectionItem {
  labels: string[];
  config: Array<UniversalConfig>;

  overviewSlug?: string;
  refs: Refs[];
  pageLink?: string;
  brandColor: string;

  image: Image;
  headerImage: Image;
  logo: Image;
  logoSecondary?: Image;
  shareImage?: Image;
  metadata?: Metadata;

  constructor(item: UniversalCollectionItem) {
    super(item);
    if (!item) {
      return null;
    }

    const tag = <UniversalTag>item;

    this.labels = tag.labels;
    this.config = tag.config;

    if (tag.images && Array.isArray(tag.images)) {
      tag.images.forEach(({ imageType, uri, title }) => {
        switch (imageType) {
          case 'image':
            this.image = {
              url: uri,
              altText: title,
            };
            break;
          case 'header':
            this.headerImage = {
              url: uri,
              altText: title,
            };
            break;
          case 'logo':
            this.logoSecondary = {
              url: uri,
              altText: title || this.title,
            };
            break;
          case 'logoWhite':
            this.logo = {
              url: uri,
              altText: title || this.title,
            };
            break;
          default:
            break;
        }
      });
    }

    if (!this.headerImage && this.image) {
      this.headerImage = this.image;
    }

    if (Array.isArray(tag.refs)) {
      this.overviewSlug = (tag.refs.find(({ targetType }) => targetType === 'overviewCollection') || {}).uri;
      this.refs = tag.refs
        // Only use Components which are prepared to use graphQL
        .filter(({ targetType }) =>
          ['tagCollection', 'podcastCollection', 'stationCollection', 'overviewCollection'].includes(targetType)
        )
        .map(({ id, targetType, uri }) => ({
          uuid: id,
          contentTypeId: targetType,
          slug: uri,
        }));
    }

    switch (this.type) {
      case MOOD:
        this.pageLink = this.slug && PAGE_TYPE.MOODS.replace(':slug', this.slug);
        break;
      case GENRE: {
        this.pageLink = this.slug && PAGE_TYPE.GENRE.replace(':slug', this.slug);
        break;
      }
      case PODCAST_GENRE: {
        this.pageLink = this.slug && PAGE_TYPE.PODCAST_GENRE.replace(':slug', this.slug);
        break;
      }
      case BRAND: {
        const slug = this.slug;

        const confArray = this.config?.[0]?.entries || [];
        const containsMainStationSlug = confArray.some((conf) => conf.key === CONFIG_KEY.MAIN_STATION_SLUG);
        if (containsMainStationSlug) {
          this.pageLink = slug && PAGE_TYPE.BRAND.replace(':slug', slug);
        } else {
          this.pageLink = slug && PAGE_TYPE.NON_STATION_BRAND.replace(':slug', slug);
        }
        break;
      }
      default:
        break;
    }

    if (Array.isArray(tag.config)) {
      this.brandColor = tag.config.reduce((acc, { entries }) => {
        const brandColor = entries.find(({ key }) => key === CONFIG_KEY.BACKGROUND_COLOR);
        return brandColor ? brandColor.value : acc;
      }, '#000');
    }

    if (!isEmpty(tag.metadata) && !isEmpty(tag.metadata[0])) {
      this.metadata = new Metadata(tag.metadata[0]);
    }

    // Make this class read-only
    Object.freeze(this);
  }
}
