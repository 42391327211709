import { contains, isEmpty } from 'util/index';
import Tab from './Tab';
import Collection from './Collection';
import { UniversalCollection, UniversalConfig, UniversalTab } from '../interfaces';
import { TAB_TYPES } from 'globalConst/const';
import CONTENT_TYPES from 'globalConst/contentTypes-const';

interface UniversalTabs extends UniversalCollection {
  items: Array<UniversalTab>;
}

export default class Tabs extends Collection {
  contentTypeId: string;
  tabs: Array<Tab>;
  hasAnchorTabs = false;
  indexableTabs = false;

  constructor(collection: UniversalCollection, config: UniversalConfig[], options: string[]) {
    super(collection, config, options);
    if (isEmpty(collection)) {
      return null;
    }

    const tabsCollection = <UniversalTabs>collection;

    this.tabs = tabsCollection.items.map((tab) => new Tab(tab));

    if (!isEmpty(this.options)) {
      this.hasAnchorTabs = contains(this.options, TAB_TYPES.ANCHOR);
      this.indexableTabs = contains(this.options, TAB_TYPES.INDEXABLE);
    }

    this.contentTypeId = CONTENT_TYPES.TABS;

    // Make this class read-only
    Object.freeze(this);
  }
}
