import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPage, getIsLoading, getIsOnline, getFetchError } from 'store/reducers/content';

import ContentPage from './ContentPage';

const mapStateToProps = (state, ownProps) => {
  const { ContentPageComponent } = ownProps;

  return {
    isLoading: getIsLoading(state),
    isOnline: getIsOnline(state),
    error: getFetchError(state),
    page: getPage(state, ownProps),
    ContentPageComponent,
  };
};

export default withRouter(connect(mapStateToProps)(ContentPage));
