export default function generateTileClick(pageTitle, pathname, category, userSearched) {
  if (userSearched) {
    return {
      event_name: 'content-interactions_expand',
      event_category: 'content-interactions',
      event_action: 'expand',
      event_label: pageTitle,
      page_category: category,
      page_id: pathname && pathname.split('/').pop().replace(/#.*$/, ''),
      search_query: userSearched,
    };
  }

  return {
    event_name: 'content-interactions_expand',
    event_category: 'content-interactions',
    event_action: 'expand',
    event_label: pageTitle,
    page_category: category,
    page_id: pathname && pathname.split('/').pop().replace(/#.*$/, ''),
  };
}
