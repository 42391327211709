import { queryRequest } from './client';
import { getCollectionConfig } from './queries';

/**
 * Fetch a single collection config from the universal api
 * @param {string} collecion.slug
 * @returns {Object} config
 */
export const fetchCollectionConfig = async ({ slug }) => {
  const { data } = (await queryRequest({ query: getCollectionConfig, variables: { slug } })) || {};
  return data.collection.config;
};
