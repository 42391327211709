export function getCookie(name) {
  let value = null;
  const cookies = document.cookie.split('; ');

  cookies.forEach((cookie) => {
    const [cname, cvalue] = cookie.split('=');
    if (name === cname) {
      value = cvalue || null;
    }
  });
  return value;
}

export function setCookie(name, value, days, domain = document.domain, path = '/') {
  const date = new Date();
  date.setDate(date.getDate() + (days || 365));

  const cookie = [];
  cookie.push(`${name}=${value}`);
  cookie.push(`expires=${date.toUTCString()}`);

  if (domain) {
    cookie.push(`domain=${domain}`);
  }

  if (path) {
    cookie.push(`path=${path}`);
  }

  document.cookie = cookie.join(';');
}
