import { StyleTheme } from 'components/theme/StyleTheme';
import * as cssVariable from 'cssVariables';
import { Styles } from 'react-jss';

const thumbDimensions = {
  DESKTOP: 252,
};

export default (theme: StyleTheme): Styles => ({
  headerContent: {
    padding: `10px 40px ${cssVariable.gutter}px 40px`,
  },
  wrapper: {
    margin: [0, 0, 20],
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  flex2: {
    display: 'flex',
    width: 'auto',
  },
  image: {
    flex: `0 0 ${thumbDimensions.DESKTOP}px`,
    height: 200,
    width: 200,
    marginRight: 40,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: cssVariable.grey100,
    borderRadius: 5.26,
    marginTop: 8,
  },
  title: {
    marginBottom: 30,
    paddingLeft: 100,
    '& > h1': {
      maxWidth: 'max-content',
    },
  },
  podcastHeaderInfo: {
    flex: 1,
    marginTop: 10,
  },
  playPauseButton: {
    marginRight: 23,
    height: 50,
    minWidth: 180,
  },
  backButtonIcon: {
    transform: 'rotate(180deg)',
  },
  actionsHolder: {},
  buttonWrapper: {
    '& svg': {
      width: 25,
      height: 25,
    },
  },
  socialShareWrapper: {
    '& svg': {
      width: 33,
      height: 33,
    },
    '& button': {
      height: 50,
    },
  },
  socialShareWrapperMobile: {
    '& button': {
      height: 40,
    },
  },
  buttonIcon: {
    '& > svg': {
      width: 40,
      height: 40,
    },
  },
  dateTime: {
    display: 'flex',
    flexDiretion: 'row',
    color: cssVariable.grey500,
    marginTop: 10,
    marginBottom: 10,
    textTransform: 'capitalize',
  },
  descriptionWrapper: {
    maxWidth: 600,
    fontSize: cssVariable.fontSizes.medium,
    color: cssVariable.grey600,
  },
  mainPodcastTitle: {
    fontSize: cssVariable.fontSizes.medium,
    color: cssVariable.grey500,
    fontWeight: cssVariable.fontWeights.bold,
    margin: [15, 0, 15],
  },
  [theme.breakpoints.down('sl')]: {
    title: {
      '& > h1': {
        fontSize: cssVariable.fontSizes.large,
      },
    },
  },

  // mobile header
  containerMobile: {
    marginTop: -30,
    display: 'flex',
    justifyContent: 'center',
    height: 230,
    overflow: 'hidden',
    marginBottom: 25,
  },

  episodeMobileInfoArea: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateTimeMobile: {
    color: cssVariable.grey400,
    marginBottom: 10,
    textTransform: 'capitalize',
  },
  mainPodcastTitleMobile: {
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.grey400,
    fontWeight: cssVariable.fontWeights.bold,
    marginTop: 10,
  },
  titleMobile: {
    textAlign: 'center',
  },
  descriptionWrapperMobile: {
    maxWidth: 600,
    fontSize: cssVariable.fontSizes.medium,
    color: cssVariable.grey600,
    padding: 20,
  },

  playButtonMobile: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },

  backgroundImage: {
    backgroundColor: 'grey',
    backgroundPosition: [-45, -120],
    backgroundSize: '120%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    filter: 'blur(8px)',
    opacity: '.4',
  },
  tileImage: {
    width: 235,
    height: 235,
    borderRadius: 8,
    marginTop: 30,
    position: 'absolute',
    backgroundSize: 'contain',
  },
});
