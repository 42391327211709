import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

import styles from './LazyTileImage.styles';
import classNames from 'classnames';

interface LazyTileProps {
  isImg?: boolean;
  /**
   * alt text when `isImg` type is rendered
   */
  alt?: string;

  imageUrl: string;
  className?: string;

  children?: React.ReactNode;
}

const useStyles = createUseStyles(styles, { name: 'LazyTileImage' });
const observerNotSupported = typeof IntersectionObserver === 'undefined';

const LazyTileImage = ({ className, isImg = false, alt, imageUrl, children, ...rest }: LazyTileProps): JSX.Element => {
  const classes = useStyles();
  const [isIntersecting, setIntersecting] = useState(observerNotSupported);

  const observer = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (observerNotSupported) {
      return;
    }

    observer.current = new window.IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin: '0px',
        threshold: 0,
      }
    );

    const { current: currentObserver } = observer;
    if (ref?.current) {
      currentObserver.observe(ref.current);
    }

    return () => currentObserver.disconnect();
  }, []);

  const url = isIntersecting ? imageUrl : null;

  const cssClass = classNames(className, {
    [classes.loading]: !isIntersecting,
    [classes.loaded]: isIntersecting,
  });

  if (isImg) {
    return <img ref={ref} className={cssClass} src={url} alt={alt} {...rest} />;
  }

  return (
    <div
      ref={ref}
      className={cssClass}
      style={{
        backgroundImage: url ? `url(${url})` : null,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default LazyTileImage;
