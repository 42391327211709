import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" viewBox="0 0 23 13">
    <path
      d="M20,24 L43,24 L43,25 L20,25 L20,24 Z M20,30 L43,30 L43,31 L20,31 L20,30 Z M20,36 L43,36 L43,37 L20,37 L20,36 Z"
      transform="translate(-20 -24)"
    />
  </svg>
);

export default svgIcon(svg);
