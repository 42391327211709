import React from 'react';
import PropTypes from 'prop-types';

import Connection from 'components/svg/Connection';
import RadioError from 'components/svg/RadioError';
import Headphone from 'components/svg/Headphone';

import { isTouch } from 'util/device';

import { injectStyles } from 'injectStyles';
import classNames from 'classnames';
import styles from './PlayerUnavailable.styles';

const PlayerUnavailable = ({ classes, isOnline, hasError, hasContent }) => {
  if (isOnline && !hasError && hasContent) {
    return null;
  }

  let Icon = Connection;
  let title = 'Geen verbinding';
  let subtitle = 'We blijven het voor je proberen, maar het kan aan je verbinding liggen.';

  if (hasError) {
    Icon = RadioError;
    title = 'Oeps... er ging iets mis.';
    subtitle = 'De radiozender kan niet worden gestart.';
  }

  if (!hasContent) {
    Icon = Headphone;
    title = 'Start met luisteren';
    subtitle = 'Kies een radiozender, non-stop muziek of podcast';
  }

  return (
    <div
      className={classNames(classes.container, {
        [classes.smallDesktop]: !isTouch,
      })}
    >
      <div className={classes.iconContainer}>
        <div className={classes.icon}>
          <Icon inherit />
        </div>
      </div>
      <div className={classes.infoArea}>
        <span className={classes.title}>{title}</span>
        <span className={classes.subtitle}>{subtitle}</span>
      </div>
    </div>
  );
};

PlayerUnavailable.propTypes = {
  classes: PropTypes.object.isRequired,
  isOnline: PropTypes.bool,
  hasError: PropTypes.bool,
  hasContent: PropTypes.bool,
};

PlayerUnavailable.defaultProps = {
  isOnline: true,
  hasError: false,
  hasContent: true,
};

export default injectStyles(styles)(PlayerUnavailable);
