export const CAST_SENDER_SRC = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
/**
 * Constants of states for media playback
 * @enum {string}
 */
export const PLAYER_STATE = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
  ERROR: 'ERROR',
};

/**
 * Constants of chrome cast state
 * @const {object} cast.framework.CastState */
export const CAST_STATE = {
  CONNECTED: 'CONNECTED',
  CONNECTING: 'CONNECTING',
  NOT_CONNECTED: 'NOT_CONNECTED',
  NO_DEVICES_AVAILABLE: 'NO_DEVICES_AVAILABLE',
};

/**
 * Constants of chrome cast context event types
 * @const {object} cast.framework.CastContextEventType */
export const CAST_CONTEXT_EVENT_TYPE = {
  CAST_STATE_CHANGED: 'caststatechanged',
  SESSION_STATE_CHANGED: 'sessionstatechanged',
};

/**
 * Constants of chrome cast errors
 * @const {object} chrome.cast.Error */
export const ERROR_CODES = {
  CANCEL: 'The operation was canceled by the user.',
  TIMEOUT: 'The operation timed out.',
  API_NOT_INITIALIZED: 'The API is not initialized.',
  INVALID_PARAMETER: 'The parameters to the operation were not valid.',
  EXTENSION_NOT_COMPATIBLE: 'The API script is not compatible with the installed Cast extension.',
  EXTENSION_MISSING: 'The Cast extension is not available.',
  RECEIVER_UNAVAILABLE: 'No receiver was compatible with the session request.',
  SESSION_ERROR: 'A session could not be created, or a session was invalid.',
  CHANNEL_ERROR: 'A channel to the receiver is not available.',
  LOAD_MEDIA_FAILED: 'Load media failed.',
  DEFAULT: 'Something went wrong',
};

/**
 * Constants of VideoJWPlayer
 */
export const VIDEO_PLAYER_ID = 'JW-player-container';
export const JW_PLAYER_SCRIPT_SRC = '//content.jwplatform.com/libraries/pvBRb3xU.js';

/**
 * Constants for VideoPlayer
 */
export const HEALTHY_PLAY_DURATION = 30 * 1000;
export const HEALTHY_PLAY_CHECK_INTERVAL = 1000;

export const HLS_SCRIPT_SRC = 'https://cdn.jsdelivr.net/npm/hls.js@0.14.2';
export const JW_PLAYER_LOAD_TIMEOUT = 100;
