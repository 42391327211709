import * as cssVariable from 'cssVariables';

const DEFAULT_IMAGE_SIZE = 115;

export default (theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    marginTop: cssVariable.gutter * 2,
    marginBottom: cssVariable.gutter * 2,
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('ml')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  contentContainer: {
    flex: 1,
    padding: '36px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '36px 20px',
    },
  },

  containerWithImage: {
    '& $contentContainer': {
      [theme.breakpoints.down('ml')]: {
        paddingTop: 0,
        textAlign: 'center',
      },
    },
    // Move image between title and text for smaller screens
    [theme.breakpoints.down('ml')]: {
      paddingTop: 80,
      textAlign: 'center',
      flexDirection: 'column-reverse',
    },
    '& $title': {
      [theme.breakpoints.down('ml')]: {
        position: 'absolute',
        top: 40,
        left: 10,
        right: 10,
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.down('xs')]: {
        left: 0,
        right: 0,
      },
    },

    '& $longTitle': {
      [theme.breakpoints.down('xs')]: {
        fontSize: cssVariable.fontSizes.mediumLarge,
      },
    },
  },

  title: {
    hyphens: 'none',
    margin: 0,
    fontSize: cssVariable.fontSizes.xlarge,
    marginBottom: 16,
  },

  longTitle: {},

  text: {
    hyphens: 'none',
    fontSize: cssVariable.fontSizes.small,
  },

  imageContainer: {
    flex: 1,
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.up('ml')]: {
      paddingRight: 40,
    },
    [theme.breakpoints.down('ml')]: {
      padding: 10,
    },
  },

  image: {
    maxWidth: '100%',
    width: 400,
  },

  containerMediaLeft: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('ml')]: {
      flexDirection: 'column-reverse',
    },
    '& $imageContainer': {
      [theme.breakpoints.up('ml')]: {
        paddingLeft: 40,
        paddingRight: 0,
      },
    },
  },
  ctaContainer: {},

  containerThumbnail: {
    ...cssVariable.fullWidthStyles(theme),
    '& $title, & $ctaContainer': {
      textAlign: 'center',
    },
    '& $text': {
      paddingRight: DEFAULT_IMAGE_SIZE + 40,
      [theme.breakpoints.down('xs')]: {
        paddingRight: DEFAULT_IMAGE_SIZE - 45,
      },
    },
    '& $imageContainer': {
      position: 'absolute',
      right: 40,
      top: '50%',
      marginTop: -DEFAULT_IMAGE_SIZE / 2,
      height: DEFAULT_IMAGE_SIZE,
      width: DEFAULT_IMAGE_SIZE,
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        right: -45,
      },
    },
    '& $contentContainer': {
      minHeight: 300,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '&$gradientBackground': {
      paddingTop: 50,
    },
    '&$gradientBackground $title': {
      position: 'relative',
      top: 0,
    },

    '&$containerMediaLeft': {
      '& $imageContainer': {
        left: 40,
        right: 'auto',
        [theme.breakpoints.down('xs')]: {
          left: -45,
        },
      },
      '& $text': {
        paddingRight: 40,
        paddingLeft: DEFAULT_IMAGE_SIZE + 40,
        [theme.breakpoints.down('xs')]: {
          paddingLeft: DEFAULT_IMAGE_SIZE - 45,
          paddingRight: 20,
        },
      },
    },
  },

  containerHorizontal: {
    ...cssVariable.fullWidthStyles(theme, -5),
    padding: '62px 40px',
    '& $contentContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
    },
  },

  containerButtonOnly: {
    padding: 0,
    '& $contentContainer': {
      justifyContent: 'center',
      padding: 10,
    },
  },

  centerContent: {
    textAlign: 'center',
  },

  containerVertical: {
    width: 'calc(100% + 80px)',
    margin: '0px -40px',
    textAlign: 'center',
    padding: '0 0 20px 0',
    '&:not(:first-child)': {
      padding: '40px 0 20px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 0 0 0',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% + 40px)',
      margin: '0px -20px',
      padding: 0,
    },
    '& $title': {
      position: 'relative',
      top: 0,
      left: 0,
      right: 0,
      fontSize: cssVariable.fontSizes.xxlarge,
      lineHeight: 1.1,
      whiteSpace: 'initial',
      textOverflow: 'initial',
      overflow: 'visible',
      [theme.breakpoints.down('ml')]: {
        fontSize: cssVariable.fontSizes.xlarge,
      },
    },
    '& $text': {
      maxWidth: 900,
      margin: '0 auto',
      color: cssVariable.grey600,
      fontWeight: cssVariable.fontWeights.regular,
      fontSize: cssVariable.fontSizes.medium,
      [theme.breakpoints.down('ml')]: {
        fontSize: cssVariable.fontSizes.small,
      },
    },
    '& $image': {
      width: '100%',
      maxWidth: '100%',
      margin: '20px 0 30px 0',
    },
  },

  // Background colors
  greyBackground: {
    background: cssVariable.grey100,
  },
  gradientBackground: {
    background: `linear-gradient(148.06deg, #37B376 0%, ${cssVariable.green500} 62.14%, #FACF18 100%)`,
    ...cssVariable.fullWidthStyles(theme, -5),
    borderRadius: 8,
    '& $contentContainer': {
      padding: '50px 40px',
      [theme.breakpoints.up('ml')]: {
        padding: '50px 90px',
      },
      [theme.breakpoints.down('ml')]: {
        paddingTop: 0,
      },
    },
    '&$containerMediaLeft $contentContainer': {
      [theme.breakpoints.up('ml')]: {
        paddingLeft: 40,
      },
    },
    [theme.breakpoints.down('ml')]: {
      '& a, & button': {
        width: '100%',
      },
    },
  },
  darkBackground: {
    background: cssVariable.grey600,
  },

  // Text colors
  whiteText: {
    color: cssVariable.white,
  },
  blackText: {
    color: theme.palette.text.primary,
  },
  greyText: {
    color: theme.palette.text.primary,
    '& $text': {
      color: theme.palette.text.secondary,
    },
  },

  // Small devices
  [theme.breakpoints.down('xs')]: {
    contentContainer: {},
    title: {
      fontSize: cssVariable.fontSizes.xlarge,
      lineHeight: '34px',
    },
    containerHorizontal: {
      borderRadius: 8,
      margin: '10px 0',
      padding: '36px 20px',
      '& $title': {
        fontSize: cssVariable.fontSizes.medium,
        marginRight: 20,
      },
      '& $contentContainer': {
        padding: 0,
      },
    },
  },
});
