import { podcastFoldActions } from '../../actions/actions-const';

const initialState = {
  isOpen: false,
  podcast: null,
  collectionId: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case podcastFoldActions.OPEN_FOLD:
      return {
        ...state,
        podcast: action.podcast,
        collectionId: action.collectionId,
        isOpen: true,
      };
    case podcastFoldActions.CLOSE_FOLD:
      return {
        ...state,
        collectionId: null,
        isOpen: false,
      };
    default:
      return state;
  }
}

/**
 * Given the current state, it returns wheather or not the podcast fold is open
 * @param {object} state
 * @returns {boolean}
 */
export const getIsOpen = (state) => state.podcastFold.isOpen;

/**
 * Given the current state, it returns the podcast shown in the fold
 * @param {object} state
 * @returns {string | null}
 */
export const getOpenCollectionId = (state) => state.podcastFold.collectionId;

/**
 * Given the current state, it returns the podcast shown in the fold
 * @param {object} state
 * @returns {string | null}
 */
export const getPodcast = (state) => state.podcastFold.podcast;
