import { Styles } from 'jss';
import * as cssVariable from 'cssVariables';
import tileStyles from 'tile-components-styles';
import { StyleTheme } from 'components/theme/StyleTheme';

const ALBUM_COVER_DIMENSION = 70;

export default (theme: StyleTheme): Styles => ({
  title: {
    ...tileStyles.titleDefault,
    textTransform: 'none',
    minHeight: 25,
    lineHeight: 1.2,
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.small,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& span': {
      fontSize: cssVariable.fontSizes.small,
      fontWeight: cssVariable.fontWeights.light,
      marginBottom: '5px',
    },
    '&:hover': {
      color: cssVariable.green500,
    },
  },
  titleLink: {
    color: cssVariable.grey600,
    textDecoration: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      color: cssVariable.green500,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  author: {
    marginTop: 5,
    fontSize: cssVariable.fontSizes.small,
  },
  backdropDefaultFix: {
    maxWidth: '99.7%',
    borderRadius: cssVariable.borderRadius.default,
  },
  ...tileStyles,
  listTileOdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '100%',
    paddingTop: '0',
    paddingBottom: '0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  listTileEven: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: cssVariable.grey100,
    height: '100%',
    paddingTop: '0',
    paddingBottom: '0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  listTileImgWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    cursor: 'pointer',
  },
  listTileButtons: { display: 'flex', flexDirection: 'row' },
  hoverBackdropDefault: {
    ...tileStyles.hoverBackdropDefault,
    borderRadius: cssVariable.borderRadius.default,
  },
  link: {
    position: 'absolute',
    left: 10,
    bottom: 5,
    fontSize: 13,
    fontWeight: cssVariable.fontWeights.light,
    color: cssVariable.white,
    textDecoration: 'none',
  },
  chevron: {
    verticalAlign: 'middle',
    marginTop: -4,
    height: 26,
    width: 26,
  },
  [theme.breakpoints.down('sm')]: {
    chevron: {
      display: 'none',
    },
  },
  listTileImage: {
    borderRadius: '4px',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  enumeration: {
    ...tileStyles.titleDefault,
    paddingRight: '8px',
    minWidth: '40px',
    textAlign: 'center',
    textTransform: 'none',
    marginTop: 12,
    minHeight: 25,
    lineHeight: 1.2,
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.small,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& span': {
      fontSize: cssVariable.fontSizes.small,
      fontWeight: cssVariable.fontWeights.light,
      marginBottom: '5px',
    },
  },
  mobileRowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '20px',
    minWidth: '20px',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '80px',
    minHeight: '80px',
    position: 'relative',
    color: theme.palette.text.primary,
  },
  authorText: {
    position: 'relative',
    height: 22,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'opacity 800ms ease',
    color: cssVariable.grey500,
    fontSize: '14px',
    letterSpacing: cssVariable.letterSpacing.small,
    textTransform: 'capitalize',
    '& > svg': {
      width: 18,
      height: 18,
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 6,
    paddingBottom: 6,
  },
  info: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  date: {
    position: 'relative',
    height: 22,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'opacity 800ms ease',
    color: cssVariable.grey500,
    fontSize: '14px',
    letterSpacing: cssVariable.letterSpacing.small,
    textTransform: 'capitalize',
    '& > svg': {
      width: 18,
      height: 18,
    },
  },
  thumbImage: {
    width: '70px !important',
    height: '70px',
    borderRadius: '4px !important',
  },
  listTileWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  newLabel: {
    borderRadius: 2,
    color: cssVariable.white,
    fontSize: cssVariable.fontSizes.xxsmall,
    fontWeight: cssVariable.fontWeights.bold,
    height: 20,
    width: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: cssVariable.red500,
    marginRight: 10,
  },
  albumCoverOverlay: {
    backgroundColor: cssVariable.transparentGrey500,
    color: cssVariable.white,
    height: ALBUM_COVER_DIMENSION,
    width: ALBUM_COVER_DIMENSION,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      width: 45,
      height: 45,
    },
    zIndex: 1,
  },
  roundOverlay: {
    backgroundColor: cssVariable.transparentLightmode030,
    width: 70,
    height: 70,
    borderRadius: '50%',
  },
  speakerIcon: {},
  stopIcon: {
    display: 'none',
  },
  withHoverEffect: {
    minHeight: 80,
    padding: 10,
    '& $albumCover': {
      borderRadius: 2,
      marginRight: cssVariable.gutter,
    },
    '& $title': {
      color: theme.palette.text.primary,
      fontSize: cssVariable.fontSizes.medium,
      lineHeight: 1.33,
    },
    '& $titleActivePlayer': {
      color: cssVariable.green500,
      fontWeight: cssVariable.fontWeights.regular,
    },
    '& $airDateHolder': {
      fontSize: cssVariable.fontSizes.small,
      lineHeight: 1.33,
    },
    '&:hover': {
      '& $speakerIcon': {
        display: 'none',
      },
      '& $stopIcon': {
        display: 'block',
      },
      '& $title': {
        color: cssVariable.green500,
      },
      '& $roundOverlay': {
        backgroundColor: cssVariable.white,
        color: cssVariable.green500,
      },
    },
  },
  test: {
    position: 'relative',
    '&:hover': {
      backgroundColor: cssVariable.grey200,
      boxShadow: `0 3px 5px ${cssVariable.transparentLightmode010}, 0 0 5px ${cssVariable.transparentLightmode005}`,
    },
  },
  test2: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    width: 70,
    height: 70,
    '&:hover': {
      background: cssVariable.linearGradient,
      opacity: 0.8,
      zIndex: 0,
      borderRadius: cssVariable.borderRadius.default,
    },
  },
});
