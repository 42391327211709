import React, { useState, useEffect } from 'react';
import Collection from 'components/Collection';
import { isEmpty } from 'util/index';
import { TILE_COMPONENT_TYPES } from 'components/TileComponent/TileComponent.const';
import { TILE_SIZES } from 'globalConst/const';

interface RecentlyPlayedStationsCollectionProps {
  history: string[];
  contentTypeId: string;
  slug: string;
  display: string;
  recentSearch: boolean;
  title: string;
}

const RecentlyPlayedStationsCollection = (props: RecentlyPlayedStationsCollectionProps): JSX.Element => {
  const { history = [], contentTypeId, slug, display, title } = props;

  const [stations, setStations] = useState([]);

  useEffect(() => {
    let items;

    if (history.length >= 2) {
      items = history.slice(0, 10);
    }

    if (!isEmpty(items)) {
      setStations(items.map((slug) => ({ contentTypeId: 'station', slug })));
    }
  }, [history]);

  if (isEmpty(stations)) {
    return null;
  }

  return (
    <Collection
      uuid={`collection-id-${contentTypeId}`}
      tileComponent={TILE_COMPONENT_TYPES.RECENTLY_PLAYED_STATION}
      contentTypeId={contentTypeId}
      slug={slug}
      items={stations}
      title={title}
      mobileRows={1}
      rows={1}
      display={display}
      tileSizes={TILE_SIZES.SMALL}
    />
  );
};

export default RecentlyPlayedStationsCollection;
