import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';

import { VIDEO_PLAYER_ID } from 'players/Players.const';

class JWPlayer extends PureComponent {
  static propTypes = {
    isShown: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes, isShown } = this.props;
    return (
      <div
        className={classNames(classes.container, {
          [classes.containerVisible]: isShown,
        })}
      >
        <div
          className={classNames(classes.playerWrapper, classes.containerVisible)}
          data-testid="video-JW-Player-wrapper-bottom"
        >
          <div id={`${VIDEO_PLAYER_ID}`} />
        </div>
      </div>
    );
  }
}

// This container is needed as a placeholder on first JWPlayer load, otherwise the content skips
const styles = {
  container: {
    display: 'flex',
    background: 'black',
    width: '100%',
  },
  containerVisible: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  playerWrapper: {
    width: '100%',
  },
};

export default injectStyles(styles)(JWPlayer);
