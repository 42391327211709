import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = ({ className }) => (
  <svg className={className} viewBox="0 0 17 18">
    <path
      d="M2 2.666v12.666L13.875 9 2 2.666zM1 18a1.004 1.004 0 01-1-1V1C0 .647.184.322.485.142c.302-.18.676-.19.986-.026l15 8a1.003 1.003 0 010 1.766l-15 8A1.008 1.008 0 011 18z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
);

export default svgIcon(svg);
