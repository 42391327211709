import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <title>Close icon</title>
    <g id="🥚-Atoms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Atom-/-Icon-/-General-/-Close"
        transform="translate(-12.000000, -12.000000)"
        fill="#8D929E"
        fillRule="nonzero"
      >
        <path
          d="M21.4141,20 L27.7071,13.707 C28.0981,13.316 28.0981,12.684 27.7071,12.293 C27.3161,11.902 26.6841,11.902 26.2931,12.293 L20.0001,18.586 L13.7071,12.293 C13.3161,11.902 12.6841,11.902 12.2931,12.293 C11.9021,12.684 11.9021,13.316 12.2931,13.707 L18.5861,20 L12.2931,26.293 C11.9021,26.684 11.9021,27.316 12.2931,27.707 C12.4881,27.902 12.7441,28 13.0001,28 C13.2561,28 13.5121,27.902 13.7071,27.707 L20.0001,21.414 L26.2931,27.707 C26.4881,27.902 26.7441,28 27.0001,28 C27.2561,28 27.5121,27.902 27.7071,27.707 C28.0981,27.316 28.0981,26.684 27.7071,26.293 L21.4141,20 Z"
          id="Fill-1"
        />
      </g>
    </g>
  </svg>
);

export default svgIcon(svg);
