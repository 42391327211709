import React from 'react';
import PropTypes from 'prop-types';
import Duration from 'components/Tiles/OnDemandTile/Duration';
import { convertSecondsToHMS } from 'util/time';

const ClipProgress = ({ clipProgress, clipDuration }) => {
  if (!clipProgress) {
    return <Duration duration={convertSecondsToHMS(clipDuration)} />;
  }

  const timeLeft = Math.round(clipDuration - clipProgress);

  if (timeLeft < 1) {
    return <span>Afgespeeld</span>;
  }
  return (
    <span>
      Nog <Duration duration={convertSecondsToHMS(timeLeft)} />
    </span>
  );
};

ClipProgress.propTypes = {
  clipProgress: PropTypes.number,
  clipDuration: PropTypes.number,
};

ClipProgress.defaultProps = {
  clipProgress: null,
  clipDuration: null,
};

export default ClipProgress;
