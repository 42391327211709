import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './PromotionEmpty.styles';
import PromotionEmptyIcon from 'components/svg/PromotionEmptyIcon';

const useStyles = createUseStyles(styles, { name: 'PromotionEmpty' });

const PromotionEmpty = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PromotionEmptyIcon className={classes.icon} />
      <span className={classes.title}>Op dit moment lopen er geen winacties. Kom snel terug!</span>
    </div>
  );
};

export default PromotionEmpty;
