import { connect } from 'react-redux';

import { getUserSearch } from 'store/reducers/search';

import { saveRecentSearch } from 'actions/search-actions';
import TagTile from './TagTile';
import { State } from 'redux';

const mapStateToProps = (state: State) => ({
  userSearched: getUserSearch(state),
});

const mapDispatchToProps = {
  saveRecentSearch,
};
export default connect(mapStateToProps, mapDispatchToProps)(TagTile);
