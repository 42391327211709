import { getStore } from 'store';

function generateTrackShowData() {
  const state = getStore().getState();

  return {
    c_media_music_trackname: state.nowPlayingInfo.playout ? state.nowPlayingInfo.playout.track.title : null,

    // Not in DL
    player_program: state.nowPlayingInfo.show ? state.nowPlayingInfo.show.name : null,
  };
}

export function setCustomData(customData, isPersistent) {
  window.Taq.push(['resetCustomData']);
  Object.assign(customData, generateTrackShowData());
  window.Taq.jukeCustomData = customData;
  window.Taq.push(['setCustomData', customData, !!isPersistent]);
}

export function getBitrate() {
  return '64kbps';
}

export function getSlugfromUrl() {
  return window.location.href.split('/').pop().replace(/#.*$/, '');
}

export function getIsOnHome() {
  const { pathname } = window.location;
  const [, currentLocation] = pathname.replace(__URL_PREFIX__, '').split('/');
  return currentLocation === '' || !currentLocation;
}

export function getMediaGenreForStation(station) {
  if (station && station.genres && station.genres.length) {
    return station.genres.join('|');
  }
  return null;
}

export function withAnalytics(actionCreator, customData) {
  return (...args) =>
    (dispatch, getState) => {
      const { isActive, query } = getState().search;
      if (isActive && query !== '') {
        const customDataWithSearch = Object.assign({ search_query: query }, customData);
        setCustomData(customDataWithSearch);
        dispatch(actionCreator(...args));
      } else {
        setCustomData(customData);
        dispatch(actionCreator(...args));
      }
    };
}

export function setPersistentData(data) {
  window.Taq.push(['setDataLayer', data, true]);
}

export function trackEvent(customData) {
  window.Taq.push(['setEventData', customData]);
  window.Taq.push(['track', 'link']);
}
