import { connect } from 'react-redux';

import { getContentWidth } from 'store/reducers/content';
import PodcastDetailPageHeader from './PodcastDetailPageHeader';
import { stop, loadOnDemandClip } from 'actions/player-actions';
import { getCurrentOnDemandClip } from 'store/reducers/player';

import { getIsPlaying } from 'store/reducers/player';

const mapStateToProps = (state) => ({
  contentWidth: getContentWidth(state),
  isPlaying: getIsPlaying(state),
  currentClip: getCurrentOnDemandClip(state),
});
const mapDispatchToProps = {
  onPlay: loadOnDemandClip,
  onStop: stop,
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastDetailPageHeader);
