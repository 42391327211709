import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { generateTileClick } from 'analytics';
import { getReaderAirDate } from 'lib/onDemand';
import ListTile from './ListTile';

function navLink(pathname, OnDemandClip, userSearched) {
  return {
    pathname,
    state: {
      analytics: generateTileClick(OnDemandClip.title, pathname, 'show page', userSearched),
    },
  };
}

export default class ListTileWrapper extends PureComponent {
  static defaultProps = {
    show: null,
    title: '',
    publishedUtc: null,
    display: null,
    id: null,
    userSearched: null,
    isPlaying: false,
  };

  static propTypes = {
    clip: PropTypes.object.isRequired,
    id: PropTypes.string,
    show: PropTypes.object,
    title: PropTypes.string,
    publishedUtc: PropTypes.string,
    display: PropTypes.string,
    userSearched: PropTypes.string,
    isPlaying: PropTypes.bool,
    onStop: PropTypes.func.isRequired,
    onPlay: PropTypes.func.isRequired,
  };

  handlePlayToggle = (forceOnlyPlay = false) => {
    const { clip, isPlaying, onStop, onPlay } = this.props;
    if (isPlaying && !forceOnlyPlay) {
      onStop();
    } else {
      onPlay(clip);
      // @Note: set upcoming trackss
      const { id, onOndemandStart } = clip || {};
      if (onOndemandStart) onOndemandStart(id);
    }
  };

  render() {
    const { clip, userSearched } = this.props;

    const { id, publishedUtc, podcastTitle, title, show } = clip || {};

    // @FIXME
    // Sometimes, when switching from live audio to track preview, `onDemandClip` becomes null
    // for a fraction of time... this is how its patched for now. We should re-check this when
    // actual previews from backend become available.
    if (!id) {
      return null;
    }

    let station;
    let tileTo = {};
    if (show) {
      station = show.station; // eslint-disable-line
      tileTo = {
        to: navLink(`/radioshows/${show.slug}`, this.props, userSearched),
      };
    }

    const onDemandTitle = podcastTitle || title || (show && show.name);
    const screenReaderTitle = `${onDemandTitle} van ${getReaderAirDate(publishedUtc)}`;
    const additionalProps = {
      ...this.props,
      station,
      tileTo,
      onDemandTitle,
      screenReaderTitle,
      onPlayPauseToggle: this.handlePlayToggle,
    };

    // @d.ravnjak do we use it or can be deleted ?
    return <ListTile clip={clip} {...additionalProps} />;
  }
}
