import { connect } from 'react-redux';
import withGraphqlData from 'components/hoc/withGraphqlData';
import { stop, loadStation } from 'actions/player-actions';
import { getIsPlaying } from 'store/reducers/player';
import withStationProps from 'components/hoc/withStationProps';
import asContentPage from 'components/hoc/asContentPage';
import StationPage from './StationPage';

const mapStateToProps = (state) => {
  return {
    isPlaying: getIsPlaying(state),
  };
};

const mapDispatchToProps = {
  loadStation,
  onLoadStation: loadStation,
  onStop: stop,
};

export default asContentPage(
  'station',
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStationProps(withGraphqlData(StationPage), { slug: true, metadata: true }))
);
