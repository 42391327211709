import React, { PureComponent } from 'react';
import * as cssVariable from 'cssVariables';
import PropTypes from 'prop-types';

export default class Logo extends PureComponent {
  // Create a unique id so no duplicated id's will be rendered in the DOM
  createId = () => `juke_gradient_${Math.random().toString(9).substr(2, 3)}`;

  render() {
    const { className } = this.props;
    const gradientId = this.createId();
    const fill = `url(#${gradientId})`;

    return (
      <svg viewBox="0 0 331 105" className={className}>
        <defs>
          <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id={gradientId}>
            <stop stopColor="#FACF18" offset="0%" />
            <stop stopColor={cssVariable.green500} offset="26.898%" />
            <stop stopColor="#37B376" offset="73.597%" />
            <stop stopColor="#3C9CDC" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M109.855 104.22c-26.848 0-37.945-12.808-37.945-35.59V2.588c0-1.41 1.16-2.55 2.592-2.55h20.68c1.43 0 2.59 1.14 2.59 2.55v66.475c.353 6.315 5.658 11.257 12.083 11.257s11.73-4.943 12.082-11.258V2.587c0-.676.273-1.325.76-1.804.486-.478 1.145-.747 1.832-.747h20.67c1.43 0 2.59 1.142 2.59 2.55V68.63c0 22.783-11.1 35.59-37.95 35.59zm-62-36.685c0 22.78-10.002 35.626-36.776 35.626H2.59c-1.43 0-2.59-1.14-2.59-2.55V81.75c0-1.41 1.16-2.55 2.592-2.55h9.344c8.76 0 10.057-4.637 10.057-10.134v-50.31c-.005-1.69-.8-3.29-2.154-4.33L3.57 3.236c-.605-.48-.837-1.28-.578-2 .258-.72.95-1.2 1.727-1.2h40.54c1.43 0 2.59 1.143 2.59 2.55v64.95zm150.72 1.455v30.273c0 1.41-1.16 2.55-2.592 2.55h-20.697c-1.375-.037-2.48-1.125-2.52-2.478V2.587c0-1.38 1.117-2.512 2.52-2.55h20.697c1.432 0 2.592 1.14 2.592 2.55v30.345c0 1.49.62 1.67 1.387.377l18.25-31.05c1.096-1.87 2.19-2.21 4.637-2.21h24.43c1.42 0 1.9 1.15 1.04 2.55l-28.45 46.71c-.57 1.03-.57 2.27 0 3.3l28.45 46.71c.78 1.4.38 2.55-1.04 2.55h-24.44c-2.468 0-3.54-.41-4.64-2.21l-18.25-31.04c-.768-1.296-1.388-1.13-1.388.36zm132.432-18.038c-.066.782-.554 1.468-1.278 1.796l-33.19 19.997c-.49.235-.8.723-.8 1.257 0 .535.31 1.022.79 1.258l33.73 23.356c.602.478.834 1.28.575 2-.26.72-.953 1.2-1.73 1.198h-59.3c-1.4 0-2.55-1.1-2.59-2.48V2.552c0-1.408 1.16-2.55 2.59-2.55h59.38c.776-.003 1.47.477 1.73 1.197.26.72.026 1.52-.58 2l-33.73 23.35c-.485.23-.793.72-.793 1.25s.306 1.02.793 1.26l33.04 20.08c.898.52 1.35 1.114 1.35 1.797z"
          fill={fill}
        />
      </svg>
    );
  }
}
Logo.defaultProps = {
  className: null,
};

Logo.propTypes = {
  className: PropTypes.string,
};
