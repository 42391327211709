import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { injectStyles } from 'injectStyles';
import * as cssVariable from 'cssVariables';

import FeaturedPodcast from './FeaturedPodcast';
import DEFAULT_TITLE from './FeaturedPodcasts.const';
class FeaturedPodcasts extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    slugs: PropTypes.array,
    title: PropTypes.string,
  };

  static defaultProps = {
    slugs: null,
    title: DEFAULT_TITLE,
  };

  render() {
    const { classes, slugs, title } = this.props;

    return (
      <Fragment>
        <div className={classes.featuredPodcastsTitle}>{title}</div>
        <div className={classes.featuredPodcastsWrapper}>
          {slugs &&
            slugs.map((slug) => (
              <FeaturedPodcast key={`featured-podcast-${slug}`} slug={slug} contentTypeId="featuredPodcast" />
            ))}
        </div>
      </Fragment>
    );
  }
}

const styles = {
  featuredPodcastsTitle: {
    marginBottom: 16,
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.medium,
  },
  featuredPodcastsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default injectStyles(styles)(FeaturedPodcasts);
