import React from 'react';
import { createUseStyles } from 'react-jss';
import { white, grey300, hexToRgba } from 'cssVariables';

const PROGRESS_WIDTH = 6;

interface DotEndProps {
  size: number;
  left: number;
  top: number;
  brandColor: string;
}

interface ShowProgressProps {
  size: number;
  brandColor?: string;
  progress?: number;
}

const DotEnd: React.FC<DotEndProps> = ({ size, left, top, brandColor }) => (
  <svg
    viewBox={`0 0 ${size} ${size}`}
    xmlns="http://www.w3.org/2000/svg"
    style={{ zIndex: 1, position: 'absolute', left: 0, bottom: 0, right: 0, top: 0 }}
  >
    <circle cx={left} cy={top} r={PROGRESS_WIDTH / 2} fill={brandColor} />
  </svg>
);

const ShowProgress: React.FC<ShowProgressProps> = ({ brandColor, size, progress = 0 }) => {
  const classes = useStyles({ size });

  if (progress === 0 || progress === 1) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.overlay} />
        <div className={classes.pie} style={{ backgroundColor: progress === 1 ? brandColor : grey300 }}></div>
      </div>
    );
  }
  let firstHalfAngle = 180;
  let secondHalfAngle = 0;

  const drawAngle = Math.round(progress * 360);

  // calculate the angle to be displayed if each half
  if (drawAngle <= 180) {
    firstHalfAngle = drawAngle;
  } else {
    secondHalfAngle = drawAngle - 180;
  }

  // Calculate position of the round dot at the end of the progress
  const cx = size / 2; // center of x(in a circle)
  const cy = size / 2; // center of y(in a circle)
  const r = size / 2 - PROGRESS_WIDTH / 2; // radius of a circle
  const ang = progress * 360 * (Math.PI / 180) - Math.PI / 2;

  const left = cx + r * Math.cos(ang);
  const top = cy + r * Math.sin(ang);

  return (
    <div className={classes.wrapper}>
      {size && (
        <div
          className={classes.dotStart}
          style={{
            backgroundColor: brandColor,
          }}
        />
      )}
      {size && <DotEnd size={size} top={top} left={left} brandColor={brandColor} />}
      <div className={classes.overlay} />
      <div className={classes.pie} style={{ backgroundColor: hexToRgba(brandColor, 0.3) }}>
        <div className={classes.clip1}>
          <div
            className={`${classes.slice} ${classes.slice1}`}
            style={{ transform: `rotate(${firstHalfAngle}deg)`, backgroundColor: brandColor }}
          ></div>
        </div>
        <div className={classes.clip2}>
          <div
            data-testid="showProgress-secondHalf"
            className={`${classes.slice} ${classes.slice2}`}
            style={{ transform: `rotate(${secondHalfAngle}deg)`, backgroundColor: brandColor }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  wrapper: ({ size }: ShowProgressProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: size || '100%',
    height: size || '100%',
    zIndex: -1,
    overflow: 'hidden',
    borderRadius: '100%',
  }),
  dotStart: {
    width: PROGRESS_WIDTH,
    height: PROGRESS_WIDTH,
    position: 'absolute',
    zIndex: 0,
    top: 0,
    borderRadius: PROGRESS_WIDTH,
    left: '50%',
    marginLeft: -PROGRESS_WIDTH / 2,
  },
  overlay: {
    position: 'absolute',
    zIndex: 1,
    top: PROGRESS_WIDTH,
    left: PROGRESS_WIDTH,
    right: PROGRESS_WIDTH,
    bottom: PROGRESS_WIDTH,
    backgroundColor: white,
    borderRadius: '100%',
    opacity: 1,
  },
  pie: {
    zIndex: -1,
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },

  clip1: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    clip: ({ size }: ShowProgressProps) => `rect(0px, ${size}px, ${size}px, ${size / 2}px)`,
  },
  slice1: {
    clip: ({ size }: ShowProgressProps) => `rect(0px, ${size / 2}px, ${size}px, 0px)`,
  },
  clip2: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    clip: ({ size }: ShowProgressProps) => `rect(0, ${size / 2}px, ${size}px, 0px)`,
  },
  slice2: {
    clip: ({ size }: ShowProgressProps) => `rect(0px, ${size}px, ${size}px, ${size / 2}px)`,
  },
  slice: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    transform: 'rotate(0)',
  },
});

export default ShowProgress;
