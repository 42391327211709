export function trackContentNavigate(tab) {
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'content-interactions_navigate',
      event_category: 'content-interactions',
      event_action: 'navigate',
      event_label: tab || null,
    },
  ]);
}

export function trackHomepageNavigation(tab) {
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'menu_select',
      event_category: 'menu',
      event_action: 'navigate',
      event_label: tab || null,
    },
  ]);
}

export function trackScroll(scrollDepth) {
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'content-interactions_scroll',
      event_category: 'content-interactions',
      event_action: 'scroll',
      event_label: scrollDepth,
    },
  ]);
}
