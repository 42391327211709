import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCollection } from 'store/reducers/content';
import { setContent } from 'actions/content-actions';
import { setSearchResults, setSearchIsLoading } from 'actions/search-actions';
import graphql from 'api/graphql';
import logger from 'util/logger';
import { Config, Refs, UniversalCollection, UniversalCollectionItem } from 'api/interfaces';
import { CONTENT_TYPES } from 'globalConst/contentTypes-const';
import * as analytics from 'analytics';
import Metadata from 'api/models/Metadata';

export interface IFetchResults {
  imageUrl?: string;
  title?: string;
  description?: string;
  contentTypeId?: string;
  slug?: string;
  subSlug?: string;
  query?: unknown;
  onError?: (error: unknown) => void;
  options?: string[];
  config?: Config;
  store?: boolean;
  refetchDeps?: Array<string | number>;
  refs?: Refs[];
  metadata?: Metadata;
  sections?: Array<UniversalCollection & { items?: Array<UniversalCollectionItem>; contentTypeId: string }>;
}

interface UseFetchData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: IFetchResults;
  isLoading: boolean;
  error?: boolean;
  loadMore: () => void;
  fetchDuration?: number;
}

interface IFetchData {
  isLoading: boolean;
  data: IFetchResults;
  error: boolean;
  fetchDuration?: number;
}

export function useFetchData({
  contentTypeId,
  slug,
  subSlug,
  query,
  onError,
  options,
  config,

  store,
  refetchDeps = [],
}: IFetchResults): UseFetchData {
  const [state, setState] = useState<IFetchData>({ isLoading: !!slug, data: null, error: false, fetchDuration: null });
  const dispatch = useDispatch();

  const dataRef = useRef(null);

  const dataFromStore = useSelector(getCollection(slug));

  useEffect(() => {
    let unmounted: boolean;

    async function fetchData() {
      try {
        const fetchFromGraphql = graphql.getFetchFunction(contentTypeId);

        /**
         * In case of new Algolia search we need to dispatch redux action for search loading state on this place
         * Because of lifecycle components and side effects we can't dispatch loading state action when onChange handler triggers
         * This is moment when search start loading
         */
        if (contentTypeId === CONTENT_TYPES.SEARCH) {
          dispatch(setSearchIsLoading());
        }

        const startTime = performance.now();
        const data = await fetchFromGraphql({
          slug,
          subSlug,
          query,
          // Remove contentTypeId after overview query cleanup
          contentTypeId,
          options,
          config,
        });
        const endTime = performance.now();

        if (!unmounted) {
          /**
           * In case of new Algolia search we need to dispatch redux action for setting data into search slice
           * And tracking data should be send
           * After NMP, delete this way of sending tracking data and update with new
           */
          if (contentTypeId === CONTENT_TYPES.SEARCH) {
            dispatch(setSearchResults(data));
            if (slug && slug.length >= 1) {
              analytics.trackSearchAlgolia(slug, data, endTime - startTime);
            }
          }

          if (store) {
            const collection = {};
            collection[slug] = data;
            dispatch(setContent(collection, contentTypeId));
          }
          dataRef.current = data;
          setState({ data, error: false, isLoading: false, fetchDuration: endTime - startTime });
        }
      } catch (graphqlError) {
        if (!unmounted) {
          dataRef.current = null;
          setState({ data: null, error: true, isLoading: false });
        }
        if (onError) {
          onError(slug);
        }
        logger.error(`Error ${graphqlError} while fetchData with graphQL for ${slug} | ${contentTypeId}`);
      }
    }

    if (slug && contentTypeId) {
      if (dataFromStore) {
        setState({ data: dataFromStore, error: false, isLoading: false });
      } else {
        fetchData();
      }
    }

    return () => {
      unmounted = true;
    };
  }, [slug, subSlug, query, ...refetchDeps]);

  const loadMore = useCallback(async () => {
    try {
      setState({ ...state, data: dataRef.current, isLoading: true });
      const fetchFromGraphql = graphql.getFetchFunction(contentTypeId);
      const data = await fetchFromGraphql({
        slug,
        subSlug,
        query,
        // Remove contentTypeId after overview query cleanup
        contentTypeId,
        options,
        config,
        data: dataRef.current,
      });

      if (store) {
        const collection = {};
        collection[slug] = data;
        dispatch(setContent(collection, contentTypeId));
      }
      dataRef.current = data;
      setState({ data, error: false, isLoading: false });
    } catch (graphqlError) {
      setState({ data: null, error: true, isLoading: false });
      if (onError) {
        onError(slug);
      }
      logger.error(`Error ${graphqlError} while fetchData with graphQL for ${slug} | ${contentTypeId}`);
    }
  }, [state]);

  return {
    ...state,
    loadMore,
  };
}
