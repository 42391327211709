import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import Loader from 'components/Loader';
import styles from './ShowMore.styles';

interface ShowMore {
  children: JSX.Element;
  hiddenItemsCount: number;
  isCarousel: boolean;
  isExpanded?: boolean;
  isLoading?: boolean;
  hideShowMoreButton: boolean;
  onClick: (isExpanded: boolean) => void;
}

const useStyles = createUseStyles(styles, { name: 'ShowMore' });
const ShowMore = ({
  children,
  hiddenItemsCount,
  isCarousel,
  isExpanded,
  isLoading,
  hideShowMoreButton,
  onClick,
}: ShowMore): JSX.Element => {
  const classes = useStyles();
  const isShowMoreButtonVisible = (hiddenItemsCount > 0 || isExpanded) && !hideShowMoreButton;
  const showMore = hiddenItemsCount > 0;

  const handleClick = useCallback(() => {
    if (isLoading) {
      return;
    }
    onClick(showMore);
  }, [isExpanded, hiddenItemsCount, isLoading]);

  if (isCarousel) {
    return children;
  }

  return (
    <>
      {children}
      {isShowMoreButtonVisible && (
        <div data-testid="show-more-show-more-btn" onClick={handleClick} className={classes.button}>
          {isLoading && <Loader size={25} color="default" />}
          {!isLoading && <span>{showMore ? `Toon ${hiddenItemsCount} meer` : 'Toon minder'}</span>}
        </div>
      )}
    </>
  );
};

export default ShowMore;
