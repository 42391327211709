import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';
import CollectionTiles from 'components/Collection/CollectionTiles';
import Swimlane from 'components/Swimlane';
import { DisplayLayout } from 'components/Collection/Collection.const';
import { isHDsupported, isTouch } from 'util/device';
import * as cssVariables from 'cssVariables';
import Card from './Card';
import { TILE_COMPONENT_TYPES } from 'components/TileComponent/TileComponent.const';

class CardDeck extends PureComponent {
  static defaultProps = {
    cards: null,
    contentWidth: null,
    description: null,
    minWidthItems: null,
    minWidthItemsMobile: null,
    showTitle: true,
    title: '',
    useCarousel: true,
  };

  static propTypes = {
    cards: PropTypes.array,
    classes: PropTypes.object.isRequired,
    contentWidth: PropTypes.number,
    collectionId: PropTypes.string,
    description: PropTypes.string,
    minWidthItems: PropTypes.number,
    minWidthItemsMobile: PropTypes.number,
    showTitle: PropTypes.bool,
    title: PropTypes.string,
    useCarousel: PropTypes.bool,
  };

  state = {
    visibleItems: 1,
  };

  componentDidMount() {
    this.calculateVisibleItems();
  }

  componentDidUpdate() {
    this.calculateVisibleItems();
  }

  calculateVisibleItems = () => {
    const { cards, contentWidth, minWidthItems, minWidthItemsMobile } = this.props;
    let visibleItems = 1;
    if (minWidthItems) {
      const minWidth = isTouch && minWidthItemsMobile ? minWidthItemsMobile : minWidthItems;
      while (contentWidth / (visibleItems + 1) > minWidth) {
        visibleItems += 1;
      }
    } else {
      visibleItems = cards && cards.length;
    }

    this.setState({ visibleItems });
  };

  setContainerRef = (ref) => {
    this.containerRef = ref;
  };

  render() {
    const { classes, collectionId, description, showTitle, title, useCarousel, minWidthItems } = this.props;
    let { cards } = this.props;
    const { visibleItems } = this.state;

    if (!cards || !cards.length) {
      return null;
    }

    cards = cards.map((card) => ({ ...card, collectionTitle: title }));

    const filteredCards = cards.filter((card) => {
      if (card.preferHD && !isHDsupported()) {
        return false;
      }
      if (card.hideOn && card.hideOn.touch && isTouch) {
        return false;
      }
      if (card.hideOn && card.hideOn.desktop && !isTouch) {
        return false;
      }
      return card;
    });

    return (
      <div className={classes.container} ref={this.setContainerRef}>
        {showTitle && <h2 className={classes.title}>{title}</h2>}
        {description && <p className={classes.description}>{description}</p>}
        {cards.length > visibleItems && useCarousel ? (
          <Fragment>
            {isTouch ? (
              <CollectionTiles
                tileComponent={TILE_COMPONENT_TYPES.CARD}
                itemsPerRow={visibleItems}
                mobileRows={1}
                rows={1}
                display={DisplayLayout.TILES}
                items={filteredCards}
                swipe
                title={title}
                collectionId="CardDeck"
                dots
              />
            ) : (
              <Swimlane
                collectionId={collectionId}
                display={DisplayLayout.CAROUSEL}
                tileComponent={TILE_COMPONENT_TYPES.CARD}
                items={filteredCards}
                peekNextItem
                itemsPerRow={visibleItems}
                contentTypeId="CardDeck"
                dots
                showControlButton={false}
              />
            )}
          </Fragment>
        ) : (
          <div
            className={classNames(classes.cards, {
              [classes.noMinWidth]: !minWidthItems,
            })}
          >
            {filteredCards.map((card, index) => (
              <div
                key={`${card.title}-${index}`}
                className={classes.cardWrapper}
                style={{ width: `${100 / visibleItems}%` }}
              >
                <Card {...card} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const CARD_PADDING = 10;

const styles = (theme) => ({
  container: {
    margin: `${cssVariables.gutter * 2}px 0`,
    padding: '10px 0',
  },
  cards: {
    margin: `0 -${CARD_PADDING}px`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  title: {
    margin: 0,
    width: '100%',
    fontFamily: cssVariables.fontFamily,
    fontSize: cssVariables.fontSizes.medium,
    fontWeight: cssVariables.fontWeights.bold,
    lineHeight: 1,
    height: 30,
    color: cssVariables.grey600,
  },
  description: {
    color: cssVariables.grey500,
    fontSize: cssVariables.fontSizes.small,
    lineHeight: 1.375,
    margin: 0,
    marginBottom: 5,
  },
  cardWrapper: {
    padding: CARD_PADDING,
  },
  noMinWidth: {
    '& $cardWrapper': {
      [theme.breakpoints.down('lg')]: {
        minWidth: '50% !important',
      },
      [theme.breakpoints.down('sl')]: {
        minWidth: '100% !important',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: '15px 0',
    },
    title: {
      fontSize: cssVariables.fontSizes.medium,
      textAlign: 'center',
    },
    description: {
      display: 'none',
    },
  },
});
export default injectStyles(styles)(CardDeck);
