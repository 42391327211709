import React from 'react';
import PropTypes from 'prop-types';
import svgIcon from 'components/hoc/svgIcon';
import * as cssVariable from 'cssVariables';

const svg = ({ strokeWidth }) => (
  <svg width="56px" height="35px" viewBox="0 0 56 35">
    <defs>
      <linearGradient x1="74.4747367%" y1="113.248246%" x2="25.594622%" y2="-13.8252756%" id="logo-gradient-definition">
        <stop stopColor="#FACF18" offset="0%" />
        <stop stopColor={cssVariable.green500} offset="27%" />
        <stop stopColor="#37B376" offset="74%" />
        <stop stopColor="#3C9CDC" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
      <g id="Desktop" fillRule="nonzero" stroke="url(#logo-gradient-definition)">
        <path
          d="M1.01133884,0.527573076 C0.955143256,0.517239653 0.897534416,0.517239653 0.841338836,0.527573076 C0.622398214,0.614198738 0.484824576,0.832698045 0.501338836,1.06757308 L0.501338836,33.0675731 C0.501338836,33.5867207 0.922191171,34.0075731 1.44133884,34.0075731 L54.4413388,34.0075731 C54.7008651,34.024173 54.9556718,33.9325154 55.1451562,33.7544001 C55.3346406,33.5762847 55.4418692,33.3276291 55.4413388,33.0675731 L55.4413388,1.06757308 C55.4578531,0.832698045 55.3202795,0.614198738 55.1013388,0.527573076 C54.8782941,0.454205537 54.6332703,0.530524443 54.4913388,0.717573076 L41.6813388,18.9275731 C41.4455543,19.2956881 41.0333566,19.512452 40.5964391,19.4980932 C40.1595216,19.4837344 39.7624454,19.2403747 39.5513388,18.8575731 L28.5513388,1.04757308 C28.1413388,0.357573076 27.7413388,0.367573076 27.3513388,1.04757308 L16.3513388,18.8675731 C16.1314433,19.2308486 15.7421588,19.4576426 15.3176873,19.4697704 C14.8932158,19.4818981 14.4916174,19.2777007 14.2513388,18.9275731 L1.43133884,0.727573076 C1.32963963,0.59974574 1.17467878,0.525954859 1.01133884,0.527573076 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

svg.propTypes = {
  strokeWidth: PropTypes.number.isRequired,
};

export default svgIcon(svg);
