import * as cssVariable from 'cssVariables';
import { Styles } from 'jss';
import { StyleTheme } from 'components/theme/StyleTheme';

import { breakpoints, mediaQuery } from '../theme/breakpoints';

const listItem = {
  position: 'relative',
  padding: [11, 0],
  borderRadius: 2,
  cursor: 'pointer',
  verticalAlign: 'middle',
  fontSize: cssVariable.fontSizes.xsmall,
  color: cssVariable.grey500,
  fontWeight: cssVariable.fontWeights.bold,
  transition: 'all 0.2s ease',
  '&:hover, &:active': {
    color: cssVariable.black,
    background: cssVariable.grey100,
  },
  '& button': {
    width: '100%',
    height: '100%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 0,
    cursor: 'pointer',
  },
};

const styles = (theme: StyleTheme): Styles => ({
  menuButton: {
    display: 'block',
    lineHeight: 0,
    position: 'relative',
  },
  menuButtonComponent: {
    '& svg': {
      height: '2.2em',
      width: '2.2em',
      transform: 'rotate(90deg)',
    },
  },
  additionalMargin: {
    marginRight: -10,
  },
  menuButtonWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contextMenu: {
    background: cssVariable.white,
    borderRadius: 4,
    boxShadow: `0 5px 10px 0 ${cssVariable.transparentLightmode030}, 0 1px 3px 0 ${cssVariable.transparentLightmode010}`,
    left: '50%',
    padding: 0,
    position: 'absolute',
    transform: 'translateX(-50%)',
    zIndex: theme.zIndex.navigation,
    top: 60,
  },
  contextMenuHeader: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    fontSize: 16,
    justifyContent: 'flex-start',
    padding: '15px 20px',
    '& svg': {
      width: 36,
      height: 36,
    },
  },
  contextMenuHeaderWithTitle: {
    padding: '11px 40px 5px 20px',
  },
  contextMenuHeaderTitle: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '1.2em',
  },
  contextMenuCloseButton: {
    position: 'absolute',
    top: 3,
    right: 3,
    margin: 'auto',
    padding: 0,
    background: 'none',
    border: 'none',
    color: cssVariable.grey500,
    cursor: 'pointer',
    display: 'block',
    lineHeight: 0,
    transition: 'all 0.2s ease',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      color: cssVariable.black,
    },
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    '& svg': {
      width: 26,
      height: 26,
    },
  },
  contextMenuListItem: {
    ...listItem,
  },
  listItemArrowRight: {
    position: 'absolute',
    top: '50%',
    right: 2,
    transform: 'translateY(-50%)',
    '& svg': {
      height: 14,
    },
  },
  contextMenuList: {
    listStyleType: 'none',
    margin: 0,
    padding: 5,
    textIndent: 0,
    '& .SocialMediaShareButton': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      padding: 15,
      '&:focus': {
        outline: 0,
      },
    },
  },
  contextMenuListHidden: {
    display: 'none',
  },
  contextMenuListItemLabel: {
    marginLeft: 15,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  contextMenuCustomListItemLabel: {
    paddingLeft: 15,
  },
  contextMenuSocialListWrapper: {
    display: 'none',
  },
  contextMenuSocialList: {
    margin: [10, 0, 5],
    padding: [0, 5],
    listStyleType: 'none',
  },
  contextMenuSocialListActive: {
    display: 'block',
  },
  contextMenuSocialListItem: {
    ...listItem,
    paddingLeft: 10,
    lineHeight: 1,
    '& button': {
      width: '100%',
      textAlign: 'left',
    },
  },
  contextMenuSocialListBack: {
    position: 'absolute',
    top: 13,
    left: 4,
    transform: 'rotate(180deg)',
    '& button': {
      border: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    '& svg': {
      height: 14,
    },
  },
  contextMenuSocialListBackButton: {
    '& path': {
      fill: cssVariable.grey500,
    },
    '&:hover path': {
      fill: cssVariable.grey600,
    },
  },
  contextMenuOffset: {},
  contextMenuFavourite: {
    padding: 0,
    '& $button': {
      color: cssVariable.grey500,
      '&:hover, &:active': {
        background: cssVariable.grey100,
        color: cssVariable.black,
        boxShadow: 'none',
        borderRadius: '2px',
      },
      border: 0,
      boxShadow: 'none',
      minWidth: 100,
      minHeight: 50,
      '& $span:first-of-type': {
        position: 'absolute',
        left: 3,
        padding: 8,
        '& $svg': {
          width: 20,
          height: 20,
        },
      },
      '& $span': {
        position: 'absolute',
        left: 45,
      },
    },
  },
  menuButtonHide: {
    display: 'none',
  },
  [mediaQuery.maxWidth(breakpoints.lg)]: {
    contextMenu: {
      left: 0,
      transform: 'translateX(calc(-100% + 45px))',
    },
  },
  [mediaQuery.maxWidth(breakpoints.ml)]: {
    contextMenu: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  pullToRight: {
    left: 'auto',
    right: 0,
    transform: 'none',
  },
  contextMenuMobile: {
    position: 'fixed',
    bottom: 10,
    left: 15,
    right: 15,
    transform: 'translateX(0)',
  },
});

export default styles;
