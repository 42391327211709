import { UniversalCollectionItem } from '../interfaces';
import { isEmpty } from 'util/index';

/**
 * @class
 * A representation of a universal collection item
 *
 */
export default class CollectionItem {
  id?: string;
  uuid?: string;
  slug: string;
  contentTypeId?: string;
  title: string;
  description?: string;

  type?: string;

  constructor(item: UniversalCollectionItem) {
    if (isEmpty(item)) {
      return null;
    }

    this.id = item.id;
    this.uuid = item.id;
    this.slug = item.slug;
    this.contentTypeId = item.type;

    this.title = item.title;
    this.description = item.description;

    this.type = item.type;
  }
}
