import { connect } from 'react-redux';

import { navigationEvents } from 'analytics/analytics-const';
import { toggleNavigation } from 'actions/navigation-actions';

import MenuButton from './MenuButton';

const mapDispatchToProps = {
  onToggleNavigation: () => toggleNavigation(navigationEvents.mainNav),
};

export default connect(null, mapDispatchToProps)(MenuButton);
