const BACK_BUTTON_HEIGHT = 40;

export default {
  button: {
    background: 'none',
    border: 'none',
    outline: 'none',
    width: BACK_BUTTON_HEIGHT,
  },
};
