import React from 'react';
const Copy = () => (
  <svg viewBox="0 0 20 20" fill="currentColor">
    <rect x="2" y="2" width="16" height="16" rx="3" />
    <rect x="9" y="6" width="2" height="8" rx="0.5" fill="#FFFFFF" />
    <rect x="9" y="6" width="2" height="8" rx="0.5" transform="translate(20) rotate(90)" fill="#FFFFFF" />
  </svg>
);

export default Copy;
