import gql from 'graphql-tag';
import { CLIP_FRAGMENT, MEDIA_FRAGMENT, IMAGE_FRAGMENT } from './fragments';

export const getClipsInProgressCollection = gql`
  query clipsInProgress($slug: String!) {
    clips: collection(slug: $slug) {
      ... on ClipCollection {
        id
        type
        slug
        title
        getClips {
          clips {
            ...ClipFragment
            progress {
              durationLength
              contentLength
              completed
            }
          }
        }
      }
    }
  }
  ${CLIP_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

export const getClipCollection = gql`
  query clipCollection($slug: String!) {
    collection(slug: $slug) {
      ... on ClipCollection {
        description
        id
        options
        slug
        title
        type
        getClips {
          clips {
            ...ClipFragment
            progress {
              durationLength
              contentLength
              completed
            }
          }
        }
        config {
          ...ConfigFragment
        }
      }
    }
  }
  ${CLIP_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;
