import React from 'react';

import TagTile from 'components/Tiles/TagTile';
import { TagTileProps } from 'components/Tiles/TagTile/TagTile';

import ShowTile from 'components/Tiles/ShowTile';
import { ShowTileProps } from 'components/Tiles/ShowTile/ShowTile';

import Promotion from 'components/Promotion';
import { PromotionProps } from 'components/Promotion/Promotion';
import OnDemandTile from 'components/Tiles/OnDemandTile';
import PodcastTile from 'components/Tiles/PodcastTile';
import { PodcastTileProps } from 'components/Tiles/PodcastTile/PodcastTile';

import ListTile from 'components/Tiles/ListTile';
import { ListTileProps } from 'components/Tiles/ListTile/ListTile';

import Card from 'components/CardDeck/Card';
import MixedContentItem from 'components/MixedContentItem';

import withGraphqlData from 'components/hoc/withGraphqlData';

import { TILE_COMPONENT_TYPES } from './TileComponent.const';
import StationTile from 'components/Tiles/StationTile';
import { DefaultProps } from 'components/Tiles/StationTile/StationTileContainer';

type TileComponentProps = {
  contentTypeId?: string;
  tileComponent: string;
  display: string;
  index?: number;
  rowNum?: number;
  isListTile?: boolean;
  collectionTitle?: string;
  isClipList?: boolean;
};

const TileComponent = ({
  tileComponent,
  isListTile,
  collectionTitle,
  contentTypeId,
  isClipList,
  ...props
}: TileComponentProps): JSX.Element => {
  switch (tileComponent) {
    case TILE_COMPONENT_TYPES.STATION:
      return <StationTile {...(props as unknown as DefaultProps)} contentTypeId={contentTypeId} />;

    case TILE_COMPONENT_TYPES.RECENTLY_PLAYED_STATION:
      const Component = withGraphqlData(StationTile);
      return <Component contentTypeId={contentTypeId} {...props} />;

    case TILE_COMPONENT_TYPES.PODCAST:
      return isListTile ? (
        <ListTile
          {...(props as unknown as ListTileProps)}
          contentTypeId={contentTypeId}
          collectionTitle={collectionTitle}
        />
      ) : (
        <PodcastTile
          {...(props as unknown as PodcastTileProps)}
          collectionTitle={collectionTitle}
          contentTypeId={contentTypeId}
        />
      );
    case TILE_COMPONENT_TYPES.ON_DEMAND:
      return <OnDemandTile {...props} contentTypeId={contentTypeId} />;

    case TILE_COMPONENT_TYPES.PROMOTION:
      return <Promotion {...(props as unknown as PromotionProps)} contentTypeId={contentTypeId} />;

    case TILE_COMPONENT_TYPES.SHOW:
      return <ShowTile {...(props as unknown as ShowTileProps)} />;

    case TILE_COMPONENT_TYPES.TAG:
      return <TagTile {...(props as unknown as TagTileProps)} contentTypeId={contentTypeId} />;

    case TILE_COMPONENT_TYPES.CARD:
      return <Card {...props} contentTypeId={contentTypeId} />;

    case TILE_COMPONENT_TYPES.MIXED_CONTENT:
      return <MixedContentItem {...props} contentTypeId={contentTypeId} />;

    case TILE_COMPONENT_TYPES.MIXED_CONTENT_WITH_HOC:
      const MixedContentItemWithHOC = withGraphqlData(MixedContentItem);
      return <MixedContentItemWithHOC contentTypeId={contentTypeId} {...props} />;
    case TILE_COMPONENT_TYPES.LIST:
      if (isClipList) {
        return (
          <ListTile
            {...(props as unknown as ListTileProps)}
            isClipList={isClipList}
            contentTypeId={contentTypeId}
            collectionTitle={collectionTitle}
          />
        );
      }
      return <OnDemandTile {...props} contentTypeId={contentTypeId} />;

    default:
      return null;
  }
};

export default TileComponent;
