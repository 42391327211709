import Timer from './Timer';
import { HEALTHY_PLAY_DURATION, HEALTHY_PLAY_CHECK_INTERVAL } from './Players.const';
import { noop } from 'util/index';

export default class VideoPlayer {
  constructor() {
    this.playSessionId = null;

    this.errorCallback = () => {
      throw new Error('Error callback must be set');
    };
  }

  /**
   * Uses the experimental Android/Chrome Media Session API to set the meta data
   * for the lock screen
   *
   * @param {string} title
   * @param {string} imageURL
   */
  setMediaSessionData = (title, imageURL) => {
    if (!title) {
      return;
    }

    if ('mediaSession' in navigator) {
      const config = {
        title,
      };

      if (imageURL) {
        config.artwork = {
          src: imageURL,
        };
      }

      navigator.mediaSession.metadata = new MediaMetadata({ config }); //eslint-disable-line
    }
  };

  /**
   * Sets the error callback to be used by the player
   *
   * @param {function} callback
   */
  setErrorCallback(callback) {
    this.errorCallback = callback;
  }

  /**
   * Given a callback, binds it to a Timer with a given duration and interval
   *
   * @param {function} healthyPlayCallback
   */
  trackHealthyPlay() {
    this.healthyPlayDetector = new Timer(
      HEALTHY_PLAY_DURATION,
      HEALTHY_PLAY_CHECK_INTERVAL,
      // TODO JWPlayer: analytics should be added, add a real callback
      noop
    );
  }

  /**
   * Unimplemented function, given a videoStreamSource, initialises videoPlayer
   * @param {string} videoStreamSource
   */
  async initialisePlayer(videoStreamSource) {
    throw new Error(`must be implemented by subclass ${this.playSessionId} ${videoStreamSource}`);
  }

  /**
   * Stops the audio playback
   */
  stop() {
    this.healthyPlayDetector.pause();
  }

  /**
   * Unimplemented method for setting the playback volume based on the given volumeLevel
   *
   * @param {number} volumeLevel
   */
  setVolumeLevel(volumeLevel) {
    throw new Error(`must be implemented by subclass ${this.playSessionId} ${volumeLevel}`);
  }

  /**
   * Mutes/Unmutes playback based on given muted
   *
   * @param {boolean} muted
   */
  setMuted(muted) {
    if (muted) {
      this.healthyPlayDetector.pause();
    } else {
      this.healthyPlayDetector.resume();
    }
  }

  /**
   * Unimplemented bind function for the given callback to the VideoPlayers's ended event
   * @param {function} callback
   */
  endedPlay(callback) {
    throw new Error(`must be implemented by subclass ${this.playSessionId} ${callback}`);
  }

  /**
   * Unimplemented play function (scaffold for inheriting classes)
   * @abstract
   */
  async play() {
    throw new Error(`must be implemented by subclass ${this.playSessionId}`);
  }

  /**
   * Unimplemented resume function (scaffold for inheriting classes)
   * @abstract
   */
  async resume() {
    throw new Error(`must be implemented by subclass ${this.playSessionId}`);
  }

  /**
   * Unimplemented load function (scaffold for inheriting classes)
   * @abstract
   */
  async load() {
    throw new Error(`must be implemented by subclass ${this.playSessionId}`);
  }
}
