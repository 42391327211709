import * as cssVariable from 'cssVariables';
import tileStyles from 'tile-components-styles';

export const styles = (theme) => ({
  isPlayingWrapperDefault: {},
  tile: {
    ...tileStyles.tileDefault,
    backgroundColor: cssVariable.grey300,
    paddingTop: '130%',
    borderRadius: 5,
  },
  subtitle: {},
  isPlayingWrapperClass: {
    transform: 'scale(1) perspective(1px)',
  },
  // needed for reference in tileStyles.tileInPlayerDefault
  scheduleDefault: {},
  tileHeader: {},
  liveIndicatorDefault: {
    ...tileStyles.liveIndicatorDefault,
  },
  backdrop: {},
  tileInPlayer: {
    ...tileStyles.tileInPlayerDefault,
    paddingTop: '100%',
    '& $content': {
      padding: '15px 15px 20px',
    },
    '& $backdrop': {
      borderRadius: 10,
    },
  },
  content: {
    ...tileStyles.contentDefault,
    padding: '5%',
    justifyContent: 'space-between',
  },
  logoContainer: {
    textAlign: 'right',
  },
  image: {
    width: 'auto',
    height: 25,
    backgroundSize: 'contain',
    backgroundPosition: '99% center',
    backgroundRepeat: 'no-repeat',
  },
  backdropRounded: {
    ...tileStyles.backdropDefault,
    borderRadius: 5,
    width: '100%',
  },
  triangleSmall: {
    ...tileStyles.triangleSmallDefault,
    width: '75%',
    zIndex: 0,
  },
  triangleBig: {
    ...tileStyles.triangleBigDefault,
    width: '140%',
    zIndex: 1,
  },
  showBackdrop: {
    ...tileStyles.backdropDefault,
    borderRadius: 5,
    width: '100%',
    overflow: 'hidden',
  },
  backgroundImage: {
    ...cssVariable.fillAspectRatio,
    ...cssVariable.backgroundCenterCoverNoRepeat,
    // transform fixes rendering issue when hovering and the parent tile is transformed
    transform: 'scale(1) perspective(1px)',
  },

  title: {
    ...tileStyles.titleDefault,
    fontWeight: cssVariable.fontWeights.bold,
    fontSize: cssVariable.fontSizes.medium,
  },
  brandWrapper: {
    lineHeight: 1,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    height: 20,
    minWidth: 70,
    marginLeft: 'auto',
  },
  subTitle: {
    ...tileStyles.subTitleDefault,
  },
  pageLinkIcon: {
    color: cssVariable.white,
    ...tileStyles.pageLinkIconDefault,
  },
  schedule: {
    ...tileStyles.scheduleDefault,
  },
  hoverBackdropRounded: {
    ...tileStyles.hoverBackdropDefault,
    borderRadius: 5,
  },
  [theme.breakpoints.down('sl')]: {
    title: {
      fontSize: cssVariable.fontSizes.small,
    },
  },
  [theme.breakpoints.down('xs')]: {
    pageLinkIcon: {
      display: 'none',
    },
  },
  [theme.breakpoints.down('md')]: {
    schedule: {
      ...tileStyles.scheduleMobileDefault,
      fontSize: cssVariable.fontSizes.xxsmall,
    },
    liveIndicatorMobileDefault: {
      ...tileStyles.liveIndicatorMobileDefault,
    },
  },
  [theme.breakpoints.between('sl', 'sl')]: {
    liveIndicatorDefault: {
      marginRight: 2,
    },
  },
});

export { styles as default };
