import { reportError } from './reportError';
import { url, os, browser } from './commonData';

const reportPageNotFound = async (errorType: string): Promise<void> => {
  const errorData = {
    errorType: errorType,
    url: url,
    OS: os,
    browser: browser,
    //talpaUID: talpaUID
  };
  reportError(errorData);
};

export { reportPageNotFound };
