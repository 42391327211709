import React from 'react';
import { NavLink } from 'react-router-dom';
import { LocationDescriptor } from 'history';

type CustomLinkProps = {
  isLink?: boolean;
  handleClick?: () => void;
  to?: LocationDescriptor;
} & Record<string, unknown>; // rest props

const CustomLink = ({ isLink = true, handleClick, to, ...rest }: CustomLinkProps): JSX.Element =>
  isLink && to ? <NavLink to={to} {...rest} /> : <div onClick={handleClick} role="presentation" {...rest} />;

export default CustomLink;
