import { getStore } from 'store';
import { mediaAudiotype, getBitrate, getMediaGenreForStation } from 'analytics';

export function trackStartLiveAudio(station, mediaPlaytime) {
  const { currentStation, isCasting } = getStore().getState().player;
  const { name, title, image, slug, tritonMount, collectionTitle } = station || currentStation || {};

  window.Taq.push([
    'track',
    'link',
    {
      ...window.Taq.jukeCustomData,
      event_name: 'juke-player_start',
      event_category: 'juke-player',
      event_action: 'start',
      player_state: 'start',
      event_label: title || name || null,
      player_channel: title || name || null,
      player_channelid: tritonMount || null,
      media_type: 'audio',
      media_name: title || name || null,
      media_thumbnail: (image && image.url) || null,
      media_duration: 0, // 0 = live
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_playtime: mediaPlaytime || 0,
      media_id: slug || null,
      media_genre: (station && getMediaGenreForStation(station)) || null,
      media_bitrate: getBitrate(),
      player_casting: isCasting ? 'chromecast' : false,
      c_media_audiotype: mediaAudiotype && mediaAudiotype.radioLive,
      content_id: collectionTitle || null,
    },
  ]);
}

export function trackPlayLiveAudio(station, mediaPlaytime) {
  const { currentStation, isCasting } = getStore().getState().player;
  const { name, title, slug, image, tritonMount, collectionTitle } = station || currentStation || {};
  window.Taq.push([
    'track',
    'link',
    {
      ...window.Taq.jukeCustomData,
      event_name: 'juke-player_play',
      event_category: 'juke-player',
      event_action: 'play',
      player_state: 'play',
      event_label: name || null,
      player_channel: title || name || null,
      player_channelid: tritonMount || null,
      media_name: name || null,
      media_duration: 0, // 0 = live
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_type: 'audio',
      media_thumbnail: (image && image.url) || null,
      media_playtime: mediaPlaytime || null,
      media_id: slug || null,
      media_genre: (station && getMediaGenreForStation(station)) || null,
      media_bitrate: getBitrate(),
      player_casting: isCasting ? 'chromecast' : false,
      c_media_audiotype: (mediaAudiotype && mediaAudiotype.radioLive) || null,
      content_id: collectionTitle || null,
    },
  ]);
}

export function trackStopLiveAudio(station, mediaPlaytime, isCasting = false) {
  const { name, image, collectionTitle, slug } = station;
  window.Taq.push([
    'track',
    'link',
    {
      ...window.Taq.jukeCustomData,
      event_name: 'juke-player_stop',
      event_category: 'juke-player',
      event_action: 'stop',
      player_state: 'stop',
      event_label: name || null,
      player_channel: name || null,
      media_type: 'audio',
      media_name: name || null,
      media_duration: 0, // 0 = live
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_thumbnail: (image && image.url) || null,
      media_playtime: mediaPlaytime || null,
      media_id: slug || null,
      media_genre: station ? getMediaGenreForStation(station) : null,
      media_bitrate: getBitrate(),
      player_casting: isCasting ? 'chromecast' : false,
      c_media_audiotype: (mediaAudiotype && mediaAudiotype.radioLive) || null,
      content_id: collectionTitle || null,
    },
  ]);
}

export function trackResumeLiveAudio(station, mediaPlaytime, isCasting = false) {
  const { name, image, slug, collectionTitle } = station;
  window.Taq.push([
    'track',
    'link',
    {
      ...window.Taq.jukeCustomData,
      event_name: 'juke-player_resume',
      event_category: 'juke-player',
      event_action: 'resume',
      player_state: 'resume',
      event_label: name || null,
      player_channel: name || null,
      media_type: 'audio',
      media_name: name || null,
      media_duration: 0, // 0 = live
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_thumbnail: (image && image.url) || null,
      media_playtime: mediaPlaytime || null,
      media_id: slug || null,
      media_genre: station ? getMediaGenreForStation(station) : null,
      media_bitrate: getBitrate(),
      player_casting: isCasting ? 'chromecast' : false,
      c_media_audiotype: (mediaAudiotype && mediaAudiotype.radioLive) || null,
      content_id: collectionTitle || null,
    },
  ]);
}
