import { messageModalActions } from '../../actions/actions-const';

const initialState = {
  isOpen: false,
  headerText: '',
  bodyText: '',
  ctaText: '',
  ctaURL: '',
  handleClick: undefined,
  ctaEventTrackingData: {
    event_name: '',
    event_category: '',
    event_action: '',
    event_label: '',
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case messageModalActions.SET_MESSAGE_MODAL_OPEN:
      return {
        ...state,
        bodyText: action.bodyText,
        headerText: action.headerText,
        ctaText: action.ctaText,
        ctaURL: action.ctaURL,
        CustomHeader: action.CustomHeader,
        handleClick: action.handleClick,
        ctaEventTrackingData: action.ctaEventTrackingData,
        isOpen: true,
      };
    case messageModalActions.SET_MESSAGE_MODAL_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}

/**
 * Given the current state, it returns the messageModal object
 *
 * @param {object} state
 * @returns {object}
 */
export const getMessageModal = (state) => state.messageModal;
