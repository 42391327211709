export const DEFAULT_REMOTE_CONFIG_FLAGS = {
  jukeRadioWebFeatureFlagBoolean: true,
  jukeRadioWebFeatureFlagString: false,
  showWebsiteOfTheYearLogo: true,
  ImageLogoFlag: false,
  LogoImageFeatureFlag: false,
  excludePreroll: false,
  newTealiumSetup: true,
  showScrubber: true,
  recommendations: true,
};

export const FEATURE_FLAG = {
  SHOW_SCRUBBER: 'showScrubber',
  EXCLUDE_PREROLL: 'excludePreroll',
};
