import {
  PodcastCollection,
  PromotionCollection,
  ShowCollection,
  StationCollection,
  TagCollection,
  ClipCollection,
  NavigationCollection,
  MixedCollection,
} from 'api/models';

import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { UniversalCollection, UniversalConfig } from 'api/interfaces';
import Collection from 'api/models/Collection';

/**
 * Create models of the raw content
 * @param {UniversalCollection} collection
 * @param {string} type
 * @param {object} config
 * @param {string[]} options
 * @returns {object} StationCollection | ShowCollection | PodcastCollection | TagCollection | PromotionCollection | ClipCollection
 */
export const parseContentModal = (
  collection: UniversalCollection,
  type: string,
  config: UniversalConfig[],
  options: string[]
): Collection | MixedCollection | null => {
  if (!collection || !type) {
    return;
  }

  if (type === CONTENT_TYPES.STATION_COLLECTION) {
    return new StationCollection(collection, config, options);
  }

  if (type === CONTENT_TYPES.SHOW_COLLECTION) {
    return new ShowCollection(collection, config, options);
  }

  if (type === CONTENT_TYPES.PODCAST_COLLECTION) {
    return new PodcastCollection(collection, config, options);
  }

  if (type === CONTENT_TYPES.TAG_COLLECTION) {
    return new TagCollection(collection, config, options);
  }

  if (type === CONTENT_TYPES.PROMOTION_COLLECTION) {
    return new PromotionCollection(collection, config, options);
  }

  if (type === CONTENT_TYPES.CLIP_COLLECTION) {
    return new ClipCollection(collection, config, options);
  }

  if (type === CONTENT_TYPES.MIXED_COLLECTION) {
    return new MixedCollection(collection);
  }

  if (type === CONTENT_TYPES.NAVIGATION_COLLECTION) {
    return new NavigationCollection(collection);
  }

  return null;
};
