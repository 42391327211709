import { createSelector } from 'reselect';
import { playerActions, playerVolumeActions } from '../../actions/actions-const';

export const playbackModes = {
  LIVE_AUDIO: 'LIVE_AUDIO',
  LIVE_VIDEO: 'LIVE_VIDEO',
  ON_DEMAND_CLIP: 'ON_DEMAND_CLIP',
};

const initialState = {
  initialized: false,
  playSessionId: null,
  isLoading: false,
  isPlaying: false,
  isCasting: false,
  castingAvailable: false,
  castingDevice: null,
  error: null,
  currentStation: null,
  currentOnDemandClip: null,
  volumeLevel: 1,
  isMuted: false,
  showFullPlayer: false,
  forceMiniPlayer: false,
  hideMiniPlayer: false,
  playbackMode: playbackModes.LIVE_AUDIO,
  onDemandPlaybackTime: 0,
  onDemandPlaybackDuration: 0,
  playerType: null,
  useHD: false,
  isPopoutPlayerActive: false,
  progressUpdated: null,
  upcomingTracks: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case playerActions.PLAYER_INITIALIZED:
      return {
        ...state,
        initialized: true,
      };
    case playerActions.PLAYER_LOAD_STATION:
    case playerActions.PLAYER_LOAD_LIVE_VIDEO_STATION:
      return {
        ...state,
        currentStation: action.station,
        playSessionId: action.playSessionId,
        currentOnDemandClip: null,
        upcomingTracks: null,
        error: null,
      };
    case playerActions.PLAYER_LOAD_ON_DEMAND_CLIP:
      return {
        ...state,
        currentOnDemandClip: action.onDemandClip,
        onDemandPlaybackTime: 0,
        playSessionId: action.playSessionId,
        currentStation: null,
        error: null,
      };
    case playerActions.PLAYER_LOADING:
      return {
        ...state,
        isLoading: true,
        isPlaying: true,
      };
    case playerActions.PLAYER_PLAYING:
      return {
        ...state,
        isLoading: false,
        isPlaying: true,
        error: null,
      };
    case playerActions.PLAYER_ERRORED:
      return {
        ...state,
        isLoading: false,
        isPlaying: false,
        error: action.error,
      };
    case playerActions.PLAYER_STOPPED:
      return {
        ...state,
        isLoading: false,
        isPlaying: false,
      };
    case playerVolumeActions.SET_VOLUME_LEVEL:
      return {
        ...state,
        volumeLevel: action.volumeLevel,
        isMuted: action.volumeLevel === 0,
      };
    case playerVolumeActions.SET_MUTED:
      return {
        ...state,
        isMuted: action.muted,
      };
    case playerActions.PLAYER_SET_FULL_PLAYER:
      return {
        ...state,
        showFullPlayer: action.visible,
      };
    case playerActions.PLAYER_FORCE_MINI_PLAYER:
      return {
        ...state,
        forceMiniPlayer: action.visible || false,
      };
    case playerActions.PLAYER_HIDE_MINI_PLAYER:
      return {
        ...state,
        hideMiniPlayer: action.payload || false,
      };
    case playerActions.SET_PLAYER_TYPE:
      return {
        ...state,
        playerType: action.payload,
      };
    case playerActions.PLAYER_SET_PLAYBACK_TO_ON_DEMAND_CLIP:
      return {
        ...state,
        playbackMode: playbackModes.ON_DEMAND_CLIP,
      };
    case playerActions.PLAYER_SET_PLAYBACK_TO_LIVE_AUDIO:
      return {
        ...state,
        playbackMode: playbackModes.LIVE_AUDIO,
      };
    case playerActions.PLAYER_SET_PLAYBACK_TO_LIVE_VIDEO:
      return {
        ...state,
        playbackMode: playbackModes.LIVE_VIDEO,
      };
    case playerActions.PLAYER_TRACK_ON_DEMAND_PLAYBACK_TIME:
    case playerActions.PLAYER_SET_ON_DEMAND_PLAYBACK_TIME:
      return {
        ...state,
        onDemandPlaybackTime: action.onDemandPlaybackTime,
      };
    case playerActions.PLAYER_EMPTY_ON_DEMAND_PLAYBACK:
      return {
        ...state,
      };
    case playerActions.PLAYER_SET_ON_DEMAND_PLAYBACK_DURATION:
      return {
        ...state,
        onDemandPlaybackDuration: action.onDemandPlaybackDuration,
      };
    case playerActions.CASTING_AVAILABLE:
      return {
        ...state,
        castingAvailable: true,
      };
    case playerActions.CASTING_UNAVAILABLE:
      return {
        ...state,
        castingAvailable: false,
      };
    case playerActions.PLAYER_CASTING:
      return {
        ...state,
        isLoading: false,
        isCasting: true,
        castingDevice: action.castingDevice,
      };
    case playerActions.PLAYER_CASTING_STOPPED:
      return {
        ...state,
        isLoading: false,
        isCasting: false,
        isPlaying: false,
        castingDevice: null,
      };
    case playerActions.PLAYER_USE_HD:
      return {
        ...state,
        useHD: action.useHD,
      };
    case playerActions.TOGGLE_POPOUT_PLAYER:
      return {
        ...state,
        isPopoutPlayerActive: action.isPopoutPlayerActive,
      };
    case playerActions.PROGRESS_UPDATE:
      return {
        ...state,
        progressUpdated: new Date(),
      };
    case playerActions.PLAYER_SET_UPCOMING_TRACKS:
      return {
        ...state,
        upcomingTracks: action.tracks,
      };
    default:
      return state;
  }
}

export const isPlayerInitialized = (state) => {
  return state.player.initialized;
};

export const isCurrentStationActiveSelector = (state, props) =>
  state.player.currentStation &&
  (props.slug === state.player.currentStation.slug ||
    (props.station && props.station.slug === state.player.currentStation.slug));

export const isCurrentOnDemandActiveSelector = (state, props) =>
  state.player.currentOnDemandClip && state.player.currentOnDemandClip.id === props.id;

/**
 * Given the current state, it returns whether or not player is playing
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIsPlaying = (state) => state.player.isPlaying;

/**
 * Given the current state, it returns whether or not player is loading
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIsLoading = (state) => state.player.isLoading;

/**
 * Given the current state, it returns forceMiniPlayer
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getForceMiniPlayer = (state) => state.player.forceMiniPlayer;

/**
 * Given the current state, it returns hideMiniPlayer
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getHideMiniPlayer = (state) => state.player.hideMiniPlayer;

/**
 * Given the current state, it returns player type
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getPlayerType = (state) => state.player.playerType;

/**
 * Given the current state, it returns the playSessionId
 *
 * @param {object} state
 * @returns {string}
 */
export const getPlaySessionId = (state) => state.player.playSessionId;

/**
 * Given the current state, it returns the currentStation
 *
 * @param {object} state
 * @returns {object}
 */
export const getCurrentStation = (state) => state.player.currentStation;

/**
 * Given the current state, it returns the error
 *
 * @param {object} state
 * @returns {string}
 */
export const getError = (state) => state.player.error;

/**
 * Given the current state, it returns the current onDemand clip
 *
 * @param {object} state
 * @returns {object}
 */
export const getCurrentOnDemandClip = (state) => state.player.currentOnDemandClip;

/**
 * Given the current state, it returns the whether or not the miniPlayer is shown fully
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getShowFullPlayer = (state) => state.player.showFullPlayer;

/**
 * Given the current state, it returns the playbackMode
 *
 * @param {object} state
 * @returns {string}
 */
export const getPlaybackMode = (state) => state.player.playbackMode;

/**
 * Given the current state, it returns the volumeLevel
 *
 * @param {object} state
 * @returns {int}
 */
export const getVolumeLevel = (state) => state.player.volumeLevel;

/**
 * Given the current state, it returns whether or not the player is muted
 *
 * @param {object} state
 * @returns {int}
 */
export const getIsMuted = (state) => state.player.isMuted;
/**
 * Given the current state, it returns the onDemandPlaybackDuration
 *
 * @param {object} state
 * @returns {int}
 */
export const getOnDemandPlaybackDuration = (state) => state.player.onDemandPlaybackDuration;

/**
 * Given the current state and props, it returns the onDemandPlaybackTime
 *
 * @param {object} state
 * @param {object} props
 * @returns {int}
 */
export const getOnDemandPlaybackTime = (state, props = null) =>
  state.player.currentOnDemandClip &&
  (!props ||
    (props.id && props.id === state.player.currentOnDemandClip.id) ||
    (props.isrc && props.isrc === state.player.currentOnDemandClip.isrc))
    ? state.player.onDemandPlaybackTime
    : 0;

/**
 * Given the current state and props, it returns whether or not current station is playing
 *
 * @param {object} state
 * @param {object} props
 * @returns {boolean}
 */
export const getIsCurrentOnDemandPlaying = createSelector(
  [isCurrentOnDemandActiveSelector, getIsPlaying],
  (isCurrentOnDemandActive, isPlaying) => !!isCurrentOnDemandActive && isPlaying
);

/**
 * Given the current state and props, it returns whether or not current station is playing
 *
 * @param {object} state
 * @param {object} props
 * @returns {boolean}
 */

export const getIsCurrentStationPlaying = (state, props) =>
  createSelector(
    [isCurrentStationActiveSelector, getIsPlaying],
    (isCurrentStationActive, isPlaying) => !!isCurrentStationActive && isPlaying
  )(state, props);

/**
 * Given the current state, it returns if player is casting
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIsCasting = (state) => state.player.isCasting;

/**
 * Given the current state, it returns if casting is available
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getCastingAvailable = (state) => state.player.castingAvailable;

/**
 * Given the current state, it returns if the casting device
 *
 * @param {object} state
 * @returns {string}
 */
export const getCastingDevice = (state) => state.player.castingDevice;

/**
 * Given the current state, it returns if current station uses hd
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getUseHD = (state) => state.player.useHD;

export const getProgressUpdated = (state) => state.player.progressUpdated;

export const hasMediaInPlayer = (state) => !!getCurrentStation(state) || !!getCurrentOnDemandClip(state);
