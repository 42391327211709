import { connect } from 'react-redux';
import { getVolumeLevel } from 'store/reducers/player';

import PlayerVolume from './PlayerVolume';

const mapStateToProps = (state) => ({
  volumeLevel: getVolumeLevel(state),
});

export default connect(mapStateToProps)(PlayerVolume);
