export const CLIP_COLLECTION_FRAGMENT = `
  fragment ClipCollectionFragment on ClipCollection {
		labels
		options
		slug
		title
		type
		description
		getClips {
			clips {
			  ...ClipFragment
			  progress {
				durationLength
				contentLength
				completed
			  }
			}
		  }
		config {
			...ConfigFragment
		  }
		media {
			...MediaFragment
		}
  }
  `;
