import React from 'react';
import Schema from 'components/Schema';
import PropTypes from 'prop-types';

const CampaignSchema = ({ title, description, keywords }) => {
  const currentUrl = window.location.origin.concat(window.location.pathname);
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: currentUrl,
    name: title,
    description,
    keywords,
  };
  return <Schema data={schema} />;
};

CampaignSchema.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
};

CampaignSchema.defaultProps = {
  title: null,
  description: null,
  keywords: null,
};
export default CampaignSchema;
