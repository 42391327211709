import { connect } from 'react-redux';

import { withAnalytics } from 'analytics';
import { getBrowser } from 'store/reducers/browser';
import StationTile from './StationTile';
import { loadStation, stop } from 'actions/player-actions';
import { getIsCurrentStationPlaying, getIsLoading } from 'store/reducers/player';
import { isCurrentStationActiveSelector } from 'store/reducers/player';
import { saveRecentSearch } from 'actions/search-actions';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { IStationTile, DispatchToProps } from './StationTile.interface';
import { State } from 'redux';
import Station from 'api/models/Station';

export type DefaultProps = Station & IStationTile;

// TODO: extract only station props from ownProps or leave everything in props and omit station
const mapStateToProps = (state: State, ownProps: DefaultProps) => {
  const { customTracking, ...restProps } = ownProps;
  return {
    station: { ...restProps },
    isLoading: getIsLoading(state),
    isActive: isCurrentStationActiveSelector(state, ownProps),
    isPlaying: getIsCurrentStationPlaying(state, ownProps),
    browser: getBrowser(state),
    customTracking: customTracking,
  };
};

const loadStationWithAnalytics = withAnalytics(loadStation, {
  player_name: 'tile',
  link_id: CONTENT_TYPES.STATION_TILE,
});

const stopStationWithAnalytics = withAnalytics(stop, {
  player_name: 'tile',
  link_id: CONTENT_TYPES.STATION_TILE,
});

const mapDispatchToProps = {
  onLoadStation: loadStationWithAnalytics,
  onStop: stopStationWithAnalytics,
  saveRecentSearch,
};

const mergeProps = (stateProps: IStationTile, { onStop, onLoadStation, saveRecentSearch }: DispatchToProps) => {
  const { isPlaying, station, isActive, browser, isLoading, hasPage, customTracking } = stateProps;
  return {
    station,
    isPlaying,
    isActive,
    browser,
    isLoading,
    onPlayToggle: isPlaying ? onStop : () => onLoadStation(station),
    saveRecentSearch,
    hasPage,
    customTracking,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StationTile);
