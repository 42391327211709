import { paletteActions } from '../../actions/actions-const';

const initialState = 'default';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case paletteActions.SET_PALETTE:
      return action.palette;
    default:
      return state;
  }
}

/**
 * Given the current state, it returns the current palette
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getPalette = (state) => state.palette;
