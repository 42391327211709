import { mediaAudiotype } from 'analytics';
export function trackShareContent(shareMedium, item, shareProps = {}) {
  const VALID_SHARE_MEDIA = ['facebook', 'whatsapp', 'email', 'twitter', 'copy-link-button'];

  if (VALID_SHARE_MEDIA.indexOf(shareMedium) === -1) {
    return;
  }

  if (shareProps?.isInPodcastEpisodePage) {
    const { media_id, media_program_name, media_name } = shareProps?.media_data;
    window.Taq.push([
      'track',
      'link',
      {
        event_action: 'share_interaction',
        event_category: 'content_listening',
        event_label: shareMedium,

        event_name: `share_${shareMedium}`,
        social_target: item || null,
        social_network: shareMedium || null,
        social_action: 'share',
        media_id: media_id,
        media_name: media_name,
        media_episode_number: null,
        media_program_name: media_program_name,
        media_type: 'audio',
        media_audiotype: mediaAudiotype.podcast,
      },
    ]);
  } else if (shareProps?.isOnDemandTile) {
    window.Taq.push([
      'track',
      'link',
      {
        event_name: `share_${shareMedium}`,
        event_category: shareProps?.collectionTitle ? 'content-listing' : 'share',
        event_action: shareProps?.collectionTitle ? 'share' : shareMedium || null,
        social_target: item || null,
        social_network: shareMedium || null,
        social_action: 'share',
        event_label: shareProps?.collectionTitle || '',
        media_id: shareProps?.podcastEpisodeSlug,
        media_name: shareProps?.podcastTitle,
        media_episode_number: null,
        media_program_name: shareProps?.podcastEpisodeTitle,
        media_type: 'audio',
        media_audiotype: mediaAudiotype.podcast,
      },
    ]);
  } else {
    window.Taq.push([
      'track',
      'link',
      {
        event_name: `share_${shareMedium}`,
        event_category: shareProps?.collectionTitle ? 'content-listing' : 'share',
        event_action: shareProps?.collectionTitle ? 'share' : shareMedium || null,
        social_target: item || null,
        social_network: shareMedium || null,
        social_action: 'share',
        event_label: shareProps?.collectionTitle || '',
        media_name: shareProps?.podcastTitle || '',
        media_program_name: shareProps?.podcastEpisodeTitle || '',
      },
    ]);
  }
}

export function trackOpenShareMenu() {
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'share_menu',
      event_category: 'share',
      event_action: 'open_menu',
    },
  ]);
}
