import { connect } from 'react-redux';
import { withAnalytics } from 'analytics';
import { loadStation, stop } from 'actions/player-actions';
import { getIsCurrentStationPlaying } from 'store/reducers/player';
import { saveRecentSearch } from 'actions/search-actions';

import MixContentItem from './MixedContentItem';

const mapStateToProps = (state, ownProps) => ({
  isPlaying: getIsCurrentStationPlaying(state, ownProps),
});

// CHECK ANALITICS
const loadStationWithAnalytics = withAnalytics(loadStation, {
  player_name: 'search',
});

const stopStationWithAnalytics = withAnalytics(stop, {
  player_name: 'search',
});

const mapDispatchToProps = {
  onLoadStation: loadStationWithAnalytics,
  onStop: stopStationWithAnalytics,
  saveRecentSearch,
};

function mergeProps(stateProps, { onLoadStation, onStop, ...dispatchProps }, ownProps) {
  const { isPlaying } = stateProps;

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onPlayToggle: isPlaying ? onStop : () => onLoadStation(ownProps),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MixContentItem);
