import { playerActions } from '../../actions/actions-const';

export default (api) => (next) => (action) => {
  if (!action.playSessionId) {
    next(action);
    return;
  }

  const otherPlaySessionAction = action.playSessionId !== api.getState().player.playSessionId;

  const loadMediaActions = [
    playerActions.PLAYER_LOAD_ON_DEMAND_CLIP,
    playerActions.PLAYER_LOAD_STATION,
    playerActions.PLAYER_LOAD_LIVE_VIDEO_STATION,
  ];

  const isLoadMediaAction = loadMediaActions.includes(action.type);

  if (otherPlaySessionAction && !isLoadMediaAction) {
    return;
  }

  next(action);
};
