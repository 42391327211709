import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import styles from './HoverOverlay.styles';
import PlayControl from 'components/PlayControl';
import { NavLink } from 'react-router-dom';
import * as analytics from 'analytics';
import ChevronIcon from 'components/svg/Chevron';
import { IHoverOverlay } from './HoverOverlay.interface';
import CollectionContext from 'components/Collection/CollectionContext';

const useStyles = createUseStyles(styles, { name: 'HoverOverlay' });

const HoverOverlay = (props: IHoverOverlay): JSX.Element => {
  const { station, onPlayToggle, isPlaying, saveRecentSearch, isLoading, playButtonSize, customTracking } = props;
  const classes = useStyles();
  const { isInSearch = false } = useContext(CollectionContext);

  const playControlProps = {
    playToggle: onPlayToggle,
    isPlaying: isPlaying && !isLoading,
    isLoading: isPlaying && isLoading,
    contentTypeId: station.contentTypeId, //used as a id to store the station in recently searched list if isInSearch is true
    slug: station.slug,
    size: playButtonSize,
    saveRecentSearch,
    customTracking,
  };

  const navLink = {
    pathname: `${station.linkedPage}`,
    state: {
      analytics: analytics.generateTileClick(station.name, station.linkedPage, 'station page', isInSearch),
    },
  };

  const clickHandler = () => {
    onPlayToggle();
    if (customTracking) {
      customTracking();
    }
    if (isInSearch) saveRecentSearch(station.contentTypeId, station.slug);
  };

  return (
    <div id={station.id} data-testid="hover-overlay" className={classes.hoverOverlay}>
      <span className={classes.hoverBackdrop} onClick={clickHandler} />
      <PlayControl {...playControlProps} />
      {station.hasPage && (
        <NavLink
          onClick={isInSearch ? () => saveRecentSearch(station.contentTypeId, station.slug) : undefined}
          className={classes.pageLink}
          data-testid="station_more_alt"
          to={navLink}
          aria-label={`Alles van ${station.title}`}
        >
          <div className={classes.title}>{`Alles van  ${station.title} `}</div>
          <div className={classes.iconWrapper}>
            <ChevronIcon className={classes.chevron} />
          </div>
        </NavLink>
      )}
    </div>
  );
};

export default HoverOverlay;
