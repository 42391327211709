import React from 'react';
import PropTypes from 'prop-types';

import AspectContainer from 'components/AspectContainer';

const HeaderIcon = ({ height, fillColor1, fillColor2 }) => (
  <AspectContainer width={1000} height={height}>
    <svg viewBox="0 0 1000 621">
      <rect
        fill={fillColor1}
        x="379.85"
        y="239.76"
        width="46.55"
        height="206.91"
        rx="23.07"
        ry="23.07"
        transform="rotate(-30 403.135 343.22)"
      />
      <rect
        fill={fillColor1}
        x="560.85"
        y="239.76"
        width="46.55"
        height="206.91"
        rx="23.07"
        ry="23.07"
        transform="rotate(-150 584.13 343.22)"
      />
      <path
        fill={fillColor2}
        d="M322.18 290.8v.1a56.18 56.18 0 0 0 4.71 44.17l43 76.37a56.18 56.18 0 0 0 36.23 27.16 8.51 8.51 0 0 0 9.33-12.49l-77.71-137a8.51 8.51 0 0 0-15.56 1.69zM664.73 291.8v.1a56.18 56.18 0 0 1-4.73 44.17l-43 76.37a56.18 56.18 0 0 1-36.23 27.16 8.51 8.51 0 0 1-9.33-12.49l77.71-137a8.51 8.51 0 0 1 15.58 1.69z"
      />
      <path
        fill={fillColor2}
        d="M679.65 363.76A37 37 0 0 1 646.91 418a36 36 0 0 1-4.91-.33l-5.05 8.76a6 6 0 0 1-8.2 2.2 6 6 0 0 1-2.2-8.19l3.86-6.67a9.06 9.06 0 0 1-1.94-11.36c9.67-16.9 31.94-55.76 32.17-55.76 14.14-26.65 21.64-57.22 21.64-89.65 0-117.66-107.34-210.24-229.45-185.15a186.41 186.41 0 0 0-144.55 143c-10.44 48.35-2.39 94.48 18.42 132.65 1.63 3 22.51 39.67 31.77 55.94a9 9 0 0 1-2.8 12l2.91 5a6 6 0 0 1-2.2 8.19 6 6 0 0 1-8.19-2.2l-4.4-7.62a34.48 34.48 0 0 1-3.68.19 37 37 0 0 1-33-53.93A214 214 0 0 1 278 259.33c-1.26-119 95.5-217.09 214.55-217.33S708 138.11 708 257a214 214 0 0 1-28.35 106.76z"
      />
      <path
        fill={fillColor1}
        d="M646 104.92l-14 9.35a19.55 19.55 0 0 1-18 2L556.21 93.5a161.34 161.34 0 0 0-125.54-.81L374.4 114a19.55 19.55 0 0 1-17.71-2l-13.59-9a2.23 2.23 0 0 1 .42-.55q4-3.93 8.18-7.63a213.9 213.9 0 0 1 289 4.9c1.78 1.69 3.54 3.42 5.27 5.16z"
      />
      <path
        fill={fillColor2}
        d="M650.9 586.78c-43.65 0-78.67-4.63-93.23-9-23-6.83-26.53-16.27-25.48-23 3.3-21.22 59.1-37.84 127-37.84 35.9 0 67.45-4.34 91.24-12.55 21.3-7.29 35.18-17.39 39.09-28.39 4.68-13.18-6.1-24.81-16-32.24C733.07 413.26 684.46 408 684 408l3.51-7c2 .17 48.74 4.5 91.13 36.43 17 12.77 23.6 27.39 18.7 41.15-10 28.19-64.23 46.4-138.12 46.4-32.79 0-63.44 3.85-86.31 10.85s-31.73 15-32.54 20.18c-.77 5 6.61 10.24 19.73 14.15 13.34 4 46.61 8.33 88.14 8.52 35.16.14 88-2.65 140.16-17 95.92-26.36 210.44-13.38 211.59-13.25v7.94c-1.13-.13-115.05-12.9-209.33 13-47.49 13.1-97.66 17.41-139.76 17.41z"
      />
      <rect
        fill={fillColor2}
        x="669.93"
        y="395.55"
        width="23.26"
        height="12"
        rx="6"
        ry="6"
        transform="rotate(-63.65 681.543 401.55)"
      />
    </svg>
  </AspectContainer>
);

HeaderIcon.propTypes = {
  height: PropTypes.number,
  fillColor1: PropTypes.string,
  fillColor2: PropTypes.string,
};

HeaderIcon.defaultProps = {
  height: 621,
  fillColor1: '#e8e8f2',
  fillColor2: '#ffffff',
};

export default HeaderIcon;
