import * as cssVariable from 'cssVariables';

const tileStyles = {
  isPlayingWrapperDefault: {
    zIndex: 3,
  },
  tileDefault: {
    position: 'relative',
    transition: 'transform 300ms ease',
    '&:hover': {
      transform: 'scale(1.05)',
      '& $isPlayingWrapperDefault': {
        display: 'none',
      },
    },
  },
  isPlayingWrapperClass: {},
  tileInPlayerDefault: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    borderRadius: 10,
    boxShadow: `0 1px 5px 0 ${cssVariable.transparentLightmode040}, 0 5px 20px 0 ${cssVariable.transparentLightmode010}`,
    overflow: 'hidden',
    '&:hover': {
      transform: 'none',
    },
    '& $scheduleDefault': {
      fontSize: cssVariable.fontSizes.small,
      margin: 0,
      paddingTop: 10,
      lineHeight: 1,
    },
    '& $tileHeader': {
      display: 'flex',
    },
    '& $liveIndicatorDefault': {
      padding: '3px 6px',
    },
  },
  tileHeader: {},

  contentDefault: {
    ...cssVariable.fillAspectRatio,
    display: 'flex',
    flexDirection: 'column',
    color: cssVariable.white,
    // Fixes rendering issue when hovering and the parent tile is transformed
    transform: 'scale(1) perspective(1px)',
    zIndex: 2,
  },
  backdropDefault: {
    ...cssVariable.fillAspectRatio,
    ...cssVariable.backgroundCenterCoverNoRepeat,
    // transform fixes rendering issue when hovering and the parent tile is transformed
    transform: 'scale(1) perspective(1px)',
  },
  hoverBackdropDefault: {
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    ...cssVariable.backgroundGradient,
    opacity: 0.9,
    zIndex: -1,
  },
  backdropGreyDefault: {
    background: cssVariable.grey300,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  screenReaderTitleDefault: {
    ...cssVariable.screenReadersOnly,
  },
  hoverOverlayDefault: {
    ...cssVariable.fillAspectRatio,
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    transition: 'opacity 200ms ease',
    '&:hover': {
      opacity: 1,
    },
    '&:focus': {
      opacity: 1,
    },
  },
  hoverOverlayFocusedDefault: {
    opacity: 1,
  },
  titleDefault: {
    lineHeight: 1,
    margin: 0,
    textTransform: 'uppercase',
    overflowWrap: 'break-word',
  },
  subTitleDefault: {
    fontSize: cssVariable.fontSizes.xsmall,
    textAlign: 'right',
    lineHeight: 1,
    margin: 0,
    fontWeight: cssVariable.fontWeights.black,
    textTransform: 'uppercase',
    overflowWrap: 'break-word',
    // Fixes rendering issue when hovering and the parent tile is transformed
    transform: 'scale(1) perspective(1px)',
  },
  pageLinkDefault: {
    display: 'block',
    ...cssVariable.fillAspectRatio,
    '&:focus': {
      outlineOffset: 0,
    },
  },
  pageLinkIconDefault: {
    position: 'absolute',
    zIndex: 1,
    transform: 'scale(1) perspective(1px)',
    top: 5,
    right: 5,
    '& > svg': {
      width: 35,
      height: 35,
      color: '#FFF',
    },
  },
  actionsDefault: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexGrow: 1,
    pointerEvents: 'none',
    transform: 'scale(1) perspective(1px)',
    '&> *': {
      pointerEvents: 'all',
    },
  },
  scheduleDefault: {
    paddingTop: 10,
    lineHeight: 1,
    margin: 0,
    fontWeight: cssVariable.fontWeights.regular,
    fontSize: cssVariable.fontSizes.xsmall,
  },
  scheduleMobileDefault: {
    fontSize: cssVariable.fontSizes.xxsmall,
  },
  liveIndicatorDefault: {
    marginRight: 5,
    padding: '0 3px',
    backgroundColor: cssVariable.red500,
    borderRadius: 2,
    color: cssVariable.white,
    textTransform: 'uppercase',
  },
  liveIndicatorMobileDefault: {
    position: 'relative',
    display: 'inline-block',
    overflow: 'hidden',
    top: 1,
    width: 12,
    height: 12,
    textIndent: -100,
    borderRadius: 6,
  },
  brandWrapperDefault: {
    lineHeight: 1,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    height: 20,
  },
  buttonPlaceholderDefault: {
    width: 40,
  },
  addToFavoritesButtonDefault: {
    padding: 5,
    background: 'transparent',
    border: 'none',
    color: cssVariable.white,
    // Todo: element should have a opacity of 1 but for some unknown reason the component
    // is only clickable if element has a opacity less then 1
    opacity: 0.99,
    transform: 'scale(1)',
    '&> svg': {
      width: 30,
      height: 30,
    },
  },
  noHoverAnimationDefault: {},
  tileWithTrianglesHover: {
    '&:hover': {
      // transform: 'perspective(1px) scale(1.048)',
      transform: 'scale(1.05)',
      '& $hoverOverlayDefault': {
        opacity: 1,
      },
    },
    '&:not($noHoverAnimationDefault):hover': {
      '& $triangleBigDefault': {
        top: '90%',
        right: '60%',
      },
      '& $triangleSmallDefault': {
        right: '80%',
        bottom: '90%',
      },
      '& $isPlayingWrapperDefault': {
        display: 'none',
      },
    },
  },
  trianglesDefault: {
    position: 'absolute',
    borderRadius: 5,
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    transform: 'scale(1) perspective(1px)',
  },
  trianglesDefaultAbove: {
    zIndex: 1,
    '& $triangleBigDefault': {
      width: '140%',
    },
    '& $triangleSmallDefault': {
      width: '75%',
    },
  },
  triangleBigDefault: {
    position: 'absolute',
    opacity: 0.6,
    transition: 'all 0.4s ease 0s',
    top: '50%',
    right: 0,
    width: '135%',
    height: '70%',
    transform: 'perspective(1px) rotate(35deg)',
  },
  triangleSmallDefault: {
    position: 'absolute',
    opacity: 0.6,
    transition: 'all 0.4s ease 0s',
    bottom: '70%',
    right: '63%',
    width: '70%',
    height: '30%',
    transform: 'perspective(1px) rotate(-55deg)',
  },

  backgroundColor: {
    ...cssVariable.fillAspectRatio,
    ...cssVariable.backgroundCenterCoverNoRepeat,
    opacity: '.6',
    borderRadius: '5px',
  },
};

export { tileStyles as default };
