import { generateTheme } from './generateTheme/generateTheme';

import palettes from './palettes';
import breakpoints from './breakpoints';
import zIndexes from './zIndexes';

const theme = (palette = 'default') =>
  generateTheme({
    palette: palettes[palette] || palettes.default,
    breakpoints,
    zIndex: zIndexes,
  });

export default theme;
