import { StyleTheme } from './../../../theme/StyleTheme';
import { Styles } from 'jss';
import * as cssVariable from 'cssVariables';

export default (theme: StyleTheme): Styles => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '50px',
  },
  luisterboeken: {},
  content: {
    marginLeft: '50px',
    width: '580px',
    padding: '0 50px',
    alignSelf: 'center',
  },
  header: {
    marginBottom: '25px',
  },
  title: {
    font: 'Amsi Pro Narw Bold',
    fontSize: '28px',
    lineHeight: '34px',
    margin: '0',
    textTransform: 'uppercase',
  },
  subtitle: {
    font: 'Amsi Pro Narw Regular',
    fontSize: '16px',
    lineHeight: '22px',
    color: cssVariable.grey500,
  },
  description: {
    font: 'Amsi Pro Narw Regular',
    fontSize: '16px',
    lineHeight: '22px',
  },
  link: {
    display: 'flex',
  },
  storeDesktop: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  storeMobile: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '580px',
  },
  text: {
    alignSelf: 'center',
    margin: '0 13px',
    padding: '0 10px',
  },
  [theme.breakpoints.down('md')]: {
    container: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      paddingTop: '0px',
    },
    content: {
      width: '475px',
      padding: '0',
      marginLeft: '0',
    },
    storeMobile: {
      width: '475px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    content: {
      width: '375px',
    },
    storeMobile: {
      width: '375px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    content: {
      width: '100%',
    },
    storeMobile: {
      width: '100%',
    },
    title: {
      fontSize: '25px',
      lineHeight: '30px',
    },
    luisterboeken: {
      marginBottom: '15px',
    },
    link: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    storeDesktop: {
      flexDirection: 'row',
      alignSelf: 'normal',
    },
  },
});
