import {
  transparentLightmode010,
  blue500,
  grey100,
  grey200,
  grey300,
  grey500,
  grey600,
  green500,
  white,
  transparentDarkmode050,
  linearGradient,
} from 'cssVariables';

export default {
  default: {
    primary: green500,
    secondary: {},
    grey: {},
    red: {},
    text: {
      primary: grey600,
      secondary: grey500,
      lightText: white,
      highlightedText: blue500,
    },
    common: {
      white: white,
    },
    navigation: {
      background: grey100,
      border: grey200,
      color: grey500,
      colorActive: blue500,
      highlight: blue500,
    },
    background: white,
    backgroundSecondary: grey300,
    gradientHeader: {
      background: linearGradient,
      color: white,
    },
    border: transparentLightmode010,
    playerControls: transparentDarkmode050,
    tabsScrollOverflowGradient:
      'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 30%,rgba(255,255,255,1) 100%)',
  },
  dark: {
    primary: green500,
    secondary: {},
    grey: {},
    red: {},
    text: {
      primary: '#929aa5',
      secondary: '#929aa5',
      lightText: '#929aa5',
    },
    navigation: {
      background: '#272b2e',
      border: '#303437',
      color: '#858994',
      colorActive: white,
      highlight: '#86bf00',
    },
    background: '#202427',
    backgroundSecondary: grey500,
    gradient: '#202427',
    gradientHeader: {
      background: '#202427',
      color: '#929aa5',
    },
    border: '#303437',
    playerControls: '#202427',
    tabsScrollOverflowGradient:
      'linear-gradient(to right, rgba(32, 36, 39, 0) 0%, rgba(32, 36, 39, 0.5) 30%,rgba(32, 36, 39, 1) 100%)',
  },
};
