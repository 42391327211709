import gql from 'graphql-tag';
import { MEDIA_FRAGMENT, IMAGE_FRAGMENT } from './fragments';

export const getUser = gql`
  query {
    user: getUser {
      tritonToken {
        token
        ttl
      }
    }
  }
`;

/*
 * TODO - Double check in clean up ticket, this should probably be updated to use non-deprecated fields
 * getPodcasts query fetch deprecated fields
 */
export const getPodcasts = gql`
  query GetPodcasts($slug: String!) {
    collection(slug: $slug) {
      ... on PodcastCollection {
        id
        title
        slug
        description
        options
        config {
          type
          entries {
            key
            value
            type
          }
        }
        items {
          title
          description
          slug
          id
          subTitle
          type
          feedUrl
          tags {
            slug
            title
            type
          }
          episodes(limit: 3) {
            id
            media {
              ...MediaFragment
            }
            type
            slug
            title
            description
            publishDate
            durationSeconds
          }
          images {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${MEDIA_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

export const createProgress = gql`
  mutation createProgress($inputProgress: InputProgress) {
    progress(input: $inputProgress) {
      id
      type
      userId
      contentId
      contentType
      contentLength
      durationLength
      completed
    }
  }
`;
