import React from 'react';
import classNames from 'classnames';
import styles from './ContentPageLayout.styles';
import { createUseStyles } from 'react-jss';
import { CONTENT_CONTAINER } from 'globalConst/const';
import getMobileLightVariant from 'util/getMobileLightVariant';
import Search from 'components/Search';

interface ContentPageLayoutProps {
  children: React.ReactNode;
  isSearchActive: boolean;
}
const useStyles = createUseStyles(styles, { name: 'ContentPageLayout' });

const ContentPageLayout = (props: ContentPageLayoutProps): JSX.Element => {
  const classes = useStyles();

  const { children, isSearchActive } = props;
  const mobileLightVariant = getMobileLightVariant();
  return (
    <div id={CONTENT_CONTAINER} className={classes.container}>
      {!mobileLightVariant && <Search />}
      <div
        className={classNames(classes.contentContainer, {
          [classes.contentContainerSearchActive]: isSearchActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default ContentPageLayout;
