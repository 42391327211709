import { createClient } from 'contentful';
import { apiSelector } from 'components/ApiSwitch/ApiSwitch.const';
import formatNavigationItemsForMainNavigation from '../components/Navigation/navigation-utils';
import getEnvironmentVariable from '../util/getEnviromentVariable';
import logger from 'util/logger';
import { reportFailedAPIRequest } from 'metrics';

const accessToken = getEnvironmentVariable('CONTENTFUL_ACCESS_TOKEN', apiSelector.JUKE_API);
const environment = getEnvironmentVariable('CONTENTFUL_ENVIRONMENT', apiSelector.JUKE_API);
const staticBase = getEnvironmentVariable('STATICS_BASE_URL', apiSelector.JUKE_API);

const contentfulClient = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken,
  environment,
  host: staticBase === 'https://preview-static.juke.nl' ? 'preview.contentful.com' : 'cdn.contentful.com',
});

/**
 * Fetches  object data from Contentful
 */
export async function fetchContentBySlug(contentType, slug, include = 10) {
  try {
    const content = await contentfulClient.getEntries({
      content_type: contentType,
      'fields.slug': slug,
      limit: 1,
      include,
    });
    return content && content.items[0] && content.items[0].fields ? content.items[0].fields : null;
  } catch (error) {
    reportFailedAPIRequest(
      `api request error - contentful - (name: ${error?.details?.errors[0]?.name}, value: ${error?.details?.errors[0]?.value})`,
      `contentType - ${contentType}`,
      `slug - ${slug}`
    );
    logger.error(`Error requesting ${contentType} with slug ${slug} from Contentful: `, error);
    return null;
  }
}

export async function fetchNavigation() {
  try {
    const mainNavigation = await fetchContentBySlug('navigation', 'juke-main-nav', 2);
    return formatNavigationItemsForMainNavigation(mainNavigation);
  } catch (error) {
    logger.error('Error requesting main navigation from Contentful: ', error);
  }
  return null;
}

export async function fetchMobileLightNavigation() {
  try {
    const mobileLightNavigation = await fetchContentBySlug('navigation', 'mobile-light-nav', 2);
    return formatNavigationItemsForMainNavigation(mobileLightNavigation);
  } catch (error) {
    logger.error('Error requesting mobile light navigation from Contentful: ', error);
  }
  return null;
}
