import * as cssVariable from 'cssVariables';

export default (theme) => ({
  recommendations: {
    position: 'relative',
    width: 'auto',
    minHeight: 100,
    backgroundColor: cssVariable.grey600,
    borderRadius: 4,
    textAlign: 'center', // needed to center Loader spinner
    cursor: 'initial',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      display: 'none',
    },

    '&:before': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      display: 'block',
      content: '""',
      width: '100%',
      height: 20,
      background: `linear-gradient(0deg, rgba(58,60,66,1) 0%, rgba(58,60,66,0) 100%)`,
      borderRadius: 4,
      zIndex: 10,
    },
  },
});
