import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { noop } from 'util/index';
import { injectStyles } from 'injectStyles';
import { NotificationTypes, NotificationTexts } from 'components/Notifications/Notification';
import styles from './GoogleCastLauncher.styles';

const NOTIFICATION_ID = uuid();

const GoogleCastLauncher = ({ classes, castingDevice, showNotification, isCasting }) => {
  useEffect(() => {
    //  If casting session is active, show a notification
    if (isCasting) {
      showNotification({
        id: NOTIFICATION_ID,
        type: NotificationTypes.NOTIFICATION,
        message: `${NotificationTexts.PLAYBACK_CASTING} ${castingDevice}`,
      });
    }
  }, [isCasting]);

  return (
    <div className={classes.googleCastWrapper}>
      <google-cast-launcher />
    </div>
  );
};

GoogleCastLauncher.propTypes = {
  classes: PropTypes.object,
  castingDevice: PropTypes.string,
  isCasting: PropTypes.bool,
  showNotification: PropTypes.func,
};

GoogleCastLauncher.defaultProps = {
  classes: null,
  castingDevice: null,
  isCasting: false,
  showNotification: noop,
};

export default injectStyles(styles)(GoogleCastLauncher);
