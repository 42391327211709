import Section from './Section';
import { isEmpty, logger } from 'util/index';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { CONFIG_KEY } from 'globalConst/const';

/**
 * @class
 * A representation of an Overview
 *
 * @param {object} overview - Overview model
 */
export default class Overview {
  constructor(overview) {
    if (isEmpty(overview)) {
      return null;
    }

    this.uuid = overview.id;
    this.title = overview.title;
    this.subtitle = overview.description;

    this.contentTypeId = CONTENT_TYPES.OVERVIEW;
    this.slug = overview.slug;
    this.name = overview.name;

    if (overview.images && Array.isArray(overview.images)) {
      overview.images.forEach(({ imageType, uri, title }) => {
        switch (imageType) {
          case 'logo':
            this.logoSecondary = {
              url: uri,
              altText: title,
            };
            break;
          case 'logoWhite':
            this.logo = {
              url: uri,
              altText: title,
            };
            break;
          case 'header':
            this.headerImage = {
              url: uri,
              altText: title,
            };
            break;
          default:
            logger.warn(`Overview missing image: ${imageType}`);
            break;
        }
      });
    }

    if (overview.items) {
      this.sections = overview.items.map((section) => {
        return new Section(section);
      });
    }

    if (overview.config && Array.isArray(overview.config)) {
      this.brandColor = overview.config.reduce((acc, { entries }) => {
        const brandColor = entries.find(({ key }) => key === CONFIG_KEY.BACKGROUND_COLOR);
        return brandColor ? brandColor.value : acc;
      }, '#000');
    }

    // Make this class read-only
    Object.freeze(this);
  }
}
