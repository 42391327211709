import { connect } from 'react-redux';
import { showNotification } from 'actions/notifications-actions';
import { NotificationTypes, NotificationTexts } from 'components/Notifications/Notification';
import { setModalClosed } from 'actions/modal-actions';
import { getModalOpen } from 'store/reducers/modal';
import asContentPage from 'components/hoc/asContentPage';

import withGraphQL from 'components/hoc/withGraphqlData';

import PodcastPage from './PodcastPage';

const mapStateToProps = (state) => ({
  modalIsOpen: getModalOpen(state),
});

const mapDispatchToProps = {
  showErrorNotification: showNotification({
    type: NotificationTypes.ERROR,
    message: NotificationTexts.PODCAST_PAGE_RSS_LOAD_ERROR,
  }),
  setModalClosed,
};

const PodcastPageContainer = connect(mapStateToProps, mapDispatchToProps)(withGraphQL(PodcastPage));

export default asContentPage('podcast', PodcastPageContainer);
