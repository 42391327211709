import { playoutActions, playerActions } from '../../actions/actions-const';

const initialState = {
  playout: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case playoutActions.PLAYOUT_START:
      return {
        ...state,
        playout: action.playout,
      };
    case playoutActions.PLAYOUT_END:
      return {
        ...state,
        playout: null,
      };
    case playerActions.PLAYER_LOAD_ON_DEMAND_CLIP:
      return initialState;
    case playerActions.PLAYER_LOAD_STATION:
      return initialState;
    case playerActions.PLAYER_LOAD_LIVE_VIDEO_STATION:
      return initialState;
    default:
      return state;
  }
}

/**
 * Given the current state, it returns the playout which is currently playing
 *
 * @param {object} state
 * @returns {object}
 */
export const getCurrentPlayout = (state) => state.nowPlayingInfo.playout;
