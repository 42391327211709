import React from 'react';
import { Station } from 'api/models';
import { useFetchNowPlaying } from 'components/hooks';
import { isEmpty } from 'util/index';

interface WithPlayoutsHOCProps {
  contentTypeId: string;
  slug: string;
  items?: Array<Station>;
  isInSearch?: boolean;
  playoutsDisabled?: boolean;
}

export function withPlayouts<P>(InnerComponent: React.FC<P>) {
  return (props: P): JSX.Element => {
    const { contentTypeId, slug, isInSearch, items, playoutsDisabled } = props as unknown as WithPlayoutsHOCProps;

    const shouldFetchTrack = Boolean(!isInSearch && !playoutsDisabled && items);

    const playouts = useFetchNowPlaying({
      contentTypeId,
      slug,
      shouldFetchTrack,
    });
    let componentItems = items;

    if (!isEmpty(playouts) && !isEmpty(items)) {
      componentItems = [];
      items.forEach((item) => {
        componentItems.push({
          ...item,
          playouts: playouts[item.slug],
        });
      });
    }

    return <InnerComponent {...props} items={componentItems} />;
  };
}

export default withPlayouts;
