import { isEmpty } from 'util/index';
import { UniversalMetadata } from '../interfaces';

/**
 * @class
 * A representation of an Metadata
 *
 * @param {object} metadata - metadata model
 */
export default class Metadata {
  title: string;
  description: string;
  share: {
    title: string;
    description: string;
    url: string;
    image: string;
  };

  constructor(metadata: UniversalMetadata) {
    if (isEmpty(metadata)) {
      return null;
    }

    this.title = metadata.title;
    this.description = metadata.description;

    this.share = {
      title: metadata.shareTitle || metadata.title,
      description: metadata.shareText || metadata.description,
      url: metadata.shareLink || window.location.origin.concat(window.location.pathname),
      image: `https://juke.nl${process.env.PUBLIC_URL}/JUKE-Share-image.jpg`,
    };

    // Make this class read-only
    Object.freeze(this);
  }
}
