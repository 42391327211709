import React from 'react';
import PropTypes from 'prop-types';
import { DisplayLayout } from 'components/Collection/Collection.const';

import Collection from 'components/Collection';
import { TILE_COMPONENT_TYPES } from 'components/TileComponent/TileComponent.const';

function OnDemandShowCollection({ show, itemsPerRow, rows, display, showMore, ...otherProps }) {
  let { episodes } = show || {};

  return (
    <Collection
      items={episodes}
      rows={rows}
      itemsPerRow={itemsPerRow}
      title={show.title}
      display={display}
      tileComponent={TILE_COMPONENT_TYPES.ON_DEMAND}
      smallHeader
      uuid={show.id}
      showMore={showMore}
      {...otherProps}
    />
  );
}

OnDemandShowCollection.propTypes = {
  display: PropTypes.string,
  rows: PropTypes.number,
  itemsPerRow: PropTypes.number,
  show: PropTypes.object.isRequired,
  showMore: PropTypes.shape({
    uuid: PropTypes.string,
    contentTypeId: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.string,
    label: PropTypes.string,
    showAmount: PropTypes.bool,
  }),
};

OnDemandShowCollection.defaultProps = {
  display: DisplayLayout.LIST,
  rows: 4,
  itemsPerRow: 4,
  showMore: {
    uuid: null,
    contentTypeId: null,
    name: null,
    variant: 'button',
    label: null,
    showAmount: null,
  },
};

export default OnDemandShowCollection;
