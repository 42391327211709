import Promotion from './Promotion';
import { TILE_SIZES } from 'globalConst/const';
import { UniversalCollection, UniversalConfig, UniversalPromotion } from 'api/interfaces';
import Collection from './Collection';
import { isEmpty, contains } from 'util/index';
import { DisplayLayout } from 'components/Collection/Collection.const';

interface UniversalPromotionCollection extends UniversalCollection {
  items: UniversalPromotion[];
}

/**
 * @class
 * A representation of a PromotionCollection
 *
 * @param {object} collection - collection model
 */
export default class PromotionCollection extends Collection {
  contentTypeId: string;
  items: unknown;
  tileSizes: string = TILE_SIZES.MEDIUM_LARGE;
  swipe: boolean;
  hideShowMore: boolean;
  title: string;

  constructor(collection: UniversalCollection, config: UniversalConfig[], options: string[]) {
    super(collection, config, options);

    if (isEmpty(collection)) {
      return null;
    }

    const promotionCollection = <UniversalPromotionCollection>collection;

    this.contentTypeId = collection.type;

    this.items = promotionCollection.items && promotionCollection.items.map((promotion) => new Promotion(promotion));

    if (this.items === 1) {
      this.display = DisplayLayout.GRID;
    }

    // Special case only on promotion(/acties) page, where we need to have grid always
    const isPromotionPage = contains(window.location.href, 'acties');
    if (isPromotionPage) {
      this.display = DisplayLayout.GRID;
    }

    this.swipe = this.display !== DisplayLayout.GRID;

    this.hideShowMore = true;
    this.title = collection.title;

    this.rows = this.swipe ? 1 : 100;

    const rows = this.config.Rows;
    if (!isNaN(+rows)) {
      this.rows = Math.max(+rows, 1);
    }

    // Make this class read-only
    Object.freeze(this);
  }
}
