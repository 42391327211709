import * as cssVariable from 'cssVariables';
import { isIE11andLower } from 'util/device';

export default (theme) => ({
  button: {
    ...cssVariable.baseButtonStyle,
    display: 'inline-block',
    color: cssVariable.white,
    fontSize: cssVariable.fontSizes.xsmall,
    backgroundColor: cssVariable.green500,
    maxWidth: isIE11andLower ? 210 : 'auto',
    padding: '0 30px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: cssVariable.grey600,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: cssVariable.fontSizes.xsmall,
      padding: '0 15px',
    },
  },

  blue: {
    background: cssVariable.blue500,
    color: cssVariable.white,
  },

  dark: {
    color: cssVariable.white,
    background: cssVariable.grey600,
    '&:hover, &:focus': {
      background: cssVariable.grey600,
      textDecoration: 'underline',
    },
  },

  whiteOutlined: {
    color: cssVariable.white,
    border: `1px solid ${cssVariable.white}`,
    background: cssVariable.transparentDarkmode000,
    '&:hover': {
      background: cssVariable.transparentDarkmode000,
      transform: 'translateY(-1px)',
      boxShadow: `0 1px 3px 0 ${cssVariable.transparentLightmode005}, 0 5px 10px 0 ${cssVariable.transparentLightmode010}`,
    },
  },
});
