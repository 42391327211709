import { Styles } from 'jss';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  swimlaneArrow: {
    display: 'none',
    position: 'absolute',
    top: 0,
    height: '100%',
    background:
      'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.65) 48%, rgba(255,255,255,0.75) 100%)',
    zIndex: 10,
    alignItems: 'center',
    justifyItems: 'flex-start',
    padding: '0 20px',
    '& > svg': {
      transition: 'height .15s',
    },
    '&:hover': {
      cursor: 'pointer',
      background:
        'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 48%, rgba(255,255,255,0.95) 100%)',
      '& > svg': {
        height: 30,
      },
    },
    transition: 'opacity .2s ease-out',
    opacity: 0,
    width: 0,
    overflow: 'hidden',
  },
  visible: {
    opacity: 1,
    width: 'auto',
  },
  left: {
    left: 0,
    transform: 'rotate(180deg)',
    marginLeft: -40,
  },
  right: {
    right: 0,
    justifyItems: 'flex-end',
    marginRight: -40,
  },
  [theme.breakpoints.down('sm')]: {
    right: {
      marginRight: -20,
    },
    left: {
      marginLeft: -20,
    },
  },
});
