import { ActivePodcastOrStation, PlaybackMode } from '@4tn/core-audio-player-v2';
import * as analytics from 'analytics';
import invariant from 'invariant';
import AudioPlayer from './AudioPlayer';
import { isHuawei } from 'util/device';
import getCapConfig from 'util/getCapConfig';
import { Station } from 'api/models';

class AudioPlayerStation extends AudioPlayer {
  /**
   * Enumeration of station types
   */
  static stationTypes = {
    TRITON: 'triton',
    GENERIC: 'generic',
  };

  /**
   * Loads and initialises the stream source of the current station
   */
  async load(): Promise<void> {
    const imageUrl = this.station && this.station.image ? this.station.image.url : '';
    this.setMediaSessionData(this.station.name, imageUrl);

    // Huawei QuickApp uses the native android player, if we start it here there are two players active
    if (!isHuawei) {
      await this.handlePlay();
    }

    analytics.trackPlayLiveAudio(this.station, this.player.currentTime);
  }

  /**
   * Resumes audio playback
   */
  async resume(): Promise<void> {
    invariant(this.playSessionId && this.station, 'PlaySessionId and Station should both be set.');

    // Huawei QuickApp uses the native android player, if we start it here there are two players active
    if (!isHuawei) {
      await this.handlePlay();
    }

    analytics.trackResumeLiveAudio(this.station, this.player.currentTime);
    this.healthyPlayDetector.resume();
  }

  /**
   * Plays audio from a given station
   */
  async play(playSessionId: string, station: Station, useHD = false) {
    // Get the preferance for HD audio
    this.prefersHD = useHD;

    if (this.playSessionId === playSessionId) {
      // Resume playback if it's the same sesseion
      await this.resume();
    } else {
      // Set and load content on new session
      this.playSessionId = playSessionId;
      this.station = station;

      analytics.trackStartLiveAudio(this.station, this.player.currentTime);

      await this.load();
      this.healthyPlayDetector.startNew();
    }
  }

  /**
   * Stops the audio playback
   */
  async stop(trackAnalytics = true): Promise<void> {
    await super.stop();

    if (trackAnalytics) {
      analytics.stopHeartBeat();
      if (this.station) {
        analytics.trackStopLiveAudio(this.station, this.player.currentTime);
      }
    }
  }

  private async handlePlay() {
    const config = await getCapConfig(this.prefersHD);

    const stationPayload = {
      contentTypeId: this.station.contentTypeId,
      id: this.station.id,
      media: this.station.media,
      slug: this.station.slug,
    } as ActivePodcastOrStation;

    // NOTE: this is a quick & dirty workaround for opening video player on streams which has video url in it
    // this code should be refactored!

    await this.player.play(stationPayload, config);
  }

  /**
   * Returns the isPlaying value
   */
  get isPlaying(): boolean {
    return this.player.isPlaying && this.player.playbackMode === PlaybackMode.LIVE_AUDIO;
  }
}

const audioPlayerStation = new AudioPlayerStation();
export default audioPlayerStation;
