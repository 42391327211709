import { connect } from 'react-redux';

import { clearNotification } from 'actions/notifications-actions';
import Notification from './Notification';

const mapDispatchToProps = { onClearNotification: clearNotification };

const mergeProps = (stateProps, { onClearNotification }, ownProps) => {
  const { id } = ownProps;
  return {
    ...ownProps,
    ...stateProps,
    onClearNotification: () => onClearNotification(id),
  };
};

export default connect(null, mapDispatchToProps, mergeProps)(Notification);
