import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg viewBox="0 0 18 18">
    <g transform="translate(-1, -1)">
      <path d="M12.8714727,12.1643659 L15.8535534,15.1464466 C16.0488155,15.3417088 16.0488155,15.6582912 15.8535534,15.8535534 C15.6582912,16.0488155 15.3417088,16.0488155 15.1464466,15.8535534 L12.1643659,12.8714727 C11.3024306,13.5768174 10.2006351,14 9,14 C6.23857625,14 4,11.7614237 4,9 C4,6.23857625 6.23857625,4 9,4 C11.7614237,4 14,6.23857625 14,9 C14,10.2006351 13.5768174,11.3024306 12.8714727,12.1643659 Z M9,13 C11.209139,13 13,11.209139 13,9 C13,6.790861 11.209139,5 9,5 C6.790861,5 5,6.790861 5,9 C5,11.209139 6.790861,13 9,13 Z" />
    </g>
  </svg>
);

export default svgIcon(svg);
