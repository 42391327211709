export default (theme) => ({
  '@global': {
    body: {
      color: theme.palette.text.primary,
    },
    a: {
      color: theme.palette.navigation.highlight,
    },
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    width: '100%',
    backgroundColor: theme.palette.background,
  },

  contentPlaceholder: {
    minHeight: 'calc(100vh - 140px)',
  },

  overflowVisible: {
    overflow: 'visible',
  },

  contentSection: {
    position: 'relative',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    zIndex: 1,
  },
  contentSectionScrollable: {
    height: '100vh',
  },

  hide: {
    display: 'none',
  },

  modalPortal: {
    display: 'none',
    position: 'relative',
  },

  showModal: {
    WebkitOverflowScrolling: 'touch',
    overflowY: 'scroll',
    display: 'block',
    top: 0,
    height: '100vh',
    width: '100%',
    zIndex: theme.zIndex.modal,
    position: 'absolute',
    backgroundColor: theme.palette.background,
  },
  showModalStandalone: {
    right: 0,
    left: 0,
    position: 'fixed',
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.modalStandalone,
  },
  hasMobileHeader: {
    '& $showModal': {
      position: 'fixed',
    },
  },
  hasMobileHeaderExtraTop: {
    '& $showModal': {
      top: 60,
    },
  },

  [theme.breakpoints.down('sl')]: {
    hideDownSl: {
      display: 'none',
    },
  },
});
