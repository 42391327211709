import * as cssVariable from 'cssVariables';

export default {
  background: {
    borderRadius: 5,
    width: 70,
    height: 70,
    backgroundSize: 'contain',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    backgroundImage: ({ imageUrl }) => (imageUrl ? `url(${imageUrl})` : null),
  },
  backgroundHover: {
    '&:hover': {
      transform: 'scale(1.05)',
      cursor: 'pointer',
    },
  },
  infoArea: {
    width: 70,
    color: cssVariable.white,
    padding: [0, 5],
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    fontSize: cssVariable.fontSizes.large,
  },

  image: {
    maxHeight: '95%',
  },
};
