import gql from 'graphql-tag';

export const getCollectionConfig = gql`
  query getCollectionConfig($slug: String!) {
    collection: collection(slug: $slug) {
      ... on Collection {
        config {
          entries {
            key
            value
          }
        }
      }
    }
  }
`;
