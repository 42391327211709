import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PlayControl from 'components/PlayControl';
import { injectStyles } from 'injectStyles';

import { getImageWithResamplingQuery } from 'util/resampling';
import * as cssVariable from '../../cssVariables';

class IframeWall extends PureComponent {
  static defaultProps = {
    currentStation: null,
  };

  static propTypes = {
    currentStation: PropTypes.object,
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.openTab();
    document.body.style.overflow = 'hidden';
  }

  openTab = () => {
    window.open(window.location.href, 'juke');
  };

  renderCover() {
    const { currentStation, classes } = this.props;
    const coverImageUrl =
      currentStation && currentStation.image ? currentStation.image.url : `${__URL_PREFIX__}/juke-logo.png`;

    const imageSize = Math.ceil(100 * window.devicePixelRatio);
    return (
      <div
        className={classes.cover}
        data-testid="cover-image"
        style={{
          backgroundImage: `url(${getImageWithResamplingQuery({
            url: coverImageUrl,
            customWidth: imageSize,
          })})`,
        }}
      />
    );
  }

  render() {
    // Display nothing if user has consented
    const { currentStation, classes } = this.props;
    return (
      <section role="presentation" className={classes.container} onClick={this.openTab}>
        <section className={classes.playerContainer}>
          <div className={classes.coverContainer}>{this.renderCover()}</div>
          <div className={classes.nowPlayingInfo}>
            <p className={classes.stationName}>{currentStation ? currentStation.name : 'Radio'}</p>
          </div>
          <div className={classes.controlContainer}>
            <PlayControl playToggle={this.openTab} isPlaying={false} size={100 - cssVariable.gutter} isPrimary />
          </div>
        </section>
      </section>
    );
  }
}

const styles = (theme) => ({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    top: '0',
    left: '0',
    zIndex: theme.zIndex.iframeWall,
    backgroundColor: cssVariable.grey100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  playerContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxHeight: 300,
    maxWidth: 500,
    display: 'flex',
    padding: 'calc(5vh + 20px)',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
  coverContainer: {
    position: 'relative',
    width: 100,
    height: 100,
    flex: 'none',
    backgroundColor: cssVariable.grey200,
    cursor: 'pointer',
  },
  cover: {
    ...cssVariable.fillAspectRatio,
    borderRadius: 1,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  nowPlayingInfo: {
    paddingLeft: cssVariable.gutter / 2,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontWeight: cssVariable.fontWeights.bold,
    lineHeight: 1,
    cursor: 'pointer',
  },
  stationName: {
    margin: 0,
  },
  controlContainer: {
    padding: cssVariable.gutter / 2,
  },
});

export default injectStyles(styles)(IframeWall);
