import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { cleanObject, generatePlaceholders, isMobile } from 'util/index';
import { STATION_PAGE_TITLE, TILE_SIZES, STATION_PAGE_SETTINGS } from 'globalConst/const';
import { SCHEMA_PAGE_TYPE, SCHEMA_ACTION_TYPE } from 'globalConst/schema-const';
import { BreadcrumbsSchema, DeeplinkSchema, PageSchema } from 'components/Schema';
import Collection from 'components/Collection';
import { withRouter } from 'react-router-dom';

import PageContent from 'components/PageContent';
import PageTypeSchema from 'components/Schema/PageTypeSchema';
import PageHeader from 'components/PageHeader';
import { DisplayLayout } from 'components/Collection/Collection.const';
import NotFoundPage from 'components/pages/status/NotFoundPage';
import { TILE_COMPONENT_TYPES } from 'components/TileComponent/TileComponent.const';
import HeaderBanner from 'components/HeaderBanner';
import ContentTypeComponent from 'components/ContentTypeComponent';
import { fetchShareImageFromSonos } from 'api/graphql/station.service';
import { logger } from 'util/index';

const DEFAULT_COLLECTION_PROPS = {
  tileSizes: TILE_SIZES.SMALL,
  rows: 1,
  showNowPlayingInfo: true,
};

const SPECIFIC_PAGE_TYPE = {
  BRAND: 'Brand',
  RADIOSTATION: 'RadioStation',
};

const SPECIFIC_BRAND_TYPE = {
  RADIOZENDERS: 'radiozenders',
  BRAND: 'brand',
};

class StationPage extends PureComponent {
  static propTypes = {
    error: PropTypes.bool,
    isComponentLoading: PropTypes.bool,
    isPlaying: PropTypes.bool,
    loadStation: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    overview: PropTypes.object,
    station: PropTypes.object,
    stationCollection: PropTypes.object,
    liveShows: PropTypes.array,
    subSlug: PropTypes.string,
    tagSlug: PropTypes.string,
    metadata: PropTypes.object,
    location: PropTypes.object.isRequired,
  };
  static defaultProps = {
    isComponentLoading: false,
    error: false,
    isPlaying: false,
    overview: null,
    station: null,
    liveShows: [],
    stationCollection: null,
    subSlug: null,
    tagSlug: null,
  };

  state = {
    shareImageUrl: '',
  };

  componentDidMount() {
    const { slug } = this.props;
    this.mounted = true;
    fetchShareImageFromSonos({
      slug: slug,
    })
      .then((imageUri) => {
        if (this.mounted) {
          this.setState({
            imageUri,
          });
        }
      })
      .catch((error) => {
        logger.error(`something went wrong while fetching station share image`, error);
      });
  }
  componentDidUpdate(prevProps) {
    const { isPlaying, loadStation, station } = this.props;

    if (isPlaying) {
      return;
    }

    if (station && (prevProps.station || {}).slug !== station.slug) {
      loadStation(station, false);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * Rendered if currentSlug matches a stationCollection
   */
  renderStationCollection = () => {
    const { stationCollection, station } = this.props;
    const stationInHeader = {
      ...station,
      title: stationCollection.title,
      logo: null,
    };

    return (
      <Fragment>
        <PageHeader station={stationInHeader} page={STATION_PAGE_SETTINGS} />
        <BreadcrumbsSchema level2={STATION_PAGE_TITLE} level3={station.brand} level4={station.title} />
        <PageContent>
          <Collection
            uuid={stationCollection.uuid}
            items={stationCollection.items}
            {...DEFAULT_COLLECTION_PROPS}
            title={stationCollection.title}
            display={DisplayLayout.TILES}
            tileComponent={TILE_COMPONENT_TYPES.STATION}
            showMoreButton={['link']}
          />
        </PageContent>
      </Fragment>
    );
  };

  /**
   * Rendered while data is loaded from Universal API
   */
  renderPlaceholder = () => {
    const placeholder = {
      uuid: 'placeholder-collection',
      contentTypeId: 'stationCollection',
      display: 'tiles',
      ...DEFAULT_COLLECTION_PROPS,

      showMore: {
        variant: 'hideShowMore',
      },
      collection: generatePlaceholders(20, {
        contentTypeId: 'station',
        tileComponent: TILE_COMPONENT_TYPES.STATION,
      }),
    };

    return (
      <Fragment>
        <PageHeader station={{ isComponentLoading: true }} page={STATION_PAGE_SETTINGS} />
        <PageContent>
          <ContentTypeComponent {...placeholder} />
        </PageContent>
      </Fragment>
    );
  };

  getStationDeeplink = () => {
    const { tagSlug, subSlug } = this.props;
    if (!subSlug || tagSlug === subSlug) {
      return `play/station/${tagSlug}/${tagSlug}`;
    }
    return `play/station/${tagSlug}/${subSlug}`;
  };

  getMetadataWithShareImage = () => {
    const { station, metadata: brandMetadata, subSlug } = this.props;
    const { metadata: stationMetadata } = station || {};
    const { share } = brandMetadata || {};
    const isStationPage = !!subSlug;

    const metadataWithShareImage = {
      ...(isStationPage ? stationMetadata : brandMetadata),
      share: {
        ...share,
        image: {
          url: this.state.imageUri,
        },
      },
    };

    return metadataWithShareImage;
  };

  render() {
    const { error, isComponentLoading, station, stationCollection, overview, slug, tagSlug, subSlug } = this.props;

    const metadataWithShareImage = this.getMetadataWithShareImage();

    if (isComponentLoading) {
      return this.renderPlaceholder();
    }

    if (stationCollection) {
      return this.renderStationCollection();
    }

    const { sections, ...otherOverviewProps } = overview || {};

    if (error || !sections || !sections.length || !station) {
      return <NotFoundPage />;
    }

    const deeplink = [
      {
        type: SCHEMA_ACTION_TYPE.LISTEN_ACTION,
        link: this.getStationDeeplink(),
      },
    ];
    if (!subSlug || tagSlug === subSlug) {
      deeplink.push({
        type: SCHEMA_ACTION_TYPE.VIEW_ACTION,
        link: `juke/brand/${tagSlug}`,
      });
    }

    const pageSchemaProps = {
      type: subSlug ? SPECIFIC_PAGE_TYPE.RADIOSTATION : SPECIFIC_PAGE_TYPE.BRAND,
      name: subSlug ? station.title : overview.title,
      logo: station?.logoSecondary?.url,
      description: metadataWithShareImage.description || station.subtitle || overview.subtitle,
      id: subSlug ? station.linkedPage : `/radiozenders/${slug}`,
      image: subSlug ? station?.image?.url : overview?.headerImage?.url,
    };
    return (
      <Fragment>
        <PageTypeSchema type={SCHEMA_PAGE_TYPE.ITEM_PAGE} metadata={metadataWithShareImage} />
        <DeeplinkSchema deeplinks={deeplink} />
        <PageSchema {...pageSchemaProps} />
        <PageHeader
          station={{
            ...otherOverviewProps,
            ...cleanObject(station),
          }}
          contentTypeId={subSlug ? 'radiostation' : 'brand'}
          pageLink={station.linkedPage}
          metadata={metadataWithShareImage}
          page={STATION_PAGE_SETTINGS}
          isStationPage={!!subSlug}
        />
        <BreadcrumbsSchema level2={STATION_PAGE_TITLE} level3={station.brandName} level4={station.title} />
        <HeaderBanner
          imageUrl={overview?.headerImage?.url}
          station={station}
          logoUrl={station.logo?.url}
          color={station.brandColor}
          title={station.title}
          description={station.subtitle ?? overview.subtitle}
          metadata={metadataWithShareImage}
          isBrandPage={this.props.location?.pathname?.split('/')[1] === SPECIFIC_BRAND_TYPE.BRAND}
        />
        <PageContent>
          {sections &&
            sections.map((section) => (
              <ContentTypeComponent
                key={section.uuid}
                {...DEFAULT_COLLECTION_PROPS}
                tileSizes={
                  isMobile && section.contentTypeId === 'stationCollection' ? TILE_SIZES.SMALL : TILE_SIZES.MEDIUM
                }
                rows={isMobile && section.contentTypeId === 'stationCollection' ? 100 : 1}
                {...section}
              />
            ))}
        </PageContent>
      </Fragment>
    );
  }
}

export default withRouter(StationPage);
