import { connect } from 'react-redux';
import { getRecentSearchItems } from 'store/reducers/search';
import { removeItemFromRecentSearch, clearRecentSearch } from 'actions/search-actions';
import RecentSearchCollection from './RecentSearchCollection';

const mapStateToProps = (state) => ({
  recentItems: getRecentSearchItems(state),
});

const mapDispatchToProps = {
  removeItemFromRecentSearch,
  clearRecentSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentSearchCollection);
