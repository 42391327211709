import * as React from 'react';
import NavigationLink from '../NavigationLink';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FOOTER from '../Footer/Footer.const';
import styles from './FooterNavigationCollection.styles';
import { createUseStyles } from 'react-jss';

interface FooterNavigationItemProps {
  variant: string;
  title: string;
  order: number;
  pageLink: unknown;
  uri: string;
  icon: {
    title?: string;
    uri?: string;
  };
}

const useStyles = createUseStyles(styles, { name: 'FooterNavigationCollectionItem' });

const FooterNavigationItem: React.FC<FooterNavigationItemProps> = ({ title, variant, pageLink, uri, icon }) => {
  const classes = useStyles();
  return (
    <li>
      <span className={classes.navItemWrapper}>
        <NavigationLink
          data-testid="footer-link"
          className={classNames(classes.footerLink, {
            [classes.footerLinkPrimary]: variant === FOOTER.ORDER_PRIMARY,
          })}
          source={FOOTER.NAV_SOURCE}
          pageLink={pageLink}
          pageUrl={uri || ''}
          title={title}
        >
          <>
            {icon ? (
              <div className={classes.socialIcon}>
                <img src={icon.uri} alt={icon.title} />
              </div>
            ) : (
              <>{title}</>
            )}
          </>
        </NavigationLink>
      </span>
    </li>
  );
};

FooterNavigationItem.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  order: PropTypes.number,
  pageLink: PropTypes.object,
  uri: PropTypes.string,
  icon: PropTypes.object,
};

export default FooterNavigationItem;
