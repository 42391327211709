import React from 'react';
import { createUseStyles } from 'react-jss';

import styles from './Container.styles';

const useStyles = createUseStyles(styles, { name: 'Layout' });

interface ContainerProps {
  testId: string;
  children: JSX.Element;
}

const Container = ({ testId, children }: ContainerProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div data-testid={testId} className={classes.containerClass}>
      {children}
    </div>
  );
};

export default Container;
