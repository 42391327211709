import { isEmpty } from 'util/index';
import { queryRequest } from './client';
import { getShows, getShow, getSchedules } from './queries';
import { ShowCollection, Show, Schedules } from 'api/models';

import { fetchStation } from './station.service';

/**
 * Fetch a list of show collections from the universal api
 * @param {object} props
 * @param {string} props.slug
 * @param {object} props.collectionProps
 * @param {object} props.sectionConfig
 *
 * @returns {Object} ShowCollection
 */
export const fetchShowCollection = async ({ slug, config, options }) => {
  const { data } = (await queryRequest({ query: getShows, variables: { slug } })) || {};

  if (!data.collection || isEmpty(data.collection.items)) {
    return null;
  }
  data.collection.items = await Promise.all(
    data.collection.items.map(async (item) => {
      const { uri: stationSlug } = item.refs.find(({ targetType }) => targetType === 'station') || {};
      if (stationSlug) {
        item.station = await fetchStation({ slug: stationSlug });
      }
      return item;
    })
  );

  return new ShowCollection(data.collection, config, options);
};

/**
 * Fetch a single show from the universal api
 * @param {string} slug
 * @returns {Object} Show
 */
export const fetchShow = async ({ slug }) => {
  const { data } = (await queryRequest({ query: getShow, variables: { slug } })) || {};
  return new Show(data.show);
};

/**
 * Fetch the schedules of a station from the universal api
 * @param {string} slug
 * @returns {Object} Schedules
 */
export const fetchSchedules = async ({ slug }) => {
  const now = new Date();
  const localTime = new Date(now.getTime());
  const broadcastStartDate = localTime.toISOString();

  localTime.setDate(now.getDate() + 1);
  const broadcastEndDate = localTime.toISOString();

  const { data } =
    (await queryRequest({
      query: getSchedules,
      variables: {
        slug,
        broadcastStartDate,
        broadcastEndDate,
      },
    })) || {};

  return data.station && new Schedules(data.station.schedules);
};
