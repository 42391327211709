import React from 'react';

import svgIcon from 'components/hoc/svgIcon';

const svg = (
  <svg viewBox="0 0 72 72">
    <path d="M55.7 62.1H13c-.8 0-1.5-.4-1.9-1-.3-.6-.3-1.4.2-2.1l6.4-9.9c.6-1 1.9-1.8 3.1-1.9l7.4-.8c.1 0 .3-.2.3-.3v-4.2c-1-.6-2-1.4-2.9-2.4-3.6-3.8-5.8-8.9-5.8-13.7 0-8.5 5.5-14.4 13.5-14.4s13.4 5.9 13.4 14.4c0 7.2-4.9 12.6-9.7 14.7-.6.3-1.4 0-1.6-.6-.3-.6 0-1.4.6-1.6 4.1-1.8 8.2-6.3 8.2-12.5 0-7.1-4.4-11.9-10.9-11.9s-11 4.8-11 11.9c0 4.2 1.9 8.7 5.1 12 1 1 2 1.8 2.9 2.4.4.2.7.6.7 1.1v4.9c0 1.4-1.1 2.6-2.5 2.8l-7.5.7c-.4 0-1 .4-1.2.8l-5.9 9.1h41.8c.7 0 1.3.6 1.3 1.3s-.6 1.2-1.3 1.2z" />
    <path d="M50 53.8c-3.5 0-6.4-2.9-6.4-6.4 0-3.5 2.9-6.4 6.4-6.4s6.4 2.9 6.4 6.4c.1 3.5-2.8 6.4-6.4 6.4zm0-10.3c-2.2 0-3.9 1.8-3.9 3.9 0 2.2 1.8 3.9 3.9 3.9 2.2 0 3.9-1.8 3.9-3.9.1-2.2-1.7-3.9-3.9-3.9z" />
    <path d="M55.3 48.2c-.7 0-1.3-.6-1.3-1.3V23.7c0-.5.4-1 .9-1.2.5-.2 1.1 0 1.4.5l5.5 7.7c.4.6.3 1.3-.3 1.7-.6.4-1.3.3-1.7-.3l-3.2-4.5v19.3c0 .7-.6 1.3-1.3 1.3z" />
  </svg>
);

export default svgIcon(svg);
