import gql from 'graphql-tag';

export const getCampaign = gql`
  query GetCampaign($slug: String!) {
    campaign(slug: $slug) {
      slug
      uri
      type
      config {
        entries {
          key
          value
        }
      }
      metadata {
        title
        description
        shareTitle
        shareText
        shareLink
      }
      config {
        entries {
          key
          value
        }
      }
    }
  }
`;
