import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Collection from 'components/Collection';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { TILE_SIZES } from 'globalConst/const';
import { TILE_COMPONENT_TYPES } from 'components/TileComponent/TileComponent.const';
import { DisplayLayout } from 'components/Collection/Collection.const';

import { TITLE, LABEL } from 'globalConst/const';
import { createUseStyles } from 'react-jss';
import styles from './RecentSearchCollection.styles';

const useStyles = createUseStyles(styles, { name: 'RecentSearchCollection' });

const RecentSearchCollection = ({ recentItems, removeItemFromRecentSearch, clearRecentSearch }) => {
  const classes = useStyles();
  const removeItemParent = (slug) => {
    removeItemFromRecentSearch(slug);
  };

  const removeAll = () => {
    clearRecentSearch();
  };

  return (
    <div className={classes.container}>
      <div className={classes.infoContainer}>
        <h3 className={classes.title}>{TITLE.RECENT_SEARCH}</h3>
        <button className={classNames(classes.button, classes.buttonText)} type="button" onClick={removeAll}>
          {LABEL.DELETE_ALL}
        </button>
      </div>
      <Collection
        items={recentItems.slice(0, 10).map(({ uri, targetType }) => ({
          key: uri,
          removeItemParent,
          slug: uri,
          contentTypeId: targetType,
        }))}
        tileComponent={TILE_COMPONENT_TYPES.MIXED_CONTENT_WITH_HOC}
        tileSizes={TILE_SIZES.SMALL}
        contentTypeId={CONTENT_TYPES.MIXED_COLLECTION}
        rows={1}
        display={DisplayLayout.CAROUSEL}
        swipe
        recentSearch
      />
    </div>
  );
};

RecentSearchCollection.defaultProps = {
  recentItems: [],
};

RecentSearchCollection.propTypes = {
  recentItems: PropTypes.array,
  removeItemFromRecentSearch: PropTypes.func.isRequired,
  clearRecentSearch: PropTypes.func.isRequired,
  isInSearch: PropTypes.bool.isRequired,
};

export default RecentSearchCollection;
