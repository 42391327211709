import { UniversalPromotion, Image, UniversalRef, UniversalCollectionItem } from 'api/interfaces';
import Station from './Station';
import Podcast from './Podcast';
import CollectionItem from './CollectionItem';
import { isEmpty, parseConfig } from 'util/index';
import { CONFIG_KEY, BRAND, RADIOZENDERS } from 'globalConst/const';

/**
 * @class
 * A representation of a Promotion
 *
 * @param {object} promotion - promotion model
 */

const IMAGE_VARIANTS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

interface PromotionPlayContent extends UniversalRef {
  station?: Station;
  podcast?: Podcast;
}

export default class Promotion extends CollectionItem {
  pageUrl?: string;
  urlTarget?: string;
  imageSmall?: Image;
  imageMedium?: Image;
  imageLarge?: Image;
  image?: Image;
  reference?: { id: string; targetType: string; type: string; uri: string };

  playContent?: PromotionPlayContent;

  constructor(item: UniversalCollectionItem) {
    super(item);
    if (!item) {
      return null;
    }

    const promotion = <UniversalPromotion>item;

    this.pageUrl = promotion.pageUrl || promotion.uri;
    this.reference = promotion.ref;
    let station = promotion.station;
    let podcast = promotion.podcast;
    if (!this.pageUrl && !isEmpty(promotion.reference)) {
      const { reference } = promotion;

      const { slug: refSlug, type: targetType, config } = reference;
      const refConfig = parseConfig(config);
      const brandSlug = refConfig?.BrandSlug;
      const { id, type, title, slug, description, uuid, ...restRefs } = promotion.reference;

      /**
       * if referenced tag of type brand contains config with MainStationSlug it is considered "station-brand"
       * if referenced tag of type brand does not contain config with MainStationSlug it is considered "non-station-brand"
       */
      const isStationBrand =
        !isEmpty(promotion?.reference.config) &&
        !!promotion?.reference?.config.find((obj) => {
          return obj.entries.find((entry) => entry.key === CONFIG_KEY.MAIN_STATION_SLUG);
        });

      const brandType = isStationBrand ? RADIOZENDERS : BRAND;

      switch (targetType) {
        case 'station':
          this.pageUrl = brandSlug !== refSlug ? `/radiozenders/${brandSlug}/${refSlug}` : `/radiozenders/${refSlug}`;
          station = new Station({
            id,
            type,
            title,
            slug,
            description,
            uuid,
          });
          break;
        case 'campaign':
          this.pageUrl = `/radiozenders/${brandSlug}/acties/${refSlug}`;
          break;
        case 'podcast':
          this.pageUrl = `/podcasts/${refSlug}`;
          podcast = new Podcast({
            id,
            type,
            title,
            slug,
            description,
            uuid,
            ...restRefs,
          });
          break;
        case 'brand':
          this.pageUrl = `/${brandType}/${refSlug}`;
          break;
        case 'mood':
          this.pageUrl = `/moods-en-momenten/${refSlug}`;
          break;
        case 'genre':
          this.pageUrl = `/muziekgenres/${refSlug}`;
          break;
        default:
          break;
      }
    }
    if (promotion.options && Array.isArray(promotion.options)) {
      promotion.options.forEach((option: string) => {
        if (option.includes('target')) {
          this.urlTarget = option.replace('target-', '_');
        }
        if (option.includes('play')) {
          this.pageUrl = null;
          this.playContent = promotion.ref;
          this.playContent.station = station;
          this.playContent.podcast = podcast;
        }
      });
    }

    if (promotion.images && Array.isArray(promotion.images)) {
      const image = promotion.images[0];
      if (!isEmpty(image)) {
        this.image = {
          url: image.uri,
          altText: image.title || this.title,
          width: image.width,
          height: image.height,
        };
      }

      promotion.images.forEach(({ uri, title, variant, width, height }) => {
        switch (variant && variant.slug) {
          case IMAGE_VARIANTS.SMALL:
            this.imageSmall = {
              url: uri,
              altText: title || this.title,
              width,
              height,
            };
            break;
          case IMAGE_VARIANTS.MEDIUM:
            this.imageMedium = {
              url: uri,
              altText: title || this.title,
              width,
              height,
            };
            break;
          case IMAGE_VARIANTS.LARGE:
            this.imageLarge = {
              url: uri,
              altText: title || this.title,
              width,
              height,
            };
            break;
          default:
            break;
        }
      });
    }
    // Make this class read-only
    Object.freeze(this);
  }
}
