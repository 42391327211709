import * as cssVariable from 'cssVariables';

export default (theme) => ({
  wrapper: {
    display: 'inline-block',
    width: 'calc(50% - 20px)',
    verticalAlign: 'top',
    marginBottom: 40,
  },
  innter: {
    display: 'flex',
  },
  image: {
    width: 150,
    height: 150,
    marginRight: 20,
  },
  content: {
    display: 'flex',
    width: 'calc(100% - 150px)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingRight: 30,
  },
  contentTopPart: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentBottomPart: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: cssVariable.fontSizes.mediumLarge,
    textTransform: 'uppercase',
    fontWeight: cssVariable.fontWeights.bold,
    // align top of the text to the top of the image
    marginTop: -6,
    marginBottom: 0,
  },
  author: {
    fontSize: cssVariable.fontSizes.xsmall,
    color: cssVariable.grey500,
    fontWeight: cssVariable.fontWeights.bold,
  },
  description: {
    fontSize: cssVariable.fontSizes.xsmall,
    color: cssVariable.grey500,

    '@supports (-webkit-line-clamp: 2)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
      display: '-webkit-box',
      [cssVariable.WEBKIT_LINE_CLAMP]: 2,
      [cssVariable.WEBKIT_BOX_ORIENT]: 'vertical',
    },
    '@supports not (-webkit-line-clamp: 2)': {
      ...cssVariable.description2LinesStyles,
    },

    '& *': {
      '@supports (-webkit-line-clamp: 2)': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        [cssVariable.WEBKIT_LINE_CLAMP]: 2,
        [cssVariable.WEBKIT_BOX_ORIENT]: 'vertical',
      },
      '@supports not (-webkit-line-clamp: 2)': {
        ...cssVariable.description2LinesStyles,
      },
    },
  },
  descriptionIE: {
    ...cssVariable.description2LinesStyles,
  },
  linkToPage: {
    fontSize: cssVariable.fontSizes.xsmall,
    fontWeight: cssVariable.fontWeights.bold,
    color: cssVariable.blue500,
    // align bottom of the text to the bottom of the image
    marginBottom: 1,
  },
  [theme.breakpoints.down('ll')]: {
    wrapper: {
      width: '100%',
    },
    content: {
      paddingRight: 0,
      maxWidth: 550,
    },
  },
  [theme.breakpoints.down('sm')]: {
    // reduce image size on small screens
    image: {
      width: 100,
      height: 100,
    },
  },
});
