/* eslint-disable no-console */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'components/App';

if (process.env.NODE_ENV === 'development') {
  console.warn = (msg) =>
    console.log(
      `%c 💤 Custom JUKE -> ${msg.slice(0, 18) === 'Warning: component' ? msg.slice(0, 31) : msg}`,
      'color: #FFFF00; background: #222200;'
    );
  console.juke = (msg, ...rest) =>
    console.log(
      `%c${msg}\n`,
      'color: #fff; background: linear-gradient(122deg, #3c9cdc, #37b376 38%, #46C31D 67%, #facf18); font-size: 1.2rem; font-weight: 1000; padding: 0 1rem;',
      ...rest
    );
}

(function init() {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);
})();

(function cleanLocalStorage() {
  try {
    // Appsync is not using local storage anymore due to overfloating local storage
    localStorage.removeItem('reduxPersist::appsync');
    // Localstorage key JUKE_LAST_PLAYED was missing
    localStorage.removeItem('undefined');
  } catch (error) {
    console.log("Couldn't clear reduxPersist::appsync/undefined");
  }
})();
