import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './PlayerButton.styles';
import { PlayerButtonsContext } from '../PlayerButtons';
import Close from 'components/svg/Close';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(styles, { name: 'PlayerButton' });

const PlayerButton = ({
  active,
  title,
  hasPopout,
  popoutAbove,
  popoutVisible,
  renderPopout,
  renderIcon,
  onPress,
  hideOnMedium,
  hideOnSmall,
  id,
  ...rest
}) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(popoutVisible);

  const { displayPopup, hidePopup } = useContext(PlayerButtonsContext);

  const onClick = () => {
    const nextVal = !visible;
    if (hasPopout) {
      setVisible(nextVal);
    }
    if (onPress) {
      onPress(nextVal);
    }
  };

  const closeBtn = (
    <div onClick={onClick} className={classes.close}>
      <Close inherent />
    </div>
  );

  useEffect(() => {
    if (!visible || popoutAbove) {
      return;
    }

    displayPopup(title, renderPopout(closeBtn, onClick));
    return () => {
      hidePopup(title);
    };
  }, [visible, renderPopout]);

  return (
    <div
      className={classNames(classes.container, {
        [classes.containerRelative]: popoutAbove,
        [classes.hideOnMedium]: hideOnMedium,
        [classes.hideOnSmall]: hideOnSmall,
      })}
    >
      {hasPopout && visible && popoutAbove && (
        <div className={classes.popout}>
          <div className={classes.containerRelative}>{renderPopout()}</div>
        </div>
      )}
      <div className={classes.separator} />
      <div
        id={id}
        data-testid={rest['data-testid']}
        className={classNames(
          {
            [classes.active]: active || visible,
          },
          classes.content
        )}
        onClick={onClick}
      >
        <div className={classes.icon}>{renderIcon()}</div>
        <span className={classes.title}>{title}</span>
      </div>
    </div>
  );
};

PlayerButton.propTypes = {
  renderIcon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,

  active: PropTypes.bool,
  id: PropTypes.string,

  hasPopout: PropTypes.bool,
  popoutAbove: PropTypes.bool,
  popoutVisible: PropTypes.bool,
  renderPopout: PropTypes.func,
  onPress: PropTypes.func,
  hideOnMedium: PropTypes.bool,
  hideOnSmall: PropTypes.bool,
};

PlayerButton.defaultProps = {
  active: false,

  popoutAbove: false,
  popoutVisible: false,
  hasPopout: false,
  renderPopout: null,
  onPress: null,
  hideOnMedium: false,
  hideOnSmall: false,
};

export default PlayerButton;
