import * as cssVariable from 'cssVariables';
import { breakpoints, mediaQuery } from 'components/theme/breakpoints';

export default (theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },

  containerRelative: {
    position: 'relative',
  },

  content: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '78px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },

  icon: {
    height: 25,
    marginBottom: '7px',
  },

  active: {
    color: theme.palette.primary,
  },

  separator: {
    height: '100%',
    width: '1px',
    backgroundColor: theme.palette.common.white,
    opacity: 0.2,
  },

  title: {
    fontSize: cssVariable.fontSizes.xsmall,
    fontWeight: cssVariable.fontWeights.light,
  },

  popout: {
    position: 'absolute',
    minHeight: 1,
    minWidth: 1,
    bottom: 'calc(100% + 11px)',
    borderRadius: '4px',
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)',
  },

  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    fontSize: 8,
    color: theme.palette.common.white,
    cursor: 'pointer',
    zIndex: 1,
  },

  hideOnMedium: {
    [mediaQuery.maxWidth(breakpoints.sl)]: {
      display: 'none',
    },
  },

  hideOnSmall: {
    [mediaQuery.maxWidth(breakpoints.sm)]: {
      display: 'none',
    },
  },
});
