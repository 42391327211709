/**
 * Algolia query has a validation layer on BE side, e.g. in case of entering only spaces in the search input field
 * Response will return error 400 - bad request, with message error "Missing input value"
 * In case of this error and any other we won't send analytics data and we will display the Not Found state on the Result page
 */
export const trackSearchAlgolia = (query, results, time) => {
  let nrOfsearchResults = null;

  if (!results && !results.sections) return;
  results.sections.forEach((result) => {
    if (!result) return;
    nrOfsearchResults += result.items?.length;
  });

  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'menu_search',
      event_category: 'menu',
      event_action: 'search',
      event_label: query || null,
      search_keyword: query || null,
      search_results: nrOfsearchResults,
      search_time: time,
    },
  ]);
};
