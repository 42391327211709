import { Styles } from 'jss';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  disabled: {
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.backgroundSecondary,
  },
});
