import { UniversalCollection, UniversalConfig } from 'api/interfaces';
import { contains, isEmpty, capitalizeFirstLetter } from 'util/index';
import { COLLECTION_OPTIONS } from 'globalConst/const';
import CollectionConfig from './CollectionConfig';
import CONTENT_TYPES from '../../../globalConst/contentTypes-const';
/**
 * @class
 * A representation of collection options
 * It contains all required data fetched from options
 *
 * @param {object} collection - collection model
 * @param {object} sectionConfig - universal api config
 * @param {object} ownProps - collection props
 */
export default class CollectionOptions extends CollectionConfig {
  contentTypeId: string;
  store?: boolean;

  isHighlighted = false;
  hideTitle = false;
  showEmptyState = false;
  showSectionTitle: boolean;

  // It will contain list of slugs for which needs to follow if it has items or not
  // base on that condition it will show or hide collection
  showIfEmpty: Array<string>;
  hideIfEmpty: Array<string>;

  options: string[];

  constructor(collection: UniversalCollection, config: UniversalConfig[], options: string[]) {
    super(collection, config);
    if (isEmpty(collection)) {
      return undefined;
    }
    this.contentTypeId = collection.type;

    this.options = [];
    if (!isEmpty(options)) {
      this.options = options;
    }

    // collection.options will be provided if its experiments
    if (!isEmpty(collection.options)) {
      this.options = [...this.options, ...collection.options];
    }

    if (contains(this.options, COLLECTION_OPTIONS.CONTINUE_LISTENING)) {
      const capitalizedContentTypeId = capitalizeFirstLetter(this.contentTypeId);
      this.contentTypeId = `continueListening${capitalizedContentTypeId}`;
    }

    if (contains(this.options, COLLECTION_OPTIONS.RECENTLY_PLAYED)) {
      this.contentTypeId = CONTENT_TYPES.RECENTLY_PLAYED_STATIONS_COLLECTION;
    }

    this.isHighlighted = contains(this.options, COLLECTION_OPTIONS.HIGHLIGHT);
    this.hideTitle = contains(this.options, COLLECTION_OPTIONS.HIDE_TITLE);
    this.showEmptyState = contains(this.options, COLLECTION_OPTIONS.SHOW_EMPTY_STATE);
    this.showSectionTitle = contains(this.options, COLLECTION_OPTIONS.SHOW_SECTION_TITLE);

    this.showIfEmpty = [];
    this.hideIfEmpty = [];

    this.options.forEach((value) => {
      if (value.startsWith(COLLECTION_OPTIONS.SHOW_IF_EMPTY)) {
        this.showIfEmpty.push(value.replace(`${COLLECTION_OPTIONS.SHOW_IF_EMPTY}-`, ''));
      }
      if (value.startsWith(COLLECTION_OPTIONS.HIDE_IF_EMPTY)) {
        this.hideIfEmpty.push(value.replace(`${COLLECTION_OPTIONS.HIDE_IF_EMPTY}-`, ''));
      }
    });
  }
}
