import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import injectStylesheet from 'react-jss';
import * as cssVariables from 'cssVariables';
import Loader from 'components/Loader';
import { SUCCESS, LOADING, FAILED, FALLBACK_TIMEOUT } from './MopinionForm.const';

function MopinionForm({ classes, fallback, mopinionId }) {
  const [state, setState] = useState(null);
  const formNode = useRef();

  useEffect(() => {
    let timeout;
    const observer = new MutationObserver(() => {
      setState(SUCCESS);
      clearTimeout(timeout);
      observer.disconnect();
    });

    if (formNode.current) {
      setState(LOADING);
      observer.observe(formNode.current, {
        childList: true,
      });

      // Validate whether form is successfully loaded or not
      timeout = setTimeout(() => {
        if (!formNode.current.hasChildNodes()) {
          setState(FAILED);
        }
      }, FALLBACK_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
      observer.disconnect();
    };
  }, [formNode, mopinionId]);

  return (
    <div className={classes.formContainer}>
      {state === LOADING && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      {state === FAILED && fallback && <div dangerouslySetInnerHTML={{ __html: fallback }} />}
      {mopinionId && <div ref={formNode} id={mopinionId} data-testid="mopinion-node" />}
    </div>
  );
}

MopinionForm.defaultProps = {
  mopinionId: null,
  fallback: null,
};

MopinionForm.propTypes = {
  mopinionId: PropTypes.string,
  fallback: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const styles = {
  formContainer: {
    marginTop: cssVariables.gutter * 2,
    marginBottom: cssVariables.gutter * 2,
  },
  loader: {
    textAlign: 'center',
    padding: [0, 20],
  },
};

export default injectStylesheet(styles)(MopinionForm);
