import * as cssVariable from 'cssVariables';
import { PLAYER_BOTTOM_HEIGHT } from 'components/PlayerBottom/PlayerBottom.const';

export default (theme) => ({
  footerContainer: {
    height: 'auto',
    marginBottom: 2,
    padding: `${cssVariable.gutter / 2}px 0 ${PLAYER_BOTTOM_HEIGHT}px`,
    textAlign: 'center',
    backgroundColor: theme.palette.background,
    fontWeight: cssVariable.fontWeights.light,
    borderTop: `1px solid ${theme.palette.navigation.border}`,
    margin: '0 40px',
    position: 'relative',
  },

  propositionText: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 14,
    color: cssVariable.grey500,
    margin: '0 40px',
    fontWeight: cssVariable.fontWeights.bold,
    padding: `${cssVariable.gutter / 2}px 0`,
  },

  footerLink: {
    color: 'inherit',
  },

  list: {
    listStyle: 'none',
    padding: '0 10px',
    marginBottom: 15,
    '& > li': {
      display: 'inline-block',
      fontSize: cssVariable.fontSizes.xxsmall,
      padding: '0 10px',
      textTransform: 'uppercase',
    },
    '& > a': {
      color: cssVariable.grey500,
    },
  },

  primary: {
    '& > li': {
      padding: '0 15px',
      fontWeight: cssVariable.fontWeights.bold,
      fontSize: cssVariable.fontSizes.xsmall,
    },
    '& > a': {
      color: cssVariable.grey600,
    },
  },

  [theme.breakpoints.down('md')]: {
    list: {
      marginBottom: 15,
      '& > li': {
        padding: '0 10px',
      },
    },

    footerContainer: {
      marginLeft: '40px',
      marginRight: '40px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    footerContainer: {
      margin: '0 20px',
    },

    list: {
      '& > li': {
        padding: '7.5px 10px',
      },
    },
    primary: {
      '& > li': {
        display: 'block',
      },
    },
  },
});
