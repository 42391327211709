import { mediaAudiotype } from 'analytics';
import convertUtcToUnix from 'util/time';

export function getMediaIdForOnDemand(onDemandClip) {
  if (!onDemandClip) return null;
  // for rss item, use Podcast slug as media id (INN-4055)
  // for everything else, same as before, use id from onDemand clip
  if (onDemandClip.podcast) {
    return onDemandClip.podcast.slug;
  }
  return onDemandClip.id || null;
}

export function getMediaGenreForOnDemand(onDemandClip) {
  if (!onDemandClip) return null;
  // for rss item, read genres from Podcast
  if (onDemandClip.podcast && onDemandClip.podcast.genres && onDemandClip.podcast.genres.length) {
    return onDemandClip.podcast.genres.join('|');
  }
  return null;
}

export function getMediaAudiotypeForOnDemand(onDemandClip) {
  if (!onDemandClip) return null;
  if (onDemandClip.podcast) {
    return mediaAudiotype.podcast;
  }
  return mediaAudiotype.radioOnDemand;
}

export function trackStartOnDemandClip(onDemandClip, isCasting) {
  const { show, title, imageUrl, duration, publishedUtc, collectionTitle } = onDemandClip || {};

  window.Taq.push([
    'track',
    'link',
    {
      ...window.Taq.jukeCustomData,
      event_name: 'juke-player-on-demand_start',
      event_category: 'juke-player-on-demand',
      event_action: 'start',
      player_state: 'start',
      event_label: (show && show.name) || title,
      media_playtime: 0,
      media_duration: duration || null,
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_id: (show && show.name) || title || null,
      media_genre: onDemandClip ? getMediaGenreForOnDemand(onDemandClip) : null,
      media_name: (show && show.name) || title || null,
      media_thumbnail: imageUrl || null,
      media_datepublished: publishedUtc ? convertUtcToUnix(publishedUtc) : null,
      media_program_name: onDemandClip ? getMediaIdForOnDemand(onDemandClip) : null,
      media_playtimepercentage: 0,
      media_bitrate: '64kbps',
      player_casting: isCasting ? 'chromecast' : false,
      c_media_audiotype: onDemandClip ? getMediaAudiotypeForOnDemand(onDemandClip) : null,
      content_id: collectionTitle || null,
    },
  ]);
}

export function trackPlayOnDemandClip(
  onDemandClip,
  mediaPlaytime,
  mediaDuration,
  isCasting = false,
  podcastEpisode = '',
  podcastTitle = ''
) {
  const { show, title, imageUrl, publishedUtc, collectionTitle } = onDemandClip || {};
  if (podcastEpisode && podcastTitle) {
    window.Taq.push([
      'track',
      'link',
      {
        event_name: `${podcastEpisode} - play`,
        event_category: 'content-listing',
        event_action: 'play',
        event_label: podcastEpisode || null,
        media_name: podcastTitle || null,
        media_program_episodenumber: podcastEpisode || null,
        media_program_name: collectionTitle || null,
        media_program_season: podcastEpisode || null,
      },
    ]);
  } else {
    window.Taq.push([
      'track',
      'link',
      {
        ...window.Taq.jukeCustomData,
        event_name: 'juke-player-on-demand_play',
        event_category: 'juke-player-on-demand',
        event_action: 'play',
        player_state: 'play',
        event_label: (show && show.name) || title || null,
        media_playtime: mediaPlaytime || null,
        media_duration: mediaDuration || null,
        media_playbackrate: 1, // 1 = normal play speed
        media_playbackpercentage: 100,
        media_id: (show && show.name) || title || null,
        media_genre: onDemandClip ? getMediaGenreForOnDemand(onDemandClip) : null,
        media_name: (show && show.name) || title || null,
        media_thumbnail: imageUrl || null,
        media_datepublished: publishedUtc ? convertUtcToUnix(publishedUtc) : null,
        media_program_name: onDemandClip ? getMediaIdForOnDemand(onDemandClip) : null,
        media_playtimepercentage: mediaPlaytime && mediaDuration ? mediaPlaytime / mediaDuration : null,
        media_bitrate: '64kbps',
        player_casting: isCasting ? 'chromecast' : false,
        c_media_audiotype: onDemandClip ? getMediaAudiotypeForOnDemand(onDemandClip) : null,
        content_id: collectionTitle || null,
      },
    ]);
  }
}

export function trackStopOnDemand(
  onDemandClip,
  mediaPlaytime,
  mediaDuration,
  isCasting = false,
  podcastEpisode = '',
  podcastTitle = ''
) {
  const { show, title, imageUrl, publishedUtc, collectionTitle } = onDemandClip || {};
  if (podcastEpisode && podcastTitle) {
    window.Taq.push([
      'track',
      'link',
      {
        event_name: `${podcastEpisode} - stop`,
        event_category: 'content-listing',
        event_action: 'stop',
        event_label: podcastEpisode || null,
        media_name: podcastTitle || null,
        media_program_episodenumber: podcastEpisode || null,
        media_program_name: collectionTitle || null,
        media_program_season: podcastEpisode || null,
      },
    ]);
  } else {
    window.Taq.push([
      'track',
      'link',
      {
        ...window.Taq.jukeCustomData,
        event_name: 'juke-player-on-demand_stop',
        event_category: 'juke-player-on-demand',
        event_action: 'stop',
        player_state: 'stop',
        media_playtime: mediaPlaytime || null,
        media_duration: mediaDuration || null,
        media_playbackrate: 1, // 1 = normal play speed
        media_playbackpercentage: 100,
        event_label: (show && show.name) || title || null,
        media_id: (show && show.name) || title || null,
        media_genre: onDemandClip ? getMediaGenreForOnDemand(onDemandClip) : null,
        media_name: (show && show.name) || title || null,
        media_thumbnail: imageUrl || null,
        media_datepublished: publishedUtc ? convertUtcToUnix(publishedUtc) : null,
        media_program_name: onDemandClip ? getMediaIdForOnDemand(onDemandClip) : null,
        media_playtimepercentage: mediaPlaytime && mediaDuration ? mediaPlaytime / mediaDuration : null,
        media_bitrate: '64kbps',
        player_casting: isCasting ? 'chromecast' : false,
        c_media_audiotype: onDemandClip ? getMediaAudiotypeForOnDemand(onDemandClip) : null,
        content_id: collectionTitle || null,
      },
    ]);
  }
}

export function trackResumeOnDemand(onDemandClip, mediaPlaytime, mediaDuration, isCasting = false) {
  const { show, title, imageUrl, publishedUtc, collectionTitle } = onDemandClip || {};
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'juke-player-on-demand_resume',
      event_category: 'juke-player-on-demand',
      event_action: 'resume',
      player_state: 'resume',
      media_playtime: mediaPlaytime || null,
      media_duration: mediaDuration || null,
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      event_label: (show && show.name) || title || null,
      media_id: (show && show.name) || title,
      media_genre: onDemandClip ? getMediaGenreForOnDemand(onDemandClip) : null,
      media_name: (onDemandClip.show && onDemandClip.show.name) || onDemandClip.title,
      media_thumbnail: imageUrl || null,
      media_datepublished: publishedUtc ? convertUtcToUnix(publishedUtc) : null,
      media_program_name: onDemandClip ? getMediaIdForOnDemand(onDemandClip) : null,
      media_bitrate: '64kbps',
      player_casting: isCasting ? 'chromecast' : false,
      c_media_audiotype: onDemandClip ? getMediaAudiotypeForOnDemand(onDemandClip) : null,
      content_id: collectionTitle || null,
    },
  ]);
}

export function trackSkipBackwardOnDemand(skipTime, onDemandClip, mediaPlaytime, mediaDuration, isCasting = false) {
  const { show, title, imageUrl, publishedUtc } = onDemandClip || {};

  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'juke-player-on-demand_skip-backward',
      event_category: 'juke-player-on-demand',
      event_action: 'skip-backward',
      event_label: (show && show.name) || title || null,
      event_value: skipTime || null,
      media_playtime: mediaPlaytime || null,
      media_duration: mediaDuration || null,
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_id: (show && show.name) || title,
      media_genre: onDemandClip ? getMediaGenreForOnDemand(onDemandClip) : null,
      media_name: (show && show.name) || title || null,
      media_thumbnail: imageUrl || null,
      media_datepublished: publishedUtc ? convertUtcToUnix(publishedUtc) : null,
      media_program_name: onDemandClip ? getMediaIdForOnDemand(onDemandClip) : null,
      media_playtimepercentage: mediaPlaytime && mediaDuration ? mediaPlaytime / mediaDuration : null,
      media_bitrate: '64kbps',
      player_casting: isCasting ? 'chromecast' : false,
      c_media_audiotype: onDemandClip ? getMediaAudiotypeForOnDemand(onDemandClip) : null,
    },
  ]);
}

export function trackSkipForwardOnDemand(skipTime, onDemandClip, mediaPlaytime, mediaDuration, isCasting = false) {
  const { show, title, imageUrl, publishedUtc } = onDemandClip || {};

  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'juke-player-on-demand_skip-forward',
      event_category: 'juke-player-on-demand',
      event_action: 'skip-forward',
      event_label: (show && show.name) || title || null,
      event_value: skipTime || null,
      media_playtime: mediaPlaytime || null,
      media_duration: mediaDuration || null,
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_id: (show && show.name) || title,
      media_genre: onDemandClip ? getMediaGenreForOnDemand(onDemandClip) : null,
      media_name: (show && show.name) || onDemandClip.title || null,
      media_thumbnail: imageUrl || null,
      media_datepublished: publishedUtc ? convertUtcToUnix(publishedUtc) : null,
      media_program_name: onDemandClip ? getMediaIdForOnDemand(onDemandClip) : null,
      media_playtimepercentage: mediaPlaytime && mediaDuration ? mediaPlaytime / mediaDuration : null,
      media_bitrate: '64kbps',
      player_casting: isCasting ? 'chromecast' : false,
      c_media_audiotype: onDemandClip ? getMediaAudiotypeForOnDemand(onDemandClip) : null,
    },
  ]);
}

export function trackSkipToOnDemand(skipTime, onDemandClip, mediaPlaytime, mediaDuration, isCasting = false) {
  const { show, title, imageUrl, publishedUtc } = onDemandClip || {};
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'juke-player-on-demand_scrubber',
      event_category: 'juke-player-on-demand',
      event_action: 'scrubber',
      event_label: (show && show.name) || title || null,
      event_value: skipTime || null,
      media_playtime: mediaPlaytime || null,
      media_duration: mediaDuration || null,
      media_id: (show && show.name) || title || null,
      media_genre: onDemandClip ? getMediaGenreForOnDemand(onDemandClip) : null,
      media_name: (show && show.name) || title || null,
      media_thumbnail: imageUrl || null,
      media_datepublished: publishedUtc ? convertUtcToUnix(publishedUtc) : null,
      media_program_name: onDemandClip ? getMediaIdForOnDemand(onDemandClip) : null,
      media_playtimepercentage: mediaPlaytime && mediaDuration ? mediaPlaytime / mediaDuration : null,
      media_bitrate: '64kbps',
      player_casting: isCasting ? 'chromecast' : false,
      c_media_audiotype: onDemandClip ? getMediaAudiotypeForOnDemand(onDemandClip) : null,
    },
  ]);
}

export function trackPlayClipsInProgress(podcastName, podcastEpisode) {
  window.Taq.push([
    'track',
    'link',
    {
      content_category: 'verder luisteren',
      event_category: 'player',
      event_action: 'play',
      media_audiotype: 'podcast',
      media_name: podcastName,
      media_program_episode: podcastEpisode,
    },
  ]);
}
