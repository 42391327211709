import { modalActions } from '../../actions/actions-const';

const initialState = {
  defaultIsOpen: false,
  standaloneIsOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case modalActions.SET_MODAL_OPEN:
      return { ...state, defaultIsOpen: true };

    case modalActions.SET_MODAL_CLOSE:
      return { ...state, defaultIsOpen: false };

    case modalActions.SET_MODAL_STANDALONE_OPEN:
      return { ...state, standaloneIsOpen: true };

    case modalActions.SET_MODAL_STANDALONE_CLOSE:
      return { ...state, standaloneIsOpen: false };

    default:
      return state;
  }
}

/**
 * Given the current state, it returns whether or not modal is open
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getModalOpen = (state) => state.modal.defaultIsOpen;

/**
 * Given the current state, it returns whether or not standalone modal is open
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getModalStandaloneOpen = (state) => state.modal.standaloneIsOpen;
