import { TILES_WITH_BACKGROUND_GUTTER } from './Collection.const';
import * as cssVariable from 'cssVariables';
import { Styles } from 'jss';
import { StyleTheme } from 'components/theme/StyleTheme';

export default (theme: StyleTheme): Styles => ({
  highlight: {
    backgroundColor: theme.palette.navigation.background,
    margin: '20px -40px',
    padding: '20px 40px 27px',
  },
  tile: {
    position: 'relative',
    display: 'block',
    width: '100%',
    height: 62,
    backgroundColor: 'red',
  },
  container: {
    position: 'relative',
    '& + &': {
      marginTop: 40,
    },
  },

  containerBackground: {
    margin: `0 -${TILES_WITH_BACKGROUND_GUTTER}px`,
    backgroundColor: cssVariable.grey100,
    padding: TILES_WITH_BACKGROUND_GUTTER,
    borderRadius: 2,
    '& $loadingAnimation': {
      background:
        'linear-gradient(to right, rgba(244, 244, 248, 0) 46%, rgba(244, 244, 248, 0.35) 50%, rgba(244, 244, 248, 0) 54%) 50% 50%',
    },
  },
  carouselContainer: {
    '& + &': {
      marginTop: 10,
    },
  },
  '@keyframes loadingAnimation': {
    '0%': {
      transform: 'translate3d(-30%, 0, 0)',
    },
    '100%': {
      transform: 'translate3d(30%, 0, 0)',
    },
  },
  collectionWrapper: {
    position: 'relative',
  },
  promotionContainerTouch: {
    marginBottom: 15,
  },
  loadingAnimation: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: '50%',
    zIndex: 1,
    width: '500%',
    marginLeft: '-250%',
    animation: '$loadingAnimation 0.8s linear infinite',
    background: `linear-gradient(to right, ${cssVariable.hexToRgba(
      theme.palette.background,
      0
    )} 46%, ${cssVariable.hexToRgba(theme.palette.background, 0.35)} 50%, ${cssVariable.hexToRgba(
      theme.palette.background,
      0
    )} 54%) 50% 50%`,
  },
  titleWrap: {
    display: 'flex',
  },
  title: {
    margin: 0,
    fontWeight: cssVariable.fontWeights.bold,
    height: 25,
    fontSize: cssVariable.fontSizes.medium,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },
  sectionTitle: {
    fontSize: cssVariable.fontSizes.large,
    marginBottom: 24,
  },
  titleSmall: {
    fontSize: cssVariable.fontSizes.small,
  },
  count: {
    fontWeight: cssVariable.fontWeights.regular,
    color: cssVariable.grey500,
  },
  countBlue: {
    fontSize: cssVariable.fontSizes.xsmall,
    fontWeight: cssVariable.fontWeights.bold,
    color: cssVariable.blue500,
    textTransform: 'lowercase',
    letterSpacing: cssVariable.letterSpacing.small,
  },
  betaText: {
    fontWeight: cssVariable.fontWeights.regular,
    fontSize: cssVariable.fontSizes.xsmall,
    color: cssVariable.grey500,
    textTransform: 'none',
  },
  feedbackLink: {
    margin: 0,
    padding: 0,
    border: 0,
    background: 'none',
    color: cssVariable.blue500,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: cssVariable.fontSizes.small,
    lineHeight: 1.375,
    maxWidth: 'calc(100% - 180px)',
    margin: [2, 0, 0, 2],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  hideHeader: {
    visibility: 'hidden',
  },
  [theme.breakpoints.down('sm')]: {
    titleWrapMobile: {
      justifyContent: 'center',
    },
    title: {
      fontSize: cssVariable.fontSizes.small,
    },
    description: {
      display: 'none',
    },
  },
});
