import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectStyles } from 'injectStyles';

import * as cssVariable from 'cssVariables';
import PlayIcon from 'components/svg/Play';
import SpeakerIcon from 'components/svg/Speaker';

class IsPlayingOverlay extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isPlayingWrapperClass: PropTypes.string,
    isActive: PropTypes.bool,
    isPlaying: PropTypes.bool,
  };

  static defaultProps = {
    isPlayingWrapperClass: '',
    isActive: false,
    isPlaying: false,
  };

  render() {
    const { classes, isActive, isPlayingWrapperClass, isPlaying } = this.props;

    return (
      <div data-testid="is-playing-overlay" className={classNames(classes.wrapper, isPlayingWrapperClass)}>
        <button
          type="button"
          className={classNames(classes.button, {
            [classes.buttonActive]: isPlaying || isActive,
          })}
        >
          {!isPlaying && <PlayIcon filled={isActive} />}
          {isPlaying && <SpeakerIcon volumeLevel={100} />}
        </button>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    transform: 'scale(1) perspective(1px)',
    zIndex: 3,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: (props) => props.size || 55,
    height: (props) => props.size || 55,
    padding: 0,
    color: cssVariable.white,
    backgroundColor: cssVariable.transparentLightmode040,
    borderRadius: '50%',
    border: 'none',
    '& > svg': {
      fill: 'none',
      stroke: '#FFF',
      width: '100%',
      height: '100%',
    },
  },
  buttonActive: {
    backgroundColor: cssVariable.green500,
  },
};

export default injectStyles(styles)(IsPlayingOverlay);
