import { connect } from 'react-redux';
import { getBrowser } from 'store/reducers/browser';
import { getForceMiniPlayer, getPlaySessionId } from 'store/reducers/player';

const mapStateToProps = (state) => ({
  browser: getBrowser(state),
  forceMiniPlayer: getForceMiniPlayer(state),
  hasPlaySession: !!getPlaySessionId(state),
});

export default connect(mapStateToProps);
