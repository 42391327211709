import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { cleanObject } from 'util/index';
import { useFetchData } from 'components/hooks';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { STATION_PAGE_SETTINGS } from 'globalConst/const';
import { getIsPlaying } from 'store/reducers/player';
import { loadStation } from 'actions/player-actions';
import { CampaignSchema } from 'components/Schema';

import NotFoundPage from 'components/pages/status/NotFoundPage';
import ContentPageLayout from 'components/ContentPageLayout';
import PageHeader from 'components/PageHeader';
import LoadingPage from 'components/pages/status/LoadingPage';
import PageContent from 'components/PageContent';
import IFrame from 'components/IFrame';

function CampaignPage({ match: { params: { slug, campaignSlug } = {} } }) {
  const dispatch = useDispatch();
  const isPlaying = useSelector(getIsPlaying);
  const { data, isLoading, error } = useFetchData({
    slug,
    contentTypeId: CONTENT_TYPES.STATION_PAGE,
  });

  const { data: campaignData, isLoading: isCampaignLoading } = useFetchData({
    slug: campaignSlug,
    contentTypeId: CONTENT_TYPES.CAMPAIGN,
  });

  const { station, overview } = data || {};
  const { metadata, brandSlug, brandName, uri } = campaignData || {};

  useEffect(() => {
    if (!isPlaying && station) {
      dispatch(loadStation(station, false));
    }
  }, [station]);

  return (
    <ContentPageLayout>
      {error || (!isCampaignLoading && (!uri || slug !== brandSlug)) || (!isLoading && !station) ? (
        <NotFoundPage />
      ) : (
        <Fragment>
          <PageHeader
            station={
              isLoading
                ? { isComponentLoading: true }
                : {
                    ...overview,
                    ...cleanObject(station),
                    ...cleanObject(metadata),
                  }
            }
            page={STATION_PAGE_SETTINGS}
            isStationPage
            metadata={metadata || null}
          />
          {isCampaignLoading ? (
            <div style={{ position: 'relative' }}>
              <LoadingPage />
            </div>
          ) : (
            <PageContent>
              <IFrame
                data-testid="campaign-page-iframe"
                source={uri}
                defaultHeight={600}
                mobileHeight={600}
                defaultMaxWidth={1024}
              />
              {metadata && <CampaignSchema {...metadata} keywords={brandName} />}
            </PageContent>
          )}
        </Fragment>
      )}
    </ContentPageLayout>
  );
}

CampaignPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default CampaignPage;
