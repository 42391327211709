import React from 'react';
import PropTypes from 'prop-types';

import VolumeSlider from './VolumeSlider';
import SpeakerIcon from 'components/svg/Speaker';
import PlayerButton from '../PlayerButton';
import styles from './PlayerVolume.styles';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(styles, { name: 'PlayerVolume' });

const PlayerVolume = ({ volumeLevel }) => {
  const classes = useStyles();
  const renderPopout = () => {
    return <VolumeSlider />;
  };

  const renderIcon = () => {
    return (
      <div className={classes.speakerIcon}>
        <SpeakerIcon volumeLevel={volumeLevel} />
      </div>
    );
  };

  return (
    <PlayerButton
      data-testid="player-bottom-volume-btn"
      hasPopout
      popoutAbove
      renderIcon={renderIcon}
      renderPopout={renderPopout}
      title="Volume"
    />
  );
};

PlayerVolume.propTypes = {
  volumeLevel: PropTypes.number.isRequired,
};

export default PlayerVolume;
