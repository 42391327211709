import { playerActions } from './actions-const';
import { showNotification } from './notifications-actions';
import { play } from './player-actions';
import { NotificationTypes } from '../components/Notifications/Notification';

import AudioPlayerStation from 'players/AudioPlayerStation';
import AudioPlayerOnDemand from 'players/AudioPlayerOnDemand';
import VideoJWPlayer from 'players/VideoJWPlayer';
import { AppDispatch, RootState } from 'store';
import { Action } from 'redux';

export function castingStart(castingDevice: unknown) {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    if (AudioPlayerStation.isPlaying) {
      await AudioPlayerStation.stop(false);
    }
    if (AudioPlayerOnDemand.isPlaying) {
      await AudioPlayerOnDemand.stop(false);
    }
    VideoJWPlayer.stop(false);

    dispatch({ type: playerActions.PLAYER_CASTING, castingDevice });

    const {
      player: { isPlaying },
    } = getState();
    if (isPlaying) {
      dispatch(play());
    }
  };
}

export function castingStop(): Action<string> {
  return {
    type: playerActions.PLAYER_CASTING_STOPPED,
  };
}

export function castingPlaybackError(message: string) {
  return (dispatch: AppDispatch): void => {
    dispatch(
      showNotification({
        type: NotificationTypes.ERROR,
        message,
      })
    );
  };
}
