import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { SCROLL_CONTAINER_ID } from 'globalConst/const';
import { isIos, isTouch } from './device';
import { noop } from 'util/index';

/**
 * Prevent body scrolling or any other element
 * @param doScroll true when body can scroll
 */
export function setBodyScroll(doScroll) {
  // NOTE: This does lock scrolling, but the element is positioned to the top of the screen (because it's fixed).
  // The result is that the page jumps, and it looks like scroll is set to 0 again.
  // Another solution can be setting overflowY: hidden, but I'm not sure if this has any side effects
  const toggleFixed = doScroll ? 'static' : 'fixed';
  document.body.style.position = toggleFixed;
  if (isIos()) {
    const toggleOverflow = doScroll ? '' : 'hidden';
    document.body.style.overflow = toggleOverflow;
  }
}

/**
 * Lock the scrolling in the scrollContainer
 * @param scrollDisabled whether or not scrolling should be disabled
 */
export function disableScrollContainer(scrollDisabled) {
  const scrollContainer = document.getElementById(SCROLL_CONTAINER_ID);
  if (scrollDisabled) {
    const x = scrollContainer.scrollX;
    const y = scrollContainer.scrollY;
    scrollContainer.onscroll = () => scrollContainer.scrollTo(x, y);
  } else {
    scrollContainer.onscroll = noop;
  }
}

function SHS_B(e, position, horizontal) {
  if (horizontal) {
    const hasScrollLeft = typeof e.scrollLeft !== 'undefined';
    if (hasScrollLeft) {
      e.scrollLeft = position;
    } else {
      e.scrollTo(position, e.scrollY);
    }
  } else {
    const hasScrollTop = typeof e.scrollTop !== 'undefined';
    if (hasScrollTop) {
      e.scrollTop = position;
    } else {
      e.scrollTo(e.scrollX, position);
    }
  }
}

function easeOutCubic(currentIteration, startValue, changeInValue, totalIterations) {
  // eslint-disable-next-line no-restricted-properties
  return changeInValue * (Math.pow(currentIteration / totalIterations - 1, 3) + 1) + startValue;
}

/**
 * Scrolls smoothly on all browsers
 */
export function SmoothScrolling(ref, time = 200, target = 0, horizontal = true) {
  if (!ref) return;

  let start = typeof ref.scrollTop !== 'undefined' ? ref.scrollTop : ref.scrollY;
  if (horizontal) {
    start = typeof ref.scrollLeft !== 'undefined' ? ref.scrollLeft : ref.scrollX;
  }
  const amount = target - start;
  let curTime = 0;
  while (curTime <= time) {
    const position = easeOutCubic(curTime, start, amount, time);
    setTimeout(SHS_B, curTime, ref, position, horizontal);
    curTime += time / 100;
  }
}

/**
 * Scroll the distant vertically of the current position
 */
export function scrollY(distance, time = 500) {
  // use body scroll for mobile, else use nested scroll
  const scrollContainer = isTouch ? window : document.getElementById(SCROLL_CONTAINER_ID);
  const y =
    (typeof scrollContainer.scrollTop !== 'undefined' ? scrollContainer.scrollTop : scrollContainer.scrollY) + distance;
  SmoothScrolling(scrollContainer, time, y, false);
}

function handleScroll() {
  if (this.scrollTop === 0) {
    setTimeout(() => {
      this.scrollTop = 1;
    }, 0);
  }

  if (this.scrollTop + this.clientHeight === this.scrollHeight) {
    setTimeout(() => {
      this.scrollTop = this.scrollTop - 1;
    }, 0);
  }
}

/**
 * Prevent body scrolling when using a scrollable modal dialog
 * @param doPrevent true to prevent body scrolling
 * @param scrollEl Scrollable modal DOM element
 */
export function preventBodyScrolling(doPrevent, scrollEl) {
  if (scrollEl && !isIos()) {
    if (doPrevent) {
      disableBodyScroll(scrollEl);
    } else {
      enableBodyScroll(scrollEl);
    }
    handleScroll.call(scrollEl);
  }
}
