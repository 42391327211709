import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import styles from './Playouts.styles';

import graphql from 'api/graphql';
import removeDuplicates from 'util/removeDuplicates';
import Loader from 'components/Loader';

import withGraphQL from 'components/hoc/withGraphqlData';
import PlayoutHistory from './PlayoutHistory';
import logger from 'util/logger';

export class Playouts extends PureComponent {
  static defaultProps = {
    slug: null,
    playouts: null,
    isComponentLoading: false,
  };

  static propTypes = {
    slug: PropTypes.string,
    playouts: PropTypes.array,
    isComponentLoading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
  };

  state = {
    playouts: [],
  };

  componentMounted = false;

  componentDidMount() {
    this.componentMounted = true;
    const { slug } = this.props;
    if (slug) {
      this.observePlayouts(slug);
    }
  }

  componentDidUpdate(prevProps) {
    const { playouts, slug } = this.props;
    if (prevProps.playouts !== playouts) {
      this.playoutChangeState(playouts);
    }
    if (slug !== prevProps.slug) {
      this.observePlayouts(slug);
    }
  }

  componentWillUnmount() {
    this.componentMounted = false;
    this.clearScheduleConnections();
  }

  playoutChangeState = (playouts) => this.setState({ playouts });

  clearScheduleConnections = () => {
    if (this.client?._state !== 'closed') this.client?.unsubscribe();
    if (this.reconnectTimer) clearTimeout(this.reconnectTimer);
  };

  handleScheduleUpdates = (playouts) => {
    if (playouts) {
      this.setState((prevState) => ({
        playouts: removeDuplicates([...playouts, ...prevState.playouts], 'id'),
      }));
    }
  };

  handleScheduleErrors = (error) => {
    if (this.componentMounted) {
      logger.error(`Connection Error: ${error.message}, reconnect in 60 sec`);
      this.reconnectTimer = setTimeout(this.observePlayouts, 60000);
    }
  };

  observePlayouts = async () => {
    const { slug } = this.props;
    this.clearScheduleConnections();
    if (slug) {
      this.client = await graphql.subscribeToPlayoutEvents(slug, this.handleScheduleUpdates, this.handleScheduleErrors);
    }
  };

  render() {
    const { playouts } = this.state;
    const { isComponentLoading, classes } = this.props;
    return (
      <Fragment>
        {isComponentLoading && (
          <div className={classes.loaderContainer}>
            <Loader data-testid="playouts-loader" size={25} />
          </div>
        )}
        <PlayoutHistory playouts={playouts} isLoading={isComponentLoading} />
      </Fragment>
    );
  }
}

export default injectStyles(styles)(withGraphQL(Playouts));
