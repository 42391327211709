import * as cssVariable from 'cssVariables';

export default (theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
  },
  button: {
    height: 40,
    borderRadius: 4,
    border: `1px solid ${cssVariable.green500}`,
    color: cssVariable.green500,
    backgroundColor: cssVariable.white,
    padding: '0 40px',
    lineHeight: '38px',
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: cssVariable.green500,
      color: cssVariable.white,
      boxShadow: `0 1px 4px 0 ${cssVariable.transparentLightmode010}, 0 5px 10px 0 ${cssVariable.transparentLightmode010}`,
    },
    '&:active': {
      backgroundColor: cssVariable.green600,
    },
    // we gotta have this since there's the global focus style that removes border, dunno why
    '&:focus': {
      border: `1px solid ${cssVariable.green500}`,
    },
  },
  buttonText: {
    fontSize: cssVariable.fontSizes.xsmall,
    fontWeight: cssVariable.fontWeights.bold,
  },
  title: {
    margin: 0,
    color: theme.palette.text.primary,
    fontSize: cssVariable.fontSizes.medium,
    lineHeight: 1,
  },
  mixedContentWrapper: {
    display: 'inline-block',

    '&:nth-child(1) > div > a': {
      paddingLeft: 0,
    },
  },
  // fix for 16:9 smartphones, to avoid overlaping of footer and mini player
  [`@media (max-height: 610px) and (max-width: ${theme.breakpoints.values.xs}px)`]: {
    container: {
      top: 70,
    },
  },
  // hide image if height is very small
  '@media (max-height: 410px)': {
    radio: {
      display: 'none',
    },
    // depending on layout type (mobile od desktop), move text
    [theme.breakpoints.down('md')]: {
      container: {
        top: 90,
      },
    },
    [theme.breakpoints.up('md')]: {
      container: {
        top: 140,
      },
    },
  },
});
