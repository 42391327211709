import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { supportedRoutes, supportedChildRoutes } from 'components/Search/searchDesignRoutes';
import { isTouch } from 'util/device';
import { styles } from './Title.styles';

interface TitleProps {
  gradientHeader: boolean;
  hideTitle: boolean;
  title: string;
  url: string;
  path: string;
}

Title.defaultProps = {
  gradientHeader: false,
  hideTitle: false,
  title: null,
  url: null,
  path: null,
};

Title.propTypes = {
  classes: PropTypes.object.isRequired,
  gradientHeader: PropTypes.bool,
  hideTitle: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  path: PropTypes.string,
};

const useStyles = createUseStyles(styles, { name: 'Title' });
function Title({ gradientHeader, hideTitle, title, url, path }: TitleProps): JSX.Element {
  const classes = useStyles();
  return (
    <h1
      className={classNames(classes.title, {
        [classes.titleIsHidden]: hideTitle,
        [classes.fullWidth]: supportedRoutes.indexOf(url) >= 0 || supportedChildRoutes.indexOf(path) >= 0 || isTouch,
        [classes.smallTitle]: gradientHeader && isTouch,
        [classes.gradientHeaderDesktop]: gradientHeader && !isTouch,
        [classes.gradientHeader]: gradientHeader,
      })}
    >
      {title}
    </h1>
  );
}

export default Title;
