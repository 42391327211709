import { connect } from 'react-redux';

import withBrowserState from 'components/hoc/withBrowserState';
import { forceMiniPlayerState } from 'actions/player-actions';

import FeedbackCompletePage from './FeedbackCompletePage';

const mapDispatchToProps = {
  forceMiniPlayer: forceMiniPlayerState,
};

const hocFeedbackCompletePage = withBrowserState(FeedbackCompletePage);

export default connect(null, mapDispatchToProps)(hocFeedbackCompletePage);
