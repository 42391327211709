import { isPopoutPlayer } from 'globalConst/const';
import { isMobile as getIsMobile, isSafari, isIE11andLower, checkForRetinaScreen, isMobile } from './device';

const SUB_QUERY_STRING = 'fm=jpg&q=85&fl=progressive';
const SUB_QUERY_STRING_QUALITY = 'q=90';

export function getURLChainingParameter(string) {
  return string && string.indexOf('?') === -1 ? '?' : '&';
}

export function getResamplingQuery(isMobile, smallThumb) {
  if (smallThumb) {
    return `w=80&${SUB_QUERY_STRING}`;
  }

  if (isMobile) {
    return `w=140&${SUB_QUERY_STRING}`;
  }

  return `w=250&${SUB_QUERY_STRING}`;
}

export function getLandscapeResamplingQuery(isMobile) {
  if (isMobile) {
    return `w=200&${SUB_QUERY_STRING}`;
  }

  return `w=350&${SUB_QUERY_STRING}`;
}

export function getHeaderResamplingQuery(isMobile) {
  if (typeof isMobile === 'undefined') {
    isMobile = getIsMobile;
  }
  if (isMobile) {
    return `w=750&${SUB_QUERY_STRING}`;
  }

  return `w=1280&${SUB_QUERY_STRING}`;
}

function getImageType() {
  // check for browsers that don't support webp
  if (isIE11andLower || isSafari || getIsMobile) {
    return 'fm=png';
  }

  return 'fm=webp';
}

/**
 * Returns image url with resampling query
 * @param {object}  imageInfo - information about image
 * @param {string=}  imageInfo.url - url of image
 * @param {boolean=} imageInfo.isSquare - wheather or not image is square
 * @param {boolean=} imageInfo.isLandcape - wheather or not image is landscape
 * @param {boolean=} imageInfo.isHeader - wheather or not image is a header
 * @param {boolean=} imageInfo.isTransparent - wheather or not image is transparant
 * @param {boolean=} imageInfo.isThumbnail - wheather or not image is a thumpnail
 * @param {number=}  imageInfo.customWidth - custom width of the image
 * @param {number=}  imageInfo.customHeight - custom height of the image
 * @returns {string}
 */
export function getImageWithResamplingQuery({
  url,
  isSquare = false,
  isLandcape = false,
  isHeader = false,
  isTransparent = false,
  isThumbnail = false,
  customWidth = null,
  customHeight = null,
  omitQuality = false,
  omitType = false,
  retinaResize = true,
} = {}) {
  if (!url) {
    return null;
  }

  const smallScreen = (isMobile || window.innerWidth < 1024) && !isPopoutPlayer;
  const type = isTransparent ? getImageType() : 'fm=jpg';

  let width = smallScreen ? 140 : 250;
  let height = null;

  const isRetina = checkForRetinaScreen();

  if (isLandcape) {
    width = smallScreen ? 200 : 350;
  }
  if (isHeader) {
    width = smallScreen ? 750 : 1280;
    height = smallScreen ? 290 : 380;
  }
  if (isThumbnail) {
    width = 40;
  }
  if (isSquare) {
    height = width;
  }

  if (isRetina && retinaResize) {
    width = customWidth * 2 || width * 2;
    height = customHeight * 2 || height * 2;
  } else {
    width = customWidth || width;
    height = customHeight || height;
  }

  let query = `w=${width}`;
  if (!omitQuality) {
    query += `&${SUB_QUERY_STRING_QUALITY}`;
  }
  if (!omitType) {
    query += `&${type}`;
  }

  if (height) {
    query = `${query}&h=${height}`;
  }
  if (url.indexOf('?') === -1) {
    return `${url}?${query}`;
  }
  return `${url}&${query}`;
}
