import HttpError from 'lib/HttpError';

function throwOnError(response) {
  if (!response.ok) {
    throw new HttpError(response.status, response.statusText);
  }
}

/**
 * Get rating for jukeApp
 */
export async function fetchAppRating() {
  const response = await fetch(process.env.REACT_APP_RATING_SCRAPPER_ENDPOINT);
  throwOnError(response);
  return response.json();
}
