import { isTouch } from 'util/device';
import { gutter } from 'cssVariables';

export const DisplayLayout = {
  LIST: 'list',
  TILES: 'tiles',
  TILES_TITLE_BELOW: 'tiles title below',
  TILES_WITH_BACKGROUND: 'tiles with background',
  LIST_NOW_PLAYING_FULL: 'list now playing full width',
  LIST_NOW_PLAYING_HALF: 'list now playing half width',
  CAROUSEL: 'carousel',
  CAROUSEL_TWO_ROWS: 'carousel with two rows',
  CAROUSEL_WITH_FOLD: 'carousel with fold',
  TILES_LOGO: 'tiles logo',

  GRID: 'grid',
  SWIMLANE: 'swimlane',
};

export const TILES_WITH_BACKGROUND_GUTTER = isTouch ? gutter * 2 : 30;

export const DEFAULT_ITEMS_PER_ROW = 4;
