import React from 'react';
import ContentTypeComponent from '../ContentTypeComponent';
import { isEmpty, contains } from 'util/index';
import { Section } from 'api/models';
import LoadingPage from 'components/pages/status/LoadingPage';
import PromotionEmpty from 'components/PromotionEmpty';
import FlatList from 'flatlist-react';

interface OverviewProps {
  sections: Section[];
  isComponentLoading: undefined; // if its not wrapped with graphqlData it will be undefined
}

const Overview = ({ sections, isComponentLoading }: OverviewProps): JSX.Element | null => {
  if (isComponentLoading === true) {
    return <LoadingPage />;
  }

  const isPromotionPage = contains(window.location.href, 'acties');

  if (isComponentLoading === false && isEmpty(sections) && isPromotionPage) {
    return <PromotionEmpty />;
  }

  const renderSection = (section: Section) => (
    <ContentTypeComponent
      key={section?.uuid || section?.slug || section?.uuid || section?.contentTypeId}
      {...section}
    />
  );

  return <FlatList list={sections} renderWhenEmpty={() => <></>} renderItem={renderSection} />;
};

export default Overview;
