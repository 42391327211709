import { connect } from 'react-redux';
import { getCurrentPage } from 'store/reducers/content';

import LinkContentPage from './LinkContentPage';

const mapStateToProps = (state) => ({
  page: getCurrentPage(state),
});

export default connect(mapStateToProps)(LinkContentPage);
