import { connect } from 'react-redux';
import { withAnalytics } from 'analytics';
import { loadStation, stop } from 'actions/player-actions';
import { getIsCurrentStationPlaying } from 'store/reducers/player';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import CallToAction from './CallToAction';

const mapStateToProps = (state, ownProps) => {
  return {
    isPlaying: getIsCurrentStationPlaying(state, {
      station: { slug: ownProps.playStationSlug },
    }),
  };
};

const loadStationWithAnalytics = withAnalytics(loadStation, {
  player_name: CONTENT_TYPES.CALL_TO_ACTION_BLOCK,
  link_id: CONTENT_TYPES.CALL_TO_ACTION_BLOCK,
});

const stopStationWithAnalytics = withAnalytics(stop, {
  player_name: CONTENT_TYPES.CALL_TO_ACTION_BLOCK,
  link_id: CONTENT_TYPES.CALL_TO_ACTION_BLOCK,
});

const mapDispatchToProps = {
  onLoad: loadStationWithAnalytics,
  onStop: stopStationWithAnalytics,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallToAction);
