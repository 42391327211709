import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { injectStyles } from 'injectStyles';
import classNames from 'classnames';

import { gutter, white, blue500, red500 } from 'cssVariables';
import { NotificationTypes } from '.';

const NOTIFICATION_DURATION = 3000;

class Notification extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClearNotification: PropTypes.func.isRequired,
    type: PropTypes.string,
    message: PropTypes.string,
  };

  static defaultProps = {
    type: null,
    message: null,
  };

  componentDidMount() {
    if (this.props.type !== NotificationTypes.STICKY) {
      setTimeout(this.props.onClearNotification, NOTIFICATION_DURATION);
    }
  }

  render() {
    const { classes, type, message, onClearNotification } = this.props;

    const isSticky = type === NotificationTypes.STICKY;

    const containerClass = classNames(classes.container, {
      [classes.containerError]: type === NotificationTypes.ERROR,
      [classes.autoHide]: type !== NotificationTypes.STICKY,
    });

    return (
      <div className={containerClass} data-testid="notification">
        {isSticky ? (
          <button
            data-testid="clearNotification"
            type="button"
            onClick={onClearNotification}
            className={classes.close}
          />
        ) : null}
        <p className={classes.noMargin}>{message}</p>
      </div>
    );
  }
}

const styles = {
  '@keyframes appearDisappearAnimation': {
    '0%': {
      animationTimingFunction: 'ease',
      transform: 'translateY(30px)',
      opacity: 0,
    },
    '8%': {
      animationTimingFunction: 'linear',
      transform: 'none',
      opacity: 1,
    },
    '92%': {
      animationTimingFunction: 'ease',
      transform: 'none',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(30px)',
      opacity: 0,
    },
  },
  container: {
    width: '100%',
    padding: gutter,
    borderRadius: 3,
    color: white,
    textAlign: 'center',
    marginBottom: gutter / 2,
    backgroundColor: blue500,
  },
  autoHide: {
    animation: `$appearDisappearAnimation ${NOTIFICATION_DURATION}ms linear`,
  },
  containerError: {
    backgroundColor: red500,
  },
  close: {
    position: 'absolute',
    right: 32,
    top: 32,
    width: 32,
    height: 32,
    background: 'none',
    border: 'none',

    '&:before, &:after': {
      position: 'absolute',
      left: 15,
      top: '25%',
      content: '""',
      height: 16,
      width: 4,
      backgroundColor: '#fff',
      borderRadius: 10,
    },
    '&:before': {
      transform: 'rotate(45deg)',
    },
    '&:after': {
      transform: 'rotate(-45deg)',
    },
  },
  noMargin: {
    margin: 0,
  },
};

export default injectStyles(styles)(Notification);
