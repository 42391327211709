import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styles from './PlayerNowPlaying.styles';
import classNames from 'classnames';
import NowPlayingImage from './NowPlayingImage';
import { isTouch } from 'util/device';
import { isEmpty } from 'util/index';

import Query from 'components/Query';
import AirTime from 'components/Tiles/ShowTile/AirTime';
import useNowPlaying from './useNowPlaying';
import logger from 'util/logger';
import { createUseStyles } from 'react-jss';
import CustomLink from 'components/CustomLink';
import { mediaAudiotype } from 'analytics';
import playerPodcastClick from 'analytics/events/playerPodcastClick';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import { fetchPlayouts } from '../../../api/graphql/station.service';

const useStyles = createUseStyles(styles, { name: 'PlayerNowPlaying' });
const PlayerNowPlaying = ({
  slug,
  additionalFetchContentTypeId,
  contentTypeId,
  imageUrl,
  logo,
  brandColor,
  title: titleInitial,
  subtitle: subtitleInitial,
  pageUrl,
}) => {
  const classes = useStyles();
  const [{ title, subtitle }, setInfo] = useState({});

  useEffect(() => {
    if (!title && contentTypeId === 'station') {
      fetchPlayoutData(slug, titleInitial);
    } else {
      setInfo({ title: titleInitial, subtitle: subtitleInitial });
    }
  }, [slug, subtitleInitial, titleInitial]);

  const fetchPlayoutData = async (slug, titleInitial) => {
    try {
      const response = await fetchPlayouts({ slug, limit: 1 });
      const { playouts } = response;
      if (playouts[0]) {
        const { artistName: initialArtistName, title: initialSongTitle } = playouts[0];
        setInfo({ title: titleInitial, subtitle: `${initialArtistName} - ${initialSongTitle}` });
      } else {
        setInfo({ title: titleInitial, subtitle: '' });
      }
    } catch (error) {
      logger.error('Fetching playout for bottom player errored: ', error);
    }
  };

  const onPlayouts = ([{ title: playoutTitle, artistName }] = [{}]) => {
    if (playoutTitle && artistName) {
      setInfo({ title: titleInitial, subtitle: `${artistName} - ${playoutTitle}` });
    }
  };

  const onShow = ({ title: showTitle, ...show }) => {
    setInfo({
      title: showTitle,
      subtitle: getOnAirInfo(show),
    });
  };

  const onError = (e) => {
    logger.error('error', e);
  };

  useNowPlaying({ slug, isDisabled: contentTypeId !== 'station', onPlayouts, onShow, onError });

  const getOnAirInfo = ({ startTime, endTime, broadcastStartDate, broadcastEndDate }) => {
    const hasOnAirTime = startTime && endTime;
    const hasOnAirBroadcastTime = broadcastStartDate && broadcastEndDate;

    if (hasOnAirTime) {
      return `${startTime} - ${endTime}`;
    }
    if (!hasOnAirTime && hasOnAirBroadcastTime) {
      return <AirTime timeStart={broadcastStartDate} timeEnd={broadcastEndDate} />;
    }
    return null;
  };

  const sendClickEvent = (clickedElement) => {
    if (contentTypeId === CONTENT_TYPES.CLIP) {
      playerPodcastClick({
        clickedElement,
        media_name: title,
        media_program_episodenumber: null,
        media_program_name: subtitle,
        media_program_season: null,
        media_audiotype: mediaAudiotype.podcast,
      });
    }
  };

  return (
    <Query slug={slug} contentTypeId={additionalFetchContentTypeId}>
      {({ data }) => {
        const { schedules } = data || {};

        let nowTitle = title;
        let nowSubtitle = subtitle;
        if (!isEmpty(schedules)) {
          nowTitle = schedules[0].title;
          nowSubtitle = getOnAirInfo(schedules[0]);
        }

        const isClip = contentTypeId === 'clip' && !isTouch;
        const url = `/podcasts/${pageUrl}`;

        return (
          <div className={classNames(classes.container, { [classes.containerMobile]: isTouch })}>
            <CustomLink to={url} isLink={isClip} onClick={() => sendClickEvent('image')}>
              <NowPlayingImage imageUrl={imageUrl} backgroundColor={brandColor} logo={logo} isClip={isClip} />
            </CustomLink>
            <div
              className={classNames(classes.infoArea, {
                [classes.hideDesktopSmall]: !isTouch,
                [classes.infoAreaMobile]: isTouch,
              })}
            >
              <CustomLink
                className={classNames(classes.title, { [classes.link]: isClip })}
                to={url}
                isLink={isClip}
                onClick={() => sendClickEvent('title')}
              >
                <span>{nowTitle}</span>
              </CustomLink>
              <CustomLink
                className={classNames(classes.subtitle, { [classes.link]: isClip })}
                to={url}
                isLink={isClip}
                onClick={() => sendClickEvent('episode')}
              >
                <span>{nowSubtitle}</span>
              </CustomLink>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

PlayerNowPlaying.propTypes = {
  slug: PropTypes.string,
  contentTypeId: PropTypes.string,
  additionalFetchContentTypeId: PropTypes.string,

  imageUrl: PropTypes.string,
  logo: PropTypes.string,
  brandColor: PropTypes.string,

  title: PropTypes.string,
  subtitle: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  broadcastStartDate: PropTypes.string,
  broadcastEndDate: PropTypes.string,
  pageUrl: PropTypes.string,
};

PlayerNowPlaying.defaultProps = {
  slug: null,
  contentTypeId: null,
  additionalFetchContentTypeId: null,

  imageUrl: null,
  logo: null,
  brandColor: null,

  title: null,
  subtitle: null,
  pageUrl: null,
};

export default PlayerNowPlaying;
