import React from 'react';
import Schema from 'components/Schema';
import PropTypes from 'prop-types';

const PodcastEpisodeSchema = ({
  about,
  name,
  abstract,
  author,
  episodeSlug,
  image,
  keywords,
  productionCompany,
  datePublished,
  podcastSlug,
  episodeNumber,
}) => {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'PodcastEpisode',
    about,
    name,
    description: `Podcast van ${author}`,
    abstract,
    author: {
      '@type': 'Person',
      name: author,
    },
    productionCompany: {
      '@type': 'Organization',
      name: productionCompany,
    },
    datePublished,
    url: `https://juke.nl/podcasts/${podcastSlug}/${episodeSlug}`,
    image,
    keywords,
    episodeNumber,
  };
  return <Schema data={schema} />;
};

PodcastEpisodeSchema.propTypes = {
  about: PropTypes.string,
  name: PropTypes.string,
  abstract: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
  productionCompany: PropTypes.string,
  datePublished: PropTypes.string,
  episodeNumber: PropTypes.number,
  podcastSlug: PropTypes.string,
  episodeSlug: PropTypes.string,
};

PodcastEpisodeSchema.defaultProps = {
  about: null,
  name: null,
  abstract: null,
  author: null,
  image: null,
  keywords: null,
  productionCompany: null,
  datePublished: null,
  episodeNumber: null,
  podcastSlug: null,
  episodeSlug: null,
};
export default PodcastEpisodeSchema;
