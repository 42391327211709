import { navItem, navItemMobile } from '../navigationStyles';

export default (theme) => ({
  navItem: {
    ...navItem,

    borderBottom: `solid 1px ${theme.palette.navigation.border}`,
    '&:hover,&:active': {
      backgroundColor: theme.palette.navigation.background,
      color: theme.palette.navigation.colorActive,
    },
    '&.active': {
      color: theme.palette.navigation.colorActive,
      '&::before': {
        backgroundColor: theme.palette.navigation.highlight,
      },
      '& path': {
        fill: theme.palette.navigation.colorActive,
      },
    },
    '&:focus': {
      borderBottom: `solid 1px ${theme.palette.navigation.border}`,
    },
  },

  collabsibleNavItem: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  smallNavItem: {
    '&:before': {
      width: 4,
    },
  },
  staticNav: {},
  [theme.breakpoints.down('sl')]: {
    staticNav: {
      ...navItemMobile,
    },
  },
});
