import { connect } from 'react-redux';
import { withAnalytics } from 'analytics';
import { loadStation, stop } from 'actions/player-actions';
import { getIsCurrentStationPlaying } from 'store/reducers/player';
import CONTENT_TYPES from 'globalConst/contentTypes-const';
import BannerButton from './BannerButton';

const mapStateToProps = (state, ownProps) => ({
  isPlaying: getIsCurrentStationPlaying(state, {
    station: { slug: ownProps.playStationSlug },
  }),
});

const actionWithAnalytics = (action, { source, ...station }) =>
  withAnalytics(action, {
    player_name: CONTENT_TYPES.BANNER,
    link_id: CONTENT_TYPES.BANNER,
    content_id: source || null,
  })(station);

const mapDispatchToProps = {
  onLoad: actionWithAnalytics,
  onStop: actionWithAnalytics,
};

const mergeProps = ({ isPlaying }, { onLoad, onStop }, ownProps) => ({
  ...ownProps,
  isPlaying,
  onStop: () => onStop(stop, { source: ownProps.collectionTitle || null }),
  onLoad: (station) => {
    onLoad(loadStation, {
      ...station,
      source: ownProps.collectionTitle || null,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BannerButton);
