import { createResponsiveStateReducer } from 'redux-responsive';
import breakpoints from 'components/theme/breakpoints';

const jssToBrowserKeys = {
  xs: 'extraSmall',
  sm: 'small',
  md: 'smallMedium',
  sl: 'medium',
  ml: 'large',
  ll: 'extraLarge',
  lg: 'extraExtraLarge',
};

// Use JSS theme breakpoints
const browserBreakpoints = Object.keys(breakpoints.values).reduce((acc, jssKey) => {
  const browserKey = jssToBrowserKeys[jssKey];
  acc[browserKey] = breakpoints.values[jssKey];
  return acc;
}, {});

const browserReducer = createResponsiveStateReducer(browserBreakpoints, {
  extraFields: ({ lessThan, greaterThan, is }) => ({
    // Create methods which correspond to JSS theme breakpoints
    down: Object.keys(jssToBrowserKeys).reduce((acc, jssKey) => {
      const browserKey = jssToBrowserKeys[jssKey];
      return { ...acc, [jssKey]: lessThan[browserKey] || is[browserKey] };
    }, {}),
    up: Object.keys(jssToBrowserKeys).reduce((acc, jssKey) => {
      const browserKey = jssToBrowserKeys[jssKey];
      return { ...acc, [jssKey]: greaterThan[browserKey] };
    }, {}),
  }),
});

export default browserReducer;

/**
 * Given the current state, it returns the browser object
 * @typedef {Object} Browser
 * @property {object} down
 * @property {boolean} down.xs smaller than 480px
 * @property {boolean} down.sm smaller than 768px
 * @property {boolean} down.md smaller than 900px
 * @property {boolean} down.sl smaller than 1025px
 * @property {boolean} down.ml smaller than 1280px
 * @property {boolean} down.ll smaller than 1440px
 * @property {boolean} down.lg smaller than 1680px
 * @property {object} up
 * @property {boolean} up.xs larger than 480px
 * @property {boolean} up.sm larger than 768px
 * @property {boolean} up.md larger than 900px
 * @property {boolean} up.sl larger than 1025px
 * @property {boolean} up.ml larger than 1280px
 * @property {boolean} up.ll larger than 1440px
 * @property {boolean} up.lg larger than 1680px
 * @property {string} mediaType
 *
 * @param {object} state
 * @returns {Browser}
 */
export const getBrowser = (state) => state.browser;
