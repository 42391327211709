function withLock<T>(fn: () => Promise<T>): () => Promise<T> {
  let lock: Promise<void> | null = null;
  let fnResult: T;

  return async () => {
    if (!lock) {
      lock = (async () => {
        fnResult = await fn();
        lock = null;
      })();
    }

    await lock;
    return fnResult;
  };
}

export default withLock;
