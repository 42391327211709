import * as cssVariable from 'cssVariables';
import { breakpoints, mediaQuery } from 'components/theme/breakpoints';

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 25,
    marginTop: -80,
    padding: [77, 0, 0, 40],
    height: 400,
    alignItems: 'flex-start',
    justifyContent: 'center',
    position: 'relative',
    '& > *': {
      zIndex: '10',
    },
  },
  containerTouch: {
    paddingTop: 60,
  },
  containerMobile: {
    height: 200,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: [60, 0, 0, 0],
  },
  logo: {
    maxWidth: 120,
    marginRight: 40,
    marginBottom: 18,
  },
  logoMobile: {
    display: 'none',
  },
  contentContainer: {
    width: '40%',
    marginRight: 40,
    color: cssVariable.white,
  },
  contentContainerMobile: {
    width: '100%',
    maxWidth: 370,
    textAlign: 'center',
    marginRight: 0,
    padding: [0, 20],
    alignSelf: 'center',
  },
  title: {
    fontSize: cssVariable.fontSizes.xlarge2,
    margin: 0,
  },
  titleMobile: {
    fontSize: cssVariable.fontSizes.medium,
  },
  content: {
    marginTop: 0,
    fontSize: cssVariable.fontSizes.large,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    hyphens: 'none',
  },
  contentMobile: {
    fontSize: cssVariable.fontSizes.xxsmall2,
  },
  buttonContainer: {
    display: 'flex',
    '& > button:first-child ': {
      marginRight: 23,
      height: 50,
      minWidth: 180,
    },
    '& > div > div > button:last-child ': {
      height: 50,
    },
  },
  buttonContainerMobile: {
    alignSelf: 'center',
    '& > button:first-child ': {
      marginRight: 0,
      height: 40,
      minWidth: 160,
    },
    '& > div > div > button:last-child ': {
      height: 40,
    },
  },
  imageContainer: {
    zIndex: '5',
  },
  image: {
    height: '100%',
    marginLeft: 'auto',
    position: 'absolute',
    zIndex: 5,
    right: 0,
    top: 0,
    maskImage: 'linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0))',
  },
  imageTouch: {
    opacity: '0.5',
  },
  [mediaQuery.maxWidth(breakpoints.k)]: {
    contentContainer: {
      width: '50%',
    },
  },
  [mediaQuery.maxWidth(breakpoints.ll)]: {
    contentContainer: {
      width: '60%',
    },
  },
  [mediaQuery.maxWidth(breakpoints.ml)]: {
    contentContainer: {
      width: '75%',
    },
  },
};
