import React, { Fragment, useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import styles from './PodcastEpisodePageHeader.styles';
import Title from '../Title';
import { getResamplingQuery, getURLChainingParameter } from 'util/resampling';
import classNames from 'classnames';
import Button from 'components/Button';
import ButtonTypes from 'components/Button/Button.const';
import PlayIcon from 'components/svg/Play';
import PauseIcon from 'components/svg/Pause';
import { PodcastEpisode } from 'api/models';
import { getAirDateWithYear } from 'lib/onDemand';
import Duration from 'components/Tiles/OnDemandTile/Duration';
import Markdown from 'marked-react';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'util/device';
import Chevron from 'components/svg/Chevron';
import { trackPodcastEpisodePageBackButton } from 'analytics';
import { outboundClick } from 'analytics';
import SocialShare from 'components/SocialShare';
import * as cssVariable from 'cssVariables';
import { BreadcrumbsSchema, PodcastEpisodeSchema } from 'components/Schema';
import { BUTTON_SIZE, PODCASTS_PAGE_TITLE } from 'globalConst/const';

export interface PodcastEpisodePageHeaderProps {
  id: string;
  slug: string;
  imageUrl: string;
  title: string;
  podcastTitle: string;
  descriptionMarkdown: string;
  description: string;
  contentTypeId: string;
  duration: Duration;
  onPlay: (episode: PodcastEpisode, autoPlay: boolean) => void;
  onStop: () => void;
  episode: PodcastEpisode;
  isPlaying: boolean;
  publishDate: string;
  mainPodcastTitle: string;
  currentClip: PodcastEpisode;
}
interface handleAnalyticsEvent extends MouseEvent {
  currentTarget: HTMLAnchorElement;
}

const useStyles = createUseStyles(styles, { name: 'PodcastEpisodePageHeader' });

function PodcastEpisodePageHeader({
  id,
  imageUrl,
  title,
  description,
  descriptionMarkdown,
  duration,
  onPlay,
  onStop,
  episode,
  isPlaying,
  publishDate,
  mainPodcastTitle,
  currentClip,
  slug,
}: PodcastEpisodePageHeaderProps): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  const getShareDescription = () => {
    return `Luister de laatste aflevering van ${title}`;
  };

  const renderButtonText = (): string => {
    return isPlaying && currentClip && currentClip.slug === slug ? 'Pauzeer' : 'Afspelen';
  };

  const socialShareProps = {
    subject: title,
    description: getShareDescription(),
    link: window.location.href,
    color: cssVariable.grey500,
    params: { id },
    isInPodcastEpisodePage: true,
    size: BUTTON_SIZE.medium,
    media_data: {
      media_id: slug,
      media_program_name: title,
      media_name: mainPodcastTitle,
    },
  };

  const renderMobileHeader = () => {
    return (
      <Fragment>
        <div className={classes.containerMobile}>
          <div
            data-testid="podcast-episode-page-header-mobile-background-image"
            className={classes.backgroundImage}
            style={{ backgroundImage: hasImage }}
          />
          <div
            data-testid="podcast-episode-page-header-mobile-tile-image"
            className={classes.tileImage}
            style={{ backgroundImage: hasImage }}
          />
        </div>
        <div className={classes.episodeMobileInfoArea}>
          <div className={classes.dateTimeMobile}>
            {getAirDateWithYear(publishDate)} <span> - </span>
            <Duration duration={duration} />
          </div>
          <div className={classes.titleMobile}>
            <Title title={title} />
          </div>
          <h2 className={classes.mainPodcastTitleMobile}>{mainPodcastTitle}</h2>
        </div>
        <div className={classes.playButtonMobile}>
          <Button
            data-testid="podcast-episode-page-header-play-pause-button-mobile"
            iconClassName={classes.buttonIcon}
            type={ButtonTypes.PRIMARY}
            icon={isPlaying && currentClip && currentClip.slug === slug ? <PauseIcon /> : <PlayIcon filled={false} />}
            onClick={onPlayEpisode}
            text={renderButtonText()}
          />
          <div className={classNames(classes.socialShareWrapper, classes.socialShareWrapperMobile)}>
            <SocialShare {...socialShareProps} />
          </div>
        </div>
        <div className={classes.descriptionWrapperMobile}>
          <Markdown>{descriptionMarkdown || description}</Markdown>
        </div>
        <BreadcrumbsSchema level2={PODCASTS_PAGE_TITLE} level3={mainPodcastTitle} level4={title} />
        <PodcastEpisodeSchema
          about={mainPodcastTitle}
          name={title}
          abstract={description}
          episodeSlug={slug}
          podcastSlug={episode && episode?.pageUrl}
          image={imageUrl}
          author={(episode && episode?.reference?.author) || ''}
          productionCompany={(episode && episode?.reference?.publisher) || ''}
          datePublished={publishDate}
          episodeNumber={episode && episode?.episodeNumber}
          keywords={episode?.reference?.labels && episode?.reference?.labels.join()}
        />
      </Fragment>
    );
  };

  const pathname = history.location.pathname.split('/')[2];

  const onPlayEpisode = (autoplay = true) => {
    return isPlaying && currentClip && currentClip.slug === slug ? onStop() : onPlay(episode, autoplay);
  };

  useEffect(() => {
    const { search } = window.location;
    if (search?.split('=')[1] === episode?.id) {
      onPlayEpisode(false);
    }
  }, [history]);

  const onRedirect = () => {
    history.push(`/podcasts/${pathname}`);
    trackPodcastEpisodePageBackButton(title);
  };

  const hasImage = imageUrl && `url(${imageUrl}${getURLChainingParameter(imageUrl)}${getResamplingQuery()})`;
  const linkRef: React.Ref<HTMLDivElement> = useRef();

  const handleAnalytics = (event: handleAnalyticsEvent) => {
    outboundClick(event.currentTarget?.href);
  };

  useEffect(() => {
    if (linkRef.current && linkRef.current.childNodes) {
      const element = document.getElementById('markdown');
      const linkArray = Array.from(element.getElementsByTagName('a'));
      linkArray.forEach((link) => {
        link.addEventListener('click', handleAnalytics);
      });
    }
  }, [linkRef]);

  const renderDesktopHeader = () => {
    return (
      <Fragment>
        <div className={classes.headerContent}>
          <div className={classes.wrapper}>
            <div className={classes.flex}>
              <div className={classes.flex2}>
                <Button
                  data-testid="podcast-episode-page-header-redirect-button"
                  iconClassName={classes.backButtonIcon}
                  icon={<Chevron />}
                  type={ButtonTypes.SECONDARY}
                  onClick={onRedirect}
                  text="TERUG"
                />
                <div className={classes.title}>
                  <Title title={title} />
                </div>
              </div>
              <div className={classes.flex2}>
                <div style={{ width: 'auto' }}>
                  <div
                    data-testid="podcast-episode-page-header-background-image"
                    className={classes.image}
                    style={{ backgroundImage: hasImage }}
                  />
                </div>
                <div className={classes.podcastHeaderInfo}>
                  <div className={classNames(classes.flex2, classes.actionsHolder)}>
                    <Button
                      data-testid="podcast-episode-page-header-play-pause-button"
                      className={classes.playPauseButton}
                      iconClassName={classes.buttonIcon}
                      type={ButtonTypes.PRIMARY}
                      icon={
                        isPlaying && currentClip && currentClip.slug === slug ? (
                          <PauseIcon />
                        ) : (
                          <PlayIcon filled={false} />
                        )
                      }
                      onClick={onPlayEpisode}
                      text={renderButtonText()}
                    />
                    <div className={classes.socialShareWrapper}>
                      <SocialShare {...socialShareProps} />
                    </div>
                  </div>
                  <h2 className={classes.mainPodcastTitle}>{mainPodcastTitle}</h2>
                  <div className={classes.dateTime}>
                    {getAirDateWithYear(publishDate)} <span> &nbsp; • &nbsp;</span>
                    <Duration duration={duration} />
                  </div>
                  <div ref={linkRef} id="markdown" className={classes.descriptionWrapper}>
                    <Markdown value={descriptionMarkdown || description} breaks={true} gfm={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BreadcrumbsSchema level2={PODCASTS_PAGE_TITLE} level3={mainPodcastTitle} level4={title} />
        <PodcastEpisodeSchema
          about={mainPodcastTitle}
          name={title}
          abstract={description}
          episodeSlug={slug}
          podcastSlug={episode && episode?.pageUrl}
          image={imageUrl}
          author={(episode && episode?.reference?.author) || ''}
          productionCompany={(episode && episode?.reference?.publisher) || ''}
          datePublished={publishDate}
          episodeNumber={episode && episode?.episodeNumber}
          keywords={episode?.reference?.labels && episode?.reference?.labels.join()}
        />
      </Fragment>
    );
  };

  return (
    <header data-testid="podcast-episode-page-header">{isMobile ? renderMobileHeader() : renderDesktopHeader()}</header>
  );
}

export default PodcastEpisodePageHeader;
