export default function trackAccordionMenuClick(title) {
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'menu-accordion_open',
      event_category: 'menu-accordion',
      event_action: 'open',
      event_label: title,
    },
  ]);
}
