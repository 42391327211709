import React, { useState } from 'react';

import styles from './PlayerButtons.styles';
import PlayerVolume from './PlayerButton/PlayerVolume';
import PlayerVideo from './PlayerButton/PlayerVideo';
import PlayerRecentlyPlayed from './PlayerButton/PlayerRecentlyPlayed';
import PlayerRecommendations from './PlayerButton/PlayerRecommendations';
import PlayerChromecast from './PlayerButton/PlayerChromecast';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { getJWPlayerActive } from 'store/reducers/jwPlayer';
import { isMobile } from 'util';

// Set order of popups
let buttonPopups = {
  Video: null,
  Afspeellijst: null,
  Recommendations: null,
};

export const PlayerButtonsContext = React.createContext({ popups: [] });

const useStyles = createUseStyles(styles, { name: 'PlayerButtons' });
const PlayerButtons = () => {
  const classes = useStyles();
  const [popups, setPopups] = useState({});
  const isVideoModalOpen = useSelector((state) => getJWPlayerActive(state));

  const displayPopup = (key, component) => {
    buttonPopups = {
      ...buttonPopups,
      [key]: component,
    };
    setPopups(buttonPopups);
  };

  const hidePopup = (key) => {
    buttonPopups = {
      ...buttonPopups,
      [key]: null,
    };
    setPopups({
      ...buttonPopups,
    });
  };

  return (
    <PlayerButtonsContext.Provider value={{ displayPopup, hidePopup }}>
      <div data-testid="player-bottom-buttons" className={classes.container}>
        <div className={classes.content}>
          <div className={classes.popout}>
            <div className={classes.containerRelative}>
              {Object.keys(popups).map((key) => (
                <div key={key} className={classes.popoutItem}>
                  {popups[key]}
                </div>
              ))}
            </div>
          </div>

          {!isMobile && <PlayerVideo />}
          <PlayerRecentlyPlayed />
          <PlayerRecommendations />
          {!isVideoModalOpen && <PlayerVolume />}
          {/* <PlayerMiniPlayer /> */}
          <PlayerChromecast />
        </div>
      </div>
    </PlayerButtonsContext.Provider>
  );
};

export default PlayerButtons;
