import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';
import * as cssVariable from 'cssVariables';
import quoteIcon from './quote-icon.svg';

export const QUOTE_LAYOUT = {
  DEFAULT: 'Default',
  LIGHT: 'Light',
};

// simple embed video player for youtube, vimeo etc
class Quote extends PureComponent {
  static propTypes = {
    quote: PropTypes.string.isRequired,
    author: PropTypes.string,
    classes: PropTypes.object.isRequired,
    layout: PropTypes.string,
  };

  static defaultProps = {
    author: null,
    layout: QUOTE_LAYOUT.DEFAULT,
  };

  render() {
    const { quote, author, classes, layout } = this.props;

    return (
      <figure className={classes.wrapper}>
        <blockquote
          className={classNames({
            [classes.quoteDefault]: layout === QUOTE_LAYOUT.DEFAULT,
            [classes.quoteLight]: layout === QUOTE_LAYOUT.LIGHT,
          })}
          dangerouslySetInnerHTML={{ __html: quote }}
        />
        {author && <figcaption className={classes.author}>{author}</figcaption>}
      </figure>
    );
  }
}

const styles = {
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '40px 0 20px 0',
  },
  quoteDefault: {
    display: 'block',
    maxWidth: 680,
    fontSize: cssVariable.fontSizes.xxlarge,
    fontWeight: cssVariable.fontWeights.bold,
    color: cssVariable.grey600,
    textTransform: 'uppercase',
    lineHeight: 1.1,
    textAlign: 'center',
    margin: 0,
    padding: 20,
    '&:before': {
      height: 40,
      width: 50,
      background: `url(${quoteIcon})`,
      backgroundRepeat: 'no-repeat',
      content: '""',
      marginBottom: 20,
    },
  },
  quoteLight: {
    display: 'block',
    maxWidth: 640,
    textAlign: 'center',
    fontSize: cssVariable.fontSizes.large,
    fontWeight: cssVariable.fontWeights.light,
    color: cssVariable.grey500,
    margin: '0 0 20px 0',
    lineHeight: 1.3,
  },
  author: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 680,
    textAlign: 'center',
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.grey500,
    textTransform: 'uppercase',
    '&:before': {
      content: '""',
      width: '100%',
      height: 2,
      marginBottom: 9,
      backgroundColor: cssVariable.green500,
    },
  },
};

export default injectStyles(styles)(Quote);
