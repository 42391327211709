import { Action } from 'redux';
import { inlineMediaActions } from './actions-const';

export function inlineMediaError(): Action<string> {
  return {
    type: inlineMediaActions.INLINE_MEDIA_ERRORED,
  };
}

export function inlineMediaPlay(mediaUrl: string): Action<string> & { mediaUrl: string } {
  return {
    type: inlineMediaActions.INLINE_MEDIA_PLAYING,
    mediaUrl,
  };
}

export function inlineMediaPause(): Action<string> {
  return {
    type: inlineMediaActions.INLINE_MEDIA_PAUSED,
  };
}

export function inlineMediaStop(): Action<string> {
  return {
    type: inlineMediaActions.INLINE_MEDIA_STOPPED,
  };
}
