import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'marked-react';
import { injectStyles } from 'injectStyles';

class RichText extends PureComponent {
  static defaultProps = {
    content: null,
  };

  static propTypes = {
    content: PropTypes.string,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { content, classes } = this.props;
    return (
      <div className={classes.richText}>
        <Markdown>{content}</Markdown>
      </div>
    );
  }
}

const styles = {
  richText: {
    maxWidth: 700,
    width: '100%',
    margin: '0 auto',
  },
};

export default injectStyles(styles)(RichText);
