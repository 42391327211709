import { getMediaGenreForStation } from 'analytics';

export function trackResumeLiveVideo(station, player, isCasting = false) {
  const { name, image, slug } = station;
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'juke-player-live-video_resume',
      event_category: 'juke-player-live-video',
      event_action: 'resume',
      player_state: 'resume',
      event_label: name || null,
      player_channel: name || null,
      media_videotype: 'visual-radio',
      media_type: 'Video',
      media_name: name || null,
      media_thumbnail: (image && image.url) || null,
      media_duration: 0, // 0 = live
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_id: slug || null,
      media_genre: station ? getMediaGenreForStation(station) : null,
      media_bitrate: '64kbps',
      player_fullscreen: player && typeof player.getFullscreen === 'function' ? player.getFullscreen() || false : false,
      player_height: player && typeof player.getHeight === 'function' ? player.getHeight() || null : null,
      player_width: player && typeof player.getWidth === 'function' ? player.getWidth() || null : null,
      player_version: player ? player.version || null : null,
      player_casting: isCasting ? 'chromecast' : false,
    },
  ]);
}

export function trackStopLiveVideo(station, player, isCasting = false) {
  const { name, image, slug } = station;
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'juke-player-live-video_stop',
      event_category: 'juke-player-live-video',
      event_action: 'stop',
      player_state: 'stop',
      event_label: name || null,
      player_channel: name || null,
      media_videotype: 'visual-radio',
      media_type: 'Video',
      media_name: name || null,
      media_thumbnail: (image && image.url) || null,
      media_duration: 0, // 0 = live
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_id: slug || null,
      media_genre: station ? getMediaGenreForStation(station) : null,
      media_bitrate: '64kbps',
      player_fullscreen: player && typeof player.getFullScreen === 'function' ? player.getFullscreen() || false : false,
      player_height: player && typeof player.getHeight === 'function' ? player.getHeight() || null : null,
      player_width: player && typeof player.getWidth === 'function' ? player.getWidth() || null : null,
      player_version: player ? player.version || null : null,
      player_casting: isCasting ? 'chromecast' : false,
    },
  ]);
}

export function trackPlayLiveVideo(station, player, isCasting = false) {
  const { name, image, slug, videoUrl } = station;
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'juke-player-live-video_play',
      event_category: 'juke-player-live-video',
      event_action: 'play',
      player_state: 'play',
      event_label: name || null,
      player_channel: name || null,
      player_video_url: videoUrl || null,
      media_videotype: 'visual-radio',
      media_type: 'Video',
      media_name: name || null,
      media_thumbnail: (image && image.url) || null,
      media_duration: 0, // 0 = live
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_id: slug || null,
      media_genre: station ? getMediaGenreForStation(station) : null,
      media_bitrate: '64kbps',
      player_fullscreen: player && typeof player.getFullscreen === 'function' ? player.getFullscreen() || false : false,
      player_height: player && typeof player.getHeight === 'function' ? player.getHeight() || null : null,
      player_width: player && typeof player.getWidth === 'function' ? player.getWidth() || null : null,
      player_version: player ? player.version || null : null,
      player_casting: isCasting ? 'chromecast' : false,
    },
  ]);
}

export function trackStartLiveVideo(station, player, isCasting = false) {
  const { name, image, slug } = station;
  window.Taq.push([
    'track',
    'link',
    {
      event_name: 'juke-player-live-video_start',
      event_category: 'juke-player-live-video',
      event_action: 'start',
      player_state: 'start',
      event_label: name || null,
      player_channel: name || null,
      media_videotype: 'visual-radio',
      media_type: 'Video',
      media_name: name || null,
      media_thumbnail: (image && image.url) || null,
      media_duration: 0, // 0 = live
      media_playtime: 0,
      media_playbackrate: 1, // 1 = normal play speed
      media_playbackpercentage: 100,
      media_id: slug || null,
      media_genre: station ? getMediaGenreForStation(station) : null,
      media_bitrate: '64kbps',
      player_fullscreen: player && typeof player.getFullScreen === 'function' ? player.getFullscreen() : false,
      player_height: player && typeof player.getHeight === 'function' ? player.getHeight() : null,
      player_width: player && typeof player.getWidth === 'function' ? player.getWidth() : null,
      player_version: player && typeof player.getVersion === 'function' ? player.getVersion() : null,
      player_casting: isCasting ? 'chromecast' : false,
    },
  ]);
}
