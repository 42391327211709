import { connect } from 'react-redux';
import { setTrackOnDemandPlaybackTime } from 'actions/player-actions';
import { getIsPlaying, getOnDemandPlaybackDuration, getOnDemandPlaybackTime } from 'store/reducers/player';
import Scrubber from './Scrubber';
import { getMobileLightVariant } from 'util/index';

const mapStateToProps = (state) => ({
  elapsedTime: getOnDemandPlaybackTime(state),
  duration: getOnDemandPlaybackDuration(state),
  isPlaying: getIsPlaying(state),
  lightVariant: getMobileLightVariant(),
});

export default connect(mapStateToProps, {
  onScrubEnd: setTrackOnDemandPlaybackTime,
})(Scrubber);
