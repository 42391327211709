import deepmerge from 'deepmerge';

import { createTypography } from './createTypography';
import { createPalette } from './createPalette';
import { createBreakpoints } from './createBreakpoints';
import defaultTheme from './defaultTheme';

const setDevice = () => {
  if (window.matchMedia('only screen and (max-width: 767px)').matches) return 'mobile';
  if (window.matchMedia('only screen and (min-width: 1025px)').matches) return 'pc';
  return 'tablet';
};

const setBannerDevice = () => (window.matchMedia('only screen and (max-width: 767px)').matches ? 'mobile' : 'desktop');

const setDeviceType = () => {
  if (window.matchMedia('only screen and (max-width: 767px)').matches) return 'mobile';
  if (window.matchMedia('only screen and (max-width: 1024px)').matches) return 'tablet';
  if (window.matchMedia('only screen and (max-width: 1280px)').matches) return 'small_laptop';
  if (window.matchMedia('only screen and (max-width: 1370px)').matches) return 'medium_laptop';
  if (window.matchMedia('only screen and (max-width: 1440px)').matches) return 'large_laptop';
  return 'pc';
};
export function generateTheme(options = {}, deviceTypeNext = '') {
  const {
    breakpoints: breakpointsInput = {},
    palette: paletteInput = defaultTheme.palette,
    typography: typographyInput = defaultTheme.typography,
    ...other
  } = options;

  const palette = createPalette(paletteInput);
  const breakpoints = createBreakpoints(breakpointsInput);
  const deviceType = process.browser ? setDeviceType() : deviceTypeNext || 'pc';
  const device = process.browser ? setDevice() : deviceTypeNext || 'pc';
  const bannerDevice = process.browser ? setBannerDevice() : deviceTypeNext || 'desktop';
  const { shadows, shape, transitions, icons, components, utils, mixins, overrides } = defaultTheme;

  return {
    palette,
    typography: createTypography(palette, typographyInput, breakpoints),
    breakpoints,
    device,
    deviceType,
    bannerDevice,
    ...deepmerge(
      {
        shadows,
        shape,
        transitions,
        icons,
        components,
        utils,
        mixins,
        overrides,
        spacing: {
          unit: 10,
        },
        zIndex: {
          default: 10,
          overlay: 10000,
          dialog: 500,
        },
      },
      other
    ),
  };
}
