export default {
  carousel: 10,
  mobileHeader: 20, // Mobile header
  modal: 30, // All regular modals like search modal, collection modal, etc
  header: 35, // default header, make it visible in the search results.
  player: 40, // Mini player, overlay player
  notifications: 50, // List of notifications
  navigation: 60, // Navigation

  onTop: 100, // Popup modal message

  iframeWall: 9999,

  feedback: 10100,

  playerBottom: 10200,
  modalStandalone: 10250, // Standalone modal that is on top of all content

  apiSwitch: 10300,

  pushNotification: 999999,
};
