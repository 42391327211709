import React from 'react';

import { injectStyles } from 'injectStyles';

import * as cssVariable from 'cssVariables';

import PageContent from 'components/PageContent';
import Loader from 'components/Loader';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 60,
  },
  text: {
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.grey500,
    margin: 0,
  },
};

const LoadingPage = injectStyles(styles)(({ classes }) => (
  <PageContent>
    <div className={classes.container} data-testid="loading-page">
      <Loader />
      <p className={classes.text}>De pagina wordt geladen...</p>
    </div>
  </PageContent>
));

export default LoadingPage;
