import React from 'react';
import { RECOMMENDATIONS } from 'globalConst/const';
import { createUseStyles } from 'react-jss';
import styles from './RecommendationTiles.styles';
import PropTypes from 'prop-types';
import StationTile from 'components/Tiles/StationTile/';
import { trackRecommendationTilesClick } from 'analytics';

const useStyles = createUseStyles(styles, { name: 'RecommendationTiles' });
const RecommendationTiles = ({ items, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.recommendationTiles}>
      <p className={classes.title}>{title}</p>

      <div className={classes.recommendationTilesWrapper}>
        {items?.map((station) => (
          <div key={station.id}>
            <StationTile {...station} customTracking={trackRecommendationTilesClick} />
          </div>
        ))}
      </div>
    </div>
  );
};

RecommendationTiles.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};

RecommendationTiles.defaultProps = {
  items: [],
  title: RECOMMENDATIONS,
};

export default RecommendationTiles;
