import React, { useEffect } from 'react';
import ScrollMemory from 'react-router-scroll-memory';
import PropTypes from 'prop-types';
import { debounce } from 'util/helpers';
import { isTouch, isIframe } from 'util/device';
import { SCROLL_CONTAINER_ID, FOOTER_ID, CONTENT_CONTAINER } from 'globalConst/const';
import ApiSwitch from 'components/ApiSwitch';
import { isPopoutPlayer } from 'globalConst/const';
import ErrorBoundary from '../App/ErrorBoundary';
import * as analytics from 'analytics';
import Content from 'components/Content';
import Navigation from 'components/Navigation';
import PlayerBottom from 'components/PlayerBottom';

import IframeWall from 'components/IframeWall';
import Search from 'components/Search';
import Notifications from 'components/Notifications';
import Container from './Container';
import GigyaDialog from '../Gigya';
export default function Layout({
  handleConsentManagerDisplay,
  isInMobileApp,
  location,
  hasMobileHeader,
  userLeftJuke,
  userIsOnJuke,
  closeFold,
  setModalClosed,
  closeNavigation,
  modalIsOpen,
  navigationIsOpen,
}) {
  const handleBrowserTabChange = () => {
    if (document.visibilityState !== 'visible') {
      userLeftJuke();
    } else {
      userIsOnJuke();
    }
  };

  useEffect(() => {
    handleConsentManagerDisplay();
    window.addEventListener('hashchange', handleConsentManagerDisplay);
    document.addEventListener('visibilitychange', handleBrowserTabChange);

    /**
     *  Check if preventCache is set to flush cache and reload
     */
    const { pathname, search } = window.location;
    if (search.includes('preventCache')) {
      window.history.replaceState(null, document.title, pathname);
      window.location.reload(true);
    }

    return () => {
      window.removeEventListener('hashchange', handleConsentManagerDisplay);
      document.removeEventListener('visibilitychange', handleBrowserTabChange);
    };
  }, []);

  /**
   *  location change
   */
  useEffect(() => {
    if (hasMobileHeader && navigationIsOpen) {
      closeNavigation();
    }

    if (hasMobileHeader && modalIsOpen) {
      setModalClosed();
    }

    // Reset feature cache
    closeFold();

    if (isTouch) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  /**
   * Track the scroll depth
   */
  useEffect(() => {
    if (isInMobileApp || isPopoutPlayer) {
      return;
    }
    const scrollContainer = isTouch ? window : document.getElementById(SCROLL_CONTAINER_ID);

    let lastScrollTop = 0;

    const trackScrollDepth = (e) => {
      let contentHeight = 0;
      const contentContainer = document.getElementById(CONTENT_CONTAINER);
      const footerContainer = document.getElementById(FOOTER_ID);
      const el = isTouch ? e.target.documentElement : e.target;
      const scrollTop = el.scrollTop;

      if (contentContainer) contentHeight = contentContainer.offsetHeight;
      if (footerContainer) contentHeight += footerContainer.offsetHeight;

      if (contentHeight === 0) {
        return;
      }
      //only track on scroll down
      if (scrollTop > lastScrollTop) {
        const scrollHeight = contentHeight - window.innerHeight;
        const scrollPercent = Math.round((100 / scrollHeight) * scrollTop);
        analytics.trackScroll(scrollPercent);
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    const debouncedTrackScrollDepth = debounce(trackScrollDepth, 2000);

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', debouncedTrackScrollDepth);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', debouncedTrackScrollDepth);
      }
    };
  }, [isInMobileApp]);

  /**
   * hide Mopinion feedback button in mobile app and in popoutplayer
   */
  useEffect(() => {
    if (isInMobileApp || isPopoutPlayer) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode('.mopinion-survey-content > button { display: none !important; }'));
      document.head.appendChild(style);
    }
  }, [isInMobileApp]);

  /**
   * Only render the player when isPopoutPlayer
   */
  if (isPopoutPlayer) {
    // TODO: include PlayerBottom mini player
    return null;
  }

  /**
   * Render when loaded in Juke Android/iOS app.
   */
  if (isInMobileApp) {
    return (
      <Container testId="mobileApp">
        <ErrorBoundary>
          <Content />
        </ErrorBoundary>
        {process.env.REACT_APP_ENABLE_API_SWITCH === 'true' && <ApiSwitch />}
      </Container>
    );
  }

  /**
   * Render when loaded normally, as standalone web app.
   */
  return (
    <Container testId="standalone">
      <ScrollMemory />
      {process.env.REACT_APP_ENABLE_API_SWITCH === 'true' && <ApiSwitch />}
      <Navigation />
      <Search show={hasMobileHeader} />
      <ErrorBoundary>
        <Content />
      </ErrorBoundary>
      <PlayerBottom />
      <Notifications isMobile={hasMobileHeader} />
      {isIframe() && <IframeWall />}
      <GigyaDialog />
    </Container>
  );
}

Layout.defaultProps = {
  hasMobileHeader: false,
  isInMobileApp: false,
  modalIsOpen: false,
  navigationIsOpen: false,
};

Layout.propTypes = {
  handleConsentManagerDisplay: PropTypes.func.isRequired,
  closeFold: PropTypes.func.isRequired,
  closeNavigation: PropTypes.func.isRequired,
  hasMobileHeader: PropTypes.bool,
  location: PropTypes.object.isRequired,
  isInMobileApp: PropTypes.bool,
  setModalClosed: PropTypes.func.isRequired,
  userIsOnJuke: PropTypes.func.isRequired,
  userLeftJuke: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
  navigationIsOpen: PropTypes.bool,
};
