import { format } from 'date-fns';
import nl from 'date-fns/locale/nl';

export function getAirDate(utc) {
  return format(utc ? new Date(utc) : new Date(0), 'EEEEEE d MMMM', {
    locale: nl,
  });
}

export function getAirDateWithYear(utc) {
  return format(utc ? new Date(utc) : new Date(0), 'EEEEEE d MMMM yyyy', {
    locale: nl,
  });
}

export function getDaysAgo(utc) {
  // use day starts for calculation
  const today = new Date(new Date().toDateString());
  const date = new Date(new Date(utc).toDateString());
  return parseInt((today - date) / (24 * 60 * 60 * 1000), 10);
}

/**
 * Returns a SEO and screen reader friendly formatted string of the given utc
 * @param {string} utc
 */
export function getReaderAirDate(utc) {
  return format(utc ? new Date(utc) : new Date(0), 'EEEE d MMMM yyyy', {
    locale: nl,
  });
}

export function calculateProgress(inTime, duration) {
  if (!inTime || !duration) {
    return 0;
  }
  const progress = (inTime / duration) * 100;
  return Math.max(Math.min(progress, 100), 0);
}
