import React, { PureComponent } from 'react';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { getStore } from 'store';
import history from 'lib/history';
import { appBoot } from 'actions/lifecycle-actions';
import DocumentMeta from 'react-document-meta';
import Schema, { generalSchema } from 'components/Schema';

import { injectStyles } from 'injectStyles';

import 'analytics/init';
import 'players/ChromeSender';

import { globalStyle, accessibilityStyleSettings } from './global-styles';
import ErrorBoundary from './ErrorBoundary';
import Layout from '../Layout';
import { baseMeta } from './App.const';
import Theme from './Theme';
import { FlagProvider } from 'firebase/FlagProvider';

const store = getStore();

// no need to wait untill the interface is loaded if we can get the data already
store.dispatch(appBoot());

class App extends PureComponent {
  componentDidMount() {
    accessibilityStyleSettings();
    window.e2e = window.e2e || {};
  }

  render() {
    return (
      <Provider store={store}>
        <FlagProvider>
          <Theme>
            <Router history={history}>
              <ErrorBoundary>
                <DocumentMeta {...baseMeta} />
                <Schema data={generalSchema} />
                <Layout />
              </ErrorBoundary>
            </Router>
          </Theme>
        </FlagProvider>
      </Provider>
    );
  }
}

export default injectStyles(globalStyle)(App);
