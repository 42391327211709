import React, { Fragment } from 'react';
import GoogleCastLauncher from './GoogleCastLauncher';
import PlayerButton from '../PlayerButton';
import { useSelector } from 'react-redux';
import { getCastingAvailable, getIsCasting } from 'store/reducers/player';

const PlayerChromecast = () => {
  const castingAvailable = useSelector(getCastingAvailable);
  const isCasting = useSelector(getIsCasting);

  const renderIcon = () => {
    return <GoogleCastLauncher />;
  };

  return (
    <Fragment>
      {castingAvailable ? (
        <PlayerButton
          data-testid="player-bottom-chromecast-btn"
          active={isCasting}
          title={isCasting ? 'Verbonden' : 'Verbinden'}
          renderIcon={renderIcon}
          hideOnMedium
          hideOnSmall
        />
      ) : null}
    </Fragment>
  );
};

export default PlayerChromecast;
