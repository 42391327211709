import { connect } from 'react-redux';

import { getSearchIsActive, getSearchIsLoading, getSearchQuery, getRecentSearchItems } from 'store/reducers/search';
import { setActive, setSearchResults } from 'actions/search-actions';
import { SearchResults } from './SearchResults';
import { State } from 'redux';

const mapStateToProps = (state: State) => {
  return {
    searchTerm: getSearchQuery(state),
    isLoading: getSearchIsLoading(state),
    isActive: getSearchIsActive(state),
    hasRecentSearchItems: !!getRecentSearchItems(state).length,
  };
};

const mapDispatchToProps = {
  setActive,
  setSearchResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
