/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action } from 'redux';
import history from 'lib/history';
import { getStore } from 'store';
import { setBodyScroll, removeHash } from 'util/index';
import { gigyaRouterActions } from './actions-const';
import { GIGYA_ACCOUNT_ACTION } from 'components/Gigya';

const store = getStore();

const SCREEN_SET_ROUTES = {
  'wachtwoord-vergeten': {
    screenName: 'wachtwoord-vergeten',
  },
  'wachtwoord-resetten': {
    screenName: 'wachtwoord-resetten',
  },
};

function browseToScreen(screenName: string): void {
  const pathname = Object.keys(SCREEN_SET_ROUTES).reduce((result, routeScreen) => {
    const path = SCREEN_SET_ROUTES[routeScreen].screenName === screenName ? `#${routeScreen}` : result;
    return path;
  }, '#');

  const currentPath = history.location.pathname;

  if (pathname === currentPath) {
    return;
  }

  if (location.search.includes(`?account-action=${GIGYA_ACCOUNT_ACTION.RESET_PASSWORD}`)) {
    return;
  }

  history.push(pathname);
  if (pathname === '#') {
    removeHash();
  }
}

export function handleBeforeScreenLoad(event: { nextScreen: string }): void {
  const {
    gigyaModal: { currentScreen },
  } = store.getState();
  const { nextScreen } = event;

  if (nextScreen !== currentScreen) {
    browseToScreen(nextScreen);
    setBodyScroll(false);
    store.dispatch({
      type: gigyaRouterActions.GIGYA_SCREEN_OPENED,
      screen: nextScreen,
    });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function handleAfterSubmit(event: any): void {
  const { response } = event;
  /*
   ** Errorcode 206001:
   ** Error message: Account pending registration
   ** Description: A method has been called that performs social login,
   ** but the registration process has not been finalized,
   ** or a required field is missing from the user profile or data.
   ** See https://developers.gigya.com/display/GD/Response+Codes+and+Errors+REST for all Gigya response codes
   */

  if (response.errorCode !== 0 && response.errorCode !== '206001') {
    return;
  }
}

export function handleHide(event: { reason: string }): void {
  if (event.reason !== 'canceled') {
    browseToScreen(null);
  }
  setBodyScroll(true);
  store.dispatch({ type: gigyaRouterActions.GIGYA_SCREEN_CLOSED });
}

export function handleConsentManagerDisplay(): () => void | Action<string> | void {
  return () => {
    if (window.location.hash.substring(1) === 'show-consent-manager' && typeof window.__tcfapi === 'function') {
      window?.OneTrust.ToggleInfoDisplay();
    }
    removeHash();
  };
}
