import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { injectStyles } from 'injectStyles';

import * as cssVariable from 'cssVariables';

import RadioError from 'components/svg/RadioError';

class NoResults extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    query: PropTypes.string.isRequired,
  };

  render() {
    const { classes, query } = this.props;
    return (
      <div className={classes.container} data-testid="no_search_result">
        <div className={classes.radioError}>
          <RadioError />
        </div>
        <p className={classes.headerLikeText}>{`Er zijn geen resultaten gevonden voor ${query}`}</p>
        <p className={classes.text}>Controleer je spelling of gebruik minder zoekwoorden</p>
      </div>
    );
  }
}

const styles = (theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: '0',
    },
    to: {
      opacity: '1',
    },
  },
  container: {
    position: 'absolute',
    top: 'calc(50% - 165px)',
    left: 0,
    width: '100%',
    opacity: '0',
    textAlign: 'center',
    animation: `$fadeIn ${cssVariable.transitionTime} ease-in ${cssVariable.transitionDelay} forwards`,
    padding: '60px 0',
  },
  headerLikeText: {
    fontSize: cssVariable.fontSizes.mediumLarge,
    fontWeight: cssVariable.fontWeights.bold,
    color: cssVariable.grey600,
    padding: '0 20px',
  },
  text: {
    fontSize: cssVariable.fontSizes.small,
    color: cssVariable.grey500,
  },
  // fix for 16:9 smartphones, to avoid overlaping of footer and mini player
  [`@media (max-height: 610px) and (max-width: ${theme.breakpoints.values.xs}px)`]: {
    container: {
      top: 70,
      '& p': {
        margin: '8px 0',
      },
    },
  },
  // hide image if height is very small
  '@media (max-height: 470px)': {
    radioError: {
      display: 'none',
    },
    // depending on layout type (mobile od desktop), move text
    [theme.breakpoints.down('md')]: {
      container: {
        top: 100,
      },
    },
    [theme.breakpoints.up('md')]: {
      container: {
        top: 140,
      },
    },
  },
});

export default injectStyles(styles)(NoResults);
