import { queryRequest } from './client';
import ClipCollection from 'api/models/ClipCollection';
import { getClipCollection } from './queries';

export const fetchClipCollection = async ({ slug }) => {
  try {
    const { data } = await queryRequest({
      query: getClipCollection,
      variables: { slug },
    });
    return new ClipCollection(data.clips);
  } catch (err) {
    return null;
  }
};
