export const PROMOTION_COLLECTION_FRAGMENT = `
  fragment PromotionCollectionFragment on PromotionCollection {
    id
    slug
    type
    title
    options
    description
    config {
      ...ConfigFragment
    }
    items {
      id
      type
      title
      uri
      options
      images {
        ...ImageFragment
      }
      ref {
        id
        targetType
        type
        uri
      }
      reference {
        ... on Campaign {
          id
          type
          slug
          uri
          config {
            ...ConfigFragment
          }
        }
        ... on Podcast {
          id
          type
          slug
          subTitle
          labels
          images {
            ...ImageFragment
          }
          refs {
            id
            type
            targetType
            uri
          }
          tags {
            type
            title
          }
        }
        ... on Tag {
          id
          type
          slug
          config {
            ...ConfigFragment
          }
        }
        ... on Station {
          id
          type
          slug
          title
          images {
            ...ImageFragment
          }
          config {
            ...ConfigFragment
          }
        }
      }
    }
  }
  `;
